import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import VerifyForm from './VerifyForm';
import LoadMask from "Utils/LoadMask/LoadMask";
import './Verify.css';
import Logo from '../../../../../assets/img/contrasena.png';
import Exit from '../../../../../assets/img/exito.png';
class Verify extends Component {
    static propTypes = {
        onVerify: PropTypes.func.isRequired,
    };

    render() {
        const { onVerify, loader, tokenV } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="background-reset align-self-center align-items-center justify-content-center ">
                <div className="login-wrapper-verify col-12 col-lg-5 col-md-6 row">
                    {tokenV != 'true' ?
                    <div className="w-100">
                    <div className="col-12 card-reset-header">
                        <span className="col-4 card-reset-link"> 
                            <Link to="/login"> 
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                &nbsp;&nbsp; Regresar 
                            </Link> 
                        </span>
                        <span className="col-8 card-reset-title"> Nueva Contraseña </span>
                    </div>
                    <div className="col-12 card card-verify justify-content-center">
                    <img className="logo" src={Logo}></img>
                        <h5 className="text-center pv"><b>INTRODUCIR NUEVA CONTRASEÑA</b></h5>
                        <LoadMask loading={loader} light>
                            <VerifyForm onSubmit={onVerify} initialValues={{"token": tokenV}} />
                        </LoadMask>
                    </div>
                    </div>
                    : <div className="col-12 card card-verify justify-content-center">
                            <img className="logo" src={Exit}></img>
                            <h5 className="text-center pv"><b>TU CONTRASEÑA AH SIDO CAMBIADA</b></h5>
                            <br/> <br/>
                            <Link to="/login" className="btn btn-azul-claro"> Iniciar Sesion </Link>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Verify;
