import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/producto/tipoproducto';
import TipoProducto from './TipoProductoCrear';

const mstp = state => {
    return {...state.tipoproducto}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TipoProducto)
