import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';

let estado = [
    {id: false, value: false, label:"Todos"},
    {id: true, value: true, label:"Alertados"},
]

export default class ToolbarProductos extends Component {

    render() {
        const {
            alertado,
            changeTipo,
            bodega,
        } = this.props;
        return (
            <div className=" row col-lg-12 col-12 row m-0 p-0 ">
                <div className="col-12">
                    <Select
                        isClearable={false}
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={false}
                        isSearchable={true}
                        options={estado}
                        placeholder={"Todos"}
                        value={alertado}
                        onChange={(e)=>changeTipo(e,bodega)}
                    />
                </div>
            </div>
        )
    }
}