import React, { Component } from 'react'

import PagoCredito from './pagosCredito';


export default class pagocContainer extends Component {
    componentWillMount(){
        const { moneda, item } = this.props;
        this.props.selectCuentas(null, moneda)

    }
    render() {
        const { cuentas } = this.props;
        return (
            <div>
                {
                    // (!this.props.orden.pago_inmediato)&& (
                    //     <PagoInmediato
                    //         {...this.props}
                    //         />
                    // ): (
                    // )
                        <PagoCredito
                            {...this.props}
                        />
                }

            </div>
        )
    }
}
