
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "bodegas",
    "bodega",
    "bodegaForm",
    "/bodegas",
);

export default handleActions(reducers, initialState);
