import React, { Component, Fragment } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import { T_MOVIMIENTOS } from "../../../../../utility/constants";
import ToolbarFiltroFecha from "../../../Utils/Toolbar/ToolbarFiltroFecha";

import Modal from "react-responsive-modal";
import AnulacionForm from "../../Transacciones/Listar/anulacionForm";
import Swal from "sweetalert2";

import ListadoT from "./listadoT";
import { GridNotasCredito } from "../../NotasCredito";
// formulario de pago
import PagoCreditoForm from "../../OrdenVenta/Detalle/Pagos/PagoCreditoForm";
import ReintegroForm from "./ReintegroForm";
import ConvertirChequeForm from "./covertirCheque";

const ver_icono = require("../../../../../../assets/img/icons/Ver.png");
const chequeAefectivo = require("../../../../../../assets/img/icons/chequeAefectivo.png");

function cellTachado(cell, row) {
    if (!row.estado) {
        return {
            textDecoration: "line-through",
            whiteSpace: "normal",
            padding: 5,
        };
    }
    return { whiteSpace: "normal", padding: 5 };
}

export default class DeudaReporte extends Component {
    state = {
        clipro: false,
        es_pago: false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        anular_transaccion: true,
        modalNota: false,
        tab: "1",
        modalChequeEfectivo: false,
    };
    componentDidMount() {
        if (this.props.match.path == "/reporte_deuda/:id") {
            this.props.listarDetalleDeudaEmpresas(
                this.props.page,
                this.props.match.params.id
            );
            this.setState({ clipro: false });
        } else {
            this.props.listarDetalleDeudaClienteProveedor(
                this.props.page,
                this.props.match.params.id
            );
            this.setState({ clipro: true });
            this.props.selectCuentas();
            this.props.selectEmpresas();
            this.props.getTipoGastos();
        }
    }
    onChangePage = (pagina) => {
        if (this.props.match.path == "/reporte_deuda/:id") {
            this.props.listarDetalleDeudaEmpresas(
                pagina,
                this.props.match.params.id
            );
            this.setState({ clipro: false });
        } else {
            this.props.listarDetalleDeudaClienteProveedor(
                pagina,
                this.props.match.params.id
            );
            this.setState({ clipro: true });
        }
    };
    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: "¿Desea anular la transacción?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, anular!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(
                    values,
                    this.props.match.params.id
                );
            }
        });
    };

    botonModal = (id, row) => {
        if (row.estado) {
            return <div></div>;
        }
        return (
            <a
                className="px-2"
                style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        id_transaccion: id,
                        transaccion_sel: row,
                    });
                    this.props.setModal(true);
                }}
            >
                <img
                    className="action-img"
                    title="adicional"
                    src={ver_icono}
                    alt="Ver eliminado"
                />
            </a>
        );
    };

    botonChequeEfectivo = (id, row) => {
        if (row.transaccion.tipo_documento != 2 || row.flotante == false) {
            return <div></div>;
        }
        if (row.transaccion.cobrador == this.props.usuario && row.estado) {
            return (
                <a
                    className="px-2"
                    style={{ cursor: "pointer", color: "#c4183c" }}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            modalChequeEfectivo: true,
                            id_transaccion: row.id,
                            transaccion_sel: row,
                        });
                    }}
                >
                    <img
                        // className="action-img"
                        style={{ width: "24px", height: "24px" }}
                        title="Cobrar cheque"
                        src={chequeAefectivo}
                        alt="Ver eliminado"
                    />
                </a>
            );
        }
    };
    openModalNota = () => {
        console.log("open modal");
        this.setState({ tab: "2" });
        this.setState({ modalNota: true });
    };

    cerrarModal = () => {
        this.props.setModal(false);
        this.props.setModalReintegros(false);
        this.props.setModalPagos(false);
        this.setState({ modalNota: false, modalChequeEfectivo: false });
    };
    renderModalChequeEfectivo = () => {
        const { open_modal_pagos, cuentas, simbolo, orden, data } = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;
        return (
            <Fragment>
                <Modal
                    open={this.state.modalChequeEfectivo}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.cerrarModal(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Cobro de cheque
                        </span>
                    </div>
                    <ConvertirChequeForm
                        initialValues={{
                            tipo_cuenta: "false",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            monto: this.state.transaccion_sel
                                ? this.state.transaccion_sel.transaccion.monto
                                : 0,
                            descripcion: this.state.transaccion_sel
                                ? this.state.transaccion_sel.transaccion
                                      .descripcion
                                : "",
                        }}
                        cerrarModal={this.cerrarModal}
                        onSubmit={(values) => {
                            this.props.abonarEfectivoCheque(
                                this.props.match.params.id,
                                true,
                                this.state.id_transaccion
                            );
                            this.cerrarModal(false);
                        }}
                    />
                </Modal>
            </Fragment>
        );
    };
    renderModalPagoVenta = () => {
        const { open_modal_pagos, cuentas, simbolo, orden, data } = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;
        return (
            <Fragment>
                <Modal
                    open={open_modal_pagos}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.props.setModalPagos(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            {data.activar_boton ? "Agregar pago" : "Cobro"}
                        </span>
                    </div>
                    <PagoCreditoForm
                        cerrarModal={this.cerrarModal}
                        cuentas={cuentas}
                        simbolo={simbolo}
                        initialValues={{
                            tipo_cuenta: "true",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            fecha_cobro: new Date(),
                        }}
                        es_gasto={true}
                        texto_boton_movimiento={"Registrar"}
                        titulo_modal="Ingresar cobro a"
                        pago_cobro={"Cobro"}
                        esCobro={true}
                        onSubmit={(e) => {
                            this.props.abonarGastoOPago(
                                this.props.match.params.id,
                                true
                            );
                        }}
                    />
                </Modal>
            </Fragment>
        );
    };

    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return (
            <Modal
                open={open_modal}
                showCloseIcon={false}
                onClose={(e) => {
                    this.props.setModal(false);
                }}
                center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title">
                        Justificación de anulación
                    </span>
                </div>
                {this.state.transaccion_sel &&
                !this.state.transaccion_sel.transaccion.estado ? (
                    <div className="form-validate mb-lg text-center">
                        <span className="m-none t-mostaza semibold">
                            Fecha anulación:
                        </span>
                        <br />
                        <span className="m-none t-musgo semibold">
                            <RenderDateTime
                                fecha={
                                    this.state.transaccion_sel.transaccion
                                        .fecha_anulacion
                                }
                            />
                        </span>
                        <br />
                        <span className="m-none t-mostaza semibold">
                            Usuario anulación:
                        </span>
                        <br />
                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span>
                        <br />
                        <span className="m-none t-mostaza semibold">
                            descripción:
                        </span>
                        <br />
                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span>
                        <br />
                    </div>
                ) : (
                    <AnulacionForm
                        onSubmit={this.onSubmit}
                        cerrarModal={this.props.setModal}
                    />
                )}
            </Modal>
        );
    };
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
        });
        this.props.setModal(true);
    };

    getMovimiento = (val) => {
        let texto = "";
        T_MOVIMIENTOS.forEach((item, index) => {
            if (item.value == val) {
                texto = item.label;
            }
        });
        return texto;
    };
    handleDateStart = (value) => {
        this.props.setDateStart(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        );
    };

    handleDateEnd = (value) => {
        this.props.setDateEnd(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        );
    };
    renderModalreintegros = () => {
        const {
            open_modal_reintegros,
            cuentas,
            simbolo,
            orden,
            data,
            empresas,
        } = this.props;

        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;

        return (
            <Fragment>
                <Modal
                    open={open_modal_reintegros}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.props.setModalReintegros(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            {data.activar_boton ? "Agregar reintegro" : ""}
                        </span>
                    </div>
                    <ReintegroForm
                        empresas={empresas}
                        cambioDescuento={this.props.cambioDescuento}
                        cerrarModal={this.cerrarModal}
                        cuentas={cuentas}
                        simbolo={simbolo}
                        initialValues={{
                            tipo_cuenta: "true",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            fecha_cobro: new Date(),
                        }}
                        tipo_gastos={this.props.tipo_gastos}
                        es_gasto={true}
                        texto_boton_movimiento={"Registrar"}
                        titulo_modal="Ingresar cobro a"
                        pago_cobro={"Cobro"}
                        esCobro={true}
                        onSubmit={(e) => {
                            // e.cuenta_cliente =  this.props.match.params.id;
                            console.log(e);
                            this.props.reintegroCliente(
                                this.props.match.params.id,
                                false
                            );
                        }}
                    />
                </Modal>
            </Fragment>
        );
    };
    render() {
        const {
            onPageChange,
            data,
            loader,
            open_modal,
            permisos,
            page,
            nombre_empresa,
            moneda,
        } = this.props;
        if (permisos["deuda"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple margen={"mt-0"} row>
                {this.props.setTitulo(
                    `Balance ${this.props.nombre_empresa} e Hilos B'alam`
                )}
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {this.renderModalAnulacion()}
                    {this.renderModalPagoVenta()}
                    {this.renderModalreintegros()}
                    {this.renderModalChequeEfectivo()}
                    <ToolbarFiltroFecha
                        {...this.props}
                        setDateStart={this.handleDateStart}
                        setDateEnd={this.handleDateEnd}
                        dateStart={this.props.dateStart}
                        dateEnd={this.props.dateEnd}
                        justify={"justify-content-center"}
                    />

                    <div className="col-md-12 pt-3 d-flex justify-content-end">
                        {data.activar_boton && (
                            <div>
                                <button
                                    onClick={() => {
                                        this.props.setModalPagos(true);
                                    }}
                                    className="btn btn-azul-claro"
                                >
                                    Agregar pago
                                </button>
                                <button
                                    onClick={() => {
                                        this.props.setModalReintegros(true);
                                    }}
                                    className="btn btn-secondary mr-1 ml-2"
                                >
                                    Agregar reintegro
                                </button>
                            </div>
                        )}
                        {/* {this.state.clipro == true && !data.activar_boton && (
                            <div>
                                <button
                                    onClick={this.openModalNota}
                                    className="btn btn-azul-claro"
                                >
                                    Nota de crédito
                                </button>
                            </div>
                        )} */}
                    </div>
                    <hr
                        style={{
                            background: "#408EAA",
                            height: "2px",
                            width: "100%",
                        }}
                    />
                    <div className="mt-5">
                        {this.state.clipro == true && !data.activar_boton ? (
                            <Tabs
                                defaultActiveKey={this.state.tab}
                                activeKey={this.state.tab}
                                tabBarPoition="top"
                                onChange={(step) => {
                                    this.setState({ tab: step });
                                }}
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Historial" key="1">
                                    <LoadMask
                                        loading={loader}
                                        light
                                        type={"TailSpin"}
                                    >
                                        <ListadoT
                                            {...this.props}
                                            onChangePage={this.onChangePage}
                                            getMovimiento={this.getMovimiento}
                                            abrirModalEliminar={
                                                this.abrirModalEliminar
                                            }
                                            botonModal={this.botonModal}
                                        />
                                    </LoadMask>
                                </TabPane>
                                {/* <TabPane tab="Notas de crédito " key="2">
                                    <GridNotasCredito
                                        openModal={this.state.modalNota}
                                        closeModal={this.cerrarModal}
                                        idP={this.props.idCliPro}
                                        idD={null}
                                    />
                                </TabPane> */}
                            </Tabs>
                        ) : (
                            <ListadoT
                                {...this.props}
                                onChangePage={this.onChangePage}
                                getMovimiento={this.getMovimiento}
                                abrirModalEliminar={this.abrirModalEliminar}
                                botonModal={this.botonModal}
                                botonChequeEfectivo={this.botonChequeEfectivo}
                            />
                        )}
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
