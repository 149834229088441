import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import Modal from "react-responsive-modal";
import DetalleCajaGrid from "./DetalleCajaGrid";
import EgresoCajaForm from "./egresoCaja";
import IngresoCajaForm from "./ingresoCaja";
import { BanderaEmpresa } from "../../../../../utility/constants";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import HistorialCajaGrid from './HistorialCajaGrid';
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";

export default class DetalleCaja extends Component {
    state = {
        open_modal_egreso: false,
        open_modal_ingreso: false,
    };
    componentDidMount() {
        this.props.getHistorialVentas(this.props.pageVentas);
    }
    setOpenModalEgreso = (valor) => {
        this.setState({ open_modal_egreso: valor });
    };
    setOpenModalIngreso = (valor) => {
        this.setState({ open_modal_ingreso: valor });
    };
    retiroDeCajaSucursal = (values) => {
        values.Empresa = `${BanderaEmpresa}`;
        this.props.retiroDeCajaSucursal(values);
        this.setOpenModalEgreso(false);
    };
    AgregarACajaSucursal = (values) => {
        this.props.agregarACajaSucursal(values);
        this.setOpenModalIngreso(false);
    };
    renderModalRemoverEfectivo = () => {
        return (
            <Modal
                open={this.state.open_modal_egreso}
                showCloseIcon={false}
                onClose={(e) => {
                    this.setOpenModalEgreso(false);
                }}
                center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title">
                        Retiro de efectivo
                    </span>
                </div>
                <div className="form-validate mb-lg text-center">
                    <EgresoCajaForm
                        onSubmit={this.retiroDeCajaSucursal}
                        cerrarModal={this.setOpenModalEgreso}
                    />
                </div>
            </Modal>
        );
    };
    renderModalAgregarEfectivo = () => {
        return (
            <Modal
                open={this.state.open_modal_ingreso}
                showCloseIcon={false}
                onClose={(e) => {
                    this.setOpenModalIngreso(false);
                }}
                center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title">
                        Ingreso de efectivo
                    </span>
                </div>
                <div className="form-validate mb-lg text-center">
                    <IngresoCajaForm
                        onSubmit={this.AgregarACajaSucursal}
                        cerrarModal={this.setOpenModalIngreso}
                    />
                </div>
            </Modal>
        );
    };
    render() {
        let { item, loader, data, ventas } = this.props;
        return (
            <div className="col-12">
                {this.renderModalRemoverEfectivo()}
                {this.renderModalAgregarEfectivo()}
                <div
                    className="col 12 row d-flex justify-content-end"
                    style={{ marginTop: "-30px" }}
                >
                    <div className="col-12 col-lg-8 flex-wrap d-flex justify-content-end">
                        <button
                            className="btn btn-azul-claro mr-2"
                            onClick={() => {
                                this.setOpenModalEgreso(true);
                            }}
                        >
                            Remover efectivo
                        </button>
                        <button
                            className="btn btn-azul-claro mr-2"
                            onClick={() => {
                                this.setOpenModalIngreso(true);
                            }}
                        >
                            Agregar efectivo
                        </button>
                        <button
                            className="btn btn-cerrar mr-2"
                            onClick={() => {
                                this.props.cerrarCaja();
                            }}
                        >
                            Cerrar caja
                        </button>
                    </div>
                    <hr
                        style={{
                            background: "#408EAA",
                            height: "2px",
                            width: "100%",
                        }}
                    />
                    <div
                        className="justy-content-center col-12 mb-3"
                        style={{
                            border: "2px solid #D3D3D3",
                            borderRadius: "10px",
                        }}
                    >
                        <div className="col-12 row py-3 justify-content-center">
                            <div className="col-12 col-md-6 row">
                                <div className="col-12 row">
                                    <div className="col-8 d-flex justify-content-start">
                                        <label> Apertura </label>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <span>
                                            <RenderMoneda
                                                monto={data.historial.apertura}
                                                className="px-0"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 row">
                                    <div className="col-8 d-flex justify-content-start">
                                        <label> Ventas en efectivo </label>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <span>
                                            <RenderMoneda
                                                monto={
                                                    data.historial
                                                        .ventasEfectivo
                                                }
                                                className="px-0"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 row">
                                    <div className="col-8 d-flex justify-content-start">
                                        <label> Ingresos en efectivo </label>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <span>
                                            <RenderMoneda
                                                monto={
                                                    data.historial.totalAgregado
                                                }
                                                className="px-0"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 row">
                                    <div className="col-8 d-flex justify-content-start">
                                        <label> Egresos en efectivo </label>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <span>
                                            <RenderMoneda
                                                monto={
                                                    data.historial.totalRetirado
                                                }
                                                className="px-0"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 row d-flex align-items-center">
                                <div>
                                    <div className="col-12">
                                        <label>TOTAL EFECTIVO EN CAJA</label>
                                        <h5 className="t-primary">
                                            <RenderMoneda
                                                monto={
                                                    data.historial
                                                        .totalEfectivoCaja
                                                }
                                                className="px-0"
                                            />
                                        </h5>
                                    </div>
                                    <div className="col-12">
                                        <label>VENTAS TARJETA DE CREDITO</label>
                                        <h5 className="t-primary">
                                            <RenderMoneda
                                                monto={
                                                    data.historial.ventasTarjeta
                                                }
                                                className="px-0"
                                            />
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5 tabDentroDeTab">
                        <Tabs
                            defaultActiveKey="HISTORIAL_VENTAS"
                            tabBarPoition="top"
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Historial ventas" key="HISTORIAL_VENTAS">
                                <DetalleCajaGrid
                                    data={ventas}
                                    loader={loader}
                                    loaderVentas={this.props.loaderVentas}
                                    anularVenta={this.props.anularVenta}
                                    pageVentas={this.props.pageVentas}
                                    getHistorialVentas={this.props.getHistorialVentas}
                                />
                            </TabPane>
                            <TabPane tab="Historial caja" key="HISTORIAL_CAJA">
                                <HistorialCajaGrid
                                    data={data}
                                    loader={this.props.loaderVentas}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}
