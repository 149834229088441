import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    AsyncSelectField,
    renderCurrency,
    renderTextArea,
} from "../../../Utils/renderField";
import { TipoTransaccion, getsimbolo } from "../../../../../utility/constants";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";

import { api } from "api";

class NotaCredito extends Component {
    // state = {
    //     despacho: null
    // }

    // componentWillMount() {
    //     const { idD } = this.props;
    //     api.get(`despachos/${idD}`).
    //         then((data) => {
    //             if (data) this.setState({despacho: data});
    //         }).catch(() => {
    //         });
    // }

    getOrdenes = (search) => {
        let params = {
            proveedor__id: this.props.idP,
        };
        if (search) {
            params.search = search;
        }
        return api
            .get("ordencompra", params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    // aun no es lo correcto
    getDevoluciones = (search) => {
        let params = {
            tipo_movimiento: 75,
            orden__proveedor: this.props.idP,
            flujo_despacho: 20,
        };
        if (search) {
            params.search = search;
        }
        return api
            .get("despachos", params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    render() {
        const { handleSubmit, titulo_modal, cerrarModal } = this.props;
        return (
            <form
                name="NotaCreditoForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row col-12 m-0 p-0">
                    <div className="form-group has-feedback col-md-6 col-12">
                        <label className="t-azul" htmlFor="fecha">
                            Fecha
                        </label>
                        <Field
                            name="fecha"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="referencia">
                            Referencia Proveedor
                        </label>
                        <Field
                            name="referencia"
                            label="referencia"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row col-12 m-0 p-0">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="orden_origen">
                            Orden de Compra
                        </label>
                        <Field
                            name="orden_origen"
                            label="nombre"
                            component={AsyncSelectField}
                            loadOptions={this.getOrdenes}
                            type="text"
                            valueKey="id"
                            labelKey="no_orden"
                            isClearable={true}
                            className="form-control"
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="devolucion">
                            Devolución
                        </label>
                        <Field
                            name="devolucion"
                            label="nombre"
                            component={AsyncSelectField}
                            loadOptions={this.getDevoluciones}
                            type="text"
                            valueKey="id"
                            labelKey="no_despacho"
                            isClearable={true}
                            className="form-control"
                            defaultValue={this.props.devolucion}
                        />
                    </div>
                </div>
                <div className="row col-12 m-0 p-0">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="monto">
                            Monto
                        </label>
                        <Field
                            name="monto"
                            label="monto"
                            component={renderCurrency}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    />
                </div>
                <div className="row col-12 m-0 p-0">
                    <div className="form-group has-feedback  col-sm-12  col-12">
                        <label className="t-azul" htmlFor="observaciones">
                            Observaciones
                        </label>
                        <Field
                            name="observaciones"
                            label="observaciones"
                            component={renderTextArea}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <button
                                type="button"
                                onClick={cerrarModal}
                                className="btn btn-secondary  align-self-center"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                            >
                                Registrar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const NotaCreditoForm = reduxForm({
    form: "NotaCreditoForm", // a unique identifier for this form
    initialValues: {
        fecha: new Date(),
    },
    validate: (data) => {
        return validate(data, {
            fecha: validators.exists()("Este campo es requerido"),
            referencia: validators.exists()("Este campo es requerido"),
            orden_origen: validators.exists()("Este campo es requerido"),
            monto: validators.exists()("Este campo es requerido"),
        });
    },
})(NotaCredito);

export default NotaCreditoForm;
