import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import ProductosGrid from "./productoGrid";
import FacturasGrid from "./facturasGrid";
// import TransporteDetalle from './transporteDetalle';
// import GastoGrid from './Gastos/gastosGrid';
import PagosContainer from "./Pagos/pagocContainer";
import DespachoContainer from "./Despacho/despachoContainer";
// import ReintegroGrid from './Reintegros/reintegro';
import OCpreview from './previewOC/OVpreview';

import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { TIPO_ENTREGA } from "../../../../../utility/constants";
import LoadMask from "Utils/LoadMask/LoadMask";

export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.props.clear();
        let id = this.props.match.params.id;
        this.props.leerOrdenCompra(id);
    }
    getEntrega = (valor) => {
        let entrega = "";
        TIPO_ENTREGA.forEach((item, index) => {
            if (item.value === valor) {
                entrega = item.label;
            }
        });
        return entrega;
    };
    render() {
        const {
            item,
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            cuentas,
        } = this.props;
        let CPid = this.props.match.params.id;
        console.log(item)
        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {item && item.no_orden && item.proceso_estado === 100 && (
                        <div className="m-t p-t fnt-size">
                            <div
                                className="row m-none"
                                style={{ paddingTop: "15px" }}
                            >
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-titulo m-none">
                                        {item ? item.no_orden : ""}
                                    </h3>
                                </div>
                                <div className="row col-lg-6 col-md-12">
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                Subtotal:
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.subtotal}
                                                    simbolo={getSimbolo(
                                                        item.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                Descuento:
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.descuento}
                                                    simbolo={getSimbolo(
                                                        item.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                IVA:
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.iva}
                                                    simbolo={getSimbolo(
                                                        item.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                Total:
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.monto}
                                                    simbolo={getSimbolo(
                                                        item.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* DATOS GENERALES */}
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        EMITIDA POR:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.emitido_por.first_name} ${item.emitido_por.last_name}`}</span>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 col-12 ">
                                    <span className="m-none t-primary semibold">
                                        AUTORIZADA POR:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.aprobado_por.first_name} ${item.aprobado_por.last_name}`}</span>
                                </div> */}
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        DIRIGIDA A:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.proveedor.nombre}`}</span>
                                </div>
                            </div>
                            <br />
                            {/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    DESCRIPCION
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-texto semibold">{`${item.descripcion}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES DE ENTREGA */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    FECHA Y LUGAR DE ENTREGA
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        FECHA APROXIMADA DE ENTREGA:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">
                                        <RenderDateTime
                                            fecha={
                                                item.fecha_prox_entrega
                                            }
                                        ></RenderDateTime>
                                    </span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        CONDICION DE ENTREGA
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${this.getEntrega(
                                        item.tipo_entrega
                                    )}`}</span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    {item.nombre_transporte && (
                                        <div>
                                            <span className="m-none t-primary semibold">
                                                TRANSPORTE:
                                            </span>
                                            <br />
                                            <span className="m-none t-texto semibold">{`${item.nombre_transporte}`}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        DIRECCION:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.direccion}`}</span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        ENCARGADO:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.encargado}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES COMERCIALES */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    CONDICIONES COMERCIALES
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-sm-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        TIPO DE PAGO:
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${
                                        item.pago_inmediato
                                            ? "Pago Inmediado"
                                            : "Al Credito"
                                    }`}</span>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        MONEDA
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.moneda}`}</span>
                                </div>
                                {item.dias_al_credito > 0 && (
                                    <div className="col-sm-4 col-12">
                                        <span className="m-none t-primary semibold">
                                            DIAS DE CREDITO:
                                        </span>
                                        <br />
                                        <span className="m-none t-texto semibold">{`${item.dias_al_credito}`}</span>
                                    </div>
                                )}
                            </div>
                            <br />
                            {!!item.Ordennits.length && (
                                <div className="row m-none">
                                    <div className=" col-12 justify-content-center">
                                        <span className="m-none t-texto bold">
                                            FACTURAS SOLICITADAS
                                        </span>
                                        <br />
                                        {item.Ordennits.map(
                                            (nit, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="row">
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-primary semibold">
                                                                    NIT:
                                                                </span>
                                                                <br />
                                                                <span className="m-none t-texto semibold">{`${
                                                                    nit.propietario
                                                                        ? nit
                                                                              .propietario
                                                                              .Nit
                                                                        : "--"
                                                                }`}</span>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-primary semibold">
                                                                    NOMBRE;
                                                                </span>
                                                                <br />
                                                                <span className="m-none t-texto semibold">{`${
                                                                    nit.nombre
                                                                        ? nit.nombre
                                                                        : "--"
                                                                }`}</span>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-primary semibold">
                                                                    MONTO:
                                                                </span>
                                                                <br />
                                                                <span className="m-none t-texto semibold">
                                                                    {nit.monto_factura ? (
                                                                        <RenderMoneda
                                                                            monto={
                                                                                nit.monto_factura
                                                                            }
                                                                            simbolo={getSimbolo(
                                                                                this
                                                                                    .props
                                                                                    .moneda
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        "--"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <hr
                                                            style={{
                                                                borderTop:
                                                                    "2px solid rgb(223, 224, 224)",
                                                                margin: "0px",
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                            <br /> {/* DESCRIPCION DE ANULACION */}
                            {!item.estado && (
                                <div>
                                    <div className="col-12">
                                        <span className="m-none t-primary bold">
                                            DESCRIPCIÓN DE ANULACIÓN
                                        </span>
                                    </div>
                                    <hr
                                        style={{
                                            borderTop: "1px solid #00334e",
                                            margin: "0px 15px 10px 15px",
                                        }}
                                    />
                                    <div className="row m-none">
                                        <div className="col-12">
                                            <span className="m-none t-musgo semibold">{`${item.descripcion_anulacion}`}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row m-none">
                                {item.documento && (
                                    <div className="col-6">
                                        <a
                                            target="_blank"
                                            href={item.documento}
                                            className="btn btn-cerrar align-self-center"
                                        >
                                            Documento
                                        </a>
                                    </div>
                                )}
                                {!item.a_produccion && (
                                    <div className="col-6">
                                        <button
                                            className="btn btn-azul-claro align-self-center"
                                            onClick={(e) => {
                                                this.props.AutorizarProduccion(
                                                    item.id
                                                );
                                            }}
                                        >
                                            Autorizar produccción
                                        </button>
                                    </div>
                                )}
                            </div>
                            <br /> {/*  */}
                            <div className="row">
                                <div className="mb-4 col-12">
                                    <div className="mb-4 ">
                                        <div className="">
                                            <h6 className="m-0"></h6>
                                        </div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <Tabs
                                                defaultActiveKey="DETALLEOV"
                                                tabBarPoition="top"
                                                onchange={this.callback}
                                                renderTabBar={() => (
                                                    <ScrollableInkTabBar />
                                                )}
                                                renderTabContent={() => (
                                                    <TabContent />
                                                )}
                                            >
                                                <TabPane
                                                    tab="Productos"
                                                    key="PRODUCTOS"
                                                >
                                                    <ProductosGrid
                                                        detalle_orden={
                                                            item.detalle_orden
                                                        }
                                                        moneda={item.moneda}
                                                    />
                                                </TabPane>
                                                {/* <TabPane tab="COBROS " key="COBROS">
                                                <PagosContainer
                                                    id_cuenta={this.props.match.params.id}
                                                    id_empresa={item.empresa}
                                                    selectCuentas={this.props.selectCuentas}
                                                    {...this.props}
                                                    cuentas={cuentas}
                                                    orden={item}
                                                    pagos_credito={this.props.pagos_credito}
                                                    moneda={item.moneda}
                                                />
                                            </TabPane> */}
                                                <TabPane
                                                    tab="Facturas"
                                                    key="FACTURAS"
                                                >
                                                    <FacturasGrid
                                                        facturas={
                                                            item.facturas
                                                        }
                                                        moneda={item.moneda}
                                                        OCid={CPid}
                                                        BillOC={
                                                            this.props.BillOC
                                                        }
                                                        DeleteOC={
                                                            this.props.DeleteOC
                                                        }
                                                        orden={item}
                                                    />
                                                </TabPane>
                                                {item.detalle_orden
                                                    .length && (
                                                    <TabPane
                                                        tab="Despacho"
                                                        key="DESPACHO"
                                                    >
                                                        <DespachoContainer
                                                            data={
                                                                this.props
                                                                    .history
                                                            }
                                                            HistoryDispatch={
                                                                this.props
                                                                    .HistoryDispatch
                                                            }
                                                            AutorizarDespacho={
                                                                this.props
                                                                    .AutorizarDespacho
                                                            }
                                                            orden={item}
                                                        />
                                                    </TabPane>
                                                )}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {item && item.proceso_estado === 60 && (
                        <OCpreview
                            item={item}
                            loader={loader}
                            id={this.props.match.params.id}
                            refusePM={this.props.refusePV}
                            acceptPM={this.props.acceptPV}
                            clear={this.props.clear}
                        />
                    )}
                </LoadMask>
            </div>
        );
    }
}
