import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";
import { RenderDateTime } from "../../../Utils/renderField/renderReadField";

export default class GridloteInventario extends Component {

    render() {
        const { loader, item, pageLote, listarLote, onPageChange, onSortChange} = this.props;
        return (
            <Fragment>
                <div className="col-12">
                    <h4 className="t-primary">
                        <span className="t-texto">Producto: </span>
                        { item && item.results && item.results[0].nombre }
                    </h4>
                    <br/>
                </div>
                <Grid
                    hover
                    striped
                    data={item}
                    loading={loader}
                    page={pageLote}
                    className="reporte-table"
                    onPageChange={listarLote} onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataFormat={(cell) => {
                            cell ? cell : ''
                            }
                        }
                        width="5%"
                    />
                    <TableHeaderColumn
                        dataField="creado"
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? <RenderDateTime value={cell} /> : "Sin registro"
                        )}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="lote"
                        dataAlign="center"
                        dataFormat={(cell, row) => (
                            cell ? cell : "Sin registro"
                        )}
                    >
                        LOTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="costo_total"
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell) => ( cell  || cell == 0 ? <RenderMoneda monto={cell}/> : "Sin registro" )}
                    >
                        COSTO TOTAL
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }

}
