import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import TipoForm from './TipoProductoForm';

class TipoProductoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'Agregar tipo producto',
    }
    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'Editar tipo producto'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        this.state.editar? editar(id,values) : crear(values);
    }
    render() {
        const { loader } = this.props;
        return (
            <CardFormulario>
                <br/>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <TipoForm onSubmit={this.onSubmit} />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default TipoProductoCrear;
