import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ResetForm from './ResetForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import './reset.css';
import Logo from '../../../../../assets/img/correo.png'

class Reset extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="background-reset align-self-center align-items-center justify-content-center ">
                <div className="login-wrapper-reset col-lg-5 col-md-6 col-12 row "> 
                    <div className="col-12 card-reset-header">
                        <span className="col-4 card-reset-link"> 
                            <Link to="/login"> 
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                &nbsp;&nbsp; Regresar 
                            </Link> 
                        </span>
                        <span className="col-8 card-reset-title"> Recuperar Contraseña </span>
                    </div>
                    <div className="col-12 card card-reset justify-content-center align-self-center">
                        <img className="logo" src={Logo}></img>
                        <h5 className="text-center pv"><bold>RECUPERAR CONTRASEÑA</bold></h5>
                        <p className="text-center">
                            Ingresa tu correo electronico, se te enviara un correo
                            para poder restablecer tu contraseña
                        </p>
                        <LoadMask loading={loader} light >
                            <ResetForm onSubmit={onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reset;
