import React, { Component } from 'react';
import Dashboard from './Dashboard';


export default class Home extends Component {

    render() {
        const { permisos } = this.props;
        if (permisos.dashbord) {
            return (
                <Dashboard {...this.props} />
            )
        } else {
            return (
                <div className="page-header py-4 no-gutters row">
                    <div className="text-sm-left mb-3 text-center text-md-left mb-sm-0 col-12 col-sm-4">
                        <span
                            className="text-uppercase page-subtitle">Escritorio
                        </span>
                        <div>
                            <h3 className="page-title">INICIO </h3>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
