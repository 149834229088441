import React, { useState, Component } from "react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { validate, validators, combine } from "validate-redux-form";
import {
    renderField,
    renderTextArea,
    renderCurrency,
    renderSwitch,
} from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import {
    SelectField,
    AsyncSelectField,
} from "../../../Utils/renderField/renderField.js";
import { formatSelects } from "../../../Utils/renderField/renderReadField";
import { UnidadMedida, TipoUnidades } from "../../../../../utility/constants";
import { api } from "../../../../../utility/api";

let tipoproducto = [];

const getTipoProducto = (search) => {
    return api
        .get("tipoproducto", { search })
        .catch((error) => {})
        .then((data) => {
            if (data) {
                tipoproducto = [];
                data.results.forEach((x) => {
                    tipoproducto.push({ value: x.id, label: x.nombre });
                });
                return tipoproducto;
            } else {
                return [];
            }
        })
        .catch((e) => {
            return [];
        });
};

class ProductoForm extends Component {
    componentDidUpdate(prevProps) {
        const { item } = this.props;
        if (prevProps.item !== item) {
            if (item) {
                this.props.setValores(item.tipo_medida);
            }
        }
    }

    render() {
        const { handleSubmit, empresas, alerta, vendible } = this.props;

        return (
            <form
                name="productoForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <h4 className="t-primary text-center">
                    Información de producto
                </h4>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-10">
                        <label className="t-azul" htmlFor="nombre">
                            Nombre <small>(Campo obligatorio) </small>
                        </label>
                        <Field
                            name="nombre"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-5">
                        <label className="t-azul" htmlFor="codigo">
                            Código <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="codigo"
                            label="codigo"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    {/*                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="precio_venta_interna">Precio venta interna</label>
                                <Field
                                    name="precio_venta_interna"
                                    label="precio_venta_interna"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control" />
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-md-6  col-12">
                        <label className="t-azul" htmlFor="empresa">Empresa</label>
                        <Field
                            name="empresa"
                            label="empresa"
                            valueKey="value"
                            labelKey="label"
                            options={empresas}
                            component={SelectField}
                        />
                    </div> */}
                    <div className="form-group has-feedback  col-md-5  col-12">
                        <label className="t-azul" htmlFor="tipo_producto">
                            Tipo producto <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="tipo_producto"
                            valueKey="value"
                            labelKey="label"
                            loadOptions={getTipoProducto}
                            component={AsyncSelectField}
                        />
                    </div>
                </div>
                <div className="row offset-md-1">
                    <div className="form-group has-feedback col-md-4 col-lg-1">
                        <label className="t-azul" htmlFor="alerta">
                            Alerta
                        </label>
                        <Field
                            name="alerta"
                            label=""
                            value="default"
                            component={renderSwitch}
                        />
                    </div>
                    {alerta && (
                        <div className="form-group has-feedback col-md-4">
                            <label
                                className="t-azul"
                                htmlFor="minimo_existencias"
                            >
                                Mínimo existencias
                                <small> (Campo obligatorio) </small>
                            </label>
                            <Field
                                name="minimo_existencias"
                                label="minimo_existencias"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    )}
                </div>
                <div className="row offset-md-1">
                    <div className="form-group has-feedback col-md-4 col-lg-1">
                        <label className="t-azul" htmlFor="vendible">
                            Vendible
                        </label>
                        <Field
                            name="vendible"
                            label=""
                            value="default"
                            component={renderSwitch}
                        />
                    </div>
                    {vendible && (
                        <div className="form-group has-feedback col-md-4">
                            <label className="t-azul" htmlFor="precio_venta">
                                Precio venta (Fabrica)
                                <small> (Campo obligatorio) </small>
                            </label>
                            <Field
                                name="precio_venta"
                                label="precio_venta"
                                component={renderCurrency}
                                type="number"
                                className="form-control"
                            />
                        </div>
                    )}
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-5 col-lg-5">
                        <label className="t-azul" htmlFor="unidad_medida">
                            Unidad Recepción
                            <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="unidad_recepcion"
                            label="unidad_recepcion"
                            valueKey="value"
                            labelKey="label"
                            options={UnidadMedida}
                            component={SelectField}
                        />
                    </div>
                    <div className="form-group has-feedback col-md-5 col-lg-5">
                        <label className="t-azul" htmlFor="conversion">
                            Conversión <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="conversion"
                            label="conversion"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-5 col-lg-5">
                        <label className="t-azul" htmlFor="tipo_medida">
                            Tipo de medida <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="tipo_medida"
                            label="tipo_medida"
                            component={SelectField}
                            onChangeEditado={(e) => {
                                this.props.onChangeTipoUnidad(e);
                            }}
                            options={TipoUnidades}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-5 col-lg-5">
                        <label className="t-azul" htmlFor="unidad_medida">
                            Unidad inventario
                            <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="unidad_inventario"
                            label="unidad_inventario"
                            valueKey="value"
                            labelKey="label"
                            options={this.props.arr_medidas}
                            component={SelectField}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-sm-10  col-12">
                        <label className="t-azul" htmlFor="descripcion">
                            Descripción <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            component={renderTextArea}
                            top={{ top: "95px" }}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to="/productos"
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        );
    }
}

ProductoForm = reduxForm({
    form: "productoForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {}
        if(!data.nombre){
            errors.nombre = "Este campo es requerido"
        }
        if(!data.descripcion){
            errors.descripcion = "Este campo es requerido"
        }
        if(!data.codigo){
            errors.codigo = "Este campo es requerido"
        }
        if(!data.precio_venta){
            errors.precio_venta = "Este campo es requerido"
        }
        if(!data.minimo_existencias){
            errors.minimo_existencias = "Este campo es requerido"
        }
        if(!data.tipo_producto){
            errors.tipo_producto = "Este campo es requerido"
        }
        if(!data.unidad_medida){
            errors.unidad_medida = "Este campo es requerido"
        }
        if(!data.unidad_recepcion){
            errors.unidad_recepcion = "Este campo es requerido"
        }
        if(!data.conversion){
            errors.conversion = "Este campo es requerido"
        } else if( data.conversion < 0){
            errors.conversion =  "No puede ser negativo"
        }
        if(!data.tipo_medida){
            errors.tipo_medida = "Este campo es requerido"
        }
        if(!data.unidad_inventario){
            errors.unidad_inventario = "Este campo es requerido"
        }
        return errors;
    },
})(ProductoForm);

const selector = formValueSelector("productoForm");
ProductoForm = connect((state) => {
    const alerta = selector(state, "alerta");
    const vendible = selector(state, "vendible");
    return {
        alerta,
        vendible,
    };
})(ProductoForm);
export default ProductoForm;
