import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'asignacion' ;
let form = 'AsignacionForm';
let dirGrid = '/asignacion_actividades';

export const constants ={
    LOADER: 'LOADER_ASIGNACION',
    DATA: 'DATA_ASIGNACION',
    ITEM: 'ITEM_ASIGNACION',
    PAGE: `PAGE_ASIGNACION`,
    ORDERING: `ORDERING_ASIGNACION`,
    SEARCH: `SEARCH_ASIGNACION`,
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().asignacionActividades;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().asignacionActividades.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: ''
};

export default handleActions(reducers, initialState);
