import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change, reset } from "redux-form";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { values, constant } from "lodash";
import { getsimbolo } from "../../../utility/constants";

let url = "puntoVentaHB";
let form = "";
let dirGrid = "/punto_venta";

export const constants = {
    LOADER: "LOADER_PUNTO_VENTA",
    LOADER_PRODUCTOS: "LOADER_PRODUCTOS",
    LOADER_VENTAS: "LOADER_VENTAS",
    DATA: "DATA_PUNTO_VENTA",
    ITEM: "ITEM_PUNTO_VENTA",
    CAJA: "CAJA_PUNTO_VEMTA",
    PAGE: `PAGE_PUNTO_VENTA`,
    PAGE_VENTAS: "PAGE_VENTAS",
    ORDERING: `ORDERING_PUNTO_VENTA`,
    SEARCH: `SEARCH_PUNTO_VENTA`,
    COMPRA: "COMPRA",
    CARRITO: "CARRITO_PUNTO_VENTA",
    TICKET_VENTA: "TICKET_VENTA",
    HISTORIAL_VENTAS: "HISTORIAL_VENTAS",
    DETALLE_VENTA: "DETALLE_VENTA",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setLoaderProductos = (loaderProductos) => ({
    type: constants.LOADER_PRODUCTOS,
    loaderProductos,
});

const setLoaderVentas = (loaderVentas) => ({
    type: constants.LOADER_VENTAS,
    loaderVentas,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const setCaja = (caja) => ({
    type: constants.CAJA,
    caja,
});

const setFinalizarCompra = (compra) => ({
    type: constants.COMPRA,
    compra,
});
const setCarrito = (carrito) => ({
    type: constants.CARRITO,
    carrito,
});

const listHistorialVentas = (ventas) => ({
    type: constants.HISTORIAL_VENTAS,
    ventas,
});

const setDetalleVenta = (detalleVenta) => ({
    type: constants.DETALLE_VENTA,
    detalleVenta,
});

const setPageVentas = (pageVentas) => ({
    type: constants.PAGE_VENTAS,
    pageVentas,
});

const cajasDisponibles = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${url}/cajasDisponibles`)
        .then((response) => {
            if (response && response.mostradorsucursalhb_set) {
                response.results = [{ ...response }];
            }
            dispatch(setData(constants.DATA, response));
            //dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al obtener las cajas","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const retiroDeCajaSucursal = (data) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    let sucursal = store.caja.sucursal;
    dispatch(setLoader(true));
    api.post(`${url}/${id_caja}/retirardecaja`, data)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(getCaja(sucursal));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la transacción","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const agregarACajaSucursal = (data) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    let sucursal = store.caja.sucursal;
    dispatch(setLoader(true));
    api.post(`${url}/${id_caja}/moveracaja`, data)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(getCaja(sucursal));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la transacción","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setModalDescripcion = (estado) => (dispatch) => {
    dispatch({
        type: constants.SET_MODAL,
        data: estado,
    });
};

const getCaja = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/getCaja`)
        .then((response) => {
            dispatch(setCaja(response));
            dispatch(getCarrito(response.id));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const abrirCaja = (data) => (dispatch) => {
    dispatch(setLoader(true));
    let total =
        parseInt(data.cantidadBillete200) * 200 +
        parseInt(data.cantidadBillete100) * 100 +
        parseInt(data.cantidadBillete50) * 50 +
        parseInt(data.cantidadBillete20) * 20 +
        parseInt(data.cantidadBillete10) * 10 +
        parseInt(data.cantidadBillete5) * 5 +
        parseInt(data.cantidadBillete1) +
        parseInt(data.cantidadMoneda1) +
        parseInt(data.cantidadMoneda50) * 0.5 +
        parseInt(data.cantidadMoneda25) * 0.25 +
        parseInt(data.cantidadMoneda10) * 0.1 +
        parseInt(data.cantidadMoneda5) * 0.05;
    data.saldo_inicial = total;
    api.post(`${url}/abrirCaja`, data)
        .then((data) => {
            NotificationManager.success("Caja abierta", "Éxito", 3000);
            if ("abrirCajaForm") dispatch(reset("abrirCajaForm"));
            dispatch(getCaja());
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al abrir la caja", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const cerrarCaja = (data, funcion) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let total =
        parseInt(data.cantidadBillete200) * 200 +
        parseInt(data.cantidadBillete100) * 100 +
        parseInt(data.cantidadBillete50) * 50 +
        parseInt(data.cantidadBillete20) * 20 +
        parseInt(data.cantidadBillete10) * 10 +
        parseInt(data.cantidadBillete5) * 5 +
        parseInt(data.cantidadBillete1) +
        parseInt(data.cantidadMoneda1) +
        parseInt(data.cantidadMoneda50) * 0.5 +
        parseInt(data.cantidadMoneda25) * 0.25 +
        parseInt(data.cantidadMoneda10) * 0.1 +
        parseInt(data.cantidadMoneda5) * 0.05;
    data.saldoCierre = total;
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    data.id_caja = id_caja;
    api.post(`${url}/cierreCaja`, data)
        .then((data) => {
            NotificationManager.success("Caja Cerrada", "Éxito", 3000);
            funcion();
            if ("abrirCajaForm") dispatch(reset("abrirCajaForm"));
            dispatch(getCaja());
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al cerrar la caja", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const getCarrito = (id) => (dispatch) => {
    dispatch(setLoader(true));
    let data = {
        id,
    };
    api.get(`${url}/${id}/getCarrito`, data)
        .then((data) => {
            dispatch(setCarrito(data));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error en el carrito", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const getInventario = (page = 1, bodega) => (dispatch, getStore) => {
    dispatch(setLoaderProductos(true));
    const resource = getStore().puntoVenta;
    let data = {
        page,
        bodega: resource.caja.bodega.id,
        search: resource.search,
    };
    api.get(`${url}/getInventario/${data.bodega}`, data)
        .then((data) => {
            dispatch(setData(constants.DATA, data));
            dispatch(setPage(page));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error en el carrito", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoaderProductos(false));
        });
};

const agregarACarrito = (values) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    dispatch(setLoader(true));
    let data = {
        producto: values.producto.id,
        cantidad: values.cantidad,
        bodega: store.caja.bodega.id,
        presentacion: values.presentacion && values.presentacion.id
    };
    api.post(`${url}/${id_caja}/agregarACarrito`, data)
        .then((data) => {
            dispatch(getCarrito(id_caja));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al agregar a carrito", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminarDeCarrito = (values) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    dispatch(setLoader(true));
    let data = {
        producto: values.producto.id,
        cantidad: values.cantidad,
        presentacion: values.presentacion.id,
    };
    console.log(values)
    api.post(`${url}/${id_caja}/eliminarDeCarrito`, data)
        .then((data) => {
            dispatch(getCarrito(id_caja));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al eliminar de carrito", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changePresentacion = (values) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    dispatch(setLoader(true));
    let data = {
        id_detalle: values.id_detalle,
        bodega: store.caja.bodega.id,
        presentacion: values.presentacion,
    };
    console.log(values)
    api.post(`${url}/${id_caja}/cambiarPresentacion`, data)
        .then((data) => {
            dispatch(getCarrito(id_caja));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al eliminar de carrito", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const cancelarVenta = () => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    dispatch(setLoader(true));
    api.post(`${url}/${id_caja}/cancelarVenta`)
        .then((data) => {
            dispatch(getCarrito(id_caja));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al cancelar venta", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const FinalizarCompra = (valor) => (dispatch) => {
    if (valor == false) dispatch(setTicketVenta(undefined));
    dispatch(setFinalizarCompra(valor));
};

const setTicketVenta = (ticket) => ({
    type: constants.TICKET_VENTA,
    ticket,
});

const FacturarCompra = (data) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    data.bodega = store.caja.bodega.id;
    data.sucursal = store.caja.sucursal;
    dispatch(setLoader(true));
    api.post(`${url}/${id_caja}/finalizarCompra`, data)
        .then((data) => {
            NotificationManager.success("Compra procesada con exito!", "ERROR");
            dispatch(getCaja());
            dispatch(getCarrito(id_caja));
            //dispatch(FinalizarCompra(false));
            dispatch(setTicketVenta(data.documento));
            dispatch(getHistorialVentas(store.pageVentas));
        })
        .catch((e) => {
            if (e && e.detail) {    
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Error al procesar la venta",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getHistorialVentas = (page) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    let data = { page };
    dispatch(setLoaderVentas(true));
    api.get(`${url}/${id_caja}/historialVenta`, data)
        .then((response) => {
            dispatch(setPageVentas(page));
            dispatch(listHistorialVentas(response));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Error al obtener el historial de ventas",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderVentas(false));
        });
};

const getDetalleVenta = (id) => (dispatch) => {
    dispatch(getCaja());
    dispatch(getDetalleRealVenta(id));
};

const getDetalleRealVenta = (id) => (dispatch, getStore) => {
    let params = { id };
    dispatch(setLoader(true));
    api.get(`${url}/getDetalleVenta`, params)
        .then((data) => {
            dispatch(setDetalleVenta(data));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Error al obtener el detalle de la venta",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const anularVenta = (data) => (dispatch, getStore) => {
    let store = getStore().puntoVenta;
    let id_caja = store.caja.id;
    console.log(data);
    dispatch(setLoader(true));
    api.post(`${url}/${id_caja}/anularVenta`, data)
        .then((data) => {
            NotificationManager.success("Venta anulada", "EXITO");
            dispatch(getInventario())
            dispatch(getCaja());
            dispatch(getHistorialVentas(store.pageVentas));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Error al anular la venta", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search, bodega) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getInventario(1, bodega));
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().puntoVenta.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(getInventario());
};

const mensajeCajaAbierta = () => () => {
    NotificationManager.error(
        "No se puede seleccionar una caja abierta",
        "ERROR",
        3000
    );
};

export const actions = {
    getCaja,
    abrirCaja,
    getCarrito,
    cancelarVenta,
    cerrarCaja,
    agregarACarrito,
    getHistorialVentas,
    getDetalleVenta,
    anularVenta,
    eliminarDeCarrito,
    getInventario,
    searchChange,
    onSortChange,
    cajasDisponibles,
    changePresentacion,
    mensajeCajaAbierta,
    retiroDeCajaSucursal,
    agregarACajaSucursal,
    FinalizarCompra,
    FacturarCompra,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.LOADER_PRODUCTOS]: (state, { loaderProductos }) => {
        return {
            ...state,
            loaderProductos,
        };
    },
    [constants.LOADER_VENTAS]: (state, { loaderVentas }) => {
        return {
            ...state,
            loaderVentas,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.COMPRA]: (state, { compra }) => {
        return {
            ...state,
            compra,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE_VENTAS]: (state, { pageVentas }) => {
        return {
            ...state,
            pageVentas,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.CAJA]: (state, { caja }) => {
        return {
            ...state,
            caja,
        };
    },
    [constants.CARRITO]: (state, { carrito }) => {
        return {
            ...state,
            carrito,
        };
    },
    [constants.TICKET_VENTA]: (state, { ticket }) => {
        return {
            ...state,
            ticket,
        };
    },
    [constants.HISTORIAL_VENTAS]: (state, { ventas }) => {
        return {
            ...state,
            ventas,
        };
    },
    [constants.DETALLE_VENTA]: (state, { detalleVenta }) => {
        return {
            ...state,
            detalleVenta,
        };
    },
};

export const initialState = {
    loader: false,
    loaderProductos: false,
    loaderVentas: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    pageVentas: 1,
    ordering: "",
    search: "",
    caja: undefined,
    compra: undefined,
    ventas: {
        count: 0,
        next: undefined,
        previous: undefined,
        results: [],
    },
    detalleVenta: {},
    carrito: undefined,
    ticket: undefined,
};

export default handleActions(reducers, initialState);
