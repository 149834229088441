import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { getsimbolo } from "../../../../../utility/constants";

export default class EmpresaGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        if (permisos["bancos"] === false) {
            if (!!permisos[`ver_bancos`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <div
                className="Container shadow bg-white mt-4"
                style={{ borderRadius: "10px" }}
            >
                <br />
                <br />
                <div className="row justify-content-end">
                    <div className="col-12">
                        <ToolbarSimple
                            textBoton="Agregar"
                            ruta="/cuenta/nueva"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={
                                "Buscar por: Propietario, Banco, Cuenta "
                            }
                            permisos={permisos.bancos}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                >
                                    {permisos.bancos && (
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataSort
                                            dataFormat={standardActions({
                                                editar: "cuenta",
                                                transac: "transaccion",
                                                eliminar,
                                            })}
                                        ></TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        isKey
                                        dataField="propietario"
                                        dataSort
                                    >
                                        NOMBRE DE LA CUENTA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre_banco"
                                        dataSort
                                    >
                                        BANCO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="numero_cuenta"
                                        dataSort
                                    >
                                        NO. DE CUENTA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="saldo_a"
                                        dataSort
                                        headerAlign="center"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => (
                                            <RenderMoneda
                                                monto={cell + row.saldo_reserva}
                                                simbolo={getsimbolo(row.moneda)}
                                            />
                                        )}
                                    >
                                        SALDO ACTUAL
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="saldo_a"
                                        dataSort
                                        headerAlign="center"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => (
                                            <RenderMoneda
                                                monto={cell}
                                                simbolo={getsimbolo(row.moneda)}
                                            />
                                        )}
                                    >
                                        SALDO DISPONIBLE
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
