import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/deuda';

import DetalleDeuda from './deudaDetalleEmpresa';

const mstp = state => {
    return {
        ...state.Reportes,
        permisos: state.login.me.permisos,
        usuario: state.login.me.id,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleDeuda)
