import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { iconos_slide } from '../../../../utility/icons';
import ImageDashbordA from '../../../../../assets/img/icons/Dashboard1.png';
import ImageAdminA from '../../../../../assets/img/icons/Admin1.png';
import ImageCajaA from '../../../../../assets/img/icons/Caja1.png'
import ImageCuentasA from '../../../../../assets/img/icons/Cuentas1.png'
import ImageBodegaD from '../../../../../assets/img/icons/Bodega.png';
import OrdenCompraA from '../../../../../assets/img/icons/oc1.png';
import OrdenVentaD from '../../../../../assets/img/icons/ov.png';
import ReporteD from '../../../../../assets/img/icons/reporte.png';
import PlanillaA from '../../../../../assets/img/icons/planilla1.png';
import PuntoVenta from '../../../../../assets/img/icons/Punto de venta.png';
import notificacionesD from '../../../../../assets/img/icons/notifiacion.png';
import GastosD from '../../../../../assets/img/icons/gastos.png';
import GastosA from '../../../../../assets/img/icons/gasto1.png'

class SideBar extends Component {
    constructor(props) {
        super(props);
    }
    getPermisosReportes = () => {
        const {permisos} = this.props
        if (permisos.bitacora || permisos.deuda || permisos.cuentasc || permisos.cuentasp || permisos.ingresoegreso )
            return true
        else
            return false
    }
    gerPermisosAdmin = () =>{
        const {permisos} = this.props
        if (permisos.usuarios || permisos.empresas || permisos.vendedores || permisos.clientes  || permisos.proveedores ||
            permisos.productos || permisos.tproducto || permisos.categiagastos || permisos.puestos || permisos.regiones || permisos.roles ||
            permisos.ver_usuarios || permisos.ver_empresas || permisos.ver_vendedores || permisos.ver_clientes  || permisos.ver_proveedores ||
            permisos.ver_productos || permisos.ver_tproducto || permisos.ver_categiagastos || permisos.ver_puestos || permisos.ver_regiones || permisos.ver_roles ){
                return true
            }
        else{ return false}
    }
    render() {
        const { toggleOpen, navToggle, logOut, permisos } = this.props;
        return (
            <aside className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen?'':'open'}`}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light"
                        style= {{ height:"8rem" }}>
                        <a  href="#" className="w-100 mr-0 navbar-brand" >
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require('assets/img/logoN.png')}
                                    alt="Logo" />
                            </div>
                        </a>
                        <a  className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <Accordion  allowZeroExpanded={true}>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <NavLink to="/" exact className="nav-link " activeClassName={'active'}>
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <img className="dashbord-img" src={ImageDashbordA}></img>
                                                        <span className="bold-sidebar">Dashboard</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ImageCajaA: ImageCajaA}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Caja</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {permisos.cajaschicas && (<div className="sub-nav-item">
                                                    <NavLink exact to="/cajas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Cajas chicas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                <div className="sub-nav-item">
                                                    <NavLink exact to={`/caja/${this.props.caja}`} activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Mi Caja</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <NavLink to="/notificaciones" exact style={{textDecoration:'None'}}>
                                                    <div className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? notificacionesD: notificacionesD}></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>Notificaciones</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                               permisos.gastos && <NavLink exact to="/gastos" style={{textDecoration:'None'}}>
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? GastosD: GastosD} />
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>Gastos</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                permisos.bodegas && <NavLink exact to="/bodegas" style={{textDecoration:'None'}}>
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? ImageBodegaD: ImageBodegaD} />
                                                        <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Bodegas</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                               (permisos.ordencompra || permisos.peticionmateriales) && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? OrdenCompraA: OrdenCompraA}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>O.C.</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {permisos.ordencompra && (<div className="sub-nav-item">
                                                    <NavLink exact to="/ordencompras" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Orden C.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                { (permisos.ordencompra || permisos.peticionmateriales) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/peticiones" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Peticion M.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                               (permisos.ordencompra || permisos.peticionmateriales) && <NavLink exact to="/punto_venta" style={{textDecoration:'None'}}>
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? PuntoVenta: PuntoVenta} />
                                                        <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Punto de venta</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.ordenventa || permisos.peticionventa) && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? OrdenVentaD: OrdenVentaD}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>O.V.</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {permisos.ordenventa &&(<div className="sub-nav-item">
                                                    <NavLink exact to="/ordenesventa" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Orden V.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                                </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                this.getPermisosReportes() && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ReporteD: ReporteD}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Reportes</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                            <div className="sub-nav-item">
                                            {permisos.bitacora && 
                                                <div>
                                                    <NavLink exact to="/reporte_bitacoras" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Bitácora</span>
                                                        </div>
                                                    </NavLink>
                                                </div>}
                                                <div>
                                                    <NavLink exact to="/reporte_ventas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Ventas</span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                                <div>
                                                    <NavLink exact to="/reporte_productividad" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Productividad</span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                            <div>
                                                    <NavLink exact to="/reporte_materia_prima" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Materia prima</span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                            <div>
                                                    <NavLink exact to="/costos_inventario" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Costos de Inventario</span>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                <div>
                                                    <NavLink exact to="/reporte_costovsventas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Costo de Producto vs Ventas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                            <div>
                                                    <NavLink exact to="/reporte_existencias" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Existencias en bodega</span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                            <div>
                                                    <NavLink exact to="/reporte_rotacion_inventario" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Rotación de Inventario</span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                            <div>
                                                    <NavLink exact to="/reporte_historico_cajas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}> Cajas de venta </span>
                                                        </div>
                                                    </NavLink>
                                            </div>
                                            {permisos.cuentasp &&
                                                <div>
                                                    <NavLink exact to="/cuentas_por_pagar" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Cuentas por Pagar</span>
                                                        </div>
                                                    </NavLink>
                                                </div>}
                                            {permisos.cuentasc && 
                                                <div>
                                                    <NavLink exact to="/cuentas_por_cobrar" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Cuentas por Cobrar</span>
                                                        </div>
                                                    </NavLink>
                                                </div>}
                                            </div>
                                        </AccordionItemPanel>
                                    )
                                }
                                </AccordionItemState>

                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                permisos.bancos && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ImageCuentasA: ImageCuentasA}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Cuentas</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/cuentas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Bancos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.bancos || permisos.ver_orden_produccion) && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? iconos_slide.produccion_inactivo: iconos_slide.produccion_inactivo}></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Producción</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/produccion" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Ordenes producción</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                permisos.bancos && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? PlanillaA:PlanillaA }></img>
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `}>Planilla</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.puestos || permisos.ver_puestos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/puestos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Puestos de trabajo</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.puestos || true)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/bonificaciones" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Bonificaciones</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.puestos || true)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/empleados" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Empleados</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                 {(permisos.puestos || true)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/planilla" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Pago de Planilla</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState caret>
                                            {({ expanded }) => (
                                                this.gerPermisosAdmin() && <div className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="dashbord-img" src={expanded ? ImageAdminA: ImageAdminA} />
                                                    <span className={expanded ? 't-blanco bold-sidebar': `bold-sidebar `} >Admin</span>
                                                    <span className="caret">
                                                    {expanded
                                                        ? <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                                        : <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                    }
                                                    </span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.usuarios || permisos.ver_usuarios) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/usuarios" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-blanco"}>Usuarios</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.vendedores || permisos.ver_vendedores) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/vendedores" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-blanco"}>Vendedores</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.clientes || permisos.ver_clientes)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/clientes" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper ">
                                                            <span className={"t-blanco"}>Clientes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.proveedores || permisos.ver_proveedores)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/proveedores" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper ">
                                                            <span className={"t-blanco"}>Proveedores</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {<div className="sub-nav-item">
                                                    <NavLink exact to="/sucursales" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Sucursales</span>
                                                        </div>
                                                    </NavLink>
                                                </div>}
                                                {(permisos.productos || permisos.ver_productos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/productos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Productos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.tproducto || permisos.ver_tproducto)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/tipoproductos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Tipo productos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.roles || permisos.ver_roles)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/roles" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Roles</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.categiagastos || permisos.ver_categiagastos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/admin_tipocategoria" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-blanco"}>Categorias Gastos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                    </Accordion>
                </div>
            </aside>
        )
    }
}

export default SideBar;
