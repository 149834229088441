import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import CardPvEnviadas from "../../../Utils/Cards/cardsPM";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Card } from "antd";
import { getsimbolo } from "../../../../../utility/constants";
import { Redirect } from "react-router-dom";
import FiltrosOrdenVenta from "./filtrosOrdeVenta";
import Tiempo from "../../../../../../assets/img/icons/Tiempo.png";
import Check from "../../../../../../assets/img/icons/Check.png";

export default class OrdenVentaGrid extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
            entregadoChange,
            pagoChange,
        } = this.props;
        if (permisos["ordenventa"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple
                margen=" "
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="/ordenesventa/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                        permisos={true}
                        estatefilterBar={true}
                        filterbar={
                            <FiltrosOrdenVenta
                                valorEntrega={this.props.entregado}
                                valorPagado={this.props.pago_completo}
                                changeEntrega={entregadoChange}
                                changePago={pagoChange}
                            />
                        }
                    />
                }
            >
                <br />
                <Grid
                    page={this.props.page}
                    hover
                    striped
                    data={data}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={standardActions({
                            eliminar,
                            verpro: "ordenesventa",
                            editar: "ordenesventa",
                        })}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={(cell, row) => {
                            return <RenderDateTime fecha={cell} />;
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="no_orden" dataSort>
                        NO. ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        dataSort
                        dataFormat={(cell, row) => (cell ? cell.nombre : "--")}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                monto={row.monto ? cell : 0}
                                simbolo={getsimbolo(row.moneda)}
                            />
                        )}
                    >
                        MONTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pago_completo"
                        dataSort
                        dataAlign="center"
                        // tdStyle={cellTachado}
                        dataFormat={(cell, row) => {
                            if (cell) {
                                return <img src={Check} width="20" />;
                            } else {
                                return <img src={Tiempo} width="20" />;
                            }
                        }}
                    >
                        PAGADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="entregado"
                        dataSort
                        dataAlign="center"
                        // tdStyle={cellTachado}
                        dataFormat={(cell, row) => {
                            if (cell) {
                                return <img src={Check} width="20" />;
                            } else {
                                return <img src={Tiempo} width="20" />;
                            }
                        }}
                    >
                        DESPACHADO
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
