import { connect } from "react-redux";
import { actions } from "../../../../../../redux/modules/gastos/gastos";
import IngresoCajaForm from "./ingresoForm";

const mstp = (state) => {
    return { ...state.gastos };
};

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(IngresoCajaForm);
