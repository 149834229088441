import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Modal from 'react-responsive-modal';
// import imglogo from '../../../../../../../assets/img/logo.png'
import PDFViewer from 'pdf-viewer-reactjs'

import RechazoForm from './rechazoForm'
import AprobacionForm from './aprobacionForm';


export default class OCpreview extends Component{
    state = {
        open_modal:false,
        open_modal_aprobacion:false,
    }
    componentDidMount(){
        this.props.clear()
        let id = this.props.match.params.id
        this.props.leer(id);

    }
    handleModal = (value) =>{
        this.setState({open_modal:value})
    }
    handleModalAprobar = (value) =>{
        this.setState({open_modal_aprobacion:value})
    }
    handleSubmit = (values) =>{
        values.id =  this.props.match.params.id
        values.mensaje = "Peticion de Venta rechazada"
        this.props.refusePV(values);
    }
    handleAprobar = (values) =>{
        values.id = this.props.match.params.id
        this.props.acceptPV(values);
        this.setState({open_modal_aprobacion:false})
    }
    render(){
        let { item, loader } = this.props
        return(
            <CardSimple
                titulo={`Peticion de venta pendiente NO. ${item.no_orden}`}
            >

                <div>
                    <Modal open={this.state.open_modal} onClose={(e)=>{
                            this.setState({open_modal:false})
                        }} center 
                    >
                        <div className="mt-5">
                            <h2 className="text-center text-primary">JUSTIFICACIÓN DE RECHAZO</h2>
                        </div>
                        {
                            (item.proceso_estado!="Rechazado")?(
                                <RechazoForm
                                onSubmit={this.handleSubmit}
                                handleModal={this.handleModal}
                                />
                            ):(
                                <div className="form-validate mb-lg text-center" >
                                    <span style={{fontSize: 20}}>{"Esta Peticion OC ya fue rechazada"}</span>
                                </div>
                            )
                        }
                    </Modal>
                    <Modal open={this.state.open_modal_aprobacion} onClose={(e)=>{
                            this.setState({open_modal_aprobacion:false})
                        }} center 
                    >
                        <div className="row">
                            <div className="mt-5 col-12">
                                <h2 className="text-center text-primary">OPCIONES DE APROBACION</h2>
                                <br/>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <AprobacionForm
                                    onSubmit={this.handleAprobar}
                                    handleModalAprobar={this.handleModalAprobar}
                                />
                            </div>
                        </div>
                        
                            
                        
                    </Modal>
                    <LoadMask loading={loader} light type={"TailSpin"}>
                        {
                            item.documento != null?
                            (
                                <div className="">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                                                <div className="col-lg-3 col-sm-6 col-12 d-flex justify-content-center">
                                                    <button onClick={()=>this.handleModal(true)} className="btn btn-secondary m-1 align-self-center">RECHAZAR</button>
                                                </div>
                                                {
                                                   (item.monto > 0) && (
                                                        <div className="col-lg-3 col-sm-6 col-12 d-flex justify-content-center">
                                                            <button onClick={()=>this.handleModalAprobar(true)} className="btn btn-primary m-1 align-self-center">APROBACION</button>
                                                        </div>
                                                    )
                                                }
                                                <div className="col-lg-3 col-sm-6 col-12 d-flex justify-content-center">
                                                    <Link className="btn btn-mostaza m-1 align-self-center" to={`/peticionesventa/${item.id}`}>EDITAR</Link>
                                                </div>
                                                <div className="col-lg-3 col-sm-6 col-12 d-flex justify-content-center">
                                                    <a target="_blank" href={item.documento} download={`${item.no_orden}`} className="btn btn-azul-claro m-1 align-self-center">IMPRIMIR</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br/>
                                    <div className="prueba">
                                        <PDFViewer
                                            navbarOnTop={true}
                                            hideRotation={false}
                                            document={{
                                                url: `${item.documento}`
                                            }}
                                        />

                                    </div>
                                </div>
                            ):(
                                <div className="t-mostaza align-self-center">{"Documento no disponible para mostrar"}</div>
                            )
                        }
                    </LoadMask>
                </div>
            </CardSimple>
        )
    }
}