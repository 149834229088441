import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Planilla/planilla';
import PlanillaGridTab from './PlanillaTab';

const mstp = state => {
    return {...state.planilla , permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PlanillaGridTab)
