import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import {SelectField, AsyncSelectField} from '../../Utils/renderField/renderField.js'
import { Field } from 'redux-form';
import { SingleDatePicker } from 'react-dates'
import classNames from 'classnames';
import { api } from 'api';


export default class ToolbarReporteRotacionInventario extends Component {
    state = {
        focusedStart: false,
        focusedEnd: false,
    }

    getBodegas = (search) => {
        let params = { es_cliente: false }
        if(search){
            params.search = search;
        }
        return api.get("bodega", params).
        then((data) => {
            if (data) {
                if(!this.props.bodega){
                    this.props.changeBodega(data.results[0])
                }
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
    };

    getProductos = (search) => {
        return api.get("productos", { search }).
        then((data) => {
            if (data){
                if(!this.props.producto){
                    this.props.changeProducto(data.results[0])
                }
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
    };


    render() {
        const { bodega, fecha_inicial, fecha_final, producto } = this.props;
        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left">
                    <div className={"col-lg-3 col-md-4 col-12 align-self-center"}>
                        <span className="t-texto font-weight-bold">Fecha Inicial</span>
                        <SingleDatePicker
                            // className="form-control"
                            placeholder={"Fecha Inicio"}
                            date={fecha_inicial ? fecha_inicial : null}
                            focused={this.state.focusedStart}
                            isOutsideRange={() => false}
                            onDateChange={(value) => {
                                this.props.changeFechaInicial(value)
                            }}
                            onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                            numberOfMonths={1}
                            id={"dateStart"}
                            readOnly={true}
                        />
                    </div>
                    <div className={"col-lg-3 col-md-4 col-12 align-self-center"}>
                        <span className="t-texto font-weight-bold">Fecha Final</span>
                        <SingleDatePicker
                            // className="form-control"
                            placeholder={"Fecha Final"}
                            date={fecha_final ? fecha_final : null}
                            focused={this.state.focusedEnd}
                            isOutsideRange={() => false}
                            onDateChange={(value) => {
                                this.props.changeFechaFinal(value)
                            }}
                            onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                            numberOfMonths={1}
                            id={"dateEnd"}
                            readOnly={true}
                        />
                    </div>
                    <div className={"col-lg-3 col-md-4 col-12 align-self-center"}>
                        <span className="t-texto font-weight-bold">Bodega</span>
                        <Async
                            //isClearable={true}
                            backspaceRemovesValue={false}
                            value={bodega}
                            isSearchable={true}
                            loadOptions={this.getBodegas}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeBodega(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Seleccionar.."
                        />
                    </div>
                    <div className={"col-lg-3 col-md-4 col-12 align-self-center"}>
                        <span className="t-texto font-weight-bold">Producto</span>
                        <Async
                            //isClearable={true}
                            backspaceRemovesValue={false}
                            value={producto}
                            isSearchable={true}
                            loadOptions={this.getProductos}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeProducto(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Seleccionar.."
                        />
                    </div>
                </div>
            </div>
        )
    }
}
