import React, { Component } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

import Fcm from "../../Fcm/FcmContainer";

const defaultAvatar = require("assets/img/avatar-placeholder.png");

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false, title: "" };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    formatearDireccion = (direccion, direccionEstado) => {
        let direccionFinal = direccion.split("/");
        if (direccionEstado) direccionFinal.push(direccionEstado);
        this.setState({ title: direccionFinal });
    };

    componentDidMount() {
        if (this.props.title) {
            this.formatearDireccion(this.props.title);
        }
        if (this.props.stateTitle) {
            this.formatearDireccion(this.props.title, this.props.stateTitle);
        }
    }

    //Verifica si el stateTitle existe en el estado titulo y si es igual al nuevo titulo
    verificarStateTitle = (stateTitle) => {
        if (
            this.state.title !== undefined &&
            this.state.title[this.state.title.length - 1] == stateTitle
        )
            return false;
        else return true;
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.title !== this.props.title &&
            this.props.title !== undefined
        ) {
            this.formatearDireccion(this.props.title);
        }
        if (
            prevProps.stateTitle !== this.props.stateTitle &&
            this.props.stateTitle !== undefined &&
            this.verificarStateTitle(this.props.stateTitle)
        ) {
            this.formatearDireccion(this.props.title, this.props.stateTitle);
        }
    }

    render() {
        const { navToggle, logOut, user } = this.props;
        return (
            <nav className="align-items-stretch flex-md-nowrap mt-1 p-0 navbar justify-content-end">
                <Fcm />
                <div className="d-flex w-100">
                    <div
                        className="t-primary navbar-title shadow"
                        style={{ backgroundColor: "white" }}
                    >
                        {this.state.title && (
                            <h5 className="title-navbar">
                                {this.state.title.map((item, index) => {
                                    if (index == 0) {
                                        if (this.state.title.length > 1)
                                            return <React.Fragment key={index}>{`${item}/`}</React.Fragment>
                                        else return <React.Fragment key={index}>{`${item}`}</React.Fragment>
                                    } else if (index == 1) {
                                        return (
                                            <small
                                                key={index}
                                                style={{
                                                    fontFamily: "Cairo-Regular",
                                                }}
                                            >
                                                {`${item}`}
                                            </small>
                                        );
                                    } else {
                                        return (
                                            <small
                                                key={index}
                                                style={{
                                                    fontFamily: "Cairo-Regular",
                                                }}
                                            >
                                                {`/${item}`}
                                            </small>
                                        );
                                    }
                                })}
                            </h5>
                        )}
                    </div>
                    <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                        <div className="ml-3 input-group input-group-seamless" />
                    </div>
                    <ul className="flex-row navbar-nav align-items-center mr-2">
                        <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={this.toggle}
                        >
                            <DropdownToggle
                                color="light"
                                caret
                                className="shadow menu-usuario nav-item-dropdown border-0 "
                            >
                                <span className="d-md-inline-block usuario">
                                    {user.first_name}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img
                                    className="user-avatar rounded-circle mr-2"
                                    src={
                                        user.profile && user.profile.avatar
                                            ? user.profile.avatar
                                            : defaultAvatar
                                    }
                                    alt="User Avatar"
                                />
                                <span>
                                    &nbsp;
                                    {this.state.dropdownOpen ? (
                                        <i
                                            className="fa fa-chevron-up"
                                            style={{
                                                color: "var(--primaryColor)",
                                            }}
                                            aria-hidden="true"
                                        ></i>
                                    ) : (
                                        <i
                                            className="fa fa-chevron-down"
                                            style={{
                                                color: "var(--primaryColor)",
                                            }}
                                            aria-hidden="true"
                                        ></i>
                                    )}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {/* <DropdownItem header>Header</DropdownItem> */}
                                <Link tabIndex="0" to="/user-profile">
                                    <DropdownItem>
                                        <i className="material-icons"></i>
                                        Perfil
                                    </DropdownItem>
                                </Link>
                                {/* <DropdownItem>
                                <Link tabIndex="0"
                                   to="/edit-user-profile">
                                    <i className="material-icons"></i>
                                    Edit Profile
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link tabIndex="0"
                                   to="/file-manager-list">
                                    <i className="material-icons"></i>
                                    Files
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link tabIndex="0"
                                   to="/transaction-history">
                                    <i className="material-icons"></i>
                                    Transactions
                                </Link>
                            </DropdownItem> */}
                                <DropdownItem divider />
                                <a tabIndex="0" onClick={logOut} href="/">
                                    <DropdownItem className="text-danger">
                                        <i className="material-icons text-danger">
                                            
                                        </i>
                                        Logout
                                    </DropdownItem>
                                </a>
                            </DropdownMenu>
                        </Dropdown>
                        <nav className="nav">
                            <a
                                className="toggle-sidebar d-sm-inline d-md-none" //"shadow nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none text-center"
                                onClick={navToggle}
                            >
                                <i className="material-icons"></i>
                            </a>
                        </nav>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navbar;
