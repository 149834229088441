import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_COSTO_INVENTARIO_LOADER',
    DATA: "REPORTE_COSTO_INVENTARIO_DATA",
    ITEM: "REPORTE_COSTO_INVENTARIO_ITEM",
    PAGE: `REPORTE_COSTO_INVENTARIO_PAGE`,
    PAGE_LOTE: 'REPORTE_COSTO_INVENTARIO_PAGE_LOTE',
    SET_PRODUCTO: 'SET_PRODUCTO_REPORTE_COSTO_INVENTARIO',
    SET_PRODUCTO_LOTE: 'SET_PRODUCTO_LOTE_REPORTE_COSTO_INVENTARIO',
    SET_BODEGA: 'SET_BODEGA_COSTO_INVENTARIO',
    SET_FECHA_CIERRE_COSTO:'SET_FECHA_CIERRE_COSTO',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type:type,
    data
})

const setPage = (type, page) => (dispatch, getStore)  => {
    dispatch({
        type: type,
        page,
    })
};

const setBodega = bodega => (dispatch) => {
    dispatch({
        type: constants.SET_BODEGA,
        bodega
    })
}

const setProducto = producto => ({
    type: constants.SET_PRODUCTO,
    producto
})

const setProductoLote = productoLote => ({
    type: constants.SET_PRODUCTO_LOTE,
    productoLote
})


// ACTIONS

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteCostoInventario;
    const params = { page };
    if(resource.producto){
        params.producto = resource.producto.id
    }
    if(resource.bodega){
        params.bodega = resource.bodega.id
    }
    if(resource.fecha_cierre){
        params.fecha_cierre = (resource.fecha_cierre.format('YYYY-MM-DD'));
    }
    dispatch(setLoader(true));
    api.get(`${url}/costos_inventario`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(constants.PAGE, page));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarLote = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteCostoInventario;
    const params = {
        page,
        producto: resource.productoLote
    };
    if(resource.bodega){
        params.bodega = resource.bodega.id
    }
    dispatch(setLoader(true));
    api.get(`${url}/lote_costos_inventario`, params).then((response) => {
        dispatch(setData(constants.ITEM ,response));
        dispatch(setPage(constants.PAGE_LOTE, page));
    }).catch((error) => {
        mensajeError(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const mensajeError = (error) => {
    let mensaje = 'Hubo un error';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
}

const changeBodega = (value) => (dispatch) => {
    dispatch(setBodega(value));
    dispatch(listar())
}

const changeProducto = (value) => (dispatch) => {
    dispatch(setProducto(value));
    dispatch(listar())
}

const changeProductoLote = (value) => (dispatch) => {
    dispatch(setProductoLote(value));
    dispatch(listarLote())
}

const HistorialCostos = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().reporteCostoInventario;
    let fecha_cierre = resource.fecha_cierre;
    api.post(`productos/Historial_costos`, {fecha_cierre}).then((response) => {
        NotificationManager.success('Historico de costos guardado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la creacion del Historico';
        console.log(error)
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                try {
                    mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
                } catch (error) {
                    mensaje = "Error en la creacion del historico";
                }
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const changeFecha = (value) => dispatch => {
    dispatch(setData(constants.SET_FECHA_CIERRE_COSTO, value));
    dispatch(listar())
}

export const actions = {
    listar,
    listarLote,
    changeBodega,
    changeProducto,
    changeProductoLote,
    HistorialCostos,
    changeFecha
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) =>{
        return{
            ...state,
            data,
        };
    },
    [constants.ITEM]:(state,{data}) =>{
        return{
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE_LOTE]: (state, { page }) => {
        return {
            ...state,
            pageLote: page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_PRODUCTO]: (state, { producto }) => {
        return {
            ...state,
            producto
        };
    },
    [constants.SET_PRODUCTO_LOTE]: (state, { productoLote }) => {
        return {
            ...state,
            productoLote
        };
    },
    [constants.SET_BODEGA]: (state, { bodega }) => {
        return {
            ...state,
            bodega
        };
    },
    [constants.SET_FECHA_CIERRE_COSTO]: (state, { data }) => {
        return {
            ...state,
            fecha_cierre: data
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    pageLote: 1,
    ordering: '',
    producto: undefined,
    productoLote: undefined,
    bodega: undefined,
    fecha_cierre: moment(),
}

export default handleActions(reducers, initialState);
