import React from "react";
import HeaderSimple from "../Header/HeaderSimple";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import iconoDatosP from "../../../../../assets/img/icons/datos oc.png";
import iconoRecetaP from "../../../../../assets/img/icons/agenda.png";
import iconoCheck from "../../../../../assets/img/icons/Finalizado oc.png";
import iconoPresentaciones from "../../../../../assets/img/icons/Punto de venta.png";

function CardFormularioOrden(props) {
    return (
        <div className="Container">
            <div className="row">
                <div className="mb-4 col-12">
                    <div className="row justify-content-center mt-4">
                        <div className="col-12">
                            <ProgressBar
                                percent={
                                    props.step === 1
                                        ? 33
                                        : props.step === 2
                                        ? 66
                                        : props.step === 3
                                        ? 100
                                        : 100
                                }
                                filledBackground="#f3a631"
                                stepPositions={[25, 50, 75]}
                            >
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? props.step > index + 1
                                                            ? "step-caption-check"
                                                            : "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished &&
                                                props.step > index + 1 ? (
                                                    <img
                                                        width="15"
                                                        src={iconoCheck}
                                                    />
                                                ) : (
                                                    <img
                                                        src={iconoDatosP}
                                                        width="15"
                                                    />
                                                )}
                                            </div>
                                            &nbsp;&nbsp;1. Datos
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? props.step > index + 1
                                                            ? "step-caption-check"
                                                            : "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished &&
                                                props.step > index + 1 ? (
                                                    <img
                                                        width="15"
                                                        src={iconoCheck}
                                                    />
                                                ) : (
                                                    <img
                                                        src={iconoRecetaP}
                                                        width="15"
                                                    />
                                                )}
                                            </div>
                                            &nbsp;&nbsp;2. Receta
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished && (
                                                    <img
                                                        src={iconoPresentaciones}
                                                        width="15"
                                                    />
                                                )}
                                            </div>
                                            &nbsp;&nbsp;3. Presentacion
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>
                        </div>
                    </div>
                    <div
                        className="mb-4 card card-small m-t shadow"
                        style={{ borderRadius: "10px" }}
                    >
                        <div className="card-body">
                            {props.titulo && (
                                <HeaderSimple titulo={props.titulo} />
                            )}
                            <div className="mb-4 mt-2 col-12">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardFormularioOrden;
