import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { RenderSchedule } from "../../../Utils/renderField/renderTableField";

export default class SucursalesGrid extends Component{

    componentDidMount() {
        let id=this.props.CPid
        this.props.selectSucursales(id);
    }

    render(){

        const { sucursales, loader, listar: onPageChange, onSortChange, eliminar  } = this.props

        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={sucursales} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                    <TableHeaderColumn
                        dataField="encargado"
                        isKey
                        dataSort
                    >
                        ENCARGADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="direccion"
                        dataSort
                    >
                        DIRECCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="telefono"
                        dataSort
                    >
                        TELEFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="horario_entrada"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                <RenderSchedule fecha_Entrada={row.horario_entrada} fecha_Salida={row.horario_salida} />
                            )
                        }
                    >
                       HORARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="departamento"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                cell ? `${cell}` : "----"
                            )
                        }
                    >
                        DEPTO.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="municipios"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                cell ? `${cell}` : "----"
                            )
                        }
                    >
                        MUNICIPIO
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}