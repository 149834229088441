import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import login from "./modules/cuenta/login";
import register from "./modules/cuenta/register";
import profile from "./modules/cuenta/profile";
import usuarios from "./modules/usuarios/usuarios";
import notificaciones from "./modules/notificaciones/notificaciones";
import roles from "./modules/roles/roles";
import cuentas from "./modules/cuentaBancos/cuentas";
import clientes from "./modules/clientes/clientes";
import transacciones from "./modules/Transacciones/transacciones";

import tipoproducto from "./modules/producto/tipoproducto";
import productos from "./modules/producto/producto";
import bodegas from "./modules/bodega/bodega";
import fcm from "./modules/fcm";

import vendedores from "./modules/vendedores/vendedores";
import ordencompra from "./modules/OrdenCompra/ordencompra";
import notasCredito from "./modules/OrdenCompra/notasCredito";
import peticionM from "./modules/PeticionMaterial/peticionmaterial";
import tipocategoria from "./modules/TipoCategorias/tipocategorias";

import bitacora from "./modules/bitacora/bitacora";
import gastos from "./modules/gastos/gastos";
import caja from "./modules/cajachica/caja";
import peticionV from "./modules/PeticionVenta/peticionventa";
import ordenventa from "./modules/OrdenVenta/ordenventa";
import ventainterna from "./modules/OrdenVenta/ventainterna";
import Reportes from "./modules/Reporte/deuda";
import reporteVentas from './modules/Reporte/ventas';
import reporteExistenciasBodega from './modules/Reporte/existenciasBodega';
import reporteProductividad from './modules/Reporte/productividad';
import reporteMateriaPrima from './modules/Reporte/materiaPrima';
import reporteCostoInventario from './modules/Reporte/reporteCostoInventario';
import reporteHistoricoCajas from './modules/Reporte/historicoCajas';
import reporteRotacionInvetario from './modules/Reporte/rotacionInventario';
import dashboard from './modules/Reporte/dashboard';
import r_ingreso_egreso from "./modules/Reporte/ingreso_egreso";
import r_pago_flotante from "./modules/Reporte/pago_flotante";
// planilla
import puestos from "./modules/Puestos/Puestos";
import flotante_inverso from "./modules/Transacciones/flotante_inverso";
import bonificacion from "./modules/bonificaciones/bonificacion";
import empleados from "./modules/Empleados/empledos";
import planilla from "./modules/Planilla/planilla";

// bodega
import estado_bodega from "./modules/estado_bodega/estado_bodega";
import devolucion from "./modules/Devolucion/devolucion";

import actividades from "./modules/Actividades/actividades";
import asignacionActividades from "./modules/Actividades/asignacionActividades";
import reporteCostoVenta from './modules/Reporte/reporteCostoVenta';
import vehiculosMaquinaria from "./modules/Vehiculos/vehiculosMaquinaria";
import sucursalesHB from "./modules/sucursales/sucursales";
// produccion
import produccion from "./modules/OrdenProduccion/ordenproduccion";
import puntoVenta from "./modules/puntoVenta/puntoVenta";
import bandejaNotificaciones from './modules/bandeja/notifiacaciones';

export default combineReducers({
    form: formReducer,
    actividades,
    asignacionActividades,
    login,
    devolucion,
    register,
    profile,
    usuarios,
    roles,
    routing,
    notificaciones,
    cuentas,
    clientes,
    transacciones,
    tipoproducto,
    productos,
    bodegas,
    sucursalesHB,
    puntoVenta,
    fcm,
    vendedores,
    ordencompra,
    peticionM,
    notasCredito,
    bitacora,
    tipocategoria,
    bandejaNotificaciones,
    gastos,
    caja,
    peticionV,
    ordenventa,
    Reportes,
    reporteVentas,
    reporteExistenciasBodega,
    reporteProductividad,
    reporteMateriaPrima,
    reporteCostoInventario,
    reporteHistoricoCajas,
    reporteCostoVenta,
    reporteRotacionInvetario,
    dashboard,
    puestos,
    ventainterna,
    flotante_inverso,
    r_ingreso_egreso,
    r_pago_flotante,
    bonificacion,
    empleados,
    estado_bodega,
    planilla,
    produccion,
    vehiculosMaquinaria,
});
