import React, { Component } from "react";
import "./toolbar.css";
import Search from "./search";

import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import es from "moment/locale/es";
import "react-dates/lib/css/_datepicker.css";

export default class ToolbarFiltroFecha extends Component {
    state = {
        dateStart: moment.now(),
        focusedStart: false,

        dateEnd: moment.now(),
        focusedEnd: false,
    };
    render() {
        return (
            <div
                className={`col-12 p-0 mx-2  row d-flex ${
                    this.props.justify
                        ? this.props.justify
                        : "justify-content-center justify-content-md-start"
                } `}
            >
                <div className="form-group has-feedback col-md-3 col-12 m-0">
                    <div className="col-md-12 p-0 m-2">
                        <label className="t-azul" htmlFor="producto">
                            Fecha Inicial
                        </label>
                    </div>
                    <SingleDatePicker
                        placeholder={"Fecha Inicio"}
                        date={
                            this.props.dateStart
                                ? moment(this.props.dateStart)
                                : null
                        }
                        focused={this.state.focusedStart}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                            this.setState({ dateStart: value });
                            this.props.setDateStart(value);
                        }}
                        onFocusChange={({ focused }) =>
                            this.setState({ focusedStart: focused })
                        }
                        numberOfMonths={1}
                        id={"dateStart"}
                        readOnly={true}
                    />
                </div>
                <div className="col-md-3 form-group m-0">
                    <div className="col-md-12 p-0 m-2">
                        <label className="t-azul" htmlFor="producto">
                            Fecha Final
                        </label>
                    </div>
                    <SingleDatePicker
                        placeholder={"Fecha Final"}
                        date={
                            this.props.dateEnd
                                ? moment(this.props.dateEnd)
                                : null
                        }
                        focused={this.state.focusedEnd}
                        isOutsideRange={() => false}
                        onDateChange={(value) => {
                            this.setState({ dateEnd: value });
                            this.props.setDateEnd(value);
                        }}
                        onFocusChange={({ focused }) =>
                            this.setState({ focusedEnd: focused })
                        }
                        numberOfMonths={1}
                        id={"dateEnd"}
                        readOnly={true}
                    />
                </div>
                {this.props.buscar !== undefined && (
                    <div className="col-lg-4 offset-md-2 col-md-4 mt-2 text-right search d-flex align-items-end">
                        <Search
                            buscar={this.props.buscar}
                            buscador={this.props.buscador}
                            placeholder={this.props.placeholder}
                        />
                    </div>
                )}
            </div>
        );
    }
}
