import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
// import { selectRoles, selectEmpresas } from '../../../../redux/modules/cuenta/register';

class Registro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };
    componentWillMount(){
        const { selectEmpresas, selectRoles} = this.props
        selectRoles();
        selectEmpresas();
    }
    format = (array=[]) =>{
        // console.log(array)
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    render() {
        const { onSubmit, loader, roles, empresas } = this.props;
        return (
            <div className="Container">
               <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 card card-small m-t">
                            <div className="card-body">
                                <h5 className="t-primary card-title t-h ">AGREGAR USUARIO</h5>
                                <div className="p-0 px-3 pt-3">
                                    <LoadMask loading={loader} light>
                                        <RegisterForm onSubmit={onSubmit} format={this.format} roles={roles} empresas={empresas} />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
