import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CardSimple from "../../../Utils/Cards/cardSimple";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {
    renderField,
    renderTextArea,
    renderSelectField,
    renderCurrency,
    AsyncSelectField,
} from "../../../Utils/renderField";

const ResumenBilletes = (props) => {
    const { img, titulo, nombre } = props;
    return (
        <div className="col-6 col-md-4 col-lg-2 my-2 d-flex align-items-end">
            <div>
                <div className="col-12 px-0">
                    <div className="col-12 px-0 mb-3 d-flex justify-content-center">
                        <img
                            src={img}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "50px",
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 px-0">
                    <div
                        className="col-12 px-0"
                        style={{ textAlign: "center" }}
                    >
                        <small>{titulo}</small>
                    </div>
                    <div className="col-12 px-0">
                        <Field
                            name={nombre}
                            type="number"
                            component={renderField}
                            min={0}
                            top={{
                                top: "67px",
                                position: "inherit",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

class ResumenMiCaja extends Component {
    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            total,
            onSortChange,
            eliminar,
            data,
            handleSubmit,
        } = this.props;
        return (
            <div className="col-12 row">
                <form name="abrirCajaForm" onSubmit={handleSubmit}>
                    <div className="col-12 col-lg-8">
                        <div className="col-12 row d-flex justify-content-center">
                            <div
                                className="col-12 py-1 my-2 d-flex justifty-content-start align-items-center"
                                style={{
                                    backgroundColor: "#3290b3",
                                    borderRadius: "5px",
                                }}
                            >
                                <label className="t-blanco bold my-0 ">
                                    Resumen de caja
                                </label>
                            </div>
                            <div className="col-12 row px-0">
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label> Apertura </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={Math.abs(
                                                    data.historial.apertura
                                                )}
                                                simbolo={"Q "}
                                            />
                                        ) : (
                                            <RenderMoneda
                                                monto={Math.abs(total)}
                                                simbolo={"Q "}
                                            />
                                        )}
                                    </span>
                                </div>
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label> ventas efectivo </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={
                                                    data.historial
                                                        .ventasEfectivo
                                                }
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </span>
                                </div>
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label> Ventas tarjeta de credito </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={
                                                    data.historial.ventasTarjeta
                                                }
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </span>
                                </div>
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label>
                                        Cuanto has retirado en efectivo
                                    </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={
                                                    data.historial.totalRetirado
                                                }
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </span>
                                </div>
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label>
                                        Cuanto has agregado en efectivo
                                    </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={
                                                    data.historial.totalAgregado
                                                }
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </span>
                                </div>
                                <div className="col-8 d-flex justify-content-start align-items-center">
                                    <label>
                                        Total registrado en caja
                                    </label>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <span>
                                        {this.props.cerrarCaja ? (
                                            <RenderMoneda
                                                monto={
                                                    data.historial.cierre
                                                }
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </span>
                                </div>
                                {this.props.cerrarCaja && (
                                    <div className="col-12 row mx-0 px-0">
                                        <div className="col-8 d-flex justify-content-start align-items-center">
                                            <label>Total de cierre</label>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end align-items-center">
                                            <span>
                                                <RenderMoneda monto={total} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 row d-flex justify-content-center my-3">
                        <div
                            className="col-12 row d-flex justify-content-center py-3 px-0 mx-0"
                            style={{
                                border: "2px solid #D3D3D3",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="col-10 row">
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete200.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete200"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete100.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete100"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete50.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete50"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete20.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete20"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete10.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete10"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete5.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete5"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/billete1.png")}`}
                                    titulo="Total de billetes"
                                    nombre="cantidadBillete1"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/moneda1.png")}`}
                                    titulo="Total de Monedas"
                                    nombre="cantidadMoneda1"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/moneda50.png")}`}
                                    titulo="Total de Monedas"
                                    nombre="cantidadMoneda50"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/moneda25.png")}`}
                                    titulo="Total de Monedas"
                                    nombre="cantidadMoneda25"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/moneda10.png")}`}
                                    titulo="Total de Monedas"
                                    nombre="cantidadMoneda10"
                                />
                                <ResumenBilletes
                                    img={`${require("../../../../../../assets/img/dinero/moneda5.png")}`}
                                    titulo="Total de Monedas"
                                    nombre="cantidadMoneda5"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <br />
                                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                                    <button
                                        className="btn btn-secondary"
                                        type="button"
                                        onClick={this.props.pasoAnterior}
                                    >
                                        cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                                    >
                                        {this.props.cerrarCaja
                                            ? `Cerrar`
                                            : "Abrir"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

ResumenMiCaja = reduxForm({
    form: "abrirCajaForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if(data.cantidadBillete200 && data.cantidadBillete200 < 0){
            errors.cantidadBillete200 = "Es menor a 0"
        }
        if(data.cantidadBillete100 && data.cantidadBillete100 < 0){
            errors.cantidadBillete100 = "Es menor a 0"
        }
        if(data.cantidadBillete50 && data.cantidadBillete50 < 0){
            errors.cantidadBillete50 = "Es menor a 0"
        }
        if(data.cantidadBillete20 && data.cantidadBillete20 < 0){
            errors.cantidadBillete20 = "Es menor a 0"
        }
        if(data.cantidadBillete10 && data.cantidadBillete10 < 0){
            errors.cantidadBillete10 = "Es menor a 0"
        }
        if(data.cantidadBillete5 && data.cantidadBillete5 < 0){
            errors.cantidadBillete5 = "Es menor a 0"
        }
        if(data.cantidadBillete1 && data.cantidadBillete1 < 0){
            errors.cantidadBillete1 = "Es menor a 0"
        }
        if(data.cantidadMoneda1 && data.cantidadMoneda1 < 0){
            errors.cantidadMoneda1 = "Es menor a 0"
        }
        if(data.cantidadMoneda50 && data.cantidadMoneda50 < 0){
            errors.cantidadMoneda50 = "Es menor a 0"
        }
        if(data.cantidadMoneda25 && data.cantidadMoneda25 < 0){
            errors.cantidadMoneda25 = "Es menor a 0"
        }
        if(data.cantidadMoneda10 && data.cantidadMoneda10 < 0){
            errors.cantidadMoneda10 = "Es menor a 0"
        }
        if(data.cantidadMoneda5 && data.cantidadMoneda5 < 0){
            errors.cantidadMoneda5 = "Es menor a 0"
        }
        return errors;
    },
})(ResumenMiCaja);

const selector = formValueSelector("abrirCajaForm");

ResumenMiCaja = connect((state) => {
    const cantidadBillete200 = selector(state, "cantidadBillete200");
    const cantidadBillete100 = selector(state, "cantidadBillete100");
    const cantidadBillete50 = selector(state, "cantidadBillete50");
    const cantidadBillete20 = selector(state, "cantidadBillete20");
    const cantidadBillete10 = selector(state, "cantidadBillete10");
    const cantidadBillete5 = selector(state, "cantidadBillete5");
    const cantidadBillete1 = selector(state, "cantidadBillete1");
    const cantidadMoneda1 = selector(state, "cantidadMoneda1");
    const cantidadMoneda50 = selector(state, "cantidadMoneda50");
    const cantidadMoneda25 = selector(state, "cantidadMoneda25");
    const cantidadMoneda10 = selector(state, "cantidadMoneda10");
    const cantidadMoneda5 = selector(state, "cantidadMoneda5");
    let total =
        parseInt(cantidadBillete200) * 200 +
        parseInt(cantidadBillete100) * 100 +
        parseInt(cantidadBillete50) * 50 +
        parseInt(cantidadBillete20) * 20 +
        parseInt(cantidadBillete10) * 10 +
        parseInt(cantidadBillete5) * 5 +
        parseInt(cantidadBillete1) +
        parseInt(cantidadMoneda1) +
        parseInt(cantidadMoneda50) * 0.5 +
        parseInt(cantidadMoneda25) * 0.25 +
        parseInt(cantidadMoneda10) * 0.1 +
        parseInt(cantidadMoneda5) * 0.05;

    return {
        total,
    };
})(ResumenMiCaja);

export default ResumenMiCaja;
