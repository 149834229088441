import React, { Component, Fragment } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from './PagoCreditoForm';


import { TipoTransaccion, getSimbolo } from '../../../../../../utility/constants';
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";

import Swal from 'sweetalert2';
import moment from 'moment';

import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}


export default class pagosCredito extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        mostrar_pago: true
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setOpenModalGasto(true)
    }
    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: 'Â¿Desea anular la transacciÃ³n?',
            text: 'Â¡No podrÃ¡ revertir esta acciÃ³n!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Â¡SÃ­, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, orden.id);
            }
        });
    }
    marcarOCCompleta = () => {
        const { orden } = this.props;
        Swal.fire({
            title: '¿Desea marcar la orden de venta como pagada?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Ya­ está pagada!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.marcarPagadaOC(orden.orden.id);
            }
        });
    }
    cerrarModal = () => {
        this.props.setOpenModal(false);
    }
    renderModalAnulacion = () => {
        const { open_modal_gasto } = this.props;
        return (
            <Modal open={open_modal_gasto} onClose={(e) => {
                this.props.setOpenModalGasto(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>

                    {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalGasto}
                            />
                        )
                    }

            </Modal>
        )
    }
    botonModal = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row
                        })
                        this.props.setOpenModal(true);
                     }}
                      alt="Ver eliminado" />)
    }
    botonRealizarMovimiento = (id, row) => {
        if(!row.estado){
            return(<div></div>)
        }
        if(!row.flotante){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional"
            src={require("../../../../../../../assets/img/icons/marcar_bono.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.props.setOpenModal(true)
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            mostrar_pago: false,
                            open_modal: true,
                            es_pago: false
                        })
                     }}
                      alt="Ver eliminado" />)
    }
    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí­, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values, this.props.id_cuenta);
            }
        });
    }
    renderModalFlotantePagado = () => {
        let transaccion = null
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return(
            <Fragment>
                <div className="mt-5">
                    <h2 className="text-center text-primary">REALIZAR PAGO</h2>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                    />
            </Fragment>
        )
    }
    renderPagoVenta = () => {
        const {  pagos_credito, cuentas,  simbolo, orden, item, esCobro} = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        return (
            <Fragment>
                <div className="mt-0">
                    <h2 className="text-center text-primary">COBRO</h2>
                </div>
                <PagoCreditoForm
                    cerrarModal={this.cerrarModal}
                    cuentas={cuentas}
                    simbolo={simbolo}
                    initialValues={{tipo_cuenta: "true", fecha: new Date() ,numero_cuenta: no_cuenta}}
                    es_gasto={true}
                    texto_boton_movimiento={"AGREGAR COBRO"}
                    titulo_modal="INGRESAR COBRO A"
                    pago_cobro={'COBRO'}
                    esCobro={esCobro}
                    onSubmit={(e) => {
                        this.props.abonarGastoOPago(orden.orden.id, true);
                        this.cerrarModal();
                    }} />
            </Fragment>
        )
    }
    render() {
        const {  open_modal, cuentas,  simbolo, orden, item} = this.props;
        let data = {
            count: 1,
            results:orden.pagos
        }
        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <Modal open={open_modal} onClose={(e) => {
                    this.props.setOpenModal(false)
                }} center>
                    {
                        (this.state.mostrar_pago) ? (
                            this.renderPagoVenta()
                        ): (
                            this.renderModalFlotantePagado()
                        )
                    }
                </Modal>
                <Grid hover striped data={data}  pagination={false} >
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={
                        standardActions({
                            eliminarModal: this.abrirModalEliminar,
                            adicional: this.botonModal,
                            // adicional2: this.botonRealizarMovimiento
                        })
                    }
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell,row) => (
                            <RenderDateTime fecha={cell} ></RenderDateTime>
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    ENTRO A
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="numero_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    CUENTA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            let id = Number(cell.tipo_documento);
                            let cuenta = _.find(TipoTransaccion, x => x.value == id);
                            if(cuenta){
                                return(cuenta.label);
                            }
                        }
                    }
                >
                    TIPO DOCUMENTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            return <span>{cell.no_documento}</span>
                        }
                    }
                >
                    NO. TRANSACCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat ={
                        (cell, row)=>{
                            if(cell){
                                return <RenderMoneda monto={cell.monto} simbolo={simbolo} />
                            }
                        }
                    }
                >
                    MONTO
                </TableHeaderColumn>
            </Grid>
            {
                (!orden.orden.pago_completo && orden.orden.estado) && (
                    <Fragment>
                        <button type="button" onClick={(e) => {
                            e.preventDefault();
                            this.props.setOpenModal(true)
                            this.setState({
                                es_pago: true,
                                open_modal: true, es_pago: true, es_gasto: false})
                        }} className="btn btn-primary m-1 align-self-center mt-2">AGREGAR COBRO</button>
                         <button type="button" onClick={(e) => {
                                e.preventDefault();
                                this.marcarOCCompleta();
                            }} className="btn btn-mostaza m-1 align-self-center mt-2">MARCAR OV COBRADA</button>
                    </Fragment>
                )
            }

        </Fragment>
        )
    }
}
