import React, {Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {connect} from 'react-redux';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderSelectField, renderTextArea,renderFieldRadio } from '../../../Utils/renderField';

import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


let RetiroCajaForm = (props) => {
    const {data, handleSubmit, previousStep, tipo_entrega, sucursales} = props;
    return (
        <form name="EgresoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>            
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-10 row m-0 p-0">
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="fecha">Fecha</label>
                            <Field
                                name="fecha"
                                selectedDate={new Date()}
                                maxDate="2040-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                         
                    </div>
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="caja">Empresa</label>
                            <Field
                                name="Empresa"
                                labelKey={`nombre`} valueKey="id"
                                component={renderSelectField}
                                options={data}
                            />
                        </div>
                         <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="monto">Monto</label>
                            <Field
                                name="monto"
                                prefix={'Q. '}
                                component={renderCurrency}
                                parse={cell => parseFloat(cell)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback col-12 `}>
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                component={renderTextArea}
                                top={{top:'94px'}}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button onClick={()=>{props.setOpenModalRetiro(false)}} className="btn btn-secondary  align-self-center mr-4">Cancelar</button>
                        <button type="submit" className="btn btn-azul-claro m-1 align-self-center">Retirar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'RetiroCajaForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if(!data.Empresa) {
            errors.Empresa = "Campo requerido.";
        }
        if(!data.monto){
            errors.monto = 'Campo requerido';
        }else{
            if(parseFloat(data.monto)<= 0)
            {errors.monto = 'El monto tiene que ser mayor a 0';}
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        
        return errors;
    },
})(RetiroCajaForm);

