import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardFormulario from 'Utils/Cards/cardFormulario';

// Componentes extra
import DevolucionForm from './DevolucionForm';
import moment from 'moment';

export default class DevolucionCrear extends Component {
    state = {
        step: 1
    }
    componentWillMount() {
        this.props.selectEmpresas();
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        let valores = _.cloneDeep(values);
        if(valores.detalle_orden){
            valores.detalle_orden.forEach( x => {
                x.producto = x.producto.id
            })
        }

        if(valores.depacho_devolucion){
            valores.depacho_devolucion = valores.depacho_devolucion.id;
        }
        valores.cliente = valores.cliente.id;
        valores.encargado = valores.encargado.id;
        valores.empresa = Number(valores.empresa);
        valores.fecha = moment(valores.fecha).format("YYYY-MM-D");
        this.state.editar? editar(id,valores) : crear(valores);
    }

    render() {
        const { loader, empresas } = this.props;
        return (
            <CardFormulario titulo={"CREAR DEVOLUCIÓN"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DevolucionForm
                        {...this.props}
                        initialValues={{fecha: new Date()}}
                        empresas={empresas}
                        onSubmit={this.onSubmit}/>
                </LoadMask>
            </CardFormulario>
        );
    }
}
