import React, { Component, Fragment } from 'react'

const dots = require('../../../../../../../assets/img/tree.png')

import { Dropdown, Button, DropdownButton } from 'react-bootstrap'

import "./dropdown.css";

export default class ItemTree extends Component {

    render() {
        const { item } = this.props;
        return (
            <div className="ml-4 no-padding  ">

                <div className="rayado-opciones px-2 py-2" style={{display: 'flex', position: 'relative', height: 40}}>
                    <div style={{flexGrow: 3}}>
                        {item.title}
                    </div>
                    <div style={{position: 'absolute', right: 10, width: 10}}>
                        <Dropdown >
                            <Dropdown.Toggle variant="info"  id="dropdown-basic">
                                <img src={dots} style = {{ background: "#F2F2F2" }} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() =>{
                                    this.props.seleccionarItem(item, 1)
                                    this.props.setOpenModal(true);
                                }}>Agregar categoria</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    this.props.seleccionarItem(item, 2);
                                    this.props.setOpenModal(true);
                                }}>Editar</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    this.props.seleccionarItem(item, 3);
                                }}>Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>

                {item.children.map((item, index) => (
                    <ItemTree
                        key={index}
                        setOpenModal={this.props.setOpenModal}
                        seleccionarItem={this.props.seleccionarItem}
                        item={item}
                    />
                ))}

            </div>
        )
    }
}
