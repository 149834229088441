import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Modal from "react-responsive-modal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import AnulacionForm from "./anulacionForm";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";

function cellTachado(cell, row) {
    if (!row.estado) {
        return {
            textDecoration: "line-through",
            whiteSpace: "normal",
            padding: 5,
        };
    }
    return { whiteSpace: "normal", padding: 5 };
}

export default class DetalleCajaGrid extends Component {
    state = {
        modal: false,
        idVenta: undefined,
    };
    eliminar = (id) => {
        this.setState({ modal: true, idVenta: id });
    };
    setModalFalse = () => {
        this.setState({ modal: false, idVenta: undefined });
    };
    anularVenta = (values) => {
        values.id = this.state.idVenta;
        this.props.anularVenta(values);
        this.setModalFalse();
    };
    renderModal = () => {
        return (
            <Modal
                open={this.state.modal}
                showCloseIcon={false}
                onClose={(e) => {
                    this.setModalFalse();
                }}
                center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title">
                        Anular venta
                    </span>
                </div>
                <div className="form-validate mb-lg text-center">
                    <AnulacionForm
                        onSubmit={this.anularVenta}
                        cerrarModal={this.setModalFalse}
                    />
                </div>
            </Modal>
        );
    };
    render() {
        const {
            data,
            loader,
            loaderVentas,
            listar: onPageChange,
            getHistorialVentas,
            onSortChange,
            pageVentas,
            eliminar,
            permisos,
        } = this.props;
        return (
            <Grid
                hover
                striped
                data={data}
                loading={loaderVentas}
                page={pageVentas}
                onPageChange={getHistorialVentas}
                onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    isKey
                    width="15%"
                    dataFormat={standardActions({
                        imprimir: "punto_venta",
                        ver: "/punto_venta",
                        anularVenta: this.eliminar,
                    })}
                >
                    ACCIONES
                    {this.renderModal()}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell) =>
                        cell ? <RenderDateTime fecha={cell} /> : "Sin registro"
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="concepto" tdStyle={cellTachado}>
                    CONCEPTO DE
                </TableHeaderColumn>
                <TableHeaderColumn dataField="comprador" tdStyle={cellTachado}>
                    COMPRADOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="operador"
                    tdStyle={cellTachado}
                    dataFormat={(cell) => (cell ? cell.nombre : "Anonimo")}
                >
                    OPERADOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="efectivo"
                    tdStyle={cellTachado}
                    headerAlign="center"
                    dataAlign="right"
                    dataFormat={(cell) =>
                        cell ? (
                            <RenderMoneda className="px-0" monto={cell} />
                        ) : (
                            <RenderMoneda className="px-0" monto={0} />
                        )
                    }
                >
                    EFECTIVO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tarjeta"
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat={(cell) =>
                        cell ? (
                            <RenderMoneda className="px-0" monto={cell} />
                        ) : (
                            <RenderMoneda className="px-0" monto={0} />
                        )
                    }
                >
                    TARJETA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat={(cell) =>
                        cell ? (
                            <RenderMoneda className="px-0" monto={cell} />
                        ) : (
                            <RenderMoneda className="px-0" monto={0} />
                        )
                    }
                >
                    PAGO DE
                </TableHeaderColumn>
            </Grid>
        );
    }
}
