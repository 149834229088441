import React, {Component} from 'react'
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas, getsimbolo} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import Grid from '../../../Utils/Grid/index';
// import getsimbolo from '../../../../../utility/constants.js'
// /home/yeicob/Documentos/ayg/frontend/src/js/utility/constants.js
export default class EstadoPeriodo extends Component{

    render(){
        let { data, loader } = this.props
        return(
            <div>

                <Grid hover striped data={{count:10, results: data}}  loading={loader}  pagination={false}>
                    {/* <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={
                                standardActions({ eliminarCC:""})
                            }
                    >
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        dataField="transaccion"
                        dataSort
                        dataFormat={
                            (cell,row)=> cell? (`${cell.descripcion} `):"-------"
                        }
                    >
                        DESCRIPCION 
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_ingreso"
                        dataSort
                        dataFormat={
                            (cell,row)=>(
                                cell ? 'Ingreso':'Egreso'
                            )
                        }
                    >
                        MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debe"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        DEBE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="haber"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        HABER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        SALDO
                    </TableHeaderColumn>
                </Grid>
                <br/>
            </div>
        )
    }
}