import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';

import {standardActions} from "../../../Utils/Grid/StandardActions";


export default class tabLinks extends Component {

    render(){
        let { hijos, loader} = this.props
        return(
            <Grid hover striped data={{count: 10, results: hijos}} loading={loader} pagination={false} >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    isKey
                    dataFormat={
                        standardActions({ verpro:"ordencompra" })
                    }
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_orden"
                    dataSort
                >
                    NO. ORDEN
                </TableHeaderColumn>
{/*                 <TableHeaderColumn
                    dataField="nombre_empresa"
                    dataSort
                >
                    Empresa
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="emitido_por"
                    dataSort
                    dataFormat={
                        (cell)=>cell? cell.first_name:'----'
                    }
                >
                    CREADO POR
                </TableHeaderColumn>
            </Grid>

        )
    }
}
