import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'devolucion' ;
let form = 'DevolucionForm';
let dirGrid = '/devoluciones';

export const constants ={
    LOADER: 'LOADER_DEVOLUCION',
    DATA: 'DATA_DEVOLUCION',
    ITEM: 'ITEM_DEVOLUCION',
    PAGE: `PAGE_DEVOLUCION`,
    ORDERING: `ORDERING_DEVOLUCION`,
    SEARCH: `SEARCH_DEVOLUCION`,
    OPEN_MODAL: 'OPENMODAL_DEVOLUCION',
    LOADER_FORMULARIO: 'LOADER_FORMULARIO_DEVOLUCION',
    SET_PENDIENTES:'SET_PENDIENTES_DEVOLUCION',
    SET_PRODUCTOSDS:'SET_PRODUCTOS_DEVOLUCION',
    HISTORIA_MOVIMIENTOS: 'SET_HISTORIA_DEVOLUCION',
    SET_EMPRESAS: 'SET_EMPRESAS_DEVOLUCION',
    CLIENTE : 'CLIENTE_DEVOLUCION'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch({type: constants.SET_EMPRESAS, data: res.empresa})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const listar = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().devolucion;
    const params = { page };
    params.bodega = bodega
    params.search = resource.search;
    params.tipo_movimiento = 70
    dispatch(setLoader(true));

    api.get(`despachos`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const getHistoria = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega_origen = bodega
    params.search = resource.search;
    dispatch(setLoader(true));

    api.get(`despachos`, params).then((response) => {
        dispatch(setData(constants.HISTORIA_MOVIMIENTOS ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        console.log('Response, ', response);
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listar());

    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const reajustar_inventario = (data, bodega) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`despachos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listar(1, bodega));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = (search, bodega) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1, bodega));
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const listDS = (id_bodega) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id_bodega}
    api.get(`despachos/listds`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const setProductoDespacho = (parametros) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {...parametros}
    api.get(`despachos/getListadepacho`, params).then((res)=>{
        dispatch(setData(constants.SET_PRODUCTOSDS, res))
        let detalle = []
        res.forEach( (item, index) => {
            detalle[index] = {
                producto: item,
                despachar: item.es_bodega? item.cantidad_actual: 0
            };
        })
        dispatch(change('DespachoBodegaForm', `Despachos`, detalle))

    }).catch((res)=>{
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const DespacharProducto = (data, orden, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;
    data.bodega = bodega;
    api.post(`despachos/createdespachobodega`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset('DespachoBodegaForm'));
        if (!!dirGrid)
            dispatch(push(`/estado_bodega/${bodega}`));
            // dispatch(goBack())
    }).catch((error) => {
        let mensaje = 'Error en la creación del despacho';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// Verificación de precios

const verificarPreciosProductos = () => (dispatch, getStore) => {
    const store = getStore().ordencompra
    const formData = _.cloneDeep(getStore().form.ordencompraForm.values);
    let productos = []
    formData.detalle_orden.forEach((item, key) => {
        if(item.producto)
            productos.push(item.producto.id)
    });
    if(productos.length > 0)
        dispatch(actualizarPrecios(productos, store.moneda, store.proveedor))
}

const actualizarPrecios = (productos, moneda, proveedor) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = {}
    if (productos) data.productos = productos
    if(moneda) data.moneda = moneda
    if(proveedor) data.proveedor = proveedor

    api.post(`productos/getProductoConComparacion`,data).then((response) => {
        if(response){
            const formData = _.cloneDeep(getStore().form.ordencompraForm.values);
            formData.detalle_orden.forEach((item, key) => {
              let select = _.find(response, x =>  x.id == item.producto.id)
              if(select){
                  item.precio_unitario = select.precio_ultimo
                  item.producto = select;
              }
            });
            dispatch(change('ordencompraForm', 'detalle_orden', formData.detalle_orden))

        }
        dispatch(setLoader(false));

    }).catch(() => {
    })
};
const setCliente = (cliente) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE,
        data: cliente
    })
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    reajustar_inventario,
    listDS,
    setProductoDespacho,
    DespacharProducto,
    getHistoria,
    selectEmpresas,
    verificarPreciosProductos,
    setCliente
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.SET_PRODUCTOSDS]:(state,{ data })=>{
        return{
            ...state,
            productosDS: data,
        }
    },
    [constants.HISTORIA_MOVIMIENTOS]:(state,{ data })=>{
        return{
            ...state,
            historia_movimientos: data,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data,
        }
    },
    [constants.CLIENTE]:(state,{ data })=>{
        return{
            ...state,
            cliente: data,
        }
    },
};


export const initialState ={
    loader:false,
    data: [],
    item: {nombre: ''},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false,
    pendientes:[],
    productosDS:[],
    empresas:[],
    cliente: null,
    historia_movimientos:{
        results: [],
        count: 0
    }
};

export default handleActions(reducers, initialState);
