import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Transacciones/flotante_inverso';
import AsignarFlotanteGrid from './AsignarFlotanteInv';

const mstp = state => {
    return {...state.flotante_inverso}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AsignarFlotanteGrid)
