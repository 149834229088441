import React from 'react';
import Select, { Creatable, Async, components } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import ImageUploader from '../FileUploader/ImageUploader';
import DatePicker from "../DayPicker";
import _ from "lodash";
import moment, { isMoment } from 'moment';
import { TimePicker as AntTimePicker, DatePicker as AntDatePicker, TreeSelect } from 'antd';
import DropdownDate from 'react-dropdown-date';




export const renderField = ({
    maxlength, val, input, placeholder, type, meta: { touched, error }, top, min, max, readOnly
}) => {
    const invalid = touched && error;
    top ? top : (top = { top: "none" })
    let value = {}
    if (val != undefined) {
        value = { value: val, defaultValue: `${val}` }
    }
    return (
        <div>
            <input
                readOnly={readOnly}
                {...input}
                placeholder={placeholder}
                type={type}
                {...value}
                min={min}
                max={max}
                maxLength={maxlength}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="pabs invalid-feedback" style={top}>
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextArea = ({
    input, placeholder, rows, meta: { touched, error }, top
}) => {
    const invalid = touched && error;
    top ? top : (top = { top: "none" })
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="pabs invalid-feedback" style={top}>
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
    input, decimalScale, allowNegative, placeholder, meta: { touched, error }, prefix = "", suffix = "", numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                allowNegative={allowNegative}
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
    readOnly, input, meta: { touched, error }, prefix = "Q. ", placeholder, _onChange, top, decimalScale = 2,
}) => {
    const invalid = touched && error;
    top ? top : (top = { top: "none" })
    return (
        <div>
            <NumberFormat
                readOnly={readOnly}
                className={classNames('form-control text-right', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    if (_onChange)
                        _onChange(values.floatValue);
                    input.onChange(values.value);
                }}
                onFocus={(e) => {
                    e.target.setSelectionRange(0, 0);
                }}
            />
            {invalid && (
                <div className="pabs invalid-feedback" style={top} >
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyOC = ({
    readOnly, input, meta: { touched, error }, prefix = "Q. ", placeholder, _onChange, top, decimalScale = 2,
}) => {
    const invalid = touched && error;
    top ? top : (top = { top: "none" })
    return (
        <div>
            <NumberFormat
                readOnly={readOnly}
                className={classNames('form-control text-left', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    if (_onChange)
                        _onChange(values.floatValue);
                    input.onChange(values.value);
                }}
                onFocus={(e) => {
                    e.target.setSelectionRange(0, 0);
                }}
            />
            {invalid && (
                <div className="pabs invalid-feedback" style={top} >
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
    input, meta: { touched, error }, label, disabled, _onChange
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#00334E"
                height={22}
                width={45}
                disabled={disabled}
                onChange={(value) => {
                    if (_onChange)
                        _onChange(value);
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        checked={input.value}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                        onChange={(e) => { input.onChange(e ? e : null); }}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        onChangeEditado,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={"Seleccionar"}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                    if (onChangeEditado) {
                        onChangeEditado(e[valueKey])
                    }
                }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


const Option = props => {
    console.log('props options ', props)
    const data = props.data.options;
    return (
        <div>
            <components.Option {...props} />

        </div>
    );
};

export const SelectFieldGroup = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }


    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                components={{ Option }}
                isSearchable={isSearchable}
                options={options}
                placeholder={"Seleccionar"}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderSelecttree = ({ options, required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div >
            <TreeSelect
                style={{ width: '100%' }}
                value={input.value}
                className={classNames('react-select-container form-control p-0', { 'is-invalid': invalid })}
                dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                placeholder="Seleccionar"
                allowClear
                treeData={options}
                treeDefaultExpandAll
                onChange={input.onChange}
                style={{ height: "36px" }}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderSelectField = ({ input, disabled, onChange, onChangePersonal, pabs = true, options, prerequisitos_list, key = 0, valueKey = 'value', labelKey = 'label', meta: { touched, error }, readOnly }) => {
    const invalid = touched && error;
    return (
        <div>
            <select {...input}
                readOnly={readOnly}
                disabled={disabled}
                key={key}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e.target.value);
                    }
                    if (onChangePersonal) {
                        onChangePersonal(e.target.value);
                    }
                    input.onChange(e);
                }}
                className={classNames('form-control ', { 'is-invalid': invalid })}>
                <option value="" className="select-text-default" style={{ color: '#918080' }}>
                    Seleccione

                </option>);
                {options.map((opcion, index) => {
                    return (<option
                        key={typeof (opcion) === "string" + key ? opcion : String(opcion[valueKey]) + String(key)}
                        value={typeof (opcion) === "string" ? opcion : opcion[valueKey]}>
                        {typeof (opcion) === "string" ? opcion : opcion[labelKey]}

                    </option>);
                })}
            </select>
            {invalid && <div className={`${pabs ? 'pabs' : ''} invalid-feedback`}>
                {error}
            </div>}
        </div>
    )
};





export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder = "Seleccionar...",
        valueKey,
        labelKey,
        key,
        changePersonalizado,
        meta: { touched, error },
        top,
    }) => {

    const invalid = touched && error;
    top ? top : (top = { top: "none" })
    return (
        <React.Fragment>
            <Async
                key={key}
                isClearable={isClearable}
                cacheOptions
                multi={true}
                autoload={false}
                cache={false}
                className={classNames('react-select-container ', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                placeholder="Seleccionar.."
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (changePersonalizado) {
                        changePersonalizado(e)
                    }
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
            />
            {invalid && (
                <div className="pabs invalid-feedback" style={top}>
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder = "Seleccionar...",
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <ImageUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    // console.log("INPUT", input)
    if (input.value == "") { input.value = new Date(moment()) }
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                // defaultValue={input.value || moment('12:08', 'DD/MM/YYYY')}
                onChange={event => input.onChange(event)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
                dateFormat="yyyy/MM/dd"
                selected={input.value ? input.value : null}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};



export const renderAntTimeField = ({ hour_value, required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    const format = 'HH:mm';
    const value = input.value ? moment(input.value) : moment(hour_value, format);
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <AntTimePicker
                defaultValue={value}
                format={format}
                // defaultValue={moment('12:08', format)}
                className="custom-ant-time-picker"
                onChange={input.onChange}
                value={value}
                allowClear={false}
                size='small'
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};



export const renderDropDownField = ({ className, selectedDate = new Date(), maxDate = '2030-12-31', minDate = '1950-01-01', input, meta: { touched, error } }) => {
    const invalid = touched && error;

    return (
        <div className="">
            <DropdownDate
                startDate={minDate}
                endDate={maxDate}
                selectedDate={input.value ? input.value : selectedDate}
                names={
                    {
                        year: 'Año',
                        month: 'Mes',
                        day: 'Dia'
                    }
                }
                ids={                               // optional
                    {
                        year: 'Año',
                        month: 'Mes',
                        day: 'dia'
                    }
                }
                order={
                    ['day', 'month', 'year']
                }
                generateMonthOptions={(e) => { console.log(e) }}
                classes={                           // optional
                    {
                        dateContainer: 'btn-group btn-group-lg d-flex flex-column flex-md-row',
                        yearContainer: 'classes',
                        monthContainer: 'classes',
                        dayContainer: '',
                        year: 'btn btn-outline-secondary dropdown', // select
                        month: 'btn btn-outline-secondary month dropdown',
                        day: 'btn btn-outline-secondary dropdown',
                        yearOptions: 'dropop',              //options
                        monthOptions: 'dropop',
                        dayOptions: 'dropop'
                    }
                }
                onDateChange={(date) => {
                    console.log(date)
                    input.onChange(date)
                }}
            />
            {invalid && <div className="invalid-feedback" >{error} </div>}
        </div>
    )
}


export const renderPercentage = ({ input, _onChange, type, disabled, addClass, meta: { touched, error }, simbolo = ' %' }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                decimalScale={2} fixedDecimalScale={true}
                disabled={disabled}
                value={input.value} thousandSeparator={true} suffix={simbolo}
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!_onChange) {
                        _onChange(values.floatValue);
                    }
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderCurrencyOC,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    AsyncSelectField,
    SelectField,
    SelectFieldGroup,
    renderPercentage,
    renderFilePicker,
    renderSelecttree
};
