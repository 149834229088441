import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import CardSimple from "../../../Utils/Cards/cardSimple";
import RechazoForm from "./rechazoForm";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import TabDetalle from "./tabDetalle";
import TabLinks from "./tabLinks";

import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";

export default class OrdenCompraGrid extends Component {
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.leer(id);
    }
    state = {
        open_modal: false,
    };
    handleSubmit = (values) => {
        let id = this.props.match.params.id;
        values.id = id;
        this.props.refusePM(values);
    };
    handleModal = (value) => {
        this.setState({ open_modal: value });
    };
    handleClick = () => {
        let id = this.props.match.params.id;
        this.props.acceptPM({ id, pm_oc: "pm" });
    };
    getoptions = (estate) => {
        const { item } = this.props;
        console.log(estate);
        if (estate === 100) {
            // if(!item.comprado){
            return (
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link
                        className="btn btn-azul-claro m-1 align-self-center"
                        to={`/ordencompra/${this.props.match.params.id}`}
                    >
                        Generar orden de compra
                    </Link>
                </div>
            );
            // }
        } else {
            return (
                <div className="row  justify-content-sm-center justify-content-center">
                    <button
                        onClick={() => {
                            this.handleModal(true);
                        }}
                        className="btn btn-secondary m-1 align-self-center"
                    >
                        Rechazar
                    </button>
                    <button
                        onClick={() => {
                            this.handleClick();
                        }}
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Aprobar
                    </button>
                </div>
            );
        }
    };
    getJustificacion = (item) => {
        if (item.proceso_estado === "Rechazado") {
            let ultiJustificacion =
                item.justificacion[item.justificacion.length - 1];
            return (
                <div className="col-12">
                    <h6 className="t-azul">Justifiación de Rechazo</h6>
                    <span>{ultiJustificacion.justificacion}</span>
                </div>
            );
        }
    };
    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
        } = this.props;
        return (
            <CardSimple titulo={item.no_orden}>
                <Modal
                    open={this.state.open_modal}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.setState({ open_modal: false });
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            {" "}
                            Justifiación de rechazo
                        </span>
                    </div>
                    {item.proceso_estado != "Rechazado" ? (
                        <RechazoForm
                            onSubmit={this.handleSubmit}
                            handleModal={this.handleModal}
                        />
                    ) : (
                        <div className="form-validate mb-lg text-center">
                            <span style={{ fontSize: 20 }}>
                                {"Esta Peticion de materiales ya fue rechazada"}
                            </span>
                        </div>
                    )}
                </Modal>
                <div className="row justy-content-center">
                    <div className=" col-md-6 col-12">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="t-azul">Fecha</h6>

                                <span className="t-musgo">
                                    <RenderDateTime fecha={item.fecha} />
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="t-azul">Productos:</h6>
                                {item.comprado ? (
                                    <span className="t-musgo">
                                        Todos los productos ya fueron comprados
                                    </span>
                                ) : (
                                    <span className="t-musgo">
                                        Aun hay productos por comprar
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="col-12">
                            <h6 className="t-azul">Descripción</h6>
                            <span className="t-musgo">{item.descripcion}</span>
                        </div>
                        {this.getJustificacion(item)}
                    </div>
                </div>
                <br />
                <Tabs
                    defaultActiveKey="DETALLEPM"
                    tabBarPoition="top"
                    onChange={(key) => {
                        key === "LINKS" &&
                            this.props.listChilds(this.props.match.params.id);
                    }}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="Detalle" key="DETALLE">
                        <TabDetalle item={item} loader={loader} />
                    </TabPane>
                    {item.proceso_estado == 100 && (
                        <TabPane tab="Links " key="LINKS">
                            <TabLinks hijos={hijos} loader={loader} />
                        </TabPane>
                    )}
                </Tabs>
                <br />
                {this.getoptions(item.proceso_estado)}
            </CardSimple>
        );
    }
}
