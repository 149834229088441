import React, { Component, Fragment } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";
import {
    RenderMoneda,
    RenderDateTime,
} from "Utils/renderField/renderTableField";

export default class HistoricoGrid extends Component {
    componentDidMount() {
        this.props.getHistoria(1, this.props.id_bodega);
    }

    pageChange = (page) => {
        this.props.getHistoria(page, this.props.id_bodega);
    };

    render() {
        const {
            historia_movimientos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page2,
            item,
        } = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={historia_movimientos}
                    page={page2}
                    loading={loader}
                    onPageChange={this.pageChange}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="20%"
                        isKey
                        hidden
                        dataFormat={standardActions({ editar: "e", eliminar })}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataFormat={(cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="no_despacho">
                        NO MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despachado_por"
                        dataFormat={(cell, row) => (
                            <span>{cell ? cell.nombre : ""}</span>
                        )}
                    >
                        DESPACHADO POR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_movimiento_label"
                    >
                        TIPO MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="bodega_origen"
                        dataFormat={(cell, row) => (
                            <span>
                                {row.bodega_origen
                                    ? row.bodega_origen.nombre
                                    : "Sin registro"}{" "}
                                /{" "}
                                {row.bodega_destino
                                    ? row.bodega_destino.nombre
                                    : "Sin bodega"}{" "}
                            </span>
                        )}
                    >
                        ORIGEN/DESTINO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        );
    }
}
