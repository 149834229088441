import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
// formulario
import DespachoForm from "./despachoForm";

export default class DespachoContainer extends Component {
    componentDidMount() {
        let id = this.props.match.params.id;
        if (id != undefined && id != null) {
        } else {
            return <Redirect to="/ordenesventa" />;
        }
    }
    handleSubmit = (values) => {
        const { DespacharProducto } = this.props;
        let id = this.props.match.params.id;
        if(values.bodega_destino){
            values.tipo_movimiento = 100; //Despacho a Sucursal
        } else {
            values.tipo_movimiento = 40;   //Despacho a cliente final
        }
        console.log("submit", values);
        DespacharProducto(values, id);
    };
    render() {
        const { loader } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoForm
                        orden={this.props.match.params.id}
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            tipo_movimiento: 40,
                            tipo_despacho: 40,
                        }}
                    />
                </LoadMask>
            </CardFormulario> 
        );
    }
}
