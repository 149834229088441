import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderTextArea } from "../../../Utils/renderField";

class AnulacionForm extends Component {
    cerrar = () => {
        this.props.cerrarModal(false);
    };

    render() {
        const { handleSubmit } = this.props;
        return (
            <form
                name="anularVentaForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-12 row m-0 p-0">
                        <div className="form-group has-feedback col-12 row">
                            <div className={`form-group has-feedback col-12 `}>
                                <label className="t-azul" htmlFor="descripcion">
                                    Descripción de anulación
                                </label>
                                <Field
                                    name="descripcion"
                                    component={renderTextArea}
                                    top={{ top: "94px" }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center ">
                            <button
                                type="button"
                                onClick={this.cerrar}
                                className="btn btn-secondary align-self-center"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-azul-claro m-1 ml-md-4 align-self-center"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

AnulacionForm = reduxForm({
    form: "anularVentaForm", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.descripcion) {
            errors.descripcion = "Campo requerido";
        }

        return errors;
    },
})(AnulacionForm);

export default AnulacionForm;
