import _ from "lodash";

const validate = values => {
    const errors = {};
    const errors_detalles = [];

    if (values.asignaciones) {
        if (values.asignaciones.length) {
            values.asignaciones.forEach((item, index) => {
                const detalleErrors = {};
                if (!item.actividad) {
                    detalleErrors.actividad = "Campo obligatorio";
                    errors_detalles[index] = detalleErrors;
                }
                if (!item.empleado) {
                    detalleErrors.empleado = "Campo obligatorio";
                    errors_detalles[index] = detalleErrors;
                }
                if (!item.empresa) {
                    detalleErrors.empresa = "Campo obligatorio";
                    errors_detalles[index] = detalleErrors;
                }
                if (!item.horas_trabajadas) {
                    detalleErrors.horas_trabajadas = "Campo obligatorio";
                    errors_detalles[index] = detalleErrors;
                }
                if (!item.tipo_horario) {
                    detalleErrors.tipo_horario = "Campo obligatorio";
                    errors_detalles[index] = detalleErrors;
                }
            });
            if (errors_detalles.length)
                errors.asignaciones = errors_detalles;
        }
    }

    return errors;

};

export default validate;
