import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import ProductosGrid from "./productoGrid";
import FacturasGrid from "./facturasGrid";
import TransporteDetalle from "./transporteDetalle";
import GastoGrid from "./Gastos/gastosGrid";
import PagosContainer from "./Pagos/pagocContainer";
import ReintegroGrid from "./Reintegros/reintegro";
import OCpreview from "./previewOC/OCpreview";
import DespachoContainer from "./Despacho/despachoContainer";

import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { TIPO_ENTREGA } from "../../../../../utility/constants";

import LoadMask from "Utils/LoadMask/LoadMask";

export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.props.clear();
        let id = this.props.match.params.id;
        this.props.leerOrdenCompra(id);
        this.props.getTipoGastos();
    }
    getEntrega = (valor) => {
        let entrega = "";
        TIPO_ENTREGA.forEach((item, index) => {
            if (item.value === valor) {
                entrega = item.label;
            }
        });
        return entrega;
    };
    render() {
        const {
            item,
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            cuentas,
            tipo_gastos,
        } = this.props;
        let CPid = this.props.match.params.id;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <div
                    className="Container shadow bg-white"
                    style={{ borderRadius: "10px" }}
                >
                    {item && item.orden && item.orden.proceso_estado === 100 && (
                        <div className="m-t p-t fnt-size">
                            <div
                                className="row m-none"
                                style={{ paddingTop: "15px" }}
                            >
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-titulo m-none">
                                        {item ? item.orden.no_orden : ""}
                                    </h3>
                                </div>
                                <div className="row col-lg-6 col-md-12">
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                Descuento:{" "}
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.orden.descuento}
                                                    simbolo={getSimbolo(
                                                        item.orden.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                IVA:{" "}
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.orden.iva}
                                                    simbolo={getSimbolo(
                                                        item.orden.moneda
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex align-items-end justify-content-end">
                                        <div className="col-8 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                Total:{" "}
                                            </span>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <span className="m-none t-primary semibold">
                                                <RenderMoneda
                                                    monto={item.orden.monto}
                                                    simbolo={getSimbolo(
                                                        item.orden.moneda
                                                    )}
                                                />{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* DATOS GENERALES */}
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Emitida por:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.emitido_por.first_name} ${item.orden.emitido_por.last_name}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 ">
                                    <span className="m-none t-primary semibold">
                                        Autorizada por:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.aprobado_por.first_name} ${item.orden.aprobado_por.last_name}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Dirigida a:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.proveedor.nombre}`}</span>
                                </div>
                            </div>
                            <br />
                            {/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Descripción
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-texto semibold">{`${item.orden.descripcion}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES DE ENTREGA */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Fecha y lugar de entrega{" "}
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-md-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        Fecha aproximada de entrega:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">
                                        <RenderDateTime
                                            fecha={
                                                item.orden.fecha_prox_entrega
                                            }
                                        ></RenderDateTime>
                                    </span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        Condicion de entrega:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${this.getEntrega(
                                        item.orden.tipo_entrega
                                    )}`}</span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        Direccion:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.direccion}`}</span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <span className="m-none t-primary semibold">
                                        Encargado:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.encargado}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES COMERCIALES */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Condiciones Comerciales
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Tipo de pago:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${
                                        item.orden.pago_inmediato
                                            ? "Pago Inmediado"
                                            : "Al Credito"
                                    }`}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Moneda:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${item.orden.moneda}`}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Nit de factura:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${
                                        item.orden.nit_facturacion
                                            ? item.orden.nit_facturacion
                                            : "--"
                                    }`}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        A nombre de:{" "}
                                    </span>
                                    <br />
                                    <span className="m-none t-texto semibold">{`${
                                        item.orden.nombre_factura
                                            ? item.orden.nombre_factura
                                            : "--"
                                    }`}</span>
                                </div>
                                {item.dias_al_credito > 0 && (
                                    <div className="col-md-6 col-12">
                                        <span className="m-none t-primary semibold">
                                            Días de crédito:{" "}
                                        </span>
                                        <br />
                                        <span className="m-none t-texto semibold">{`${item.orden.dias_al_credito}`}</span>
                                    </div>
                                )}
                            </div>
                            <br /> {/* DESCRIPCION DE ANULACION */}
                            {!item.estado && (
                                <div>
                                    <div className="col-12">
                                        <span className="m-none t-texto bold">
                                            Descripción de Anulacion
                                        </span>
                                    </div>
                                    <hr
                                        style={{
                                            borderTop: "1px solid #00334e",
                                            margin: "0px 15px 10px 15px",
                                        }}
                                    />
                                    <div className="row m-none">
                                        <div className="col-12">
                                            <span className="m-none t-musgo semibold">{`${
                                                item.orden.descripcion_anulacion
                                                    ? item.orden.descripcion
                                                    : " "
                                            }`}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {item.orden.documento && (
                                <div className="row m-none">
                                    <div className="col-12">
                                        <a
                                            target="_blank"
                                            href={item.orden.documento}
                                            className="btn btn-azul-claro align-self-center"
                                        >
                                            Documento
                                        </a>
                                    </div>
                                    <br />
                                    {/*  */}
                                </div>
                            )}
                            <div className="row">
                                <div className="mb-4 col-12">
                                    <div className="mb-4 ">
                                        <div className="">
                                            <h6 className="m-0"></h6>
                                        </div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <Tabs
                                                defaultActiveKey="DETALLEOC"
                                                tabBarPoition="top"
                                                onchange={this.callback}
                                                renderTabBar={() => (
                                                    <ScrollableInkTabBar />
                                                )}
                                                renderTabContent={() => (
                                                    <TabContent />
                                                )}
                                            >
                                                <TabPane
                                                    tab="Productos"
                                                    key="PRODUCTOS"
                                                >
                                                    <ProductosGrid
                                                        detalle_orden={
                                                            item.orden
                                                                .detalle_orden
                                                        }
                                                        moneda={item.moneda}
                                                    />
                                                </TabPane>
                                                <TabPane
                                                    tab="Pagos"
                                                    key="PAGOS"
                                                >
                                                    <PagosContainer
                                                        id_cuenta={
                                                            this.props.match
                                                                .params.id
                                                        }
                                                        id_empresa={
                                                            item.empresa
                                                        }
                                                        selectCuentas={
                                                            this.props
                                                                .selectCuentas
                                                        }
                                                        {...this.props}
                                                        cuentas={cuentas}
                                                        orden={item}
                                                        tipo_gastos={
                                                            tipo_gastos
                                                        }
                                                        pagos_credito={
                                                            this.props
                                                                .pagos_credito
                                                        }
                                                        moneda={item.moneda}
                                                    />
                                                </TabPane>
                                                {/* <TabPane tab="TRANSPORTE" key="TRANSPORTE">
                                            <TransporteDetalle
                                                tipo_entrega={item.tipo_entrega }
                                                encargado = { item.encargado }
                                                nombreT = { item.nombre_transporte }
                                            />
                                        </TabPane> */}
                                                <TabPane
                                                    tab="Facturas"
                                                    key="FACTURAS"
                                                >
                                                    <FacturasGrid
                                                        facturas={
                                                            item.orden.facturas
                                                        }
                                                        moneda={item.moneda}
                                                        OCid={CPid}
                                                        BillOC={
                                                            this.props.BillOC
                                                        }
                                                        DeleteOC={
                                                            this.props.DeleteOC
                                                        }
                                                    />
                                                </TabPane>
                                                <TabPane
                                                    tab="Gastos"
                                                    key="GASTOS"
                                                >
                                                    <GastoGrid
                                                        id_cuenta={
                                                            this.props.match
                                                                .params.id
                                                        }
                                                        cuenta={item}
                                                        orden={item}
                                                        tipo_gastos={
                                                            tipo_gastos
                                                        }
                                                        selectCuentas={
                                                            this.props
                                                                .selectCuentas
                                                        }
                                                        {...this.props}
                                                    />
                                                </TabPane>
                                                <TabPane
                                                    tab="Reintegro"
                                                    key="REINTEGRO"
                                                >
                                                    <ReintegroGrid
                                                        id_cuenta={
                                                            this.props.match
                                                                .params.id
                                                        }
                                                        item={item}
                                                        orden={item}
                                                        selectCuentas={
                                                            this.props
                                                                .selectCuentas
                                                        }
                                                        {...this.props}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Ingreso" key="INGRESO">
                                                    <DespachoContainer
                                                        data={this.props.history}
                                                        HistoryDispatch={this.props.HistoryDispatch}
                                                        AutorizarDespacho={this.props.AutorizarDespacho}
                                                        orden={item.orden}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {item && item.proceso_estado === 60 && (
                        <OCpreview
                            item={item}
                            loader={loader}
                            id={this.props.match.params.id}
                            refusePM={this.props.refusePM}
                            acceptPM={this.props.acceptPM}
                            clear={this.props.clear}
                        />
                    )}
                </div>
            </LoadMask>
        );
    }
}
