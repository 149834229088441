import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
// import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderSwitch, renderNumber } from '../../../Utils/renderField';
import { SelectField, AsyncSelectField } from '../../../Utils/renderField/renderField.js'
import { Link, Redirect } from 'react-router-dom';

import { api } from '../../../../../utility/api';
import validate from './validacion';

const tipo_horario = [
    { value: 1, label: 'Horario laboral' },
    { value: 5, label: 'Horario extra' }
]

const getEmpleados = (search) => {
    return api.get("empleados", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getEmpresas = (search) => {
    return api.get("empresa", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getActividades = (search) => {
    return api.get("actividades", { search }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const renderAsignaciones = ({ fields, meta: { error, submitFailed } }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "20%" }}>Empleado</th>
                            <th style={{ width: "20%" }}>Empresa</th>
                            <th style={{ width: "20%" }}>Actividad</th>
                            <th style={{ width: "20%" }}>Horas trabajadas</th>
                            <th style={{ width: "15%" }}>Tipo de Horario</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((asignacion, index) => (
                            <tr key={index}>
                                <td className="text-center sin-borde-top">
                                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.empleado`}
                                        component={AsyncSelectField}
                                        loadOptions={getEmpleados}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.empresa`}
                                        component={AsyncSelectField}
                                        loadOptions={getEmpresas}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.actividad`}
                                        component={AsyncSelectField}
                                        loadOptions={getActividades}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${asignacion}.horas_trabajadas`}
                                        type="text"
                                        component={renderNumber}
                                        decimalScale={2}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    {/* <Field
                                        type="text"
                                        component={renderSwitch}
                                        /> */}
                                    <Field
                                        name={`${asignacion}.tipo_horario`}
                                        label="unidad_medida"
                                        valueKey="value"
                                        labelKey="label"
                                        options={tipo_horario}
                                        component={SelectField}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
)

const AsignacionesForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="AsignacionesForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center py-1" />
            <div className="form-group has-feedback  col-12">
                <FieldArray name="asignaciones" component={renderAsignaciones} />

            </div>
            <div className="row  justify-content-sm-around justify-content-center">
                <Link className="btn btn-secondary  align-self-center" to="/asignacion_actividades" >CANCELAR</Link>
                <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'AsignacionForm', // a unique identifier for this form
    validate: validate
})(AsignacionesForm);
