import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import UsuariosForm from "./UsuariosForm";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
// import { selectRoles, selectEmpresas } from '../../../../redux/modules/cuenta/register';
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import { BanderaEmpresa } from "../../../../../utility/constants";

class Registro extends Component {
    state = {
        id: 0,
        editar: false,
        titulo: "Agregar usuario",
    };
    static propTypes = {
        editar: PropTypes.func.isRequired,
    };
    componentWillMount() {
        const { selectEmpresas2, selectRoles, selectSucursales, leer } = this.props;
        let idU = this.props.match.params.id;

        selectRoles();
        selectEmpresas2();
        selectSucursales();

        if (
            this.props.match.params.id != null &&
            this.props.match.params.id > 0
        ) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
                titulo: "Editar usuario",
            });
        }
    }
    format = (array = []) => {
        const nuevoArray = [];
        array.forEach((item, index) => {
            nuevoArray[index] = { label: item.nombre, value: item.id };
        });
        return nuevoArray;
    };
    onSubmit = (values) => {
        this.setState({ id: this.props.match.params.id }, () => {
            // console.log(this.state)
            //this.props.editar(this.state.id,values);
        });
        values.profile = {
            ...values.profile,
            empresa: BanderaEmpresa,
        };
        if(values.profile.sucursal == ""){ values.profile.sucursal = null}
        this.props.match.params.id
            ? this.props.editar(this.props.match.params.id, values)
            : this.props.crear(values);
    };

    render() {
        const { loader, roles, empresas, sucursales } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light>
                    <UsuariosForm
                        vendedores_form={false}
                        editar = {this.state.editar}
                        onSubmit={this.onSubmit}
                        format={this.format}
                        roles={roles}
                        empresas={empresas}
                        sucursales={sucursales}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default Registro;
