import React from "react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";

import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderCurrency,
    renderTextArea,
    AsyncSelectField,
    renderSelectField,
} from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import {
    renderDatePicker,
    renderAntTimeField,
} from "../../../Utils/renderField/renderField";
import { api } from "../../../../../utility/api";
import { Component } from "react";

class ItemArray extends Component {
    state = {
        producto: {},
    };
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        // this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions2: (search) => props.getProductos(search),
        };
    }
    componentDidMount() {
        if (this.props.producto_seleccionado) {
            this.setState({ producto: this.props.producto_seleccionado });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.producto_seleccionado !== prevProps.producto_seleccionado
        ) {
            const { todos, index } = this.props;
            if (this.props.producto_seleccionado) {
                this.setState({ producto: this.props.producto_seleccionado });
            }
        }
    }
    render() {
        const { index, trabajador, eliminar, editar } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                {/* <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <div className="d-flex justify-content-center">
                        <div className="px-2">
                            <img className="action-img " src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                title="Borrar"
                                onClick={() =>{
                                    eliminar()
                                }}
                            />
                        </div>
                    </div>
                </td> */}
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${trabajador}`}
                        name={`${trabajador}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions2}
                        top={{ top: "67px", position: "inherit" }}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({ producto: e });
                        }}
                        className="form-control"
                        disabled={true}
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${trabajador}.cantidad`}
                        type="number"
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    <label>{this.state.producto.unidad_texto}</label>
                </td>
                <td className="sin-borde-top">
                    {this.props.produccion.fila.cantidad
                        ? this.props.total * this.props.produccion.fila.cantidad
                        : this.props.total}
                </td>
            </tr>
        );
    }
}

const renderReporte = ({
    fields,
    meta: { error, submitFailed },
    getEmpleados,
    getProductos,
    proveedor,
    produccion,
    todos_productos,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <div className="w-100 row py-4"style={{ width: "10%" }}>
                    <div className="col-5">
                        <h5 className="t-primary semibold">
                            <span className="t-texto bold">
                                Producto:
                            </span>
                            &nbsp;&nbsp;
                            {produccion && produccion.fila && produccion.fila.producto.nombre}
                        </h5>
                    </div>
                    <div className="col-5">
                        <h5 className="t-primary semibold">
                            <span className="t-texto bold">
                                Cantidad en esta OP:
                            </span>
                            &nbsp;&nbsp;
                            {produccion && produccion.fila && produccion.fila.cantidad} &nbsp;{produccion && produccion.fila && produccion.fila.producto.unidad_medida}
                        </h5>
                    </div>
                </div>
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            {/* <th></th> */}
                            <th style={{ width: "30%" }}>PRODUCTO</th>
                            <th style={{ width: "25%" }}>CANTIDAD</th>
                            <th style={{ width: "25%" }}>UM</th>
                            <th style={{ width: "20%" }}>TOTAL OP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((trabajador, index) => {
                            let todos = fields.getAll();
                            const producto = todos_productos[index];

                            return (
                                <ItemArray
                                    proveedor={proveedor}
                                    producto_seleccionado={producto}
                                    index={index}
                                    key={index}
                                    todos={todos}
                                    trabajador={trabajador}
                                    getProductos={getProductos}
                                    produccion={produccion}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                    field={fields ? fields.get(index) : null}
                                    total={
                                        fields
                                            ? fields.get(index).cantidad
                                            : null
                                    }
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div> */}
        </div>
        {(submitFailed || error) && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos

class RecetaForm extends React.Component {
    state = {
        orden: null,
    };
    getOrdenes = (search) => {
        return api
            .get("produccion", { search, empresa: this.props.empresa })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getProductos = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        params.empresa__entidad = this.props.empresa;
        return api
            .get("productos", params)
            .then((data) => {
                if (data) {
                    return data.results;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const {
            handleSubmit,
            estadoRead,
            empresa,
            todos_productos,
        } = this.props;
        return (
            <form
                name="RecetaForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-12">
                        <FieldArray
                            name="receta"
                            label="receta"
                            component={renderReporte}
                            className="form-control"
                            getProductos={this.getProductos}
                            todos_productos={todos_productos}
                            produccion={this.props.produccion}
                        />
                    </div>
                </div>
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/reportar_produccion/${empresa}`}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

RecetaForm = reduxForm({
    form: "RecetaForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            // empresa: validators.exists()('Este campo es requerido'),
            // sueldo: validators.exists()('Este campo es requerido'),
            // descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(RecetaForm);

const selector = formValueSelector("RecetaForm");
RecetaForm = connect((state) => {
    const receta = selector(state, "receta");
    let todos_productos = [];
    if (receta) {
        todos_productos = receta;
    }

    return {
        todos_productos,
    };
})(RecetaForm);

export default RecetaForm;
