import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    AsyncSelectField,
} from "../../../Utils/renderField/renderField";
import { Link, Redirect } from "react-router-dom";
import { SelectField } from "../../../Utils/renderField/renderField.js";

import { api } from "api";

const UsuariosForm = (props) => {
    const {
        handleSubmit,
        regiones,
        format,
        roles,
        empresas,
        sucursales,
        vendedores_form,
    } = props;
    return (
        <form
            name="loginForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <br />
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-sm-5  col-12">
                    <label className="t-azul" htmlFor="username">
                        Nombre de usuario <small> (Campo obligatorio) </small>
                    </label>
                    <Field
                        name="username"
                        label="Usuario"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback  col-sm-5  col-12">
                    <label className="t-azul" htmlFor="first_name">
                        Nombre <small> (Campo obligatorio) </small>
                    </label>
                    <Field
                        name="first_name"
                        label="Nombre"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-sm-5  col-12">
                    <label className="t-azul" htmlFor="last_name">
                        Apellido <small> (Campo obligatorio) </small>
                    </label>
                    <Field
                        name="last_name"
                        label="Apellido"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback  col-sm-5  col-12">
                    <label className="t-azul" htmlFor="password">
                        Contraseña
                    </label>
                    <Field
                        name="password"
                        label="Contraseña"
                        component={renderField}
                        type="password"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-sm-5  col-12">
                    <label className="t-azul" htmlFor="email">
                        Correo <small> (Campo obligatorio) </small>
                    </label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-sm-5  col-12">
                    <label className="t-azul" htmlFor="phone">
                        Teléfono
                    </label>
                    <Field
                        name="profile.phone"
                        label="phone"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback col-sm-5 offset-sm-1 col-12">
                    <label className="t-azul" htmlFor="address">
                        Dirección
                    </label>
                    <Field
                        name="profile.address"
                        label="address"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-sm-5  col-12">
                    <label className="t-azul" htmlFor="role">
                        Sucursal
                    </label>
                    <Field
                        name="profile.sucursal"
                        label="sucursal"
                        options={format(sucursales.results)}
                        component={renderSelectField}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                {!vendedores_form && (
                    <div className="form-group has-feedback col-sm-5  col-12">
                        <label className="t-azul" htmlFor="role">
                            Role <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name="profile.role"
                            label="role"
                            options={format(roles)}
                            component={renderSelectField}
                            top={{ top: "5px", position: "inherit" }}
                        />
                    </div>
                )}
                <div className="form-group has-feedback col-sm-5  col-12"></div>
            </div>
            <br />
            <div className="row  justify-content-sm-center justify-content-center">
                <Link
                    className="btn btn-secondary  m-1 align-self-center"
                    to={`${!vendedores_form ? "/usuarios" : "/vendedores"}`}
                >
                    Cancelar
                </Link>
                <button
                    type="submit"
                    className="btn btn-azul-claro m-1 ml-4  align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "EditarUsuarioForm", // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if (!data.username) {
            errors.username = "Campo requerido";
        }
        if (!data.first_name) {
            errors.first_name = "Campo requerido";
        }
        if (!data.last_name) {
            errors.last_name = "Campo requerido";
        }
        if(!data.password && !props.editar){
            errors.password = "Campo requerido"
        }
        if (!data.email) {
            errors.email = "Campo requerido";
        }
        if(!data.profile){
            errors.profile = { role: "Campo requerido" }
        }
        if (data.profile && !props.vendedores_form) {
            if(!data.profile.role){
                errors.profile = { role:"Campo requerido" }
            }
        }
        return errors;
    },
})(UsuariosForm);
