import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import CuentasForm from './cuentasForm';

class Empresa extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'Agregar cuenta de banco',
    }
    componentWillMount(){
        this.props.selectEmpresas();

        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'Editar cuenta de banco'
            });
        }
    }
    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        this.state.editar? editar(id,values) : crear(values);
    }

    render() {
        const { loader, empresas } = this.props;
        return (
            <div className="Container">
                <div className="row">
                <div className="mb-4 col-12">
                    <div className="mb-4 card shadow card-small m-t" style={{ borderRadius: "10px" }}>
                        <div className="card-body">
                            <div className="p-0 px-3 pt-3">
                                    <LoadMask loading={loader} light>
                                        <CuentasForm onSubmit={this.onSubmit} format={this.format} empresas={empresas} />
                                    </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Empresa;
