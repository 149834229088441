import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Puestos/Puestos';
import Puestos from './Puestos';

const mstp = state => {
    return {...state.puestos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Puestos)
