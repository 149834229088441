
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "actividades",
    "actividades",
    "ActividadesForm",
    "/actividades",
);

export default handleActions(reducers, initialState);
