import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";

export default class ProductosGrid extends Component {
    getsimbolo = (valor) => {
        let simbolo = "";
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo;
            }
        });
        return simbolo + ". ";
    };
    render() {
        const {
            detalle_orden,
            moneda,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
        } = this.props;
        return (
            <div className="margin-tabgrid px-3">
                <Grid
                    hover
                    striped
                    data={{ count: 10, results: detalle_orden }}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    pagination={false}
                >
                    <TableHeaderColumn
                        dataField="producto"
                        dataSort
                        dataFormat={(cell) => cell.nombre}
                        isKey
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_unitario"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                monto={cell ? cell : 0}
                                simbolo={this.getsimbolo(moneda)}
                            />
                        )}
                    >
                        COSTO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="cantidad" dataSort dataAlign="center">
                        CANTIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                monto={
                                    cell
                                        ? row.cantidad * row.precio_unitario
                                        : 0
                                }
                                simbolo={this.getsimbolo(moneda)}
                            />
                        )}
                    >
                        SUBTOTAL
                    </TableHeaderColumn>
                </Grid>
            </div>
        );
    }
}
