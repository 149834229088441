import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/bitacora/bitacora';
import BitacoraGrd from './BitacoraGrd';

const mstp = state => {
    return {...state.bitacora, permisos: state.login.me.permisos,}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BitacoraGrd)
