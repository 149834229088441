import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import BodegaForm from "./BodegaForm";
import { BanderaEmpresa } from "../../../../../utility/constants";

class BodegaEditar extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
    };
    componentWillMount() {
        this.props.selectEmpresas2();

        if (
            this.props.match.params.id != null &&
            this.props.match.params.id > 0
        ) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }
    onSubmit = (values) => {
        const { crear, editar } = this.props;
        let id = this.props.match.params.id;
        values.empresa = `${BanderaEmpresa}`;
        this.state.editar ? editar(id, values) : crear(values);
    };
    render() {
        const { loader, empresas } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <BodegaForm onSubmit={this.onSubmit} empresas={empresas} />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default BodegaEditar;
