import React, { Component } from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField, AsyncSelectField } from "../../../Utils/renderField";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { api } from "api";
import {
    RenderCurrency,
    RenderNumber,
} from "../../../Utils/renderField/renderReadField";

class ItemArray extends Component {
    state = {
        producto: null,
    };
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if (producto !== undefined && producto.cantidad_actual > 0) {
            if (producto.es_bodega) {
                todos[index]["despachar"] = producto.cantidad_actual;
            } else {
                todos[index]["despachar"] = 0;
            }
        }
    };
    componentDidMount() {
        if (this.props.producto_seleccionado) {
            this.setState({
                producto: this.props.producto_seleccionado.producto,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.producto_seleccionado.producto !==
            prevProps.producto_seleccionado.producto
        ) {
            const { todos, index } = this.props;
            if (this.props.producto_seleccionado) {
                this.setState({
                    producto: this.props.producto_seleccionado.producto,
                });
            }
        }
    }
    render() {
        const { getProductosOrden, eliminar, index, Despacho } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td
                    className="text-center sin-borde-top"
                    style={{ width: "48px" }}
                >
                    <img
                        style={{ padding: "10%" }}
                        className="action-img iconoEliminarTabla iconoEliminar"
                        src={require("assets/img/icons/Cerrar.png")}
                        alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar();
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${Despacho}.producto`}
                        loadOptions={getProductosOrden}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({ producto: e });
                        }}
                        className="form-control"
                    />
                </td>

                <td className="sin-borde-top">
                    {this.state.producto ? (
                        <label>
                            <RenderNumber
                                value={this.state.producto.cantidad}
                            />{" "}
                            <span>
                                {this.state.producto.label_unidad_inventario}
                            </span>
                        </label>
                    ) : (
                        <span>--</span>
                    )}
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${Despacho}.despachar`}
                        type="number"
                        // min="0" max="5"
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
            </tr>
        );
    }
}
const renderProductos = ({
    fields,
    meta: { error, submitFailed },
    getProductosOrden,
    todosProductos,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>PRODUCTO</th>
                            <th style={{ width: "15%" }}>EXISTENCIAS</th>
                            <th style={{ width: "30%" }}>A DESPACHAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll();
                            const producto = todosProductos[index];

                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    producto_seleccionado={producto}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar despacho
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos

class DespachoBodegaForm extends Component {
    getProductosOrden = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        params.bodega = this.props.bodega;
        return api
            .get(`inventario/getInventario/${this.props.bodega}`, params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    getBodega = (search) => {
        const { item } = this.props;
        return api
            .get("bodega/getBodegaEmpresa", {
                search,
            })
            .then((data) => {
                if (data) {
                    console.log(data);
                    let bodegas = [];
                    data.results.forEach((item) => {
                        if (item.id != this.props.bodega) {
                            bodegas.push(item);
                        }
                    });
                    console.log(bodegas);
                    return bodegas;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const { handleSubmit, orden, bodega, todosProductos } = this.props;
        return (
            <form
                name="DespachoBodegaForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <br />
                <div className="row">
                    <div className="form-group has-feedback col-lg-5 offset-lg-1 col-md-6 col-12">
                        <label className="t-azul" htmlFor="bodega_destino">
                            Bodega destino <small>(Campo obligatorio)</small>
                        </label>
                        <Field
                            name={`bodega_destino`}
                            component={AsyncSelectField}
                            loadOptions={this.getBodega}
                            type="text"
                            filterOptions={false}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                            top={{ top: "5px", position: "inherit" }}
                        />
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12">
                        <FieldArray
                            name="Despachos"
                            component={renderProductos}
                            todosProductos={todosProductos}
                            getProductosOrden={this.getProductosOrden}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/bodegas`}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

DespachoBodegaForm = reduxForm({
    form: "DespachoBodegaForm", // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if (!data.bodega_destino) {
            errors.bodega_destino = "Campo requerido";
        }
        if (!data || !data.Despachos) {
            errors.Despachos = { _error: "Debe ingresar al menos un producto" };
        } else {
            const despachoArray = [];
            data.Despachos.forEach((producto, index) => {
                let detErrors = {};
                let repetidos = [];
                if (producto.producto) {
                    repetidos = _.filter(data.Despachos, (x) => {
                        if (x.producto)
                            return x.producto.id == producto.producto.id;
                        return false;
                    });
                    if (!producto.despachar) {
                        detErrors.despachar = "Campo requerido";
                    } else {
                        console.log("producto: ", producto.despachar);
                        console.log("cantidad seleccionada ", producto.producto);
                        if (parseFloat(producto.despachar) <= 0) {
                            detErrors.despachar = "Debe ser 0 o mayor";
                        }
                        if (
                            parseFloat(producto.despachar) >
                            producto.producto.cantidad
                        ) {
                            detErrors.despachar = `Debe ser un maximo de ${producto.producto.cantidad}`;
                        }
                    }
                }
                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.producto = "Este producto está repetido";
                    }
                }
                if (detErrors) {
                    despachoArray[index] = detErrors;
                }
            });
            if (despachoArray.length) {
                errors.Despachos = despachoArray;
            }
        }
        return errors;
    },
})(DespachoBodegaForm);

const selector = formValueSelector("DespachoBodegaForm");
DespachoBodegaForm = connect((state) => {
    const productos = selector(state, "Despachos");

    let todosProductos = [];
    if (productos) {
        todosProductos = productos;
    }

    return {
        todosProductos,
    };
})(DespachoBodegaForm);

export default DespachoBodegaForm;
