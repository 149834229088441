import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import { SingleDatePicker } from 'react-dates'
import classNames from 'classnames';
import { api } from 'api';
import DatePicker from "react-datepicker";
import moment from 'moment';

export default class ToolbarReporteCostoInventario extends Component {

    getProductos = (search) => {
        let params = {}
        if(search){
            params.search = search;
        }
        if(this.props.empresa){
            params.empresa = this.props.empresa;
        }
        return api.get("productos", params).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
    };

    render() {
        const { bodega, producto, data } = this.props;
        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left">
                    <div className={"col-lg-4 col-md-4 col-6 align-self-center"}>
                        <span className="t-azul-claro font-weight-bold">Producto</span>
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={producto}
                            isSearchable={true}
                            loadOptions={this.getProductos}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeProducto(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Seleccionar.."
                        />
                    </div>
                    <div className="col-lg-8 col-md-6 col-12 d-flex pt-4 mt-3" style={{padding: "20px"}}>
                            <DatePicker
                                selected={new Date(this.props.fecha_cierre)}
                                value={new Date(this.props.fecha_cierre)}
                                clearIcon={null}
                                onChange={value => {
                                    this.props.changeFecha(moment(value))
                                }}
                                className="form-control text-date d-flex flex-column flex-1 general_height"
                                dateFormat="MMMM/yyyy"
                                locale="es"
                                showMonthYearPicker
                            />
                        {data != null && data.results && data.results[0] ?
                        !data.results[0].costos_cerrados &&
                            <div className="d-flex align-self-center px-3">
                                <button onClick={this.props.cerrarCostos} className="btn btn-azul-claro m-0 p-0" style={{marginLeft:"20px"}}>
                                    Cerrar costos
                                </button>
                            </div>
                        : ''}
                        </div>
                </div>
            </div>
        )
    }
}