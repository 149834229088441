import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import SucursalForm from "./SucursalesForm";
import { BanderaEmpresa } from "../../../../../utility/constants";

class SucursalesEditar extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
    };
    componentWillMount() {
        if (
            this.props.match.params.id != null &&
            this.props.match.params.id > 0
        ) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }
    onSubmit = (values) => {
        const { crear, editar } = this.props;
        let id = this.props.match.params.id;
        values.empresa = `${BanderaEmpresa}`;
        if (!values.telefono) {
            values.telefono = null;
        }
        this.state.editar ? editar(id, values) : crear(values);
    };
    render() {
        const { loader, empresas } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <SucursalForm
                        onSubmit={this.onSubmit}
                        empresas={empresas}
                        initialValues={{
                            mostradorsucursalhb_set: [{}],
                        }}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default SucursalesEditar;
