import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderCurrency, renderTextArea } from "../../../../Utils/renderField";

class IngresoCajaForm extends Component {
    cerrar = () => {
        this.props.cerrarModal(false);
    };

    render() {
        const { handleSubmit } = this.props;
        return (
            <form
                name="IngresoForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-12 row m-0 p-0">
                        <div className="form-group has-feedback col-12 row">
                            <div
                                className={`form-group has-feedback  col-md-6  col-12 `}
                            >
                                <label className="t-azul" htmlFor="monto">
                                    Monto
                                </label>
                                <Field
                                    name="monto"
                                    prefix={"Q. "}
                                    component={renderCurrency}
                                    parse={(cell) => parseFloat(cell)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback col-12 row">
                            <div className={`form-group has-feedback col-12 `}>
                                <label className="t-azul" htmlFor="descripcion">
                                    Descripción
                                </label>
                                <Field
                                    name="descripcion"
                                    component={renderTextArea}
                                    top={{ top: "94px" }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center ">
                            <button
                                type="button"
                                onClick={this.cerrar}
                                className="btn btn-secondary align-self-center"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-azul-claro m-1 ml-md-4 align-self-center"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

IngresoCajaForm = reduxForm({
    form: "IngresoForm", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.monto) {
            errors.monto = "Campo requerido";
        } else {
            if (parseFloat(data.monto) <= 0) {
                errors.monto = "El monto tiene que ser mayor a 0";
            }
        }
        if (!data.tipo_gasto) {
            errors.tipo_gasto = "Campo requerido";
        }
        if (!data.descripcion) {
            errors.descripcion = "Campo requerido";
        }

        return errors;
    },
})(IngresoCajaForm);

export default IngresoCajaForm;
