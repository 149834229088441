import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderAntTimeField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { SelectField } from '../../../Utils/renderField/renderField.js'
import { departamentos, municipios } from '../../../../../utility/constants';

const renderSucursales = ({fields, meta: {error, submitFailed }, contactos, municipios_bancos, AddMunicipio, ChangeMunicipio}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>ENCARGADO</th>
                        <th>DIRECCION</th>
                        <th>TELEFONO</th>
                        <th >HORARIO</th>
                        <th style={{width:"15%", minWidth:"100px"}} >DEPTO</th>
                        <th style={{width:"15%", minWidth:"100px"}}>MPIO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((sucursal, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar" 
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${sucursal}.encargado`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${sucursal}.direccion`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${sucursal}.telefono`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${sucursal}.horario_entrada`}
                                    component={renderAntTimeField}
                                    hour_value='07:00'
                                />
                                <Field
                                    name={`${sucursal}.horario_salida`}
                                    component={renderAntTimeField}
                                    hour_value='19:00'
                                /> 
                            </td>
                            <td className="sin-borde-top">
                                <Field 
                                    name={`${sucursal}.departamento`} 
                                    label="departamento" 
                                    component={SelectField}
                                    options={departamentos}
                                    className="form-control"
                                    onChange={
                                        (e)=>{
                                            ChangeMunicipio( index,`${e[0]}${e[1]}`)
                                        }
                                    }
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field 
                                    name={`${sucursal}.municipios`} 
                                    label="municipios" 
                                    component={SelectField}
                                    options={municipios_bancos[index]}
                                    className="form-control" 
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => {
                    AddMunicipio()
                    fields.push({
                        'horario_entrada':"2020-12-16T07:00:00-06:00",
                        'horario_salida': "2020-01-20T19:00:00-06:00",
                        'departamento': '01'
                    })
                }
                }>
                    Agregar sucursal
                </button>
            </div>
        </div>
    </div>
)



const SucursalesForm = (props) => {
    const { handleSubmit,  previousStep, municipios_bancos, AddMunicipio, ChangeMunicipio } = props;
    return (
        <form name="ContactoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >Sucursales</h4>
            </div>
            <FieldArray 
                name="sucursales" 
                component={renderSucursales} 
                municipios_bancos={municipios_bancos}
                AddMunicipio={AddMunicipio}
                ChangeMunicipio={ChangeMunicipio}
            />
            <div className="row  justify-content-sm-center justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">Anterior</button>                        
                <button type="submit" className="btn btn-azul-claro m-1 ml-md-4 align-self-center">Siguiente</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {}
        if(data.sucursales){
            let detErrors=[]
            data.sucursales.forEach((item,index) => {
                let arrayErrors = {}
                if(!item.encargado){
                    arrayErrors.encargado = "Campo requerido"
                }
                if(!item.direccion){
                    arrayErrors.direccion = "Campo requerido"
                }
                if(!item.telefono){
                    arrayErrors.telefono = "Campo requerido"
                }
                if(!item.departamento){
                    arrayErrors.departamento = "Campo requerido"
                }
                if(!item.municipios){
                    arrayErrors.municipios = "Campo requerido"
                }
                if(arrayErrors){
                    detErrors[index] = arrayErrors;
                }
            });
            if(detErrors.length){
                errors.sucursales = detErrors;
            }
        }
        return errors;
    },
})(SucursalesForm);
