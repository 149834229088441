import React, { Component, Fragment } from "react";
import "react-step-progress-bar/styles.css";
import CardSimple from "../../../Utils/Cards/cardSimple";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import TotalForm from "./totalform";
import moment from "moment";
export default class NuevoPagoPlanilla extends Component {
    state = {
        editar: false,
        valor_formulario: null,
    };
    get_fechaI = () => {
        let fecha = new Date();
        let dia = fecha.getDate();
        if (dia > 1) {
            fecha.setDate(fecha.getDate() - 1);
        }
        return fecha;
    };
    componentWillUnmount(){
        this.props.clear()
    }
    componentDidMount() {
        const { selectEmpresas, state_bonos, setEmpleadoInfo } = this.props;
        selectEmpresas();
        if (state_bonos) {
            if (this.props.match.params.id) {
                this.setState({ editar: true });
            }
            setEmpleadoInfo({}, null, [], false);
        } else {
            const { selectEmpleados } = this.props;
            if (this.props.match.params.id) {
                this.setState({ editar: true });
                this.props.leer(this.props.match.params.id);
            } else {
                selectEmpleados(this.get_fechaI(), new Date());
            }
        }
    }
    handleSubmit = (values) => {
        const { crear, editar, item } = this.props;
        if (this.state.editar) {
            values.id = item.id;
            editar(item.id, values);
        } else {
            crear(values);
        }
    };
    render() {
        const { loader, empleados } = this.props;

        return (
            <div>
                <CardSimple margen="mt-0">
                    <div className="col-12">
                        <LoadMask loading={loader} light>
                            <div className="col-md-12 row d-flex justify-content-center">
                                <div className="col-md-12 mt-1">
                                    <TotalForm
                                        mostrar_precios={
                                            this.state.mostrar_precios
                                        }
                                        texto_descripcion={
                                            this.state.texto_descripcion
                                        }
                                        {...this.props}
                                        initialValues={{
                                            es_borrador: "1",
                                            fechaI: this.get_fechaI(),
                                            fechaF: new Date(),
                                        }}
                                        previousStep={this.previousStep}
                                        onSubmit={this.handleSubmit}
                                    />
                                </div>
                            </div>
                        </LoadMask>
                    </div>
                </CardSimple>
            </div>
        );
    }
}
