
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/reset';
import Reset from './Reset';


const mstp = (state) => {
  return {
    ...state.reset,
  };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Reset);
