import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import VendedorForm from '../../Users/Editar/UsuariosForm';

class VendedorCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'Agregar vendedor',
    }
    componentWillMount(){
        this.props.selectRegiones()
        this.props.selectSucursales();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'Editar Vendedor'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        console.log(values)
        this.state.editar? editar(id,values) : crear(values);
    }
    format = (array = []) => {
        const nuevoArray = [];
        array.forEach((item, index) => {
            nuevoArray[index] = { label: item.nombre, value: item.id };
        });
        return nuevoArray;
    };
    render() {
        const { loader, sucursales } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <VendedorForm
                        vendedores_form={true}
                        editar = {this.state.editar}
                        format={this.format}
                        sucursales={sucursales}
                        onSubmit={this.onSubmit}
                        regiones={this.props.regiones}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default VendedorCrear;
