import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
//Extras
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";



function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class RechazadosGrid extends Component {
    state = {

    }
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
        this.props.handleData(this.props.value)

    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, page, eliminar } = this.props;
        return (
            <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                { true &&( <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    width="150"
                    dataFormat={
                        standardActions({
                            editar: "bonificacion_descuento", 
                            eliminar
                        })
                    }
                >
                </TableHeaderColumn>)}
                <TableHeaderColumn
                    isKey
                    dataField="nombre"
                    width="250"
                    dataSort
                >
                    NOMBRE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    width="150"
                    dataAlign="center"
                    dataFormat ={
                        (cell, row)=>(
                            <RenderMoneda className="pl-0" monto={cell? cell: 0} simbolo={"Q. "} />
                        )
                    }
                >
                    MONTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="descripcion"
                    dataSort
                >
                    DESCRIPCION
                </TableHeaderColumn>
            </Grid>
        );
    }
}
