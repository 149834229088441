import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import moment from 'moment';

export default class GridTrimestre extends Component {
    componentDidMount() {
        this.props.porTrimestre();
    }

    getPorcentaje = (venta, costo) => {
        let porcentaje = 0;
        if (venta > 0) {
            porcentaje = ((venta - costo) / venta) * 100;
            porcentaje = porcentaje.toFixed(2);
        }
        return porcentaje;
    }

    render() {
        const { page, data, loader, porTrimestre } = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    className="reporte-table"
                    onPageChange={porTrimestre}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        className='reporte-table'
                        width="2%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="nombre"
                        className='reporte-table'
                        width="15%"
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="primer_trimestre"
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            <div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Costo: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.costo} />
                                            ) : (
                                                    <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Ventas: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Diferencia: </span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas - cell.costo} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold"></span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <div className="moneda-reporte">
                                                    {`(${this.getPorcentaje(cell.ventas, cell.costo)}%)`}
                                                </div>
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                            </div>
                        )}
                    >
                        ENERO A MARZO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="segundo_trimestre"
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            <div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Costo: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.costo} />
                                            ) : (
                                                    <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Ventas: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Diferencia: </span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas - cell.costo} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold"></span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <div className="moneda-reporte">
                                                    {`(${this.getPorcentaje(cell.ventas, cell.costo)}%)`}
                                                </div>
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                            </div>
                        )}
                    >
                        ABRIL A JUNIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tercer_trimestre"
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            <div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Costo: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.costo} />
                                            ) : (
                                                    <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Ventas: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Diferencia: </span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas - cell.costo} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold"></span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <div className="moneda-reporte">
                                                    {`(${this.getPorcentaje(cell.ventas, cell.costo)}%)`}
                                                </div>
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                            </div>
                        )}
                    >
                        JULIO A SEPTIEMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cuarto_trimestre"
                        className='reporte-table'
                        dataFormat={(cell, row) => (
                            <div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Costo: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.costo} />
                                            ) : (
                                                    <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Ventas: </span>
                                    <span style={{ color: "black", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold">Diferencia: </span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <RenderMoneda className="" decimalScale={2} monto={cell.ventas - cell.costo} />
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex flex-1">
                                    <span className="font-weight-bold"></span>
                                    <span style={{ color: "#00334E", marginLeft: "auto" }}>
                                        {
                                            (cell.costo != null && cell.ventas != null) ? (
                                                <div className="moneda-reporte">
                                                    {`(${this.getPorcentaje(cell.ventas, cell.costo)}%)`}
                                                </div>
                                            ) : (
                                                <div>-----</div>
                                            )
                                        }
                                    </span>
                                    <br />
                                </div>
                            </div>
                        )}
                    >
                        OCTUBRE A DICIEMBRE
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
