import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import Tiempo from '../../../../../../assets/img/icons/Tiempo.png';
import Check from '../../../../../../assets/img/icons/Check.png';
import { Link, Redirect } from 'react-router-dom';

import Modal from 'react-responsive-modal';

import Swal from 'sweetalert2';


import AnulacionForm from '../../Transacciones/Listar/anulacionForm';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

export default class GastosGrid extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null
    }
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setOpenModal(true)
    }
    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(values)
            }
        });
    }

    opcionVerAdicional = (id, row) => {
        let ruta_gasto = "/gasto/detalle"
        if(row.tipo_flotante && row.estado){
            ruta_gasto = "/gasto"
        }
        return (
            <Link className="text-warning" to={`${ruta_gasto}/${id}`} >
                <img
                    className="action-img-ver iconoAcciones iconoVer p-0"
                    title="Editar" src={require("../../../../../../assets/img/icons/Ver.png")}
                    alt="Edit"
                />
            </Link>
        )
    }


    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return (
            <Modal open={open_modal} onClose={(e) => {
                this.props.setOpenModal(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>

                    {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModal}
                            />
                        )
                    }

            </Modal>
        )
    }
    render() {
        const { data, loader, listar, page, onSortChange, eliminar, permisos } = this.props;
        if(permisos["gastos"] === false) {
            if(!!permisos[`ver_gastos`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <CardSimple
                    toolbar= {<ToolbarSimple
                        textBoton="Agregar"
                        ruta="/gasto/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                        permisos={permisos.gastos}
                    />}
                >
                        <Grid hover striped data={data} loading={loader} onPageChange={listar} page={page} onSortChange={onSortChange} >
                            {permisos.gastos && (<TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                dataSort
                                dataFormat={
                                    standardActions({ eliminarModal: this.abrirModalEliminar,  adicional: this.opcionVerAdicional })
                                }
                            >
                            </TableHeaderColumn>)}
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat = {
                                    (cell,row) => (
                                        <RenderDateTime fecha={cell} ></RenderDateTime>
                                    )
                                }
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="proveedor"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                PROVEEDOR
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_documento"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                TIPO DE MOVIMIENTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_gasto"
                                dataSort
                                tdStyle={cellTachado}
                            >
                                TIPO DE GASTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat= {
                                    (cell, row) => (
                                        <span style={{width:'100%'}} className="">{cell}</span>
                                    )
                                }
                            >
                                DESCRIPCIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_flotante"
                                dataSort
                                dataAlign="center"
                                tdStyle={cellTachado}
                                dataFormat= {
                                    (cell, row) => {
                                        if(cell){
                                            return(<img src={Tiempo} width="20"/>)
                                        }else{
                                            return (<img src={Check} width="20"/>)
                                        }
                                    }
                                }
                            >
                                LIQUIDADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto"
                                dataSort
                                tdStyle={cellTachado}
                                dataFormat ={
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={this.getsimbolo(row.moneda)} />
                                    )
                                }
                            >
                                MONTO
                            </TableHeaderColumn>
                        </Grid>
                </CardSimple>
            </Fragment>

        );
    }
}
