import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import ItemTree from './components/ItemTree';
import Modal from 'react-responsive-modal';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import TipoCategoriaForm from './categoriaForm';
import Swal from 'sweetalert2';

export default class TipoCategoria extends Component {
    state = {
        opcion: 0,
        item: null,
        initialValues: {},
        titulo: "Subcategoria"
    }
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    seleccionarItem = (item, opcion) => {
        this.setState({
            item: item,
            opcion: opcion
        });
        this.setState({titulo: 'Subcategoria'});
        if(opcion == 1){
            if(item)
                this.setState({initialValues: {parent: item.value}})
        }else if(opcion == 2) {
            this.setState({initialValues: {...item}})
        }else {
            this.setState({initialValues: {...item}})
            Swal.fire({
                title: '¿Desea eliminar la categoria?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, borrar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(item.value);
                }
            });
        }
    }
    onSubmit = (data) => {
        if(this.state.opcion == 1){
            //Revisa si es para crear
            this.props.crear(data);
        }
        else if(this.state.opcion == 2){
            //Revisa si es una edición
            this.props.editar(this.state.item.value, data)
        }else {
            // revisa si es un delete
        }
    }
    cerrarModal = () => {
        this.props.setOpenModal(false);
    }
    renderModal= ()=>{
        const { data, loader_formulario, open_modal } = this.props;
        const { opcion } = this.state;
        return(
            <Modal open={open_modal} showCloseIcon={false} onClose={(e) => {
                this.props.setOpenModal(false)
            }} center>
                <div>
                    {
                        (opcion === 1) && (
                            <Fragment>
                                <div className="col-12 modal-card-header">
                                    <span className="col-12 col-lg-10 modal-card-title"> Agregar categoria </span>
                                </div>
                                {
                                    (this.state.item) && (
                                        <h3 className="text-center text-primary">De: {this.state.item.title}</h3>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    {
                        (opcion === 2) && (
                            <Fragment>
                                <div className="col-12 modal-card-header">
                                    <span className="col-12 col-lg-10 modal-card-title"> Editar categoria </span>
                                </div>
                            </Fragment>
                        )
                    }
                    {
                        (opcion === 3) && (
                            <Fragment>
                                <div className="col-12 modal-card-header">
                                    <span className="col-12 col-lg-10 modal-card-title"> Agregar categoria </span>
                                </div>
                                <h3 className="text-center text-primary">De: {this.state.item.title}</h3>
                            </Fragment>
                        )
                    }
                    <LoadMask loading={loader_formulario} light type={"TailSpin"}>
                        <TipoCategoriaForm
                            cerrarModal={this.cerrarModal}
                            initialValues={this.state.initialValues}
                            onSubmit={this.onSubmit} />
                    </LoadMask>

                </div>

            </Modal>
        )
    }

    render() {
        const { data, loader, open_modal, permisos } = this.props;
        if(permisos["categiagastos"] === false) {
            if(!!permisos[`ver_categiagastos`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
            margen="mt-0"
            >
                <div className="col-12">

                <LoadMask loading={loader} light type={"TailSpin"} className="col-12">

                     <button type="button"
                    onClick={(e) => {
                        e.preventDefault()
                        this.seleccionarItem(null, 1);
                        this.setState({titulo: 'Categoria'});
                        this.props.setOpenModal(true);
                    }}
                    className="btn btn-azul-claro align-self-center m-3">Crear categoria</button>
                    {this.renderModal()}
                    <div className="col-md-6 contendor-arbol fondo-arbol">
                        {data.map((item, index) => (
                            <ItemTree
                                key={index}
                                setOpenModal={this.props.setOpenModal}
                                seleccionarItem={this.seleccionarItem}
                                item={item}
                            />
                        ))}
                    </div>
                </LoadMask>
                </div>
            </CardSimple>
        );
    }
}
