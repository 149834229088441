import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/sucursales/sucursales";
import SucursalesListar from "./SucursalesGrid";

const mstp = (state) => {
    return { ...state.sucursalesHB, permisos: state.login.me.permisos };
};

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(SucursalesListar);
