import React, { Fragment, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../../../Utils/renderField";
import ProductoGrid from "./ProductoGris";

class ProductosForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="row mt-0 mx-4 row col-12 d-flex justify-content-center">
                <form name="productosVentaForm" onSubmit={handleSubmit}>
                    <ProductoGrid
                        agregarACarrito={this.props.agregarACarrito}
                        bodega={this.props.bodega}
                        getInventario={this.props.getInventario}
                        productos={this.props.productos}
                        searchChange={this.props.searchChange}
                        search={this.props.search}
                        page={this.props.page}
                        onPageChange={this.props.onPageChange}
                        loaderProductos={this.props.loaderProductos}
                    />
                </form>
            </div>
        );
    }
}

ProductosForm = reduxForm({
    form: "productosVentaForm", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.monto) {
            errors.monto = "Campo requerido";
        } else {
            if (parseFloat(data.monto) <= 0) {
                errors.monto = "El monto tiene que ser mayor a 0";
            }
        }
        if (!data.tipo_gasto) {
            errors.tipo_gasto = "Campo requerido";
        }
        if (!data.descripcion) {
            errors.descripcion = "Campo requerido";
        }

        return errors;
    },
})(ProductosForm);

export default ProductosForm;
