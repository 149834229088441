import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { Link, Redirect } from "react-router-dom";
import {
    renderField,
    renderTextArea,
    renderSelectField,
    AsyncSelectField,
} from "../../../Utils/renderField";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import { api } from "api";
import { BanderaEmpresa } from "../../../../../utility/constants";

const renderPeticiones = ({
    fields,
    meta: { error, submitFailed },
    getProductos,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: "40%" }}>PRODUCTOS</th>
                            <th style={{ width: "40%" }}>CANTIDAD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((PET, index) => (
                            <tr key={index} className="align-items-start">
                                <td
                                    className="text-center sin-borde-top"
                                    style={{ width: "48px" }}
                                >
                                    <img
                                        className="action-img iconoEliminarTabla iconoEliminar"
                                        style={{ padding: "4px" }}
                                        src={require("assets/img/icons/Cerrar.png")}
                                        alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </td>
                                <td
                                    className="sin-borde-top "
                                    style={{ width: "40%" }}
                                >
                                    <Field
                                        name={`${PET}.producto`}
                                        component={AsyncSelectField}
                                        loadOptions={getProductos}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="nombre"
                                        className="form-control"
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </td>
                                <td
                                    className="sin-borde-top"
                                    style={{ width: "40%" }}
                                >
                                    <Field
                                        name={`${PET}.cantidad`}
                                        type="number"
                                        component={renderField}
                                        min={1}
                                        className="form-control"
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-azul-claro py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar producto
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);

class PeticionesForm extends Component {
    getProductos = (search) => {
        return api
            .get("productos", { search, proveedor: null, moneda: null })
            .then((data) => {
                // debugger;
                if (data) {
                    return data.results;
                } else {
                    return [];
                }
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const { handleSubmit, empresas } = this.props;
        return (
            <form
                name="PeticionForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row m-0 p-0 d-flex justify-content-center">
                    <div className="form-group has-feedback col-md-8 col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Fecha
                        </label>
                        <Field
                            name="fecha"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    {/* <div className="form-group has-feedback col-lg-6 col-md-12 col-12">
                        <label className="t-azul" htmlFor="empresa">Empresa</label>
                        <Field
                            name="empresa"
                            label="empresa"
                            labelKey="nombre" valueKey="id"
                            component={renderSelectField}
                            options={empresas}
                            className="form-control"
                        />
                    </div> */}
                </div>
                <div className="row m-0 p-0 d-flex justify-content-center">
                    <div className="form-group has-feedback col-lg-8 col-md-12 col-12">
                        <label className="t-azul" htmlFor="descripcion">
                            Descripción
                        </label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            rows={4}
                            component={renderTextArea}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-12">
                        <FieldArray
                            name="detalle_orden"
                            component={renderPeticiones}
                            getProductos={this.getProductos}
                        />
                    </div>
                </div>
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to="/peticiones"
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro m-1 ml-4 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: "PeticionForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.descripcion){
            errors.descripcion= "Campo requerido.";
        }
        if (!data.detalle_orden || !data.detalle_orden.length) {
            errors.detalle_orden = {
                _error: "Debe de ingresar al menos un producto",
            };
        } else {
            const detalleArray = [];
            data.detalle_orden.forEach(function (producto, index) {
                let detErrors = {};
                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                }
                if (!producto.cantidad) {
                    detErrors.cantidad = "Campo requerido";
                } else {
                    if (parseFloat(producto.cantidad) <= 0) {
                        detErrors.cantidad = "Debe ser mayor a 0";
                    }
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.detalle_orden = detalleArray;
            }
        }
        return errors;
    },
})(PeticionesForm);
