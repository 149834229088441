import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency } from '../../../Utils/renderField';
import {SelectField, AsyncSelectField, renderSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';
import {connect} from 'react-redux';
import { Monedas} from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import { number } from 'validate-redux-form/compiled/validators';


let prefix="Q. ";

const handleMoneda = (value) => {
    let simbolo ="Q."
    Monedas.forEach((item, index) =>{
        if (value == item.value){
            simbolo = item.simbolo + ". ";
            prefix= simbolo;
        }
    })
}

let CuentacppForm = (props) => {
    const { handleSubmit, previousStep, empresas, es_cliente } = props;
    return (
        <form name="CuentacppForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >{`Cuentas por ${ es_cliente == 2 ? 'cobrar':'pagar'}`}</h4>
            </div>
            {es_cliente == 2 ? (
            <div className=" col-sm-12 p-0 d-flex justify-content-center">
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label>Saldo inicial</label>
                    <Field
                        name='cpp[0].cuenta_inicial'
                        label="cuenta_inicial"
                        component={renderCurrency}
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                </div>
            </div>
            ) :
            (
                <div className="col-12 row justify-content-center">
                    <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                        <label>Moneda</label>
                        <Field
                            name={`cpp[0].moneda`}
                            label="moneda"
                            component={renderSelectField}
                            options={Monedas}
                            onChange={(e) => {
                                handleMoneda(e.target.value)
                            }}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                        <label >Tipo Cambio</label>
                        <Field
                            name={`cpp[0].tipo_cambio`}
                            label="tipo_cambio"
                            prefix={prefix}
                            component={renderCurrency}
                            decimalScale={4}
                            parse={cell => parseFloat(cell)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label>Saldo Inicial</label>
                        <Field
                            name={`cpp[0].cuenta_inicial`}
                            label="cuenta_inicial"
                            prefix={prefix}
                            component={renderCurrency}
                            className="form-control"
                            parse={cell => parseFloat(cell)}
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    </div>
                </div>
            )}
            <br/>
            <div className="row  justify-content-sm-center justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">Anterior</button>
                <button type="submit" className="btn btn-azul-claro m-1 ml-md-4 align-self-center">Registrar</button>
            </div>
        </form>
    );
};


CuentacppForm =  reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        return validate(data, {
        });
    },
})(CuentacppForm);

const selector = formValueSelector('ClientesForm');
CuentacppForm = connect( (state, ownProps)=>{
    const cpp = selector(state, 'cpp');

    if (!cpp) {
        if (ownProps.es_cliente == 2){
            ownProps.setEmpresasForm(ownProps.empresas);
        }
        else{
            ownProps.setEmpresasFormProvedor(ownProps.empresas);
        }
    }
    return{
        test: true
    }
})(CuentacppForm)

export default  CuentacppForm;