import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

import moment from 'moment';


export default class AsignacionesGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar } = this.props;

        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Asignar"
                    ruta="asignacion_actividades/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar por: horas"}
                    permisos={true}
                />}
                titulo="asignación de actividades">
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={
                                standardActions({ eliminar })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="creado"
                            dataSort
                            dataFormat = {(cell, row)=> moment(cell).format("L") }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="actividad"
                            dataSort
                            dataFormat = {(cell, row)=> cell.nombre}
                        >
                            ACTIVIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="empleado"
                            dataSort
                            dataFormat = {(cell, row)=> `${cell.nombre} ${cell.apellido}`}
                        >
                            EMPLEADO
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="empresa_actividad"
                            dataSort
                            dataFormat = {(cell, row)=> cell.nombre}
                        >
                            EMPRESA
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="horas_trabajadas"
                            dataSort
                            dataAlign="right"
                            tdStyle={{paddingRight: "2%"}}
                            thStyle={{paddingRight: "2%"}}
                            dataFormat = {(cell, row)=> <RenderNumber value={cell} decimalScale={2} />}
                        >
                            Horas Trabajadas
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="tipo_horario"
                            dataSort
                            dataFormat = {(cell, row)=> (
                                cell == 1 ? "Horario normal" : "Horario extra"
                            )}
                        >
                            Tipo de horario
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
