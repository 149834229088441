import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldCheck, renderTextArea, renderCurrency, renderSelectField, AsyncSelectField  } from '../../../Utils/renderField';
import { SelectField } from '../../../Utils/renderField/renderField.js'
import { departamentos } from '../../../../../utility/constants';
import { Link } from 'react-router-dom';
import {api} from 'api';

const getVendedores = (search) => {
    return api.get("vendedores", { search, }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const ClienteForm = (props) => {
    const { handleSubmit, municipios, es_cliente } = props;
    return (
        <form name="ClienteForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" > Datos generales </h4>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre <small>(Campo obligatorio)</small></label>
                    <Field name="nombre" label="nombre" type="text" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="telefono">Teléfono</label>
                    <Field name="telefono" label="telefono" component={renderField} className="form-control" />
                </div>
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="correo">Correo</label>
                    <Field name="correo" label="correo" component={renderField} className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="dia_credito">Días de Crédito</label>
                    <Field
                        name="dia_credito"
                        type="number"
                        min={0}
                        component={renderField}
                    />
                </div>
                <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="encargado">Nombre del Encaragado</label>
                    <Field name="encargado" label="encargado" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            {
                (es_cliente == 2) &&(
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul"  htmlFor="correo">Departamento <small>(Campo obligatorio)</small></label>
                        <Field 
                            name="departamento" 
                            label="departamento" 
                            component={SelectField}
                            options={departamentos}
                            className="form-control" 
                            onChange={
                                (e)=>{
                                    props.getMunicipios(`${e[0]}${e[1]}`)
                                }
                            }
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                        <label className="t-azul"  htmlFor="municipios">Municipio <small>(Campo obligatorio)</small></label>
                        <Field 
                            name="municipios" 
                            label="municipios" 
                            component={SelectField}
                            options={municipios}
                            className="form-control" 
                        />
                    </div>
                </div>)
            }
            {
                (es_cliente ==2) && (
                    <div className="row justify-content-center">
                            <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                                <label className="t-azul" htmlFor="nombre">Vendedor</label>
                                <Field 
                                    isClearable={true}
                                    name="vendedor" 
                                    label="vendedor" 
                                    type="text" 
                                    component={AsyncSelectField} 
                                    loadOptions={getVendedores}
                                    valueKey="id"
                                    labelKey="first_name"
                                    type="text"  
                                    className="form-control" 
                                />
                            </div>
                            <div className="form-group has-feedback col-lg-4 col-md-6  col-12">
                                <div className="col-12 mt-md-4 pt-md-3 mt-1 pt-1">
                                    <Field name="es_sucursal" label="Es una sucursal" component={renderFieldCheck} className="form-control" />
                                </div>
                            </div>
                    </div>
                )
            }
            {/* {
                !props.editar && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-lg-4 col-md-6 col-12">
                            <label className="t-azul"  htmlFor="cuenta_inicial">{es_cliente == 2? "Cuenta por Cobrar":"Cuenta por Pagar"}</label>
                            <Field name="cuenta_inicial" label="cuenta_inicial" component={renderCurrency} className="form-control" />
                        </div>
                        <div className="form-group has-feedback col-lg-4 col-md-6 col-12">
                        </div>
                    </div>
                )
            } */}
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-8 col-md-12  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Comentario</label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} className="form-control" />
                </div>
            </div>


            <div className="row  justify-content-sm-center justify-content-center">
                <Link className="btn btn-secondary  align-self-center" to={props.route} >Cancelar</Link>
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Siguiente</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {}
        if(!data.nombre){
            errors.nombre = "Este campo es requerido"
        }
        if(!data.direccion){
            errors.direccion = "Este campo es requerido"
        }
        if(!data.departamento){
            errors.departamento = "Este campo es requerido"
        }
        if(!data.municipios){
            errors.municipios = "Este campo es requerido"
        }
        // if(data.dia_credito && data.dia_credito <= 0){
        //     errors.dia_credito = "Debe ser mayor a 0"
        // }
        return errors;
    },
})(ClienteForm);
