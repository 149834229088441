import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    SelectField,
    AsyncSelectField,
} from "../../../Utils/renderField/renderField.js";
import { formatSelects } from "../../../Utils/renderField/renderReadField";

import { renderField } from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";

const renderCajas = ({ fields, meta: { error, submitFailed } }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}></th>
                            <th>NOMBRE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => (
                            <tr key={index} className="align-items-start">
                                <td
                                    className="text-center sin-borde-top"
                                    style={{ width: "48px" }}
                                >
                                    <img
                                        className="action-img iconoEliminarTabla iconoEliminar"
                                        style={{ padding: "4px" }}
                                        src={require("assets/img/icons/Cerrar.png")}
                                        alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top ">
                                    <Field
                                        name={`${item}.nombre`}
                                        type="text"
                                        component={renderField}
                                        className="form-control"
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-azul-claro py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);

const SucursalForm = (props) => {
    const { handleSubmit, empresas } = props;
    return (
        <form
            name="SucursalForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row justify-content-center mt-5">
                <div className="col-12 col-lg-10 row">
                    <div className="form-group has-feedback col-lg-6 col-md-12">
                        <label className="t-azul" htmlFor="nombre">
                            Nombre <small>(Campo obligatorio)</small>
                        </label>
                        <Field
                            name="nombre"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-md-12">
                        <label className="t-azul" htmlFor="nombre">
                            Dirección <small>(Campo obligatorio)</small>
                        </label>
                        <Field
                            name="direccion"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-10 row">
                    <div className="form-group has-feedback col-lg-6 col-md-12">
                        <label className="t-azul" htmlFor="nombre">
                            Telefono
                        </label>
                        <Field
                            name="telefono"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-md-12">
                        <label className="t-azul" htmlFor="nombre">
                            Numero Establecimiento<small>(Campo obligatorio)</small>
                        </label>
                        <Field
                            name="establecimiento"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-10 pt-3 row">
                    <div className="col-md-12">
                        <label htmlFor="nombre">CAJAS</label>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                        <FieldArray
                            name="mostradorsucursalhb_set"
                            component={renderCajas}
                        />
                    </div>
                </div>
            </div>
            <br />
            <div className="row  justify-content-sm-center justify-content-center">
                <Link
                    className="btn btn-secondary  align-self-center"
                    to="/sucursales"
                >
                    Cancelar
                </Link>
                <button
                    type="submit"
                    className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "SucursalForm",
    validate: (data) => {
        const errors = {};
        if (!data.nombre) {
            errors.nombre = "Campo requerido.";
        }
        if (!data.direccion) {
            errors.direccion = "Campo requerido.";
        }
        if (!data.establecimiento) {
            errors.establecimiento = "Campo requerido.";
        }
        if (
            !data.mostradorsucursalhb_set ||
            !data.mostradorsucursalhb_set.length
        ) {
            errors.mostradorsucursalhb_set = {
                _error: "Se necesita al menos una caja",
            };
        } else {
            let detErrors=[]
            data.mostradorsucursalhb_set.forEach((item,index) => {
                let arrayErrors = {}
                if(!item.nombre){
                    arrayErrors.nombre = "Campo requerido"
                }
                if(arrayErrors){
                    detErrors[index] = arrayErrors;
                }
            });
            if(detErrors.length){
                errors.mostradorsucursalhb_set = detErrors;
            }
        }
        return errors;
    },
})(SucursalForm);
