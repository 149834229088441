import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, AsyncSelectField, SelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {
    renderTextArea,
} from "../../../Utils/renderField/renderField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";

import { getSimbolo } from "../../../Utils/renderField/renderTableField";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { RenderNumber} from "../../../Utils/renderField/renderReadField";
import {api} from 'api';
import { departamentos, municipios } from '../../../../../utility/constants';


class ItemArray extends Component{
    state={
        producto: {},
    }
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if(producto !== undefined){
            todos[index]['facturar'] = producto.cantidad - producto.cantidad_facturada;
            // if (producto.existencias < producto.cantidad_actual){
            // }else{
            //     todos[index]['facturar'] = producto.cantidad - producto.cantidad_facturada;
            // }
        }
    }
    componentDidMount(){
        if( this.props.producto_seleccionado){
            this.setState({producto: this.props.producto_seleccionado.producto});
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.producto_seleccionado !== prevProps.producto_seleccionado){
            const { todos, index } = this.props;
            if( this.props.producto_seleccionado){
                this.setState({producto: this.props.producto_seleccionado.producto});
            }
        }
    }
    render(){
        const {getProductosOrden, eliminar, index, Despacho, subtotal }= this.props
        return(
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img style={{ padding:"4px"}} className="action-img iconoEliminarTabla iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${Despacho}.producto`}
                        loadOptions={getProductosOrden}
                        type="text"
                        component={AsyncSelectField}
                        top ={{top:"67px", position:"inherit"}}
                        valueKey="producto"
                        labelKey="producto_nombre"
                        onChange={(e) => {
                            this.setState({producto: e});
                            this.setPrecioUnitario(e)
                        }}
                        className="form-control"
                    />

                </td>
                <td className="sin-borde-top" >
                    <label><RenderNumber value={this.state.producto ? this.state.producto.cantidad : 0 } /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderNumber value={ this.state.producto ? this.state.producto.cantidad - this.state.producto.cantidad_actual: 0} /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderNumber value={ this.state.producto ?  this.state.producto.cantidad_facturada: 0} /></label>
                </td>
                <td className="sin-borde-top" >
                    <Field
                        name={`${Despacho}.facturar`}
                        type="number"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                <td className="sin-borde-top">
                    <label><RenderMoneda monto={  this.state.producto ? this.state.producto.precio_unitario:0} /></label>
                </td>
                <td className="sin-borde-top">
                    <label><RenderMoneda monto={subtotal} /></label>
                </td>
            </tr>
        )
    }
}

const renderProductos = ({fields, meta: {error, submitFailed }, getProductosOrden, subtotales}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Producto</th>
                        <th style={{width: "10%"}}>Solicitada</th>
                        <th style={{width: "10%"}}>Despachada</th>
                        <th style={{width: "10%"}}>Facturada</th>
                        <th style={{width: "15%"}}>por facturar</th>
                        <th  style={{width: "17%"}}>precio</th>
                        <th  style={{width: "17%"}}>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll()
                            const producto = todos[index];
                            return(
                                <ItemArray
                                    index={index}
                                    producto_seleccionado={producto}
                                    key={index}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    subtotal={subtotales[index]}
                                    eliminar={ () =>{
                                        fields.remove(index)
                                    }}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)


class AgregarFForm extends React.Component{
    getProductosOrden = (search) => {
        let params = {}
        if(search){
            params.search = search;
        }
        params.orden = this.props.orden;
        params.bandera = 1;
        return api.get("ordenventa/productosporventa", params).
        then((data) => {
            if (data) return data;
            return [];
        }).catch(() => {
            return [];
        });
    };
    render(){
        const { handleSubmit, item, total, subtotales, municipios } = this.props
        let simbolo = item.moneda;

        return(
            <form name="FacturaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            {  (item.orden && !!item.orden.Ordennits.length) && (
               <div className="row m-none">
                   <div className=" col-12 justify-content-center">
                       <span className="m-none t-mostaza semibold">Facturas Solicitadas</span><br/>
                       { item.orden.Ordennits.map((nit, index) =>{
                               return(
                                   <div key={index}>
                                       <div className="row">
                                            <div className="col-sm-2 col-12">
                                                <span className="m-none t-mostaza semibold"></span><br/>
                                                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                                                    target="_blank"
                                                    onClick={()=>{this.props.setAutomaticoReceptor(nit)}}
                                                >
                                                    <img
                                                        className="action-img"
                                                        title="aplicar"
                                                        src={require("../../../../../../assets/img/icons/Ver.png")} alt="applicar"/>
                                                </a>
                                            </div>
                                           <div className="col-sm-3 col-12">
                                               <span className="m-none t-mostaza semibold">Nit: </span><br/>
                                               <span className="m-none t-musgo semibold">{`${nit.propietario? nit.propietario.Nit : "--"}`}</span>
                                           </div>
                                           <div className="col-sm-4 col-12">
                                               <span className="m-none t-mostaza semibold">Nombre: </span><br/>
                                               <span className="m-none t-musgo semibold">{`${nit.nombre? nit.nombre : "--"}`}</span>
                                           </div>
                                           <div className="col-sm-3 col-12">
                                               <span className="m-none t-mostaza semibold">Monto: </span><br/>
                                               <span className="m-none t-musgo semibold">{nit.monto_factura? <RenderMoneda monto={nit.monto_factura} simbolo={getSimbolo(simbolo)} />: "--"}</span>
                                           </div>
                                       </div>
                                   </div>
                               )
                           })}
                            <div>
                                <div className="row">
                                    <div className="col-sm-2 col-12">
                                        <span className="m-none t-mostaza semibold"></span><br/>
                                        <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                                            target="_blank"
                                            onClick={()=>{this.props.setAutomaticoReceptor({nombre:"Consumidor Final", propietario:{Nit:"CF"}})}}
                                        >
                                            <img
                                                className="action-img"
                                                title="aplicar"
                                                src={require("../../../../../../assets/img/icons/Ver.png")} alt="applicar"/>
                                        </a>
                                    </div>
                                    <div className="col-sm-3 col-12">
                                        <span className="m-none t-mostaza semibold">Nit: </span><br/>
                                        <span className="m-none t-musgo semibold">{`CF`}</span>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <span className="m-none t-mostaza semibold">Nombre: </span><br/>
                                        <span className="m-none t-musgo semibold">{`Consumidor Final`}</span>
                                    </div>
                                    <div className="col-sm-3 col-12">
                                        <span className="m-none t-mostaza semibold">Monto: </span><br/>
                                        <span className="m-none t-musgo semibold">{ <RenderMoneda monto={0} simbolo={getSimbolo(simbolo)} />}</span>
                                    </div>
                                </div>
                            </div>
                   </div>

               </div>
               )
           }

           <br/>

            <div className=" col-12 justify-content-center">
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-12">
                        <label className="t-azul" htmlFor="nombre">Fecha</label>
                            <Field
                                name="fecha"
                                selectedDate={new Date()}
                                maxDate={new Date()}
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                    </div>
                    <div className="form-group has-feedback col-sm-6 col-12">
                       <label htmlFor="nombre">Nombre</label>
                       <Field
                           name="nombre"
                           label="Nombre"
                           component={renderField}
                           type="text"
                           className="form-control"
                       />
                    </div>
                    <div className="form-group has-feedback col-sm-6 col-12">
                       <label htmlFor="nit">Nit</label>
                       <Field
                           name="nit"
                           label="Nit"
                           component={renderField}
                           type="text"
                           className="form-control"
                       />
                    </div>
                    <div className={`form-group has-feedback col-12`}>
                        <label className="t-azul" htmlFor="nombre">Direccion</label>
                        <Field name="direccion" label="direccion" component={renderField} type="text" className="form-control" />
                    </div>
                   <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Departamento</label>
                        <Field
                            name={`departamento`}
                            label="departamento"
                            component={SelectField}
                            options={departamentos}
                            className="form-control"
                            onChange={
                                (e)=>{
                                    this.props.getMunicipios(`${e[0]}${e[1]}`)
                                }
                            }
                        />
                   </div>
                    <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                        <label className="t-azul" htmlFor="nombre">Municipio</label>
                        <Field
                            name={`municipios`}
                            label="municipios"
                            component={SelectField}
                            options={municipios}
                            className="form-control"
                            onChange={
                                (e, a, c)=>{
                                    this.props.setCodigoPostal(a)
                                }
                            }
                        />
                   </div>
                   <div className="form-group has-feedback col-md-4 col-sm-6 col-12">
                        <label className="t-azul" htmlFor="nombre">Codigo Postal</label>
                        <Field
                            name={`codigo_postal`}
                            label="codigo_postal"
                            component={renderField}
                            type="text"
                            className="form-control"
                            readOnly={true}
                        />
                   </div>
                   <div className="form-group has-feedback  col-12">
                       <FieldArray
                            subtotales={subtotales}
                            name="detalle_factura"
                            component={renderProductos}
                            getProductosOrden={this.getProductosOrden}
                            simbolo={simbolo}
                        />
                   </div>
                   <div className="col-12 p-0 m-0 row justify-content-end">
                        <div className="form-group has-feedback col-sm-6 col-12 d-flex align-items-center justify-content-end">
                            <label className="t-azul">TOTAL A FACTURAR:</label>
                            <h3 className="t-primary"  htmlFor="region">{<RenderMoneda monto={total}/>}</h3>
                        </div>
                    </div>
               </div>
           </div>

           <div className="row  justify-content-sm-center justify-content-center">
               <Link  className="btn btn-secondary m-1 align-self-center" to={`/ordenesventa/${item ? item.id: 0}/detalle`} >Cancelar</Link>
               <button type="submit" className="btn btn-azul-claro m-1  ml-md-4 align-self-center">Agregar</button>
           </div>
       </form>
        )
    }
}

AgregarFForm = reduxForm({
    form: 'FacturaForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {}
        if(!data.nombre){
            errors.nombre = 'Este campo es requerido';
        }
        if(!data.nit){
            errors.nit = "Este campo es requerido";
        }
        if(!data.no_factura){
            errors.no_factura = "Este campo es requerido";
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido";
        }
        if(data.detalle_factura){
            let listErrors = []
            data.detalle_factura.map((item, index) => {
                let detErrors = {}
                if(!item.producto){
                    detErrors.producto = "Este campo es requerido";
                }
                if(!item.facturar){
                    detErrors.facturar = "Este campo es requerido";
                } else if(item.facturar < 0){
                    detErrors.facturar = "La cantidad es menor a 0";
                }
                if(Object.keys(detErrors).length){
                    listErrors[index] = detErrors;
                }
            })
            if(listErrors.length){
                errors.detalle_factura = listErrors;
            }
        }
        return errors;
    },
})(AgregarFForm);

const selector = formValueSelector('FacturaForm');
AgregarFForm = connect(state => {

    const detalle = selector(state, 'detalle_factura');

    let subtotales = [];
    let resultado = 0;
    let total = 0;
    if (detalle){
        detalle.forEach((item, index) => {
            if (item.producto) {
                if (item.facturar){
                    resultado = item.producto.precio_unitario * item.facturar;
                }else{
                    resultado = 0;
                }
                subtotales.push(resultado);
                total += resultado;
            }
        });
    }

    return{
        total,
        subtotales,
    }
})(AgregarFForm);

export default AgregarFForm;