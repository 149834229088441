import React, { Component, Fragment } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import NotaCreditoForm from "../Crear/NotaCreditoForm";
import Modal from "react-responsive-modal";
import moment from "moment";

export default class GridNotasCredito extends Component {
    state = {
        openModal: false,
        idNota: null,
    };

    componentWillMount() {
        const { listar, idP, idD } = this.props;
        console.log("idP ", idP);
        console.log("idD ", idD);
        listar(1, idP, idD);
    }

    changePage = (page) => {
        const { listar, idP, idD } = this.props;
        console.log("idP ", idP);
        console.log("idD ", idD);
        listar(page, idP, idD);
    };

    openModalEditar = (id) => {
        console.log("id editar ", id);
        this.props.leer(id);
        this.setState({ openModal: true, idNota: id });
    };

    closeModal = () => {
        this.setState({ openModal: false, idNota: null });
    };

    renderModalNota = () => {
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={(e) => {
                    this.props.closeModal();
                }}
                center
            >
                <Fragment>
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Agregar Nota de crédito
                        </span>
                    </div>
                    <NotaCreditoForm
                        onSubmit={this.submitNota}
                        cerrarModal={this.props.closeModal}
                        idP={this.props.idP}
                        idD={this.props.idD}
                        devolucion={this.props.devolucion}
                    />
                </Fragment>
            </Modal>
        );
    };

    renderModalNotaEdit = () => {
        return (
            <Modal
                open={this.state.openModal}
                showCloseIcon={false}
                onClose={(e) => {
                    this.closeModal();
                }}
                center
            >
                <Fragment>
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Editar nota de crédito
                        </span>
                    </div>
                    <NotaCreditoForm
                        onSubmit={this.submitEditNota}
                        cerrarModal={this.closeModal}
                        idP={this.props.idP}
                        idD={this.props.idD}
                        devolucion={this.props.devolucion}
                    />
                </Fragment>
            </Modal>
        );
    };

    submitNota = (values) => {
        const { idP, idD } = this.props;
        const data = { ...values };
        data.fecha = moment(data.fecha).format("YYYY-MM-DD");
        data.orden_origen = data.orden_origen.id;
        if (data.devolucion) {
            data.devolucion = data.devolucion.id;
        } else {
            if (idD != null) data.devolucion = this.props.devolucion.id;
        }
        console.log("Oye ", data);
        this.props.crear(data, this.props.closeModal, idP, idD);
    };

    submitEditNota = (values) => {
        const { idP, idD } = this.props;
        const data = { ...values };
        data.fecha = moment(data.fecha).format("YYYY-MM-DD");
        data.orden_origen = data.orden_origen.id;
        if (data.devolucion) data.devolucion = data.devolucion.id;
        console.log("Oye ", data);
        this.props.editar(this.state.idNota, data, this.closeModal, idP, idD);
    };

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
        } = this.props;
        return (
            <Fragment>
                {this.renderModalNota()}
                {this.renderModalNotaEdit()}
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={this.changePage}
                        onSortChange={onSortChange}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width="13%"
                            dataFormat={standardActions({
                                editarModal: this.openModalEditar,
                                eliminarEspecial: eliminar,
                            })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            dataSort
                            dataFormat={(cell, row) => moment(cell).format("L")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="referencia"
                            // dataSort
                        >
                            REFERENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="orden_origen"
                            width="14%"
                            dataFormat={(cell, row) =>
                                cell != null ? cell.no_orden : "------"
                            }
                            // dataSort
                        >
                            ORDEN DE COMPRA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="devolucion"
                            width="14%"
                            dataAlign="center"
                            dataFormat={(cell, row) =>
                                cell != null ? cell.no_despacho : "------"
                            }
                            // dataSort
                        >
                            DEVOLUCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="monto"
                            dataAlign="right"
                            dataFormat={(cell, row) => (
                                <RenderMoneda monto={cell} />
                            )}
                            // dataSort
                        >
                            MONTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aplicada"
                            dataSort
                            width="8%"
                            dataFormat={(cell, row) => {
                                if (cell) {
                                    return (
                                        <span className="t-verde font-weight-bold">
                                            Aplicada
                                        </span>
                                    );
                                } else {
                                    return (
                                        <span className="t-corinto font-weight-bold">
                                            Pendiente
                                        </span>
                                    );
                                }
                            }}
                        >
                            APLICADA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="observaciones"
                            width="20%"
                            // dataSort
                        >
                            OBSERVACIONES
                        </TableHeaderColumn>
                    </Grid>
                </LoadMask>
            </Fragment>
        );
    }
}
