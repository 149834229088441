import React, { Component } from "react";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import Modal from "react-responsive-modal";
import PDFViewer from "pdf-viewer-reactjs";

export default class PreviewFactura extends Component {

    imprimir = () =>{
        var obj = document.getElementById('pdfTicket');
        obj.contentWindow.focus();
        obj.contentWindow.print();
    }

    render() {
        let { ticket, loader } = this.props;
        return (
            <div className="col-12 mx-0 px-0">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {ticket != null ? (
                        <div className="col-12 row mx-0 px-0">
                            <div
                                className="col-12 row mx-0 px-0 d-flex my-4"
                                style={{
                                    backgroundColor: "var(--primaryColor)",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="col-7 d-flex align-items-center justify-content-start">
                                    <h6 className="t-blanco my-4">
                                        Vista previa
                                    </h6>
                                </div>
                                <div className="col-5 d-flex justify-content-end align-items-center">
                                    <a
                                        target="_blank"
                                        //href={ticket}
                                        //download={`${ticket}`}
                                        className="btn btn-azul-claro m-1 align-self-center t-blanco"
                                        style={{
                                            border: "1px solid #fff",
                                            minWidth: "100px",
                                        }}
                                        onClick={this.imprimir}
                                    >
                                        Imprimir
                                    </a>
                                </div>
                            </div>
                            <br />
                            <div className="col-12 d-flex justify-content-center" style={{minHeight:"400px"}}>
                                <iframe
                                    src={ticket} id="pdfTicket"
                                    frameborder="0"
                                    width="100%" height="100%">
                                </iframe>
                            </div>
                            {/* <div className="prueba col-12 mx-0 px-0" id="documento">
                                <PDFViewer
                                    navbarOnTop={true}
                                    hideRotation={false}
                                    document={{
                                        url: `${ticket}`,
                                    }}
                                />
                            </div> */}
                        </div>
                    ) : (
                        <div className="t-mostaza align-self-center">
                            {"Documento no disponible para mostrar"}
                        </div>
                    )}
                </LoadMask>
            </div>
        );
    }
}
