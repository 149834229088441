import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/bonificaciones/bonificacion';
import BonificacionesGrid from './bonificacionGrid';

const mstp = state => {
    return {
        ...state.bonificacion,
        permisos: state.login.me.permisos,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BonificacionesGrid)
