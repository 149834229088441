import React from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderFieldRadio, renderTextArea} from '../../../Utils/renderField';


let ReajusteForm = (props) => {
    const { handleSubmit, producto} = props;
    return (
        <form name="anulacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row col-md-12 m-0 p-0 d-flex justify-content-start">
                    <div className={`${props.optionColor1 ? 'colorOptionModal' : ''} form-group has-feedback col-md-4 col-12 d-flex justify-content-center`}>
                        <Field
                            name="tipo_movimiento"
                            label="Reajuste de producto"
                            component={renderFieldRadio}
                            type="radio"
                            value={"50"}
                            className="form-control" />
                    </div>
                    <div className={`${!props.optionColor1 ? 'colorOptionModal' : ''} form-group has-feedback col-md-3 col-12 d-flex justify-content-center`}>
                        <Field
                            value={"60"}
                            name="tipo_movimiento"
                            label="Dar de baja"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control" />
                    </div>
                </div>

            <div className="row">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-primary" style={{ color: "var(--primaryColor)" }} htmlFor="fecha_fundacion">Código</label>
                    <div className="col-md-12 p-0 m-0">
                        <span className=" t-primary font-weight-bold">{producto.codigo}</span>
                    </div>
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-primary" style={{ color: "var(--primaryColor)", fontWeight:"bolder" }} htmlFor="fecha_fundacion">Nombre</label>
                    <div className="col-md-12 p-0 m-0">
                        <span className=" t-primary font-weight-bold">{producto.nombre} </span>
                    </div>
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback  col-6">
                    <label className="t-azul"  htmlFor="cantidad">Cantidad</label>
                    <Field
                        name="cantidad"
                        label="cantidad"
                        type="number"
                        component={renderField}
                        top={{top:"95px"}}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-sm-12  col-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        rows={7}
                        component={renderTextArea}
                        type="text"
                        top={{top:"174px"}}
                        className="form-control" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={() => {
                            props.cerrarModal(false);
                        }} className="btn btn-secondary  align-self-center">Cancelar</button>
                        <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


ReajusteForm = reduxForm({
    form: 'ReajusteForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcion: validators.exists()('Este campo es requerido'),
            cantidad: validators.exists()('Este campo es requerido'),
        });
    },
})(ReajusteForm);


const selector = formValueSelector('ReajusteForm')
ReajusteForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_movimiento')
    let optionColor1 = false;
    if(tipo_movimiento == 50 ){
        optionColor1 = true
    }
    return {
        optionColor1
    }
})(ReajusteForm)

export default ReajusteForm;
