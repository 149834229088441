import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderFieldRadio, SelectField, renderSelecttree } from '../../../Utils/renderField';
import {renderTextArea} from "../../../Utils/renderField/renderField";
import { Link } from 'react-router-dom';

const origen_pago = [
    {label: 'Banco', value: 10},
    {label: 'Efectivo', value: 20},
]

let Bonificacionform = (props) => {
    const { handleSubmit, pristine, reset, opcion_monto, onChangeTitulo, tipo_gastos } = props;
    return (
        <form name="Bonificacionform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            {/* <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field
                        name="es_bonificacion"
                        label="Bonificación"
                        component={renderFieldRadio}
                        type="radio"
                        value={"true"}
                        // parse={(value)=> value=="true"}
                        onChange={(e) => {
                            onChangeTitulo(e.target.value)
                        }}
                        className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-4 col-12">
                    <Field
                        name="es_bonificacion"
                        label="Descuento"
                        component={renderFieldRadio}
                        type="radio"
                        value={"false"}
                        onChange={(e) => {
                            onChangeTitulo(e.target.value)
                        }}
                        className="form-control" />
                </div>
            </div> */}
            <div className="row justify-content-center">
                    <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                        <label className="t-azul" htmlFor="nombre">Nombre</label>
                        <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                    {
                        opcion_monto && (
                            <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                                <label className="t-azul"  htmlFor="monto">Monto</label>
                                <Field name="monto" label="monto" component={renderCurrency} type="text" className="form-control" />
                            </div>
                        )
                    }
            </div>
            {
                (opcion_monto) && (
                    <div className="row justify-content-center">
                            <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                                <label className="t-azul" htmlFor="origen_pago">Origen de pago</label>
                                <Field name="origen_pago" label="origen_pago" component={SelectField}
                                    options={origen_pago}
                                    type="text" className="form-control" />
                            </div>
                            <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                                <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                                <Field
                                    name="tipo_gasto"
                                    label="nombre"
                                    component={renderSelecttree}
                                    options={tipo_gastos}
                                    type="text"
                                    valueKey="value"
                                    labelKey="label"
                                    className="form-control" 
                                />
                            </div>
                    </div>
                )
            }

            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-10 col-md-12 col-12">
                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        className="form-control"
                        rows={3}
                        top={{top:'96px'}}
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-center justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/bonificaciones" >Cancelar</Link>
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};


Bonificacionform = reduxForm({
    form: 'Bonificacionform', // a unique identifier for this form,
    validate: (data) => {
        const errors={}
        if(!data.nombre){
            errors.nombre = "Este campo es requerido"
        }
        if(!data.descripcion){
            errors.descripcion = "Este campo es requerido"
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido"
        } else if(data.monto <= 0){
            errors.monto = "El monto debe ser mayo a 0"
        }
        return errors;
    },
})(Bonificacionform);

const selector = formValueSelector('Bonificacionform');
Bonificacionform = connect((state, ownProps) =>{
    const es_bonificacion = selector(state, 'es_bonificacion')
    let opcion_monto = true
    if(es_bonificacion == "true" || es_bonificacion == undefined){
        opcion_monto = true;
    }else {
        opcion_monto = false;
    }

    return{
        opcion_monto,
    }
})(Bonificacionform)


export default Bonificacionform
