import React, {Fragment} from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';


export const RenderNumber = ({ value, decimalScale, className }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale || 0}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix=""
      displayType="text"
    />
  );
};

export const RenderCurrency = ({ value, className, simbolo, decimalScale }) => {
  return (
    <NumberFormat
      className={`${className} text-left`}
      decimalScale={decimalScale || 2}
      // decimalScale={2}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix={simbolo ? simbolo : "Q. "}
      displayType="text"
    />
  );
};

export const RenderDateTime = ({ value, className }) => {
  if (value) {
    const fecha = new Date(value);
    let date = moment(value).format('DD/MM/YYYY')
    return (
      <span className={className}>
        {date}
        {/* {' '}
        {fecha.toLocaleTimeString()} */}
      </span>
    );
  }
  return (<span className={className}>{value}</span>);
};

export const RenderDateTimeAll = ({ value, className }) => {
    if (value) {
      const fecha = new Date(value);
      let date = moment(value).format('DD/MM/YYYY HH:mm:ss')
      return (
        <span className={className}>
          {date}
          {/* {' '}
          {fecha.toLocaleTimeString()} */}
        </span>
      );
    }
    return (<span className={className}>{value}</span>);
  };

export const formatSelects = (array=[]) =>{
    const nuevoArray = [];
    if (array){
      array.forEach((item,index) => {
          nuevoArray[index]={"label": item.nombre , "value": item.id}
      });
    }
    return nuevoArray
}


export const ReadFields = {
  renderCurrency: RenderCurrency,
  renderNumber: RenderNumber,
};

export const RenderTime = ({ value, className }) => {
  if (value) {
    const fecha = new Date(value);
    let date = moment(value).format('HH:mm')
    return (
      <span className={className} style={{color:"#212529"}}>
        {date}
      </span>
    );
  }
  return (<span className={className}>{value}</span>);
};

export const RenderDatosAfectadoReporte = ({cell,row}) => {
    return(
        <div>
            <span className="font-weight-bold">FECHA </span>
            <RenderDateTime value={cell} ></RenderDateTime> <br/>
            {
                (row.fecha_emision) && (
                    <Fragment>
                        <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                    </Fragment>
                )
            }
            <span className="font-weight-bold">TIPO DE PAGO</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
            {
                (row.cliente) && (
                    <Fragment>
                        <br/>
                        <span className="font-weight-bold">
                            CLIENTE:
                        </span>
                        <span> {row.cliente}</span>
                    </Fragment>

                )
            }
            {
                (row.proveedor) && (
                    <Fragment>
                        <br/>
                        <span className="font-weight-bold">
                            PROVEEDOR:
                        </span>
                        <span> {row.proveedor}</span>
                    </Fragment>

                )
            }
        </div>
    )
}
