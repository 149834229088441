import React, { Component, Fragment } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";
import { withRouter } from 'react-router';

import ToolbarEstadoCuenta from '../../../Utils/Toolbar/ToolbarEstadoCuenta';
import CardSimple from '../../../Utils/Cards/cardSimple';
import Modal from 'react-responsive-modal';
import AnulacionForm from './anulacionForm';
import RealizarPagoForm from './realizarPagoForm';
import RetiroForm from './retiroForm';
import EstadoCuentaForm from './estadoCuentaForm';

import Swal from 'sweetalert2';
import moment from 'moment';

import { getSimbolo } from 'Utils/renderField/renderTableField';
import CardsCaja from '../../../Utils/Cards/cardsCaja';

import {
    AsignarFlotante,
    Nuevo as TransaccionCrear,
} from '../../Transacciones';

// iconos
import { iconos } from '../../../../../utility/icons';


function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}

class TransaccionesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        open_modal_retiro: false,
        open_modal_egreso: false,
        open_modal_ingreso: false,
        modal_flotante_inverso: false,
        archivo: null,
        open_modal_cuenta: false
    }
    componentDidMount() {
        this.cargar();
        this.props.setTitulo(`${this.props.me && this.props.me.propietario}`)
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.me !== this.props.me && this.props.me !== undefined){
            this.props.setTitulo(this.props.me.propietario)
        }
    }

    cargar = () => {
        // Funcion que llama a las acciones del reducer de transacciones para cargar el periodo seleccionado
        // y el listado de transacciones de dicho periodo
        let id = this.props.match.params.id
        const { listPCaja, page, meCuenta, getPeriodo } = this.props;
        meCuenta(id, true);
        getPeriodo();
        listPCaja(id);
    }

    getsimbolo = (valor) => {
        let simbolo = ''
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    setFile = (file) => {
        this.setState({ file });
    }
    botonModal = (id, row) => {

        if (row.estado) {
            return (<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
            onClick={(e) => {
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: true
                })
                this.props.setOpenModal(true);
            }}
            alt="Ver eliminado" />)
    }
    botonRealizarMovimiento = (id, row) => {
        if (!row.estado) {
            return (<div></div>)
        }
        if (row.bloqueado) {
            return (<div></div>)
        }
        if (row.transaccion.flotante_inverso) {
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }} onClick={
                    (e) => {
                        e.preventDefault();
                        this.props.setTransaccion({
                            ...row.transaccion,
                            id_cuenta: this.props.match.params.id
                        });
                        this.setModalFlotante(true);
                    }
                }>
                    <img className="action-img" title="Ver flotante" src={require("../../../../../../assets/img/icons/Pagos_flotantes.png")} alt="Ver pago flotante" />
                </a>
            )
        } else {
            if (!row.flotante) {

                return (<div></div>)
            }
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }} onClick={
                    (e) => {
                        e.preventDefault();
                        this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: false
                        })
                        this.props.setOpenModal(true);
                    }
                }>
                    {
                        (row.tipo_flotante) ? (
                            <img
                                className="action-img" title="cheque cliente"
                                src={iconos.cheque_cliente} alt="Cheque cliente" />
                        ) : (
                                <img
                                    className="action-img" title="Cheque proveedor"
                                    src={iconos.cheque_proveedor} alt="Cheque proveedor" />
                            )
                    }

                </a>
            )
        }

    }
    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }
    onSubmit = (values) => {
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values);
                this.cargar();
            }
        });
    }


    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values);
            }
        });
    }


    cerrerPeriodo = () => {
        Swal.fire({
            title: '¿Desea cerrar el periodo?',
            text: '¡Ya no podrá realizar nuevas transacciones ni anulaciones!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, cerrar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: '¿Está seguro de cerrar el periodo?',
                    text: '¡No podrá revertir esta acción!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, cerrar!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.props.cierrePeriodo(this.props.match.params.id);
                    }
                });
            }
        });
    }
    renderModalAnulacion = () => {
        return (
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Justificación de anulación </span>
                </div>
                {
                    (this.state.transaccion_sel && !this.state.transaccion_sel.estado) ? (
                        <div className="form-validate mb-lg text-center" >
                            {console.log(this.state.transaccion_sel)}
                            <span className="m-none t-mostaza semibold">Fecha anulación: </span><br />
                            <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br />
                            <span className="m-none t-mostaza semibold">Usuario anulación: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br />
                            <span className="m-none t-mostaza semibold">descripción: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br />
                        </div>
                    ) : (
                            <AnulacionForm
                                onSubmit={this.onSubmit}
                                cerrarModal={this.props.setOpenModal}
                            />
                        )
                }
            </Fragment>
        )
    }
    renderModalFlotantePagado = () => {
        let transaccion = null
        if (this.state.transaccion_sel) {
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return (
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Realizar pago </span>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                />
            </Fragment>
        )
    }
    setOpenModalRetiro = (val) => {
        this.setState({ open_modal_retiro: val })
        if (val === true)
            this.props.selectEmpresas()
    }
    setOpenModalRetiro = (val) => {
        this.setState({ open_modal_retiro: val })
        if (val === true)
            this.props.selectEmpresas()
    }
    setOpenModalEstadoCuenta = (val) => {
        if (val)
            this.setState({ open_modal_cuenta: val })
        else
            this.setState({ open_modal_cuenta: val, file: null })

    }

    setOpenModalEgreso = (value) => {
        this.setState({ open_modal_egreso: value });
    }

    setOpenModalIngreso = () => {
        this.setState({ open_modal_ingreso: !this.state.open_modal_ingreso });
    }

    setModalFlotante = (value) => {
        this.setState({ modal_flotante_inverso: value });
    }

    handleSubmitRetiro = (values) => {
        values.cuenta = this.props.match.params.id
        this.props.retiroDeCuenta(values, this.props.match.params.id);
        this.setOpenModalRetiro(false);
    }
    handleSubmitEstadoCuenta = (values) => {
        const { subirEstadoCuenta } = this.props
        if (this.state.file) {
            subirEstadoCuenta(
                { cuenta: this.props.match.params.id },
                [{ name: "csv", file: this.state.file }],
                this.props.match.params.id
            )
            this.setOpenModalEstadoCuenta(false);
        } else {
            subirEstadoCuenta(
                { cuenta: this.props.match.params.id },
                [],
                this.props.match.params.id
            )
        }
    }
    handleBloqueo = (id) => {
        this.props.bloquear_transaccion(id);
        this.cargar();
    }
    aceptarEnvio = (id) => {
        this.props.AcceptMovimiento(id, this.props.match.params.id);
    }
    rechazarEnvio = (id) => {
        this.props.RefuseMovimiento(id, this.props.match.params.id);
    }
    cancelEnvio = (id) => {
        this.props.CancelMovimiento(id, this.props.match.params.id);
    }
    render() {
        const { data, me, loader, listar: onPageChange, onSortChange, eliminar, periodo, valorMovimiento, open_modal } = this.props;
        let id = this.props.match.params.id;
        return (
            <div >
                <CardSimple
                margen={'mt-0'}
                >
                <div className="col-md-12 row m-0 p-0 d-flex justify-content-around">
                    <div className="col-lg-12 col-md-12 p-0 row">
                        {
                            (!periodo.es_cerrado) && (
                                <div className="col-lg-12 col-sm-12 col-12 mb-5 pl-0 ml-0 ml-md-1 d-flex flex-wrap justify-content-between">

                                    <div className="m-0 p-0 mt-3 d-flex flex-wrap justify-content-between flex-column flex-sm-row flex-column">
                                        <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setOpenModalEgreso(true);
                                                    }}
                                                    className="btn btn-t btn-primary align-self-center mr-md-2">Egreso</button>
                                        <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setOpenModalIngreso(true);
                                                    }}
                                                    className="btn btn-t btn-primary align-self-center mr-md-2">Ingreso</button>
                                    </div>
                                    <div className="m-0 p-0 mt-3 d-flex flex-wrap justify-content-between flex-column flex-sm-row flex-column">
                                        <button type="button"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.cerrerPeriodo();
                                            }}
                                            className="btn btn-cerrar mr-md-2 align-self-center">Cerrar
                                        </button>
                                        {
                                            (periodo.es_principal) && (
                                                <button type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // this.handleSubmitEstadoCuenta(1);
                                                        this.setOpenModalEstadoCuenta(true);
                                                    }}
                                                    className="btn btn-ec align-self-center">Subir E.C.
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-12 col-md-7">
                            <ToolbarEstadoCuenta {...this.props} />
                        </div>
                        <div className="col-12 col-md-5 mb-5">
                            <div className="row col-12 m-0 p-0">
                                <div className="col-5 d-flex align-items-center justify-content-end">
                                    <span className="t-musgo font-weight-bold">Saldo inicial</span>
                                </div>
                                <div className="col-7 d-flex justify-content-end pl-0">
                                    <h4 className="t-primary m-none">{<RenderMoneda className="pl-0" monto={periodo.saldo_apertura} simbolo={this.getsimbolo(periodo.moneda)} />}</h4>
                                </div>
                            </div>
                            <div className="row col-12 m-0 p-0">
                                <div className="col-5 d-flex align-items-center justify-content-end">
                                    <span className="t-musgo font-weight-bold">
                                        Saldo {periodo.es_cerrado ? 'final' : 'actual'}
                                    </span>
                                </div>
                                <div className="col-7 d-flex justify-content-end pl-0">
                                    <h4 className="t-primary m-none">{
                                        <RenderMoneda className="pl-0" monto={
                                            me.saldo_reserva ?
                                                (
                                                    me.saldo_reserva + me.saldo_a
                                                ) : (
                                                    periodo.saldo_cierre ? periodo.saldo_cierre : periodo.saldo_apertura
                                                )
                                        } simbolo={this.getsimbolo(periodo.moneda)} />
                                    }</h4>
                                </div>
                            </div>
                            <div className="row col-12 m-0 p-0">
                                <div className="col-5 d-flex align-items-center justify-content-end">
                                    <span className="t-musgo font-weight-bold">Saldo disponible</span>
                                </div>
                                <div className="col-7 d-flex justify-content-end pl-0">
                                    <h4 className="t-verde m-none">{<RenderMoneda className="pl-0" monto={periodo.saldo_cierre} simbolo={this.getsimbolo(periodo.moneda)} />}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CardsCaja
                    data={this.props.pendientes}
                    loader={loader}
                    aceptarEnvio={this.aceptarEnvio}
                    rechazarEnvio={this.rechazarEnvio}
                    cancelEnvio={this.cancelEnvio}
                    titulo={"Transferencias pendientes"}
                />

                <Modal open={open_modal} showCloseIcon={false} onClose={(e) => {
                    this.props.setOpenModal(false)
                }} center>
                    {
                        (this.state.anular_transaccion) ? (
                            this.renderModalAnulacion()
                        ) : (
                                this.renderModalFlotantePagado()
                            )
                    }
                </Modal>
                <Modal open={this.state.open_modal_egreso} showCloseIcon={false} onClose={(e) => {
                    this.setOpenModalEgreso(false)
                }} center>
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title"> Registrar Egreso </span>
                    </div>

                    <TransaccionCrear
                        match={{
                            params:{
                                id: id
                            }
                        }}
                        ingreso = {false}
                        cancelar = {(e)=>{this.setOpenModalEgreso(false)}}
                    />

                </Modal>
                <Modal open={this.state.open_modal_ingreso} showCloseIcon={false} onClose={(e) => {
                    this.setOpenModalIngreso(false)
                }} center>
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title"> Registrar Ingreso </span>
                    </div>

                    <TransaccionCrear
                        match={{
                            params:{
                                id: id
                            }
                        }}
                        ingreso = {true}
                        cancelar = {this.setOpenModalIngreso}
                    />

                </Modal>

                <Modal open={this.state.modal_flotante_inverso} onClose={(e) => {
                    this.setModalFlotante(false)
                }} center>
                    <AsignarFlotante
                        setModalFlotante={this.setModalFlotante}
                        cargar={this.cargar}
                    />
                </Modal>


                <Modal open={this.state.open_modal_retiro} showCloseIcon={false} onClose={(e) => {
                    this.setOpenModalRetiro(false)
                }} center>
                    <div className="col-12 modal-card-header">
                            <span className="col-12 col-lg-10 modal-card-title"> Registrar Retiro </span>
                        </div>
                    <RetiroForm
                        initialValues={{ fecha: new Date() }}
                        simbolo={getSimbolo(periodo.moneda)}
                        manejo_tipo_cambio
                        data={this.props.empresas}
                        onSubmit={this.handleSubmitRetiro}
                        setOpenModalRetiro={this.setOpenModalRetiro}
                    />
                </Modal>
                <Modal open={this.state.open_modal_cuenta} showCloseIcon={false} onClose={(e) => {
                    this.setOpenModalEstadoCuenta(false)
                }} center>
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title"> Subir estado de cuenta </span>
                    </div>
                    <EstadoCuentaForm
                        setFile={this.setFile}
                        onSubmit={(values) => { this.handleSubmitEstadoCuenta(values); }}
                        setOpenModalEstadoCuenta={this.setOpenModalEstadoCuenta}
                    />
                </Modal>



                <div className="tabla-scoll">
                    <Grid hover striped data={{ count: 10, results: periodo.movimientos }} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                        <TableHeaderColumn
                            dataField="id"
                            width="15%"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                eliminarModal: this.abrirModalEliminar,
                                adicional: this.botonModal,
                                adicional2: this.botonRealizarMovimiento,
                                candado: this.handleBloqueo
                            })}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="fecha"
                            width="10%"
                            columnClassName=""
                            tdStyle={cellTachado}
                            dataSort
                            dataFormat={
                                (cell, row) => {
                                    return (
                                        <div>
                                            <span className="font-weight-bold t-primary">Fecha </span><RenderDateTime fecha={cell} className="t-texto"></RenderDateTime> <br />
                                            {
                                                (row.fecha_emision) && (
                                                    <Fragment>
                                                        <span className="font-weight-bold t-primary"> Fecha emisión </span><RenderDateTime className="t-texto" fecha={row.fecha_emision} ></RenderDateTime> <br />
                                                    </Fragment>
                                                )
                                            }
                                            <span className="font-weight-bold t-primary">Tipo de pago</span> <span className="t-texto">{row.flotante ? 'Flotante' : 'Inmediato'}</span>
                                        </div>
                                    )
                                }
                            }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="transaccion"
                            width="20%"
                            dataSort
                            tdStyle={cellTachado}
                            dataFormat={(cell, row) => (
                                cell ? (
                                    <div>
                                        {
                                            cell.lugar_transaccion && (
                                                <Fragment>

                                                    <span className="font-weight-bold t-primary">Transaccion realizada: </span>
                                                    <span className="t-texto" >{cell.lugar_transaccion}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_cliente && (
                                                <Fragment>

                                                    <span className="font-weight-bold t-primary">Cliente: </span>
                                                    <span className="t-texto" >{cell.nombre_cliente}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_proveedor && (
                                                <Fragment>

                                                    <span className="font-weight-bold t-primary">Proveedor: </span>
                                                    <span className="t-texto" >{cell.nombre_proveedor}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.nombre_tipo_gasto && (
                                                <Fragment>

                                                    <span className="font-weight-bold t-primary">Gasto: </span>
                                                    <span className="t-texto" >{cell.nombre_tipo_gasto}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.label_tipo_transaccion && (
                                                <Fragment>
                                                    <span className="font-weight-bold t-primary">Transaccion: </span>
                                                    <span className="t-texto ">{cell.label_tipo_transaccion}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.no_documento && (
                                                <Fragment>
                                                    <span className="font-weight-bold t-primary">No. documento: </span>
                                                    <span className="t-texto ">{cell.no_documento}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell.propietario && (
                                                <Fragment>
                                                    <span className="font-weight-bold t-primary">Propietario: </span>
                                                    <span className="t-texto ">{cell.propietario}</span><br />
                                                </Fragment>
                                            )
                                        }
                                        <span className="font-weight-bold t-primary">Descripcion: </span>
                                        <span className="t-texto ">{cell.descripcion}</span><br />
                                        {
                                            cell.orden && (
                                                <div>
                                                    <span className="font-weight-bold">orden: </span>
                                                    <span className="t-musgo ">{cell.orden}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : " NO HAY INFORMACION "
                            )}
                        >
                            DETALLE TRANSACCION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="es_ingreso"
                            width="10%"
                            tdStyle={cellTachado}
                            dataSort
                            dataFormat={
                                (cell, row) => (cell ? "Ingreso" : "Egreso")
                            }
                        >
                            MOVIMIENTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="debe"
                            dataSort
                            headerAlign="center"
                            dataAlign="right"
                            width="15%"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={row.es_ingreso ? cell : 0} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            DEBE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="haber"
                            dataSort
                            width="15%"
                            headerAlign="center"
                            dataAlign="right"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={row.es_ingreso ? 0 : cell} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            HABER
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="saldo"
                            dataSort
                            width="15%"
                            headerAlign="center"
                            dataAlign="right"
                            tdStyle={cellTachado}
                            dataFormat={
                                (cell, row) => (
                                    <RenderMoneda monto={cell} simbolo={this.getsimbolo(periodo.moneda)} />
                                )
                            }
                        >
                            SALDO
                        </TableHeaderColumn>
                    </Grid>
                </div>

            </CardSimple>
            </div>
        )
    }
}

export default withRouter(TransaccionesGrid)
