import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';

import ActividadesForm from './ActividadesForm';




class ActividadesCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR ACTIVIDAD',
        cuentas: []
    }
    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR ACTIVIDAD'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id;
        let valor = _.cloneDeep(values);
        if(valor.bono){
            valor.bono = valor.bono.id;
        }
        this.state.editar? editar(id, valor) : crear(valor);
    }
    render() {
        const { loader, cuentas_banco} = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <ActividadesForm
                        onSubmit={this.onSubmit} />
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default ActividadesCrear;
