import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderTextArea,
    renderFieldCheck
} from "../../../Utils/renderField/renderField";

const AprobacionForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="rechazoOCform" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">                
                <div className="form-group has-feedback col-md-6 col-12">
                    <Field
                        name="a_produccion"
                        label="¿Enviar a produccion?"
                        component={renderFieldCheck}
                        value={false}
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <Field
                        name="a_despachar"
                        label="¿Autorizado para despachar?"
                        component={renderFieldCheck}
                        value={false}
                    />
                </div>
            </div>
            
            <div className="row  justify-content-sm-around justify-content-center">
                <button onClick={()=>{props.handleModalAprobar(false)}} type="button" className="btn btn-secondary m-1 align-self-center">CANCELAR</button>                
                <button type="submit" className="btn btn-primary m-1 align-self-center">APROBAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'aprobacionOVform', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
        });
    },
})(AprobacionForm);
