import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";

import ReporteForm from "./reporteForm";

class ReporteNuevo extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        values: {},
        estadoRead: false,
    };
    setUnidadMedida = (index, data) => {
        this.props.setUnidadMedida(index, data);
    };
    eliminarUnidadMedida = (index) => {
        this.props.eliminarUnidadMedida(index);
    };
    setOrden = (orden) => {
        this.props.setOrden(orden);
    };
    onSubmit = (values) => {
        const { crear, editar, reportarProduccion } = this.props;
        let id = this.props.match.params.empresa;
        reportarProduccion(values, id);
    };
    handledestroy = () => {
        this.props.destroyformulario("ProduccionForm");
    };
    render() {
        const { loader, empresas, itemov, setRecetaProducto } = this.props;
        let empresa = this.props.match.params.empresa;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br />
                    <ReporteForm
                        onSubmit={this.onSubmit}
                        estadoRead={this.state.estadoRead}
                        empresa={empresa}
                        setRecetaProducto={setRecetaProducto}
                        handledestroy={this.handledestroy}
                        setUnidadMedida={this.setUnidadMedida}
                        unidadMedida={this.props.unidadMedida}
                        eliminarUnidadMedida={this.eliminarUnidadMedida}
                        setOrden={this.setOrden}
                        orden={this.props.orden}
                        initialValues={{
                            tipo_horario: 1,
                            horas_trabajadas: 0,
                            reporte: [{}],
                        }}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default ReporteNuevo;
