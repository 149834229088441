import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/puntoVenta/puntoVenta";
import MiCaja from "./MiCaja";

const mstp = (state) => {
    return { ...state.puntoVenta };
};

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(MiCaja);
