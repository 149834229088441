import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {TIPO_ENTREGA} from "../../../../../utility/constants";


export default class TransporteDetalle extends Component{
    getEntrega =(valor)=>{
        let entrega =''
        TIPO_ENTREGA.forEach((item,index)=>{
            if (item.value === valor){
                entrega = item.label
            }
        })
        return entrega 
    }
    render(){
        const { tipo_entrega,encargado, nombreT, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <div className="m-l row justify-content-arround text-align-start">
                    <div className="col-lg-12 col-md-12 col-12">
                        <h5 className="t-primary">DATOS DE TRANSPORTE</h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <h6 className=" m-l" style={{color:"#f3a631"}} >Tipo Entrega: </h6>                   
                        <h6 className=" m-l t-musgo"> {this.getEntrega(tipo_entrega)}</h6><br/>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <h6 className=" m-l t-mostaza" >Nombre de transporte: </h6>                   
                        <h6 className=" m-l t-musgo"> {nombreT? nombreT:" ---------"}</h6><br/>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <h6 className=" m-l t-mostaza">Encargado: </h6>                   
                        <h6 className=" m-l t-musgo"> {encargado? encargado:" --------"}</h6>
                    </div>
                </div>
            </div>
        )
    }

}