import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import {
    RenderMoneda,
    RenderNombreSelect,
} from "../../../Utils/renderField/renderTableField";

export default class ProductoGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            page,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
        } = this.props;
        if (permisos["productos"] === false) {
            if (!!permisos[`ver_productos`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <CardSimple
                margen="mt-0"
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="producto/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                        permisos={permisos.productos}
                    />
                }
            >
                <Grid
                    hover
                    striped
                    data={data}
                    page={page}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                >
                    {permisos.productos && (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "producto",
                                eliminar,
                            })}
                        ></TableHeaderColumn>
                    )}
                    <TableHeaderColumn isKey dataField="nombre" dataSort>
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="unidad_medida" dataSort>
                        UNIDAD DE MEDIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="codigo" dataSort>
                        CODIGO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="descripcion" dataSort>
                        DESCRIPCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_producto"
                        dataSort
                        dataFormat={(cell, row) => (
                            <RenderNombreSelect cell={cell} />
                        )}
                    >
                        TIPO PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_venta"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell, row) => (
                            <RenderMoneda monto={cell} simbolo={"Q"} />
                        )}
                    >
                        PRECIO VENTA
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
