import React, { Component } from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField, AsyncSelectField, SelectField } from "../../../Utils/renderField";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "api";
import {
    RenderCurrency,
    RenderNumber,
} from "../../../Utils/renderField/renderReadField";

class ItemArray extends Component {
    state = {
        producto: {},
    };
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if (producto !== undefined && producto.cantidad_actual > 0) {
            todos[index]["despachar"] = producto.cantidad;
        }
    };
    render() {
        const { getProductosOrden, eliminar, index, Despacho } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td
                    className="text-center sin-borde-top"
                    style={{ width: "48px" }}
                >
                    <img
                        className="action-img iconoAcciones iconoEliminar"
                        src={require("assets/img/icons/Cerrar.png")}
                        alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar();
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${Despacho}.producto`}
                        loadOptions={getProductosOrden}
                        type="text"
                        component={AsyncSelectField}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="producto"
                        labelKey="producto_nombre"
                        onChange={(e) => {
                            this.setState({ producto: e });
                            this.setPrecioUnitario(e);
                        }}
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top">
                    <label>
                        <RenderNumber value={this.state.producto.cantidad} />
                    </label>
                </td>
                <td className="sin-borde-top">
                    <label>
                        <RenderNumber
                            value={
                                this.state.producto.cantidad -
                                this.state.producto.cantidad_actual
                            }
                        />
                    </label>
                </td>
                <td className="sin-borde-top">
                    <label>
                        <RenderNumber value={this.state.producto.existencias} />
                    </label>
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${Despacho}.despachar`}
                        type="number"
                        component={renderField}
                        min={0}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
            </tr>
        );
    }
}
const renderProductos = ({
    fields,
    meta: { error, submitFailed },
    getProductosOrden,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Producto</th>
                            <th style={{ width: "18%" }}>
                                Cantidad Solicitada
                            </th>
                            <th style={{ width: "18%" }}>
                                Cantidad Despachada
                            </th>
                            <th style={{ width: "18%" }}>Existencias</th>
                            <th style={{ width: "18%" }}>A despachar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((Despacho, index) => {
                            let todos = fields.getAll();
                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    Despacho={Despacho}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos

const opcionesDespacho = [
    { nombre: "Cliente", id: 40},
    { nombre: "Sucursal", id: 100}
]
class DespachoForm extends Component {
    getProductosOrden = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        params.orden = this.props.orden;
        return api
            .get("ordenventa/productosporventa", params)
            .then((data) => {
                if (data) {
                    return data;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getBodegas = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        return api
            .get("bodega/getBodegaSucursales", params)
            .then((data) => {
                if (data) {
                    console.log(data);
                    return data;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const { handleSubmit, orden } = this.props;
        return (
            <form
                name="DespachoForm"
                className="form-validate mb-lg mt-5"
                onSubmit={handleSubmit}
            >
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12 col-lg-5">
                        <label> Despacho a </label>
                        <Field
                            name={`tipo_despacho`}
                            options={opcionesDespacho}
                            type="text"
                            component={SelectField}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback  col-12 col-lg-5">
                        {this.props.tipo_despacho == 100 &&(
                            <div>
                                <label> Bodega sucursal </label>
                                <Field
                                    label="Bodega"
                                    name={`bodega_destino`}
                                    loadOptions={this.getBodegas}
                                    type="text"
                                    component={AsyncSelectField}
                                    top={{ top: "4px", position: "inherit" }}
                                    valueKey="id"
                                    labelKey="nombre"
                                    className="form-control"
                                    />
                            </div>
                        )}
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12">
                        <FieldArray
                            name="Despachos"
                            component={renderProductos}
                            getProductosOrden={this.getProductosOrden}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/ordenesventa/${orden}/detalle`}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

DespachoForm = reduxForm({
    form: "DespachoForm", // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        if(data.tipo_despacho == 100){
            if(!data.bodega_destino){
                errors.bodega_destino="Campo requerido"
            }
        }
        if (!data.Despachos || !data.Despachos.length) {
            errors.Despachos = { _error: "Debe ingresar al menos un producto" };
        } else {
            const despachoArray = [];
            data.Despachos.forEach((producto, index) => {
                let detErrors = {};
                let repetidos = [];
                if (producto.producto) {
                    repetidos = _.filter(data.Despachos, (x) => {
                        if (x.producto)
                            return x.producto.id == producto.producto.id;
                        return false;
                    });
                    if (!producto.despachar) {
                        detErrors.despachar = "Campo requerido";
                    } else {
                        if (parseFloat(producto.despachar) <= 0) {
                            detErrors.despachar = "Debe ser mayor a 0";
                        }
                        if (
                            parseFloat(producto.despachar) >
                            producto.producto.cantidad_actual
                        ) {
                            detErrors.despachar = `Debe ser un maximo de ${producto.producto.cantidad_actual}`;
                        }
                        if (
                            parseFloat(producto.despachar) >
                            producto.producto.existencias
                        ) {
                            detErrors.despachar = `Hay una existencias de ${producto.producto.existencias}`;
                        }
                    }
                }

                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.producto = "Este producto está repetido";
                    }
                }
                
                if (detErrors) {
                    despachoArray[index] = detErrors;
                }
            });
            if (despachoArray.length) {
                errors.Despachos = despachoArray;
            }
        }
        return errors;
    },
})(DespachoForm);

const selector = formValueSelector("DespachoForm");
DespachoForm = connect((state) => {

    const tipo_despacho = selector(state, "tipo_despacho");

    return {
        tipo_despacho
    };
})(DespachoForm);

export default DespachoForm;
