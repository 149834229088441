import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import { PROCESOS } from "../../../../../utility/constants";
import CardPvEnviadas from "../../../Utils/Cards/cardsPM";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Card } from "antd";
import { getsimbolo } from "../../../../../utility/constants";
import { Redirect } from "react-router-dom";
import ToolbarFiltrosOV from "./filtrosOV";

export default class ListaordenesVentaGrid extends Component {
    componentDidMount() {
        const { listarEspecial, page, setEmpresa } = this.props;
        listarEspecial(page, true);
        let id = this.props.match.params.id;
        setEmpresa(id);
    }

    changePagina = (page) => {
        this.props.listarEspecial(page, true);
    };

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChangeEspecial,
            eliminar,
            permisos,
        } = this.props;
        let empresaEntidad = this.props.match.params.id;
        if (permisos["ordenventa"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple
                margen=" "
                toolbar={
                    <ToolbarSimple
                        //textBoton2="Nueva OP"
                        ruta2={`/produccion/${this.props.match.params.id}/orden`}
                        buscar={this.props.searchChange2}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                        estatefilterBar={true}
                        filterbar={
                            <ToolbarFiltrosOV
                                changeEstado={this.props.changeFiltroEstado}
                                valorEstado={this.props.filtro_estado}
                            />
                        }
                    />
                }
            >
                {this.props.setTitulo(
                    `${this.props.produccion.nombre_empresa}`
                )}
                <br />
                <Grid
                    page={this.props.page}
                    hover
                    striped
                    data={data}
                    loading={loader}
                    onPageChange={this.changePagina}
                    onSortChange={onSortChangeEspecial}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={standardActions({
                            // verpro:"ordenesventa",
                            inicioProduccion: `/produccion/${empresaEntidad}/orden`,
                        })}
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={(cell, row) => {
                            return <RenderDateTime fecha={cell} />;
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="no_orden" dataSort>
                        NO. ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        dataSort
                        dataFormat={(cell, row) => (cell ? cell.nombre : "--")}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="ordenes_produccion"
                        dataSort
                        dataAlign="center"
                        tdStyle={{ paddingRight: "5%" }}
                        thStyle={{ paddingRight: "5%" }}
                    >
                        ORDENES DE PRODUCCIÓN
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
