import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {  Monedas, getsimbolo } from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


import {api} from 'api';

const getProveedores = (search) => {
    return api.get("clientes", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ItemArray extends Component {
    state = {
        producto: {},
        sel_producto: {}
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getProductos(search)
          }
      }
    componentDidUpdate(prevProps, prevState){
        if(this.props.simbolo !== prevProps.simbolo){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.proveedor !== prevProps.proveedor){
            if(this.textInput && this.textInput.current){
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                  })
                this.forceUpdate();
               // this.textInput.ref.props.loadOptions()
            }
        }
        if(this.props.prod_seleccionado !== prevProps.prod_seleccionado){
            const { todos, index } = this.props;
            if( this.props.prod_seleccionado)
                this.setState({producto: this.props.prod_seleccionado.producto});
        }
    }
    setPrecioUnitario = (producto_nuevo) => {
        const { todos, index } = this.props;
            if(producto_nuevo !== undefined && producto_nuevo.precio_ultimo !== undefined){
                todos[index]['precio_unitario'] = producto_nuevo.precio_ultimo;
            }

    }
    render(){
        const {index, producto, simbolo, eliminar, getProductos, subtotal, proveedor} = this.props;
        return (
            <tr key={index} style={{height:"68px"}}>
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${producto}-${simbolo}-${proveedor}`}
                        ref={this.textInput}
                        id={`${producto}.selectProducto`}
                        name={`${producto}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top ={{top:"67px", position:"inherit"}}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({producto: e});
                            this.setPrecioUnitario(e);
                        }}
                        className="form-control" />
                        {
                            (this.state.producto && this.state.producto.menor_precio) && (
                                <div className="speech-bubble mt-1 p-2">
                                   <span> {this.state.producto.menor_precio}</span>
                                </div>
                            )
                        }

                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.cantidad`}
                        type="number"
                        component={renderField}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
            </tr>
        );
    }
}


const renderProductos = ({fields, meta: {error, submitFailed }, simbolo, getProductos, reRender, productos, proveedor}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Producto</th>
                        <th style={{width: "20%"}}>Cantidad</th>
                    </tr>
                    </thead>
                {/* </table>

            </div>

            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped"> */}
                    <tbody>
                    {fields.map((producto, index) => {
                        let subtotal = 0;
                        let mensaje;
                        let todos = fields.getAll();
                        const prod = fields.get(index);
                        try {
                            subtotal = Number(prod.cantidad) * Number(prod.precio_unitario)
                        } catch (error) {
                            subtotal = 0;
                        }


                        return (
                            <ItemArray
                                prod_seleccionado={prod}
                                proveedor={proveedor}
                                index={index}
                                key={index}
                                todos={todos}
                                subtotal={subtotal}
                                producto={producto}
                                getProductos={getProductos}
                                simbolo={simbolo}
                                eliminar={ () =>{
                                    fields.remove(index)
                                }}
                                field={fields ? fields.get(index) : null}
                            />
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR PRODUCTO
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger">
                        {error}
                </div>}
    </div>
)
//fin de render de productos

class OrdenCompraForm extends Component {
    state = {
        empresa: null,
        moneda: null,
        credito: {}
    }
    getProductos = (search) => {
        let params = {}
        if(search){
            params.search = search;
        }
        if(this.state.empresa){
            params.empresa = this.state.empresa;
        }
        return api.get("productos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
    };
    reRender = () => {
        this.setState({
            proveedor: this.props.producto,
            moneda: this.props.moneda
        });
    }
    render() {
        const { handleSubmit,
                total,
                simbolo,
                valor_iva,
                empresas,
                empresas_destino,
                onChangeOrigen,
                subtotal,
                productos, tiene_iva} = this.props;
        return (
            <form name="ordencompraForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                {
                    (this.props.proceso_estado === 80 && this.props.estado_justificacion) && (
                    (
                        this.props.justificacion &&(
                            <div>
                                <div className="row justify-content-center text-center " >
                                    <div className="col-12 col-md-10"  style={{backgroundColor:"#e8e8e8"}}>
                                        <br/>
                                        <h6 className="t-mostaza text-uppercase" >Justificacion de rechazo: </h6>
                                        <p style={{fontWeight:600}} className="t-primary">{this.props.justificacion[this.props.justificacion.length -1].justificacion}</p>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        )
                    ))
                }
                <div className="row justify-content-center">
                    <h4 className="t-primary" >Datos Orden de Compra</h4>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 row m-0 p-0">
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Fecha</label>
                                <Field
                                    name="fecha"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="empresa">Empresa Origen</label>
                                <Field
                                    name="empresa_origen"
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas}
                                    onChange={(e) => {
                                        onChangeOrigen(e.target.value)
                                        this.setState({
                                            empresa: e.target.value
                                        }, ()=> {
                                            this.getProductos(null);
                                        });
                                        //handleCuentas(e.target.value, 'GTQ')
                                    }}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="empresa">Empresa Destino</label>
                                <Field
                                    name="empresa_destino"
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas_destino}
                                    onChange={(e) => {
                                        //handleCuentas(e.target.value, 'GTQ')
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback  col-sm-12  col-12">
                                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                                <Field
                                    name="descripcion"
                                    label="descripcion"
                                    rows={7}
                                    component={renderTextArea}
                                    type="text"
                                    top={{top:"174px"}}
                                    className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0">
                        <FieldArray
                            proveedor={this.state.proveedor}
                            productos={productos}
                            reRender={this.reRender}
                            getProductos={this.getProductos}
                            simbolo={simbolo}
                            name="detalle_orden"
                            component={renderProductos} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/ordencompras" >CANCELAR</Link>
                            <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

//{"fecha":"2019-10-15","empresa":"1","proveedor":1,"moneda":"USD","detalle_orden":[{"producto":2,"cantidad":"3","precio_unitario":3}]}


OrdenCompraForm = reduxForm({
    form: 'ordencompraForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        if(!data.fecha){
            errors.fecha = "Campo requerido.";
        }
        if(!data.empresa) {
            errors.empresa = "Campo requerido.";
        }
        if(!data.proveedor){
            errors.proveedor = 'Campo requerido';
        }
        if(!data.moneda){
            errors.moneda = "Campo requerido"
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        if(!data.empresa_origen){
            errors.empresa_origen = "Campo requerido";
        }
        if(!data.empresa_destino){
            errors.empresa_destino = "Campo requerido";
        }
        if(data.descuento){
            let subtotal = 0;
            data.detalle_orden.forEach( x => {
                if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
                    subtotal += Number(x.cantidad) * Number(x.precio_unitario);
                }
            })
            if(Number(subtotal) < Number(data.descuento)){
                errors.descuento = "El descuento no debe de ser mayor que el subtotal"
            }
        }
        if(!data.detalle_orden || !data.detalle_orden.length){
            errors.detalle_orden = {_error: 'Debe de ingresar al menos un producto'}
        }else {
            const detalleArray = []
            data.detalle_orden.forEach(function(producto, index){
                let detErrors = {};
                let repetidos = [];
                if(producto.producto){
                    repetidos = _.filter(data.detalle_orden, (x) => {
                        if(x.producto)
                            return  x.producto.id == producto.producto.id
                        return false;
                        });
                }

                if(!producto.producto){
                    detErrors.producto =  "Campo requerido";
                }else {
                    if(1 < repetidos.length){
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                if(!producto.cantidad){
                    detErrors.cantidad = "Campo requerido"
                }else {
                    if(parseFloat(producto.cantidad) <= 0){
                        detErrors.cantidad = "Debe ser mayor a 0"
                    }
                }
                if(!producto.precio_unitario){
                    detErrors.precio_unitario = "Campo requerido";
                }else {
                    if(parseFloat(producto.precio_unitario) <= 0){
                        detErrors.precio_unitario = "Debe ser mayor a 0"
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.detalle_orden = detalleArray
            }
        }
        return errors;
    },
})(OrdenCompraForm);

const selector = formValueSelector('ordencompraForm');
OrdenCompraForm = connect(state => {
    let todos_productos = [];
    let simbolo = "Q. "
    let valor_iva = 0;

    const productos = selector(state, 'detalle_orden');
    const moneda = selector(state, 'moneda');
    const descuento = selector(state, 'descuento');
    const empresa = selector(state, 'empresa');
    const tiene_iva = selector(state, 'tiene_iva');
    const apply_credito = selector(state, 'aply_credito');
    const proveedor = selector(state, 'proveedor');

    if(moneda){
        simbolo = getSimbolo(moneda)
    }
    if(productos){
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    todos_productos.forEach( x => {
        if(!isNaN(x.cantidad) && !isNaN(x.precio_unitario)){
            subtotal += Number(x.cantidad) * Number(x.precio_unitario);
        }
    })

    total = subtotal
    if(descuento){
        total = total - descuento;
    }
    if(apply_credito){
        total = total - proveedor.credito_deuda.saldo
    }
    if(tiene_iva){
        valor_iva = total * 0.12;
        total = total + valor_iva;
    }


    return {
        total,
        simbolo,
        subtotal,
        empresa,
        productos,
        tiene_iva,
        valor_iva
    }
})(OrdenCompraForm);

export default OrdenCompraForm;
