import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardPMEnviadas from '../../../Utils/Cards/cardsPM'


export default class PpendientesGrid extends Component {
    componentDidMount() {
        const { listar, listPM, page } = this.props;
        listar(page, 1);
        listPM(true);
    }
    
    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar } = this.props;
        
        return (
            <CardSimple
                titulo="Pendientes de generar Orden de compra">
                    <CardPMEnviadas 
                    data={this.props.pendientes}
                    loader={loader}
                    />
                    <ToolbarSimple
                        margen={"0"}
                        textBoton="Agregar"
                        ruta="/solicitud/nuevo"
                        buscar={this.props.searchChange2}
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden"}
                    />
                    <br/>
                    <Grid page={this.props.page} hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            dataSort
                            dataFormat={
                                standardActions({ verpro:"peticiones" })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="no_orden"
                            dataSort
                        >
                            NO. ORDEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proceso_estado"
                            dataSort
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
