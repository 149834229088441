import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/vendedores/vendedores';
import VendedoresGrid from './vendedorGrid';

const mstp = state => {
    return {
        ...state.vendedores, 
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(VendedoresGrid)
