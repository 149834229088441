import React, {Component} from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";

import CardSimple from '../../../Utils/Cards/cardSimple';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import MiCaja from './miCaja';
import EgresoForm from './egresoForm';
import RetiroForm from './retiroForm';
import HistorialCaja from './historialCaja';
import EstadoPeriodo from './periodoCaja'

class DetalleCaja extends Component{
    state = {
        open_modal:false,
        open_modal_retiro:false,
        modalHistory:false,
        periodo: null
    }
    handleSubmit = (values) => {
        this.props.movimiento(values, this.props.match.params.id);
        this.setOpenModal(false);
    }
    handleSubmitRetiro = (values) => {
        console.log(values)
        this.props.retiroDeCaja(values, this.props.match.params.id);
        this.setOpenModalRetiro(false);
    }
    setOpenModal = (val)=>{
        this.setState({open_modal:val})
        if (val===true)
            this.props.listar()
    }
    setOpenModalRetiro = (val)=>{
        this.setState({open_modal_retiro:val})
        if (val===true)
            this.props.selectEmpresas()
    }
    setOpenModalHistory = (val, row)=>{
        this.setState({modalHistory:val})
        if (val === true)
            this.props.estadoPeriodo(row.id)
            // this.setState({periodo:row})
    }
    componentDidMount(){
        let id = this.props.match.params.id;
        let {leer, listPCaja, estadoMiCaja } = this.props
        // lee la informaciond el estado de la caja del ultimo periodo
        leer(id);
        //lista los traslados que etan pendientes de confirmar en ambas vias
        listPCaja(id);
        //obtiene el estado de cuenta de la caja en el ultimo periodo
        estadoMiCaja(id);
        this.props.setTitulo(this.props.item 
            ? `${this.props.item.nombre_dueno} ${this.props.item.apellido_dueno}`
            : "n/a" )
    }
    componentDidUpdate(prevProps){
        if (this.props.match.params.id != prevProps.match.params.id){
            let id = this.props.match.params.id;
            let {leer, listPCaja, estadoMiCaja } = this.props
            // lee la informaciond el estado de la caja del ultimo periodo
            leer(id);
            //lista los traslados que etan pendientes de confirmar en ambas vias
            listPCaja(id);
            //obtiene el estado de cuenta de la caja en el ultimo periodo
            estadoMiCaja(id);
        }
    }
    cerrarperiodo = () =>{
        console.log('cierre de caja')
        this.props.cierredecaja(this.props.match.params.id)
        window.location.reload()
    }

    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    render(){
        let {data, item, loader, entidad, empresas } = this.props
        return(
            <CardSimple
                //titulo={item? `caja de: ${item.nombre_dueno} ${item.apellido_dueno}`: "n/a" }
                margen={' '}
                setTitulo = { this.props.setTitulo }
                className="col-12"
            >
                {!!item && this.props.setTitulo(`${this.props.item.nombre_dueno} ${this.props.item.apellido_dueno}`)}
                <Modal open={this.state.open_modal_retiro} onClose={(e) => {
                        this.setOpenModalRetiro(false)
                    }} center showCloseIcon={false}>
                        <div className="col-12 modal-card-header">
                            <span className="col-12 col-lg-10 modal-card-title"> Registrar Retiro </span>
                        </div>
                        <RetiroForm
                            initialValues={{fecha:new Date()}}
                            data={empresas}
                            onSubmit={this.handleSubmitRetiro}
                            setOpenModalRetiro={this.setOpenModalRetiro}
                        />
                </Modal>
                <Modal open={this.state.open_modal} onClose={(e) => {
                        this.setOpenModal(false)
                    }} center showCloseIcon={false}>
                        <div className="col-12 modal-card-header">
                            <span className="col-12 col-lg-10 modal-card-title"> Registrar Transferencia </span>
                        </div>
                        <EgresoForm
                            initialValues={{fecha:new Date(), egreso_tipo: 'false'}}
                            data={data}
                            onSubmit={this.handleSubmit}
                            setOpenModal={this.setOpenModal}
                        />
                </Modal>
                <Modal open={this.state.modalHistory} onClose={(e) => {
                        this.setOpenModalHistory(false)
                    }} center>
                        <div className="col-12 modal-card-header">
                            <span className="col-12 col-lg-10 modal-card-title"> Historial de Movimientos </span>
                        </div>
                        <EstadoPeriodo
                            data={this.props.periodo}
                            loader={loader}
                        />
                </Modal>
                     <div className="col-md-6 pl-0 ml-0 justify-content-end pb-4">
                        <div className="col-md-auto d-flex align-items-center justify-content-end">
                            <span className="t-musgo font-weight-bold"> SALDO INICIAL </span>
                            <h4 className="t-primary m-none">
                                { <RenderMoneda monto={item.saldo_inicial} simbolo={this.getsimbolo(item.moneda)} />}
                            </h4>
                        </div>
                    </div>
                    <div className="col-md-6 pl-0 ml-0 justify-content-start pb-4">
                        <div className="col-md-auto d-flex align-items-center">
                                <span className="t-musgo font-weight-bold">
                                    SALDO {(false? 'FINAL':'ACTUAL')}
                                </span>
                            <h4 className="t-primary m-none">
                                { <RenderMoneda monto={item.saldo_actual} simbolo={this.getsimbolo(item.moneda)} />}
                            </h4>
                        </div>
                </div>
                <br/>
                <Tabs
                    defaultActiveKey="CAJACHICA"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >

                        {/* ACTIVDAD RECIENTE DE CAJA CHICA */}
                    <TabPane tab="Caja" key="CAJA">
                        <MiCaja
                            {...this.props}
                            data={this.props.estado}
                            item={item}
                            role={this.props.role}
                            loader={loader}
                            entidad={entidad}
                            setOpenModal={this.setOpenModal}
                            setOpenModalRetiro={this.setOpenModalRetiro}
                            cerrarperiodo={this.cerrarperiodo}
                            pendientes={this.props.pendientes}
                            AcceptMovimiento={this.props.AcceptMovimiento}
                            RefuseMovimiento={this.props.RefuseMovimiento}
                            CancelMovimiento={this.props.CancelMovimiento}
                            idcaja={this.props.match.params.id}
                        />
                    </TabPane>
                    <TabPane tab="Historial" key="HISTORIAL">
                        {/* HISTORIAL DE CAJA CHICA */}
                        <HistorialCaja
                            historial={this.props.historial}
                            pagehistorial={this.props.pagehistorial}
                            loader={this.props.loader}
                            historialMiCaja={this.props.HistorialMiCaja}
                            caja={this.props.match.params.id}
                            setOpenModalHistory = {this.setOpenModalHistory}
                        />
                    </TabPane>
                </Tabs>
            </CardSimple>
        )
    }
}

export default withRouter(DetalleCaja);
