import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "bandejaNotificaciones",
    "notificaciones",
);

actions.onSortChange = (ordering, order, listarSort) => (dispatch, getStore) => {
    const sort = getStore().bandejaNotificaciones.ordering;
    if (ordering === sort) {
        dispatch(actions.setOrderingNotificaciones(`-${ordering}`));
    } else {
        dispatch(actions.setOrderingNotificaciones(ordering));
    }
    if(listarSort)
        dispatch(actions.listarespecial(listarSort.url, listarSort.page, listarSort.data));
    else
        dispatch(actions.listar());
};

export default handleActions(reducers, initialState);
