import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import DespachoForm from './despachoForm';


export default class DespachoContainer extends Component {
    componentDidMount(){
        let id = this.props.match.params.id
        if (id != undefined && id != null){
            this.props.setProductoDespacho(
                {
                    bodega:this.props.match.params.bodega,
                    orden:this.props.match.params.id
                }
            )
        }else{
            return (<Redirect to="/bodegas" />)
        }
    }
    handleSubmit = (values) => {
        const {DespachoEntreBodega} = this.props
        let id = this.props.match.params.id
        let bodega = this.props.match.params.bodega;
        values.orden = this.props.match.params.id;
        DespachoEntreBodega(values, id);
    }
    render() {
        const {loader, item } = this.props;
        console.log('item ', item);
        return (
            <CardFormulario >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <DespachoForm
                        orden={this.props.match.params.id}
                        bodega={this.props.match.params.id}
                        onSubmit={this.handleSubmit}
                        item={item}
                        initialValues={{tipo_movimiento:30}}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
