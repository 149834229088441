import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import ProductoForm from "./ProductoForm";
import RecetaForm from "./recetaForm";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CardFormulario from "../../../Utils/Cards/CardProducto";
import { BanderaEmpresa } from "../../../../../utility/constants";
import { UnidadMedida } from "../../../../../utility/constants";
import PresentacionForm from './presentacionForm';

const UnidadMedidaSingular = [
    {value: 1, label: "Una Unidad", unidad_medida: 10},
    {value: 5, label: "Un Mililitro", unidad_medida: 20},
    {value: 10, label: "Un Litro", unidad_medida: 20},
    {value: 15, label: "Un Galon", unidad_medida: 20},
    {value: 20, label: "Un Miligramo", unidad_medida: 30},
    {value: 25, label: "Un Gramo", unidad_medida: 30},
    {value: 30, label: "Un Kilogramo", unidad_medida: 30},
    {value: 35, label: "Una Onza", unidad_medida: 30},
    {value: 40, label: "Una Libra", unidad_medida: 30},
    {value: 45, label: "Una Arroba", unidad_medida: 30},
    {value: 50, label: "Un Quintal", unidad_medida: 30},
    {value: 51, label: "Una Tonelada", unidad_medida: 30},
    {value: 55, label: "Un Milímetro", unidad_medida: 40},
    {value: 60, label: "Un Centímetro", unidad_medida: 40},
    {value: 65, label: "Un Metro", unidad_medida: 40},
    {value: 70, label: "Una Pulgada", unidad_medida: 40},
    {value: 75, label: "Un Pie", unidad_medida: 40},
    {value: 80, label: "Un Metro cuadrado", unidad_medida: 50},
    {value: 85, label: "Un Metro cúbico", unidad_medida: 60},
]

class ProductoCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        titulo: "Agregar producto",
        step: 1,
        arr_medidas: [],
        values: {},
    };
    setValores = (id) => {
        let unidades = _.filter(UnidadMedida, (e) => {
            if (e.unidad_medida == id) {
                return e;
            }
        });
        unidades = _.without(unidades, undefined);
        this.setState({ arr_medidas: unidades });
    };
    onChangeTipoUnidad = (id) => {
        this.setValores(id);
    };
    onChangeUnidadInventario = (id) => {
        let label= "";
        UnidadMedidaSingular.forEach((item, index) => {
            if(item.value == id){
                label = item.label
            }
        })
        return label;
    }
    componentWillMount() {
        this.props.selectEmpresas2();
        this.props.selectRegiones();

        if (
            this.props.match.params.id != null &&
            this.props.match.params.id > 0
        ) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
                titulo: "Editar producto",
            });
        }
    }
    nextStep = (values) => {
        this.setState({ step: this.state.step + 1 });
        this.setState({ values: values })
    };

    previousStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    onSubmit = (values) => {
        const { crear, editar } = this.props;
        let id = this.props.match.params.id;
        let valores = _.cloneDeep(values);
        if (valores.recetas) {
            valores.recetas.forEach((x) => {
                x.producto = x.producto.id;
            });
        }
        valores.empresa = `${BanderaEmpresa}`;
        if(valores.presentacion){
            valores.presentacion.forEach((item) => {
                item.producto = valores.id
            })
        }
        this.state.editar ? editar(id, valores) : crear(valores);
    };
    componentWillUnmount() {
        this.props.resetFormulario();
    }

    render() {
        const { loader, empresas, regiones, item } = this.props;
        const { step } = this.state;
        return (
            <CardFormulario step={this.state.step}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {step === 1 && (
                        <ProductoForm
                            item={item}
                            onSubmit={this.nextStep}
                            empresas={empresas}
                            regiones={regiones}
                            setValores={this.setValores}
                            onChangeTipoUnidad={this.onChangeTipoUnidad}
                            arr_medidas={this.state.arr_medidas}
                        />
                    )}
                    {step === 2 && (
                        <RecetaForm
                            item={item}
                            onSubmit={this.nextStep}
                            empresass={empresas}
                            regiones={regiones}
                            previousStep={this.previousStep}
                        />
                    )}
                    {step === 3 && (
                        <PresentacionForm
                            item={item}
                            onSubmit={this.onSubmit}
                            empresass={empresas}
                            regiones={regiones}
                            previousStep={this.previousStep}
                            arr_medidas={this.state.arr_medidas}
                            datos = {this.state.values}
                            //unidad_inventario={this.state.unidad_inventario}
                            onChangeUnidadInventario={this.onChangeUnidadInventario}
                        />
                    )}
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default ProductoCrear;
