import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import Modal from 'react-responsive-modal';

import AgregarFForm from './facturasForm'
import { handleSubmit } from '../../../../../redux/modules/notificaciones/notificaciones';


export default class FacturasGrid extends Component{
    state={
        open_modal:false,
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    handleModal = (value) =>{
        this.setState({open_modal:value})
    }
    handleSubmit = (values) =>{
        values.id =  this.props.OCid
        this.props.BillOC(values);
    }
    handleDelete =(id)=>{
        let values ={
            orden: this.props.OCid,
            id
        }
        this.props.DeleteOC(values);
    }
    render(){
        const { facturas,moneda, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Modal open={this.state.open_modal} onClose={(e)=>{
                    this.setState({open_modal:false})
                }} center >
                    <div className="mt-5">
                        <h2 className="text-center text-primary">DATOS FACTURAS</h2>
                    </div>
                    <AgregarFForm
                        onSubmit={this.handleSubmit}
                        handleModal={this.handleModal}
                        simbolo={this.getsimbolo(moneda)}
                    />
                </Modal>
                <Grid hover striped data={{count:10,results:facturas}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            standardActions({eliminar:this.handleDelete})
                        }
                    > </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nit"
                        dataSort
                    >
                        NIT
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_factura"
                        dataSort
                    >
                        NO DOCUMENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        dataFormat ={
                            (cell, row)=>(
                                <RenderMoneda monto={cell? cell : 0} simbolo={this.getsimbolo(moneda)} />
                            )
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
                <div className="row justify-content-sm-start justify-content-start">
                    <div className="col-12">
                        <button onClick={()=>{this.handleModal(true)}}  className="btn btn-primary m-1 align-self-center">AGREGAR FACTURA</button>
                    </div>
                </div>
            </div>
        )
    }

}