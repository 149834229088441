import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardSimple from '../../../Utils/Cards/cardSimple';

export default class EmpresaGrid extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, permisos } = this.props;
        if(permisos["puestos"] === false) {
            if(!!permisos[`ver_puestos`] === false) {
                return <Redirect to={`/`} />
            }
        } 
        return(
            <CardSimple
                toolbar={
                    <ToolbarSimple 
                        textBoton="Agregar"
                        ruta="/puesto/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Puesto"}
                        permisos={permisos.puestos}
                    />
                }
                margen={'mt-3'}
            >
                <Grid hover striped data={data} loading={loader} page={page} onPageChange={onPageChange} onSortChange={onSortChange} >
                    { permisos.puestos && (<TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="20%"
                        dataSort
                        dataFormat={
                            standardActions({ editar: "puesto", eliminar})
                        }
                    >
                    </TableHeaderColumn>)}
                    <TableHeaderColumn
                        isKey
                        dataField="nombre"
                        width="25%"
                        dataSort
                    >
                        PUESTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="sueldo"
                        dataSort
                        width="20%"
                        dataFormat={
                            (cell, row) => <RenderMoneda monto={cell}/>
                        }
                    >
                        SUELDO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                    >
                        DESCRIPCIÓN
                    </TableHeaderColumn>
                </Grid>

            </CardSimple>

        )
    }
}

