import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/bandeja/notifiacaciones';
import NotificacionesGrid from './NotificacionesGrid';

const mstp = state => {
    return {
        ...state.bandejaNotificaciones,
        me: state.login.me
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(NotificacionesGrid)
