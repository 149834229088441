import React from 'react';
import HeaderSimple from '../Header/HeaderSimple';


function CardFormulario(props) {
    return (
            <div className="Container bg-white shadow-card mt-3 mb-3 d-flex justify-content-center"
            style={{borderRadius: "10px"}}>

                <div className="col-12 ">
                    {
                        (props.titulo) && (
                            <HeaderSimple titulo={props.titulo}/>
                        )
                    }
                    <div className="row mt-3 mx-md-3 ">
                        <div className={`mb-4 ${props.className ? props.className : 'col-12'}`}>
                        {props.children}
                        </div>
                    </div>
                </div>

            </div>
    );
}

export default CardFormulario;
