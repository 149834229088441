import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'REPORTE_COSTOVENTA_LOADER',
    DATA: 'REPORTE_COSTOVENTA_DATA',
    DATA2: 'REPORTE_COSTOVENTA_DATA2',
    PAGE: 'REPORTE_COSTOVENTA_PAGE',
    PAGE2: 'REPORTE_COSTOVENTA_PAGE2',
    SET_FECHA_INICIO: 'SET_FECHA_INICIO_COSTOVENTA',
    SET_FECHA_FIN: 'SET_FECHA_FIN_COSTOVENTA',
    SET_EMPRESA: 'SET_EMPRESA_COSTOVENTA',
    SET_CLIENTE: 'SET_CLIENTE_COSTOVENTA',
    SET_TIPO_P: 'SET_TIPO_P_COSTOVENTA',
    SET_PRODUCTO: 'SET_PRODUCTO_COSTOVENTA',
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page,
});


// ACTIONS

const porTrimestre = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteCostoVenta;
    const params = { page, trimestre: 1 };
    if (resource.fecha_inicio)
        params.fechaI = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.cliente)
        params.cliente = resource.cliente.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.tipo_producto)
        params.tipo = resource.tipo_producto.id;
    if (resource.producto)
        params.producto = resource.producto.id;
    dispatch(setLoader(true));
    api.get(`${url}/costos_vs_ventas`, params).then((response) => {
        dispatch(setData(constants.DATA, response));
        dispatch(setPage(constants.PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const porMeses = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteCostoVenta;
    const params = { page };
    if (resource.fecha_inicio)
        params.fechaI = resource.fecha_inicio.format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fechaF = resource.fecha_fin.format("YYYY-MM-DD");
    if (resource.cliente)
        params.cliente = resource.cliente.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.tipo_producto)
        params.tipo = resource.tipo_producto.id;
    if (resource.producto)
        params.producto = resource.producto.id;
    dispatch(setLoader(true));
    api.get(`${url}/costos_vs_ventas`, params).then((response) => {
        dispatch(setData(constants.DATA2, response));
        dispatch(setPage(constants.PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = (value) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_INICIO, value));
    dispatch(porTrimestre());
    dispatch(porMeses());
};
const changeFechaFin = (value) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_FIN, value));
    // dispatch(porTrimestre());
    dispatch(porMeses());
};

const changeEmpresa = (value) => (dispatch) => {
    dispatch(setData(constants.SET_EMPRESA, value));
    dispatch(porTrimestre());
    dispatch(porMeses());
};

const changeCliente = (value) => (dispatch) => {
    dispatch(setData(constants.SET_CLIENTE, value));
    dispatch(porTrimestre());
    dispatch(porMeses());
};

const changeTipoProducto = (value) => (dispatch) => {
    dispatch(setData(constants.SET_TIPO_P, value));
    dispatch(porTrimestre());
    dispatch(porMeses());
};

const changeProducto = (value) => (dispatch) => {
    dispatch(setData(constants.SET_PRODUCTO, value));
    dispatch(porTrimestre());
    dispatch(porMeses());
};

export const actions = {
    porTrimestre,
    porMeses,
    changeFechaInicio,
    changeFechaFin,
    changeEmpresa,
    changeCliente,
    changeTipoProducto,
    changeProducto,
}

// REDUCERS
export const reducers = {
    [constants.LOADER]:(state, { loader }) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state, { data }) => {
        return{
            ...state,
            data: data
        }
    },
    [constants.DATA2]:(state, { data }) => {
        return{
            ...state,
            data2: data
        }
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [constants.SET_FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data
        };
    },
    [constants.SET_FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data
        };
    },
    [constants.SET_EMPRESA]: (state, { data }) => {
        return {
            ...state,
            empresa: data
        };
    },
    [constants.SET_CLIENTE]: (state, { data }) => {
        return {
            ...state,
            cliente: data
        };
    },
    [constants.SET_TIPO_P]: (state, { data }) => {
        return {
            ...state,
            tipo_producto: data
        };
    },
    [constants.SET_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data
        };
    },
}

export const initialState = {
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    page: 1,
    page2: 1,
    fecha_inicio: moment(`${moment().year()}-1-1`),
    fecha_fin: moment(),
    empresa: null,
    cliente: null,
    producto: null,
    tipo_producto: null,
}

export default handleActions(reducers, initialState);
