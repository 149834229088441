import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/vendedores/vendedores';
import VendedorCrear from './VendedoresCrear';

const mstp = state => {
    return {...state.vendedores}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(VendedorCrear)
