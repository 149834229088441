import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldCheck, renderFieldRadio } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const RolesForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="Rolesform" className="form-validate mb-lg roles" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">
                    <label className="t-primaryColor" htmlFor="role">Nombre del Rol</label>
                    <Field name="nombre" label="role" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                    <Field name="descripcion" label="descripcion" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <br/>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-lg-8 col-md-12  col-12">
                    <h6 className="t-texto" >Permisos de Vistas</h6>
                </div>
            </div>
            <div className="row justify-content-center ">
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">
                    {/* <label className="t-azul" htmlFor="role">Dashboard    .</label> */}
                    <Field name="dashbord" label="Dashboard" component={renderFieldCheck} className="form-control" />
                    {/* <div className="row">
                        <div className="col-6">
                            <Field name="gastos" label="Gastos" component={renderFieldCheck} className="form-control"  />
                        </div>
                        <div className="col-6">
                            <Field name="ver_gastos" label="Ver Gastos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <Field name="bodegas" label="Bodegas" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bodegas" label="Ver Bodegas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <br/>
                    <h6 className="titulo-rol" htmlFor="role">Caja:</h6>
                    {/* <Field name="micaja" label="Mi Caja" component={renderFieldCheck} disabled className="form-control" /> */}
                    <Field name="cajaschicas" label="Cajas Chicas" component={renderFieldCheck} className="form-control" />

                    <br/>

                    <h6 className="titulo-rol" htmlFor="role">Orden de Compra:</h6>
                    <Field name="ordencompra" label="Orden de Compra" component={renderFieldCheck} className="form-control" />
                    <Field name="peticionmateriales" label="Peticion de Materiales" component={renderFieldCheck} className="form-control" />                    

                    <br/>

                    <h6 className="titulo-rol" htmlFor="role">Cuentas:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bancos" label="Bancos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bancos" label="Ver Bancos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <br/>
                    <h6 className="titulo-rol" htmlFor="role">Orden de Venta:</h6>
                    <Field name="ordenventa" label="Orden de Venta" component={renderFieldCheck} className="form-control" />
                    <Field name="peticionventa" label="Peticion de venta" component={renderFieldCheck} className="form-control" />
                    <br/>
                    <h6 className="titulo-rol" htmlFor="role">Planilla:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="puestos" label="Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_puestos" label="Ver Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="bonificaciones" label="Bonificaciones" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_bonificaciones" label="Ver Bonificaciones" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="empleados" label="Empleados" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_empleados" label="Ver Empleados" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="planilla" label="Pago de Planilla" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_planilla" label="Ver Pago de Planilla" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="form-group has-feedback  col-lg-5 col-md-6  col-12">

                    <h6 className="titulo-rol" htmlFor="role">Reportes:</h6>
                    <Field name="bitacora" label="Bitacora" component={renderFieldCheck} className="form-control" />
                    <Field name="deuda" label="Deudas" component={renderFieldCheck} className="form-control" />
                    <Field name="cuentasp" label="Cuentas por Pagar" component={renderFieldCheck} className="form-control" />
                    <Field name="cuentasc" label="Cuentas por Cobrar" component={renderFieldCheck} className="form-control" />
                    <br/>
                    <h6 className="titulo-rol" htmlFor="role">Administrativos:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="usuarios" label="Usuarios" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_usuarios" label="Ver Usuarios" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
{/*                     <div className="row">
                        <div className="col-6">
                            <Field name="empresas" label="Empresas" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_empresas" label="Ver Emrpesas" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <Field name="vendedores" label="Vendedores" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_vendedores" label="Ver Vendedores" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="clientes" label="Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_clientes" label="Ver Clientes" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="proveedores" label="Proveedores" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="vever_proveedoresr_" label="Ver Proveedor" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="productos" label="Productos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_productos" label="Ver Productos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="tproducto" label="Tipo de Producto" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_tproducto" label="Ver Tipos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field name="roles" label="Roles" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_roles" label="Ver Roles" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
{/*                     <div className="row">
                        <div className="col-6">
                            <Field name="regiones" label="Regiones" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_regiones" label="Ver Regiones" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <Field name="categiagastos" label="Categia de Gastos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_categiagastos" label="Ver Categorias" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-6">
                            <Field name="puestos" label="Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_puestos" label="Ver Puestos" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>
                    <h6 className="titulo-rol" htmlFor="role">Retiro:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="retiro_caja" label="Retiro de Caja" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="retiro_banco" label="Retiro de Banco" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>*/}
                    <br/>
                    <h6 className="titulo-rol" htmlFor="role">Producción:</h6>
                    <div className="row">
                        <div className="col-6">
                            <Field name="orden_produccion" label="Orden de Producción" component={renderFieldCheck} className="form-control" />
                        </div>
                        <div className="col-6">
                            <Field name="ver_orden_produccion" label="Ver Producción" component={renderFieldCheck} className="form-control" />
                        </div>
                    </div>


                </div>
            </div>
            {/* <div className="row justify-content-center ">
                <div className="form-group has-feedback  col-lg-4 col-md-6  col-12">
                </div>
            </div> */}
            <div className="row justify-content-center ">
                <div className="form-group has-feedback col-lg-8 col-12">
                    <h6 className="t-texto" htmlFor="role">Notificaciones:</h6>
                </div>
                <div className="row col-lg-8 col-12 m-0 p-0">
                    <div className="form-group has-feedback col-md-6 col-sm-6 col-12">
                        <h6 className="titulo-rol" htmlFor="role">Orden venta:</h6>
                        <Field name="pv_creada" label="Peticion de venta creada" component={renderFieldCheck} className="form-control" />

                        <h6 className="titulo-rol" htmlFor="role">Produccion:</h6>
                        <Field name="op_creada" label="Orden produccion creada" component={renderFieldCheck} className="form-control" />
                        <Field name="op_finalizada" label="Orden produccion finalizada" component={renderFieldCheck} className="form-control" />

                    </div>
                    <div className="form-group has-feedback col-md-6 col-sm-6 col-12">
                        <h6 className="titulo-rol" htmlFor="role">Orden compra:</h6>
                        <Field name="sm_creada" label="Solicitud Materiales creada" component={renderFieldCheck} className="form-control" />
                        <Field name="sm_autorizada" label="Solicitud Materiales autorizada" component={renderFieldCheck} className="form-control" />
                        <Field name="oc_creada" label="Orden Compra creada" component={renderFieldCheck} className="form-control" />

                        <h6 className="titulo-rol" htmlFor="role">Inventario:</h6>
                        <Field name="ds_creado" label="Despacho  creada" component={renderFieldCheck} className="form-control" />
                        <Field name="ing_oc" label="Ingreso por OC" component={renderFieldCheck} className="form-control" />
                        <Field name="low_inventario" label="Producto bajo inventario" component={renderFieldCheck} className="form-control" />
                    </div>
                </div>
            </div>

            <div className="row  justify-content-sm-center justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to="/roles" >Cancelar</Link>
                    <button type="submit" className="btn btn-azul-claro m-1 ml-4 align-self-center">Guardar</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'RolesForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            role: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
        });
    },
})(RolesForm);
