import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import {BanderaEmpresa} from '../../../utility/constants';
import moment from 'moment';

let url = 'cuentas' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'D_LOADER',
    DATA: 'D_DATA',
    ITEM: `D_ITEM`,
    PAGE: `D_PAGE`,
    ORDERING: `D_ORDERING`,
    SEARCH: `D_SEARCH`,
    REPORTE_DEUDA: `REPORTE_DEUDA`,
    PORPAGAR: `PORPAGAR`,
    PORCOBRAR: `PORCOBRAR`,
    REPORTE_DEUDA_CoP: 'REPORTE_DEUDA_CoP',
    SET_ID_CLIPRO: 'SET_ID_CLIPRO',
    SET_NOMBRE_EMPRESA: 'SET_NOMBRE_EMPRESA',
    SET_MONEDA_CUENTA: 'SET_MONEDA_CUENTA',
    FECHA_INICIO: 'FECHA_INICIO_FLINV',
    FECHA_FIN: 'FECHA_FIN_FLINV',
    TOTALIZADOS: 'TOTALIZADOS',
    V_BUSQUEDA: 'V_BUSQUEDA',
    ESCLIENTE: 'ESCLIENTE',
    ASYNCVALUE:'ASYNCVALUE',
    OPEN_MODAL: 'OPEN_MODAL',
    SET_CUENTAS: 'SET_CUENTAS_DEUDA',
    OPEN_MODAL_PAGOS: 'OPEN_MODAL_PAGOS',
    OPEN_MODAL_REINTEGROS: 'OPEN_MODAL_REINTEGROS',
    EMPRESAS: 'EMPRESAS',
    TIPO_GASTOS: 'TIPO_GASTO',
    SET_CLIENTE: 'SET_CLIENTE',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().Reportes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createpv`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((error) => {
        let mensaje = 'Error en la creación de Peticion de Materiales';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D")
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format("YYYY-MM-D")
    api.put(`${url}/${id}/updatepv`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        dispatch(listPv(""));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().Reportes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const DeudaEmpresas = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const params = {};
    api.get(`reportes/reporteEmpresas`, params).then((response) => {
        dispatch({type: constants.REPORTE_DEUDA, data: response});
        dispatch(CalculoTotalDeudas())
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const CalculoTotalDeudas = () =>(dispatch,getStore) =>{
    const resource = getStore().Reportes;
    let cuentaPorPagar = 0
    let cuentaPorCobrar = 0
    resource.deudasEmpresas.forEach(element => {
        if (element.ultimo_afectado.saldo >=0 ){
            cuentaPorCobrar = cuentaPorCobrar + element.ultimo_afectado.saldo
        }
        else{
            cuentaPorPagar = cuentaPorPagar + element.ultimo_afectado.saldo
        }
    });
    dispatch({type: constants.PORCOBRAR, data: cuentaPorCobrar});
    dispatch({type: constants.PORPAGAR, data: cuentaPorPagar});
}

const cuentasporCOP = (values, clipro, page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true))
    console.log("Page ", page);
    const params = {page ,clipro};
    (values.estado && values.estado !== undefined ) ?  (params.estado = values.estado): (params.estado=1)
    values.clienteproveedor && values.clienteproveedor !== null &&  (params.clienteproveedor = values.clienteproveedor)
    values.vendedor && values.vendedor !== null && (params.vendedor = values.vendedor)

    params.moneda = values.moneda ? values.moneda : "GTQ"
    params.tipo_cambio_dolar = values.tipo_cambio_dolar ? values.tipo_cambio_dolar: 0.13
    params.tipo_cambio_euro = values.tipo_cambio_euro ? values.tipo_cambio_euro : 0.12

    api.get(`reportes/reporteClientesProveedores`, params).then((response) => {
        // totalizados titulos generales
        let total_vigente = 0;
        let total_porvencer = 0;
        let total_vencido = 0;
        let total = 0;
        let total_saldo_pendiente = 0;
    
        console.log("Response: ", response);
    
        const updatedResults = response.results.map(item => {
            const totalItem = item.saldo_vigencia.vigencias.vencidas + item.saldo_vigencia.vigencias.porvencer + item.saldo_vigencia.vigencias.vigentes;
            const saldo1 = item.saldo_vigencia.total - item.saldo_vigencia.pendiente;
            
            total_vigente += item.saldo_vigencia.vigencias.vigentes;
            total_porvencer += item.saldo_vigencia.vigencias.porvencer;
            total_vencido += item.saldo_vigencia.vigencias.vencidas;
            total += totalItem;
            total_saldo_pendiente += saldo1;
    
            console.log("total: ", item.saldo_vigencia.total);
            console.log("pendiente: ", item.saldo_vigencia.pendiente);
            console.log("saldo: ", saldo1);
            console.log("Total: ", total);
    
            return {
                ...item,
                saldo: saldo1
            };
        });
    
        dispatch({type: constants.TOTALIZADOS, totalizado: {
            total_vigente,
            total_porvencer,
            total_vencido,
            total,
            total_saldo_pendiente,
        }});
    
        dispatch({type: constants.V_BUSQUEDA, variables_busqueda: values});
        dispatch({type: constants.ESCLIENTE, clipro: clipro});
        dispatch({type: constants.ASYNCVALUE, asyncValue: {id: response.results[0].id_clipro, nombre: response.results[0].nombre_origen}});
        dispatch({type: constants.REPORTE_DEUDA_CoP, data: {results: updatedResults}});
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
    
}

const listarDetalleDeudaEmpresas = (page = 1, cuenta) => (dispatch, getStore) => {
    const resource = getStore().Reportes;
    const params = { page , cuenta};
    let dateStart = resource.dateStart;
    let dateEnd = resource.dateEnd;

    if(dateStart){
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if(dateEnd) {
        try{
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch(error){
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }

    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`reportes/balanceEmpresa`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setData(constants.SET_MONEDA_CUENTA , response.moneda));
        dispatch(setData(constants.SET_NOMBRE_EMPRESA , response.nombre_cuenta));

        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const listarDetalleDeudaClienteProveedor = (page = 1, cuenta) => (dispatch, getStore) => {
    const resource = getStore().Reportes;
    const params = { page , cuenta};
    let dateStart = resource.dateStart;
    let dateEnd = resource.dateEnd;

    if(dateStart){
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if(dateEnd) {
        try{
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch(error){
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }

    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`reportes/balanceClienteProveedor`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setData(constants.SET_ID_CLIPRO , response.idCliPro));
        dispatch(setData(constants.SET_NOMBRE_EMPRESA , response.nombre_cuenta));
        dispatch(setData(constants.SET_MONEDA_CUENTA , response.moneda));

        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const anularTransaccion = (data, id) => (dispatch, getStore) => {
    const store = getStore().Reportes
    const page = store.page;
    dispatch(setLoader(true));
    api.post(`periodos/anularTransaccion`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(listarDetalleDeudaClienteProveedor(page,id));
        dispatch(setModal(false))

    }).catch((error) => {
        let mensaje = 'Error en la anulación';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setDateStart = (date, listarFunc, cuenta) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_INICIO, dateStart: date});
    if(listarFunc == 1){
        dispatch(listarDetalleDeudaClienteProveedor(1, cuenta ));
    }else{
        dispatch(listarDetalleDeudaEmpresas(1, cuenta ));
    }
}
const setDateEnd = (date, listarFunc, cuenta) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_FIN, dateEnd: date});
    if(listarFunc === 1){
        dispatch(listarDetalleDeudaClienteProveedor(1, cuenta ));
    }else{
        dispatch(listarDetalleDeudaEmpresas(1, cuenta ));
    }
}

const setModal = (modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        data: modal
    });
}
const setModalPagos = (modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL_PAGOS,
        data: modal
    });
}
const setModalReintegros = (modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL_REINTEGROS,
        data: modal
    });
}
const selectCuentas = () => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    let data = {solo_principal: true}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch(setData(constants.SET_CUENTAS, res))
        const formPago = getStore().form.PeticionVentaForm;

    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const abonarGastoOPago = (id, es_pago=true) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.PagoCreditoForm.values);
    formData.cuenta = id
    formData.es_pago = es_pago
    formData.fecha = moment(formData.fecha).format('YYYY-MM-DD')
    if(formData.poss){
        formData.poss = formData.poss.id;
    }
    dispatch(setLoader(true));
    api.post(`periodos/agregarGastoOPago`, formData).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listarDetalleDeudaClienteProveedor(1, id))
    }).catch((error) => {
        let mensaje = 'Error en el abono de pago';
        console.log(error)
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                try {
                    mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
                } catch (error) {
                    mensaje = "Error al agregar bono";
                }
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
        //dispatch(leerOrdenCompra(id));
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setModalPagos(false));
    });
 }

 const abonarEfectivoCheque = (id, es_pago=true, id_transaccion) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.ConvertirChequeForm.values);
    formData.cuenta = id
    formData.es_pago = es_pago
    formData.fecha = moment(formData.fecha).format('YYYY-MM-DD')
    formData.descripcion = formData.descripcion + `, este efectivo fue cobrado de un cheque en el banco:  ${formData.banco_referencia}`
    console.log('formData', formData)

    const data = {descripcionAnulacion: "Este cheque fue cobrado a efectivo", id: id_transaccion}


    dispatch(setLoader(true));
    api.post(`periodos/agregarGastoOPago`, formData).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(anularTransaccion(data, id))
    }).catch((error) => {
        let mensaje = 'Error en el abono de pago';
        console.log(error)
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                try {
                    mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
                } catch (error) {
                    mensaje = "Error al agregar bono";
                }
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
        //dispatch(leerOrdenCompra(id));
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setModalPagos(false));
    });
 }

 const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const cambioDescuento = (valor, campo, index, vista) => (dispatch, getStore) => {
    const {  values: { desglose }, values } = _.cloneDeep(vista==undefined ? getStore().form.GastoForm:getStore().form[vista]);
    if (desglose != undefined){
        if (desglose.length > 0){
            const fraccion = desglose[index]

            let monto = 0
            if(values.monto){
                monto = values.monto
            }

            if(campo === 'precio'){
                if (valor){
                    fraccion.porcentaje = ((valor / parseFloat(monto)) * 100).toFixed(2);
                }
                else{
                    fraccion.porcentaje = 0;
                }
            }
            else if(campo=== 'monto'){
                if(valor){
                    if(fraccion.porcentaje){
                        fraccion.subtotal = ((fraccion.porcentaje / 100) * parseFloat(valor)).toFixed(2)
                    }
                }
            }
            else{
                if (valor){
                    fraccion.subtotal = ((valor / 100) * parseFloat(monto)).toFixed(2);
                }
                else{
                    fraccion.porcentaje = 0;
                }

            }
            dispatch(initializeForm(vista == undefined ? 'GastoForm' : vista, {...values}))
        }
    }
}

const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const reintegroCliente = (id, es_pago=true) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.ReintegroForm.values);
    formData.cuenta = id
    formData.es_pago = es_pago
    formData.fecha = moment(formData.fecha).format('YYYY-MM-DD')
    if(formData.poss){
        formData.poss = formData.poss.id;
    }
    formData.desglose= [{
        empresa: `${BanderaEmpresa}`, porcentaje: "100.00", subtotal: `${parseFloat(formData.monto)}`
    }]
    dispatch(setLoader(true));
    api.post(`periodos/agregarReintegro`, formData).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(listarDetalleDeudaClienteProveedor(1, id))
        dispatch(setModalReintegros(false))
    }).catch((error) => {
        let mensaje = 'Error en el Reintegro';
        console.log(error)
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                try {
                    mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
                } catch (error) {
                    mensaje = "Error en el Reintegro";
                }
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
        //dispatch(leerOrdenCompra(id));
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setModalPagos(false));
    });
}

const changeCliente = (value) => (dispatch) => {
    dispatch({
        type: constants.SET_CLIENTE,
        cliente: value
    })
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    clear,
    DeudaEmpresas,
    CalculoTotalDeudas,
    cuentasporCOP,
    listarDetalleDeudaEmpresas,
    listarDetalleDeudaClienteProveedor,
    setDateStart,
    setDateEnd,
    setModal,
    anularTransaccion,
    setModalPagos,
    selectCuentas,
    abonarGastoOPago,
    setModalReintegros,
    selectEmpresas,
    cambioDescuento,
    getTipoGastos,
    reintegroCliente,
    changeCliente,
    abonarEfectivoCheque,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.REPORTE_DEUDA]: (state, { data }) => {
        return {
            ...state,
            deudasEmpresas: data,
        };
    },
    [constants.PORCOBRAR]: (state, { data }) => {
        return {
            ...state,
            porCobrar: data,
        };
    },
    [constants.PORPAGAR]: (state, { data }) => {
        return {
            ...state,
            porPagar: data,
        };
    },
    [constants.REPORTE_DEUDA_CoP]: (state, { data }) => {
        return {
            ...state,
            deudasCoP: data,
        };
    },
    [constants.SET_ID_CLIPRO]: (state, { data }) => {
        return {
            ...state,
            idCliPro: data,
        };
    },
    [constants.SET_NOMBRE_EMPRESA]: (state, { data }) => {
        return {
            ...state,
            nombre_empresa: data,
        };
    },
    [constants.SET_MONEDA_CUENTA]: (state, { data }) => {
        return {
            ...state,
            moneda: data,
        };
    },
    [constants.FECHA_INICIO]: (state, {dateStart}) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.FECHA_FIN]: (state, {dateEnd}) => {
        return {
            ...state,
            dateEnd
        }
    },
    [constants.TOTALIZADOS]: (state, {totalizado}) => {
        return {
            ...state,
            totalizado
        }
    },
    [constants.V_BUSQUEDA]: (state, {variables_busqueda}) => {
        return {
            ...state,
            variables_busqueda
        }
    },
    [constants.ESCLIENTE]: (state, {clipro}) => {
        return {
            ...state,
            clipro
        }
    },
    [constants.ASYNCVALUE]: (state, {asyncValue}) => {
        return {
            ...state,
            asyncValue
        }
    },
    [constants.OPEN_MODAL]: (state, {data}) => {
        return {
            ...state,
            open_modal: data
        }
    },
    [constants.OPEN_MODAL_PAGOS]: (state, {data}) => {
        return {
            ...state,
            open_modal_pagos: data
        }
    },
    [constants.SET_CUENTAS]: (state, {data}) => {
        return {
            ...state,
            cuentas: data.cuenta
        }
    },
    [constants.OPEN_MODAL_REINTEGROS]: (state, {data}) => {
        return {
            ...state,
            open_modal_reintegros: data
        }
    },
    [constants.EMPRESAS]: (state, {empresas}) => {
        return {
            ...state,
            empresas
        }
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.SET_CLIENTE]: (state, {cliente}) => {
        return {
            ...state,
            cliente
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    idCliPro: null,
    nombre_empresa:'AYG',
    moneda:'GTQ',
    item: {},
    page: 1,
    ordering: '',
    search: '',
    deudasEmpresas:[],
    porPagar:0,
    porCobrar:0,
    deudasCoP:{
        results: [],
        count: 0,
    },
    dateStart: moment().subtract(30, 'days'),
    dateEnd: moment(),
    totalizado:{},
    variables_busqueda:{},
    clipro:'true',
    asyncValue: null,
    open_modal: false,
    cuentas: [],
    open_modal_pagos: false,
    open_modal_reintegros: false,
    empresas: [],
    tipo_gastos: [],
    cliente: {},
};

export default handleActions(reducers, initialState);
