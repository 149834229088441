import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/cuentaBancos/cuentas';
import CuentasGrid from './CuentasGrid';

const mstp = state => {
    return {...state.cuentas, permisos: state.login.me.permisos,}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CuentasGrid)
