import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
// formulario
import IngresoForm from "./ingresoForm";

export default class IngresoContainer extends Component {
    componentDidMount() {
        let id = this.props.match.params.id;
        if (id != undefined && id != null) {
            this.props.setProductoDespacho({
                bodega: this.props.match.params.bodega,
                orden: this.props.match.params.id,
            });
        } else {
            return <Redirect to="/bodegas" />;
        }
    }
    handleSubmit = (values) => {
        const { ingresoProductosBodega } = this.props;
        let bodega = this.props.match.params.bodega;
        for (let i = 0; i < values.detalle_orden.length; i++) {
            if (
                (values.detalle_orden[i] &&
                    values.detalle_orden[i].cant_conversion <= 0) ||
                values.detalle_orden[i].cant_despacho <= 0
            ) {
                values.detalle_orden.splice(i, 1);
            }
        }
        let valores = {
            Despachos: values.detalle_orden,
            orden: values.orden.id,
        };
        valores.tipo_movimiento = 10; // 10 se refiere a ingreso de materia prima.
        ingresoProductosBodega(valores, bodega);
    };
    render() {
        const { loader, item } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <IngresoForm
                        orden={this.props.orden}
                        bodega={this.props.match.params.bodega}
                        onSubmit={this.handleSubmit}
                        item={item}
                        itemOC={this.props.itemOC}
                        getProductosOC={this.props.getProductosOC}
                        initialValues={{ tipo_movimiento: 30 }}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}
