import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/OrdenVenta/ordenventa';
import NuevaFactura from './facturas';

const mstp = state => {
    return {...state.ordenventa}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(NuevaFactura)
