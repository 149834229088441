import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'PAGO_FLOTANTE_LOADER',
    DATA: 'PAGO_FLOTANTE_DATA',
    ITEM: `PAGO_FLOTANTE_ITEM`,
    PAGE: `PAGO_FLOTANTE_PAGE`,
    ORDERING: `PAGO_FLOTANTE_ORDERING`,
    SEARCH: `PAGO_FLOTANTE_SEARCH`,
    SEARCH_FLONTANTE: 'SEARCH_FLOTANTE',
    SEARCH_TARJETA: 'SEARCH_TARJETA_FLOTANTE',
    SEARCH_DEPOSITO: 'SEARCH_DEPOSITO',
    REPORTE_CHEQUES: `REPORTE_CHEQUES`,
    PORPAGAR: `PORPAGAR_PAGO_FLOTANTE`,
    PORCOBRAR: `PORCOBRAR_PAGO_FLOTANTE`,
    REPORTE_TARJETAS: 'REPORTE_TARJETAS',
    REPORTE_DEPOSITO: 'REPORTE_DEPOSITO',
    REPORTE_TRANSACCION: 'REPORTE_TRANSACCION',
    CUENTA_CHEQUE_FLOTANTE: 'R_CUENTA_FLOTANTE',
    CUENTA_TARJETA_FLOTANTE: 'R_CUENTA_TARJETA_FLOTANTE',
    CUENTA_INVERSO_FLOTANTE: 'CUENTA_INVERSO_FLOTANTE',
    CUENTA_DEPOSITO_FLOTANTE: 'CUENTA_DEPOSITO_FLOTANTE',
    CUENTA_TRANSACCION_FLOTANTE: 'CUENTA_TRANSACCION_FLOTANTE',
    CUENTA_DEPOSITO: 'CUENTA_DEPOSITO',
    FL_INVERSO_REPORTE: 'FL_INVERSO_REPORTE',
    SET_CUENTAS: `R_FLOTANTE_CUENTAS`,
    OPEN_MODAL: 'OPEN_MODAL_FLOTANTE',
    OPEN_MODAL_TARJETA: 'OPEN_MODAL_TARJETA',
    OPEN_MODAL_DEPOSITO: 'OPEN_MODAL_DEPOSITO',
    OPEN_MODAL_TRANSACCION: 'OPEN_MODAL_TRANSACCION',
    SET_FILTRO_POSS: 'SET_FILTRO_POSS',
    FILTRO_CHEQUE: 'FILTRO_CHEQUE_FLOTANTE',
    TARJETAS_SELECCIONADAS: 'TARJETAS_SELECCIONADAS',
    MONTO_TARJETA_SELECCIONADA: 'MONTO_TARJETA_SELECCIONADA'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setSearchTarjeta = search => ({
    type: constants.SEARCH_TARJETA,
    search_tarjeta: search
})

const setSearchFlotante = search => ({
    type: constants.SEARCH_FLONTANTE,
    search_flontante: search
})
const setSearchDeposito = search => ({
    type: constants.SEARCH_DEPOSITO,
    search_deposito: search
})


const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(reporteChequeFlotante());
};

const searchTarjetaChange = search => (dispatch) => {
    dispatch(setSearchTarjeta(search));
    dispatch(reporteTarjetaFlotante())
}
const searchFlotanteChange = search => (dispatch) => {
    dispatch(setSearchFlotante(search))
    dispatch(reporteFlotanteInverso())
}
const searchDepositoChange = search => (dispatch) => {
    dispatch({
        type: constants.SEARCH_DEPOSITO,
        data: search
    });
    dispatch(reporteDepositoFlotante());
}
const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().Reportes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const reporteChequeFlotante = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore().r_pago_flotante;
    const search= store.search;

    const params = {search};

    if(store.cuenta_cheque_flotante){
        params['cuenta'] = store.cuenta_cheque_flotante
    }
    if(store.filtro_cheque){
        if(store.filtro_cheque == 'Ingreso'){
            params['es_ingreso'] = true;
        }else {
            params['es_ingreso'] = false;
        }
    }
    api.get(`reportes/reporteFlotanteCheque`, params).then((response) => {
        dispatch({type: constants.REPORTE_CHEQUES, data: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}



const reporteTarjetaFlotante = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore().r_pago_flotante;
    const search = store.search_tarjeta;
    const params = {search};
    if(store.filtro_poss){
        params['filtro_poss'] = store.filtro_poss.id
    }
    api.get(`reportes/reporteFlotanteTarjeta`, params).then((response) => {
        dispatch({type: constants.REPORTE_TARJETAS, data: response});

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const reporteFlotanteInverso = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore().r_pago_flotante;
    const search = store.search_flontante;
    const params = {search};
    if(store.cuenta_inverso_flotane){
        params['cuenta'] = store.cuenta_inverso_flotane;
    }
    api.get(`reportes/reporteFlotanteInverso`, params).then((response) => {
        dispatch({type: constants.FL_INVERSO_REPORTE, data: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const reporteDepositoFlotante = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore().r_pago_flotante;
    const search = store.search_deposito;
    const params = { search };

    if(store.cuenta_deposito){
        params['cuenta'] = store.cuenta_deposito
    }
    api.get(`reportes/reporteDepositoFlotante`, params).then((response) => {
        dispatch({type: constants.REPORTE_DEPOSITO, data: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const reporteTransaccionFlotante = () => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore().r_pago_flotante;

    const params = {};
    if(store.cuenta_transaccion_flotante){
        params['cuenta'] = store.cuenta_transaccion_flotante
    }
    api.get(`reportes/reporteTransaccionFlotante`, params).then((response) => {
        dispatch({type: constants.REPORTE_TRANSACCION, data: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const setCuentaCheque = (val) => (dispatch, getStore) => {
    dispatch({
        type: constants.CUENTA_CHEQUE_FLOTANTE,
        data: val
    })
    dispatch(reporteChequeFlotante())
}
const setCuentaTarjeta = (val) => (dispatch, getStore) => {
    dispatch({
        type: constants.CUENTA_TARJETA_FLOTANTE,
        data: val
    })
}

const setCuentaInverso = (val) => (dispatch, getStore) => {
    dispatch({
        type: constants.CUENTA_INVERSO_FLOTANTE,
        data:val
    });
    dispatch(reporteFlotanteInverso());
}
const setCuentaDeposito = (val) => (dispatch, getStore) => {
    dispatch({
        type: constants.CUENTA_DEPOSITO_FLOTANTE,
        data:val
    });
    dispatch(reporteFlotanteInverso());
}
const setCuentaTransaccion = (val) => (dispatch, getStore) => {
    dispatch({
        type: constants.CUENTA_TRANSACCION_FLOTANTE,
        data:val
    });
    dispatch(reporteFlotanteInverso());
}


const selectCuentas = (id, moneda) => (dispatch) =>{
    dispatch(setLoader(true));
    let data = { moneda}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch({type: constants.SET_CUENTAS, data: res})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setOpenModal = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}

const setOpenModalTarjeta = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL_TARJETA,
        open_modal_tarjeta: open_modal
    })
}

const setOpenModalDeposito = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL_DEPOSITO,
        open_modal_deposito: open_modal
    })
}
const setOpenModalTransaccion = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL_TRANSACCION,
        open_modal_transaccion: open_modal
    })
}
const setFiltroPoss = filtro => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_FILTRO_POSS,
        filtro_poss: filtro
    });
    dispatch(reporteTarjetaFlotante())

}
const setFiltroCheque = filtro => (dispatch, getStore) => {
    dispatch({
        type: constants.FILTRO_CHEQUE,
        data: filtro
    });
    dispatch(reporteChequeFlotante())
}
const setTarjetaSeleccionados = data => (dispatch, getStore) => {
    dispatch({
        type: constants.TARJETAS_SELECCIONADAS,
        data: data
    });
    let sumatoria = 0;
    _.forEach(data, x => {
        sumatoria += x.transaccion.monto;
    });
    dispatch({
        type: constants.MONTO_TARJETA_SELECCIONADA,
        data: sumatoria
    });
}

const flotanteChequePagado = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));

    api.post(`periodos/flotanteChequePagado`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(reporteChequeFlotante())
        dispatch(setOpenModal(false));
    }).catch((error) => {
        let mensaje = "Error en marcar cheque cobrado";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};




const marcarComoTarjetaPagado = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().r_pago_flotante;
    const poss = store.filtro_poss;
    let tarjetas_seleccionadas = _.cloneDeep(store.tarjetas_seleccionados);
    tarjetas_seleccionadas = tarjetas_seleccionadas.map(x => x.transaccion.id);

    let values = _.cloneDeep(data);

    if(!values.nuevo){
        values = {
            id_trans: values.id,
            nuevo: false,
        };
    }
    values.poss = poss.id
    values.tarjeta_seleccionados = tarjetas_seleccionadas;
    api.post(`periodos/flotanteTarjetaPagado`, values).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/reporte_pago_flotante'));
    }).catch((error) => {
        let mensaje = "Error al marcar el cheque como cobrado";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const marcarComoChequePagado = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().r_pago_flotante;
    const poss = store.filtro_poss;
    let tarjetas_seleccionadas = _.cloneDeep(store.tarjetas_seleccionados);
    tarjetas_seleccionadas = tarjetas_seleccionadas.map(x => x.transaccion.id);

    let values = _.cloneDeep(data);

    if(!values.nuevo){
        values = {
            id_trans: values.id,
            nuevo: false,
        };
    }
    values.tarjeta_seleccionados = tarjetas_seleccionadas;
    api.post(`periodos/flotanteTarjetaPagado`, values).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/reporte_pago_flotante'));
    }).catch((error) => {
        let mensaje = "Error al marcar el cheque como cobrado";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getPoss = (search) => (dispatch, getStore) => {
    return api.get("poss", { search, esCliente:true }).
    then((data) => {
        if (data){
            if(data.results.length){
                dispatch({
                    type: constants.SET_FILTRO_POSS,
                    filtro_poss: data.results[0]
                });
                dispatch(reporteTarjetaFlotante())
            }

            return data.results;
        }
        return [];
    }).catch(() => {
        return [];
    });
};


export const actions = {
    searchChange,
    searchFlotanteChange,
    searchTarjetaChange,
    searchDepositoChange,
    onSortChange,
    clear,
    setCuentaCheque,
    setCuentaTarjeta,
    setCuentaInverso,
    setCuentaDeposito,
    setCuentaTransaccion,
    selectCuentas,
    reporteChequeFlotante,
    reporteTarjetaFlotante,
    reporteDepositoFlotante,
    reporteTransaccionFlotante,
    setOpenModal,
    setOpenModalDeposito,
    setOpenModalTransaccion,
    flotanteChequePagado,
    setFiltroPoss,
    getPoss,
    setOpenModalTarjeta,
    marcarComoTarjetaPagado,
    reporteFlotanteInverso,
    setFiltroCheque,
    setTarjetaSeleccionados,
    marcarComoChequePagado
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SEARCH_FLONTANTE]: (state, { search_flontante }) => {
        return {
            ...state,
            search_flontante,
        };
    },
    [constants.SEARCH_TARJETA]: (state, { search_tarjeta }) => {
        return {
            ...state,
            search_tarjeta,
        };
    },
    [constants.REPORTE_CHEQUES]: (state, { data }) => {
        return {
            ...state,
            reporte_cheques: data,
        };
    },
    [constants.PORCOBRAR]: (state, { data }) => {
        return {
            ...state,
            porCobrar: data,
        };
    },
    [constants.PORPAGAR]: (state, { data }) => {
        return {
            ...state,
            porPagar: data,
        };
    },
    [constants.REPORTE_TARJETAS]: (state, { data }) => {
        return {
            ...state,
            reporte_tarjetas: data,
        };
    },
    [constants.REPORTE_DEPOSITO]: (state, { data }) => {
        return {
            ...state,
            reporte_deposito: data,
        };
    },
    [constants.REPORTE_TRANSACCION]: (state, { data }) => {
        return {
            ...state,
            reporte_transaccion: data,
        };
    },
    [constants.CUENTA_CHEQUE_FLOTANTE]: (state, { data }) => {
        return {
            ...state,
            cuenta_cheque_flotante: data,
        };
    },
    [constants.CUENTA_TARJETA_FLOTANTE]: (state, { data }) => {
        return {
            ...state,
            cuenta_tarjeta_flotante: data,
        };
    },
    [constants.CUENTA_DEPOSITO_FLOTANTE]: (state, { data }) => {
        return {
            ...state,
            cuenta_deposito_flotante: data,
        };
    },
    [constants.CUENTA_TRANSACCION_FLOTANTE]: (state, { data }) => {
        return {
            ...state,
            cuenta_transaccion_flotante: data,
        };
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas_banco: data.cuenta,
        }
    },
    [constants.OPEN_MODAL]: (state, {open_modal}) => {
        return {
            ...state,
            open_modal
        }
    },
    [constants.OPEN_MODAL_TARJETA]: (state, {open_modal_tarjeta}) => {
        return {
            ...state,
            open_modal_tarjeta
        }
    },
    [constants.OPEN_MODAL_DEPOSITO]: (state, {open_modal_deposito}) => {
        return {
            ...state,
            open_modal_deposito
        }
    },
    [constants.OPEN_MODAL_TRANSACCION]: (state, {open_modal_transaccion}) => {
        return {
            ...state,
            open_modal_transaccion
        }
    },
    [constants.SET_FILTRO_POSS]: (state, {filtro_poss}) => {
        return {
            ...state,
            filtro_poss
        }
    },
    [constants.FL_INVERSO_REPORTE]: (state, {data}) => {
        return {
            ...state,
            reporte_fl_inverso: data
        }
    },
    [constants.CUENTA_INVERSO_FLOTANTE]: (state, {data}) => {
        return {
            ...state,
            cuenta_inverso_flotane: data
        }
    },
    [constants.FILTRO_CHEQUE]: (state, {data}) => {
        return {
            ...state,
            filtro_cheque: data
        }
    },
    [constants.TARJETAS_SELECCIONADAS]: (state, {data}) => {
        return {
            ...state,
            tarjetas_seleccionados: data
        }
    },
    [constants.MONTO_TARJETA_SELECCIONADA]: (state, {data}) => {
        return {
            ...state,
            monto_tarjeta_seleccionada: data
        }
    },
    [constants.CUENTA_DEPOSITO]: (state, {data}) => {
        return {
            ...state,
            cuenta_deposito: data
        }
    },
    [constants.SEARCH_DEPOSITO]: (state, {data}) => {
        return {
            ...state,
            search_deposito: data
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    search_flontante: '',
    search_tarjeta: '',
    search_deposito: '',
    reporte_cheques:{
        movimientos: [],
        total_cobrar: 0
    },
    porPagar:0,
    porCobrar:0,
    reporte_tarjetas:{
        movimientos: [],
        total_cobrar: 0
    },
    reporte_fl_inverso: {
        movimientos: [],
        total_cobrar: 0
    },
    reporte_deposito: {
        movimientos: [],
        total_cobrar: 0
    },
    reporte_transaccion: {
        movimientos: [],
        total_cobrar: 0
    },
    cuenta_cheque_flotante: null,
    cuenta_tarjeta_flotante: null,
    cuenta_inverso_flotane: null,
    cuenta_transaccion_flotante: null,
    cuenta_deposito_flotante: null,
    cuenta_deposito: null,
    cuentas_banco: [],
    open_modal: false,
    open_modal_tarjeta: false,
    open_modal_deposito: false,
    open_modal_transaccion: false,
    filtro_poss: null,
    filtro_cheque: null,
    tarjetas_seleccionados: null,
    monto_tarjeta_seleccionada: 0
};

export default handleActions(reducers, initialState);
