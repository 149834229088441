import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset as resetForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'gastos' ;
let form = 'GastoForm';
let dirGrid = '/gastos';

export const constants ={
    LOADER: 'GASTOS_LOADER',
    DATA: 'GASTOS_DATA',
    ITEM: `GASTOS_ITEM`,
    PAGE: `GASTOS_PAGE`,
    ORDERING: `GASTOS_ORDERING`,
    SEARCH: `GASTOS_SEARCH`,
    OPEN_MODAL: 'GASTOS_OPENMODAL',
    LOADER_FORMULARIO: 'GASTOS_LOADER_FORMULARIO',
    EMPRESAS: 'GASTOS_EMPRESAS',
    TIPO_GASTOS: 'GASTOS_TIPO_GASTO',
    CUENTAS: 'GASTOS_CUENTAS'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})
const setLoaderFormulario = loader_formulario =>({
    type:constants.LOADER_FORMULARIO,
    loader_formulario
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => (dispatch, getStore)  => {
    dispatch({
        type: constants.PAGE,
        page,
    })
};

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().GASTOSs;
    const params = { page };
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch({type: constants.PAGE, page: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/gastos'));
        dispatch(listar());
    }).catch((res) => {
        NotificationManager.error(res.detail ? res.detail:'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/gastos'));

    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false))
    });
};

const eliminar =values => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}/anularTransaccion`, values).then(() => {
        dispatch(setOpenModal(false))
        dispatch(listar());

    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoaderFormulario(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const cambioDescuento = (valor, campo, index) => (dispatch, getStore) => {
    const {  values: { desglose }, values } = _.cloneDeep(getStore().form.GastoForm);
    const fraccion = desglose[index]

    let monto = 0
    if(values.monto){
        monto = values.monto
    }

    if(campo === 'precio'){
        if (valor){
            fraccion.porcentaje = ((valor / parseFloat(monto)) * 100).toFixed(2);
        }
        else{
            fraccion.porcentaje = 0;
        }
    }
    else if(campo=== 'monto'){
        if(valor){
            if(fraccion.porcentaje){
                fraccion.subtotal = ((fraccion.porcentaje / 100) * parseFloat(valor)).toFixed(2)
            }
        }
    }
    else{
        if (valor){
            fraccion.subtotal = ((valor / 100) * parseFloat(monto)).toFixed(2);
        }
        else{
            fraccion.porcentaje = 0;
        }

    }
    dispatch(initializeForm('GastoForm', {...values}))
}

const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}


const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch({
            type: constants.EMPRESAS,
            empresas: res.empresa
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}
const getTipoGastos = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('tipo_gasto').then((res)=>{
        dispatch({
            type: constants.TIPO_GASTOS,
            tipo_gastos: res
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const  getSelectCuentas = () => (dispatch) =>{
    dispatch(setLoader(true));
    let data = {}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch({
            type: constants.CUENTAS,
            select_cuentas:res.cuenta
        })
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const resetFormulario = () => (dispatch) => {
    dispatch(initializeForm('GastoForm', {
        desglose:[{}],
        tipo_cuenta:"true",
        fecha: new Date(),
        pendiente_liquidar:false
    }))
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setOpenModal,
    selectEmpresas,
    getTipoGastos,
    getSelectCuentas,
    resetFormulario,
    cambioDescuento
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.EMPRESAS]: (state, {empresas}) => {
        return {
            ...state,
            empresas
        }
    },
    [constants.TIPO_GASTOS]: (state, {tipo_gastos}) => {
        return {
            ...state,
            tipo_gastos
        }
    },
    [constants.CUENTAS]: (state, {select_cuentas}) => {
        return {
            ...state,
            select_cuentas
        }
    }
};


export const initialState ={
    loader:false,
    data: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
    open_modal: false,
    loader_formulario: false,
    empresas: [],
    tipo_gastos: [],
    select_cuentas: []
};

export default handleActions(reducers, initialState);
