import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import { QUETZAL } from '../../../utility/constants';
import moment from 'moment';

let url = 'reportes' ;
let form = '';
let dirGrid = '/';

export const constants ={
    LOADER: 'DASHBOARD_LOADER',
    INGRESOS_ENGRESOS: "DASHBOARD_INGRESOS_ENGRESOS",
    CUENTAS_COBRAR: "DASHBOARD_CUENTAS_COBRAR",
    CUENTAS_PAGAR: "DASHBOARD_CUENTAS_PAGAR",
    EXISTENCIAS: "DASHBOARD_EXISTENCIAS",
    TOP_VENDIDOS: "DASHBOARD_TOP_VENDIDOS",
    TOP_PRODUCIDOS: "DASHBOARD_TOPPRODUCIDOS",
    MONEDA: "DASHBOARD_MONEDA",
}

// PURE ACTIONS

const setLoader = loader =>({
    type:constants.LOADER,
    loader
});

const setData = (type, data) => ({
    type:type,
    data
});


// ACTIONS

const ingresoEgreso = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    const params = {
        anio: resource.fecha_actual.year(),
        // dashboard: 1
    };
    dispatch(setLoader(true));
    api.get(`${url}/reporteGeneralIngresos`, params).then((response) => {
        console.log("Response ", response);
        const ingresos = response.ingreso_actual;
        const egresos = response.gastos_actual;
        const array = [['Mes', 'Ingreso', 'Egreso']];
        for (const index in ingresos) {
            array.push([ingresos[index].label, ingresos[index].value, egresos[index].value * -1]);
        }
        dispatch(setData(constants.INGRESOS_ENGRESOS, array));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cuentasCobrar = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    dispatch(setLoader(true));
    api.get(`${url}/resumen_cuentasc`).then((response) => {
        console.log("Response ", response);
        dispatch(setData(constants.CUENTAS_COBRAR, response));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cuentasPagar = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    const params = { moneda: resource.moneda.value }
    dispatch(setLoader(true));
    api.get(`${url}/resumen_cuentasp`, params).then((response) => {
        console.log("Response ", response);
        dispatch(setData(constants.CUENTAS_PAGAR, response));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getExistencias = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${url}/reporteExistenciasxProductoHB`).then((response) => {
        const array = [['Producto', 'Existencias']];
        if (!response.results.length) {
            array.push(['Sin data', 0]);
        }
        for (const item of response.results) {
            array.push([item.nombre, item.cantidad]);
        }
        dispatch(setData(constants.EXISTENCIAS, array));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const topProductosVendidos = () => (dispatch, getStore) => {
    const fecha_actual = getStore().dashboard.fecha_actual;
    const fecha_inicial = `${fecha_actual.year()}-${fecha_actual.month()+1}-1`;
    const fecha_final = `${fecha_actual.year()}-${fecha_actual.month()+1}-${fecha_actual.daysInMonth()}`;
    console.log("fecha inicio ", fecha_inicial);
    console.log("fecha fin ", fecha_final);
    const params = {
        fecha_inicial,
        fecha_final
    };
    dispatch(setLoader(true));
    api.get(`${url}/reporteVentasxSucursalesHB`, params).then((response) => {
        console.log("Response ", response);
        const array = [['Sucursal', 'Ventas']];
        if (!response.results.length) {
            array.push(['Sin data', 0]);
        }
        for (const item of response.results) {
            array.push([item.nombre, item.total]);
        }
        dispatch(setData(constants.TOP_VENDIDOS, array));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const topProductosProducidos = () => (dispatch, getStore) => {
    const fecha_actual = getStore().dashboard.fecha_actual;
    const fecha_inicial =  moment().subtract(7, "days").format("YYYY-MM-DD");
    const fecha_final = moment().format("YYYY-MM-DD");
    const params = {
        fecha_inicial,
        fecha_final
    };
    dispatch(setLoader(true));
    api.get(`${url}/reporteProductividadxProductosHB`, params).then((response) => {
        console.log("Response ", response);
        const array = [['Producto', 'Producción']];
        if (!response.results.length) {
            array.push(['Sin data', 0]);
        }
        for (const item of response.results) {
            array.push([item.nombre, item.total]);
        }
        dispatch(setData(constants.TOP_PRODUCIDOS, array));
    }).catch((error) => {
        console.log(error)
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setMoneda = (moneda) => (dispatch, getStore) => {
    dispatch(setData(constants.MONEDA, moneda));
    dispatch(cuentasPagar());
}

export const actions = {
    ingresoEgreso,
    cuentasCobrar,
    cuentasPagar,
    getExistencias,
    topProductosVendidos,
    topProductosProducidos,
    setMoneda
}

export const reducers = {
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.INGRESOS_ENGRESOS]:(state,{data}) =>{
        return{
            ...state,
            ingresos_egresos: data,
        };
    },
    [constants.CUENTAS_COBRAR]:(state,{data}) =>{
        return{
            ...state,
            cuentas_cobrar: data,
        };
    },
    [constants.CUENTAS_PAGAR]:(state,{data}) =>{
        return{
            ...state,
            cuentas_pagar: data,
        };
    },
    [constants.EXISTENCIAS]:(state,{data}) =>{
        return{
            ...state,
            existencias: data,
        };
    },
    [constants.TOP_VENDIDOS]:(state,{data}) =>{
        return{
            ...state,
            top_vendidos: data,
        };
    },
    [constants.TOP_PRODUCIDOS]:(state,{data}) =>{
        return{
            ...state,
            top_producidos: data,
        };
    },
    [constants.MONEDA]:(state,{data}) =>{
        return{
            ...state,
            moneda: data,
        };
    },
}

export const initialState = {
    loader:false,
    ingresos_egresos: [['Mes', 'Ingreso', 'Egreso'], ['Sin data', 0, 0]],
    cuentas_cobrar: {
        vigentes: 0,
        por_vencer: 0,
        vencidas: 0,
        total: 0
    },
    cuentas_pagar: {
        vigentes: 0,
        por_vencer: 0,
        vencidas: 0,
        total: 0
    },
    existencias: [['Producto', 'Existencias'],['Sin data', 0]],
    top_vendidos: [['Producto', 'Ventas'],['Sin data', 0]],
    top_producidos: [['Producto', 'Producción'], ['Sin data', 0]],
    fecha_actual: moment(),
    moneda: {value:'GTQ', label:'GTQ', simbolo: 'Q'}
}

export default handleActions(reducers, initialState);
