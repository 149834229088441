import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import CardSimple from "../../../Utils/Cards/cardSimple";

export default class EmpresaGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        if(permisos.empleados === false) {
            if(!!permisos.ver_empleados === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <CardSimple
                margen="mt-0"
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="/empleados/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre, Puesto"}
                        permisos={true}
                    />
                }
            >
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                >
                    {true && (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: "empleados",
                                eliminar,
                                verpro: "empleados",
                            })}
                        ></TableHeaderColumn>
                    )}
                    <TableHeaderColumn
                        isKey
                        dataField="nombre"
                        dataSort
                        dataFormat={(cell, row) => {
                            return <span>{`${cell} ${row.apellido}`}</span>;
                        }}
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="puesto"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell.nombre;
                        }}
                    >
                        PUESTO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="telefono" dataSort>
                        TELEFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="direccion" dataSort>
                        DIRECCION
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
