import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/clientes/clientes';
import AgendaGrid from './AgendaGrid';

const mstp = state => {
    return {
        ...state.clientes,
    }
};

const mdtp = {
    ...actions,
};

export default connect(mstp,mdtp)(AgendaGrid);