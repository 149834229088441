import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import { BanderaEmpresa } from "../../../../../utility/constants";
import Tiempo from "../../../../../../assets/img/icons/Tiempo.png";
import Check from "../../../../../../assets/img/icons/Check.png";

class SplitDropDown extends Component {
    state = {
        direccion: `/produccion/${this.props.orden}/orden`,
        nombre: "GENERAR OP",
    };

    handleBoton = (url) => {
        console.log("activador boton");
        console.log(this.props);
    };

    render() {
        const { orden } = this.props;
        return (
            <div
                className="btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
            >
                {/* <button type="button" className="btn btn-primary">{this.state.nombre}</button> */}
                {/* <Link className="btn btn-primary" to={this.state.direccion} >{this.state.nombre}</Link> */}
                <div className="btn-group" role="group">
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-azul-claro dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        // style={{width:"50px", minWidth:"auto", border:"0"}}
                    >
                        Nueva OP
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupDrop1"
                    >
                        <Link
                            className="dropdown-item"
                            to={`/produccion/${orden}/orden`}
                        >
                            Generar OP
                        </Link>
                        <Link
                            className="dropdown-item"
                            to={`/produccion/ordenes/${orden}`}
                        >
                            Generar sobre OV
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default class ColaDeProduccionGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    state = {
        empresa: "",
    };

    componentWillMount() {
        const { listar, page, setEmpresa, clearSearch } = this.props;
        let id = this.props.match.params.id;
        clearSearch();
        listar(`${BanderaEmpresa}`);
        setEmpresa(`${BanderaEmpresa}`);

        for (const item of this.props.empresas) {
            if (item.id === BanderaEmpresa) {
                this.setState({ empresa: item.nombre });
                break;
            }
        }
    }

    pageChange = (page) => {
        const { listar } = this.props;
        //let id = this.props.match.params.id;
        listar(`${BanderaEmpresa}`, page);
    };

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        if(permisos["orden_produccion"] === false) {
            if(!!permisos[`ver_orden_produccion`] === false) {
                return <Redirect to={`/`} />
            }
        }
        return (
            <div
                className="Container bg-white mt-4 shadow"
                style={{ borderRadius: "10px" }}
            >
                {this.props.nombre_empresa &&
                    this.props.setTitulo(`${this.props.nombre_empresa}`)}
                <div className="row justify-content-end">
                    <div className="col-12 mt-5">
                        <ToolbarSimple
                            textBoton="Reportar Producción"
                            colorBtn="cerrar"
                            ruta={`/reportar_produccion/${BanderaEmpresa}`}
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: No. orden"}
                            permisos={true}
                            splitboton={
                                <SplitDropDown orden={BanderaEmpresa} />
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4">
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    loading={loader}
                                    onPageChange={this.pageChange}
                                    onSortChange={onSortChange}
                                    page={page}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataFormat={standardActions({
                                            ver: "/produccion/orden",
                                        })}
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        isKey
                                        dataField="no_orden"
                                    >
                                        NO ORDEN
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                    >
                                        FECHA CREACION
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                    >
                                        FECHA DE SOLICITUD
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha_prox_entrega"
                                        dataFormat={(cell) => (
                                            <RenderDateTime fecha={cell} />
                                        )}
                                    >
                                        FECHA ENTREGA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="produccion_finalizada"
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            if (cell) {
                                                return (
                                                    <img
                                                        src={Check}
                                                        width="20"
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <img
                                                        src={Tiempo}
                                                        width="20"
                                                    />
                                                );
                                            }
                                        }}
                                    >
                                        ESTADO PRODUCCIÓN
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
