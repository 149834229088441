import React, {useState, Component} from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderSwitch } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {SelectField, AsyncSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';
import { UnidadMedida, TipoUnidades } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';


let tipoproducto = [];

const getBonificacion = (search) => {
    return api.get("bonificacion", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};



class ActividadesForm extends Component {
    state = {
        arr_medidas: []
    }

    componentDidUpdate(prevProps){

    }

    render() {
        const { handleSubmit, empresas, alerta, vendible} = this.props;

        return (
            <form name="ActividadesForm" className="form-validate mb-lg" onSubmit={handleSubmit}>

                <div className="row ">
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="nombre">Nombre</label>
                        <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="monto">Monto</label>
                        <Field
                            name="monto"
                            label="monto"
                            component={renderCurrency}
                            type="number"
                            className="form-control" />
                    </div>
                </div>
                <div className="row justify-content-center">

                    <div className="form-group has-feedback  col-md-6  col-12">
                        <label className="t-azul" htmlFor="bono">Bono</label>
                        <Field
                                    name="bono"
                                    valueKey="id"
                                    labelKey="nombre"
                                    loadOptions={getBonificacion}
                                    component={AsyncSelectField}
                                />
                    </div>
                    <div className="col-md-6">

                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-sm-12  col-12">
                        <label className="t-azul" htmlFor="descripcion">Descripción</label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            component={renderTextArea}
                            top={{top:"95px"}}
                            type="text"
                            className="form-control" />
                    </div>
                </div>
                <div className="row  justify-content-sm-around justify-content-center">
                    <Link className="btn btn-secondary m-1 align-self-center" to="/actividades" >CANCELAR</Link>
                    <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                </div>

            </form>
        );
    }
}



ActividadesForm =  reduxForm({
    form: 'ActividadesForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            monto: validators.exists()('Este campo es requerido'),
            descripcion: validators.exists()('Este campo es requerido'),
            bono: validators.exists()('Este campo es requerido'),
        });
    },
})(ActividadesForm);

const selector = formValueSelector('ActividadesForm')
ActividadesForm = connect(state => {
    const alerta = selector(state, 'alerta')
    const vendible = selector(state, 'vendible')
    return {
        alerta,
        vendible
      }
    })(ActividadesForm)
export default ActividadesForm;
