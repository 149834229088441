import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, resetSection } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";


let url = 'cuentas' ;
let form = 'EgresoForm';
let dirGridCuentas = '/caja';

export const constants ={
    LOADER: 'CAJA_LOADER',
    DATA: 'CAJA_DATA',
    ITEM: `CAJA_ITEM`,
    PAGE: `CAJA_PAGE`,
    PAGEHISTORIAL: `CAJA_PAGEHISTORIAL`,
    ORDERING: `CAJA_ORDERING`,
    SEARCH: `CAJA_SEARCH`,
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_PENDIENTES:'SET_PENDIENTES',
    SET_ESTADO: 'SET_ESTADO',
    SET_HISTORIAL: 'SET_HISTORIAL',
    SET_ESTADOP: 'SET_ESTADOP',
    SET_MODAL: 'SET_MODAL'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setPageHistorial = page => ({
    type: constants.PAGEHISTORIAL,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().caja;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.cob = 1;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    let params={"cob": 1}
    dispatch(setLoader(true));
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        // if (!!form)
            // dispatch(initializeForm(form, response));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la creación","ERROR",0)
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Error en la edición","ERROR",0)
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la transacción","ERROR",0)
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().caja.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al obtener las empresas","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const movimiento = (data, id)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/moveracaja`, data).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(listPCaja(id));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la transacción","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listPCaja = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    api.get(`${url}/listTransaccionescaja`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const estadoMiCaja = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    api.get(`${url}/estadomicaja`, params).then((res)=>{
        dispatch(setData(constants.SET_ESTADO, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al obtener el estado","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const AcceptMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/acceptmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(listPCaja(caja));
        dispatch(leer(caja));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un en la transacción","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const RefuseMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/refusemovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la transacción","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const CancelMovimiento = (id, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/cancelmovimientocaja`, id).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        // dispatch(estadoMiCaja(caja));
        // dispatch(leer(caja));
        dispatch(listPCaja(caja));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al cancelar la transacción","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cierredecaja = (caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/cierremicaja`, {id: caja}).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(leer(caja));
        // dispatch(listPCaja(caja));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al cerrar la caja","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const HistorialMiCaja = (page = 1, caja) => (dispatch, getStore) => {
    const resource = getStore().caja;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.caja = caja
    dispatch(setLoader(true));
    api.get(`periodos`, params).then((response) => {
        // debugger;
        dispatch({type:constants.SET_HISTORIAL, historial:response});
        dispatch(setPageHistorial(page));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en el historial","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const estadoPeriodo = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id}
    api.get(`${url}/estadoperiodo`, params).then((res)=>{
        dispatch(setData(constants.SET_ESTADOP, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const retiroDeCaja = (data, caja)=> dispatch => {
    dispatch(setLoader(true));
    api.post(`${url}/retirardecaja`, data).then((res) => {
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(estadoMiCaja(caja));
        dispatch(listPCaja(caja));
        dispatch(leer(caja));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la transacción","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
const setModalDescripcion = (estado) => dispatch => {
    dispatch({
        type: constants.SET_MODAL,
        data: estado
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectEmpresas,
    movimiento,
    listPCaja,
    estadoMiCaja,
    AcceptMovimiento,
    RefuseMovimiento,
    CancelMovimiento,
    HistorialMiCaja,
    cierredecaja,
    estadoPeriodo,
    retiroDeCaja,
    setModalDescripcion
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGEHISTORIAL]: (state, { page }) => {
        return {
            ...state,
            pagehistorial:page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.SET_ESTADO]:(state,{ data })=>{
        return{
            ...state,
            estado: data,
        }
    },
    [constants.SET_HISTORIAL]:(state,{ historial })=>{
        return{
            ...state,
            historial,
        }
    },
    [constants.SET_ESTADOP]:(state,{ data })=>{
        return{
            ...state,
            periodo: data,
        }
    },
    [constants.SET_MODAL]:(state,{ data })=>{
        return{
            ...state,
            open_modal: data,
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    pagehistorial: 1,
    ordering: '',
    search: '',
    empresas:[],
    pendientes:[],
    estado:[],
    historial:{
        results: [],
        count: 0,
    },
    periodo:[],
    open_modal: false
};

export default handleActions(reducers, initialState);
