import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/clientes/clientes';
import ClienteCrear from './clienteCrear';
import { change } from 'redux-form';


const mstp = state => {
    return {
        ...state.clientes,
    }
};

const mdtp = {
    ...actions,
};

export default connect(mstp,mdtp)(ClienteCrear);