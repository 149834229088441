import React from "react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid/index";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import {
    renderField,
    SelectField,
    renderTextArea,
    AsyncSelectField,
    renderNumber,
} from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import { api } from "../../../../../utility/api";
import { Component } from "react";

class ItemArray extends Component {
    state = {
        trabajador: {},
        producto: {},
    };
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        // this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getEmpleados(search),
            loadOptions2: (search) => props.getProductos(search),
        };
    }
    render() {
        const { index, trabajador, eliminar, editar } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td
                    className="text-center sin-borde-top"
                    style={{ width: "48px" }}
                >
                    <div className="d-flex justify-content-center">
                        <Link
                            to="/reportar_produccion/edicionreceta"
                            className="px-2"
                            onClick={(e) => {
                                editar();
                            }}
                        >
                            <img
                                className="action-img iconoEditar iconoEditarTabla"
                                style={{ padding: "15%" }}
                                src={require("assets/img/icons/editar.png")}
                                alt="Editar Receta"
                                title="Editar Receta"
                            />
                        </Link>
                        <div className="px-2">
                            <img
                                className="action-img iconoEliminar iconoEliminarTabla"
                                style={{ padding: "15%" }}
                                src={require("assets/img/icons/Cerrar.png")}
                                alt="Borrar"
                                title="Borrar"
                                onClick={() => {
                                    eliminar();
                                    this.props.eliminarUnidadMedida(index);
                                }}
                            />
                        </div>
                    </div>
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${trabajador}`}
                        name={`${trabajador}.trabajador`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top={{ top: "67px", position: "inherit" }}
                        type="text"
                        valueKey="id"
                        labelKey="nombre_completo"
                        onChange={(e) => {
                            this.setState({ trabajador: e });
                        }}
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${trabajador}-${this.props.orden}`}
                        name={`${trabajador}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions2}
                        top={{ top: "67px", position: "inherit" }}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({
                                producto: e,
                            });
                            this.props.setUnidadMedida(index, e);
                        }}
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${trabajador}.cantidad`}
                        type="number"
                        component={renderField}
                        min={1}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    {this.props.unidadMedida[index]
                        ? this.props.unidadMedida[index]
                        : "----"}
                </td>
            </tr>
        );
    }
}

const renderReporte = ({
    fields,
    meta: { error, submitFailed },
    getEmpleados,
    getProductos,
    proveedor,
    setOpenModal,
    setUnidadMedida,
    orden,
    unidadMedida,
    eliminarUnidadMedida,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: "30%" }}>EMPLEADO</th>
                            <th style={{ width: "30%" }}>PRODUCTO</th>
                            <th style={{ width: "20%" }}>CANTIDAD</th>
                            <th style={{ width: "20%" }}> UNIDAD MEDIDA </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((trabajador, index) => {
                            let todos = fields.getAll();
                            return (
                                <ItemArray
                                    proveedor={proveedor}
                                    index={index}
                                    key={index}
                                    todos={todos}
                                    trabajador={trabajador}
                                    getEmpleados={getEmpleados}
                                    getProductos={getProductos}
                                    setUnidadMedida={setUnidadMedida}
                                    unidadMedida={unidadMedida}
                                    eliminarUnidadMedida={eliminarUnidadMedida}
                                    orden={orden}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                    editar={() => {
                                        setOpenModal(fields.get(index), index);
                                    }}
                                    field={fields ? fields.get(index) : null}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar
                </button>
            </div>
        </div>
        {(submitFailed || error) && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos
const tipo_horario = [
    { value: 1, label: "Horario laboral" },
    { value: 5, label: "Horario extra" },
];

class ProduccionForm extends React.Component {
    getOrdenes = (search) => {
        return api
            .get("produccion", { produccion_finalizada:false, search, empresa: this.props.empresa })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getEmpleados = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        return api
            .get("empleados", params)
            .then((data) => {
                if (data) {
                    return data.results;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getProductos = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        params.tiene_receta = true;
        params.empresa__entidad = this.props.empresa;
        if (this.props.orden) {
            params.detalle_orden__orden = this.props.orden.id;
        }
        return api
            .get("productos", params)
            .then((data) => {
                if (data) {
                    return data.results;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    setOpenModal = (fila = {}, index = null) => {
        const {
            setRecetaProducto,
            empresa,
            cola_produccion,
            orden_produccion,
        } = this.props;
        if (index != null && index != undefined) {
            setRecetaProducto(
                fila.producto.id,
                fila,
                index,
                cola_produccion,
                true,
                empresa,
                orden_produccion
            );
        } else setRecetaProducto({}, null, [], false, null, null);
    };
    componentDidUpdate(prevProps, prevState) {
        if (this.props.orden_produccion !== prevProps.orden_produccion) {
            if (this.props.orden_produccion) {
                this.setState({ orden: this.props.orden_produccion });
            }
        }
    }
    render() {
        const {
            handleSubmit,
            estadoRead,
            empresa,
            cola_produccion,
            handledestroy,
        } = this.props;
        return (
            <form
                name="ProduccionForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-lg-5 offset-lg-1 col-md-6 col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Orden produccion
                            <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name={`orden`}
                            component={AsyncSelectField}
                            loadOptions={this.getOrdenes}
                            isClearable={false}
                            type="text"
                            filterOptions={false}
                            valueKey="id"
                            labelKey="no_orden"
                            className="form-control"
                            changePersonalizado={(e) => {
                                this.props.setOrden(e);
                                // this.props.getRecetaOV(e.id, empresa)
                            }}
                            top={{ top: "0px", position: "inherit" }}
                            // disabled={estadoRead}
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-5 col-sm-6 col-12">
                        <label className="t-azul" htmlFor="producto">
                            Horario
                            <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name={`tipo_horario`}
                            label="unidad_medida"
                            valueKey="value"
                            labelKey="label"
                            options={tipo_horario}
                            component={SelectField}
                        />
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-lg-5 offset-lg-1 col-sm-6 col-12">
                        <label className="t-azul" htmlFor="producto">
                            Horas trabajadas
                            <small> (Campo obligatorio) </small>
                        </label>
                        <Field
                            name={`horas_trabajadas`}
                            type="text"
                            component={renderNumber}
                            decimalScale={2}
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-5 col-sm-6 col-12"></div>
                </div>
                <br />
                {this.props.orden && this.props.orden.detalle_orden && (
                    <div className="row justify-content-start">
                        <div className="col-12" >
                            <label> DETALLE DE ORDEN</label>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 0px 10px 0px",
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-12">
                        <Grid
                            hover
                            striped
                            data={{
                                count: 10,
                                results: this.props.orden.detalle_orden,
                            }}
                            loading={false}
                            pagination={false}
                        >
                            <TableHeaderColumn
                                dataField="producto"
                                dataFormat={
                                    (cell) => (cell.nombre)
                                }
                                isKey
                            >
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad"
                                dataAlign="right"
                                dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                            >
                                CANTIDAD SOLICITADA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad_actual"
                                dataAlign="right"
                                dataFormat={
                                    (cell, row) => <RenderNumber value={row.cantidad - cell} decimalScale={4} />
                                }
                            >
                                CANTIDAD REPORTADA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad_actual"
                                dataAlign="right"
                                dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                            >
                                CANTIDAD FALTANTE
                            </TableHeaderColumn>
                        </Grid>
                        </div>
                    </div>
                )}
                <br />
                <div className="row justify-content-start">
                    <div className="col-12" >
                        <label> REPORTAR PRODUCCIÓN </label>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                    </div>
                    <div className="form-group has-feedback col-12">
                        <FieldArray
                            name="reporte"
                            label="reporte"
                            component={renderReporte}
                            className="form-control"
                            getProductos={this.getProductos}
                            getEmpleados={this.getEmpleados}
                            setOpenModal={this.setOpenModal}
                            orden={this.props.orden && this.props.orden.id && this.props.orden.id}
                            setUnidadMedida={this.props.setUnidadMedida}
                            unidadMedida={this.props.unidadMedida}
                            eliminarUnidadMedida={
                                this.props.eliminarUnidadMedida
                            }
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/produccion`}
                        onClick={() => handledestroy()}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

ProduccionForm = reduxForm({
    form: "ProduccionForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        if (!data.orden) {
            errors.orden = "Campo requerido";
        }
        if (!data.tipo_horario) {
            errors.tipo_horario = "Campo requerido";
        }
        if (!data.horas_trabajadas) {
            errors.horas_trabajadas = "Campo requerido";
        }
        if (!data.reporte || !data.reporte.length) {
            errors.reporte = {
                _error: "Debe de ingresar al menos un Registro",
            };
        } else {
            const detalleArray = [];
            data.reporte.forEach((campo, index) => {
                let detErrors = {};
                if (!campo.trabajador) {
                    detErrors.trabajador = "Campo requerido";
                }
                if (!campo.producto) {
                    detErrors.producto = "Campo requerido";
                }
                if (!campo.cantidad) {
                    detErrors.cantidad = "Campo requerido";
                } else if(campo.cantidad <= 0){
                    detErrors.cantidad = "La cantidad debe ser mayor a 0"
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.reporte = detalleArray;
            }
        }
        return errors;
    },
})(ProduccionForm);

const selector = formValueSelector("ProduccionForm");
ProduccionForm = connect((state) => {
    const reporte = selector(state, "reporte");
    const orden = selector(state, "orden");
    let cola_produccion = [];
    let orden_produccion = null;
    if (reporte) {
        cola_produccion = reporte;
    }
    if (orden) {
        orden_produccion = orden;
    }

    return {
        cola_produccion,
        orden_produccion,
    };
})(ProduccionForm);

export default ProduccionForm;
