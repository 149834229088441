import { connect } from 'react-redux';
import {
    actions
} from '../../../../../../redux/modules/Reporte/ventas';
import ReporteVentas from './reporteVentas';

const mstp = state => {
    return {...state.reporteVentas, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteVentas)