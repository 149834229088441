import React, { Component } from "react";
import Select, { Creatable, Async } from "react-select";
import classNames from "classnames";
import { api } from "api";

import Modal from "react-responsive-modal";
import { renderCurrency } from "../../Utils/renderField";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";

const getClientes = (search) => {
    return api
        .get("clientes", { search, esCliente: true })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getProveedores = (search) => {
    return api
        .get("clientes", { search, esCliente: "" })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getVendedores = (search) => {
    return api
        .get("vendedores", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

let estados = [
    { value: 1, label: "Todos" },
    { value: 2, label: "Vigente" },
    { value: 3, label: "Por vencer" },
    { value: 4, label: "Vencido" },
];

let monedas = [
    { value: "GTQ", label: "GTQ", simbolo: "Q" },
    { value: "USD", label: "USD", simbolo: "$" },
    { value: "EUR", label: "EUR", simbolo: "€" },
    // {value: 'ALL', label:"Todas"},
];

export default class ToolbarReporteCuentas extends Component {
    state = {
        clienteproveedor: this.props.defaultclipro
            ? this.props.defaultclipro
            : null,
        asyncValue: this.props.asyncValue ? this.props.asyncValue : null,
        vendedor: null,
        estado: 1,
        resultados: [],
        openmodalMonedas: false,
        moneda: "GTQ",
        tipo_cambio_dolar: 0.13,
        tipo_cambio_euro: 0.12,
    };
    componentWillMount() {
        let resultados = [];

        if (this.props.es_cliente) {
            resultados = getClientes("");
        } else {
            resultados = getProveedores("");
        }

        resultados.then((valor) => {
            this.setState({ resultados: valor });
        });
    }
    componentDidUpdate(preevprops) {}
    handlesubmit = (values) => {
        his.setOpenModalMoneda(false);
        this.setState({
            tipo_cambio_dolar: values.tipo_cambio_dolar,
            tipo_cambio_euro: values.tipo_cambio_euro,
        });
    };
    setOpenModalMoneda = (val) => {
        this.setState({ openmodalMonedas: val });
    };
    render() {
        const {
            es_cliente,
            valorEsetado,
            valorMoneda,
            isClearableCliPro,
            vistaOrdenes,
            defaultclipro,
        } = this.props;
        return (
            <div className="row col-12 p-0">
                <Modal
                    open={this.state.openmodalMonedas}
                    onClose={(e) => {
                        this.setOpenModalMoneda(false);
                    }}
                    center
                >
                    <div className="mt-5">
                        <h2 className="text-center text-primary">
                            DATOS TIPO DE CAMBIO
                        </h2>
                    </div>
                    <TipoCambioForm
                        onSubmit={this.handlesubmit}
                        setOpenModalMoneda={this.setOpenModalMoneda}
                        initialValues={{
                            tipo_cambio_euro: 0,
                            tipo_cambio_dolar: 0,
                        }}
                    />
                </Modal>
                <div className="row col-12 m-0 text-lg-left">
                    <div
                        className={"col-lg-4 col-md-6 col-12 align-self-center"}
                    >
                        <span className="t-azul-claro font-weight-bold">
                            {es_cliente ? "Cliente" : "Proveedor"}
                        </span>
                        <Async
                            isClearable={isClearableCliPro}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            loadOptions={
                                es_cliente ? getClientes : getProveedores
                            }
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["nombre"]}
                            type="text"
                            onChange={(e) => {
                                e
                                    ? this.setState({
                                          clienteproveedor: e.id,
                                          vendedor: null,
                                          asyncValue: e ? e : null,
                                      })
                                    : this.setState({
                                          clienteproveedor: null,
                                          vendedor: null,
                                      });
                                this.props.cuentasporCOP(
                                    {
                                        clienteproveedor: e ? e.id : null,
                                        vendedor: null,
                                        estado: this.state.estado,
                                        moneda: this.state.moneda,
                                        tipo_cambio_dolar: this.state
                                            .tipo_cambio_dolar,
                                        tipo_cambio_euro: this.state
                                            .tipo_cambio_euro,
                                    },
                                    es_cliente
                                );
                            }}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames("react-select-container ")}
                            defaultOptions={this.state.resultados}
                            placeholder="Seleccionar.."
                        />
                    </div>
                    {es_cliente && !vistaOrdenes && (
                        <div className="col-lg-4 col-md-6 col-12 align-self-center">
                            <span className="t-azul-claro font-weight-bold">
                                Vendedor
                            </span>
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                loadOptions={getVendedores}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) =>
                                    `${option["first_name"]} ${option["last_name"]}`
                                }
                                type="text"
                                onChange={(e) => {
                                    e
                                        ? this.setState({
                                              clienteproveedor: null,
                                              vendedor: e.id,
                                          })
                                        : this.setState({
                                              clienteproveedor: null,
                                              vendedor: null,
                                          });
                                    this.props.cuentasporCOP(
                                        {
                                            vendedor: e ? e.id : null,
                                            clienteproveedor: null,
                                            estado: this.state.estado,
                                            moneda: e.value,
                                        },
                                        es_cliente
                                    );
                                }}
                                //cacheOptions
                                multi={true}
                                autoload={false}
                                cache={false}
                                className={classNames(
                                    "react-select-container "
                                )}
                                defaultOptions
                                placeholder="Seleccionar.."
                            />
                        </div>
                    )}
                    {!es_cliente && (
                        <div className="col-lg-4 col-md-6 col-12 align-self-center">
                            <span className="t-azul-claro font-weight-bold">
                                Moneda
                            </span>
                            <Select
                                isClearable={false}
                                className={classNames("react-select-container")}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={monedas}
                                placeholder={"Monedas"}
                                defaultValue={valorMoneda}
                                onChange={(e) => {
                                    e && this.setState({ moneda: e.value });
                                    if (e.value === "ALL") {
                                        this.setOpenModalMoneda(true);
                                    } else {
                                        this.props.cuentasporCOP(
                                            {
                                                clienteproveedor: this.state
                                                    .clienteproveedor,
                                                estado: this.state.estado,
                                                moneda: e.value,
                                                vendedor: this.state.vendedor,
                                            },
                                            es_cliente
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={"col-lg-4 col-md-6 col-12 align-self-center"}
                    >
                        <span className="t-azul-claro font-weight-bold">
                            Estado
                        </span>
                        <Select
                            isClearable={false}
                            className={classNames("react-select-container")}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={estados}
                            placeholder={"Movimiento"}
                            defaultValue={valorEsetado}
                            onChange={(e) => {
                                this.setState({ estado: e.value });
                                this.props.cuentasporCOP(
                                    {
                                        estado: e.value,
                                        clienteproveedor: this.state
                                            .clienteproveedor,
                                        vendedor: this.state.vendedor,
                                        moneda: this.state.moneda,
                                        tipo_cambio_dolar: this.state
                                            .tipo_cambio_dolar,
                                        tipo_cambio_euro: this.state
                                            .tipo_cambio_euro,
                                    },
                                    es_cliente
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class TipoCambioForm extends Component {
    state = {
        dolar: "$. ",
        euro: "€. ",
    };
    render() {
        const { handleSubmit } = this.props;
        return (
            <form
                name="TipoCambioForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row justify-content-center">
                    <div className={`form-group has-feedback   col-12 `}>
                        <label className="t-azul" htmlFor="cuenta">
                            Tipo de cambio Dolar a Quetzal
                        </label>
                        <Field
                            name={`tipo_cambio_dolar`}
                            label="tipo_cambio"
                            prefix={this.state.dolar}
                            component={renderCurrency}
                            decimalScale={4}
                            parse={(cell) => parseFloat(cell)}
                            onChange={(e) => {
                                // console.log(e)
                                //   this.setState({t_cambio: e})
                            }}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-12">
                        <label className="t-azul" htmlFor="descripcion">
                            Tipo de cambio Euro a Quetzal
                        </label>
                        <Field
                            name={`tipo_cambio_euro`}
                            label="tipo_cambio"
                            prefix={this.state.euro}
                            component={renderCurrency}
                            decimalScale={4}
                            parse={(cell) => parseFloat(cell)}
                            onChange={(e) => {
                                // console.log(e)
                                //   this.setState({t_cambio: e})
                            }}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <button
                                type="submit"
                                className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

TipoCambioForm = reduxForm({
    form: "TipoCambioForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            tipo_cambio_dolar: validators.exists()("Este campo es requerido"),
            tipo_cambio_euro: validators.exists()("Este campo es requerido"),
        });
    },
})(TipoCambioForm);
