import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/producto/tipoproducto';
import TipoProductoGrid from './TipoProductoGrid';

const mstp = state => {
    return {...state.tipoproducto, permisos: state.login.me.permisos,}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TipoProductoGrid)
