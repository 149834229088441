import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Actividades/actividades';
import ActividadesCrear from './ActividadesCrear';

const mstp = state => {
    return {...state.actividades ,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ActividadesCrear)


