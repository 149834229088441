import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import CardPMEnviadas from "../../../Utils/Cards/cardsPM";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import { Redirect } from "react-router-dom";

//Extras
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { getsimbolo } from "../../../../../utility/constants";
import Swal from "sweetalert2";

import Modal from "react-responsive-modal";
import AnulacionForm from "../../Transacciones/Listar/anulacionForm";
import GridRechazadas from "./OCrechazadas/gridRechazadas";
import GridAprobados from "./OCaprobadas/gridAprobadas";
import ToolbarFiltrosOC from "./filtrosOC";

function cellTachado(cell, row) {
    if (!row.estado) {
        return {
            textDecoration: "line-through",
            whiteSpace: "normal",
            padding: 5,
        };
    }
    return { whiteSpace: "normal", padding: 5 };
}
export default class OrdenCompraGrid extends Component {
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
    };
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
        this.props.listPM(true);
    }
    abrirModalEliminar = (id, row) => {
        let _data = this.props.data;
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
        });
        this.props.setOpenModal(true);
    };
    onSubmit = (values) => {
        values.orden = this.state.id_transaccion;
        Swal.fire({
            title: "¿Desea anular la orden de compra?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, anular!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.anularOC(values);
            }
        });
    };
    handleData = (value) => {
        this.props.setAprube(value)
    };
    callBackTab = (key) => {
        console.log("key ", key);
    };
    setSimbolo = () => {
        let valor = "Q.";
        let _data = this.props.data;
        if (_data != undefined && this.state.id_transaccion != 0) {
            _data.results.forEach((e) => {
                if (e.id === this.state.id_transaccion) {
                    valor = getsimbolo(e.moneda);
                }
            });
        }
        return valor;
    };
    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            open_modal,
            page,
            page2,
            permisos,
            entregadoChange,
            pagoChange,
        } = this.props;
        if (permisos["ordencompra"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple
                //titulo="Ordenes de compras"
                margen={"mt-0"}
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="ordencompra/Nuevo"
                        buscar={
                            this.props.aprube
                                ? this.props.searchChange
                                : this.props.searchChangeRefuse
                        }
                        buscador={this.props.search}
                        placeholder={"Buscar por: No. Orden, Proveedor"}
                        permisos={permisos.ordencompra}
                        // margen={"0"}
                        estatefilterBar={true}
                        filterbar={
                            <ToolbarFiltrosOC
                                valorEntrega={this.props.entregado}
                                valorPagado={this.props.pago_completo}
                                changeEntrega={entregadoChange}
                                changePago={pagoChange}
                            />
                        }
                    />
                }
            >
                <Modal
                    open={open_modal}
                    onClose={(e) => {
                        this.props.setOpenModal(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Justificación de anulación
                        </span>
                    </div>

                    {this.state.transaccion_sel &&
                    !this.state.transaccion_sel.estado ? (
                        <div className="form-validate mb-lg text-center">
                            <span className="m-none t-mostaza semibold">
                                Fecha anulación:
                            </span>
                            <br />
                            <span className="m-none t-musgo semibold">
                                <RenderDateTime
                                    fecha={
                                        this.state.transaccion_sel.transaccion
                                            .fecha_anulacion
                                    }
                                />
                            </span>
                            <br />
                            <span className="m-none t-mostaza semibold">
                                Usuario anulación:
                            </span>
                            <br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span>
                            <br />
                            <span className="m-none t-mostaza semibold">
                                descripción:
                            </span>
                            <br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span>
                            <br />
                        </div>
                    ) : (
                        <AnulacionForm
                            onSubmit={this.onSubmit}
                            simbolo={this.setSimbolo()}
                            cerrarModal={this.props.setOpenModal}
                            tiene_monto_credito={true}
                        />
                    )}
                </Modal>

                <CardPMEnviadas
                    data={this.props.pendientes}
                    loader={loader}
                    titulo={"Pendientes de aprobar"}
                    link={`ordencompra`}
                />

                <h5 className="t-primary col-12">
                    {"Listado de ordenes de compra"}
                </h5>

                <Tabs
                    defaultActiveKey="ORDENES"
                    tabBarPoition="top"
                    onChange={(key) => {
                        key === "APROBADAS"
                            ? this.handleData(true)
                            : this.handleData(false);
                    }}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="Aprobadas" key="APROBADAS">
                        <GridAprobados
                            data={data}
                            listar={this.props.listar}
                            loader={loader}
                            page={page}
                            onPageChange={onPageChange}
                            onSortChange={onSortChange}
                            abrirModalEliminar={this.abrirModalEliminar}
                            handleData={this.handleData}
                            setSimbolo={this.setSimbolo}
                        />
                    </TabPane>
                    <TabPane tab="Rechazadas" key="RECHAZADAS">
                        <GridRechazadas
                            data={this.props.datarefuse}
                            loader={loader}
                            onPageChange={onPageChange}
                            onSortChange={this.props.onSortChangeRefuse}
                            page={page2}
                            listar={this.props.listarRefuse}
                            anularOC={this.props.anularOC}
                            handleData={this.handleData}
                        />
                    </TabPane>
                </Tabs>
            </CardSimple>
        );
    }
}
