import { connect } from 'react-redux';
import {
    actions
} from '../../../../../../redux/modules/Reporte/existenciasBodega';
import ReporteExistenciasBodega from './reporteExistenciasBodega';

const mstp = state => {
    return {...state.reporteExistenciasBodega, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteExistenciasBodega)