import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency, renderTextArea, AsyncSelectField, renderSelecttree, SelectField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { TIPO_PUESTO } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';


let tipoproducto = [];

const getBonificaciones = (search) => {
    return api.get("bonificacion", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderBonficacionPuesto = ({fields, meta: {error, submitFailed }, getProductos, regiones, simbolo}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th style={{width: "40%"}}>BONIFICACION</th>
                        <th style={{width: "40%"}}>MONTO</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((PET, index) => {
                        let transaccion = fields.get(index);
                        return (
                            <tr key={index} className="align-items-start">
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top "  style={{width: "40%"}}>
                                <Field
                                    name={`${PET}.bono`}
                                    component={AsyncSelectField}
                                    loadOptions={getBonificaciones}
                                    type="text"
                                    filterOptions= {false}
                                    valueKey="id"
                                    labelKey="nombre"
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                            <td className="sin-borde-top "  style={{width: "40%"}}>
                                <Field
                                    name={`${PET}.valor`}
                                    label="monto"
                                    component={renderCurrency}
                                    parse={cell => parseFloat(cell)}
                                    className="form-control"
                                    top ={{top:"67px", position:"inherit"}}
                                />
                            </td>
                        </tr>
                        );
                    }
                    )}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    Agregar
                </button>
            </div>
        </div>
    </div>
)

const PuestosForm = (props) => {
    const { handleSubmit, tipo_gastos } = props;
    return (
        <form name="PuestosForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Nombre del Puesto<small> (Campo obligatorio) </small></label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Sueldo Base <small> (Campo obligatorio) </small></label>
                    <Field name="sueldo" label="sueldo" component={renderCurrency} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Tipo de gasto Sueldo <small> (Campo obligatorio) </small></label>
                    <Field
                        name="Gasto_sueldo"
                        label="nombre"
                        component={renderSelecttree}
                        options={tipo_gastos}
                        type="text"
                        valueKey="value"
                        labelKey="label"
                        className="form-control" 
                    />
                </div>
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label className="t-azul" htmlFor="tipo">Tipo de Puesto</label>
                    <Field
                        name="tipo"
                        valueKey="value"
                        labelKey="label"
                        options={TIPO_PUESTO}
                        component={SelectField}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Bono por Horas extras <small> (Campo obligatorio) </small></label>
                    <Field
                        name="bono_horas_extras"
                        valueKey="id"
                        labelKey="nombre"
                        loadOptions={getBonificaciones}
                        component={AsyncSelectField}
                    />
                </div>
                <div className="form-group has-feedback col-lg-5 col-md-6 col-12">

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-10 col-md-10 col-12">
                    <label className="t-azul"  htmlFor="producto">Descripción <small> (Campo obligatorio) </small></label>
                    <Field name="descripcion" label="descripcion" component={renderTextArea} type="text" className="form-control" top={{top:"98px"}}/>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-lg-10 col-md-10 col-12">
                    <FieldArray
                        name="bonificaciones"
                        label="bonificaciones"
                        component={renderBonficacionPuesto}
                        className="form-control"
                        regiones={props.regiones}
                        simbolo={'Q.'}
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-center justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/puestos" >Cancelar</Link>
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'PuestosForm', // a unique identifier for this form
    validate: (data) => {
        const errors={}
        if(!data.nombre){
            errors.nombre="Este campo es requerido"
        }
        if(!data.sueldo){
            errors.sueldo="Este campo es requerido"
        }
        if(!data.tipo){
            errors.sueldo="Este campo es requerido"
        }
        if(!data.descripcion){
            errors.descripcion="Este campo es requerido"
        }
        if(!data.Gasto_sueldo){
            errors.Gasto_sueldo="Este campo es requerido"
        }
        if(!data.bono_horas_extras){
            errors.bono_horas_extras="Este campo es requerido"
        }
        if(!data.tipo){
            errors.tipo="Este campo es requerido"
        }
        if(data.bonificaciones){
            const ItemArrayErrors = [];
            data.bonificaciones.forEach((item, index) => {
                const errorItem = {};
                if (!item || !item.bono) {
                    errorItem.bono = 'Campo Requerido';
                    ItemArrayErrors[index] = errorItem;
                }
                if(!item || !item.valor){
                    errorItem.valor = 'Campo Requerido'
                    ItemArrayErrors[index] = errorItem;
                } else if(item.valor <= 0){
                    errorItem.valor = 'El monto debe ser mayor a 0'
                    ItemArrayErrors[index] = errorItem;
                }
                if (errorItem) {
                    ItemArrayErrors[index] = errorItem;
                }
            });
            if(ItemArrayErrors.length){
                errors.bonificaciones = ItemArrayErrors
            }
        }
        return errors;
    },
})(PuestosForm);
