import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/PeticionVenta/peticionventa';
import PeticionVentaGridTab from './peticionesGridTab';

const mstp = state => {
    return {...state.peticionV, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PeticionVentaGridTab)
