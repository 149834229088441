import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteHistoricoCajas from "../../../Utils/Toolbar/ToolbarReporteHistoricoCajas";

export default class ReporteHistoricoCajas extends Component {
    componentDidMount() {
        this.props.getHistoricoCajas();
    }

    render() {
        const { loader, data, page, getHistoricoCajas } = this.props;
        return (
            <CardSimple row={true} margen="mt-0" style={{ marginTop: "-50px" }}>
                <ToolbarReporteHistoricoCajas
                    changeEmpleado={this.props.changeEmpleado}
                    empleados={this.props.empleado && this.props.empleado}
                    changeFechaInicial={this.props.changeFechaInicial}
                    fecha_inicial={this.props.fecha_inicial}
                    fecha_final={this.props.fecha_final}
                    changeFechaFinal={this.props.changeFechaFinal}
                />
                <br />
                <label className="t-primary"> Listado </label>
                <hr
                    style={{
                        background: "#408EAA",
                        height: "2px",
                        width: "100%",
                        marginTop: "0px",
                    }}
                />
                <div className="col-12 row m-0 p-0 d-flex">
                    <div className="col-md-12 mx-0 px-0">
                        {data && data.results && (
                            <Grid
                                hover
                                striped
                                page={page}
                                data={data}
                                loading={loader}
                                onPageChange={getHistoricoCajas}
                                pagination={true}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="15%"
                                    dataFormat={standardActions({
                                        ver: "/reporte_historico_cajas",
                                    })}
                                >
                                    ACCIONES
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_apertura"
                                    dataFormat={(cell) =>
                                        cell ? (
                                            <RenderDateTime fecha={cell} />
                                        ) : (
                                            "Sin registro"
                                        )
                                    }
                                >
                                    FECHA APERTURA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_cierre"
                                    dataFormat={(cell) =>
                                        cell ? (
                                            <RenderDateTime fecha={cell} />
                                        ) : (
                                            "Sin registro"
                                        )
                                    }
                                >
                                    FECHA CIERRE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="vendedor"
                                    dataAlign="center"
                                    dataFormat={(cell) => (cell ? cell : 'Sin registro')}
                                >
                                    VENDEDOR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="apertura"
                                    headerAlign="center"
                                    dataAlign="right"
                                    dataFormat={(cell) =>
                                        cell ? (
                                            <RenderMoneda monto={cell} />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )
                                    }
                                >
                                    TOTAL APERTURA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cierre"
                                    headerAlign="center"
                                    dataAlign="right"
                                    dataFormat={(cell) =>
                                        cell ? (
                                            <RenderMoneda monto={cell} />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )
                                    }
                                >
                                    TOTAL CIERRE
                                </TableHeaderColumn>
                            </Grid>
                        )}
                    </div>
                </div>
            </CardSimple>
        );
    }
}
