import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';
import Chart from "react-google-charts";
import { RenderMoneda } from "../../Utils/renderField/renderTableField";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CardSimple from '../../Utils/Cards/cardSimple';
import { Monedas } from '../../../../utility/constants';
import {
    CircularProgressbar,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


export default class Dashboard extends Component {

    componentDidMount() {
        //this.props.ingresoEgreso();
        this.props.cuentasCobrar();
        this.props.cuentasPagar();
        this.props.getExistencias();
        this.props.topProductosVendidos();
        this.props.topProductosProducidos();
        this.props.cuentasporCOP(
            {
                clienteproveedor: null,
                vendedor: null,
                estado: 1,
                moneda: "GTQ",
                tipo_cambio_dolar: 0.13,
                tipo_cambio_euro: 0.12,
            }
        );
    }

    render() {
        const { loader, cuentas_cobrar, cuentas_pagar, existencias, top_vendidos, top_producidos, totalizado } = this.props;
        return (
            <div className="col-12 row px-0 mx-0">
                <div className="col-12 col-md-6 pl-0 pr-1 mx-0">
                    <CardSimple margen="mt-0" titulo_dashboard="CUENTAS POR COBRAR" row>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row m-0 p-0 d-flex mt-3" style={{marginTop:"-50px"}}>
                                <div className="d-flex flex-1 flex-column mr-md-1 w-100">
                                    <div className="h-100 d-flex align-items-end flex-column w-100" style={{marginTop:"-50px"}}>
                                        <div className="d-flex flex-row w-100">
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*cuentas_cobrar.vigentes)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)}
                                                        text={`${parseFloat((100*cuentas_cobrar.vigentes)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                        strokeLinecap: "butt",
                                                        textSize: "12px",
                                                        textColor: "black",
                                                        pathColor: "128085",
                                                        trailColor: "E4E4E4",
                                                        rotation: 1 + (1 - (100*cuentas_cobrar.vigentes)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Vigente: </h6>
                                                <h6 style={{color:"#157E8B"}}>
                                                    <RenderMoneda monto={cuentas_cobrar.vigentes} />
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*cuentas_cobrar.por_vencer)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)}
                                                        text={`${parseFloat((100*cuentas_cobrar.por_vencer)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                            strokeLinecap: "butt",
                                                            textSize: "12px",
                                                            textColor: "black",
                                                            pathColor: "5A69FE",
                                                            trailColor: "E4E4E4",
                                                            rotation: 1 + (1 - (100*cuentas_cobrar.por_vencer)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Por vencer: </h6>
                                                <h6 style={{color:"#5A69FE"}}>
                                                    <RenderMoneda monto={cuentas_cobrar.por_vencer} />
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*cuentas_cobrar.vencidas)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)}
                                                        text={`${parseFloat((100*cuentas_cobrar.vencidas)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                        strokeLinecap: "butt",
                                                        textSize: "12px",
                                                        textColor: "black",
                                                        pathColor: "FF5784",
                                                        trailColor: "E4E4E4",
                                                        rotation: 1 + (1 - (100*cuentas_cobrar.vencidas)/(cuentas_cobrar.vigentes + cuentas_cobrar.por_vencer + cuentas_cobrar.vencidas) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Vencido: </h6>
                                                <h6 style={{color:"#FF5784"}}>
                                                    <RenderMoneda monto={cuentas_cobrar.vencidas} />
                                                </h6>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="d-flex justify-content-arround w-100 mb-4">
                                            <div className="d-flex flex-1 justify-content-start">
                                                <h5 className="t-primary">TOTAL:&nbsp;</h5>
                                                <h5>
                                                    <RenderMoneda monto={cuentas_cobrar.total} className="t-primary"/>
                                                </h5>
                                            </div>
                                            <Link className="btn btn-azul-claro  align-self-end" to="/cuentas_por_cobrar">
                                                Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div>
                <div className="col-12 col-md-6 pr-0 pl-1 mx-0">
                    <CardSimple margen="mt-0" titulo_dashboard="CUENTAS POR PAGAR" row>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row m-0 p-0 d-flex mt-3">
                                <div className="d-flex flex-1 flex-column mx-md-1 w-100">
                                    <div style={{marginTop:"-50px"}}>
                                        <div className="d-flex flex-row mt-0">
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*totalizado.total_vigente)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)}
                                                        text={`${parseFloat((100*totalizado.total_vigente)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                        strokeLinecap: "butt",
                                                        textSize: "12px",
                                                        textColor: "black",
                                                        pathColor: "157E8B",
                                                        trailColor: "E4E4E4",
                                                        rotation: 1 + (1 - (100*totalizado.total_vigente)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Vigente: </h6>
                                                <h6 style={{color:"#157E8B"}}>
                                                    <RenderMoneda monto={totalizado.total_vigente} simbolo={this.props.moneda.simbolo} />
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*totalizado.total_porvencer)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)}
                                                        text={`${parseFloat((100*totalizado.total_porvencer)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                        strokeLinecap: "butt",
                                                        textSize: "12px",
                                                        textColor: "black",
                                                        pathColor: "5A69FE",
                                                        trailColor: "E4E4E4",
                                                        rotation: 1 + (1 - (100*totalizado.total_porvencer)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Por vencer: </h6>
                                                <h6 style={{color:"#5A69FE"}}>
                                                    <RenderMoneda monto={totalizado.total_porvencer} simbolo={this.props.moneda.simbolo} />
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-1 flex-column align-items-center">
                                                <div className="p-3">
                                                    <CircularProgressbar
                                                        value={(100*totalizado.total_vencido)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)}
                                                        text={`${parseFloat((100*totalizado.total_vencido)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido)).toFixed(2)}%`}
                                                        strokeWidth={25}
                                                        styles={buildStyles({
                                                        strokeLinecap: "butt",
                                                        textSize: "12px",
                                                        textColor: "black",
                                                        pathColor: "FF5784",
                                                        trailColor: "E4E4E4",
                                                        rotation: 1 + (1 - (100*totalizado.total_vencido)/(totalizado.total_vigente + totalizado.total_porvencer + totalizado.total_vencido) / 100) / 2
                                                        })}
                                                    />
                                                </div>
                                                <h6 className="t-musgo m-0">Vencido: </h6>
                                                <h6 style={{color:"#FF5784"}}>
                                                    <RenderMoneda monto={totalizado.total_vencido} simbolo={this.props.moneda.simbolo} />
                                                </h6>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="d-flex justify-content-end mb-4 align-items-center">
                                            <div className="d-flex flex-1 justify-content-start align-self-center">
                                                <h5 className="t-primary">TOTAL:&nbsp;</h5>
                                                <h5>
                                                    <RenderMoneda monto={totalizado.total} simbolo={this.props.moneda.simbolo} className="t-primary" />
                                                </h5>
                                            </div>
                                            <Link className="btn btn-azul-claro  align-self-center" to="/cuentas_por_pagar">
                                                Ver más
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div>
                {/* <div className="col-12 px-1 mx-0 mt-3">
                    <CardSimple margen="mt-2" titulo_dashboard="INGRESOS Y GASTOS">
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row m-0 p-0 d-flex mt-3">
                                <div className="col-md-12 row m-0 p-0 d-flex"
                                style={{ border: "1px solid #E8E8E8", }}>
                                <div className="head-dash">Ingresos y Gastos</div>
                                <div className="col-md-12 mx-0 px-0">
                                    <div className="">
                                        <div style={{ width: '100%', flex: 1 }}>
                                            <Chart
                                                width={"100%"}
                                                height={250}
                                                chartType="ColumnChart"
                                                loader={<div>Cargando Gráfica</div>}
                                                data={this.props.ingresos_egresos}
                                                options={{
                                                    chartArea: { width: '70%' },
                                                    bar: { groupWidth: '40%' },
                                                    colors: ['#1B6197', '#FF9437'],
                                                    hAxis: {
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        format: 'Q ###,###,###.00'
                                                    },
                                                    tooltip: {
                                                        format: 'Q ###,###,###.00'
                                                    },
                                                }}
                                                legendToggle
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-4 mt-3">
                                        <Link className="btn btn-secondary  align-self-center" to="/reporte_ingreso">
                                            VER MÁS
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div> */}
                <div className="col-12 px-1 mx-0 mt-3">
                    <CardSimple margen="mt-2" titulo_dashboard="RESUMEN DE VENTAS POR SUCURSAL DE ESTE MES" row>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row col-12 m-0 p-0 d-flex mt-3">
                                <div className="col-md-12 mx-0 px-0">
                                    <div className="w-100">
                                        <div style={{ width: '100%', flex: 1 }}>
                                            <Chart
                                                width={"100%"}
                                                height={250}
                                                chartType="ColumnChart"
                                                loader={<div>Cargando Gráfica</div>}
                                                data={top_vendidos}
                                                options={{
                                                    chartArea: { width: '80%' },
                                                    legend: { position: 'none' },
                                                    bar: { groupWidth: '45%' },
                                                    colors: ['#128085'],
                                                    hAxis: {
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        format: 'Q ###,###,###.00',
                                                        minValue: 0
                                                    },
                                                    tooltip: {
                                                        format: 'Q ###,###,###.00',
                                                    },
                                                }}
                                                legendToggle
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link className="btn btn-azul-claro  align-self-center" to="/reporte_ventas">
                                            Ver mas
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div>
                <div className="col-12 px-1 mx-0 mt-3">
                    <CardSimple margen="mt-2" titulo_dashboard="PRODUCCION DE ESTA SEMANA" row>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row m-0 p-0 d-flex mt-3">
                                <div className="col-md-12 mx-0 px-0">
                                    <div className="">
                                        <div style={{ width: '100%', flex: 1 }}>
                                            <Chart
                                                width={"100%"}
                                                height={250}
                                                chartType="ColumnChart"
                                                loader={<div>Cargando Gráfica</div>}
                                                data={top_producidos}
                                                options={{
                                                    chartArea: { width: '80%' },
                                                    legend: { position: 'none' },
                                                    bar: { groupWidth: '45%' },
                                                    colors: ['#128085'],
                                                    hAxis: {
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        minValue: 0
                                                    },
                                                }}
                                                legendToggle
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link className="btn btn-azul-claro  align-self-center" to="/reporte_productividad">
                                            Ver más
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div>
                <div className="col-12 px-1 mx-0 mt-3">
                    <CardSimple margen="mt-2" titulo_dashboard="EXISTENCIA DE PRODUCTOS" row>
                        <LoadMask loading={loader} light type={"TailSpin"}>
                            <div className="col-md-12 row m-0 p-0 d-flex mt-3">
                                <div className="col-md-12 mx-0 px-0">
                                    <div className="">
                                        <div style={{ width: '100%', flex: 1 }}>
                                            <Chart
                                                width={"100%"}
                                                height={250}
                                                chartType="ColumnChart"
                                                loader={<div>Cargando Gráfica</div>}
                                                data={existencias}
                                                options={{
                                                    chartArea: { width: '80%' },
                                                    legend: { position: 'none' },
                                                    bar: { groupWidth: '45%' },
                                                    colors: ['#128085'],
                                                    hAxis: {
                                                        minValue: 0,
                                                    },
                                                    vAxis: {
                                                        minValue: 0
                                                    },
                                                }}
                                                legendToggle
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link className="btn btn-azul-claro  align-self-center" to="/reporte_existencias">
                                            Ver más
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </CardSimple>
                </div>
            </div>
        )
    }
}
