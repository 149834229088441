import React, { Component, Fragment } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';



import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getSimbolo} from "../../../Utils/renderField/renderTableField";
import CardFormulario from '../../../Utils/Cards/cardFormulario';

import {TIPO_ENTREGA, getsimbolo, TipoTransaccion} from "../../../../../utility/constants";

export default class GastosDetalle extends Component {

    componentDidMount() {
        let id = this.props.match.params.id
        this.props.leer(id)
        this.props.setTitulo("GASTOS")
    }

    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar,cuentas } = this.props;
        let CPid = this.props.match.params.id
        let tipoTransaccion = _.find(TipoTransaccion, x => x.value === item.tipo_documento);
        return (
            <CardFormulario titulo={"GASTOS"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                <div className=" fnt-size">
                        <div className="row m-none d-flex justify-content-end">
                            <div className="col-md-12 text-right inline-headers">
                                <h4 className="m-none t-mostaza semibold">Categoría de gasto: </h4>
                                <span className="m-none t-musgo semibold">{item.tipo_gasto ? item.tipo_gasto.title: null}</span>
                            </div>
                        </div>

                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha: </span><br/>
                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.fecha} ></RenderDateTime></span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 ">
                                <span className="m-none t-mostaza semibold">Proveedor: </span><br/>
                                <span className="m-none t-musgo semibold">{item.proveedor ? item.proveedor.nombre : null}</span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Monto: </span><br/>
                                <span className="m-none t-musgo semibold"><RenderMoneda monto={item.monto} simbolo={getSimbolo(this.props.moneda)} /></span>
                            </div>
                        </div>
                        <br/>{/* DESCRIPCION */}
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Cuenta: </span><br/>
                                <span className="m-none t-musgo semibold">{item.cuenta_origen ? item.cuenta_origen.numero_cuenta: ''}</span>
                            </div>
                            {
                                (item.cuenta_origen && item.cuenta_origen.cuenta_tipo == 1) ? (
                                    <Fragment>
                                        <div className="col-lg-4 col-md-6 col-12 ">
                                            <span className="m-none t-mostaza semibold">Tipo documento: </span><br/>
                                            <span className="m-none t-musgo semibold text-capitalize">{tipoTransaccion.label}</span>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <span className="m-none t-mostaza semibold">No documento: </span><br/>
                                            <span className="m-none t-musgo semibold">{item.no_documento}</span>
                                        </div>
                                        {
                                            (item.banco_depositado) && (
                                                <div className="col-lg-4 col-md-6 col-12 ">
                                                    <span className="m-none t-mostaza semibold">Banco depositado: </span><br/>
                                                    <span className="m-none t-musgo semibold text-capitalize">{item.banco_depositado}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            (item.no_boleta) && (
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <span className="m-none t-mostaza semibold">No. depósito: </span><br/>
                                                    <span className="m-none t-musgo semibold">{item.no_boleta}</span>
                                                </div>
                                            )
                                        }


                                    </Fragment>
                                ): (
                                    <Fragment>
                                        <div className="col-lg-4 col-md-6 col-12 ">
                                            <span className="m-none t-mostaza semibold">Propietario: </span><br/>
                                            <span className="m-none t-musgo semibold">{item.cuenta_origen ? item.cuenta_origen.propietario : ''}</span>
                                        </div>
                                    </Fragment>
                                )
                            }

                        </div>
                        <br/>{/* DESCRIPCION */}
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Cliente: </span><br/>
                                <span className="m-none t-musgo semibold">{item.cliente ? item.cliente.nombre : null}</span>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 ">
                                <span className="m-none t-mostaza semibold">Proveedor: </span><br/>
                                <span className="m-none t-musgo semibold">{item.proveedor ? item.proveedor.nombre : null}</span>
                            </div>
                        </div>
                        <br/>{/* DESCRIPCION */}
                        <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                        <div className="row m-none">
                            <div className="col-12">
                                <span className="m-none t-mostaza semibold">Descripción: </span><br/>
                                <span className="m-none t-musgo semibold">{`${item.descripcion}`}</span>
                            </div>
                        </div>
                        {
                            (!item.estado) && (
                                <Fragment>
                                    <hr style={{borderTop: "2px solid rgb(223, 224, 224)", margin:"0px 15px 10px 15px"}}/>
                                    <div className="row m-none">
                                        <div className="col-12">
                                            <span className="m-none t-mostaza semibold">Descripción Anulación: </span><br/>
                                            <span className="m-none t-musgo semibold">{`${item.descripcion}`}</span>
                                        </div>
                                    </div>
                                </Fragment>

                            )
                        }
                        <div className="row m-none">
                            <div className="col-12 mt-5 color-header-blanco">
                                <h4 className="t-primary">Desglose de gastos</h4>
                                <table className="table table-sm table-responsive-sm m-0">
                                    <thead>
                                    <tr>
                                        <th className="t-mostaza" >Empresa</th>
                                        <th className="t-mostaza text-right" style={{width: "20%"}}>Cantidad</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        item && item.afectados && (
                                            <Fragment>
                                            {item.afectados.map((empresa, index) => {
                                                return(
                                                <tr key={index} >
                                                    <td className="sin-borde-top" style={{width: "48px"}}>
                                                        <span>{empresa.empresa_nombre}</span>
                                                    </td>
                                                    <td className="sin-borde-top text-right"  style={{width: "20%"}}>
                                                        <RenderMoneda monto={empresa.debe} simbolo={getsimbolo(empresa.transaccion.moneda)} />
                                                    </td>
                                                </tr>
                                                );
                                            })}
                                            </Fragment>
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className="col-md-12 m-0 p-0 mt-4 inline-headers text-center text-md-right">
                                    <span className="t-mostaza h5 mr-3"  htmlFor="descripcion">Total:  </span>
                                    <h3 className=" t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={item.monto} simbolo={getsimbolo(item.moneda)} />
                                    }</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadMask>
            </CardFormulario>
        )
    }
}

