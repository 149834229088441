import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
export default class UsuariosGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        if (permisos["usuarios"] === false) {
            if (!!permisos[`ver_usuarios`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <div
                className="Container bg-white mt-4 shadow"
                style={{ borderRadius: "10px" }}
            >
                <br />
                <div className="row justify-content-end">
                    <div className="col-12">
                        <ToolbarSimple
                            textBoton="Agregar"
                            ruta="/usuario/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre, Apellido "}
                            permisos={permisos.usuarios}
                        />
                        {/* <Link className="btn btn-primary" to="/usuario/nuevo">AGREGAR</Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="p-0 px-3">
                                <Grid
                                    hover
                                    page={page}
                                    striped
                                    data={data}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                >
                                    {this.props.permisos.usuarios && (
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataSort
                                            dataFormat={standardActions({
                                                editar: "usuario",
                                                eliminar,
                                            })}
                                        ></TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        isKey
                                        dataField="first_name"
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="last_name"
                                        dataSort
                                    >
                                        APELLIDOS
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="profile"
                                        dataFormat={(cell) =>
                                            cell ? cell.phone : "---"
                                        }
                                        dataSort
                                    >
                                        CONTACTO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="email"
                                        dataSort
                                    >
                                        CORREO
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
