import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { AsyncSelectField } from "../../../Utils/renderField";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteCuentas from "../../../Utils/Toolbar/ToolbarReporteCuentas";
// grid expandible
import DetalleCuenta from "./detallecuenta";

import flechaA from "../../../../../../assets/img/icons/flecha_amarilla.png";
import flechaD from "../../../../../../assets/img/icons/flecha_azul.png";

export default class CuentasPorPoC extends Component {
    state = {
        es_cliente: this.props.es_cliente,
    };
    handleEstado = (value = true) => {
        this.setState({ es_cliente: value });
        this.props.cuentasporCOP(
            {
                clienteproveedor: null,
                vendedor: null,
                estado: 1,
                moneda: "GTQ",
                tipo_cambio_dolar: 0.13,
                tipo_cambio_euro: 0.12,
            },
            value,
            this.props.page
        );
    };
    pageChange = (page) => {
        console.log("Pinche pagina ", page);
        this.props.cuentasporCOP(
            {
                clienteproveedor: null,
                vendedor: null,
                estado: 1,
                moneda: "GTQ",
                tipo_cambio_dolar: 0.13,
                tipo_cambio_euro: 0.12,
            },
            this.state.es_cliente,
            page
        );
    };
    handleDireccion = (direccion) => {
        if (direccion == "/cuentas_por_cobrar") this.handleEstado(true);
        else this.handleEstado(false);
    };
    componentDidMount() {
        // console.log(this.props.match.url)
        this.handleDireccion(this.props.match.url);
    }
    componentDidUpdate(prevProps) {
        // console.log(this.props.match.url)
        if (this.props.match.path != prevProps.match.path) {
            this.handleDireccion(this.props.match.url);
        }
    }
    isExpandableRow(row) {
        if (row.saldo_vigencia.total != 0) return true;
        else return false;
    }
    expandComponent(row, es_cliente) {
        return (
            <DetalleCuenta
                data={row.saldo_vigencia.ordenes}
                moneda={row.moneda}
                es_cliente={es_cliente}
            />
        );
    }
    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = "";

        if (isExpandableRow) {
            content = isExpanded ? flechaA : flechaD;
        } else {
            content = " ";
        }
        return (
            <div>
                <img className="Cuentas-img" src={content}></img>
            </div>
        );
    }
    render() {
        const {
            deudasCoP,
            page,
            data,
            loader,
            open_modal,
            permisos,
            totalizado,
            variables_busqueda,
        } = this.props;
        if (permisos["cuentasp"] === false && permisos["cuentasc"] === false) {
            return <Redirect to={`/`} />;
        }
        return (
            <CardSimple margen="mt-0">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center">
                        <div
                            className="row col-md-9 col-12 m-0  inline-headers text-lg-left"
                            style={{ padding: "15px" }}
                        >
                            <ToolbarReporteCuentas
                                es_cliente={this.state.es_cliente}
                                valorEsetado={{ value: 1, label: "Todos" }}
                                valorMoneda={{ value: "GTQ", label: "GTQ" }}
                                cuentasporCOP={this.props.cuentasporCOP}
                                isClearableCliPro={true}
                                vistaOrdenes={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center mt-3">
                        <div className="col-md-6 col-12 row d-flex justify-content-center align-items-center">
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL PENDIENTE</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h5 className="t-primary">
                                    <RenderMoneda
                                        className="px-0"
                                        monto={totalizado.total}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 row d-flex justify-content-center">
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL VIGENTE</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h5 className="t-verde m-0">
                                    <RenderMoneda
                                        className="px-0"
                                        monto={totalizado.total_vigente}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL POR VENCER</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h5 className="t-mostaza m-0">
                                    <RenderMoneda
                                        className="px-0"
                                        monto={totalizado.total_porvencer}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL VENCIDOS</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h5 className="t-corinto m-0">
                                    <RenderMoneda
                                        className="px-0"
                                        monto={totalizado.total_vencido}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={deudasCoP}
                            loading={loader}
                            page={page}
                            className="reporte-table"
                            onPageChange={this.pageChange}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                width="15%"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    historialOrdenes: `${
                                        this.state.es_cliente
                                            ? "/ordenes_por_cobrar"
                                            : "/ordenes_por_pagar"
                                    }`,
                                    historialCuenta:
                                        "/reporte_deuda_cliente_proveedor",
                                })}
                            >
                                ACCIONES
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre_origen"
                                dataSort
                            >
                                {this.state.es_cliente
                                    ? `CLIENTE`
                                    : `PROVEEDOR`}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo_vigencia"
                                dataAlign="right"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <RenderMoneda
                                        monto={Math.abs(cell.total)}
                                        simbolo={getsimbolo(row.moneda)}
                                    />
                                )}
                            >
                                TOTAL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo_vigencia"
                                dataAlign="right"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <RenderMoneda
                                        monto={cell.abonado}
                                        simbolo={getsimbolo(row.moneda)}
                                    />
                                )}
                            >
                                ABONO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo_vigencia"
                                dataAlign="right"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <span
                                        style={{
                                            color: "#1B6197",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <RenderMoneda
                                            monto={cell.pendiente}
                                            simbolo={getsimbolo(row.moneda)}
                                        />
                                    </span>
                                )}
                            >
                                SALDO PENDIENTE
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
