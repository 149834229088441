import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";

import RecetaForm from "./recetaform";
import { Redirect } from "react-router";

class ReporteNuevo extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        values: {},
        estadoRead: false,
    };
    componentWillMount() {}
    onSubmit = (values) => {
        const { editRecetaProducto, produccion, empresa } = this.props;
        this.props.history.push("/reportar_produccion/" + empresa);
        editRecetaProducto(values, produccion, empresa);
    };

    render() {
        const { loader, empresas, itemov, empresa } = this.props;
        // let empresa = this.props.match.params.empresa;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br />
                    <RecetaForm
                        onSubmit={this.onSubmit}
                        estadoRead={this.state.estadoRead}
                        produccion ={this.props.produccion}
                        empresa={empresa}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default ReporteNuevo;
