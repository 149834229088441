import React, { Component } from "react";
import DespachoGrid from "./despachogrid";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";

import HistoriaDespachosGrid from "./historydespacho";

export default class DespachoContainer extends Component {
    state = {
        open_modal: false,
    };
    setOpenModal = (val) => {
        this.setState({
            open_modal: val,
        });

        if (val) {
            let data = { orden: this.props.orden.id };
            this.props.HistoryDispatch(data);
        }
    };
    setDespachar = () => {
        this.props.AutorizarDespacho(this.props.orden.id);
    };
    render() {
        const { cuentas, orden } = this.props;
        return (
            <div>
                <Modal
                    open={this.state.open_modal}
                    onClose={(e) => {
                        this.setOpenModal(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Historial de despachos
                        </span>
                    </div>
                    <HistoriaDespachosGrid data={this.props.data} />
                </Modal>
                <DespachoGrid detalle_orden={orden.detalle_orden} />
                {/* {orden.entregado && ( */}
                    <div className="row  justify-content-sm-start justify-content-start">
                        <div className="col-12">
                            {/* <Link
                                className="btn btn-azul-claro m-1 align-self-center"
                                to={`/ingreso/ordencompra/${orden.id}`}
                            >
                                Ingresar
                            </Link> */}
                            <button
                                className="btn btn-mostaza m-1 align-self-center"
                                onClick={(e) => {
                                    this.setOpenModal(true);
                                }}
                            >
                                Historial
                            </button>
                            {/* <button className="btn btn-mostaza m-1 align-self-center">MARCAR DESPACHADA</button> */}
                        </div>
                    </div>
                {/* )} */}
            </div>
        );
    }
}
