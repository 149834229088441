import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';

import {AsyncSelectField , renderCurrency, renderField, renderTextArea, renderFieldRadio,  renderDropDownField } from '../../../Utils/renderField';
import {  Monedas, TipoTransaccion } from '../../../../../utility/constants';
import {renderSelectField, SelectField} from '../../../Utils/renderField/renderField.js'
import {RenderMoneda} from "../../../Utils/renderField/renderTableField";

import {api} from 'api';

const getCaja = (search) => {
    return api.get("cuentas", { search, cob:'1', origen__tipo: 10 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


let TransaccionForm = (props) => {
    const [tcambio, setTcambio]=useState(1);
    const [monto, setMonto] = useState(0);

    const { handleSubmit, empresas, cuentas,
        handleCuentas, noCuenta, meCuenta,
        Cambio, simbolo, getSimbolo,
        meSimbolo,
        egreso_tipo,
        tipo_documento,
        valorMes,
        valorAnio,
        es_flotante_inverso
    } = props;
    let dia_hoy= new Date().getDate();
    let fecha_inical = new Date(Number(valorAnio), Number(valorMes - 1), dia_hoy)
    return (

        <form name="TransaccionForm" className="form-validate mb-lg mt-0" onSubmit={handleSubmit}>
            {
                (!props.ingreso) ? (
                    <div className="row col-md-10 offset-md-1">
                        <div style={{border: "1px solid #e8e8e8", padding:"10px"}} className="col-md-12 justify-content-start">
                            <div className="d-flex justify-content-start col-12">
                                <h4 className="t-primary align-self-start">Seleccione tipo de transacción</h4>
                            </div>
                            <div className="row d-flex justify-content-start col-12">
                                <div className={`form-group has-feedback col-md-5 col-sm-6 col-12`}>
                                    <Field
                                        value={"false"}
                                        name="egreso_tipo"
                                        label="Transacción a otra Cuenta"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback  col-md-3 col-sm-6 col-12`}>
                                    <Field
                                        name="egreso_tipo"
                                        label="Retiro"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control" />
                                </div>
                                {
                                    (props.principal) &&(
                                        <div className={`form-group has-feedback col-md-4 col-sm-6 col-12`}>
                                            <Field
                                                value={"1"}
                                                name="egreso_tipo"
                                                label="Transacción a Caja"
                                                component={renderFieldRadio}
                                                type="radio"
                                                className="form-control" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row col-md-10 offset-md-1">
                        <div style={{border: "1px solid #e8e8e8", padding:"10px"}} className="col-md-12 justify-content-start">
                            <div className="d-flex justify-content-start">
                                <h4 className="t-primary align-self-center">Seleccione tipo de ingreso</h4>
                            </div>
                            <div className=" d-flex justify-content-start">
                                <div className={`form-group has-feedback col-md-4 col-sm-6 col-12`}>
                                    <Field
                                        value={"false"}
                                        name="flotante_inverso"
                                        label="Ingreso"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control" />
                                </div>
                                <div className={`form-group has-feedback  col-md-4 col-sm-6 col-12`}>
                                    <Field
                                        name="flotante_inverso"
                                        label="Flotante inverso"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <br/>
            <div className="row col-lg-10 offset-lg-1">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="fecha_fundacion">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={fecha_inical}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropDownField}
                    />

                </div>
                <div className={`form-group has-feedback  col-md-6 col-12`}>
                    <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                    <Field
                        name="tipo_documento"
                        label="tipo_documento"
                        labelKey="label" valueKey="value"
                        component={renderSelectField}
                        options={TipoTransaccion}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row col-lg-10 offset-lg-1">
                <div className={`form-group has-feedback  col-md-6  col-12`}>
                    <label className="t-azul"  htmlFor="no_documento">{tipo_documento}</label>
                    <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                </div>
                <div className={`form-group has-feedback col-md-6 col-12`}>
                    <label className="t-azul"  htmlFor="monto">Monto</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={getSimbolo(meSimbolo)}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        _onChange={(e)=>{
                            setMonto(e)
                        }}
                        className="form-control"
                    />
                </div>
            </div>
            {
                 (props.ingreso &&  es_flotante_inverso) && (
                    <div className="row col-lg-10 offset-lg-1">
                        <div className={`form-group has-feedback  ${props.ingreso? 'col-12' : 'col-md-6'}   col-12`}>
                            <label className="t-azul"  htmlFor="lugar_transaccion">Lugar transacción:</label>
                            <Field name="lugar_transaccion" label="lugar_transaccion" component={renderField} type="text" className="form-control" />
                        </div>
                        <div className={`form-group has-feedback  ${props.ingreso? 'col-12' : 'col-md-6'}   col-12`}>
                        </div>
                    </div>
                 )
            }


            {
                (egreso_tipo == 'false') && (
                    <div className="row col-lg-10 offset-lg-1">
{/*                          <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="empresa">Empresa</label>
                            <Field
                                name="empresa"
                                label="empresa"
                                labelKey="nombre" valueKey="id"
                                component={renderSelectField}
                                options={empresas}
                                onChange={(e)=>handleCuentas(e.target.value)}
                                className="form-control"
                            />
                        </div> */}
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="cuenta_destino">No. de Cuenta</label>
                            <Field
                                name="cuenta_destino"
                                label="cuenta_destino"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                onChange={(e)=>meCuenta(e.target.value, false)}
                                className="form-control"
                            />
                        </div>

                    </div>
                )
            }

            <div className="row col-lg-10 offset-lg-1">
                {
                    (egreso_tipo == '1') && (
                        // <div className="row justify-content-center">
                        // </div>
                        <div className={`form-group has-feedback  col-md-6 col-12`}>
                            <label className="t-azul" htmlFor="empresa">Caja</label>
                            <Field
                                name="caja"
                                label="caja"
                                labelKey="nombre_dueno" valueKey="id"
                                component={AsyncSelectField}
                                type="text"
                                loadOptions={getCaja}
                                // onChange={(e)=>handleCuentas(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    )
                }
                {(Cambio && (egreso_tipo == 'false')) && (
                    <div className={`form-group has-feedback  col-md-6  col-12 `}>
                        <label className="t-azul"  htmlFor="tipo_cambio">Tipo de cambio</label>
                        <Field
                            name="tipo_cambio"
                            label="tipo_cambio"
                            prefix={getSimbolo(meSimbolo)}
                            component={renderCurrency}
                            parse={cell => parseFloat(cell)}
                            _onChange={(e)=>setTcambio(e)}
                            className="form-control"
                        />
                    </div>
                )}

            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-12"></div>
                <div className={`form-group has-feedback col-sm-6 col-12 d-flex justify-content-between align-items-end ${ Cambio && (egreso_tipo == 'false') ? '':'d-none'}`}>
                    <h6 className="t-primary"  htmlFor="descripcion">Total.     </h6>
                    <h3 className="t-mostaza"  htmlFor="descripcion">{

                        <RenderMoneda monto={props.validCambio(monto, tcambio)} simbolo={getSimbolo(simbolo)} />
                    }</h3>
                </div>
            </div>
            <div className="row justify-content-center col-lg-10 offset-lg-1">
                <div className="form-group has-feedback  col-12">
                    <label className="t-azul"  htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        top={{top:"95px"}}
                        className="form-control"
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-center justify-content-center">
                <button type="button" className="btn btn-secondary ml-md-4  m-1 align-self-center" onClick={props.cancelar}>Cancelar</button>
                <button type="submit" className="btn btn-azul-claro ml-md-4  m-1 align-self-center">Registrar</button>
            </div>
        </form>
    );
};


TransaccionForm = reduxForm({
    form: 'transaccionesForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors={}
        if(!data.tipo_cambio){
            errors.tipo_cambio = "Este campo es requerido"
        }
        if(!data.tipo_documento){
            errors.tipo_documento = "Este campo es requerido"
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido"
        } else if(data.monto <=0 ){
            errors.monto = "El monto debe ser mayor a 0"
        }
        if(!data.caja){
            errors.caja = "Este campo es requerido"
        }
        if(!data.descripcion){
            errors.descripcion = "Este campo es requerido"
        }
        if(!data.cuenta_destino){
            errors.cuenta_destino = "Este campo es requerido"
        }
        if(!data.fecha){
            errors.fecha = "Este campo es requerido"
        }
        return errors;
    },
})(TransaccionForm);

const selector = formValueSelector('transaccionesForm');
TransaccionForm = connect(state => {
    const egreso_tipo = selector(state, 'egreso_tipo');
    const tipo_movimiento = selector(state, 'tipo_documento');
    let tipo_documento = 'Documento de Registro';
    const flotante_inverso = selector(state, 'flotante_inverso');
    let es_flotante_inverso = false;
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda

    }
    if(flotante_inverso == 'true'){
        es_flotante_inverso = true
    }
    return {
        egreso_tipo,
        tipo_documento,
        es_flotante_inverso
    }
})(TransaccionForm)

export default TransaccionForm;
