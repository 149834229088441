import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import { Link } from "react-router-dom";
import {
    RenderMoneda,
    RenderDateTime,
    RenderDateTimeAll,
} from "../../../Utils/renderField/renderTableField";
import Grid from "../../../Utils/Grid/index";

export default class DetalleHistoricoCajas extends Component {
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.leer(id);
    }

    render() {
        const { loader, item } = this.props;
        return (
            <CardSimple margen="mt-2">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br />
                    {item && (
                        <div className="col-12 row px-0 mx-0">
                            <div className="col-12 row px-0 mx-0">
                                <div className="col-4">
                                    <label className="t-primary">
                                        USUARIO:
                                    </label>
                                    <br/>
                                    <label className="t-texto">
                                        {item.vendedor ? item.vendedor : "Sin registro"}
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label className="t-primary">
                                        SUCURSAL:
                                    </label><br/>
                                    <label className="t-texto">
                                        {item.nombre_sucursal ? item.nombre_sucursal : "Sin registro"}
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label className="t-primary"> CAJA: </label>
                                    <br/>
                                    <label className="t-texto">
                                        {item.nombre_caja ? item.nombre_caja : "Sin registro"}
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 row px-0 mx-0 mt-4">
                                <div className="col-4">
                                    <label className="t-primary">
                                        FECHA APERTURA:
                                    </label>
                                    <br/>
                                    <label className="t-texto">
                                        <RenderDateTime fecha = {item.fecha_apertura} />
                                    </label>
                                </div>
                                <div className="col-4">
                                    <label className="t-primary">
                                        FECHA CIERRE:
                                    </label>
                                    <br/>
                                    <label className="t-texto">
                                        <RenderDateTime fecha = {item.fecha_cierre} />
                                    </label>
                                </div>
                                <br />
                            </div>
                            <br />
                            <div className="col-12 row px-0 mx-0 py-3">
                                <div className="react-bs-table react-bs-table-bordered">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="20%"></th>
                                                    <th className="py-2">
                                                        DESCRIPCION
                                                    </th>
                                                    <th className="py-2">
                                                        SUBTOTAL
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td className="py-2">
                                                        Apertura
                                                    </td>
                                                    <td className="py-2">
                                                        <RenderMoneda
                                                            monto={
                                                                item.apertura
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <td className="py-2">
                                                        Ventas en efectivo
                                                    </td>
                                                    <td className="py-2">
                                                        <RenderMoneda
                                                            monto={
                                                                item.ventasEfectivo
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <td className="py-2">
                                                        Ventas tarjeta de
                                                        credito
                                                    </td>
                                                    <td className="py-2">
                                                        <RenderMoneda
                                                            monto={
                                                                item.ventasTarjeta
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <td className="py-2">
                                                        Ingresos en efectivo
                                                    </td>
                                                    <td className="py-2">
                                                        <RenderMoneda
                                                            monto={
                                                                item.totalAgregado
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> </td>
                                                    <td className="py-2">
                                                        Egresos en efectivo
                                                    </td>
                                                    <td className="py-2">
                                                        <RenderMoneda
                                                            monto={
                                                                item.totalRetirado
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="col-12 row mx-0 px-0 py-1 my-3">
                                <div
                                    className="col-12 row mx-0 px-0"
                                    style={{
                                        backgroundColor: "#828FFB",
                                        borderRadius: "5px 5px 0px 0px",
                                    }}
                                >
                                    <div className="col-12 row">
                                        <div
                                            className="col-8 t-blanco"
                                            align="right"
                                        >
                                            TOTAL
                                        </div>
                                        <div className="col-4 t-blanco">
                                            <RenderMoneda
                                                monto={item.totalEfectivoCaja}
                                                className="pl-0 t-blanco"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            {/* <label className="t-primary"> Detalle </label>
                            <hr
                                style={{
                                    background: "#408EAA",
                                    height: "2px",
                                    width: "100%",
                                    marginTop: "0px",
                                }}
                            /> */}
                            {/*item.detalle && (
                                <Grid
                                    hover
                                    striped
                                    data={{
                                        count: item.detalle.length,
                                        results: item.detalle,
                                    }}
                                    loading={loader}
                                    className="reporte-table"
                                    pagination={false}
                                    onPageChange={null}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        isKey
                                        hidden
                                        dataSort
                                        dataFormat={(cell) => (cell ? "" : "")}
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha_hora"
                                        dataSort
                                        dataFormat={(cell, row) => (
                                            <RenderDateTimeAll fecha={cell} />
                                        )}
                                    >
                                        FECHA Y HORA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="descripcion"
                                        dataSort
                                        dataFormat={(cell, row) =>
                                            cell ? `${cell} ` : "-------"
                                        }
                                    >
                                        DESCRIPCION
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="es_ingreso"
                                        dataSort
                                        dataFormat={(cell, row) =>
                                            cell ? "Ingreso" : "Egreso"
                                        }
                                    >
                                        MOVIMIENTO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="debe"
                                        dataSort
                                        dataFormat={(cell, row) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                    >
                                        DEBE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="haber"
                                        dataSort
                                        dataFormat={(cell, row) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                    >
                                        HABER
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="saldo"
                                        dataSort
                                        dataFormat={(cell, row) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                    >
                                        SALDO
                                    </TableHeaderColumn>
                                </Grid>
                                        )*/}
                            <div className="col-12" align="center">
                                <br />
                                <Link
                                    to="/reporte_historico_cajas"
                                    className="btn btn-secondary"
                                    type="button"
                                >
                                    Regresar
                                </Link>
                            </div>
                        </div>
                    )}
                </LoadMask>
            </CardSimple>
        );
    }
}
