import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import DatosClienteForm from "./datosCliente/formDatosCliente";
import ProductosForm from "./productos/formProductos";
import Carrito from "./carrito/carrito";
import PreviewFactura from "./previewFactura/previewFactura";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { renderField } from "../../../Utils/renderField";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import Swal from "sweetalert2";

export default class MiTienda extends Component {
    state = {
        mensaje: "",
    };
    setMensaje = (mensaje) => {
        this.setState({ mensaje: mensaje });
    };
    cancelarVenta = () => {
        if (this.props.carrito && this.props.carrito.detalle_carrito.length) {
            Swal.fire({
                title: "¿Desea cancelar la venta?",
                text: "¡Se borrara todo lo que ah agregado recientemente!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, borrar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.cancelarVenta();
                }
            });
        } else {
            this.setMensaje("No se puede cancelar si no ha agregado nada");
            setTimeout(() => {
                this.setMensaje("");
            }, 3000);
        }
    };
    FinalizarCompra = (value) => {
        if (this.props.carrito && this.props.carrito.detalle_carrito.length) {
            this.props.FinalizarCompra(value);
        } else {
            this.setMensaje(
                "No se puede finalizar una compra si no ha agregado nada"
            );
            setTimeout(() => {
                this.setMensaje("");
            }, 3000);
        }
    };
    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
        } = this.props;
        return (
            <div className="Container row my-3 mx-1">
                <div
                    className=" bg-white shadow col-12 col-lg-7"
                    style={{ borderRadius: "10px" }}
                >
                    {this.props.compra ? (
                        <DatosClienteForm
                            FinalizarCompra={this.props.FinalizarCompra}
                            onSubmit={this.props.FacturarCompra}
                            carrito={this.props.carrito}
                            ticket={this.props.ticket}
                        />
                    ) : (
                        <ProductosForm
                            agregarACarrito={this.props.agregarACarrito}
                            bodega={this.props.caja.bodega}
                            getInventario={this.props.getInventario}
                            productos={this.props.data}
                            searchChange={this.props.searchChange}
                            search={this.props.search}
                            page={this.props.page}
                            onPageChange={this.props.onPageChange}
                            loaderProductos={this.props.loaderProductos}
                        />
                    )}
                    <br />
                </div>
                <div
                    className="bg-white shadow  col-12 col-lg-5 ml-1"
                    style={{
                        borderRadius: "10px",
                        marginRight: "-1em",
                    }}
                >
                    <div className="col-12 row pb-5 mx-0">
                        {this.props.compra ? (
                            <PreviewFactura {...this.props} />
                        ) : (
                            <Carrito
                                carrito={this.props.carrito}
                                eliminarDeCarrito={this.props.eliminarDeCarrito}
                                agregarACarrito={this.props.agregarACarrito}
                                cancelarVenta={this.cancelarVenta}
                                FinalizarCompra={this.FinalizarCompra}
                                mensaje={this.state.mensaje}
                                changePresentacion = {this.props.changePresentacion}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
