import React, { Component } from 'react';
import './Fcm.css';
import NotificationSystem from 'react-notification-system'
import Modal from 'react-responsive-modal';
import ImageNotification from '../../../../assets/img/icons/fcm.png';

const _notificationSystem = null;
class Fcm extends Component {
  constructor(props) {
    super(props);
    this.obtenerPermiso = this.obtenerPermiso.bind(this);
    this.verificarToken = this.verificarToken.bind(this);
    this._addNotification = this._addNotification.bind(this);
    this.state = {
      next: true,
      open: false,
      mensaje: '',
    };
  }

  componentWillMount(){
    // inicia el servicio de mensajeria de firebase
    this.props.initializeFirebase()
  }
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  obtenerPermiso(messaging){
    messaging.requestPermission()
      .then(() => {
        this.verificarToken(messaging);
      })
      .catch((err) => {
        // variable que oculta la modal
        this.props.setHideAlert(true);
      });
  }
  verificarToken = (messaging) =>{
    messaging.getToken()
      .then((currentToken) => {
        if (currentToken) {
          // Si tiene token lo agrega a la BD
          // console.log('tiene Token',currentToken)
          this.props.newToken(currentToken);
        } else {
          // No tiene token abra la modal
          this.onOpenModal();
          this.props.setHideAlert(true);
        }
      })
      .catch(function(err) {
        console.log('Ocurrio un error al obtener el token, error: ', err);
      });
  }

   onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

   _addNotification = (notification) => {
    this._notificationSystem.addNotification({
      children: (
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
            <img className="icono" src={ImageNotification} />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
              <p className="font-weight-bold" style={{color:'white'}} >{notification.title}</p>
              <p  style={{color:'white'}} >{notification.body}</p>
          </div>

        </div>
      ),
      level: 'info',
      position:'br',
      dismissible:true,
      autoDismiss:0,
      action: {
        label: 'Ir al sitio',
        callback: function() {
          console.log('Notification button clicked!');
        }
      }
    });
  }


  render() {
    const { hasToken, hideAlert, messaging } = this.props;
    const { setHideAlert} = this.props;
    const { open } = this.state;
    if(messaging.getToken !== undefined && !hideAlert)
    {
      this.verificarToken(messaging);
    }
    if (messaging.onMessage !== undefined){
      messaging.onMessage((payload) => {
        if (payload.notification.body !== this.state.mensaje){
          this._addNotification(payload.notification);
          this.setState({mensaje: payload.notification.body});
        }
    });
    }

    return (
      <div>
        <NotificationSystem ref="notificationSystem" />
        {/*Modal*/}
        <Modal open={open} onClose={this.onCloseModal} little>
          {(this.state.next) && (<div className={"modal-container"}>

            <div className="row">
              <div className={`col-lg-4 col-md-6 col-sm-8 offset-lg-4 offset-md-3 offset-sm-2 text-center`}>
                <div className="panel-body">
                  <img src={require("../../../../assets/img/alertas/notificacion1fcm.png")} alt="Prenda Cŕedito Avanza"/>
                </div>
              </div>
            </div>
            <div className="text-center txt">
              <span className="reset-caption">El sistema necesita enviarte notificaciones para mantenerte actualizado.</span><br/>
              <span className="reset-caption">¿Deseas recibir estas notificaciones?</span>
            </div>
            <br/>
            <div className="text-center botones">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.onCloseModal();
                }}
                className="btn btn-secondary mt-lg later"
                type={'submit'}
              >
                Mas tarde
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({next: false});
                }}
                className="btn btn-azul-claro mt-lg"
                type={'submit'}
              >
                Aceptar
              </button>
            </div>
          </div>)}
          {(!this.state.next) && (<div className={"modal-container"}>
            <div className="row">
              <div className={`col-lg-6 col-md-6 col-sm-8 offset-lg-3 offset-md-3 offset-sm-2 text-center`}>
                <div className="panel-body">
                  <img className="alert-permitir" src={require("../../../../assets/img/alert-permitir.png")} alt="Genesis"/>
                </div>
              </div>
            </div>
            <div className="text-center txt">
              <span className="reset-caption">Te aparecera un mensaje como el de la imágen</span><br/>
              <span className="reset-caption">Es necesario que le des en permitir notificaciones</span>
            </div>
            <br/>
            <div className="text-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.onCloseModal();
                  this.obtenerPermiso(messaging);
                }}
                className="btn btn-azul-claro mt-lg botones"
                type={'submit'}
              >
                Aceptar
              </button>
            </div>
          </div>)}
        </Modal>
        {/*End-Modal*/}
      </div>
    );
  }
}
Fcm.propTypes = {
};

export default Fcm;

