import React, { Component } from 'react'

import PagoCredito from './pagosCredito';


export default class pagocContainer extends Component {
    componentWillMount(){
        const { moneda, item } = this.props;
        this.props.selectCuentas(item.empresa, moneda)

    }
    render() {
        const { cuentas } = this.props;
        return (
            <div>
                {
                    <PagoCredito
                        {...this.props}
                        esCobro={true}
                    />
                }
            </div>
        )
    }
}
