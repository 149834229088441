import React, { Component, Fragment } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormularioOrden";

// formularios
import PeticionesVForm from "./PVform";
import PagoOCForm from "./pagoOCForm";
import EntregaOCForm from "./entregaOCForm";

class PeticionForm extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        texto_descripcion: "Datos Petición de Venta",
        step: 1,
        justificacion: false,
        mostrar_precios: false,
        ordenes_venta: false,
        limite_credito: null,
    };
    componentDidMount() {
        // this.props.selectEmpresas();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id;
            this.setState({
                editar: true,
                mostrar_precios: true,
                ordenes_venta: false,
            });
            this.props.leer(id);

            console.log("props: ", this.props.match.path);
            if (this.props.match.path == "/ordenesventa/:id") {
                this.setState({
                    texto_descripcion: "Datos de Venta",
                    ordenes_venta: true,
                });
            }
        } else {
            if (this.props.match.path == "/ordenesventa/:id") {
                this.setState({ ordenes_venta: true });
            }
            this.props.clear();
        }
    }
    handleSubmit = (values) => {
        //id de la cuenta a la que pertenece la nueva transaccion
        let id = this.props.match.params.id;
        let dataForm = _.cloneDeep(values);
        dataForm.tipo_orden = 6;
        dataForm.direccion = dataForm.direccion.value;
        const { crear, editar } = this.props;
        let ruta_regreso = null;
        if (this.state.ordenes_venta) {
            ruta_regreso = "/ordenesventa";
        }
        this.state.editar
            ? editar(id, dataForm, ruta_regreso)
            : crear(dataForm);
    };
    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };

    previousStep = () => {
        this.setState({ step: this.state.step - 1 });
    };
    setLimiteCredito = (value) => {
        this.setState({ limite_credito: value });
    };
    render() {
        const { loader, empresas, item } = this.props;
        const { step } = this.state;
        return (
            <CardFormulario step={this.state.step}>
                <LoadMask loading={loader} light>
                    <Fragment>
                        {item.justificacion && item.justificacion.length > 0 && (
                            <div className="row justify-content-center text-center">
                                <div
                                    className="col-12 col-md-10"
                                    style={{ backgroundColor: "#e8e8e8" }}
                                >
                                    <br />
                                    <h6 className="t-mostaza text-uppercase">
                                        Justificacion de rechazo:{" "}
                                    </h6>
                                    <p
                                        style={{ fontWeight: 600 }}
                                        className="t-primary"
                                    >
                                        {
                                            item.justificacion[
                                                item.justificacion.length - 1
                                            ].justificacion
                                        }
                                    </p>
                                </div>
                            </div>
                        )}
                        <br />
                        {step === 1 && (
                            <PeticionesVForm
                                mostrar_precios={true}
                                onSubmit={this.handleSubmit}
                                empresas={empresas}
                                onSubmit={this.nextStep}
                                texto_descripcion={this.state.texto_descripcion}
                                {...this.props}
                                initialValues={{
                                    pago_inmediato: "true",
                                    tipo_cuenta: "true",
                                    fecha: new Date(),
                                    fecha_prox_entrega: new Date(),
                                    detalle_orden: [{ cantidad: 1 }],
                                    dias_al_credito: 0,
                                }}
                                setNits={this.props.setNits}
                                credito={this.state.limite_credito}
                                setLimiteCredito={this.setLimiteCredito}
                            />
                        )}
                        {step === 2 && (
                            <PagoOCForm
                                role={this.props.role}
                                previousStep={this.previousStep}
                                onSubmit={this.nextStep}
                                setDias_de_credito={
                                    this.props.setDias_de_credito
                                }
                                setNombre_nit={this.props.setNombre_nit}
                                credito={this.state.limite_credito}
                            />
                        )}
                        {step === 3 && (
                            <EntregaOCForm
                                onSubmit={this.handleSubmit}
                                previousStep={this.previousStep}
                                initialValues={{
                                    fecha_prox_entrega: new Date(),
                                }}
                            />
                        )}
                    </Fragment>
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default PeticionForm;
