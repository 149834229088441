import React from "react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";

import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderCurrency,
    renderTextArea,
    AsyncSelectField,
    renderSelectField,
} from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import {
    renderDatePicker,
    renderAntTimeField,
} from "../../../Utils/renderField/renderField";
import { api } from "../../../../../utility/api";
import { Component } from "react";

let tipoproducto = [];

class renderBonficacionPuesto extends Component {

    state = {
        detalle: []
    }

    getDetalle = (producto,index) => {
        let params = {};
        params.id = producto.id
        params.empresa = this.props.empresa;
        return api
            .get("produccion/getRetriveRecipeHB", params)
            .then((data) => {
                if (data) {
                    let detalle = this.state.detalle;
                    detalle[index] = data
                    this.setState({detalle})
                }
            })
            .catch((e) => {
                console.log(e)
            });
    }

    borrarProducto = (index) => {
        let detalle = this.state.detalle;
        detalle.splice(index, 1);
        this.setState({detalle})
    }

    render() {
        const {
            fields,
            meta: { error, submitFailed },
            getProductos,
        } = this.props;
        return (
            <div className=" col-sm-12 p-0">
                <div className=" col-sm-12 form-group np-r p-0">
                    <div className=" table-header-wrapper">
                        <table className="table table-striped table-highlight mb-0">
                            <thead>
                                <tr>
                                    <th style={{ width: "5%" }}></th>
                                    <th style={{ width: "25%" }}>PRODUCTO</th>
                                    <th style={{ width: "14%" }}>INVENTARIO</th>
                                    <th style={{ width: "14%" }}>DISPONIBLE</th>
                                    <th style={{ width: "14%" }}>OTRAS OP</th>
                                    {this.props.es_sobre_orden &&
                                    <th style={{ width: "14%" }}>
                                        REQUERIDO OV
                                    </th>
                                    }
                                    <th style={{ width: "14%" }}>EN ESTA OP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((PET, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            className="align-items-start"
                                        >
                                            <td
                                                className="text-center sin-borde-top"
                                                style={{ width: "5%" }}
                                            >
                                                <img
                                                    className="action-img iconoEliminarTabla iconoEliminar"
                                                    style={{ padding: "15%" }}
                                                    src={require("assets/img/icons/Cerrar.png")}
                                                    alt="Borrar"
                                                    title="Borrar"
                                                    onClick={() => {
                                                        fields.remove(index);
                                                        this.borrarProducto(index);
                                                    }}
                                                />
                                            </td>
                                            <td
                                                className="sin-borde-top "
                                                style={{ width: "25%" }}
                                            >
                                                <Field
                                                    withRef
                                                    // key={`${producto}-${simbolo}-${proveedor}`}
                                                    id={`${PET}.selectProducto`}
                                                    name={`${PET}.producto`}
                                                    label="nombre"
                                                    component={AsyncSelectField}
                                                    loadOptions={(search) =>
                                                        getProductos(search)
                                                    }
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    type="text"
                                                    valueKey="id"
                                                    labelKey="nombre"
                                                    onChange={(e) => {
                                                        this.getDetalle(e, index)
                                                    }}
                                                    className="form-control"
                                                />
                                            </td>
                                            <td
                                                className="sin-borde-top "
                                                style={{ width: "14%" }}
                                            >
                                                <Field
                                                    name={`${PET}.inventario`}
                                                    type="number"
                                                    component={renderField}
                                                    val = {this.state.detalle && this.state.detalle[index] && this.state.detalle[index].inventario}
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    readOnly
                                                />
                                            </td>
                                            <td
                                                className="sin-borde-top "
                                                style={{ width: "14%" }}
                                            >
                                                <Field
                                                    name={`${PET}.disponible`}
                                                    type="number"
                                                    component={renderField}
                                                    val = {this.state.detalle && this.state.detalle[index] && this.state.detalle[index].disponible}
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    readOnly
                                                />
                                            </td>
                                            <td
                                                className="sin-borde-top "
                                                style={{ width: "14%" }}
                                            >
                                                <Field
                                                    name={`${PET}.otras_OP`}
                                                    type="number"
                                                    component={renderField}
                                                    val = {this.state.detalle && this.state.detalle[index] && this.state.detalle[index].otras_OP}
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    readOnly
                                                />
                                            </td>
                                            {this.props.es_sobre_orden &&
                                                <td
                                                    className="sin-borde-top "
                                                    style={{ width: "14%" }}
                                                >
                                                    <Field
                                                        name={`${PET}.requerido`}
                                                        type="number"
                                                        component={renderField}
                                                        top={{
                                                            top: "67px",
                                                            position: "inherit",
                                                        }}
                                                        readOnly
                                                    />
                                                </td>
                                            }
                                            <td
                                                className="sin-borde-top "
                                                style={{ width: "14%" }}
                                            >
                                                <Field
                                                    name={`${PET}.cantidad`}
                                                    type="number"
                                                    component={renderField}
                                                    min={1}
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                        <button
                            type="button"
                            className="btn btn-primary py-0"
                            onClick={() => fields.push({})}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
                {submitFailed && error && (
                    <div className="invalid-feedback-array text-danger">
                        {error}
                    </div>
                )}
            </div>
        );
    }
}

const renderDetalleOV = ({ fields, meta: { error, submitFailed } }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="table-header-wrapper">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }} />
                            <th style={{ width: "50%" }}>PRODUCTO</th>
                            <th style={{ width: "40%" }}>CANTIDAD</th>
                            <th style={{ width: "5%" }} />
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((detalle, index) => {
                            return (
                                <tr key={index} className="align-items-start">
                                    <td />
                                    <td className="sin-borde-top ">
                                        <Field
                                            name={`${detalle}.producto.nombre`}
                                            type="text"
                                            component={renderField}
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                            readOnly
                                            clase="field-clear"
                                        />
                                    </td>
                                    <td className="sin-borde-top ">
                                        <Field
                                            name={`${detalle}.cantidad`}
                                            type="number"
                                            component={renderField}
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                            readOnly
                                            clase="field-clear"
                                        />
                                    </td>
                                    <td />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        {/* {
            (submitFailed || error) && (
                <div className="invalid-feedback-array text-danger">
                    {error}
                </div>
            )
        } */}
    </div>
);

class ProduccionForm extends React.Component {
    state = {
        orden: null,
    };
    getProductos = (search) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        params.tiene_receta = true;
        params.empresa__entidad = this.props.empresa;
        return api
            .get("productos", params)
            .then((data) => {
                if (data) {
                    return data.results;
                }
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getOrdenes = (search) => {
        return api
            .get("ordenventa", { search, entregado: false, a_produccion: true })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    getOrdenesSI = (search) => {
        return api
            .get("ventainterna", { search, entregado: false })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const {
            handleSubmit,
            empresas,
            estadoRead,
            empresa,
            tipo_pro,
        } = this.props;
        return (
            <form
                name="ProduccionForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row justify-content-center">
                    {/* <div className="form-group has-feedback col-lg-6 col-md-6 col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Empresa*
                        </label>
                        <Field
                            name="empresa"
                            label="empresa"
                            labelKey="nombre"
                            valueKey="id"
                            component={renderSelectField}
                            options={empresas}
                            onChange={(e) => {}}
                            className="form-control"
                            readOnly={estadoRead}
                            disabled={estadoRead}
                        />
                    </div> */}
                    {this.props.bandera_orden ? (
                        <div className="row col-12">
                            <div className="form-group has-feedback col-md-6 col-12 d-flex justify-content-end pr-4">
                                <div style={{ width: "295px" }}>
                                    <label className="t-azul" htmlFor="nombre">
                                        {tipo_pro == "SI"
                                            ? "Solicitud Interna"
                                            : "Orden de Venta"}
                                    </label>
                                    <Field
                                        name={`orden`}
                                        component={AsyncSelectField}
                                        loadOptions={
                                            tipo_pro == "SI"
                                                ? this.getOrdenesSI
                                                : this.getOrdenes
                                        }
                                        isClearable={true}
                                        type="text"
                                        filterOptions={false}
                                        valueKey="id"
                                        labelKey="no_orden"
                                        className="form-control"
                                        changePersonalizado={(e) => {
                                            this.setState({ orden: e });
                                            tipo_pro == "SI"
                                                ? this.props.leersi(e.id)
                                                : this.props.leerov(e.id);
                                            this.props.getRecetaOV(
                                                e.id,
                                                empresa
                                            );
                                        }}
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-group has-feedback col-md-6 col-12 d-flex justify-content-start"></div>
                        </div>
                    ) : (
                        <div className="form-group has-feedback col-lg-6 col-md-6 col-12" />
                    )}
                </div>
                <div className="row justify-content-center">
                    <div className="form-group has-feedback col-md-6 col-12 d-flex justify-content-end">
                        <div>
                            <label className="t-azul" htmlFor="producto">
                                Fecha de solicitud
                            </label>
                            <Field
                                name="fecha"
                                selectedDate={new Date()}
                                maxDate="2030-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                    </div>
                    <div className="form-group has-feedback col-md-6 col-12">
                        <label className="t-azul" htmlFor="producto">
                            Fecha de entrega OP
                        </label>
                        <Field
                            name="fecha_prox_entrega"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                </div>
                <br />

                {/* DETALLE DE ORDEN DE VENTA */}

                {this.props.bandera_orden && (
                    <React.Fragment>
                        <span className="m-none bold text-uppercase">
                            {tipo_pro == "SI"
                                ? "INFORMACION DE SOLICITUD INTERNA "
                                : "Información Orden de Venta"}
                        </span>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                        <div className="row justify-content-center">
                            <div className="form-group has-feedback col-4 readFieldOnly">
                                <label
                                    className="t-azul"
                                    htmlFor="fecha_prox_entrega"
                                >
                                    Fecha de entrega
                                </label>
                                <Field
                                    name={`orden.fecha_prox_entrega`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "67px", position: "inherit" }}
                                    clase="field-clear"
                                    readOnly
                                />
                            </div>
                            <div className="form-group has-feedback col-4 readFieldOnly">
                                <label className="t-azul" htmlFor="producto">
                                    Emitida por
                                </label>
                                <Field
                                    name={`orden.emitido_por.first_name`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "67px", position: "inherit" }}
                                    clase="field-clear"
                                    readOnly
                                />
                            </div>
                            {tipo_pro == "SI" ? (
                                <div className="form-group has-feedback col-4 readFieldOnly">
                                    <label
                                        className="t-azul"
                                        htmlFor="producto"
                                    >
                                        Empresa Destino
                                    </label>
                                    <Field
                                        name={`orden.empresa_destino.nombre`}
                                        type="text"
                                        component={renderField}
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                        clase="field-clear"
                                        readOnly
                                    />
                                </div>
                            ) : (
                                <div className="form-group has-feedback col-4 readFieldOnly">
                                    <label
                                        className="t-azul"
                                        htmlFor="producto"
                                    >
                                        Cliente
                                    </label>
                                    <Field
                                        name={`orden.proveedor.nombre`}
                                        type="text"
                                        component={renderField}
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                        clase="field-clear"
                                        readOnly
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row justify-content-center">
                            <div className="form-group has-feedback col-12">
                                <label htmlFor="detalle_ov">
                                    DETALLE ORDEN DE VENTA
                                </label>
                                <hr
                                    style={{
                                        borderTop:
                                            "2px solid rgb(223, 224, 224)",
                                        margin: "0px 0px 10px 0px",
                                    }}
                                />
                                <FieldArray
                                    name="orden.detalle_orden"
                                    label="detalle_ov"
                                    component={renderDetalleOV}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {/* DETALLE PRODUCCION */}

                <div className="row justify-content-start">
                    <div className="form-group has-feedback col-12">
                        <label htmlFor="detalle_ov">
                            DETALLE DE PRODUCCION
                        </label>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                        <FieldArray
                            name="produccion"
                            label="produccion"
                            component={renderBonficacionPuesto}
                            className="form-control"
                            empresa = {this.props.empresa}
                            es_sobre_orden = {this.props.bandera_orden}
                            getProductos={this.getProductos}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/produccion`}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

ProduccionForm = reduxForm({
    form: "ProduccionForm", // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido";
        }
        if (!data.fecha_prox_entrega) {
            errors.fecha_prox_entrega = "Campo requerido";
        }
        if (!data.produccion || !data.produccion.length) {
            errors.produccion = {
                _error: "Debe de ingresar al menos un Registro",
            };
        } else {
            const detalleArray = [];
            data.produccion.forEach((campo, index) => {
                let detErrors = {};
                if (!campo.producto) {
                    detErrors.producto = "Campo requerido";
                }
                if (!campo.cantidad) {
                    detErrors.cantidad = "Campo requerido";
                } else if(campo.cantidad <= 0) {
                    detErrors.cantidad = "La cantidad debe ser mayor a 0"
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.produccion = detalleArray;
            }
        }
        return errors;
    },
})(ProduccionForm);

export default ProduccionForm;
