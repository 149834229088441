import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";
import iconoPaginaAtras from '../../../../../assets/img/icons/izquierda.png';
import iconoPaginaAdelante from '../../../../../assets/img/icons/derecha.png';
import iconoPaginaInicial from '../../../../../assets/img/icons/pagina_inicial.png';
import iconoPaginaFinal from '../../../../../assets/img/icons/pagina_final.png';


export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };
    state={
        paginacion: false,
        tamanioPaginacion: 0
    }

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            selectRowProp,
            ...other
        } = this.props;
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : 'No hay datos',
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand,
            prePage: <img width='15' src={iconoPaginaAtras}/>,
            nextPage: <img width='15' src={iconoPaginaAdelante}/>,
            firstPage: <img width='15' src={iconoPaginaInicial}/>,
            lastPage: <img width='15' src={iconoPaginaFinal}/>
        };

        const paginar = !(pagination === false);

        console.log('Data: ', data)

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                      selectRow={selectRowProp}
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data.results}
                        afterSaveCell={afterSave}
                        remote={remote}
                        pagination={paginar}
                        fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        {...other}
                        // exportCSV
                        // csvFileName='table-export'
                    />
                </LoadMask>
            </div>

        )
    }
}
