import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";

export default class RolesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page, true);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        if (permisos["clientes"] === false) {
            if (!!permisos[`ver_clientes`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <div
                className="Container bg-white mt-4 shadow"
                style={{ borderRadius: "10px" }}
            >
                <br />
                <div className="row justify-content-end">
                    <div className="col-12">
                        <ToolbarSimple
                            textBoton="Agregar"
                            ruta="/clientes/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre, Nit"}
                            permisos={permisos.clientes}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="p-0 px-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                >
                                    {permisos.clientes && (
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataSort
                                            dataFormat={standardActions({
                                                agenda: "clientes",
                                                editCliente: "clientes",
                                                eliminar,
                                            })}
                                        ></TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        isKey
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="telefono"
                                        dataSort
                                    >
                                        TELEFONO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sucursal"
                                        dataSort
                                        dataFormat={(cell, row) => {
                                            if (cell.length) {
                                                return cell[0].direccion;
                                            } else return "";
                                        }}
                                    >
                                        DIRECCION
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
