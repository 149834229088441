import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { getsimbolo } from '../../../../../utility/constants';
import Modal from 'react-responsive-modal';



export default class AsignarFlotanteGrid extends Component {
    state = {
        orden_venta: null
    }
    componentDidMount() {
        const { listarClientes, page } = this.props;
        listarClientes();
    }

    botonModal = (id, row) => (
        <img className="action-img" title="Asignar pago" src={require("../../../../../../assets/img/icons/marcar_bono.png")}
            onClick={(e)=>{
                e.preventDefault();
                this.props.setOpenModal(true);
                this.props.setOrdenVenta(row);
            }}
            alt="Asignar pago"
        />
    )

    componentWillUnmount(){
        this.props.setOpenModal(false);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, open_modal, transaccion, page, orden_venta, setModalFlotante, cargar } = this.props;
        return (
            <div>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Acreditar Pago </span>
                </div>
                    {open_modal ?
                        <React.Fragment>
                            <div className="">
                                <h3 className="text-center text-primary text-uppercase">¿Está seguro de asignar el pago?</h3>
                            </div>
                            <div className="mb-5 mt-3 ">
                                <h4 className="text-center text-primary text-uppercase">Pago:</h4>

                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <span className="m-none t-mostaza semibold">Fecha: </span>
                                        <span className="m-none t-musgo semibold"><RenderDateTime fecha={transaccion ? transaccion.fecha : null} ></RenderDateTime></span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <span className="m-none t-mostaza semibold">Monto: </span>
                                        <span className="m-none t-musgo semibold">
                                            <RenderMoneda
                                                monto={transaccion ? transaccion.monto : 0}
                                                simbolo={getsimbolo(transaccion ? transaccion.moneda : '')} />
                                        </span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <span className="m-none t-mostaza semibold">No. transacción: </span>
                                        <span className="m-none t-musgo semibold">{transaccion ? transaccion.no_documento : ''}</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <span className="m-none t-mostaza semibold">Tipo de transacción: </span>
                                        <span className="m-none t-musgo semibold">{transaccion ? transaccion.label_tipo_transaccion : ''}</span>
                                    </div>
                                </div>
                                <h4 className="mt-3 text-center text-primary">Al cliente: { orden_venta ? orden_venta.nombre : ''}</h4>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <button type="button" onClick={()=>{this.props.setOpenModal(false)}} className="btn btn-secondary ml-md-4 m-1 align-self-center">Cancelar</button>
                                        <button type="button"
                                            onClick={() => {
                                                this.props.asginarPago(()=>{
                                                    setModalFlotante(false);
                                                    cargar();
                                                })
                                            }}
                                            className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    : <React.Fragment>
                        <div className="mb-3 col-md-6 col-12">
                            <div className="col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Fecha: </span>
                                <span className="m-none t-musgo semibold"><RenderDateTime fecha={transaccion ? transaccion.fecha : null} ></RenderDateTime></span>
                            </div>
                            <div className="col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Monto: </span>
                                <span className="m-none t-musgo semibold">
                                    <RenderMoneda
                                        monto={transaccion ? transaccion.monto : 0}
                                        simbolo={getsimbolo(transaccion ? transaccion.moneda : '')} />
                                </span>
                            </div>
                            <div className="col-12">
                                <span className="m-none t-mostaza semibold">No. transacción: </span>
                                <span className="m-none t-musgo semibold">{transaccion ? transaccion.no_documento : ''}</span>
                            </div>
                            <div className="col-md-6 col-12">
                                <span className="m-none t-mostaza semibold">Tipo de transacción: </span>
                                <span className="m-none t-musgo semibold">{transaccion ? transaccion.label_tipo_transaccion : ''}</span>
                            </div>
                        </div>
                        <div className="mb-4 col-12">
                            <ToolbarSimple
                                buscar={this.props.searchChange}
                                buscador={this.props.search}
                                placeholder={"Buscar por: No, orden, cliente"}
                                margen={"0"}
                            />
                        </div>

                        <Grid hover
                            page={page}
                            striped
                            data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                dataSort
                                dataFormat={
                                    standardActions({adicional: this.botonModal})
                                }
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                            >
                                NOMBRE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="telefono"
                                dataSort
                            >
                                TELÉFONO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="sucursal"
                                dataSort
                                dataFormat={
                                    (cell,row) => {
                                        if (cell.length){
                                            return cell[0].direccion
                                        }
                                        else return ""
                                    }
                                }
                            >
                                DIRECCION
                            </TableHeaderColumn>
                        </Grid>
                    </React.Fragment>
                }
            </div>
        );
    }
}
