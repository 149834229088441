import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/TipoCategorias/tipocategorias';
import TipoCategorias from './TipoCategoriaList';

const mstp = state => {
    return {...state.tipocategoria, permisos: state.login.me.permisos,}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TipoCategorias)
