import React, { Component, Fragment } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";

import Modal from "react-responsive-modal";
import { iconos } from "../../../../../utility/icons";
import Check from "../../../../../../assets/img/icons/Check.png";
import ReajusteForm from "./reajusteForm";
export default class InventarioGrid extends Component {
    state = {
        producto: null,
    };
    componentDidMount() {
        this.props.listar(1, this.props.id_bodega);
    }

    pageChange = (page) => {
        this.props.listar(page, this.props.id_bodega);
    };

    botonRealizarReajuste = (id, row) => {
        return (
            <a
                className="px-2"
                style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({ producto: row });
                    this.props.setOpenModal(true);
                }}
            >
                <img
                    className="action-img iconoAcciones iconoReajuste"
                    title="Reajustar o baja"
                    src={iconos.baja_reajuste}
                    alt="Reajustar o baja"
                />
            </a>
        );
    };
    handleSubmitReajuste = (values) => {
        let valores = _.cloneDeep(values);
        valores.cantidad = Number(values.cantidad);
        valores.bodega = Number(values.bodega);
        valores.tipo_movimiento = Number(values.tipo_movimiento);
        this.props.reajustar_inventario(valores, this.props.id_bodega);
    };
    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            open_modal,
            page,
            item,
        } = this.props;

        return (
            <Fragment>
                <Modal
                    open={open_modal}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.props.setOpenModal(false);
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Reajuste o baja
                        </span>
                    </div>
                    <ReajusteForm
                        producto={this.state.producto}
                        initialValues={{
                            producto: this.state.producto
                                ? this.state.producto.id
                                : 0,
                            bodega: this.props.id_bodega,
                            tipo_movimiento: "50",
                        }}
                        onSubmit={this.handleSubmitReajuste}
                        cerrarModal={this.props.setOpenModal}
                    />
                </Modal>
                <Grid
                    hover
                    striped
                    data={data}
                    loading={loader}
                    page={page}
                    onPageChange={this.pageChange}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="15%"
                        isKey
                        dataFormat={standardActions({
                            adicional: this.botonRealizarReajuste,
                        })}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="codigo">
                        CÓDIGO
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="nombre">
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="label_unidad_inventario">
                        UNIDAD DE MEDIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="cantidad">
                        CANTIDAD
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="alertado"
                        dataAlign="center"
                        dataFormat={(cell, row) => {
                            if (cell) {
                                return(
                                    <div>
                                        <i className="fa fa-exclamation-triangle" style={{color:'red'}} aria-hidden="true"></i>
                                        <br/>
                                        Existencias menores a {row.minimo_existencias}
                                    </div>)
                            } else {' '}
                        }}
                    >
                        ALERTA
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn dataField="minimo_existencias"
                        dataAlign="center"
                        dataFormat={(cell,row) => row.alertado ? cell && cell : " "}
                    >
                        MIN. EXISTENCIAS
                    </TableHeaderColumn> */}
                </Grid>
            </Fragment>
        );
    }
}
