import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _, { inRange, constant } from "lodash";
import moment from "moment";

let url = "reportes";
let form = "";
let dirGrid = "/";

export const constants = {
    LOADER: "LOADER_MATERIA_PRIMA_HB",
    LOADER_GRAFICA: "LOADER_GRAFICA_MATERIA_PRIMA_HB",
    DATA_GRAFICA: "DATA_GRAFICA_MATERIA_PRIMA_HB",
    DATA: "DATA_MATERIA_PRIMA_HB",
    ITEM: "ITEM_MATERIA_PRIMA_HB",
    PAGE: `PAGE_MATERIA_PRIMA_HB`,
    ORDERING: `ORDERING_MATERIA_PRIMA_HB`,
    PRODUCTO: "PRODUCTO_MATERIA_PRIMA_HB",
    PROVEEDOR: "PROVEEDOR_MATERIA_PRIMA_HB",
    SET_FECHA_FINAL: "SET_FECHA_FINAL_MATERIA_PRIMA_HB",
    SET_FECHA_INICIAL: "SET_FECHA_INICIAL_MATERIA_PRIMA_HB",
    SEARCH: "SEARCH_MATERIA_PRIMA_HB",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setLoaderGrafica = (loaderGrafica)=>({
    type: constants.LOADER_GRAFICA,
    loaderGrafica
})

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const getReporteMateriaPrimaxMes = () => (dispatch, getStore) => {
    dispatch(setLoaderGrafica(true));
    const store = getStore().reporteMateriaPrima;
    const params = { };
    if(store.producto)
        params.producto = store.producto.id
    if(store.proveedor)
        params.proveedor = store.proveedor.id
    if (store.search)
        params.search = store.search;
    api.get(`${url}/reportePrecioMateriaPrimaxMesesHB`, params)
        .then((response) => {
            if(store.producto)
                response[0][1] = response[0][1] + 'en ' + store.producto.nombre
            dispatch(setData(constants.DATA_GRAFICA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderGrafica(false));
        });
};


const getReporteMateriaPrima = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().reporteMateriaPrima;
    const params = { page };
    if(store.fecha_inicial)
        params.fecha_inicial = moment(store.fecha_inicial).format("YYYY-MM-DD");
    if(store.fecha_final)
        params.fecha_final = moment(store.fecha_final).format("YYYY-MM-DD");
    if(store.producto)
        params.producto = store.producto.id
    if(store.proveedor)
        params.proveedor = store.proveedor.id
    if (store.search)
        params.search = store.search;
    api.get(`${url}/reportePrecioMateriaPrimaHB`, params)
        .then((response) => {
            dispatch(setPage(page));
            dispatch(setData(constants.DATA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { id };
    api.get(`${url}/reporteProductividadHB`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeProveedor = (proveedor) => (dispatch) => {
    dispatch(setData(constants.PROVEEDOR,proveedor));
    dispatch(getReporteMateriaPrima());
    dispatch(getReporteMateriaPrimaxMes());
};

const changeProducto = (producto) => (dispatch) => {
    dispatch(setData(constants.PRODUCTO,producto));
    dispatch(getReporteMateriaPrima());
    dispatch(getReporteMateriaPrimaxMes());
};
const changeFechaInicial = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_INICIAL,fecha));
    dispatch(getReporteMateriaPrima());
};
const changeFechaFinal = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_FINAL,fecha));
    dispatch(getReporteMateriaPrima());
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getReporteMateriaPrima());
};

export const actions = {
    getReporteMateriaPrima,
    getReporteMateriaPrimaxMes,
    changeProveedor,
    changeProducto,
    changeFechaInicial,
    changeFechaFinal,
    searchChange,
    leer,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.LOADER_GRAFICA]: (state, { loaderGrafica }) => {
        return {
            ...state,
            loaderGrafica,
        };
    },
    [constants.DATA_GRAFICA]: (state, { data }) => {
        return {
            ...state,
            dataGrafica: data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.PROVEEDOR]: (state, { data }) => {
        return {
            ...state,
            proveedor: data,
        };
    },
    [constants.PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data,
        };
    },
    [constants.SET_FECHA_INICIAL]: (state, { data }) => {
        return {
            ...state,
            fecha_inicial: data,
        };
    },
    [constants.SET_FECHA_FINAL]: (state, { data }) => {
        return {
            ...state,
            fecha_final: data,
        };
    },
};

export const initialState = {
    loader: false,
    loaderGrafica: false,
    data: {},
    dataGrafica: {},
    item: {},
    page: 1,
    search: "",
    ordering: "",
    proveedor: null,
    producto: null,
    fecha_inicial: moment().subtract(7, "days"),
    fecha_final: moment(),
};

export default handleActions(reducers, initialState);
