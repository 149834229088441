import React, {Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {connect} from 'react-redux';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency, renderSelectField, renderTextArea, renderFieldRadio, renderFilePicker } from '../../../Utils/renderField';

import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


let EstadoCuentaForm = (props) => {
    const {data, handleSubmit, setOpenModalEstadoCuenta, setFile} = props;
    return (
        <form name="EstadoCuentaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>  
            <div>
                <span className="font-weight-bold">Seleccione un archivo  .txt   o   .csv </span>
            </div>
            <div>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-10 row m-0 p-0">
                    <div className="form-group has-feedback col-12 row">
                        <div className={`form-group has-feedback  col-12 `}>
                            <Field
                                name="estado_file"
                                placeholder="Seleccione el archivo tipo csv "
                                filename="estadocuenta"
                                setFile={setFile}
                                className="form-control"
                                component={renderFilePicker}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                        <button onClick={(e)=>{ e.preventDefault(); setOpenModalEstadoCuenta(false)}} className="btn btn-secondary  align-self-cente">Cancelar</button>
                        <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Subir</button>
                    </div>
                </div>
            </div>

        </div>
        </form>
    );
};


export default reduxForm({
    form: 'EstadoCuentaForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        return errors;
    },
})(EstadoCuentaForm);

