import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const VerifyForm = (props) => {
    const { handleSubmit} = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="password">Ingrese nueva contraseña</label>
                <Field name="password" label="password" component={renderField} type="password" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="password_confirmation">Confirma contraseña</label>
                <Field name="password_confirmation" label="password_confirmation" component={renderField} type="password" className="form-control" />
            </div>
            <Field name="token" label="token" component="input"  type="hidden" className="form-control" />
            <div className="buttons-box">
                <button type="submit" className="btn btn-azul-claro btn-block m-1 align-self-center">Confirmar</button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    console.log('confirmando')
    return pass === confirm;
}); 

export default reduxForm({
    form: 'verify',
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('Este campo es requerido'),
                matchPassword(data.password, data.password_confirmation)()('Las contraseñas no coinciden')
             ),
            password: validators.exists()('Este campo es requerido'),
            password_confirmation: validators.exists()('Este campo es requerido'),
            token: validators.exists()('Este campo es requerido'),
        });
    },
})(VerifyForm);
