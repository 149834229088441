import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './acciones.css';
import Swal from "sweetalert2";
import ImageEdit from "../../../../../assets/img/icons/editar.png";
import ImageDel from "../../../../../assets/img/icons/Cerrar.png";
import ImageVer from "../../../../../assets/img/icons/Ver.png";
import ImageAgen from "../../../../../assets/img/icons/agenda.png";
import ImageTransac from "../../../../../assets/img/icons/cuentas.png";
import ImagePago from "../../../../../assets/img/icons/marcar_bono.png";
import ImagenArchivero from "../../../../../assets/img/icons/Archivero.png";
import ImagenArchivo from "../../../../../assets/img/icons/Archivo.png";
import ImagenProduccionVenta from "../../../../../assets/img/icons/produccionventas.png";
import InicioProduccionVenta from "../../../../../assets/img/icons/convertirproduccion.png";
import ImagenCSV from "../../../../../assets/img/icons/CSV.png";
import ImagePrint from "../../../../../assets/img/icons/imprimir.png";
import ImageAnular from "../../../../../assets/img/icons/anularVenta.png";
import { iconos } from "../../../../utility/icons";

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: "¿Eliminar?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, eliminar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };
    eliminarBodega = id => {
        return () => {
            Swal.fire({
                title: "¿Eliminar?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, eliminar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then(result => {
                if(result.value) {
                    this.props.eliminarBodega(id)
                }
            })
        }
    }
    eliminarpm = (id) => {
        return () => {
            Swal.fire({
                title: "¿Eliminar?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, eliminar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarpm(id);
                }
            });
        };
    };

    eliminarModal(id, row) {
        return () => {
            this.props.eliminarModal(id, row);
        };
    }
    eliminarAdicional = (id) => {
        return () => {
            this.props.eliminarAdicional(id, row);
        };
    };
    bloquear_transaccion = (id) => {
        Swal.fire({
            title: "¿Desea bloquear la transacción?",
            text: "¡No podrá asignar esta transacción a un cliente!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, bloquear!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.candado(id);
            }
        });
    };
    desbloquear_transaccion = (id) => {
        Swal.fire({
            title: "¿Desea desbloquear la transacción?",
            text: "¡La transacción podrá ser asignada a un cliente!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, desbloquear",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.candado(id);
            }
        });
    };
    descargar = (id) => {
        this.props.descargar(id);
    };
    agregarCarrito(id, row) {
        return () => {
            this.props.agregarCarrito(id, row);
        };
    }

    render() {
        const {
            id,
            ver,
            editar,
            descargar,
            eliminar,
            agenda,
            agendapro,
            transac,
            editCliente,
            editProveedor,
            adicional,
            eliminarAdicional,
            verpro,
            eliminarCC,
            row,
            eliminar2,
            eliminarModal,
            editarpm,
            eliminarpm,
            vermodal,
            adicional2,
            historialCuenta,
            eliminarBodega,
            candado,
            historialOrdenes,
            verproduccion,
            imprimir,
            imprimirReporte,
            anularVenta,
            inicioProduccion,
            agregarCarrito,
        } = this.props;
        if (id === null || id === undefined) {
            return <div></div>;
        }
        return (
            <div className="d-sm-flex justify-content-sm-center flex-row justify-content-around align-content-between">
                {imprimir !== undefined && row.estado && (
                    <a
                        target="_blank"
                        href={row.documento}
                        download={`${row.documento}`}
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoImprimir"
                            title="ver"
                            src={ImagePrint}
                            alt="ver"
                        />
                    </a>
                )}
                {imprimirReporte !== undefined && row.estado && row.documento && (
                    <a
                        target="_blank"
                        href={row.documento}
                        download={`${row.documento}`}
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoReajuste"
                            title="ver"
                            src={ImagePrint}
                            alt="ver"
                        />
                    </a>
                )}
                {ver !== undefined && (
                    <Link className="pl-2" to={`${ver}/${id}/`}>
                        <img
                            className="action-img-ver iconoAcciones iconoVer p-0"
                            title="ver"
                            src={ImageVer}
                            alt="ver"
                        />
                    </Link>
                )}
                {verproduccion !== undefined && (
                    <Link className="pl-2" to={`${verproduccion}/${id}`}>
                        <img
                            className="action-img-ver iconoAcciones"
                            title="ver"
                            src={ImagenProduccionVenta}
                            alt="ver"
                        />
                    </Link>
                )}
                {verpro !== undefined && (
                    <Link className="pl-2" to={`/${verpro}/${id}/detalle`}>
                        <img
                            className="action-img-ver iconoAcciones iconoVer p-0"
                            title="ver"
                            src={ImageVer}
                            alt="ver"
                        />
                    </Link>
                )}
                {inicioProduccion !== undefined && (
                    <Link className="pl-2" to={`${inicioProduccion}/${id}`}>
                        <img
                            className="action-img-ver iconoAcciones"
                            title="ver"
                            src={InicioProduccionVenta}
                            alt="ver"
                        />
                    </Link>
                )}
                {descargar !== undefined && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={() => this.descargar(id)}
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoEditar"
                            title="Descargar"
                            src={iconos.descarga}
                            alt="Descargar"
                        />
                    </a>
                )}
                {vermodal !== undefined && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={(e) => {
                            e.preventDefault();
                            vermodal(true, row);
                        }}
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoVer p-0"
                            title="ver"
                            src={ImageVer}
                            alt="ver"
                        />
                    </a>
                )}
                {editar !== undefined && (
                    <Link className="pl-2 text-warning" to={`${editar}/${id}`}>
                        <img
                            className="action-img iconoAcciones iconoEditar"
                            title="Editar"
                            src={ImageEdit}
                            alt="Edit"
                        />
                    </Link>
                )}
                {editCliente !== undefined && (
                    <Link
                        className="pl-2 text-warning"
                        to={`${editCliente}/${id}/editar`}
                    >
                        <img
                            className="action-img iconoAcciones iconoEditar"
                            title="Editar"
                            src={ImageEdit}
                            alt="Edit"
                        />
                    </Link>
                )}
                {editProveedor !== undefined && (
                    <Link
                        className=" pl-2 text-warning"
                        to={`${editProveedor}/${id}/editar`}
                    >
                        <img
                            className="action-img iconoAcciones iconoEditar"
                            title="Editar"
                            src={ImageEdit}
                            alt="Edit"
                        />
                    </Link>
                )}
                {editarpm !== undefined && row.proceso_estado != "Aceptado" && (
                    <Link
                        className="pl-2 text-warning"
                        to={`${editarpm}/${id}`}
                    >
                        <img
                            className="action-img iconoAcciones iconoEditar"
                            title="Editar"
                            src={ImageEdit}
                            alt="Edit"
                        />
                    </Link>
                )}
                {agregarCarrito !== undefined && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.agregarCarrito(id, row)}
                    >
                        <img
                            className="action-img iconoRedondo iconoRotar iconoAgregarCarrito"
                            title="Agregar a carrito"
                            src={ImageDel}
                            alt="Edit"
                        />
                    </a>
                )}
                {candado !== undefined && row.flotante_inverso && row.estado && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={(e) => {
                            e.preventDefault();
                            row.bloqueado
                                ? this.desbloquear_transaccion(id)
                                : this.bloquear_transaccion(id);
                        }}
                    >
                        {row.bloqueado ? (
                            <img
                                className="action-img-ver"
                                title="candado cerrado"
                                src={iconos.candado_abierto}
                                alt="Transacción bloqueada"
                            />
                        ) : (
                            <img
                                className="action-img-ver"
                                title="candado abierto"
                                src={iconos.candado_cerrado}
                                alt="Transacción desbloqueada"
                            />
                        )}
                    </a>
                )}
                {agenda !== undefined && (
                    <Link to={`${agenda}/${id}/`} className="pl-2">
                        <img
                            className="action-img-ver iconoAcciones iconoAgenda"
                            title="agenda"
                            src={ImageAgen}
                            alt="Edit"
                        />
                    </Link>
                )}
                {agendapro !== undefined && (
                    <Link
                        to={`${agendapro}/${id}/`}
                        className="pl-2"
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoAgenda"
                            title="agendapro"
                            src={ImageAgen}
                            alt="Edit"
                        />
                    </Link>
                )}
                {transac !== undefined && (
                    <Link className="pl-2" to={`${transac}/${id}/`}>
                        <img
                            className="action-img-ver"
                            title="transacciones"
                            src={ImageTransac}
                            alt="Edit"
                        />
                    </Link>
                )}
                {historialOrdenes !== undefined && (
                    <Link
                        className="pl-2"
                        to={`${historialOrdenes}/${row.id_clipro}/`}
                    >
                        <img
                            className="action-img-ver iconoAcciones iconoEditar"
                            title="Listado Ordenes"
                            src={ImagenArchivero}
                            alt="Listado Ordenes"
                        />
                    </Link>
                )}
                {historialCuenta !== undefined && (
                    <Link className="pl-2" to={`${historialCuenta}/${id}/`}>
                        <img
                            className="action-img-ver iconoAcciones iconoAgenda"
                            title="Historial de Cuenta"
                            src={ImagenArchivo}
                            alt="Historial de Cuenta"
                        />
                    </Link>
                )}
                {adicional !== undefined && adicional(id, row)}
                {adicional2 !== undefined && adicional2(id, row)}
                {eliminar !== undefined && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminar(id)}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="eliminar"
                            src={ImageDel}
                            alt="Delete"
                        />
                    </a>
                )}
                {eliminarBodega !== undefined && row.sucursal == null && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminarBodega(id)}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="eliminar"
                            src={ImageDel}
                            alt="Delete"
                        />
                    </a>
                )}
                {eliminarpm !== undefined && row.proceso_estado !== "Aceptado" && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminarpm(id)}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="eliminar"
                            src={ImageDel}
                            alt="Delete"
                        />
                    </a>
                )}
                {eliminarCC !== undefined &&
                    row.transaccion.tipo_transaccion != 70 && (
                        <a
                            className="pl-2"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                            onClick={this.eliminar(id)}
                        >
                            <img
                                className="action-img iconoAcciones iconoEliminar"
                                title="eliminar"
                                src={ImageDel}
                                alt="Delete"
                            />
                        </a>
                    )}
                {eliminarModal !== undefined && row.estado && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminarModal(id, row)}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="eliminar"
                            src={ImageDel}
                            alt="Delete"
                        />
                    </a>
                )}
                {eliminarAdicional !== undefined && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={this.eliminarAdicional(id, row)}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="eliminar"
                            src={ImageDel}
                            alt="Delete"
                        />
                    </a>
                )}
                {anularVenta !== undefined && row.estado && (
                    <a
                        className="pl-2"
                        style={{ cursor: "pointer", color: "#c4183c" }}
                        onClick={() => {
                            this.props.anularVenta(id, row);
                        }}
                    >
                        <img
                            className="action-img iconoAcciones iconoEliminar"
                            title="anular"
                            src={ImageAnular}
                            alt="Anular"
                        />
                    </a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} row={row} {...acciones} />;
    };
}

export function standardActions2(acciones) {
    return (
        <Acciones
            id={cell}
            {...acciones}
            row={acciones.row}
            contenido={acciones.row}
        />
    );
}
