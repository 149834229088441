import { handleActions } from "redux-actions";
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _ from "lodash";
import moment from "moment";
import { getMunicipios } from '../../../utility/constants';

let url = "ordenventa";
let form = "PeticionVentaForm";
let dirGrid = "/ordenesventa";
let formDespacho = "DespachoForm";

export const constants = {
    LOADER: "OV_LOADER",
    DATA: "OV_DATA",
    ITEM: `OV_ITEM`,
    PAGE: `OV_PAGE`,
    PAGE_HISTORY: "PAGE_HISTORY",
    ORDERING: `OV_ORDERING`,
    SEARCH: `OV_SEARCH`,
    SET_EMPRESAS: "EMPRESAS_OV",
    SET_CHILDS: "SET_CHILDS",
    SET_PENDIENTES: "SET_PENDIENTES",
    CLIENTE_OV: "CLIENTE_OV",
    SET_CUENTAS: "SET_CUENTAS_OV",
    SET_MODAL_GASTO_OV: "SET_MODAL_GASTO_OV",
    SET_MODAL: "SET_MODAL_OV",
    SET_HISTORY: "SET_HISTORY",
    ENTREGADO: "OV_ENTREGADO",
    PAGO: "OV_PAGO",
    SET_MUNICIPIOS: 'SET_MUNICIPIOS',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS',
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().ordenventa;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.pago_completo = resource.pago_completo.value;
    params.entregado = resource.entregado.value;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            dispatch(setData(constants.DATA, response));
            dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const listarEspecial = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().ordenventa;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.pago_completo = resource.pago_completo.value;
    params.entregado = estate ? !estate : resource.entregado.value;
    if (estate) params.a_produccion = true;
    params.state = "3";
    if (resource.filtro_estado)
        params.filtro_estado = resource.filtro_estado.value;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            dispatch(setData(constants.DATA, response));
            dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`${url}/${id}`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
            if (!!form) dispatch(initializeForm(form, response));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR", 0);
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR",
                    0
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createpv`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset(form));
            if (!!dirGrid) dispatch(push(dirGrid));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la creación","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D");
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format(
        "YYYY-MM-D"
    );
    api.put(`${url}/${id}/updatepv`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            if (!!dirGrid) dispatch(push(dirGrid));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la edición","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    let params = { id };
    api.eliminar(`${url}/${id}`, params)
        .then(() => {
            dispatch(listar());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al tratar de eliminar el registro","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const entregadoChange = (entregado) => (dispatch) => {
    dispatch({ type: constants.ENTREGADO, entregado });
    dispatch(listar());
};

const pagoChange = (pago_completo) => (dispatch) => {
    dispatch({ type: constants.PAGO, pago_completo });
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const onSortChangeEspecial = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarEspecial(1, true));
};

const selectEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("empresa/selectempresas")
        .then((res) => {
            dispatch(setData(constants.SET_EMPRESAS, res));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const BillOC = (params) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/billoc`, params).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(verifyBil(res.idFactura, params.id))
        // dispatch(leerOrdenCompra(params.id))
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
        dispatch(setLoader(false));
    })
};

const verifyBil = (id, ov) => (dispatch) => {
    const params={id}
    api.get(`${url}/verify_bill`, params).then((res)=>{
        if (res.estado == "0" || res.estado == 0){
            setTimeout(() => {
                dispatch(verifyBil(id, ov))
            }, 500);
        }else{
            if (res.estado == "1" || res.estado == 1){
                NotificationManager.success('Factura timbrada', 'Éxito', 3000);
                // dispatch(leerOrdenCompra(ov))
                dispatch(push(`/ordenesventa/${ov}/detalle`));
                dispatch(leerOrdenCompra(ov));
            }else{
                NotificationManager.error(res.mensaje, 'ERROR', 3000);
            }
            dispatch(setLoader(false))
        }

    }).catch(()=>{
        dispatch(setLoader(false))
    })
}

const DeleteOC = (params) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/deletebilloc`, params)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(leerOrdenCompra(params.orden));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al tratar de eliminar el registro","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE_OV,
        proveedor,
    });
};

const selectCuentas = (id, moneda) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data = { id, moneda, solo_principal: true };
    api.post(`cuentas/selectcuentas`, data)
        .then((res) => {
            dispatch(setData(constants.SET_CUENTAS, res));
            console.log("get store", getStore());
            const formPago = getStore().form.PeticionVentaForm;
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const anularTransaccion = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/anularTransaccion`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
            dispatch(setOpenModalGasto(false));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al tratar de anular la transacción","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setOpenModalGasto = (open_modal_gasto) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_GASTO_OV,
        open_modal_gasto: open_modal_gasto,
    });
};

const marcarPagadaOC = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/marcarPagadaOC`, { orden: id })
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al trata de anular la transacción","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const abonarGastoOPago = (id, es_pago = true) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.PagoCreditoForm.values);
    formData.orden = id;
    formData.es_pago = es_pago;
    formData.fecha = moment(formData.fecha).format("YYYY-MM-DD");
    if (formData.poss) {
        formData.poss = formData.poss.id;
    }
    dispatch(setLoader(true));
    api.post(`${url}/agregarGastoOPago`, formData)
        .then((response) => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leerOrdenCompra = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/getOrdenCompra/${id}`)
        .then((response) => {
            console.log(response);
            let datos = {};
            if (response && response.orden) {
                datos = response.orden;
            } else {
                datos = response;
            }
            dispatch({ type: constants.ITEM, item: datos });
            if (!!form) dispatch(initializeForm(form, datos));
            // dispatch(setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes))
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR", 0);
            } else {
                NotificationManager.error("Hubo un error", "ERROR", 0);
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const flotanteChequePagado = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/flotanteChequePagado`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(setOpenModal(false));
            dispatch(leerOrdenCompra(id));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al marcar el cheque como cobrado","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL,
        open_modal: open_modal,
    });
};

const clear = () => (dispatch) => {
    dispatch({ type: constants.ITEM, item: {} });
};

const DespacharProducto = (data, orden) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;

    api.post(`despachos`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset(formDespacho));
            if (!!dirGrid) dispatch(goBack());
            //dispatch(push(`${dirGrid}/${orden}/detalle`));
        })
        .catch((error) => {
            let mensaje = "Error en el despacho de OV";
            if (error && error.detail) {
                NotificationManager.error(`${error.detail}`, "ERROR");
            } else {
                NotificationManager.error(mensaje, "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const HistoryDispatch = (data) => (dispatch, getStore) => {
    const params = {};
    params.orden = data.orden;

    dispatch(setLoader(true));
    api.get(`despachos/historialdespacho`, params)
        .then((res) => {
            dispatch(setData(constants.SET_HISTORY, res));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al obtener los datos","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const AutorizarDespacho = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`despachos/autorizardespacho`, data)
        .then((res) => {
            dispatch(clear());
            dispatch(leerOrdenCompra(data));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const AutorizarProduccion = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`${url}/autorizarproduccion`, data)
        .then((res) => {
            dispatch(clear());
            dispatch(leerOrdenCompra(data));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const selectProductos = () => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    // let fecha1 = moment(fechaI).format('YYYY-MM-DD');
    // let fecha2 = moment(fechaF).format('YYYY-MM-DD');
    // let params = {
    //     fechaI: fecha1,
    //     fechaF: fecha2
    // }
    const resource = getStore().ordenventa;
    if (Object.keys(resource.item).length){
        let detalle = resource.item.detalle_orden
        let a_facturar = []

        detalle.forEach((item,index) => {
            a_facturar.push({
                producto: {
                    producto: item.producto.id,
                    producto_nombre: item.producto.nombre,
                    cantidad:item.cantidad,
                    cantidad_actual:item.cantidad_actual,
                    cantidad_facturada:item.cantidad_facturada,
                    precio_unitario:item.precio_unitario,
                },
                facturar: (item.cantidad - item.cantidad_facturada >= 0) ? item.cantidad - item.cantidad_facturada: 0
            })
        });
        dispatch(change('FacturaForm', `detalle_factura`, a_facturar))
        dispatch(setLoader(false))
    }else{
        dispatch(push(dirGrid))
    }

}

const setMunicipios = (val, set= 1) => (dispatch) =>{
    if (set == 1) {
        let municipios = getMunicipios(val)
        dispatch({type: constants.SET_MUNICIPIOS, data:municipios})
    }else
        dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:val})

}

const setCodigoPostal = (municipio) => (dispatch, getStore) =>{
    const resource = getStore().ordenventa;
    const codigo =  resource.municipios.find((item) => {
            if (item.value == municipio)
                return item.codigo
        })
    console.log(codigo)
    dispatch(change('FacturaForm','codigo_postal',codigo.codigo.toString()))
}
const AddMunicipio = () => (dispatch, getStore) =>{
    const resource = getStore().clientes;
    let anterior = resource.municipios_bancos
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior, getMunicipios('01')]})
}

const ChangeMunicipio = ( index, depto ) => (dispatch, getStore) =>{
    const resource = getStore().clientes;
    const formulario = getStore().form.FacturaForm;

    let anterior = resource.municipios;
    anterior[0] = _.cloneDeep(getMunicipios(depto));
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior]})
    dispatch({type: constants.SET_CONTROLES, data:anterior[index]})
    formulario.values.sucursales[index].municipios = anterior[index][0].value

}

const setAutomaticoReceptor = (info) => (dispatch, getStore) =>{
    const resource = getStore().form.FacturaForm;
    const formulario = resource.values;
    formulario.nombre = info.nombre;
    formulario.nit = info.propietario.Nit.replace("-", "");
    dispatch(initializeForm('FacturaForm', formulario));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    listarEspecial,
    searchChange,
    onSortChange,
    onSortChangeEspecial,
    selectEmpresas,
    BillOC,
    DeleteOC,
    setProveedor,
    clear,
    setOpenModal,
    selectCuentas,
    anularTransaccion,
    marcarPagadaOC,
    abonarGastoOPago,
    leerOrdenCompra,
    flotanteChequePagado,
    DespacharProducto,
    HistoryDispatch,
    AutorizarDespacho,
    entregadoChange,
    pagoChange,
    AutorizarProduccion,
    selectProductos,
    setMunicipios,
    AddMunicipio,
    ChangeMunicipio,
    setCodigoPostal,
    setAutomaticoReceptor
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data.empresa,
        };
    },
    [constants.SET_CHILDS]: (state, { data }) => {
        return {
            ...state,
            hijos: data,
        };
    },
    [constants.SET_PENDIENTES]: (state, { data }) => {
        return {
            ...state,
            pendientes: data,
        };
    },
    [constants.CLIENTE_OV]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.SET_CUENTAS]: (state, { data }) => {
        return {
            ...state,
            cuentas: data.cuenta,
        };
    },
    [constants.SET_MODAL_GASTO_OV]: (state, { open_modal_gasto }) => {
        return {
            ...state,
            open_modal_gasto,
        };
    },
    [constants.SET_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.SET_HISTORY]: (state, { data }) => {
        return {
            ...state,
            history: data,
        };
    },
    [constants.PAGO]: (state, { pago_completo }) => {
        return {
            ...state,
            pago_completo,
        };
    },
    [constants.ENTREGADO]: (state, { entregado }) => {
        return {
            ...state,
            entregado,
        };
    },
    [constants.SET_MUNICIPIOS]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    open_modal: false,
    ordering: "",
    search: "",
    pago_completo: { value: null, label: "Todos" },
    entregado: { value: null, label: "Todos" },
    empresas: [],
    hijos: [],
    proveedor: null,
    pendientes: [],
    cuentas: [],
    open_modal_gasto: false,
    history: [],
    municipios:[],
    municipios_bancos:[],
};

export default handleActions(reducers, initialState);
