import { handleActions } from 'redux-actions';
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

let url = 'ventainterna' ;
let form = 'PeticionVentaForm';
let dirGrid = '/ventainterna';
let formDespacho = 'DespachoForm';

export const constants ={
    LOADER: 'OV_LOADER',
    DATA: 'OV_DATA',
    ITEM: `OV_ITEM`,
    PAGE: `OV_PAGE`,
    PAGE_HISTORY: 'PAGE_HISTORY',
    ORDERING: `OV_ORDERING`,
    SEARCH: `OV_SEARCH`,
    SET_EMPRESAS:'EMPRESAS_OV',
    SET_CHILDS:'SET_CHILDS',
    SET_PENDIENTES:'SET_PENDIENTES',
    CLIENTE_OV: 'CLIENTE_OV',
    SET_CUENTAS: 'SET_CUENTAS_OV',
    SET_MODAL_GASTO_OV: 'SET_MODAL_GASTO_OV',
    SET_MODAL: 'SET_MODAL_OV',
    SET_HISTORY: 'SET_HISTORY',
    SET_EMPRESAS: 'SET_EMPRESAS_VI',
    SET_EMPRESAS_DESTINO: 'SET_EMPRESS_DESTINO_VI',
    DETALLE_ORDEN: 'DETALLE_ORDEN_VI'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});



const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().ordenventa;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createpv`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((error) => {
        let mensaje = 'Error en la creación de Peticion de Materiales';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D")
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format("YYYY-MM-D")
    api.put(`${url}/${id}/updatepv`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        dispatch(listPv(""));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const BillOC = (params)=> (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/billoc`, params).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(leerOrdenCompra(params.id))
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const DeleteOC = (params) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/deletebilloc`, params).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(leerOrdenCompra(params.orden))
    }).catch((res)=>{
        NotificationManager.error(res.detail, 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE_OV,
        proveedor
    })
}

const selectCuentas = (id, moneda) => (dispatch, getStore) =>{
    dispatch(setLoader(true));
    let data = {id, moneda, solo_principal: true}
    api.post(`cuentas/selectcuentas`, data).then((res)=>{
        dispatch(setData(constants.SET_CUENTAS, res))
        console.log('get store', getStore())
        const formPago = getStore().form.PeticionVentaForm;

    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const anularTransaccion = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/anularTransaccion`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(leerOrdenCompra(id))
        dispatch(setOpenModalGasto(false))

    }).catch((error) => {
        let mensaje = "Error en la anulación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setOpenModalGasto = open_modal_gasto => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_GASTO_OV,
        open_modal_gasto: open_modal_gasto
    })
}

const marcarPagadaOC = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/marcarPagadaOC`, {orden: id}).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(leerOrdenCompra(id))

    }).catch((error) => {
        let mensaje = "Error en la anulación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const abonarGastoOPago = (id, es_pago=true) => (dispatch, getStore) => {

    //Agrega un registro a la BD seǵun sea el gasto
    const formData = _.cloneDeep(getStore().form.PagoCreditoForm.values);
    formData.orden = id
    formData.es_pago = es_pago
    formData.fecha = moment(formData.fecha).format('YYYY-MM-DD')
    if(formData.poss){
        formData.poss = formData.poss.id;
    }
    dispatch(setLoader(true));
    api.post(`${url}/agregarGastoOPago`, formData).then((response) => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(leerOrdenCompra(id))
    }).catch((error) => {
        let mensaje = 'Error en la creación de Orden de compra';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
 }

 const leerOrdenCompra = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/getOrdenCompra/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
       // dispatch(setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const leerDespacho = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`despachos/${id}`).then((response) => {
        dispatch({type: constants.ITEM, item: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const flotanteChequePagado = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/flotanteChequePagado`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(setOpenModal(false))
        dispatch(leerOrdenCompra(id))
    }).catch((error) => {
        let mensaje = "Error al marcar el cheque como cobrado";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setOpenModal = open_modal => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL,
        open_modal: open_modal
    })
}

const clear = () =>(dispatch) =>{
    dispatch({type: constants.ITEM, item: {}});
};

const DespacharProducto = (data, orden) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;

    api.post(`ventainterna/despachos`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(formDespacho));
        if (!!dirGrid)
            dispatch(push(`${dirGrid}/${orden}/detalle`));
    }).catch((error) => {
        let mensaje = 'Error en la creación de Peticion de Materiales';
        if(error){
            if(error.detail){
                mensaje = error.detail
            }else {
                mensaje = Object.keys(error)[0] + " " + error[Object.keys(error)[0]][0]
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const HistoryDispatch = (data) => (dispatch, getStore) => {
    const params = { };
    params.orden = data.orden;

    dispatch(setLoader(true));
    api.get(`despachos/historialdespacho`, params).then((res) => {
        dispatch(setData(constants.SET_HISTORY, res))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const AutorizarDespacho = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`despachos/autorizardespacho`, data).then((res) => {
        dispatch(clear())
        dispatch(leerOrdenCompra(data))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};


const getEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresasE').then((res)=>{
        dispatch({
            type: constants.SET_EMPRESAS,
            data: res.empresa
        });
        dispatch({
            type: constants.SET_EMPRESAS_DESTINO,
            data: res.empresa
        });
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setEmpresaOrigen = (empresa) => (dispatch, getStore) => {
    const store = getStore().ventainterna;
    const empresas = _.cloneDeep(store.empresas_origen);
    let empresas_destino = []
    empresas.map(x => {
        if(Number(x.id) !== Number(empresa)){
            empresas_destino.push(x);
        }
    });
    dispatch({
        type: constants.SET_EMPRESAS_DESTINO,
        data: empresas_destino
    });
    dispatch(borrarOptionsSelect());
}

const borrarOptionsSelect = () => (dispatch) => {
    dispatch(change('ordencompraForm', 'detalle_orden', []));
    setTimeout(() => {
        dispatch(change('ordencompraForm', 'detalle_orden', [{}]));
    }, 100);

}

const getDetalleOrden = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.get(`ventainterna/getPendientesIngreso/${id}`).then((res)=>{
        dispatch({
            type: constants.DETALLE_ORDEN,
            data: res
        })
        dispatch(initializeForm('IngresoForm', {Despachos: res}))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}



const rechazarDespacho = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`despachos/rechazarDespacho`, data).then(() => {
        NotificationManager.success('Despacho rechazado.', 'Éxito', 3000);
        dispatch(goBack());
    }).catch((error) => {
        let mensaje = 'Error al rechazar el despacho';
        if(error && error.detail){
            mensaje = error.detail
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const aceptarRechazo = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`despachos/aceptar_despacho_HB`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(goBack());
    }).catch((error) => {
        let mensaje = 'Error al aceptar el despacho';
        if(error && error.detail){
            mensaje = error.detail
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectEmpresas,
    BillOC,
    DeleteOC,
    setProveedor,
    clear,
    setOpenModal,
    selectCuentas,
    anularTransaccion,
    marcarPagadaOC,
    abonarGastoOPago,
    leerOrdenCompra,
    flotanteChequePagado,
    DespacharProducto,
    HistoryDispatch,
    AutorizarDespacho,
    getEmpresas,
    setEmpresaOrigen,
    borrarOptionsSelect,
    getDetalleOrden,
    leerDespacho,
    rechazarDespacho,
    aceptarRechazo
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_CHILDS]:(state,{ data })=>{
        return{
            ...state,
            hijos: data,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
    [constants.CLIENTE_OV]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas: data.cuenta,
        }
    },
    [constants.SET_MODAL_GASTO_OV]:(state,{ open_modal_gasto })=>{
        return{
            ...state,
            open_modal_gasto,
        }
    },
    [constants.SET_MODAL]:(state,{ open_modal })=>{
        return{
            ...state,
            open_modal,
        }
    },
    [constants.SET_HISTORY]:(state,{ data })=>{
        return{
            ...state,
            history: data,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas_origen: data,
        }
    },
    [constants.SET_EMPRESAS_DESTINO]:(state,{ data })=>{
        return{
            ...state,
            empresas_destino: data,
        }
    },
    [constants.DETALLE_ORDEN]: (state, {data}) => {
        return {
            ...state,
            detalle_orden: data
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    open_modal: false,
    ordering: '',
    search: '',
    empresas: [],
    hijos:[],
    proveedor: null,
    pendientes:[],
    cuentas: [],
    open_modal_gasto: false,
    history: [],
    empresas_origen: [],
    empresas_destino: [],
    detalle_orden: [],
};

export default handleActions(reducers, initialState);
