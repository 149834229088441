export { default as DeudaReporte } from './DeudaEntreEmpresas';
export { default as CuentasporCOP } from './cuentasPorCoP';
export { default as CuentasporC } from './cuetasPorC';
export { default as DeudaDetallEmpresa } from './DetalleDeudaEmpresa';
// detalle de ordenes
export { default as OrdenesporCOP } from './ordenesPorCoP';
export { default as OrdenesporC } from './ordenesPorC';

export { default as ReporteVentas } from './ventas/reporte';
export { default as DetalleVentasReporte } from './ventas/detalle';

export { default as ReporteExistenciasBodega } from './existenciasBodega/reporte';
export { default as DetalleExistenciasBodega } from './existenciasBodega/detalle';

export { default as ReporteProductividad } from './productividad';
export { default as ReporteCostoVenta } from './CostovsVenta';
export { default as ReporteMateriaPrima } from './materiaPrima';
export { default as ReporteCostoInventario } from './costoInventario';
export { default as ReporteLoteInventario } from './loteCostoInventario';
export { default as ReporteHistoricoCajas } from './historicoCajas';
export { default as DetalleHistoricoCajas } from './detalleHistorialCajas';
export { default as ReporteRotacionInventario } from './rotacionInventario';