import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/OrdenCompra/ordencompra';
import OrdenCompraGrid from './ordencompragrid';

const mstp = state => {
    return {...state.ordencompra, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(OrdenCompraGrid)
