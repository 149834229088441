import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import LoadMask from "../LoadMask/LoadMask";
import ImageVer from "../../../../../assets/img/icons/Ver.png";
import { RenderDateTime } from "../../Utils/renderField/renderTableField";

export default class CardDSEnviadas extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div className="">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <h5 className="t-primary">
                        {data.length > 0 ? this.props.titulo : ""}
                    </h5>
                    <div className="row d-flex justify-content-center">
                        <div className="row col-12 d-flex justify-content-start">
                            {data.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="col-lg-4 col-md-4 col-sm-6 col-12 row pmcard"
                                        style={{ height: "200px" }}
                                    >
                                        <div className="col-12">
                                            <div className="col-12 d-flex justify-content-center">
                                                <span className="align-self-center font-weight-bold text-center bold t-primary">
                                                    DESPACHO
                                                </span>
                                            </div>
                                            <span className="t-primary">
                                                No. despacho:
                                            </span>
                                            <span>{item.no_despacho}</span>
                                            <br />
                                            <span className="t-primary">
                                                Tipo:
                                            </span>
                                            <span>
                                                {item.tipo_movimiento_label}
                                            </span>
                                            <br />
                                            <span className="t-primary">
                                                Fecha:
                                            </span>
                                            <span>
                                                <RenderDateTime
                                                    fecha={item.fecha}
                                                />
                                            </span>
                                            <br />
                                            {item.nombre_reponsable && (
                                                <Fragment>
                                                    <span className="t-primary">
                                                        Emitida por:
                                                    </span>
                                                    <span>
                                                        {item.nombre_reponsable
                                                            ? item.nombre_reponsable
                                                            : "----"}
                                                    </span>
                                                    <br />
                                                </Fragment>
                                            )}
                                            {item.nombre_cliente && (
                                                <Fragment>
                                                    <span className="t-primary">
                                                        Cliente:
                                                    </span>
                                                    <span>
                                                        {item.nombre_cliente}
                                                    </span>
                                                    <br />
                                                </Fragment>
                                            )}

                                            <span className="t-primary">
                                                Direccion:
                                            </span>
                                            <span>{item.direccion}</span>
                                            <div className="col-10 offset-1 p-0 pmicon">
                                                <Link
                                                    to={`/estado_bodega/${this.props.id_bodega}/despacho/${item.id}`}
                                                    className=""
                                                >
                                                    Ver detalle
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}
