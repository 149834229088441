import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';
import  {renderDropdownDateField} from "Utils/renderField/otherrenders";
import {
    renderField,
    renderTextArea,
    renderFieldCheck,
    renderSelectField,
    renderCurrency,
    AsyncSelectField,
    renderFieldRadio,
    SelectField,
    renderSelecttree,
    renderPercentage,
    SelectFieldGroup } from '../../../Utils/renderField';
import { Monedas, getsimbolo } from '../../../../../utility/constants';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";
import { TipoTransaccion } from '../../../../../utility/constants';
import {api} from 'api';
import moment from 'moment';


const getProveedores = (search) => {
    return api.get("clientes", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getTrabajador = (search) => {
    return api.get("user", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getClientes = (search) => {
    return api.get("clientes", { search, esCliente:false }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};




const renderDesglose = ({fields, meta: {error, submitFailed }, simbolo, empresas, cambioDescuento}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                    <tr>
                        <th ></th>
                        <th>Empresa</th>
                        <th style={{width: "20%"}}>Porcentaje</th>
                        <th  style={{width: "20%"}}>Subtotal</th>
                    </tr>
                    </thead>
                {/* </table>

            </div>

            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped"> */}
                    <tbody>
                    {fields.map((empresa, index) => {
                        return (
                            <tr key={index} style={{height:"68px"}}>
                                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                    <img style={{ padding:"10%" }} className="action-img iconoEliminarTabla iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                        title="Borrar"
                                        onClick={() =>{
                                            fields.remove(index)
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                    name={`${empresa}.empresa`}
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas}
                                    pabs={false}
                                    className="form-control"/>
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                            name={`${empresa}.porcentaje`}
                                            type="number"
                                            addClass={"text-right"}
                                            component={renderPercentage}
                                            placeholder="Precio"
                                            _onChange={(e) => {cambioDescuento(e, 'porcentaje', index)}}
                                        />
                                </td>
                                <td className="sin-borde-topzz">
                                    <Field
                                        name={`${empresa}.subtotal`}
                                        label="tipo_cambio"
                                        prefix={simbolo}
                                        component={renderCurrency}
                                        parse={cell => parseFloat(cell)}
                                        className="form-control"
                                        top ={{top:"67px", position:"inherit"}}
                                        _onChange={(e) => {cambioDescuento(e, 'precio', index)}}
                                    />
                                </td>
                            </tr>
                        );

                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    Agregar empresa
                </button>
            </div>
        </div>
        {submitFailed &&
                error &&
                <div className="invalid-feedback-array text-danger h5 font-weight-bold">
                        {error}
                </div>}
    </div>
)
//fin de render de desglose



let GastoForm = (props) => {
    const {
        handleSubmit,
        editar,
        pendiente_liquidar,
        tipo_documento,
        select_cuentas,
        tipo_gastos,
        tipo_cuenta,
        mostrar_deposito,
        mostrar_banco,
        cambioDescuento
    } = props;

    return (
        <form name="GastoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            {
                (!editar) && (
                    <div style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                        <div className="d-flex justify-content-center">
                            <h4 className="t-primary align-self-center">Pagar desde</h4>
                        </div>
                        <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                            <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                                <Field
                                    name="tipo_cuenta"
                                    label="Cuenta"
                                    component={renderFieldRadio}
                                    type="radio"
                                    value={"true"}
                                    className="form-control" />
                            </div>
                            <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                                <Field
                                    value={"false"}
                                    name="tipo_cuenta"
                                    label="Mi Caja Chica"
                                    component={renderFieldRadio}
                                    type="radio"
                                    className="form-control" />
                            </div>
                        </div>
                    </div>
                )
            }
            <br/>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                    <Field
                        name="tipo_gasto_nombre"
                        label="nombre"
                        component={renderSelecttree}
                        options={tipo_gastos}
                        type="text"
                        valueKey="value"
                        labelKey="label"
                        className="form-control" />
                </div>
            </div>
            <div className="row ">
                <div className="form-group has-feedback col-md-6">
                                <label className="t-azul" htmlFor="nombre">Proveedor</label>
                                <Field
                                    name="proveedor"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getProveedores}
                                    type="text"
                                    valueKey="id"
                                    labelKey="nombre"
                                    onChange={(e) => {

                                    }}
                                    className="form-control" />
                </div>
                {
                    (tipo_cuenta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="numero_cuenta">No. de Cuenta</label>
                            <Field
                                name="numero_cuenta"
                                label="label"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={select_cuentas}
                                className="form-control"
                            />
                        </div>
                    )
                }

            </div>
            {
                (tipo_cuenta) && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-md-6">
                                    <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                                    <Field
                                        name="tipo_documento"
                                        label="tipo_documento"
                                        labelKey="label" valueKey="value"
                                        component={renderSelectField}
                                        options={TipoTransaccion}
                                        className="form-control"
                                    />
                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label className="t-azul"  htmlFor="no_documento">{tipo_documento}</label>
                            <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }
            {
                (mostrar_deposito) && (
                    <div className="row justify-content-center">
                        <div className="form-group has-feedback col-md-6">
                            {
                                (mostrar_banco) && (
                                    <Fragment>
                                        <label className="t-azul"  htmlFor="no_boleta">No. Depósito</label>
                                        <Field name="no_boleta" label="no_boleta" component={renderField} type="text" className="form-control" />
                                    </Fragment>
                                )
                            }

                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label className="t-azul"  htmlFor="no_documento">Banco</label>
                            <Field name="banco_depositado" label="banco_depositado" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }

            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6">
                        <label className="t-azul" htmlFor="nombre">Asignar Cliente</label>
                        <Field
                            name="cliente"
                            label="nombre"
                            component={AsyncSelectField}
                            loadOptions={getClientes}
                            type="text"
                            valueKey="id"
                            labelKey="nombre"
                            onChange={(e) => {
                            }}
                            isClearable={true}
                            className="form-control" />
                    </div>
                <div className="form-group has-feedback col-md-6">
                    <label className="t-azul" htmlFor="nombre">Monto</label>
                    <Field
                        name={`monto`}
                        label="tipo_cambio"
                        prefix={"Q"}
                        _onChange={(e) => {cambioDescuento(e, 'monto', 0)}}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="form-group has-feedback col-12 p-0 m-0">
                                    <label className="t-azul" htmlFor="nombre">Asignar Trabajador</label>
                                    <Field
                                        name="trabajador"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getTrabajador}
                                        type="text"
                                        valueKey="id"
                                        labelKey="first_name"
                                        onChange={(e) => {

                                        }}
                                        isClearable={true}
                                        className="form-control" />
                    </div>
                    <div className="form-group has-feedback col-12 p-0 m-0 mt-4">
                        {
                            (!editar) && (
                                <Field
                                name="pendiente_liquidar"
                                label="Pendiente liquidar"
                                component={renderFieldCheck}
                                className="form-control" />
                            )
                        }

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group has-feedback col-12 m-0 p-0">
                                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                                <Field
                                    name="descripcion"
                                    label="descripcion"
                                    rows={7}
                                    component={renderTextArea}
                                    type="text"
                                    top={{top:"174px"}}
                                    className="form-control" />
                            </div>
                </div>
            </div>

            <br></br><br></br><br></br>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                        <Link className="btn btn-secondary  align-self-center" to="/gastos/" >Cancelar</Link>
                        <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Liquidar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


GastoForm = reduxForm({
    form: 'GastoForm', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if(!data.tipo_cuenta){
            errors.tipo_cuenta = "Campo requerido.";
        }else{
            if(data.tipo_cuenta == 'true'){
                if(!data.numero_cuenta) {
                    errors.numero_cuenta = "Campo requerido.";
                }
                if(!data.tipo_documento){
                    errors.tipo_documento = 'Campo requerido';
                }
                if(!data.no_documento){
                    errors.no_documento = "Campo requerido"
                }
            }
        }
        if(!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if(!data.proveedor) {
            errors.proveedor = "Campo requerido.";
        }
        if(!data.tipo_gasto){
            errors.tipo_gasto = 'Campo requerido';
        }
        if(!data.descripcion){
            errors.descripcion = "Campo requerido"
        }
        if(!data.monto){
            errors.monto = "Campo requerido"
        }
        if(!data.desglose || !data.desglose.length){
            errors.desglose = {_error: 'Debe de ingresar al menos una empresa'}
        }else {

            const detalleArray = []
            let hay_error = false
            data.desglose.forEach(function(empresa, index){
                let detErrors = {};
                let repetidos = [];
                if(empresa.empresa){
                    repetidos = _.filter(data.desglose, (x) => {
                        if(x.empresa){
                            return  x.empresa == empresa.empresa
                        }

                        return false;
                        });
                }

                if(!empresa.empresa){
                    detErrors.empresa =  "Campo requerido";
                    hay_error = true
                }else {
                    if(1 < repetidos.length){
                        detErrors.empresa = "Esta empresa está repetido"
                        hay_error = true
                    }
                }
                if(!empresa.subtotal){
                    detErrors.subtotal = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.subtotal) <= 0){
                        detErrors.subtotal = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }
                if(!empresa.porcentaje){
                    detErrors.porcentaje = "Campo requerido";
                    hay_error = true
                }else {
                    if(parseFloat(empresa.porcentaje) <= 0){
                        detErrors.porcentaje = "Debe ser mayor a 0"
                        hay_error = true
                    }
                }

                if(detErrors){
                    detalleArray[index] = detErrors;
                }
            });
            if(detalleArray.length){
                errors.desglose = detalleArray
                if(!hay_error){
                     /*Manejo del total de desglose */
                    let subtotal = 0;
                    data.desglose.forEach( x => {
                        subtotal += Number(x.subtotal)
                    })
                    console.log('sub total: ', subtotal, data.monto)
                    if(Number(subtotal) != Number(data.monto)){
                        console.log('La sumatoria es difertene')
                        errors.desglose = {_error: 'La sumatoria del desglose no coincide con el monto del gasto'}
                    }
                    /*Fin del desglose */
                }

            }
        }
        return errors;
    },
})(GastoForm);

const selector = formValueSelector('GastoForm');
GastoForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_documento')
    const tipo_cuenta_form = selector(state, 'tipo_cuenta')
    const pendiente_liquidar = selector(state, 'pendiente_liquidar')

    let tipo_cuenta = true
    let mostrar_deposito = false
    let mostrar_banco = false

    if(tipo_cuenta_form == "false"){
        tipo_cuenta = false;
        mostrar_deposito = true
        mostrar_banco = true
    } else {
        mostrar_banco = false
        mostrar_deposito = false
    }
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        if(seleccionado){
            if(seleccionado.value == 2){
                mostrar_deposito = true
                mostrar_banco = true
            }else if(seleccionado.value == 4){
                mostrar_deposito = true
                mostrar_banco = false
            }
            tipo_documento = seleccionado.ayuda
        }
    }
    return{
        tipo_documento,
        tipo_cuenta,
        pendiente_liquidar,
        mostrar_deposito,
        mostrar_banco
    }


})(GastoForm);

export default GastoForm

