import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/OrdenVenta/ventainterna';
import ListaOrdenes from './VInternoGrid';

const mstp = state => {
    return {...state.ventainterna, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ListaOrdenes)
