import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import _ from "lodash";
import { NotificationManager} from "react-notifications";
import moment from 'moment';
import { bool } from 'prop-types';
import { getMunicipios } from '../../../utility/constants';
import { change } from 'redux-form';

let url = 'clientes' ;
let form = 'ClientesForm';
let dirGrid = '/clientes';
let dirGrid2 = '/proveedores';

export const constants ={
    LOADER: 'CLIENTES_LOADER',
    DATA: 'CLIENTES_DATA',
    ITEM: `CLIENTES_ITEM`,
    PAGE: `CLIENTES_PAGE`,
    ORDERING: `CLIENTES_ORDERING`,
    SEARCH: `CLIENTES_SEARCH`,
    SET_CONTACTOS: 'SET_CONTACTOS',
    SET_CUENTAS: 'SET_CUENTAS',
    SET_SUCURSALES: 'SET_SUCURSALES',
    SET_NITS: 'SET_NITS',
    SET_REGIONES_CP: 'SET_REGIONES_CP',
    SET_MUNICIPIOS: 'SET_MUNICIPIOS',
    SET_MUNICIPIOS_BANCOS: 'SET_MUNICIPIOS_BANCOS',
    SET_CONTROLES: 'SET_CONTROLES',
    SET_EMPRESAS:'SET_EMPRESAS',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setDataSelect = (type, data) =>({
    type,
    data
});

const listar = (page = 1, esCliente = true) => (dispatch, getStore) => {
    const resource = getStore().clientes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.esCliente = esCliente
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const listarPro = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().clientes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    // params.esCliente = "false"
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = (id, esCliente) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.esCliente=esCliente;
    api.get(`${url}/${id}`,params).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form){  
            dispatch(initializeForm(form, response));
            dispatch(setMunicipios(response.departamento))
            let municipios = response.sucursales.map(
                (item, index) => getMunicipios(item.departamento)
            )
            dispatch(setMunicipios(municipios, 2))
        }
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, esCliente) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset('ClientesForm'));
        if (!!dirGrid)
            if(esCliente===1){
                dispatch(push(dirGrid2));
            }else{
                dispatch(push(dirGrid));
            }
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la creación","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, esCliente) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            if(esCliente===1){
                dispatch(push(dirGrid2));
            }else{
                dispatch(push(dirGrid));
            }
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la edición","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al tratar de eliminar","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const searchChangePro = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarPro());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectContactos = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {id};
    api.get(`${url}/selectContactos`, params).then((res)=>{
        dispatch(setData(constants.SET_CONTACTOS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectCuentas = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectCuentas`, params).then((res)=>{
        dispatch(setData(constants.SET_CUENTAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectSucursales = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectSucursales`, params).then((res)=>{
        dispatch(setData(constants.SET_SUCURSALES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectNits = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    let params ={id}
    api.get(`${url}/selectNit`, params).then((res)=>{
        dispatch(setData(constants.SET_NITS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const selectRegiones = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('region/selectregiones').then((res)=>{
        dispatch({type: constants.SET_REGIONES_CP, data:res})
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setMunicipios = (val, set= 1) => (dispatch) =>{
    if (set == 1) {
        let municipios = getMunicipios(val)
        dispatch({type: constants.SET_MUNICIPIOS, data:municipios})
    }else
        dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:val})

}

const AddMunicipio = () => (dispatch, getStore) =>{
    const resource = getStore().clientes;
    let anterior = resource.municipios_bancos
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior, getMunicipios('01')]})
}

const ChangeMunicipio = ( index, depto ) => (dispatch, getStore) =>{
    // debugger;
    const resource = getStore().clientes;
    const formulario = getStore().form.ClientesForm;

    let anterior = resource.municipios_bancos;
    anterior[index] = _.cloneDeep(getMunicipios(depto));
    dispatch({type: constants.SET_MUNICIPIOS_BANCOS, data:[...anterior]})
    dispatch({type: constants.SET_CONTROLES, data:anterior[index]})
    formulario.values.sucursales[index].municipios = anterior[index][0].value

}

const selectEmpresas = () => (dispatch) =>{
    // devuelve el listado de empreas con el id de su entidad

    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setDataSelect(constants.SET_EMPRESAS, res));
        dispatch(setEmpresasForm(res));
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const setEmpresasForm = value => dispatch =>{
    // debugger;
    let Empresas =[]
    value.forEach((item, index) => {
        Empresas[index] = {
            // empresa: {id: item.id, value: item.id, label: item.nombre},
            empresa: item.id,
            cuenta_inicial: 0
        }
    });
    dispatch(change('ClientesForm', 'cpp', Empresas))
}

const setEmpresasFormProvedor = value => dispatch =>{
    // debugger;
    let Empresas =[]
    value.forEach((item, index) => {
        Empresas[index] = {
            // empresa: {id: item.id, value: item.id, label: item.nombre},
            empresa: item.id,
            moneda: "GTQ",
            tipo_cambio: 1,
            cuenta_inicial: 0
        }
    });
    dispatch(change('ClientesForm', 'cpp', Empresas))
}

const limpiarItem = () => dispatch => {
    dispatch(setData(constants.ITEM, {}));
}

export const actions = {
    listar,
    listarPro,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    searchChangePro,
    onSortChange,
    selectContactos,
    selectCuentas,
    selectSucursales,
    selectNits,
    selectRegiones,
    setMunicipios,
    AddMunicipio,
    ChangeMunicipio,
    selectEmpresas,
    setEmpresasForm,
    setEmpresasFormProvedor,
    limpiarItem,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item:data,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_CONTACTOS]:(state,{ data })=>{
        return{
            ...state,
            contactos:{ 
                results:data.contactos,
                count:data.count
            }
        }
    },
    [constants.SET_CUENTAS]:(state,{ data })=>{
        return{
            ...state,
            cuentas:  {
                results:data.cuentas,
                count:data.count
            }
        }
    },
    [constants.SET_SUCURSALES]:(state,{ data })=>{
        return{
            ...state,
            sucursales:  {
                results:data.sucursales,
                count:data.count
            }
        }
    },
    [constants.SET_NITS]:(state,{ data })=>{
        return{
            ...state,
            nits:  {
                results:data.Nits,
                count:data.count
            }
        }
    },
    [constants.SET_REGIONES_CP]:(state,{ data })=>{
        return{
            ...state,
            regiones: data,
        }
    },
    [constants.SET_MUNICIPIOS]:(state,{ data })=>{
        return{
            ...state,
            municipios: data,
        }
    },
    [constants.SET_MUNICIPIOS_BANCOS]:(state,{ data })=>{
        return{
            ...state,
            municipios_bancos: data,
        }
    },
    [constants.SET_CONTROLES]:(state,{ data })=>{
        return{
            ...state,
            controles: data,
        }
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    contactos:{
        results:[],
        count:0
    },
    cuentas:{
        results:[],
        count:0
    },
    sucursales:{
        results:[],
        count:0
    },
    nits:{
        results:[],
        count:0
    },
    regiones:[],
    municipios:[],
    municipios_bancos:[],
    controles:[],
    empresas:[],

};

export default handleActions(reducers, initialState);