import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import Search from "../../../Utils/Toolbar/search";

export default class RolesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listarPro, page } = this.props;
        listarPro(page);
    }

    render() {
        const {
            data,
            loader,
            listarPro: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
        } = this.props;
        console.log(data);
        if (permisos["proveedores"] === false) {
            if (!!permisos[`ver_proveedores`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <div
                className="Container bg-white mt-4 shadow"
                style={{ borderRadius: "10px" }}
            >
                <br />
                <div className="row justify-content-end">
                    <div className="col-12">
                        <div className="col-12 p-0 m-0 px-4 row d-flex justify-content-center justify-content-md-end">
                            <div
                                className={`col-lg-4 col-md-4 p-0 search ${this.props.margen}`}
                            >
                                {this.props.searchChangePro !== undefined && (
                                    <Search
                                        buscar={this.props.searchChangePro}
                                        buscador={this.props.search}
                                        placeholder={"Buscar po: Nombre"}
                                    />
                                )}
                            </div>
                            {permisos.proveedores && (
                                <div className="col-auto pr-md-0 pt-3 pt-md-0">
                                    <Link
                                        className="btn btn-azul-claro"
                                        to={`/proveedores/nuevo/`}
                                    >
                                        {"Agregar"}
                                    </Link>
                                </div>
                            )}
                            <hr
                                style={{
                                    background: "#408EAA",
                                    height: "2px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="p-0 px-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                >
                                    {permisos.proveedores && (
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataSort
                                            dataFormat={standardActions({
                                                agendapro: "proveedores",
                                                editProveedor: "proveedores",
                                                eliminar,
                                            })}
                                        ></TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        isKey
                                        dataSort
                                    >
                                        NOMBRE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="telefono"
                                        dataSort
                                    >
                                        TELEFONO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="sucursal"
                                        dataSort
                                        dataFormat={(cell, row) => {
                                            if (cell.length) {
                                                return cell[0].direccion;
                                            } else return "------";
                                        }}
                                    >
                                        DIRECCION
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
