import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderFieldRadio, renderSelectField, renderCurrency } from '../../../Utils/renderField';
import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo} from "../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../Utils/renderField/otherrenders";


let SeleccionTipoCuentaForm = (props) => {
    const { handleSubmit, cuentas, simbolo, tipo_documento, cerrarModal} = props;
    return (
        <form name="SeleccionTipoCuentaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>

            <div className="row col-md-12 m-0 p-0 d-flex justify-content-center my-5">
                <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                    <Field
                        name="tipo_cuenta"
                        label="Cuenta"
                        component={renderFieldRadio}
                        type="radio"
                        value={"true"}
                        className="form-control" />
                </div>
                <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                    <Field
                        value={"false"}
                        name="tipo_cuenta"
                        label="Caja chica"
                        component={renderFieldRadio}
                        type="radio"
                        className="form-control" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="submit" className="btn btn-primary m-1 align-self-center">Seleccionar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


SeleccionTipoCuentaForm =  reduxForm({
    form: 'SeleccionTipoCuentaForm', // a unique identifier for this form
    initialValues: {
        tipo_cuenta: 'true',
    },
    validate: (data) => {
        return validate(data, {
            tipo_cuenta: validators.exists()('Este campo es requerido'),
        });
    },
})(SeleccionTipoCuentaForm);

const selector = formValueSelector('SeleccionTipoCuentaForm');
SeleccionTipoCuentaForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_documento')
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
    }
    return{
        tipo_documento
    }


})(SeleccionTipoCuentaForm);


export default SeleccionTipoCuentaForm;
