import React from 'react';
import { RenderDateTime as DateTime, RenderCurrency, RenderDateTimeAll as DateTimeAll, RenderTime } from './renderReadField';
import {Monedas} from '../../../../utility/constants';

export const RenderImage = ({ src, alt }) => {
  if (src !== null) {
    return (
      <img style={{ maxWidth: '50px', borderRadius: '50%' }} src={src} alt={alt} />
    );
  }
  // return (
  //   <img style={{ maxWidth: '50px', borderRadius: '50%' }} src={icons.img_placeholder} alt={alt} />
  // );
};

export const RenderDateTime = ({ fecha, className }) => {
  if (fecha) {
    return (
      <DateTime value={fecha} className={className} />
    );
  }
  return <span className={className}>Sin registro</span>;
};

export const RenderDateTimeAll = ({ fecha, className }) => {
    if (fecha) {
      return (
        <DateTimeAll value={fecha} className={className} />
      );
    }
    return <span className={className}>Sin registro</span>;
  };

export const RenderMoneda = ({ monto, className, simbolo, decimalScale }) => {
    if (monto || monto === 0) {
      return (
        <RenderCurrency value={monto} className={className} simbolo={simbolo} decimalScale={decimalScale}/>
      );
    }
    return <span className={className}>Sin registro</span>;
  };

export const RenderNombreSelect = ({cell, className}) => {
    if(cell && cell.nombre){
        return <span className={className}>{cell.nombre}</span>
    }
    return <span className={className}>--</span>
}

export const getSimbolo=(simbolo)=>{
    let prefix='Q'
    Monedas.forEach((item,index)=>{
        if (item.value === simbolo){
            prefix = item.simbolo
        }
    })
    return prefix + '. '
}

export const RenderSchedule = ({ fecha_Entrada, fecha_Salida, className }) => {
  if (fecha_Entrada &&  fecha_Salida) {
    return (
      <div>
        <RenderTime value={fecha_Entrada} className={className} />
        -
        <RenderTime value={fecha_Salida} className={className} />
      </div>
    );
  }
  return <span className={className}>Sin registro</span>;
};