import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "../../../Utils/Grid/index";
import {
    RenderMoneda,
    RenderDateTime,
    RenderDateTimeAll,
} from "../../../Utils/renderField/renderTableField";

export default class DetalleVenta extends Component {
    componentDidMount() {
        this.props.leerDetalle(this.props.id);
    }
    getJustificacion = (item) => {
        if (item.proceso_estado === "Rechazado") {
            let ultiJustificacion =
                item.justificacion[item.justificacion.length - 1];
            return (
                <div className="col-12">
                    <h6 className="t-azul">Justifiación de Rechazo</h6>
                    <span>{ultiJustificacion.justificacion}</span>
                </div>
            );
        }
    };
    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            detalleVenta,
        } = this.props;
        return (
            <div style={{ marginTop: "-50px" }} className="mt-4">
                <div
                    className="justy-content-center col-12 mb-3"
                    style={{
                        border: "2px solid #D3D3D3",
                        borderRadius: "10px",
                    }}
                >
                    {detalleVenta && detalleVenta.comprador && (
                        <div className="col-12 row py-3 justify-content-center">
                            <div className="col-12 col-md-4 offset-md-1 row">
                                <div className="col-12">
                                    <label> VENDEDOR </label>
                                    <h4> {detalleVenta.operador.nombre} </h4>
                                </div>
                                <div className="col-12">
                                    <label> COMPRADOR </label>
                                    <h4> {detalleVenta.comprador} </h4>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 row d-flex align-items-center">
                                <div className="col-12">
                                    <div className="col-12 row px-0 mx-0">
                                        <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                            <label className="my-0">
                                                Fecha/hora
                                            </label>
                                        </div>
                                        <div className="col-8 col-lg-8 d-flex justify-content-start align-items-center">
                                            <span>
                                                <RenderDateTimeAll
                                                    fecha={detalleVenta.fecha}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 row mx-0 px-0">
                                        <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                            <label className="my-0">Nit</label>
                                        </div>
                                        <div className="col-6 col-lg-8 d-flex justify-content-start align-items-center">
                                            <span> {detalleVenta.nit} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {detalleVenta && detalleVenta.estado == false && (
                    <div className="col-12 row">
                        <div className="col-12">
                            <label> ANULACIÓN </label>
                        </div>
                        <div className="col-12 row mx-0 px-0">
                            <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                <label className="my-0">
                                    Descripción de anulación
                                </label>
                            </div>
                            <div className="col-6 col-lg-8 d-flex justify-content-start align-items-center">
                                <span>{detalleVenta.descripcionAnulacion}</span>
                            </div>
                        </div>
                        <div className="col-12 row mx-0 px-0">
                            <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                <label className="my-0">Fecha anulación</label>
                            </div>
                            <div className="col-6 col-lg-8 d-flex justify-content-start align-items-center">
                                <span>
                                    <RenderDateTimeAll
                                        fecha={detalleVenta.fecha_anulacion}
                                        />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <br />
                {detalleVenta && detalleVenta.comprador && (
                    <Grid
                        hover
                        striped
                        data={{
                            count: detalleVenta.detalle.length,
                            results: detalleVenta.detalle,
                        }}
                        loading={loader}
                        pagination={false}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width="15%"
                            dataFormat={(cell) => (cell ? "" : "")}
                        ></TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="producto"
                            dataSort
                            width="25%"
                            dataFormat={(cell) =>
                                cell ? cell : "Sin registro"
                            }
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="presentacion"
                            dataAlign="center"
                            width="20%"
                        >
                            PRESENTACION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_unitario"
                            width="20%"
                            dataAlign="center"
                            dataFormat={(cell) =>
                                cell ? (
                                    <RenderMoneda
                                        className="px-0"
                                        monto={cell}
                                    />
                                ) : (
                                    <RenderMoneda className="px-0" monto={0} />
                                )
                            }
                        >
                            PRECIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataAlign="center"
                            width="20%"
                        >
                            CANTIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="subtotal"
                            width="20%"
                            headerAlign="center"
                            dataAlign="right"
                            dataFormat={(cell) =>
                                cell ? (
                                    <RenderMoneda
                                        className="px-0"
                                        monto={cell}
                                    />
                                ) : (
                                    <RenderMoneda className="px-0" monto={0} />
                                )
                            }
                        >
                            SUBTOTAL
                        </TableHeaderColumn>
                    </Grid>
                )}

                <div
                    className="col-12 my-3 py-1"
                    style={{
                        backgroundColor: "#828FFB",
                        borderRadius: "5px 5px 0px 0px",
                    }}
                >
                    <div className="row col-12 d-flex justify-content-end">
                        <div className="col-2 t-blanco"> TOTAL</div>
                        <div className="col-3">
                            {detalleVenta && detalleVenta.total ? (
                                <RenderMoneda
                                    className="t-blanco px-0"
                                    monto={detalleVenta.total}
                                />
                            ) : (
                                <RenderMoneda
                                    className="t-blanco px-0"
                                    monto={0}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <br />
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <Link
                                to="/punto_venta"
                                className="btn btn-secondary"
                                type="button"
                            >
                                Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
