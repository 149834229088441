import React, {useState, Component} from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderSwitch } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {SelectField, AsyncSelectField} from '../../../Utils/renderField/renderField.js'
import { formatSelects } from '../../../Utils/renderField/renderReadField';
import { api } from '../../../../../utility/api';
import { UnidadMedida, TipoUnidades } from '../../../../../utility/constants';


let tipoproducto = [];

const getProductos = (search) => {
    return api.get("productos", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

class ItemArray extends Component {
    state = {
        producto:null,
        arr_medidas: []
    }
    setValores = (id) => {
        let unidades = _.filter(UnidadMedida, e => {
            if(e.unidad_medida == id){
                return e;
            }
        });
        unidades = _.without(unidades, undefined);
        this.setState({arr_medidas: unidades});
    }
    componentWillMount(){
        const { producto } = this.props;
        console.log('item ', producto);
        if(producto && producto.producto){
            this.setValores(producto.producto.tipo_medida);
        }
    }
    render(){
        const {eliminar, index, PET } = this.props;
        return(
            <tr key={index} className="align-items-start">
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar();
                        }}
                    />
                </td>
                <td className="sin-borde-top "  style={{width: "40%"}}>
                    <Field
                        name={`${PET}.producto`}
                        component={AsyncSelectField}
                        loadOptions={getProductos}
                        type="text"
                        filterOptions= {false}
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                        changePersonalizado={ e => {
                            this.setState({producto: e})
                            this.setValores(e.tipo_medida)
                        }}
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "25%"}}  >
                    <Field
                        name={`${PET}.cantidad`}
                        label="precio"
                        type="number"
                        component={renderField}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "25%"}}  >
                    <Field
                        name={`${PET}.unidad_medida`}
                        label="unidad_medida"
                        valueKey="value"
                        labelKey="label"
                        options={this.state.arr_medidas}
                        component={SelectField}
                    />
                </td>
            </tr>
        );
    }
}
class renderReceta extends Component {
    state = {
        producto: null
    }
    render(){
        const {fields, meta: {error, submitFailed }, regiones, item} = this.props;
        return (
            <div className=" col-sm-12 p-0">
                <div className=" col-sm-12 form-group np-r p-0">
                    <div className=" table-header-wrapper">
                        <table className="table table-striped table-highlight mb-0">
                            <thead>
                            <tr>
                                <th></th>
                                <th style={{width: "50%"}}>PRODUCTO</th>
                                <th style={{width: "20%"}} >CANTIDAD</th>
                                <th style={{width: "30%"}} >UNIDAD</th>
                            </tr>
                            </thead>
                            <tbody >
                            {fields.map((PET, index) => {
                                let producto = fields.get(index);
                                return (
                                    <ItemArray
                                        index={index}
                                        key={index}
                                        PET={PET}
                                        item={item}
                                        producto={producto}
                                        eliminar={() => {
                                            fields.remove(index);
                                        }}
                                        />
                                );
                            }
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                        <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                            Agregar producto
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}




let ProductoForm = (props) => {
    const { handleSubmit, item, alerta, previousStep} = props;

    return (
        <form name="productoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <h4 className="t-primary text-center">
                Receta
            </h4>
            <div className="form-group has-feedback  col-12">
                    <FieldArray
                        name="recetas"
                        item={item}
                        component={renderReceta}
                    />
                    </div>
            <div className="row  justify-content-sm-center justify-content-center">
                <button className="btn btn-secondary m-1 align-self-center" onClick={previousStep} >Regresar</button>
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Siguiente</button>
            </div>

        </form>
    );
};


ProductoForm =  reduxForm({
    form: 'productoForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        const membersArrayErrors = [];
        if(data.recetas){
            data.recetas.forEach((member, memberIndex) => {
                const memberErrors = {};
                if (!member || !member.producto) {
                    memberErrors.producto = 'Campo Requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                }
                if (!member || !member.cantidad) {
                    memberErrors.cantidad = 'Campo Requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                } else if(parseFloat(member.cantidad) <= 0){
                    memberErrors.cantidad = 'Debe ser mayor a 0';
                    membersArrayErrors[memberIndex] = memberErrors;
                }
                if(!member || !member.unidad_medida){
                    memberErrors.unidad_medida = 'Campo Requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                }
            });
            if (membersArrayErrors.length) {
                errors.recetas = membersArrayErrors;
            }
        }
        return errors;
    }
})(ProductoForm);

const selector = formValueSelector('productoForm')
ProductoForm = connect(state => {
    const alerta = selector(state, 'alerta')
    const vendible = selector(state, 'vendible')
    return {
        alerta,
        vendible
      }
    })(ProductoForm)
export default ProductoForm;
