import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {
    renderTextArea,
} from "../../../Utils/renderField/renderField";

const AgregarFForm = (props) => {
    const { handleSubmit, simbolo } = props;
    return (
        <form name="FacturaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">                
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="nombre">Nombre</label>
                    <Field 
                        name="nombre" 
                        label="Nombre"
                        component={renderField} 
                        type="text" 
                        className="form-control" 
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="nit">Nit</label>
                    <Field 
                        name="nit" 
                        label="Nit"
                        component={renderField} 
                        type="text" 
                        className="form-control" 
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="no_factura">No. de factura</label>
                    <Field 
                        name="no_factura" 
                        label="No. de factura"
                        component={renderField} 
                        type="text" 
                        className="form-control" 
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="monto">Monto</label>
                    <Field 
                        name="monto" 
                        label="Monto"
                        component={renderCurrency}
                        prefix={simbolo}
                        type="text" 
                        parse={cell => parseFloat(cell)}
                        className="form-control" 
                    />
                </div>
            </div>
            
            <div className="row  justify-content-sm-around justify-content-center">
                <button onClick={()=>{props.handleModal(false)}} type="button" className="btn btn-secondary m-1 align-self-center">CANCELAR</button>                
                <button onClick={()=>{props.handleModal(false)}} type="submit" className="btn btn-primary m-1 align-self-center">AGREGAR</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'FacturaForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            nit: validators.exists()('Este campo es requerido'),
            no_factura: validators.exists()('Este campo es requerido'),
            monto: validators.exists()('Este campo es requerido'),
        });
    },
})(AgregarFForm);
