import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

import { TIPO_VEHICULO, TIPO_MAQUINARIA } from '../../../../../utility/constants';

import moment from 'moment';


export default class VehiculosGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar } = this.props;

        return (
            <CardSimple
                toolbar= {<ToolbarSimple
                    textBoton="Agregar"
                    ruta="vehiculos_maquinaria/nuevo"
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    placeholder={"Buscar"}
                    permisos={true}
                />}
                titulo="vehículos/maquinaria">
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={
                                standardActions({ editar: "vehiculos_maquinaria", eliminar })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="placa"
                            dataSort
                        >
                            No. Placa
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="modelo"
                        >
                            Modelo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="marca"
                        >
                            Marca
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo"
                            dataFormat = {(cell, row)=> (
                                cell == TIPO_VEHICULO ? "Vehículo" : cell == TIPO_MAQUINARIA ? "Maquinaria" : "----"
                            )}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn

                            dataField="descripcion"
                        >
                            Descripción
                        </TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
