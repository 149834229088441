import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import LoadMask from "../LoadMask/LoadMask";
import ImageVer from '../../../../../assets/img/icons/Ver.png';
import { RenderDateTime } from "../../Utils/renderField/renderTableField";
export default class CardPMEnviadas extends Component {
    render() {
        const { data, loader } = this.props;
        return (
            <div className="col-12">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <h5 className="t-primary">{ data.length > 0 ? this.props.titulo: ""}</h5>
                    <div className="row d-flex justify-content-center">
                            <div className="row col-12 d-flex justify-content-start">
                                {
                                    data.map((item,index)=>{
                                        return(
                                            <div
                                                key={index}
                                                className="col-md-4 col-sm-6 col-12 row pmcard"
                                            >
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-5 p-0 pm-title">
                                                        <span className="t-primary" >Fecha: </span>
                                                    </div>
                                                    <div className="col-7 p-0 pm-span">
                                                        <span><RenderDateTime fecha={item.fecha} /></span><br/>
                                                    </div>
                                                    <div className="col-5 p-0 pm-title">
                                                        <span className="t-primary">Emitida por: </span>
                                                    </div>
                                                    <div className="col-7 p-0 pm-span">
                                                        <span>{item.emitido_por? item.emitido_por.first_name:'----'}</span><br/>
                                                    </div>
                                                    <div className="col-5 p-0 pm-title">
                                                        <span className="t-primary">No. orden: </span>
                                                    </div>
                                                    <div className="col-7 p-0 pm-span">
                                                        <span>{item.no_orden}</span><br/>
                                                    </div>
                                                    {/* <span style={{color:"#f3a631"}}>Empresa:</span>
                                                    <span>{item.nombre_empresa}</span> */}
                                                {this.props.link && (<div className="col-10 offset-1 p-0 pmicon">
                                                    <Link to={`${this.props.link}/${item.id}/detalle`} className="" >
                                                        Ver detalle  {/* <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" /> */}
                                                    </Link>
                                                </div>)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}
