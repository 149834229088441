import React, { Component } from "react";
import Select, { Creatable, Async } from "react-select";
import Search from "./search";
import classNames from 'classnames';

let estado = [
    {id: false, value: false, label:"Todos"},
    {id: true, value: true, label:"Alertados"},
]

export default class ToolbarReporteExistencias extends Component {
    handleInputChangeProductos = (search) => {
        if (search) {
            this.props.getProductos(search);
        }
    };
    handleInputChangeBodegas = (search) => {
        if (search) {
            this.props.getBodegas(search);
        }
    };

    render() {
        const { alertado, changeTipo } = this.props;
        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 px-0 text-lg-left">
                    <div
                        className={"col-lg-4 col-md-6 col-12 align-self-center"}
                    >
                        <span className="t-azul-claro font-weight-bold">
                            Bodegas
                        </span>
                        <Select
                            defaultValue={this.props.bodegas_sel}
                            isMulti
                            name="bodegas"
                            options={
                                this.props.bodegas ? this.props.bodegas : []
                            }
                            className="basic-multi-select"
                            classNamePrefix="seleccionar..."
                            onChange={(e) => {
                                this.props.changeBodegas(e);
                            }}
                            onInputChange={this.handleInputChangeBodegas}
                        />
                    </div>
                    <div
                        className={"col-lg-4 col-md-6 col-12 align-self-center"}
                    >
                        <span className="t-azul-claro font-weight-bold">
                            Productos
                        </span>
                        <Select
                            defaultValue={this.props.productos_sel}
                            isMulti
                            name="productos"
                            options={
                                this.props.productos ? this.props.productos : []
                            }
                            value={this.props.productos_sel}
                            className="basic-multi-select"
                            classNamePrefix="seleccionar..."
                            onChange={(e) => {
                                this.props.changeProductos(e);
                            }}
                            onInputChange={this.handleInputChangeProductos}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 px-0 mx-0 align-self-center">
                        <span className="t-azul-claro font-weight-bold">
                            Buscar
                        </span>
                        <Search
                            buscar={this.props.buscar}
                            buscador={this.props.buscador}
                            placeholder={this.props.placeholder}
                        />
                    </div>
                </div>
                <div className=" row col-lg-12 col-12 row m-0 p-0 mt-2">
                    <div className="col-12 col-md-4">
                        <span className="t-azul-claro font-weight-bold">
                            Alerta
                        </span>
                        <Select
                            isClearable={false}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={estado}
                            placeholder={"Todos"}
                            value={alertado}
                            onChange={(e)=>changeTipo(e)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
