import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../../Utils/renderField/renderTableField";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../../Utils/Cards/cardSimple";
import Grid from "../../../../Utils/Grid/index";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import ToolbarReporteExistencias from "../../../../Utils/Toolbar/ToolbarReporteExistencias";
import Check from "../../../../../../../assets/img/icons/Check.png";
export default class ReporteExistenciasBodega extends Component {
    componentDidMount() {
        this.props.getBodegas();
        this.props.getProductos();
        this.props.getExistenciasBodega();
    }

    format = (array = []) => {
        const nuevoArray = [];
        array.forEach((item, index) => {
            nuevoArray[index] = { label: item.nombre, value: item.id };
        });
        return nuevoArray;
    };

    render() {
        const { loader, data, page, getExistenciasBodega } = this.props;
        return (
            <CardSimple row={true} margen="mt-0" style={{ marginTop: "-50px" }}>
                <ToolbarReporteExistencias
                    changeBodegas={this.props.changeBodegas}
                    changeProductos={this.props.changeProductos}
                    productos={
                        this.props.productos &&
                        this.props.productos.results &&
                        this.format(this.props.productos.results)
                    }
                    bodegas={
                        this.props.bodegas &&
                        this.props.bodegas.results &&
                        this.format(this.props.bodegas.results)
                    }
                    productos_sel={this.props.productos_seleccionados}
                    bodegas_sel={this.props.bodegas_seleccionadas}
                    buscar={this.props.searchChange}
                    buscador={this.props.search}
                    getProductos={this.props.getProductos}
                    getBodegas={this.props.getBodegas}
                    placeholder={"Buscar por: Codigo"}
                    changeTipo={this.props.changeTipo}
                    alertado = {this.props.alertado}
                />
                <br />
                <div className="col-12 row m-0 p-0 d-flex">
                    <div className="col-md-12 mx-0 px-0">
                        {data && data.results && (
                            <Grid
                                hover
                                striped
                                page={page}
                                data={data}
                                loading={loader}
                                onPageChange={getExistenciasBodega}
                                pagination={true}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="5%"
                                    dataFormat={standardActions({
                                        //ver: "/reporte_existencias",
                                    })}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre_bodega"
                                    dataFormat={(cell) =>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    BODEGA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="codigo"
                                    headerAlign="center"
                                    dataAlign="left"
                                    dataFormat={(cell) =>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    CODIGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre"
                                    headerAlign="center"
                                    dataAlign="left"
                                    dataFormat={(cell) =>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    NOMBRE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="label_unidad_inventario"
                                    headerAlign="center"
                                    dataAlign="left"
                                >
                                    UNIDAD DE MEDIDA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad"
                                    headerAlign="center"
                                    dataAlign="left"
                                    dataFormat={(cell) =>
                                        cell ? cell : 0
                                    }
                                >
                                    CANTIDAD
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="alertado"
                                    dataAlign="center"
                                    width="10%"
                                    dataFormat={(cell, row) => {
                                        if (cell) {
                                            return(
                                                <div>
                                                    <i className="fa fa-exclamation-triangle" style={{color:'red'}} aria-hidden="true"></i>
                                                    <br/>
                                                    Existencias menores a {row.minimo_existencias}
                                                </div>)
                                        } else {' '}
                                    }}
                                >
                                    ALERTA
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataField="minimo_existencias"
                                    dataAlign="center"
                                    dataFormat={(cell) => cell ? cell : " "}
                                >
                                    MIN. EXISTENCIAS
                                </TableHeaderColumn> */}
                            </Grid>
                        )}
                    </div>
                </div>
            </CardSimple>
        );
    }
}
