import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/estado_bodega/estado_bodega';
import EstadoBodega from './EstadoBodega';

const mstp = state => {
    return {...state.estado_bodega}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EstadoBodega)
