// iconos de acciones
export const candado_cerrado = require("../../assets/img/icons/candado_abierto.png");
export const candado_abierto = require("../../assets/img/icons/candado.png");
export const cheque_cliente = require("../../assets/img/icons/Cheque_cliente.png");
export const cheque_proveedor = require("../../assets/img/icons/cheque_proveedor.png");
export const estado_bodega = require("../../assets/img/icons/estado_bodega.png");
export const baja_reajuste = require("../../assets/img/icons/Datos de pago oc.png");
export const devolucion = require("../../assets/img/icons/devolucion_accion.png");
export const csv = require("../../assets/img/icons/CSV.png");
export const descarga = require("../../assets/img/icons/Descarga.png");

// Iconos slidebar
export const produccion_activo = require("../../assets/img/icons/Produccion_activo.png");
export const produccion_inactivo = require("../../assets/img/icons/Produccion.png");
export const devolucion_activo = require("../../assets/img/icons/devolucion_activo.png");
export const devolucion_inactivo = require("../../assets/img/icons/devolucion.png");

export const iconos = {
    candado_abierto,
    candado_cerrado,
    cheque_cliente,
    cheque_proveedor,
    estado_bodega,
    baja_reajuste,
    csv,
    descarga,
};

export const iconos_slide = {
    produccion_activo,
    produccion_inactivo,
    devolucion_activo,
    devolucion_inactivo,
};
