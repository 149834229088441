import React, { Component } from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField, AsyncSelectField } from "../../../Utils/renderField";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { api } from "api";
import {
    RenderCurrency,
    RenderNumber,
} from "../../../Utils/renderField/renderReadField";

class ItemArray extends Component {
    state = {
        producto: null,
    };
    setPrecioUnitario = (producto) => {
        const { todos, index } = this.props;
        if (producto !== undefined && producto.cantidad_actual > 0) {
            if (producto.es_bodega) {
                todos[index]["despachar"] = producto.cantidad_actual;
            } else {
                todos[index]["despachar"] = 0;
            }
        }
    };
    componentDidMount() {
        if (this.props.producto_seleccionado) {
            this.setState({
                producto: this.props.producto_seleccionado.producto,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // if (
        //     this.props.producto_seleccionado.producto !==
        //     prevProps.producto_seleccionado.producto
        // ) {
        //     const { todos, index } = this.props;
        //     if (this.props.producto_seleccionado) {
        //         this.setState({
        //             producto: this.props.producto_seleccionado.producto,
        //         });
        //     }
        // }
    }
    render() {
        const { getProductosOrden, eliminar, index, item, itemOC } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td
                    className="text-center sin-borde-top"
                    style={{ width: "48px" }}
                >
                    <img
                        style={{ padding: "10%" }}
                        className="action-img iconoAcciones iconoEliminar"
                        src={require("assets/img/icons/Cerrar.png")}
                        alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar();
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <span>{itemOC.detalle_orden[index].nombre_producto}</span>
                </td>

                <td className="sin-borde-top">
                    <span>
                        {itemOC.detalle_orden[index].cantidad}&nbsp;
                        {
                            itemOC.detalle_orden[index].producto
                                .label_unidad_recepcion
                        }
                    </span>
                </td>
                <td className="sin-borde-top">
                    <span>
                        {itemOC.detalle_orden[index].cantidad_actual}&nbsp;
                        {
                            itemOC.detalle_orden[index].producto
                                .label_unidad_recepcion
                        }
                    </span>
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${item}.cant_despacho`}
                        type="text"
                        component={renderField}
                        min={0}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                    />
                    <div className="text-right">
                        <span>
                            {
                                itemOC.detalle_orden[index].producto
                                    .label_unidad_recepcion
                            }
                        </span>
                    </div>
                </td>
                <td className="sin-borde-top">
                    <Field
                        label="nombre"
                        name={`${item}.cant_conversion`}
                        type="text"
                        component={renderField}
                        min={0}
                        top={{ top: "67px", position: "inherit" }}
                        valueKey="id"
                        labelKey="nombre"
                        className="form-control"
                    />
                    <div className="text-right">
                        <span>
                            {
                                itemOC.detalle_orden[index].producto
                                    .label_unidad_inventario
                            }
                        </span>
                    </div>
                </td>
            </tr>
        );
    }
}
const renderProductos = ({
    fields,
    meta: { error, submitFailed },
    getProductosOrden,
    todosProductos,
    itemOC,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>PRODUCTO</th>
                            <th style={{ width: "15%" }}>CANT. SOLICITADA</th>
                            <th style={{ width: "15%" }}>CANT. FALTANTE</th>
                            <th style={{ width: "15%" }}>CANT. INGRESADA</th>
                            <th style={{ width: "20%" }}>CANT. CONVERSION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => {
                            let todos = fields.getAll();
                            const producto = todosProductos[index];

                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    producto_seleccionado={producto}
                                    item={item}
                                    todos={todos}
                                    getProductosOrden={getProductosOrden}
                                    itemOC={itemOC}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/*             <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar despacho
                </button>
            </div> */}
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos

class IngresoBodegaForm extends Component {
    getOrdenes = (search) => {
        let params = {
            entregado: false,
            estado: true,
        };
        if (search) {
            params.search = search;
        }
        return api
            .get("ordencompra", params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    render() {
        const { handleSubmit, orden, bodega, todosProductos } = this.props;
        return (
            <form
                name="IngresoBodegaForm"
                className="form-validate mb-lg mt-4"
                onSubmit={handleSubmit}
            >
                <div className="row">
                    <div className="form-group has-feedback col-lg-5 offset-lg-1 col-md-6 col-12">
                        <label className="t-azul" htmlFor="orden_compra">
                            Orden de compra
                        </label>
                        <Field
                            name={`orden`}
                            label="Orden de compra"
                            component={AsyncSelectField}
                            loadOptions={this.getOrdenes}
                            top={{ top: "5px", position: "inherit" }}
                            valueKey="id"
                            labelKey="no_orden"
                            className="form-control"
                            onChange={(e) => {
                                this.props.getProductosOC(e);
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <div className="form-group has-feedback  col-12">
                        <FieldArray
                            name="detalle_orden"
                            component={renderProductos}
                            todosProductos={todosProductos}
                            itemOC={this.props.itemOC}
                            getProductosOrden={this.getProductosOrden}
                        />
                    </div>
                </div>
                <br />
                <div className="row  justify-content-sm-center justify-content-center">
                    <Link
                        className="btn btn-secondary m-1 align-self-center"
                        to={`/estado_bodega/${bodega}/`}
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        );
    }
}

IngresoBodegaForm = reduxForm({
    form: "IngresoBodegaForm", // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};
        console.log(data)
        if (!data || !data.detalle_orden || !data.detalle_orden.length) {
            errors.detalle_orden = { _error: "Debe ingresar al menos un producto" };
        } else {
            const despachoArray = [];
            data.detalle_orden.forEach((producto, index) => {
                let detErrors = {};
                let repetidos = [];
                if (producto.producto) {
                    repetidos = _.filter(data.detalle_orden, (x) => {
                        if (x.producto)
                            return x.producto.id == producto.producto.id;
                        return false;
                    });
                }

                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.producto = "Este producto está repetido";
                    }
                }
                if (!producto.cant_despacho) {
                    detErrors.cant_despacho = "Campo requerido";
                } else {
                    console.log("producto: ", producto.despachar);
                    console.log("cantidad seleccionada ", producto.producto);
                    if (parseFloat(producto.cant_despacho) <= 0) {
                        detErrors.cant_despacho = "Debe ser 0 o mayor";
                    }
                    if (
                        parseFloat(producto.cant_despacho) >
                        producto.producto.cantidad
                    ) {
                        detErrors.cant_despacho = `Debe ser un maximo de ${producto.producto.cantidad}`;
                    }
                }
                if (detErrors) {
                    despachoArray[index] = detErrors;
                }
            });
            if (despachoArray.length) {
                errors.detalle_orden = despachoArray;
            }
        }
        return errors;
    },
})(IngresoBodegaForm);

const selector = formValueSelector("IngresoBodegaForm");
IngresoBodegaForm = connect((state) => {
    const productos = selector(state, "Despachos");

    let todosProductos = [];
    if (productos) {
        todosProductos = productos;
    }

    return {
        todosProductos,
    };
})(IngresoBodegaForm);

export default IngresoBodegaForm;
