import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import { NotificationManager} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';


let url = 'flotanteinverso' ;
let form = 'transaccionesForm';
let dirGridCuentas = '/transaccion';


export const constants ={
    LOADER: 'LOADER_FLINV',
    DATA: 'DATA_FLINV',
    ITEM: `ITEM_FLINV`,
    PAGE: `PAGE_FLINV`,
    ORDERING: `ORDERING_FLINV`,
    SEARCH: `SEARCH_FLINV`,
    SET_EMPRESAS: 'SET_EMPRESAS_FLINV',
    SET_CUENTAS: 'SET_CUENTAS_FLINV',
    FECHA_INICIO: 'FECHA_INICIO_FLINV',
    FECHA_FIN: 'FECHA_FIN_FLINV',
    OPEN_MODAL: 'OPEN_MODAL_FLIV',
    SET_TRANSACCION: 'SET_TRANSACCION_FLIV',
    SET_OV: 'SET_OV_FLIV',
    SET_RUTA: 'SET_RUTA_FLIV'
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) =>({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});


const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().flotante_inverso;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    let dateStart = resource.dateStart;
    let dateEnd = resource.dateEnd;

    if(dateStart){
        try {
            dateStart = dateStart.format("YYYY-MM-D");
        } catch (error) {
            dateStart = dateStart;
        }
        params["fecha_inicial"] = dateStart;
    }
    if(dateEnd) {
        try{
            dateEnd = dateEnd.format("YYYY-MM-D");
        } catch(error){
            dateEnd = dateEnd;
        }
        params["fecha_final"] = dateEnd;
    }

    dispatch(setLoader(true));
    api.get(`ordenventa`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};
const listarClientes = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().flotante_inverso;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.esCliente = false

    dispatch(setLoader(true));
    api.get(`clientes`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch,getStore) => {
    dispatch(setLoader(true));
    const store = getStore().flotante_inverso;
    const params = { cuenta: id, mes: store.valorMes.value, anio: store.valorAnio.value};
    api.get(`${url}/0`, params).then((response) => {
        dispatch(setData(constants.ITEM,response));
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const asginarPago = (accionExito) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().flotante_inverso;
    const id_cuenta = store.transaccion.id_cuenta;
    const ruta = store.ruta;

    let data = {
        id_venta: store.orden_venta.id,
        id_transaccion: store.transaccion.id
    };

    const params = {};
    api.post(`ordenventa/asignarPagoOV`, data, params).then(() => {
        NotificationManager.success('Transaccion realizada', 'Éxito', 3000);
        if(ruta){
            dispatch(push(`/${ruta}`));
        }else {
            dispatch(push('/transaccion'+`/${id_cuenta}`));
        }
        if(accionExito)
            accionExito();
    }).catch((error) => {
            let mensaje = "Error en la creación";
            if(error && error.detail){
                mensaje = error.detail
            }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(setOpenModal(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGridCuentas)
            dispatch(push(dirGridCuentas));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarClientes());
};


const initialC = () => (dispatch) =>{
    dispatch(setData(constants.CUENTA_DESTINO,{}));
}

const setOpenModal = open_modal => (dispatch, getStore) => {
    const store = getStore().transacciones;
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal
    })
}
export const setTransaccion = (transaccion, ruta=null) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_TRANSACCION,
        data: transaccion
    });
    if(ruta){
        dispatch({
            type: constants.SET_RUTA,
            data: ruta
        })
    }
    // dispatch(push('/transaccion/flotante'));
}

const setOrdenVenta = orden => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_OV,
        data: orden
    });
}

// acciones no comunes
const setDateStart = (date) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_INICIO, dateStart: date});
    dispatch(listar(1));
}
const setDateEnd = (date) => (dispatch, getStore) => {
    dispatch({type: constants.FECHA_FIN, dateEnd: date});
    dispatch(listar(1));
}

export const actions = {
    listar,
    listarClientes,
    leer,
    asginarPago,
    editar,
    searchChange,
    initialC,
    setOpenModal,
    setDateStart,
    setDateEnd,
    setTransaccion,
    setOrdenVenta
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, {open_modal}) => {
        return {
            ...state,
            open_modal
        }
    },
    [constants.FECHA_INICIO]: (state, {dateStart}) => {
        return {
            ...state,
            dateStart
        }
    },
    [constants.FECHA_FIN]: (state, {dateEnd}) => {
        return {
            ...state,
            dateEnd
        }
    },
    [constants.SET_TRANSACCION]: (state, {data}) => {
        return {
            ...state,
            transaccion: data
        }
    },
    [constants.SET_OV]: (state, {data}) => {
        return {
            ...state,
            orden_venta: data
        }
    },
    [constants.SET_RUTA]: (state, {data}) => {
        return {
            ...state,
            ruta: data
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    dateStart: moment().subtract(7, 'days'),
    dateEnd: moment(),
    open_modal: false,
    transaccion: null,
    orden_venta: null,
    ruta: null
};

export default handleActions(reducers, initialState);
