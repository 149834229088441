import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";

export default class CuentasGrid extends Component{
    componentWillMount() {
        // let id=this.props.CPid
        // this.props.selectCuentas(id);
    }
    render(){
        const { cuenta, cuentas, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10, results:cuenta}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                    <TableHeaderColumn
                        dataField="propietario"
                        isKey
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre_banco"
                        dataSort
                    >
                        BANCO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero_cuenta"
                        dataSort
                    >
                        NO. DE CUENTA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo"
                        dataSort
                    >
                        TIPO DE CUENTA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="moneda"
                        dataSort
                    >
                        MONEDA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataSort
                    >
                        OBSERVACIONES
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}