import React, {Component} from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from 'prop-types';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import CardSimple from '../../../Utils/Cards/cardSimple';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

 class DetalleEmpleado extends Component{
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { leer } = this.props;
        if (this.props.match.params.id){
            leer(this.props.match.params.id);

        }else{
           return <Redirect to={`/`} />
        }
    }

    render(){
        const { item, loader, permisos } = this.props;
        // if(permisos["empresas"] === false) {
        //     if(!!permisos[`ver_empresas`] === false) {
        //         return <Redirect to={`/`} />
        //     }
        // }
        return(
            <CardSimple margen="mt-0">
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <hr style={{borderTop: "1px solid #00334E", margin:"10px 0px"}}/>
                    {!!item && !!item.nombre && (
                        <div>
                            <div className="row">
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Nombre</span>
                                    <span className="t-texto">{`${item.nombre}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Apellido</span>
                                    <span className="t-texto">{`${item.apellido}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Telefono</span>
                                    <span className="t-texto">{`${item.telefono}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Pueblo de pertenencia</span>
                                    <span className="t-texto">{`${item.pueblo_pertenencia}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Lugar de nacimiento</span>
                                    <span className="t-texto">{`${item.lugar_nacimiento}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Genero</span>
                                    <span className="t-texto">{`${item.genero}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">DPI</span>
                                    <span className="t-texto">{`${item.dpi}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Numero de hijo</span>
                                    <span className="t-texto">{`${item.numero_hijos}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Idiomas que domina</span>
                                    <span className="t-texto">{`${item.idiomas_que_domina}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Nivel academico</span>
                                    <span className="t-texto">{`${item.nivel_academico}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Estado Civil</span>
                                    <span className="t-texto">{`${item.estado_civil}`}</span>
                                </div>
                            </div>
                            <hr style={{borderTop: "1px solid #00334E", margin:"10px 0px"}}/>
                            <div className="row">
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Puesto</span>
                                    <span className="t-texto">{`${item.puesto.nombre}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Contacto</span>
                                    <span className="t-texto">{`${item.nombre_contacto}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Telefono de Contacto</span>
                                    <span className="t-texto">{`${item.telefono_contacto}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Código</span>
                                    <span className="t-texto">{`${item.codigo}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Banco</span>
                                    <span className="t-texto">{`${item.banco}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">No cuenta</span>
                                    <span className="t-texto">{`${item.no_cuenta}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Tipo cuenta</span>
                                    <span className="t-texto">{`${item.tipo_cuenta}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Nit</span>
                                    <span className="t-texto">{`${item.nit}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">IGSS</span>
                                    <span className="t-texto">{`${item.igss}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Licencia</span>
                                    <span className="t-texto">{`${item.licencia}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Tipo de licencia</span>
                                    <span className="t-texto">{`${item.tipo_licencia}`}</span>
                                </div>
                            </div>
                            <hr style={{borderTop: "1px solid #00334E", margin:"10px 0px"}}/>
                            <div className="row">
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Fecha de Nacimiento</span>
                                    <span className="t-texto">{`${item.fecha_nacimiento}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Fecha de Inicio de labores</span>
                                    <span className="t-texto">{`${item.fecha_inicio_labores}`}</span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <span className="t-primary">Direccion</span>
                                    <span className="t-texto">{`${item.direccion}`}</span>
                                </div>

                            </div>
                    </div>
                    )}
                </LoadMask>
            </CardSimple>
        )
    }
}

export default DetalleEmpleado
