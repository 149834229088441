import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/PeticionMaterial/peticionmaterial';
import PeticionMaterialGrid from './peticionMaterialGrid';

const mstp = state => {
    return {...state.peticionM, permisos: state.login.me.permisos }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PeticionMaterialGrid)
