import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import AsignacionesForm from './AsignacionesForm';

import { NotificationManager} from "react-notifications";

class AsignacionesCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };

    state ={
        editar: false,
        titulo: 'ASIGNACIÓN DE ACTIVIDADES',
    }

    componentWillMount(){
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR TIPO PRODUCTO'
            });
        }
    }

    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        this.state.editar? editar(id,values) : crear(values);
    }

    crear = (values) => {
        const {crear, editar} = this.props;
        let detalle = [];
        if (values.asignaciones){
            for (const item of values.asignaciones) {
                detalle.push({
                    actividad: item.actividad.id,
			        empleado: item.empleado.id,
			        empresa_actividad: item.empresa.id,
			        horas_trabajadas: item.horas_trabajadas,
			        tipo_horario: item.tipo_horario
                })
            }

            const body = {
                detalle: detalle
            }
    
            crear(body);
        } else {
            NotificationManager.error('Ingrese al menos un registro', 'ERROR');
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <AsignacionesForm onSubmit={this.crear} />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default AsignacionesCrear;
