import React, {useState, Component} from 'react';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {connect } from 'react-redux';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrency, renderSwitch } from '../../../Utils/renderField';

class ItemArray extends Component {

    render(){
        const {eliminar, index, PET } = this.props;
        return(
            <tr key={index} className="align-items-start">
                <td className="text-center sin-borde-top" style={{width: "48px"}}>
                    <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() =>{
                            eliminar();
                        }}
                    />
                </td>
                <td className="sin-borde-top "  style={{width: "40%"}}>
                    <Field
                        name={`${PET}.unidad_medida`}
                        label="unidad_medida"
                        type="text"
                        component={renderField}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "25%"}}  >
                    <Field
                        name={`${PET}.conversion`}
                        label="conversion"
                        type="number"
                        component={renderField}
                        className="form-control"
                        top ={{top:"67px", position:"inherit"}}
                    />
                </td>
                <td className="sin-borde-top"  style={{width: "25%"}}  >
                    <Field
                        name={`${PET}.precio_venta`}
                        label="precio_venta"
                        valueKey="value"
                        labelKey="label"
                        component={renderCurrency}
                        top={{top:"5px", position:"inherit"}}
                    />
                </td>
            </tr>
        );
    }
}
class renderPresentacion extends Component {
    state = {
        producto: null
    }
    render(){
        const {fields, meta: {error, submitFailed }, regiones, item} = this.props;
        return (
            <div className=" col-sm-12 p-0">
                <div className=" col-sm-12 form-group np-r p-0">
                    <div className=" table-header-wrapper">
                        <table className="table table-striped table-highlight mb-0">
                            <thead>
                            <tr>
                                <th></th>
                                <th style={{width: "40%"}}>PRESENTACION</th>
                                <th style={{width: "20%"}} >CONVERSION</th>
                                <th style={{width: "40%"}} >PRECIO VENTA UNITARIO</th>
                            </tr>
                            </thead>
                            <tbody >
                            {fields.map((PET, index) => {
                                let producto = fields.get(index);
                                return (
                                    <ItemArray
                                        index={index}
                                        key={index}
                                        PET={PET}
                                        item={item}
                                        producto={producto}
                                        eliminar={() => {
                                            fields.remove(index);
                                        }}
                                        />
                                );
                            }
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                        <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                            Agregar producto
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}




let ProductoForm = (props) => {
    const { handleSubmit, item, alerta, previousStep} = props;

    return (
        <form name="productoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            {console.log(props.datos)}
            <h4 className="t-primary text-center">
                Presentaciones
            </h4>
            <div className="col-12">
                {props.datos && props.datos.unidad_inventario && (
                    <h6 className="t-texto">
                        {`${props.onChangeUnidadInventario(props.datos.unidad_inventario)}`} de {props.datos.nombre} equivale a:
                    </h6>
                )}
            </div>
            <div className="form-group has-feedback  col-12">
                    <FieldArray
                        name="presentacion"
                        item={item}
                        component={renderPresentacion}
                    />
                    </div>
            <div className="row  justify-content-sm-center justify-content-center">
                <button className="btn btn-secondary m-1 align-self-center" onClick={previousStep} >Regresar</button>
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Guardar</button>
            </div>

        </form>
    );
};


ProductoForm =  reduxForm({
    form: 'productoForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data) => {
        const errors = {};
        const membersArrayErrors = [];
        if(data.presentacion){
            data.presentacion.forEach((member, memberIndex) => {
                let memberErrors = {}
                let repetidos = [];
                if(member.unidad_medida){
                    repetidos = _.filter(data.presentacion, (x) => {
                        if (x.unidad_medida)
                            return x.unidad_medida == member.unidad_medida;
                        return false;
                    });
                }
                if (!member || !member.unidad_medida) {
                    memberErrors.unidad_medida = 'Campo Requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                }else if (1 < repetidos.length) {
                        memberErrors.unidad_medida = "La unidad de medida está repetida";
                        membersArrayErrors[memberIndex] = memberErrors;
                    }
                if(!member || !member.conversion){
                    memberErrors.conversion = 'Campo requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                } else if(member.conversion < 0) {
                    memberErrors.conversion = 'Debe ser mayor a 0';
                    membersArrayErrors[memberIndex] = memberErrors;
                }
                if(!member || !member.precio_venta){
                    memberErrors.precio_venta = 'Campo Requerido';
                    membersArrayErrors[memberIndex] = memberErrors;
                }
            })
            if (membersArrayErrors.length) {
                errors.presentacion = membersArrayErrors;
            }
        }
        console.log(errors)
        return errors;
    }
})(ProductoForm);

export default ProductoForm;
