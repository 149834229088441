import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import {Monedas} from "../../../../../utility/constants";
import {withRouter} from 'react-router';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Grid from '../../../Utils/Grid/index';
import CardsCaja from '../../../Utils/Cards/cardsCaja';
import Modal from 'react-responsive-modal';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}

class Micaja extends Component{
    state = {
        id_transaccion: 0,
        transaccion_sel: null,
        anular_transaccion: false,
        cierre_modal: false,
        open_modal_retiro:false,
        archivo: null,
        open_modal_cuenta:false
    }
    getsimbolo =(valor)=>{
        let simbolo =''
        Monedas.forEach((item,index)=>{
            if (item.value === valor){
                simbolo = item.simbolo
            }
        })
        return simbolo + '. '
    }
    aceptarEnvio = (id) =>{
        this.props.AcceptMovimiento(id, this.props.idcaja)
    }
    rechazarEnvio = (id) =>{
        this.props.RefuseMovimiento(id, this.props.idcaja)
    }
    cancelEnvio = (id) =>{
        this.props.CancelMovimiento(id, this.props.idcaja)
    }
    toggleModal = () => {
        this.setState({ cierre_modal: !this.state.cierre_modal });
    }

    confirmarCerrar = () => {
        this.toggleModal();
        this.props.cerrarperiodo();
    }

    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModal(true);
    }
    botonModal = (id, row) => {

        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: true
                        })
                        this.props.setModalDescripcion(true);
                     }}
                      alt="Ver eliminado" />)
    }
    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return(
            <Modal open={open_modal} onClose={(e) => {
                this.props.setModalDescripcion(false)
            }} center>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Justificación de anulación </span>
                </div>
                    {
                        (this.state.transaccion_sel) && (
                            <div className="form-validate mb-lg text-center" >
                                <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                            </div>
                        )
                    }


            </Modal>

        )
    }
    render(){
        let { data, item, loader, entidad } = this.props
        return(
            <div>
                {this.renderModalAnulacion()}
                {
                    (entidad === item.origen )&&
                    (
                        <div>
                            <CardsCaja
                                data={this.props.pendientes}
                                loader={loader}
                                aceptarEnvio={this.aceptarEnvio}
                                rechazarEnvio={this.rechazarEnvio}
                                cancelEnvio={this.cancelEnvio}
                                titulo={"Transferencias Pendientes"}
                            />
                            <div className="row col-12 m-0 p-0 d-flex justify-content-md-end justify-content-center">
                                <div className="col-xl-6 col-lg-10 col-md-10 col-12 m-0 p-0 row d-flex justify-content-sm-end justify-content-center">
                                    {/*
                                        (this.props.role === 1) && (
                                            <button type="button"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.props.setOpenModalRetiro(true);
                                                }}
                                            className="btn btn-t btn-primary m-l mt-3">Retiro</button>
                                        )
                                            */}
                                    <button type="button"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.props.setOpenModal(true);
                                    }}
                                    className="btn btn-t btn-primary m-l mt-0">Transferir</button>
                                    <button type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (window.confirm("¿Estás seguro de que quieres cerrar?")) {
                                                this.props.cerrarperiodo();
                                            }
                                        }}
                                        className="btn btn-cerrar m-l mt-0">
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                            <br/>
                        </div>
                    )
                }
                <Grid hover striped data={{count:10, results: data}}  loading={loader}  pagination={false}>
                    <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={
                                standardActions({
                                    adicional: this.botonModal
                                })
                            }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat = {
                            (cell,row)=>(
                                <RenderDateTime fecha={cell}></RenderDateTime>
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        tdStyle={cellTachado}
                        dataField="transaccion"
                        dataSort
                        dataFormat={
                            (cell,row)=> cell? (`${cell.descripcion} `):"-------"
                        }
                    >
                        DESCRIPCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_ingreso"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat={
                            (cell,row)=>(
                                // <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                                cell ? 'Ingreso':'Egreso'
                            )
                        }
                    >
                        MOVIMIENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debe"
                        dataSort
                        tdStyle={cellTachado}
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        DEBE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="haber"
                        dataSort
                        tdStyle={cellTachado}
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        HABER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="saldo"
                        dataSort
                        tdStyle={cellTachado}
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={
                            (cell,row)=>(
                                <RenderMoneda monto={cell} simbolo={this.getsimbolo(row.moneda)} />
                            )
                        }
                    >
                        SALDO
                    </TableHeaderColumn>
                </Grid>
                <br/>

            </div>
        )
    }
}

export default withRouter(Micaja);
