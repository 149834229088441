import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import ProduccionForm from "./produccionForm";

class ProduccionNuevo extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        orden: false,
        values: {},
        estadoRead: false,
        tipo: "N",
    };
    componentWillMount() {
        let direccion = this.props.match.path;
        const { selectEmpresas, leerov, getRecetaOV } = this.props;
        selectEmpresas();
        let orden = this.props.match.params.orden
            ? this.props.match.params.orden
            : null;
        let id_empresa = this.props.match.params.empresa;
        if (orden) {
            this.setState({ orden: true, tipo: "OV" });
            leerov(orden);
            getRecetaOV(orden, id_empresa);
        }
        if (direccion == "/produccion/:empresa/sim") {
            this.setState({ orden: true, tipo: "SI" });
        }
        if (this.props.match.params.empresa) {
            this.setState({
                values: {
                    orden: orden,
                    empresa: id_empresa,
                },
                estadoRead: true,
            });
        }
    }
    onSubmit = (values) => {
        const { crear, editar } = this.props;
        values.empresa = this.props.match.params.empresa;
        crear(values, this.props.match.params.empresa);
    };

    render() {
        const { loader, empresas, itemov } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <br />
                    <ProduccionForm
                        onSubmit={this.onSubmit}
                        empresas={empresas}
                        initialValues={{
                            empresa: this.state.values.empresa,
                            fecha_prox_entrega: new Date(),
                            fecha: new Date(),
                        }}
                        empresa={this.props.match.params.empresa}
                        estadoRead={this.state.estadoRead}
                        getRecetaOV={this.props.getRecetaOV}
                        leerov={this.props.leerov}
                        leersi={this.props.leersi}
                        bandera_orden={this.state.orden}
                        tipo_pro={this.state.tipo}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default ProduccionNuevo;
