import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteRotacionInventario from "../../../Utils/Toolbar/ToolbarReporteRotacionInventario";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";

export default class ReporteRotacionInventario extends Component {
    componentDidMount() {
        //this.props.getReporteRoptacionInventario();
    }

    render() {
        const { loader, loaderGrafica, data, dataGrafica, page, getReporteRoptacionInventario } = this.props;
        return (
            <CardSimple row={true} margen="mt-0" style={{ marginTop: "-50px" }}>
                <ToolbarReporteRotacionInventario
                    changeBodega={this.props.changeBodega}
                    changeProducto={this.props.changeProducto}
                    producto={this.props.producto && this.props.producto}
                    bodega={this.props.bodega && this.props.bodega}
                    changeFechaInicial = {this.props.changeFechaInicial}
                    fecha_inicial = {this.props.fecha_inicial && this.props.fecha_inicial}
                    fecha_final = {this.props.fecha_final && this.props.fecha_final}
                    changeFechaFinal = {this.props.changeFechaFinal}
                />
                <br/>
                <div className="col-12">
                    <hr
                        style={{
                            background: "#408EAA",
                            height: "2px",
                            width: "100%",
                            marginTop: '0px',
                        }}
                        />
                </div>
                <br/>
                <LoadMask loading={loaderGrafica} light type={"TailSpin"}>
                        <div className="col-12 row m-0 p-0 d-flex">
                            <div className="col-md-12 mx-0 px-0">
                                <div className="">
                                    {dataGrafica && dataGrafica.length && (

                                        <div style={{ width: "100%", flex: 1 }}>
                                        <Chart
                                            width={"100%"}
                                            height={250}
                                            chartType="ColumnChart"
                                            loader={<div>Cargando Gráfica</div>}
                                            data={dataGrafica}
                                            options={{
                                                chartArea: { width:"80%", right: "5%" },
                                                bar: { groupWidth: "40%" },
                                                colors: ["#128085", "#F8C9A8"],
                                                vAxis: {
                                                    format: "###,###,###.00",
                                                    baselineColor: "#000",
                                                },
                                                tooltip: {
                                                    format: "###,###,###.00",
                                                },
                                                animation: {
                                                    startup: true,
                                                    easing: "linear",
                                                    duration: 1000,
                                                },
                                                legend: {
                                                    position: "top",
                                                    alignment: "center",
                                                },
                                                fontName: "Cairo-Regular",
                                            }}
                                            formatters={[
                                                {
                                                    type: "NumberFormat",
                                                    column: 1,
                                                    options: {
                                                        prefix: " ",
                                                    },
                                                },
                                            ]}
                                            legendToggle
                                            />
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                <br/>
                <br/>
                <label className="t-primary"> Listado </label>
                <hr
                    style={{
                        background: "#408EAA",
                        height: "2px",
                        width: "100%",
                        marginTop: '0px',
                    }}
                    />
                <div className="col-12 row m-0 p-0 d-flex">
                    <div className="col-md-12 mx-0 px-0">
                        {data && data.results && (
                            <Grid
                                hover
                                striped
                                page={page}
                                data={data}
                                loading={loader}
                                onPageChange={getReporteRoptacionInventario}
                                pagination={true}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="5%"
                                    dataFormat={standardActions({
                                    })}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    width="15%"
                                    dataFormat={(cell) =>
                                        cell ? <RenderDateTime fecha ={cell} /> : "Sin registro"
                                    }
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre_bodega"
                                    dataAlign="left"
                                    dataFormat={(cell)=>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    BODEGA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="label_movimiento"
                                    dataAlign="left"
                                    dataFormat={(cell)=>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    MOVIMIENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad"
                                    dataAlign="left"
                                    dataFormat={(cell) =>
                                        cell ? <RenderNumber value={cell} decimalScale={2} /> : <RenderNumber value={0} decimalScale={2} />
                                    }
                                >
                                    CANTIDAD
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad_anterior"
                                    dataAlign="left"
                                    dataFormat={(cell) =>
                                        cell ? <RenderNumber value={cell} decimalScale={2}  /> : <RenderNumber value={0} decimalScale={2} />
                                    }
                                >
                                    CANTIDAD ANTERIOR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad_nuevo"
                                    dataAlign="left"
                                    dataFormat={(cell)=>
                                        cell ? <RenderNumber value={cell} decimalScale={2}  /> : <RenderNumber value={0} decimalScale={2} />
                                    }
                                >
                                    CANTIDAD ACTUAL
                                </TableHeaderColumn>
                            </Grid>
                        )}
                    </div>
                </div>
            </CardSimple>
        );
    }
}
