import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import StateManager from "react-select";

let url = "bodega";
let form = "TipoCategoriaForm";
let dirGrid = "/admin_tipocategoria";

export const constants = {
    LOADER: "LOADER_EB",
    DATA: "DATA_EB",
    ITEM: "ITEM_EB",
    ITEM_OC: "ITEM_OC",
    ORDEN_BODEGA: "ORDEN_BODEGA",
    PAGE: `PAGE_EB`,
    PAGE2: `PAGE2_EB`,
    ORDERING: `ORDERING_EB`,
    SEARCH: `SEARCH_EB`,
    SET_TIPO: 'TIPO_PRODUCTO',
    OPEN_MODAL: "OPENMODAL_EB",
    LOADER_FORMULARIO: "LOADER_FORMULARIO_EB",
    SET_PENDIENTES: "SET_PENDIENTES",
    SET_PRODUCTOSDS: "SET_PRODUCTOSDS",
    HISTORIA_MOVIMIENTOS: "SET_HISTORIA_EB",
    DESPACHOS_PENDIENTES: "DESPACHOS_PENDIENTES_EB",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});
const setLoaderFormulario = (loader_formulario) => ({
    type: constants.LOADER_FORMULARIO,
    loader_formulario,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setPage2 = (page2) => ({
    type: constants.PAGE2,
    page2,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const setOrden = (orden) => ({
    type: constants.ORDEN_BODEGA,
    orden,
});

const setItemOC = (itemOC) => ({
    type: constants.ITEM_OC,
    itemOC,
});

const listar = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega = bodega;
    params.search = resource.search;
    if(resource.alertado && resource.alertado.value == true){
        params.alerta = resource.alertado.value
    }
    dispatch(setLoader(true));

    api.get(`inventario/getInventario/${bodega}`, params)
        .then((response) => {
            dispatch(setData(constants.DATA, response));
            dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getHistoria = (page = 1, bodega) => (dispatch, getStore) => {
    const resource = getStore().estado_bodega;
    const params = { page };
    params.bodega = bodega;
    params.search = resource.search;
    dispatch(setLoader(true));

    api.get(`despachos/getHistorialBodega`, params)
        .then((response) => {
            dispatch(setData(constants.HISTORIA_MOVIMIENTOS, response));
            dispatch(setPage2(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`)
        .then((response) => {
            console.log("Response, ", response);
            dispatch(setData(constants.ITEM, response));
            if (!!form) dispatch(initializeForm(form, response));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getDespachosPendientes = (id) => (dispatch) => {
    // let params = {bodega_destino: id, flujo_despacho: 10, tipo_despacho:10}
    const params = { tipo_despacho: 10,bodega: id };
    dispatch(setLoader(true));
    api.get(`despachos/getDespachosPendientesHB`, params)
        .then((response) => {
            dispatch(setData(constants.DESPACHOS_PENDIENTES, response.data));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = (data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`${url}`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(listar());
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la creación","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoaderFormulario(false));
            dispatch(setOpenModal(false));
        });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.put(`${url}/${id}`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(listar());
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la edición","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoaderFormulario(false));
            dispatch(setOpenModal(false));
        });
};

const reajustar_inventario = (data, bodega) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.post(`despachos`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(listar(1, bodega));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la operación","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoaderFormulario(false));
            dispatch(setOpenModal(false));
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch(setLoaderFormulario(true));
    api.eliminar(`${url}/${id}`)
        .then(() => {
            dispatch(listar());
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la operación","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoaderFormulario(false));
        });
};

const searchChange = (search, bodega) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1, bodega));
};

const getProductosOC = (orden) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`ordencompra/${orden.id}`)
        .then((data) => {
            for (let i = 0; i < data.detalle_orden.length; i++) {
                if (
                    data.detalle_orden[i] &&
                    data.detalle_orden[i].cantidad_actual <= 0
                ) {
                    data.detalle_orden.splice(i, 1);
                }
            }
            data.orden = {
                id: data.id,
                no_orden: data.no_orden,
            };
            dispatch(setOrden(orden));
            dispatch(setItemOC(data));
            dispatch(initializeForm("IngresoBodegaForm", data));
            //dispatch(change("IngresoBodegaForm", data));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const ingresoProductosBodega = (data, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.bodega = bodega;
    api.post(`despachos`, data)
        .then(() => {
            NotificationManager.success(
                "Productos ingresados a bodega",
                "EXITO!",
                3000
            );
            dispatch(listar(1,bodega))
            dispatch(push(`/estado_bodega/${bodega}`));
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(`${error.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error en el ingreso",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.OPEN_MODAL,
        open_modal: open_modal,
    });
};

const listDS = (id_bodega) => (dispatch) => {
    dispatch(setLoader(true));
    let params = { id_bodega };
    api.get(`despachos/listds`, params)
        .then((res) => {
            dispatch(setData(constants.SET_PENDIENTES, res));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setProductoDespacho = (parametros) => (dispatch) => {
    dispatch(setLoader(true));
    console.log("parametros", parametros);
    let params = { ...parametros };
    api.get(`despachos/getListadepacho`, params)
        .then((res) => {
            dispatch(setData(constants.SET_PRODUCTOSDS, res));
            let detalle = [];
            res.forEach((item, index) => {
                detalle[index] = {
                    producto: item,
                    despachar: item.es_bodega ? item.cantidad_actual : 0,
                };
            });
            dispatch(change("DespachoBodegaForm", `Despachos`, detalle));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const DespacharProducto = (data, orden, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;
    data.bodega = bodega;
    api.post(`despachos/createdespachobodega`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset("DespachoBodegaForm"));
            if (!!dirGrid) dispatch(push(`/estado_bodega/${bodega}`));
            // dispatch(goBack())
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la creación del despacho","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const DespachoEntreBodega = (data, bodega) => (dispatch) => {
    dispatch(setLoader(true));
    data.bodega = bodega;
    api.post(`despachos/despachoEntreBodega`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(push(`/estado_bodega/${bodega}`));
            // dispatch(goBack())
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la creación del despacho","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeTipo = (value, bodega) => (dispatch) => {
    dispatch(setData(constants.SET_TIPO, value));
    dispatch(listar(1,bodega));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    getProductosOC,
    ingresoProductosBodega,
    onSortChange,
    changeTipo,
    setOpenModal,
    reajustar_inventario,
    listDS,
    setProductoDespacho,
    DespacharProducto,
    getHistoria,
    getDespachosPendientes,
    DespachoEntreBodega,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [constants.ITEM_OC]: (state, { itemOC }) => {
        return {
            ...state,
            itemOC,
        };
    },
    [constants.ORDEN_BODEGA]: (state, { orden }) => {
        return {
            ...state,
            orden,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page2 }) => {
        return {
            ...state,
            page2,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.OPEN_MODAL]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.LOADER_FORMULARIO]: (state, { loader_formulario }) => {
        return {
            ...state,
            loader_formulario,
        };
    },
    [constants.SET_TIPO]: (state, { data }) => {
        return {
            ...state,
            alertado: data,
        };
    },
    [constants.SET_PENDIENTES]: (state, { data }) => {
        return {
            ...state,
            pendientes: data,
        };
    },
    [constants.SET_PRODUCTOSDS]: (state, { data }) => {
        return {
            ...state,
            productosDS: data,
        };
    },
    [constants.HISTORIA_MOVIMIENTOS]: (state, { data }) => {
        return {
            ...state,
            historia_movimientos: data,
        };
    },
    [constants.DESPACHOS_PENDIENTES]: (state, { data }) => {
        return {
            ...state,
            despachos_pendientes: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    item: { nombre: "" },
    itemOC: [],
    page: 1,
    page2: 1,
    ordering: "",
    search: "",
    open_modal: false,
    loader_formulario: false,
    pendientes: [],
    productosDS: [],
    historia_movimientos: {
        results: [],
        count: 0,
    },
    alertado: false,
    despachos_pendientes: [],
};

export default handleActions(reducers, initialState);
