import React, { Component } from "react";
import Grid from "../../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import CardSimple from "../../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../../Utils/Toolbar/ToolbarSimple";
import Search from "../../../../Utils/Toolbar/search";
import {
    RenderMoneda,
    RenderNombreSelect,
} from "../../../../Utils/renderField/renderTableField";

export default class ProductoGrid extends Component {
    componentWillMount() {
        const { page } = this.props;
        this.props.getInventario(page, this.props.bodega.id);
    }

    agregarCarrito = (cell, row) => {
        this.props.agregarACarrito({ producto: row, cantidad: 1 });
    };

    render() {
        const {
            data,
            page,
            loaderProductos,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
        } = this.props;
        return (
            <div className="col-12 row mt-5">
                <div className="col-lg-6 col-md-6 p-0 search">
                    <Search
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                    />
                </div>
                <hr
                    style={{
                        background: "#408EAA",
                        height: "2px",
                        width: "100%",
                    }}
                />
                <Grid
                    hover
                    striped
                    data={this.props.productos}
                    page={page}
                    loading={loaderProductos}
                    onPageChange={this.props.getInventario}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn dataField="nombre" dataSort>
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="precio_venta"
                        dataSort
                        width="20%"
                        dataFormat={(cell, row) =>
                            cell ? (
                                <RenderMoneda monto={cell} className="pl-0" />
                            ) : (
                                <RenderMoneda monto={0} className="pl-0" />
                            )
                        }
                    >
                        PRECIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataAlign="center"
                        width="25%"
                        dataFormat = {(cell,row)=>
                            cell ? `${cell} ${row.label_unidad_inventario}` : ''
                        }
                    >
                        EXISTENCIA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        isKey
                        width="20%"
                        dataFormat={standardActions({
                            agregarCarrito: this.agregarCarrito,
                        })}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                </Grid>
            </div>
        );
    }
}
