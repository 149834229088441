import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarFiltroFecha from '../../../Utils/Toolbar/ToolbarFiltroFecha';
import {RenderDateTimeAll, RenderDateTime} from "../../../Utils/renderField/renderTableField";

export default class BodegaGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos["bitacora"] === false) {
            return <Redirect to={`/`} />
        } 
        return (
            <CardSimple
                toolbar= {<div className="pr-4"><ToolbarFiltroFecha
                    buscar={this.props.search}
                    buscador={this.props.buscador}
                    {...this.props}
                    placeholder={"Buscar por: Nombre"}
                /></div>}>
                    <Grid hover striped page={page} data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width="15%"
                            dataSort
                            isKey
                            dataFormat={
                                standardActions({ verpro: "reporte_bitacora_detalle" })
                            }
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat = {
                                (cell,row) => (
                                    <RenderDateTimeAll fecha={cell} ></RenderDateTimeAll>
                                )
                            }
                            dataField="creado" dataSort>FECHA</TableHeaderColumn>
                        <TableHeaderColumn
                            dataFormat= {
                                (cell, row) => (
                                    <span>{cell.first_name} {cell.last_name}</span>
                                )
                            }
                            dataField="usuario" dataSort>USUARIO</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accion" dataSort width="30%">ACCIÓN</TableHeaderColumn>
                    </Grid>
            </CardSimple>
        );
    }
}
