import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { TiposCuentas, Monedas } from '../../../../../utility/constants';
import {SelectField, renderSelectField} from '../../../Utils/renderField/renderField.js'

const renderCuentas = ({fields, meta: {error, submitFailed }, contactos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>NOMBRE</th>
                        <th>BANCO</th>
                        <th>NO. CUENTA</th>
                        <th>TIPO DE CUENTA</th>
                        <th>MONEDA</th>
                        <th>OBSERVACIONES</th>
                    </tr>
                    </thead>
                    <tbody >
                    {fields.map((contacto, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar" 
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.propietario`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top" >
                                <Field
                                    name={`${contacto}.nombre_banco`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.numero_cuenta`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.tipo`}
                                    type="text"
                                    component={renderSelectField}
                                    options={TiposCuentas}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.moneda`}
                                    type="text"
                                    component={renderSelectField}
                                    options={Monedas}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.descripcion`}
                                    type="text"
                                    component={renderTextArea}
                                    rows={3}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    Agregar cuenta
                </button>
            </div>
        </div>
    </div>
)

const CuentaForm = (props) => {
    const { handleSubmit, previousStep, permiso_cuenta } = props;
    return (
        <form name="CuentaForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >Cuentas de banco</h4>
            </div>
            <FieldArray name="cuenta" component={renderCuentas} />
            <div className="row  justify-content-sm-center justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">Anterior</button>
                <button type="submit" className="btn btn-azul-claro m-1 ml-md-4 align-self-center">{permiso_cuenta ? 'Siguiente' : 'Registrar'}</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {}
        if(data.cuenta){
            let detErrors=[]
            data.cuenta.forEach((item,index) => {
                let arrayErrors = {}
                if(!item.propietario){
                    arrayErrors.propietario = "Campo requerido"
                }
                if(!item.nombre_banco){
                    arrayErrors.nombre_banco = "Campo requerido"
                }
                if(!item.numero_cuenta){
                    arrayErrors.numero_cuenta = "Campo requerido"
                }
                if(arrayErrors){
                    detErrors[index] = arrayErrors;
                }
            });
            if(detErrors.length){
                errors.cuenta = detErrors;
            }
        }
        return errors;
    },
})(CuentaForm);
