import React from "react";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    SelectField,
    AsyncSelectField,
} from "../../../Utils/renderField/renderField.js";
import { formatSelects } from "../../../Utils/renderField/renderReadField";

import { renderField, renderSwitch } from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";

const BodegaForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            name="bodegaForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10 row">
                    <div className="form-group has-feedback col-lg-6 col-md-12">
                        <label className="t-azul" htmlFor="nombre">
                            Nombre
                        </label>
                        <Field
                            name="nombre"
                            label="nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-md-12 pt-3">
                        <label className="t-azul" htmlFor="es_principal"></label>
                        <Field
                            name="es_principal"
                            label="¿Bodega principal?"
                            component={renderSwitch}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row  justify-content-sm-center justify-content-center">
                <Link
                    className="btn btn-secondary  align-self-center"
                    to="/bodegas"
                >
                    Cancelar
                </Link>
                <button
                    type="submit"
                    className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "bodegaForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
        });
    },
})(BodegaForm);
