import React, { Component } from 'react'
import {Link, Redirect} from 'react-router-dom'
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
// formulario
import FacturasForm from './FacturasForm';


export default class FacturasContainer extends Component {
    componentDidMount(){
        let id = this.props.match.params.id
        this.props.setMunicipios('13')
        if (id != undefined && id != null){
            this.props.selectProductos()
        }else{
            return (<Redirect to="/ordenesventa" />)
        }
    }
    handleSubmit = (values) => {
        const {BillOC, item} = this.props
        let id = this.props.match.params.id
        console.log('submit', values);
        values.id =  id;
        BillOC(values)
    }
    render() {
        const {loader, item } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <FacturasForm
                        orden={this.props.match.params.id}
                        setCodigoPostal={this.props.setCodigoPostal}
                        onSubmit={this.handleSubmit}
                        setAutomaticoReceptor={this.props.setAutomaticoReceptor}
                        item={item}
                        getMunicipios={this.props.setMunicipios}
                        municipios={this.props.municipios}
                        initialValues={{
                            fecha:new Date(),
                            nombre:"Consumidor Final",
                            nit:"CF",
                            direccion:"Ciudad",
                            departamento:'13',
                            municipios: '1315',
                            codigo_postal:'09001'
                        }}
                    />
                </LoadMask>
            </CardFormulario>
        )
    }
}
