import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";

export default class ContactoGrid extends Component{
    componentWillMount() {
        let id=this.props.CPid
        this.props.selectContactos(id);
    }
    render(){
        const { contactos, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={contactos} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                    <TableHeaderColumn
                        dataField="nombre"
                        isKey
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="puesto"
                        dataSort
                    >
                        PUESTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="telefono"
                        dataSort
                    >
                        TELEFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="correo"
                        dataSort
                    >
                        CORREO
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}