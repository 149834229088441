import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import { Redirect } from "react-router-dom";
import { estado_bodega } from "../../../../../utility/icons";
import { Link } from "react-router-dom";
import Check from '../../../../../../assets/img/icons/Check.png'
import Remove from '../../../../../../assets/img/icons/remove2.png'

export default class BodegaGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    boton_adicional = (id, row) => {
        return (
            <Link to={`estado_bodega/${id}/`} className="px-2">
                <img
                    className="action-img"
                    title="adicional"
                    src={estado_bodega}
                    alt="Estado bodega"
                />
            </Link>
        );
    };

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            page,
            eliminar,
            permisos,
        } = this.props;
        if (permisos["bodegas"] === false) {
            if (!!permisos[`ver_bodegas`] === false) {
                return <Redirect to={`/`} />;
            }
        }
        return (
            <CardSimple
                margen="mt-0"
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="bodega/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        placeholder={"Buscar por: Nombre"}
                        permisos={permisos.bodegas}
                    />
                }
            >
                <Grid
                    hover
                    striped
                    page={page}
                    data={data}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                >
                    {permisos.bodegas && (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width="200"
                            dataFormat={standardActions({
                                editar: "bodega",
                                eliminarBodega: eliminar,
                                adicional: this.boton_adicional,
                            })}
                        ></TableHeaderColumn>
                    )}
                    <TableHeaderColumn isKey dataField="nombre" dataSort>
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="es_principal"
                        dataFormat={cell => {
                            return cell ? <img src={Check} width="20"/> : <img src={Remove} width="20"/>
                        }}
                    >
                        PRINCIPAL
                    </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
