import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const SET_ROLES = 'SET_ROLES';
const SET_EMPRESAS ='SET_EMPRESAS';

export const constants = {
    SUBMIT,
    SET_ROLES,
    SET_EMPRESAS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = (type, data) =>({
    type,
    data
})

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        dispatch(push("/login"));
        NotificationManager.success('Cuenta creada con éxito, puedes iniciar sesión', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Credenciales incorrectas, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

export const selectRoles = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('role/selectroles').then((res)=>{
        dispatch(setData(constants.SET_ROLES, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const actions = {
    onSubmit,
    logOut,
    selectRoles,
    selectEmpresas,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_ROLES]:(state,{ data })=>{
        return{
            ...state,
            roles: data.role,
        }
    },
    [SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
};

export const initialState = {
    loader: false,
    roles: [],
    empresas:[],
};

export default handleActions(reducers, initialState);
