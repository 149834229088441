import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/gastos/gastos';
import GastoDetalle from './GastoDetalle';

const mstp = state => {
    return {...state.gastos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(GastoDetalle)
