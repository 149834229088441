import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Monedas } from "../../../../../utility/constants";
import Modal from "react-responsive-modal";
import {Link} from 'react-router-dom';
import AgregarFForm from "./facturasForm";
import { handleSubmit } from "../../../../../redux/modules/notificaciones/notificaciones";

export default class FacturasGrid extends Component {
    state = {
        open_modal: false,
    };
    getsimbolo = (valor) => {
        let simbolo = "";
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo;
            }
        });
        return simbolo + ". ";
    };
    handleModal = (value) => {
        this.setState({ open_modal: value });
    };
    handleSubmit = (values) => {
        values.orden = this.props.OCid;
        this.props.BillOC(values);
        this.handleModal(false);
    };
    handleDelete = (id) => {
        let values = {
            orden: this.props.OCid,
            id,
        };
        this.props.DeleteOC(values);
    };
    botonFactura = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.factura){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.factura}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }
    botonXml = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        if(row.archivo_xml){
            return (
                <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                    target="_blank" 
                    href={row.archivo_xml}
                    // onClick={(e)=>{
                    //     e.preventDefault();
                    //     this.setState({
                    //     id_transaccion: id,
                    //     transaccion_sel: row
                    // })
                    // this.props.setOpenModal(true);
                    // }}
                    download={`${row.archivo_xml}`}
                >
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/XML.png")} alt="PDF"/>
                </a>
            )
        }else{return(<div></div>)}

    }
    render() {
        const {
            facturas,
            moneda,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            orden,
        } = this.props;
        return (
            <div className="margin-tabgrid px-3">
                <Modal
                    open={this.state.open_modal}
                    showCloseIcon={false}
                    onClose={(e) => {
                        this.setState({ open_modal: false });
                    }}
                    center
                >
                    <div className="col-12 modal-card-header">
                        <span className="col-12 col-lg-10 modal-card-title">
                            Datos facturas
                        </span>
                    </div>
                    <AgregarFForm
                        onSubmit={this.handleSubmit}
                        handleModal={this.handleModal}
                        simbolo={this.getsimbolo(moneda)}
                    />
                </Modal>
                <Grid 
                    hover
                    striped
                    data={{ count: 10, results: facturas }}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    pagination={false}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={standardActions({
                            // eliminar:this.handleDelete
                            adicional:this.botonFactura,
                            adicional2:this.botonXml,
                        })}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="nombre" dataSort>
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="nit" dataSort>
                        NIT
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="no_factura" dataSort>
                        NO DOCUMENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                monto={cell ? cell : 0}
                                simbolo={this.getsimbolo(moneda)}
                            />
                        )}
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
                <div className="row justify-content-sm-start justify-content-end">
                    <div className="col-12 d-flex justify-content-end">
                        <Link  className="btn btn-primary m-1 align-self-center" to={`/facturar/ordenventa/${orden.id}`} >AGREGAR FACTURA</Link>
                        {/* <button
                            onClick={() => {
                                this.handleModal(true);
                            }}
                            className="btn btn-azul-claro m-1 align-self-center"
                        >
                            Agregar factura
                        </button> */}
                    </div>
                </div>
            </div>
        );
    }
}
