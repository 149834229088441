import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _, { inRange } from "lodash";
import moment from "moment";

let url = "reportes";
let form = "";
let dirGrid = "/";

export const constants = {
    LOADER: "LOADER_VENTASXMES",
    LOADER_DETALLE: "LOADER_DETALLE_VENTAS",
    DATA: "DATA_VENTASXMES",
    DATA_DETALLE: "DATA_VENTAS_DETALLE",
    ITEM: "ITEM_VENTAS_DETALLE",
    PAGE: `PAGE_VENTAS_DETALLE`,
    ORDERING: `ORDERING_VENTAS_DETALLE`,
    FECHA_INICIO: "FECHA_INICIO_VENTAS_DETALLE",
    FECHA_FINAL: "FECHA_FINAL_VENTAS_DETALLE",
    VENDEDOR_REPORTE: "VENDEDOR_REPORTE",
    CLIENTE_REPORTE: "CLIENTE_REPORTE",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setLoaderDetalle = (loaderDetalle) => ({
    type: constants.LOADER_DETALLE,
    loaderDetalle,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const getVentasxMes = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/reporteVentasxMesesHB`)
        .then((response) => {
            dispatch(setData(constants.DATA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getVentasxFecha = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoaderDetalle(true));
    const store = getStore().reporteVentas;
    const params = { page };
    if (store.fecha_inicio)
        params.fecha_inicial = moment(store.fecha_inicio).format("YYYY-MM-DD");
    if (store.fecha_final)
        params.fecha_final = moment(store.fecha_final).format("YYYY-MM-DD");
    if(store.cliente)
        params.cliente = store.cliente.id
    if(store.vendedor)
        params.vendedor = store.vendedor.id

    api.get(`${url}/reporteVentasHB`, params)
        .then((response) => {
            dispatch(setPage(page));
            dispatch(setData(constants.DATA_DETALLE, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderDetalle(false));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoaderDetalle(true));
    const params = {id};
    api.get(`${url}/getDetalleVentaReporte`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoaderDetalle(false));
        });
};

const set_fecha_inicio = (fecha) => (dispatch, getStore) => {
    dispatch({ type: constants.FECHA_INICIO, data: fecha });
    dispatch(getVentasxFecha());
};
const set_fecha_final = (fecha) => (dispatch, getStore) => {
    dispatch({ type: constants.FECHA_FINAL, data: fecha });
    dispatch(getVentasxFecha());
};

const resetFechas = () => (dispatch) => {
    console.log("reseteo");
    const fecha_inicio = moment().subtract(7, "days");
    const fecha_final = moment();

    dispatch({ type: constants.FECHA_INICIO, data: fecha_inicio });
    dispatch({ type: constants.FECHA_FINAL, data: fecha_final });
};

const changeVendedor = (vendedor) => (dispatch) => {
    dispatch({type: constants.VENDEDOR_REPORTE, vendedor});
    dispatch(getVentasxFecha())
}

const changeCliente = (cliente) => (dispatch) => {
    dispatch({type: constants.CLIENTE_REPORTE, cliente});
    dispatch(getVentasxFecha())
}

export const actions = {
    set_fecha_inicio,
    set_fecha_final,
    getVentasxMes,
    getVentasxFecha,
    changeVendedor,
    changeCliente,
    leer,
    resetFechas,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.LOADER_DETALLE]: (state, { loaderDetalle }) => {
        return {
            ...state,
            loaderDetalle,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.DATA_DETALLE]: (state, { data }) => {
        return {
            ...state,
            dataDetalle: data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.VENDEDOR_REPORTE]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [constants.CLIENTE_REPORTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [constants.FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [constants.FECHA_FINAL]: (state, { data }) => {
        return {
            ...state,
            fecha_final: data,
        };
    },
};

export const initialState = {
    loader: false,
    loaderDetalle: false,
    data: {},
    dataDetalle: {},
    item: {},
    page: 1,
    ordering: "",
    vendedor: null,
    cliente: null,
    fecha_inicio: moment().subtract(7, "days"),
    fecha_final: moment(),
};

export default handleActions(reducers, initialState);
