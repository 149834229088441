import React, { Component, Fragment } from 'react';
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from '../../Crear/PagoCreditoForm';


import { TipoTransaccion, getsimbolo } from '../../../../../../utility/constants';
import { RenderMoneda, RenderDateTime } from "../../../../Utils/renderField/renderTableField";

import Swal from 'sweetalert2';
import moment from 'moment';


import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';

import { getSimbolo } from '../../../../Utils/renderField/renderTableField';


function cellTachado(cell, row) {
    if(!row.estado){
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 };
    }
    return { whiteSpace: 'normal', padding: 5 };
}


export default class pagosCredito extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        anular_transaccion: true,
        abonar_cheque: false
    }

    abrirModalEliminar = (id, row) => {
        console.log('row gastos, eliminar ', id, row);
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        });
        this.props.setOpenModalAnulacion(true);
    }

    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, orden.orden.id);
            }
        });
    }

    marcarOCCompleta = () => {
        const { orden } = this.props;

        Swal.fire({
            title: '¿Desea marcar la orden de compra como pagada?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagada!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.marcarPagadaOC(orden.orden.id);
            }
        });
    }

    cerrarModal = () => {
        this.props.cerrarModales();
    }

    botonRealizarMovimiento = (id, row) => {
        if(!row.estado) {
            return(<div></div>);
        }
        if(!row.flotante) {
            return(<div></div>);
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    abonar_cheque: false
                });
                this.props.setOpenModalPago(true);
                }}
            >
                <img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/marcar_bono.png")} alt="Ver eliminado"/>
            </a>
        );
    }

    renderModalAnulacion = () => {
        const { open_modal_anulacion } = this.props;
        return (
            <Modal open={open_modal_anulacion} showCloseIcon={ false } onClose={(e) => {
                this.props.setOpenModalAnulacion(false);
            }} center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Justificación de Anulación </span>
                </div>

                {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalAnulacion}
                            />
                        )
                    }

            </Modal>
        );
    }


    botonModal = (id, row) => {
        if(row.estado){
            return(<div></div>);
        }
        return (
            <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row
                });
                this.props.setOpenModalAnulacion(true);
                }}
            >
                <img className="action-img" title="Ver eliminado" src={require("../../../../../../../assets/img/icons/Ver.png")} alt="Ver eliminado"/>
            </a>
        );
    }


    renderModalFlotantePagado = () => {
        let transaccion = null;
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            };
        }

        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Realizar pago </span>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={()=> {
                        this.props.cerrarModales();
                    }}
                />
            </Fragment>
        );
    }


    renderModalAbonarPago = () => {
        const {  pagos_credito, cuentas,  simbolo, orden, item, tipo_gastos } = this.props;

        let simbolo_orden = getSimbolo(orden.orden.moneda);
        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Agregar Pago </span>
                </div>
                <PagoCreditoForm
                    cerrarModal={this.cerrarModal}
                    cuentas={cuentas}
                    simbolo={simbolo}
                    simbolo_orden={simbolo_orden}
                    es_gasto={true}
                    texto_boton_movimiento={"Agregar pago"}
                    titulo_modal="Pagar desde"
                    tipo_gastos={tipo_gastos}
                    onSubmit={(e) => {
                        this.props.abonarGastoOPago(orden.orden.id, true);
                        this.cerrarModal();
                    }}
                />
            </Fragment>
        );
    }

    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD');
                this.props.flotanteChequePagado(values, this.props.id_cuenta);
            }
        });
    }


    render() {
        const {  open_modal_pago, cuentas,  simbolo, orden, item } = this.props;
        let data = {
            count: 1,
            results:orden.pagos
        };

        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <Modal
                    open={open_modal_pago}
                    showCloseIcon={ false }
                    onClose={(e) => {
                        this.props.cerrarModales();
                    }}
                    center
                >
                    {
                        (this.state.abonar_cheque) ? (
                            this.renderModalAbonarPago()
                        ): (
                            this.renderModalFlotantePagado()
                        )
                    }
                </Modal>
                <Grid hover striped data={data}  pagination={false} >
                    <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({
                        // eliminarModal: this.abrirModalEliminar,
                        adicional: this.botonModal,
                        adicional2: this.botonRealizarMovimiento
                    })}
                >
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell,row) => {
                            return(
                                <div>
                                    <span className="font-weight-bold">Fecha </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                    {
                                        (row.fecha_emision) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Fecha emisión </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                            </Fragment>
                                        )
                                    }
                                    <span className="font-weight-bold">Tipo de pago</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                </div>
                            );
                        }
                    }
                >
                    FECHA
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="tipo_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    ENTRO A
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="numero_cuenta"
                    dataSort
                    tdStyle={cellTachado}

                >
                    CUENTA
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            let id = Number(cell.tipo_documento);
                            let cuenta = _.find(TipoTransaccion, x => x.value == id);
                            if(cuenta){
                                return(cuenta.label);
                            }
                        }
                    }
                >
                    TIPO DOCUMENTO
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            return <span>{cell.no_documento}</span>;
                        }
                    }
                >
                    NO. TRANSACCION
                </TableHeaderColumn>
                    <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    tdStyle={cellTachado}
                    headerAlign="center"
                    dataAlign="right"
                    dataFormat ={
                        (cell, row)=>{
                            if(cell){
                                return <RenderMoneda monto={cell} simbolo={getsimbolo(row.moneda)} />;
                            }
                        }
                    }
                >
                    MONTO
                </TableHeaderColumn>
                </Grid>
                {console.log(this.props)}
                {
                (!orden.orden.pago_completo && orden.estado) && (
                    <Fragment>
                        <button type="button" onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                open_modal: true,
                                es_pago: true,
                                es_gasto: false,
                                abonar_cheque: true
                            });
                            this.props.setOpenModalPago(true);
                        }} className="btn btn-mostaza m-1 align-self-center mt-2"
                        >Agregar pago</button>
                        <button type="button" onClick={(e) => {
                                e.preventDefault();
                                this.marcarOCCompleta();
                            }} className="btn  btn-primary  m-1 align-self-center mt-2"
                         >Orden de compra pagada </button>
                    </Fragment>
                )
            }

            </Fragment>
        );
    }
}
