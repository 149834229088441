import React, { Component } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { PROCESOS } from '../../../../../utility/constants'
import CardPvEnviadas from '../../../Utils/Cards/cardsPM';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Card } from 'antd';
import { Redirect } from 'react-router-dom';


export default class OrdenVentaGrid extends Component {
    componentDidMount() {
        const { listar, page, permisos } = this.props;
        listar(page);
        this.props.handleData(this.props.value)
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        
        return (
            <Grid page={this.props.page} hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    dataFormat={
                        standardActions({ editar: "peticionesventa",  eliminarpm:eliminar, })
                    }
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    dataFormat = {(cell, row) => {
                        return <RenderDateTime fecha={cell} />
                    }}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_orden"
                    dataSort
                >
                    NO. ORDEN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="proceso_estado"
                    dataSort
                >
                    ESTADO
                </TableHeaderColumn>
            </Grid>
        );
    }
}
