import { handleActions } from 'redux-actions';
import { api } from "api";

import * as firebase from "firebase";

const LOADER = 'FCM_LOADER';
const HIDE_ALERT = 'HIDE_ALERT';
const MESSAGING = 'MESASSING';
export const constants = {
  LOADER,
  MESSAGING
};
// ------------------------------------
// Actions
// ------------------------------------

export const setHideAlert = (value) => (dispatch) => {
  dispatch({ type: HIDE_ALERT, hideAlert: value });
};

export const initializeFirebase = () => (dispatch) => {
  api.get('fcm/firebase').catch((error) => {}).then((data) => {
    try {

      firebase.initializeApp(data);
      dispatch({ type: MESSAGING, messaging: firebase.messaging() });
    }catch (e) {
        console.error('Error al iniciar firebase', e)
    }
  });
};
export const newToken = (token) => (dispatch) => {
  const body = {token};
  api.post('fcm/nuevo', body).catch((error) => {}).then((data) => {
    dispatch({ type: HIDE_ALERT, hideAlert: true });
  });
};

export const actions = {
  setHideAlert,
  initializeFirebase,
  newToken,
};

export const reducers = {
  [HIDE_ALERT]: (state, { hideAlert }) => {
    return {
      ...state,
      hideAlert,
    };
  },
  [MESSAGING]: (state, { messaging }) => {
    return {
      ...state,
      messaging,
    };
  },
};

export const initialState = {
  hideAlert: false,
  messaging: {},
};

export default handleActions(reducers, initialState);
