import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/producto/producto';
import ProductoGrid from './ProductoGris';

const mstp = state => {
    return {...state.productos,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ProductoGrid)


