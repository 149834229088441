import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/Reporte/dashboard';
import {actions as acciones_duda} from '../../../../redux/modules/Reporte/deuda'
import Home from './Home';

const mstp = state => {
    return {
        ...state.dashboard,
        totalizado:state.Reportes.totalizado,
        permisos: state.login.me.permisos
    }  
};

const mdtp = {
    ...actions,
    ...acciones_duda,
};

export default connect(mstp,mdtp)(Home);
