import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule} from "react-notifications";
import _ from 'lodash';
import moment from 'moment';

import {BanderaEmpresa} from '../../../utility/constants';

let url = 'ordencompra' ;
let form = 'PeticionForm';
let dirGrid = '/peticiones';

export const constants ={
    LOADER: 'PM_LOADER',
    DATA: 'PM_DATA',
    ITEM: `PM_ITEM`,
    PAGE: `PM_PAGE`,
    ORDERING: `PM_ORDERING`,
    SEARCH: `PM_SEARCH`,
    SET_EMPRESAS:'EMPRESAS_PM',
    SET_CHILDS:'SET_CHILDS',
    SET_PENDIENTES:'SET_PENDIENTES',
}

const setLoader = loader =>({
    type:constants.LOADER,
    loader
})

const setData = (type, data) => ({
    type,
    data
})

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});


const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().peticionM;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.pm = true;
    params.estate = estate
    dispatch(setLoader(true));
    api.get(`${url}`, params).then((response) => {
        dispatch(setData(constants.DATA ,response));
        dispatch(setPage(page));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(initializeForm([]))
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.pm = true;
    api.get(`${url}/${id}`, params).then((response) => {
        dispatch({type: constants.ITEM, item: response});
        if (!!form)
            dispatch(initializeForm(form, response));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    data.empresa = `${BanderaEmpresa}`
    api.post(`${url}/createpm`, data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(reset(form));
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la creación de Peticion de Materiales","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}/updatepm`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        if (!!dirGrid)
            dispatch(push(dirGrid));
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error en la edición","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((e) => {
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error al tratar de eliminar el registro","ERROR")
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1,1));
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) =>{
    dispatch(setLoader(true));
    api.get('empresa/selectempresas').then((res)=>{
        dispatch(setData(constants.SET_EMPRESAS, res))
    }).catch(()=>{
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

const refusePM = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/refusepm`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const acceptPM = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/acceptpm`, id).then((res)=>{
        NotificationManager.success(res.detail, 'Éxito', 3000);
        dispatch(push(dirGrid));
        // dispatch(push(`/ordencompra/${res.id}`));
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listChilds = (id) => (dispatch) =>{
    dispatch(setLoader(true));
    api.post(`${url}/listchildsOC`, id).then((res)=>{
        dispatch(setData(constants.SET_CHILDS, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}

const listPM = (val) => (dispatch) =>{
    dispatch(setLoader(true));
    let params = {val}
    api.get(`${url}/listpm`, params).then((res)=>{
        dispatch(setData(constants.SET_PENDIENTES, res))
    }).catch((e)=>{
        if(e && e.detail){
            NotificationManager.error(`${e.detail}`,"ERROR")
        }else{
            NotificationManager.error("Hubo un error","ERROR")
        }
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}


export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    searchChange2,
    onSortChange,
    selectEmpresas,
    refusePM,
    acceptPM,
    listChilds,
    listPM,
};


export const reducers ={
    [constants.LOADER]:(state,{loader}) =>{
        return{
            ...state,
            loader,
        };
    },
    [constants.DATA]:(state,{data}) => {
        return{
            ...state,
            data
        }
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]:(state,{ data })=>{
        return{
            ...state,
            empresas: data.empresa,
        }
    },
    [constants.SET_CHILDS]:(state,{ data })=>{
        return{
            ...state,
            hijos: data,
        }
    },
    [constants.SET_PENDIENTES]:(state,{ data })=>{
        return{
            ...state,
            pendientes: data,
        }
    },
};


export const initialState ={
    loader:false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    empresas: [],
    hijos:[],
    pendientes:[],
};

export default handleActions(reducers, initialState);
