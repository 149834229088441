import React, { Component, Fragment } from "react";
import {
    Field,
    reduxForm,
    formValueSelector,
    change,
    FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
} from "../../../Utils/renderField";
import { TipoTransaccion } from "../../../../../utility/constants";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { CreatableSelectField } from "Utils/renderField/renderField";
import Modal from "react-responsive-modal";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";

class renderItemsNits extends Component {
    render() {
        let {
            fields,
            simbolo,
            Nits,
            meta: { error, submitFailed },
        } = this.props;
        return (
            <div>
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: "35%" }}>NIT</th>
                            <th style={{ width: "30%" }}>NOMBRE</th>
                            <th style={{ width: "25%" }}>MONTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((OrdenNit, index) => (
                            <tr key={index}>
                                <td
                                    className="text-center sin-borde-top"
                                    style={{ width: "48px" }}
                                >
                                    <img
                                        className="action-img iconoAcciones iconoEliminar"
                                        src={require("assets/img/icons/Cerrar.png")}
                                        alt="Borrar"
                                        title="Borrar"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${OrdenNit}.propietario`}
                                        type="text"
                                        options={Nits}
                                        component={CreatableSelectField}
                                        labelKey="Nit"
                                        valueKey="id"
                                        placeholder="Seleccione o Esciba"
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                        onChange={(e) => {
                                            this.props.setNombre_nit(
                                                index,
                                                e.nombre
                                            );
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${OrdenNit}.nombre`}
                                        type="text"
                                        component={renderField}
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top">
                                    <Field
                                        name={`${OrdenNit}.monto_factura`}
                                        type="number"
                                        prefix={simbolo}
                                        component={renderCurrency}
                                        top={{
                                            top: "67px",
                                            position: "inherit",
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="d-flex justify-content-start mt-2">
                    <button
                        type="button"
                        className="btn btn-primary py-0"
                        onClick={() => fields.push({})}
                    >
                        Agregar nit
                    </button>
                </div>
                {submitFailed && error && (
                    <div className="invalid-feedback-array text-danger">
                        {error}
                    </div>
                )}
            </div>
        );
    }
}

class PagoOCForm extends Component {
    state = {
        open_modal: false,
        es_pago: false,
        es_cuenta: true,
        es_gasto: false,
    };
    componentDidMount() {
        if (this.props.pago_inmediato == true) {
            this.setState({
                es_pago: false,
                open_modal: true,
            });
        }
    }
    // componentWillUpdate(pre)
    componentDidUpdate(prevProps, prevState) {
        if (this.props.pago_inmediato !== prevProps.pago_inmediato) {
            if (!this.props.pago_inmediato) {
                // this.setState({
                //     es_pago: false,
                //     open_modal: true
                // })
                this.props.setDias_de_credito(this.props.dias_credito);
            }
        }
    }
    renderDataFacturacion = () => {
        const {
            handleSubmit,
            previousStep,
            cuentas,
            tipo_documento,
            simbolo,
        } = this.props;
        return (
            <Fragment>
                <div className="row col-12 m-0 p-0">
                    <div className={`form-group has-feedback  col-12 `}>
                        <FieldArray
                            name="Ordennits"
                            component={renderItemsNits}
                            Nits={this.props.Nits}
                            simbolo={this.props.simbolo}
                            setNombre_nit={this.props.setNombre_nit}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };
    renderDiasCredito = () => {
        const { role } = this.props;

        return (
            <Fragment>
                <div className="row col-12 col-lg-10 m-0 p-0 d-flex justify-content-start">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="dias_al_credito">
                            Días de crédito
                        </label>
                        <Field
                            readOnly={role ? false : true}
                            name="dias_al_credito"
                            label="dias_al_credito"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
            </Fragment>
        );
    };
    eliminarPago = (id, row) => {
        this.props.eliminarPagoCredito(id);
    };
    eliminarGasto = (id, row) => {
        this.props.eliminarGasto(id);
    };

    cerrarModal = () => {
        this.setState({ open_modal: false });
    };

    onSubmitTipoCuenta = (values) => {
        this.setState({
            es_cuenta: values.tipo_cuenta == "true" ? true : false,
        });
        this.cerrarModal();
    };
    render() {
        const {
            handleSubmit,
            previousStep,
            proveedor,
            agregarGastoOC,
            cuentas,
            agregarPagoCredito,
            simbolo,
            total,
            pago_inmediato,
        } = this.props;

        return (
            <Fragment>
                <form
                    name="pagoOCForm"
                    className="form-validate mb-lg"
                    onSubmit={handleSubmit}
                >
                    {console.log(this.props)}
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-12 row m-0 p-0">
                            <div className="col-12 row d-flex justify-content-center">
                                <div className="row col-12 col-lg-10 m-0 p-0 d-flex justify-content-start inline-header">
                                    <div
                                        className={`form-group has-feedback col-12 col-md-4 col-lg-4 align-items-end d-flex inline-headers col-12`}
                                    >
                                        <span
                                            className="t-texto h6 mr-3"
                                            htmlFor="descripcion"
                                        >
                                            CLIENTE:
                                        </span>
                                        <h5
                                            className="t-primary"
                                            htmlFor="descripcion"
                                        >
                                            {proveedor}
                                        </h5>
                                    </div>
                                    <div
                                        className={`form-group has-feedback d-flex col-12 col-md-4 col-lg-4 align-items-end inline-headers col-12`}
                                    >
                                        <span
                                            className="t-texto h6 mr-3"
                                            htmlFor="descripcion"
                                        >
                                            TOTAL:
                                        </span>
                                        <h5
                                            className="t-primary"
                                            htmlFor="descripcion"
                                        >
                                            {
                                                <RenderMoneda
                                                    monto={total}
                                                    simbolo={simbolo}
                                                />
                                            }
                                        </h5>
                                    </div>
                                    {this.props.credito != null && (
                                        <div
                                            className={`form-group has-feedback d-flex col-12 col-md-4 col-lg-4 align-items-end inline-headers col-12`}
                                        >
                                            <span
                                                className="t-texto h6 mr-3"
                                                htmlFor="credito"
                                            >
                                                CREDITO:
                                            </span>
                                            <h5
                                                className="t-primary"
                                                htmlFor="credito"
                                            >
                                                {
                                                    <RenderMoneda
                                                        monto={
                                                            this.props.credito
                                                        }
                                                        simbolo={simbolo}
                                                    />
                                                }
                                            </h5>
                                        </div>
                                    )}
                                </div>

                                <div className="row col-12 col-lg-10 m-0 p-0 d-flex justify-content-start">
                                    <div
                                        className={`form-group has-feedback col-md-5 col-lg-4 col-12`}
                                    >
                                        <Field
                                            name="pago_inmediato"
                                            label="Pago Inmediato"
                                            component={renderFieldRadio}
                                            type="radio"
                                            value={"true"}
                                            className="form-control"
                                        />
                                    </div>
                                    <div
                                        className={`form-group has-feedback col-md-5 col-lg-4 col-12`}
                                    >
                                        <Field
                                            value={"false"}
                                            name="pago_inmediato"
                                            label="Pago al Crédito"
                                            component={renderFieldRadio}
                                            type="radio"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                {!pago_inmediato && this.renderDiasCredito()}
                            </div>
                            <br />
                            {this.renderDataFacturacion()}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                                <button
                                    onClick={previousStep}
                                    className="btn btn-secondary  align-self-center"
                                >
                                    Anterior
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                                >
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}

PagoOCForm = reduxForm({
    form: "PeticionVentaForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if (data.pago_inmediato == "false") {
            if (data.dias_al_credito <= 0) {
                errors.dias_al_credito = "Debe ser mayor a 0";
            }
        }
        if (data.Ordennits.length > 0) {
            let count = 0;
            data.Ordennits.forEach((item) => {
                count = count + parseFloat(item.monto_factura);
            });
            if (count != props.total) {
                errors.Ordennits = {
                    _error: "La sumatoria no coincide con el total a facturar",
                };
            }
        } else {
            errors.Ordennits = {
                _error: "debe ingresar una forma de facturacion",
            };
        }
        return errors;
    },
})(PagoOCForm);

const selector = formValueSelector("PeticionVentaForm");
PagoOCForm = connect((state, props) => {
    let todos_productos = [];
    let simbolo = "Q. ";
    let valor_iva = 0;

    const productos = selector(state, "detalle_orden");
    const moneda = selector(state, "moneda");
    const descuento = selector(state, "descuento");
    const tiene_iva = true;
    const tipo_movimiento = selector(state, "tipo_documento");
    const proveedor = selector(state, "proveedor");
    let dias_de_credito = selector(state, "dias_al_credito");

    let pago_inmediato = selector(state, "pago_inmediato");
    let tipo_documento = "Documento de Registro";
    if (tipo_movimiento) {
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento;
        });
        tipo_documento = seleccionado.ayuda;
    }
    if (moneda) {
        simbolo = getSimbolo(moneda);
    }
    if (pago_inmediato == "true") {
        pago_inmediato = true;
    } else {
        pago_inmediato = false;
    }
    if (productos) {
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    todos_productos.forEach((x) => {
        if (!isNaN(x.cantidad) && !isNaN(x.precio_unitario)) {
            subtotal += Number(x.cantidad) * Number(x.precio_unitario);
        }
    });

    total = subtotal;
    if (descuento) {
        total = total - descuento;
    }
    if (tiene_iva) {
        valor_iva = total * 0.12;
        subtotal = subtotal - valor_iva;
        // total = total + valor_iva;
    }

    return {
        pago_inmediato,
        simbolo,
        tipo_documento,
        total,
        proveedor: proveedor ? proveedor.nombre : "--",
        dias_credito: proveedor.dia_credito,
        Nits: proveedor.empresa,
    };
})(PagoOCForm);

export default PagoOCForm;
