import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/deuda';
import OrdenesPorPoC from './ordenespor';

const mstp = state => {
    return {...state.Reportes, es_cliente:false, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(OrdenesPorPoC)
