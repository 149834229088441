import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/Actividades/asignacionActividades';
import AsignacionesCrear from './AsignacionesCrear';

const mstp = state => {
    return {...state.asignacionActividades ,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AsignacionesCrear)


