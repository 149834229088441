import React, {Component} from 'react';

var monthByNumber = exports.monthByNumber = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
};

var numberByMonth = exports.numberByMonth = {
    Enero: 0,
    Febrero: 1,
    Marzo: 2,
    Abril: 3,
    Mayo: 4,
    Junio: 5,
    Julio: 6,
    Agosto: 7,
    Septiembre: 8,
    Octrubre: 9,
    Noviembre: 10,
    Diciembre: 11
};

var daysInMonth = exports.daysInMonth = {
    0: 31,
    1: 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31
};

var unit = exports.unit = {
    day: 'day',
    month: 'month',
    year: 'year'
};
export  class DropDownDate extends Component{
    state = {
        startYear: 0,
        startMonth: 0,
        startDay: 0,
        endYear: 0,
        endMonth: 0,
        endDay: 0,
        selectedYear: 0,
        selectedMonth: 0,
        selectedDay: 0,
    }

    componentWillMount(){
        var startYear = void 0,
        startMonth = void 0,
        startDay = void 0,
        endYear = void 0,
        endMonth = void 0,
        endDay = void 0,
        selectedYear = void 0,
        selectedMonth = void 0,
        selectedDay = void 0;
        // console.log("SELECTED",this.props.selectedDate)
        if(this.props.startDate){
            var date = new Date(this.props.startDate)
            startYear = date.getFullYear();
            startMonth = date.getMonth();
            startDay = date.getDate();
        }else{
            startYear = 1990;
            startMonth = 0;
            startDay = 1;
        }
        if (this.props.endDate) {
            var _date = new Date(this.props.endDate);
            endYear = _date.getFullYear();
            endMonth = _date.getMonth();
            endDay = _date.getDate();
        } else {
            var _date2 = new Date();
            endYear = _date2.getFullYear();
            endMonth = _date2.getMonth();
            endDay = _date2.getDate();
        }
        if (this.props.selectedDate) {
            var _date3 = new Date(this.props.selectedDate);
            selectedYear = _date3.getFullYear();
            selectedMonth = _date3.getMonth();
            selectedDay = _date3.getDate();
        } else {
            selectedYear = -1;
            selectedMonth = -1;
            selectedDay = -1;
        }
        this.setState(
            { startYear: startYear, startMonth: startMonth, startDay: startDay, endYear: endYear, endMonth: endMonth, endDay: endDay, selectedYear: selectedYear, selectedMonth: selectedMonth, selectedDay: selectedDay }
        );
    }

    componentWillReceiveProps(nextProps){
        // console.log("nextprops",nextProps.selectedDate)
        if (nextProps.selectedDate !== this.props.selectedDate) {
            var date = new Date(nextProps.selectedDate);
            var selectedYear = date.getFullYear();
            var selectedMonth = date.getMonth();
            var selectedDay = date.getDate();
            this.setState({ selectedYear: selectedYear, selectedMonth: selectedMonth, selectedDay: selectedDay });
            // this.handleYearChange(selectedYear)
            // this.handleMonthChange(selectedMonth)
            // this.handleDayChange(selectedDay)
        }
    }
    generateYearOptions = ()=>{
        var _state = this.state;
        var startYear = _state.startYear;
        var endYear = _state.endYear;
        var yearOptions = [];
        for (var _i = startYear; _i <= endYear; _i++) {
            yearOptions.push(_i);
        }
        /* yearOptions.map((item, index) => {
            yearOptions[index] = <option value={`${item}`}>{item}</option>
        }) */

        return yearOptions
    }
    generateMonthOptions = ()=>{
        var _state2 = this.state;
        let startMonth = _state2.startMonth;
        let endMonth = _state2.endMonth;
        let startYear = _state2.startYear;
        let endYear = _state2.endYear;
        let selectedYear = _state2.selectedYear;
        var months = [];
        if (selectedYear === startYear) {
            for (var i = startMonth; i <= 11; i++) {
                months.push({
                    value: i,
                    month: monthByNumber[i]
                });
            }
        } else if (selectedYear === endYear) {
            for (var _i2 = 0; _i2 <= endMonth; _i2++) {
                months.push({
                    value: _i2,
                    month: monthByNumber[_i2]
                });
            }
        } else {
            for (var _i3 = 0; _i3 <= 11; _i3++) {
                months.push({
                    value: _i3,
                    month: monthByNumber[_i3]
                });
            }
        }
        /* let monthOptions =[]
        month.map((item, index) => {
            monthOptions[index]= <option value={`${item.value}`}>{item.month}</option>
        }) */
        return months
    }

    generateDayOptions = ()=>{
        var _state3 = this.state;
        let startYear = _state3.startYear;
        let startMonth = _state3.startMonth;
        let startDay = _state3.startDay;
        let endYear = _state3.endYear;
        let endMonth = _state3.endMonth;
        let endDay = _state3.endDay;
        let selectedYear = _state3.selectedYear;
        let selectedMonth = _state3.selectedMonth;
        var dayOptions = [];
        var monthDays = void 0;
        if (selectedYear === startYear) {
            if (selectedMonth === startMonth) {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                for (var i = startDay; i <= monthDays; i++) {
                    dayOptions.push(i);
                }
            } else {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                for (var _i4 = 1; _i4 <= monthDays; _i4++) {
                    dayOptions.push(_i4);
                }
            }
        } else if (selectedYear === endYear) {
            if (selectedMonth === endMonth) {
                for (var _i5 = 1; _i5 <= endDay; _i5++) {
                    dayOptions.push(_i5);
                }
            } else {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                for (var _i6 = 1; _i6 <= monthDays; _i6++) {
                    dayOptions.push(_i6);
                }
            }
        } else {
            if (selectedMonth) {
                monthDays = selectedYear % 4 === 0 && selectedMonth === 1 ? daysInMonth[selectedMonth] + 1 : daysInMonth[selectedMonth];
                for (var _i7 = 1; _i7 <= monthDays; _i7++) {
                    dayOptions.push(_i7);
                }
            } else {
                for (var _i8 = 1; _i8 <= 31; _i8++) {
                    dayOptions.push(_i8);
                }
            }
        }
        /* return dayOptions.map((item, index) => {
            <option value={`${item}`}>{item}</option>
        }) */
        return dayOptions

    }
    handleYearChange =(year)=>{
        year = parseInt(year)
        this.setState({
            selectedYear:year
        });
        if (this.props.onYearChange){
            this.props.onYearChange(year)
        }
        this.handleDateChange(unit.year, year);
    }
    handleMonthChange =(month)=>{
        month = parseInt(month);
        this.setState({ selectedMonth: month });
        if (this.props.onMonthChange) {
            this.props.onMonthChange(monthByNumber[month]);
        }
        this.handleDateChange(unit.month, month);
    }
    handleDayChange =(day)=>{
        day = parseInt(day);
        this.setState({ selectedDay: day });
        if (this.props.onDayChange) {
            this.props.onDayChange(day);
        }
        this.handleDateChange(unit.day, day);
    }
    handleDateChange =(type, value)=>{
        if (this.props.onDateChange) {
            var _state4 = this.state,
                selectedYear = _state4.selectedYear,
                selectedMonth = _state4.selectedMonth,
                selectedDay = _state4.selectedDay;

            if (type === unit.year) {
                selectedYear = value;
            } else if (type === unit.month) {
                selectedMonth = value;
            } else if (type === unit.day) {
                selectedDay = value;
            }
            if (selectedYear !== -1 && selectedMonth !== -1 && selectedDay !== -1) {
                this.props.onDateChange(new Date(selectedYear, selectedMonth, selectedDay));
            }
        }
    }

    render(){
        const {className, input} = this.props
        return(
            <div className="d-flex">
                <div className={this.props.className}
                style={{ marginRight: "0.7rem" }}
                >
                    <select
                        className="btn btn-outline-secondary dropdown"
                        name={"dia"}
                        value={this.state.selectedDay}
                        onChange={(e)=>this.handleDayChange(e.target.value)}
                    >
                        {
                            this.generateDayOptions().map((item, index) => (
                                 <option
                                    key={`${index}`}
                                    selected
                                    className="dropop"
                                    value={`${item}`}
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className={this.props.className}
                style={{ marginRight: "0.7rem" }}
                >
                    <select
                        className="btn btn-outline-secondary month dropdown"
                        name={"mes"}
                        value={this.state.selectedMonth}
                        onChange={(e)=>this.handleMonthChange(e.target.value)}
                    >
                        {
                            this.generateMonthOptions().map((item, index) => {
                                return <option
                                    key={`${index}`}
                                    className="dropop"
                                    value={`${item.value}`}
                                >
                                    {item.month}
                                </option>
                                // return <option value={`${item.value}`}>{'hola'}</option>
                            })
                        }
                    </select>
                </div>
                <div className={this.props.className}
                style={{ marginRight: "0.7rem" }}
                >
                    <select
                        className="btn btn-outline-secondary dropdown"
                        name={"anio"}
                        value={this.state.selectedYear}
                        onChange={(e)=>this.handleYearChange(e.target.value)}
                    >
                        {
                            this.generateYearOptions().map((item, index) => {
                                return (
                                    <option
                                        key={`${index}`}
                                        value={`${item}`}
                                        className="dropop"
                                    >
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        )
    }
}
