
import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import { renderAntTimeField } from '../../../Utils/renderField/renderField';


const renderContactos = ({fields, meta: {error, submitFailed }, contactos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>NOMBRE</th>
                        <th>PUESTO</th>
                        <th>TELEFONO</th>
                        <th>E-MAIL</th>
                    </tr>
                    </thead>
                {/* </table>

            </div>
           
            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped"> */}
                    <tbody>
                    {fields.map((contacto, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img iconoAcciones iconoEliminar" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar" 
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.nombre`}
                                    type="text"
                                    component={renderField}
                                    top={{ top: "4px", position: "inherit" }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.puesto`}
                                    type="puesto"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.telefono`}
                                    type="telefono"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.correo`}
                                    type="correo"
                                    component={renderField}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    Agregar contacto
                </button>
            </div>
        </div>
    </div>
)


const ContactoForm = (props) => {
    const { handleSubmit, previousStep } = props;
    return (
        <form name="ContactoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >Contactos</h4>
            </div>
            <FieldArray name="contactos" component={renderContactos} />
            <div className="row  justify-content-sm-center justify-content-center">
                <button onClick={previousStep} className="btn btn-secondary m-1 align-self-center">Anterior</button>                        
                <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">Siguiente</button>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ClientesForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        const errors = {}
        if(data.contactos){
            let detErrors=[]
            data.contactos.forEach((item,index) => {
                let arrayErrors = {}
                if(!item.nombre){
                    arrayErrors.nombre = "Campo requerido"
                }
                if(arrayErrors){
                    detErrors[index] = arrayErrors;
                }
            });
            if(detErrors.length){
                errors.contactos = detErrors;
            }
        }
        return errors;
    },
})(ContactoForm);
