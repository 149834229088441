import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/cajachica/caja';
import CajasGrid from './CajaChicaGrid';

const mstp = state => {
    return {...state.caja, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CajasGrid)
