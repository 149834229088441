import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormularioOrden';

//Frmularios
import OrdenForm from './ordencompraForm';
import PagoOCForm from './pagoOCForm';
import EntregaOCForm from './entregaOCForm';
import FacturaOCForm from './facturasOCForm';

class OrdenCompraCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'Orden de compra',
        step: 1,
        justificacion: false
    }
    componentWillMount(){
        this.props.selectEmpresas();
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leerPM(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'Orden de compra',
                justificacion: true
            });
        }
    }
    componentWillUnmount(){
        this.props.resetFormulario();
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id
        let dataForm = _.cloneDeep(values)
        dataForm.direccion = dataForm.direccion.value;
        this.state.editar? editar(id,dataForm) : crear(dataForm);
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    handleCuentas =(idEmpresa, moneda) =>{
        idEmpresa &&(this.props.selectCuentas(idEmpresa, moneda))
    }


    render() {
        const { loader,
                empresas,
                cuentas,
                agregarGastoOC, gastos_oc, pagos_credito,
                agregarPagoCredito,
                eliminarGasto,
                verificarPreciosProductos,
                eliminarPagoCredito } = this.props;
        const { step} = this.state;
        return (
            <CardFormulario
                step={this.state.step}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <Fragment>
                        {step === 1 && (
                            <OrdenForm
                                verificarPreciosProductos={verificarPreciosProductos}
                                handleCuentas={this.handleCuentas}
                                cuentas={cuentas}
                                onSubmit={this.nextStep}
                                empresas={empresas}
                                {...this.props}
                                editar={this.state.editar}
                                initialValues={{
                                    'pago_inmediato': 'true',
                                    'tipo_cuenta': 'true',
                                    'fecha': new Date(),
                                    'fecha_prox_entrega': new Date(),
                                    'detalle_orden': [{cantidad: 1}]
                                }}
                                proceso_estado={this.props.item.proceso_estado}
                                estado_justificacion={this.state.justificacion}
                                justificacion={this.props.item.justificacion}
                                />
                            )
                        }
                        {step === 2 && (
                            <PagoOCForm
                                previousStep={this.previousStep}
                                cuentas={cuentas}
                                pagos_credito={pagos_credito}
                                onSubmit={this.nextStep}
                                agregarPagoCredito={agregarPagoCredito}
                                eliminarPagoCredito={eliminarPagoCredito}
                                eliminarGasto ={eliminarGasto}
                                agregarGastoOC={agregarGastoOC}
                                gastos_oc={gastos_oc}
                                proceso_estado={this.props.item.proceso_estado}
                                estado_justificacion={this.state.justificacion}
                                justificacion={this.props.item.justificacion}
                                />
                            )
                        }
                        {
                            (step === 3) && (
                                <EntregaOCForm
                                    onSubmit={this.onSubmit}
                                    previousStep={this.previousStep}
                                    initialValues={{
                                        'fecha_prox_entrega': new Date()
                                    }}
                                    proceso_estado={this.props.item.proceso_estado}
                                    estado_justificacion={this.state.justificacion}
                                    justificacion={this.props.item.justificacion}
                                />
                            )
                        }
                    </Fragment>
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default OrdenCompraCrear;
