import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from "../../../Utils/Cards/cardFormulario";
import PeticionesForm from "./PMform";

class PeticionForm extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state = {
        editar: false,
        titulo: "Nueva peticion",
    };
    componentDidMount() {
        this.props.selectEmpresas();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id;
            this.setState({ editar: true, titulo: "Editar peticion" });
            this.props.leer(id);
        }
    }
    handleSubmit = (values) => {
        //id de la cuenta a la que pertenece la nueva transaccion
        let id = this.props.match.params.id;
        values.tipo_orden = 4;
        const { crear, editar } = this.props;
        this.state.editar ? editar(id, values) : crear(values);
    };

    render() {
        const { loader, empresas, item } = this.props;
        return (
            <CardFormulario>
                <LoadMask loading={loader} light>
                    {item.justificacion &&
                        this.state.editar &&
                        item.justificacion.length > 0 && (
                            <div className="row justify-content-center text-center">
                                <div className="col-12 col-md-10">
                                    <h6 className="t-mostaza">
                                        Justificacion de rechazo:{" "}
                                    </h6>
                                    <p
                                        style={{ fontWeight: 600 }}
                                        className="t-primary"
                                    >
                                        {
                                            item.justificacion[
                                                item.justificacion.length - 1
                                            ].justificacion
                                        }
                                    </p>
                                </div>
                            </div>
                        )}
                    <PeticionesForm
                        onSubmit={this.handleSubmit}
                        initialValues={{ fecha: new Date() }}
                        empresas={empresas}
                    />
                </LoadMask>
            </CardFormulario>
        );
    }
}

export default PeticionForm;
