import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import ProductosGrid from "./productoGrid";
import FacturasGrid from "./facturasGrid";
// import TransporteDetalle from './transporteDetalle';
// import GastoGrid from './Gastos/gastosGrid';
import PagosContainer from "./Pagos/pagocContainer";
import DespachoContainer from "./Despacho/despachoContainer";
// import ReintegroGrid from './Reintegros/reintegro';
// import OCpreview from './previewOC/OCpreview';

import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { TIPO_ENTREGA } from "../../../../../utility/constants";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

import Swal from "sweetalert2";

export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.props.clear();
        let id = this.props.match.params.id;
        this.props.leerDespacho(id);
    }
    getEntrega = (valor) => {
        let entrega = "";
        TIPO_ENTREGA.forEach((item, index) => {
            if (item.value === valor) {
                entrega = item.label;
            }
        });
        return entrega;
    };
    rechazarDespacho = () => {
        Swal.fire({
            title: "¿Desea rechazar la orden de despacho?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, anular!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.rechazarDespacho({ id: this.props.match.params.id });
            }
        });
    };
    render() {
        const {
            item,
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            cuentas,
        } = this.props;
        let CPid = this.props.match.params.id;
        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light>
                    {item && (
                        <div className="m-t p-t fnt-size">
                            <div
                                className="row m-none"
                                style={{ paddingTop: "15px" }}
                            >
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-titulo m-none">
                                        {item ? item.no_despacho : ""}
                                    </h3>
                                </div>
                            </div>
                            <br />
                            {/* DATOS GENERALES */}
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Emitida por:{" "}
                                    </span>
                                    <br />
                                    {item.despachado_por && (
                                        <span className="m-none t-musgo semibold">{`${item.despachado_por.nombre}`}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Bodega origen:
                                    </span>
                                    <br />
                                    {item.bodega_origen && (
                                        <span className="m-none t-musgo semibold">{`${item.bodega_origen.nombre}`}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Bodega destino:
                                    </span>
                                    <br />
                                    {item.bodega_destino && (
                                        <span className="m-none t-musgo semibold">{`${item.bodega_destino.nombre}`}</span>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Cliente
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Cliente:
                                    </span>
                                    <br />
                                    {item.cliente_orden && (
                                        <span className="m-none t-musgo semibold">{`${item.cliente_orden.nombre}`}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Telefono:
                                    </span>
                                    <br />
                                    {item.cliente_orden && (
                                        <span className="m-none t-musgo semibold">{`${item.cliente_orden.telefono}`}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Correo:
                                    </span>
                                    <br />
                                    {item.cliente_orden && (
                                        <span className="m-none t-musgo semibold">{`${item.cliente_orden.correo}`}</span>
                                    )}
                                </div>
                            </div>
                            <br/>
                            {/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Descripción
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-musgo semibold">{`${
                                        item.orden ? item.orden.descripcion : ""
                                    }`}</span>
                                </div>
                            </div>
                            <br />
                            <div className="col-12">
                                <span className="m-none t-texto bold">
                                    Fecha:
                                </span>
                            </div>
                            <hr
                                style={{
                                    borderTop: "2px solid rgb(223, 224, 224)",
                                    margin: "0px 15px 10px 15px",
                                }}
                            />
                            <div className="row m-none">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-primary semibold">
                                        Fecha:
                                    </span>
                                    <br />
                                    <span className="m-none t-musgo semibold">
                                        <RenderDateTime
                                            fecha={item.fecha}
                                        ></RenderDateTime>
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="mb-1 col-12">
                                    <div className="mb-0">
                                        <div className="">
                                            <h6 className="m-0"></h6>
                                        </div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <Tabs
                                                defaultActiveKey="DETALLEOV"
                                                tabBarPoition="top"
                                                onchange={this.callback}
                                                renderTabBar={() => (
                                                    <ScrollableInkTabBar />
                                                )}
                                                renderTabContent={() => (
                                                    <TabContent />
                                                )}
                                            >
                                                <TabPane
                                                    tab="Productos"
                                                    key="PRODUCTOS"
                                                >
                                                    <ProductosGrid
                                                        detalle_orden={
                                                            item.detalle_despacho
                                                        }
                                                        moneda={"Q."}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                {item.flujo_despacho == 10 && (
                                    <button
                                        className="btn btn-mostaza m-1 align-self-center"
                                        onClick={(e) => {
                                            this.rechazarDespacho();
                                        }}
                                    >
                                        Rechazar
                                    </button>
                                )}
                                <button
                                    className="btn btn-azul-claro m-1 align-self-center"
                                    onClick={(e) => {
                                        this.props.aceptarRechazo({
                                            id: this.props.match.params.id,
                                            id_bodega_origen: this.props.match
                                                .params.bodega,
                                            flujo_despacho: item.flujo_despacho,
                                        });
                                    }}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </div>
                    )}
                </LoadMask>
            </div>
        );
    }
}
