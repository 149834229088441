import React, { Component, Fragment } from 'react'
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import CardSimple from '../../../Utils/Cards/cardSimple';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import { PROCESOS } from '../../../../../utility/constants'
import CardPvEnviadas from '../../../Utils/Cards/cardsPM';
import {RenderMoneda, RenderDateTime} from "../../../Utils/renderField/renderTableField";
import { Card } from 'antd';
import { getsimbolo } from '../../../../../utility/constants';
import { Redirect } from 'react-router-dom';


export default class VentaInternaGrid extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, permisos } = this.props;
        if(permisos["ordenventa"] === false ) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo="Ventas internas"
                margen=" "
                toolbar={
                    <ToolbarSimple
                        textBoton="Agregar"
                        ruta="/ventainterna/nuevo"
                        buscar={this.props.searchChange}
                        buscador={this.props.search}
                        permisos={true}
                        placeholder={"Buscar por: No. Orden"}
                    />
                }
            >

                <br/>
                <Grid
                    page={this.props.page}
                    hover striped
                    data={data}
                    loading={loader}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange} >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataSort
                        dataFormat={
                            standardActions({ eliminar, verpro:"ventainterna" })
                        }
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat = {(cell, row) => {
                            return <RenderDateTime fecha={cell} />
                        }}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="no_orden"
                        dataSort
                    >
                        NO. ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor"
                        dataSort
                        dataFormat = {
                            (cell, row) => {
                                return(
                                    <div>
                                        {
                                            (row.empresa_origen && row.empresa_destino) && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Origen: </span><span>{row.empresa_origen.nombre}</span><br/>
                                                    <span className="font-weight-bold">Destino: </span><span>{row.empresa_destino.nombre}</span><br/>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }
                        }
                    >
                        EMPRESAS
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="entregado"
                        dataSort
                        // tdStyle={cellTachado}
                        dataFormat = {
                            (cell, row) => {
                                if(cell){
                                    return (<span className="t-verde font-weight-bold">Entregada</span>)
                                }else{
                                    return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                                }
                            }

                        }
                    >
                        DESPACHADO
                </TableHeaderColumn>
                </Grid>
            </CardSimple>
        );
    }
}
