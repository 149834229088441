import { handleActions } from "redux-actions";
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm, reset, change } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _ from "lodash";
import moment from "moment";
import { BanderaEmpresa } from "../../../utility/constants";

let url = "ordencompra";
let form = "ordencompraForm";
let dirGrid = "/ordencompras";

export const constants = {
    LOADER: "OC_LOADER",
    DATA: "OC_DATA",
    ITEM: `OC_ITEM`,
    PAGE: `OC_PAGE`,
    PAGE2: "OC_PAGE2",
    ORDERING: `OC_ORDERING`,
    SEARCH: `OC_SEARCH`,
    SET_EMPRESAS: "EMPRESAS_OC",
    SET_CUENTAS: "SET_CUENTAS_OC",
    SET_PAGOS_CREDITO: "SET_PAGOS_CREDITO",
    SET_GASTOS_OC: "SET_GASTOS_OC",
    SET_MODAL_OC: "SET_MODAL_OC",
    SET_MODAL_GASTO_OC: "SET_MODAL_GASTO_OC",
    SET_MODAL_FACTURAS_OC: "SET_MODAL_FACTURAS_OC",
    SET_MODAL_REINTEGRO_OC: "SET_MODAL_REINTEGRO_OC",
    SET_MODAL_ANULACION: "SET_MODAL_ANULACION_OC",
    SET_MODAL_PAGO: "SET_MODAL_PAGO_OC",
    SET_HISTORY: "SET_HISTORY",
    PROVEEDOR_OC: "PROVEEDOR_OC",
    MONEDA_OC: "MONEDA_OC",
    SET_PENDIENTES: "SET_PENDIENTES",
    DATAREFUSE: "DATAREFUSE",
    TIPO_GASTOS: "GASTOS_TIPO_OC",
    DETALLE_ORDEN: "DETALLE_ORDEN_OC",
    ENTREGADO: "OV_ENTREGADO",
    PAGO: "OV_PAGO",
    SET_APRUBE: "SET_APRUBE",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setPage2 = (page2) => ({
    type: constants.PAGE2,
    page2,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ordencompra;
    const params = { page };
    params.ordering = resource.ordering;
    if(resource.aprube){
        params.search = resource.search;
    }
    params.pago_completo = resource.pago_completo.value;
    params.entregado = resource.entregado.value;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            dispatch(setData(constants.DATA, response));
            dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ordencompra;
    const params = { page };
    params.ordering = resource.ordering;
    if(resource.aprube == false){
        params.search = resource.search;
    }
    params.estate = 1;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            dispatch(setData(constants.DATAREFUSE, response));
            dispatch(setPage2(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/${id}`)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
            if (!!form) dispatch(initializeForm(form, response));
            dispatch(
                setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes)
            );
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const leerOrdenCompra = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/getOrdenCompra/${id}`)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
            if (!!form) dispatch(initializeForm(form, response));
            // dispatch(setData(constants.SET_PAGOS_CREDITO, response.pagos_ordenes))
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al obtener los datos","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const leerPM = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    //params.pm = true;
    api.get(`${url}/${id}`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
            if (response.pago_inmediato === true) {
                response.pago_inmediato = "true";
            } else {
                response.pago_inmediato = "false";
            }
            response.direccion = {
                value: response.direccion,
                label: response.direccion,
            };
            _.forEach(response.detalle_orden, (x) => {
                x.cantidad = x.cantidad_actual;
            });
            dispatch(initializeForm(form, response));
            dispatch(setLoader(false));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = (data) => (dispatch, getStore) => {
    data = formatData(data, getStore);
    data.empresa = `${BanderaEmpresa}`;
    dispatch(setLoader(true));
    api.post(`${url}`, data)
        .then((response) => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset(form));
            if (!!dirGrid) dispatch(push(dirGrid));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error en la creación",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data) => (dispatch, getStore) => {
    data = formatData(data, getStore);
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            if (!!dirGrid) dispatch(push(dirGrid));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error en la edición",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const verificarPreciosProductos = () => (dispatch, getStore) => {
    const store = getStore().ordencompra;
    const formData = _.cloneDeep(getStore().form.ordencompraForm.values);
    let productos = [];
    formData.detalle_orden.forEach((item, key) => {
        if (item.producto) productos.push(item.producto.id);
    });
    if (productos.length > 0)
        dispatch(actualizarPrecios(productos, store.moneda, store.proveedor));
};

const actualizarPrecios = (productos, moneda, proveedor) => (
    dispatch,
    getStore
) => {
    dispatch(setLoader(true));
    let data = {};
    if (productos) data.productos = productos;
    if (moneda) data.moneda = moneda;
    if (proveedor) data.proveedor = proveedor;

    api.post(`productos/getProductoConComparacion`, data)
        .then((response) => {
            if (response) {
                const formData = _.cloneDeep(
                    getStore().form.ordencompraForm.values
                );
                formData.detalle_orden.forEach((item, key) => {
                    let select = _.find(
                        response,
                        (x) => x.id == item.producto.id
                    );
                    if (select) {
                        item.precio_unitario = select.precio_ultimo;
                        item.producto = select;
                    }
                });
                dispatch(
                    change(
                        "ordencompraForm",
                        "detalle_orden",
                        formData.detalle_orden
                    )
                );
            }
            dispatch(setLoader(false));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

/*anularOC */
const anularOC = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/anularOC`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(listar());
            dispatch(setOpenModal(false));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al tratar de anular la orden",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al tratar de eliminar la orden",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const entregadoChange = (entregado) => (dispatch) => {
    dispatch({ type: constants.ENTREGADO, entregado });
    dispatch(listar());
};

const pagoChange = (pago_completo) => (dispatch) => {
    dispatch({ type: constants.PAGO, pago_completo });
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const searchChangeRefuse = (search) => (dispatch) => {
    dispatch(setSearch(search));
    console.log("Search refuse");
    dispatch(listarRefuse(1, 1));
};

const setAprube = (value) => (dispatch) => {
    dispatch({type: constants.SET_APRUBE, aprube: value})
}

const onSortChangeRefuse = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page, 1));
};
const agregarPagoCredito = () => (dispatch, getStore) => {
    //Sirve para agregar más datos a la tabla de registro de pagos al crédito
    const formData = getStore().form.PagoCreditoForm.values;
    let pagos = [];
    const pagos_registrado = _.cloneDeep(getStore().ordencompra.pagos_credito);
    if (pagos_registrado && pagos_registrado.length > 0) {
        pagos = pagos_registrado;
    }
    pagos.push({
        id: new Date().toTimeString(),
        ...formData,
    });
    dispatch(setData(constants.SET_PAGOS_CREDITO, pagos));
};
const eliminarPagoCredito = (id) => (dispatch, getStore) => {
    let pagos = [];
    const pagos_registrado = _.cloneDeep(getStore().ordencompra.pagos_credito);
    _.forEach(pagos_registrado, (x) => {
        if (x.id !== id) {
            pagos.push(x);
        }
    });
    dispatch(setData(constants.SET_PAGOS_CREDITO, pagos));
};
const agregarGastoOC = () => (dispatch, getStore) => {
    //Sirve para agregar más datos a la tabla de registro de pagos al crédito
    const formData = getStore().form.PagoCreditoForm.values;
    let gastos = [];
    const pagos_registrado = _.cloneDeep(getStore().ordencompra.gastos_oc);
    if (pagos_registrado && pagos_registrado.length > 0) {
        gastos = pagos_registrado;
    }
    gastos.push({
        id: new Date().toTimeString(),
        ...formData,
    });
    dispatch(setData(constants.SET_GASTOS_OC, gastos));
};

const abonarGastoOPago = (id, es_pago = true) => (dispatch, getStore) => {
    //Agrega un registro a la BD seǵun sea el gasto
    const formData = getStore().form.PagoCreditoForm.values;
    formData.orden = id;
    formData.es_pago = es_pago;
    formData.fecha = moment(formData.fecha).format("YYYY-MM-DD");
    dispatch(setLoader(true));
    api.post(`${url}/agregarGastoOPago`, formData)
        .then((response) => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
            dispatch(setOpenModal(false));
            dispatch(cerrarModales());
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error en la creación de la orden de compra",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const anularTransaccion = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/anularTransaccion`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
            dispatch(setOpenModalGasto(false));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al tratar de anular la transacción",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const marcarPagadaOC = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/marcarPagadaOC`, { orden: id })
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(leerOrdenCompra(id));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al tratar de marcar como pagada la Orden",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const HistoryDispatch = (data) => (dispatch, getStore) => {
    const params = {};
    params.orden = data.orden;
    dispatch(setLoader(true));
    api.get(`despachos/historialdespacho`, params)
        .then((res) => {
            dispatch(setData(constants.SET_HISTORY, res));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const eliminarGasto = (id) => (dispatch, getStore) => {
    let gastos = [];
    const gastos_os = _.cloneDeep(getStore().ordencompra.gastos_oc);
    _.forEach(gastos_os, (x) => {
        if (x.id !== id) {
            gastos.push(x);
        }
    });
    dispatch(setData(constants.SET_GASTOS_OC, gastos));
};
const selectEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("empresa/selectempresas")
        .then((res) => {
            dispatch(setData(constants.SET_EMPRESAS, res));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const selectCuentas = (id, moneda) => (dispatch) => {
    dispatch(setLoader(true));
    let data = { moneda };
    api.post(`cuentas/selectcuentas`, data)
        .then((res) => {
            dispatch(setData(constants.SET_CUENTAS, res));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const resetFormulario = () => (dispatch) => {
    dispatch(
        initializeForm(form, {
            pago_inmediato: "true",
            tipo_cuenta: "true",
            fecha: new Date(),
            fecha_prox_entrega: new Date(),
            detalle_orden: [{}],
            fecha_prox_entrega: new Date(),
        })
    );
};

///Manejo de modales
const setOpenModal = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_OC,
        open_modal: open_modal,
    });
};
const setOpenModalAnulacion = (open_modal) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_ANULACION,
        open_modal_anulacion: open_modal,
    });
};

const setOpenModalGasto = (open_modal_gasto) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_GASTO_OC,
        open_modal_gasto: open_modal_gasto,
    });
};

const setOpenModalFactura = (open_modal_facturas) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_FACTURAS_OC,
        open_modal_facturas: open_modal_facturas,
    });
};

const setOpenModalReintegro = (open_modal_reintegro) => (
    dispatch,
    getStore
) => {
    dispatch({
        type: constants.SET_MODAL_REINTEGRO_OC,
        open_modal_reintegro: open_modal_reintegro,
    });
};
const setOpenModalPago = (open_modal_pago) => (dispatch, getStore) => {
    dispatch({
        type: constants.SET_MODAL_PAGO,
        open_modal_pago: open_modal_pago,
    });
};

const cerrarModales = () => (dispatch, getStore) => {
    dispatch(setOpenModal(false));
    dispatch(setOpenModalAnulacion(false));
    dispatch(setOpenModalGasto(false));
    dispatch(setOpenModalFactura(false));
    dispatch(setOpenModalReintegro(false));
    dispatch(setOpenModalPago(false));
};

//Fin de manejo de modales
const BillOC = (params) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/billoc`, params)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(leerOrdenCompra(params.id));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const DeleteOC = (params) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/deletebilloc`, params)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(leerOrdenCompra(params.orden));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const formatData = (data, getStore) => {
    let dataForm = _.cloneDeep(data);
    dataForm.proveedor = dataForm.proveedor.id;
    _.forEach(dataForm.detalle_orden, (x) => {
        x.producto = x.producto.id;
    });
    dataForm.fecha = moment(dataForm.fecha).format("YYYY-MM-DD");
    dataForm.fecha_prox_entrega = moment(dataForm.fecha).format("YYYY-MM-DD");
    const pagos_registrado = _.cloneDeep(getStore().ordencompra.pagos_credito);
    const gastos_registrados = _.cloneDeep(getStore().ordencompra.gastos_oc);
    if (pagos_registrado) {
        let pagos_credito = [];
        _.forEach(pagos_registrado, (x) => {
            x.fecha = moment(x.fecha).format("YYYY-MM-DD");
        });
        pagos_credito = _(pagos_registrado)
            .groupBy("numero_cuenta")
            .map((pago, id) => {
                return {
                    numero_cuenta: Number(_.map(pago, "numero_cuenta")[0]),
                    monto: _.sumBy(pago, "monto"),
                };
            })
            .value();
        dataForm.total_pagos_credito = pagos_credito;
        dataForm.pagos_credito = pagos_registrado;
    }
    if (gastos_registrados) {
        let gastos_array = [];
        _.forEach(gastos_registrados, (x) => {
            x.fecha = moment(x.fecha).format("YYYY-MM-DD");
        });
        gastos_array = _(gastos_registrados)
            .groupBy("numero_cuenta")
            .map((pago, id) => {
                return {
                    numero_cuenta: Number(_.map(pago, "numero_cuenta")[0]),
                    monto: _.sumBy(pago, "monto"),
                };
            })
            .value();
        dataForm.total_gastos = gastos_array;
        dataForm.gastos_registrados = gastos_registrados;
    }

    let pagos_y_gastos = pagos_registrado.concat(gastos_registrados);
    let pagos_y_gastos_array = _(pagos_y_gastos)
        .groupBy("numero_cuenta")
        .map((pago, id) => {
            return {
                numero_cuenta: Number(_.map(pago, "numero_cuenta")[0]),
                monto: _.sumBy(pago, "monto"),
            };
        })
        .value();
    dataForm.pagos_y_gastos = pagos_y_gastos_array;
    return dataForm;
};

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.PROVEEDOR_OC,
        proveedor,
    });
};
const setMoneda = (moneda) => (dispatch) => {
    dispatch({
        type: constants.MONEDA_OC,
        moneda,
    });
};

const refusePM = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/refusepm`, id)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(push(dirGrid));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const acceptPM = (values) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/acceptpm`, values)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(leerOrdenCompra(values.id));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listPM = () => (dispatch) => {
    dispatch(setLoader(true));
    let params = { val: true };
    api.get(`${url}/listpm`, params)
        .then((res) => {
            dispatch(setData(constants.SET_PENDIENTES, res));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const flotanteChequePagado = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`periodos/flotanteChequePagado`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(cerrarModales());
            dispatch(leerOrdenCompra(id));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getTipoGastos = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("tipo_gasto")
        .then((res) => {
            dispatch({
                type: constants.TIPO_GASTOS,
                tipo_gastos: res,
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getDetalleOrden = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`ordencompra/getPendientesIngreso/${id}`)
        .then((res) => {
            dispatch({
                type: constants.DETALLE_ORDEN,
                data: res,
            });
            dispatch(initializeForm("IngresoForm", { Despachos: res }));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const DespacharProducto = (data, orden) => (dispatch) => {
    dispatch(setLoader(true));
    data.orden = orden;
    data.bodega = `${BanderaEmpresa}`;
    api.post(`despachos`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset(formDespacho));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error en el ingreso de materiales",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(goBack());
        });
};

const clear = () => (dispatch) => {
    dispatch({ type: constants.ITEM, item: {} });
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    selectEmpresas,
    selectCuentas,
    agregarPagoCredito,
    eliminarPagoCredito,
    agregarGastoOC,
    HistoryDispatch,
    eliminarGasto,
    resetFormulario,
    BillOC,
    abonarGastoOPago,
    anularTransaccion,
    setOpenModal,
    DeleteOC,
    marcarPagadaOC,
    setOpenModalGasto,
    anularOC,
    verificarPreciosProductos,
    setProveedor,
    setMoneda,
    listPM,
    setAprube,
    refusePM,
    acceptPM,
    searchChangeRefuse,
    onSortChangeRefuse,
    listarRefuse,
    leerPM,
    clear,
    leerOrdenCompra,
    flotanteChequePagado,
    setOpenModalAnulacion,
    setOpenModalFactura,
    setOpenModalReintegro,
    setOpenModalPago,
    getTipoGastos,
    cerrarModales,
    getDetalleOrden,
    DespacharProducto,
    entregadoChange,
    pagoChange,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.DATAREFUSE]: (state, { data }) => {
        return {
            ...state,
            datarefuse: data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.PAGE2]: (state, { page2 }) => {
        return {
            ...state,
            page2,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_HISTORY]: (state, { data }) => {
        return {
            ...state,
            history: data,
        };
    },
    [constants.SET_EMPRESAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data.empresa,
        };
    },
    [constants.SET_CUENTAS]: (state, { data }) => {
        return {
            ...state,
            cuentas: data.cuenta,
        };
    },
    [constants.SET_PAGOS_CREDITO]: (state, { data }) => {
        return {
            ...state,
            pagos_credito: data,
        };
    },
    [constants.SET_GASTOS_OC]: (state, { data }) => {
        return {
            ...state,
            gastos_oc: data,
        };
    },
    [constants.SET_MODAL_OC]: (state, { open_modal }) => {
        return {
            ...state,
            open_modal,
        };
    },
    [constants.SET_MODAL_GASTO_OC]: (state, { open_modal_gasto }) => {
        return {
            ...state,
            open_modal_gasto,
        };
    },
    [constants.SET_MODAL_FACTURAS_OC]: (state, { open_modal_facturas }) => {
        return {
            ...state,
            open_modal_facturas,
        };
    },
    [constants.SET_MODAL_REINTEGRO_OC]: (state, { open_modal_reintegro }) => {
        return {
            ...state,
            open_modal_reintegro,
        };
    },
    [constants.SET_MODAL_ANULACION]: (state, { open_modal_anulacion }) => {
        return {
            ...state,
            open_modal_anulacion,
        };
    },
    [constants.SET_MODAL_PAGO]: (state, { open_modal_pago }) => {
        return {
            ...state,
            open_modal_pago,
        };
    },
    [constants.PROVEEDOR_OC]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.MONEDA_OC]: (state, { moneda }) => {
        return {
            ...state,
            moneda,
        };
    },
    [constants.SET_PENDIENTES]: (state, { data }) => {
        return {
            ...state,
            pendientes: data,
        };
    },
    [constants.TIPO_GASTOS]: (state, { tipo_gastos }) => {
        return {
            ...state,
            tipo_gastos,
        };
    },
    [constants.DETALLE_ORDEN]: (state, { data }) => {
        return {
            ...state,
            detalle_orden: data,
        };
    },
    [constants.PAGO]: (state, { pago_completo }) => {
        return {
            ...state,
            pago_completo,
        };
    },
    [constants.ENTREGADO]: (state, { entregado }) => {
        return {
            ...state,
            entregado,
        };
    },
    [constants.SET_APRUBE]: (state, { aprube }) => {
        return {
            ...state,
            aprube
        }
    }
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    datarefuse: {
        results: [],
        count: 0,
    },
    detalle_orden: [],
    item: {},
    page: 1,
    page2: 1,
    ordering: "",
    search: "",
    pago_completo: { value: null, label: "Todos" },
    entregado: { value: null, label: "Todos" },
    empresas: [],
    cuentas: [],
    pagos_credito: [],
    gastos_oc: [],
    open_modal: false,
    open_modal_gasto: false,
    open_modal_facturas: false,
    open_modal_reintegro: false,
    open_modal_anulacion: false,
    open_modal_pago: false,
    proveedor: null,
    moneda: null,
    pendientes: [],
    history: [],
    tipo_gastos: [],
    aprube: true,
};

export default handleActions(reducers, initialState);
