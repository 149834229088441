import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Puestos/Puestos';
import PuestosGrid from './PuestosGrid';

const mstp = state => {
    return {...state.puestos, permisos: state.login.me.permisos,}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PuestosGrid)
