import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderCurrency } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import {RenderMoneda, getSimbolo} from "../../../Utils/renderField/renderTableField";


const renderFacturas = ({fields, meta: {error, submitFailed }, facturas, simbolo}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table table-striped table-highlight  mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>NOMBRE</th>
                        <th>NIT</th>
                        <th>NO. FACTURA</th>
                        <th>MONTO</th>
                    </tr>
                    </thead>
                {/* </table>

            </div>

            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped"> */}
                    <tbody>
                    {fields.map((factura, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "48px"}}>
                                <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                                     title="Borrar"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${factura}.nombre`}
                                    type="text"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${factura}.nit`}
                                    type="puesto"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${factura}.no_factura`}
                                    type="telefono"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">

                                <Field
                                    name={`${factura}.monto`}
                                    label="monto"
                                    prefix={simbolo}
                                    component={renderCurrency}
                                    parse={cell => parseFloat(cell)}
                                    className="form-control"
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR FACTURA
                </button>
            </div>
        </div>
    </div>
)

let FacturaOCForm = (props) => {
    const { handleSubmit, previousStep, simbolo} = props;
    return (
        <form name="ordencompraForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <h4 className="t-primary" >Datos facturas</h4>
            </div>
            <div className="col-12 m-0 p-0 d-flex justify-content-center">
                <div className="col-md-10 row m-0 p-0">
                    <FieldArray
                        simbolo={simbolo}
                        name="facturas"
                        component={renderFacturas} />

                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <button onClick={previousStep} className="btn btn-secondary  align-self-cente">ANTERIOR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


FacturaOCForm =  reduxForm({
    form: 'ordencompraForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });
    },
})(FacturaOCForm);

const selector = formValueSelector('ordencompraForm');

FacturaOCForm = connect( state => {
    let simbolo = "Q. "

    const moneda = selector(state, 'moneda');

    if(moneda){
        simbolo = getSimbolo(moneda)
    }
    return {
        simbolo,
    }
})(FacturaOCForm);


export default FacturaOCForm;
