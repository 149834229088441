import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Modal from "react-responsive-modal";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import AnulacionForm from "./anulacionForm";
import {
    RenderMoneda,
    RenderDateTime,
    RenderDateTimeAll,
} from "../../../Utils/renderField/renderTableField";

function cellTachado(cell, row) {
    if (!row.estado) {
        return {
            textDecoration: "line-through",
            whiteSpace: "normal",
            padding: 5,
        };
    }
    return { whiteSpace: "normal", padding: 5 };
}

export default class HistorialCajaGrid extends Component {

    render() {
        const {
            data,
            loader,
            loaderVentas,
            listar: onPageChange,
            getHistorialVentas,
            onSortChange,
            pageVentas,
            eliminar,
            permisos,
        } = this.props;
        return (
            <Grid hover striped data={{count:10, results: data.historial.detalle}}  loading={loader}  pagination={false}>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10%"
                    dataSort
                    dataFormat={(cell)=>cell?'':''}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_hora"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell,row)=>(
                            <RenderDateTimeAll fecha={cell} />
                        )
                    }
                >
                    FECHA Y HORA
                </TableHeaderColumn>
                <TableHeaderColumn
                    isKey
                    tdStyle={cellTachado}
                    dataField="descripcion"
                    dataSort
                    dataFormat={
                        (cell,row)=> cell? (`${cell} `):"-------"
                    }
                >
                    DESCRIPCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="es_ingreso"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell,row)=>(
                            cell ? 'Ingreso':'Egreso'
                        )
                    }
                >
                    MOVIMIENTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="debe"
                    dataSort
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell,row)=>(
                            <RenderMoneda monto={cell} />
                        )
                    }
                >
                    DEBE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="haber"
                    dataSort
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell,row)=>(
                            <RenderMoneda monto={cell} />
                        )
                    }
                >
                    HABER
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="saldo"
                    dataSort
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell,row)=>(
                            <RenderMoneda monto={cell} />
                        )
                    }
                >
                    SALDO
                </TableHeaderColumn>
            </Grid>
        );
    }
}
