import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Grid from "../../../../Utils/Grid/index";
import {
    RenderMoneda,
    RenderDateTime,
    RenderDateTimeAll,
} from "../../../../Utils/renderField/renderTableField";
import CardSimple from '../../../../Utils/Cards/cardSimple';

export default class DetalleVenta extends Component {
    componentDidMount() {
        this.props.leer(this.props.match.params.id);
    }
    getJustificacion = (item) => {
        if (item.proceso_estado === "Rechazado") {
            let ultiJustificacion =
                item.justificacion[item.justificacion.length - 1];
            return (
                <div className="col-12">
                    <h6 className="t-azul">Justifiación de Rechazo</h6>
                    <span>{ultiJustificacion.justificacion}</span>
                </div>
            );
        }
    };
    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            detalleVenta,
        } = this.props;
        return (
            <CardSimple style={{ marginTop: "-50px" }} margen="mt-0">
                <div
                    className="justy-content-center col-12 mb-3"
                >
                    {item && item.comprador && (
                        <div className="col-12 row py-3 justify-content-center">
                            <div className="col-12 col-md-4 row">
                                <div className="col-12">
                                    <label className="t-primary"> VENDEDOR: </label>
                                    <br/>
                                    <label className="t-texto"> {item.operador.nombre} </label>
                                </div>
                                <div className="col-12">
                                    <label className="t-primary"> COMPRADOR: </label>
                                    <br/>
                                    <label className="t-texto"> {item.comprador} </label>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 row d-flex align-items-start">
                                <div className="col-12 px-0 mx-0">
                                    <label className="my-0 t-primary">
                                        FECHA/HORA:
                                    </label>
                                    <br/>
                                    <label>
                                        <RenderDateTimeAll
                                            fecha={item.fecha}
                                            className="t-texto"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 row d-flex align-items-start">
                                <div className="col-12 mx-0 px-0">
                                    <label className="my-0 t-primary">NIT:</label>
                                    <br/>
                                    <label> {item.nit} </label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {item && item.estado == false && (
                    <div className="col-12 row">
                        <div className="col-12">
                            <label> ANULACIÓN </label>
                        </div>
                        <div className="col-12 row mx-0 px-0">
                            <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                <label className="my-0">
                                    Descripción de anulación
                                </label>
                            </div>
                            <div className="col-6 col-lg-8 d-flex justify-content-start align-items-center">
                                <span>{item.descripcionAnulacion}</span>
                            </div>
                        </div>
                        <div className="col-12 row mx-0 px-0">
                            <div className="col-6 col-lg-4 d-flex justify-content-start align-items-center">
                                <label className="my-0">Fecha anulación</label>
                            </div>
                            <div className="col-6 col-lg-8 d-flex justify-content-start align-items-center">
                                <span>
                                    <RenderDateTimeAll
                                        fecha={item.fecha_anulacion}
                                        />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <br />
                {item && item.comprador && (
                    <Grid
                        hover
                        striped
                        data={{
                            count: item.detalle.length,
                            results: item.detalle,
                        }}
                        loading={loader}
                        pagination={false}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width="15%"
                            dataFormat={(cell) => (cell ? "" : "")}
                        ></TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="producto"
                            dataSort
                            width="25%"
                            dataFormat={(cell) =>
                                cell ? cell : "Sin registro"
                            }
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="presentacion"
                            dataAlign="center"
                            width="20%"
                            dataFormat={(cell)=>
                            cell ? cell : "------"}
                        >
                            PRESENTACION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_unitario"
                            width="20%"
                            headerAlign="center"
                            dataAlign="right"
                            dataFormat={(cell) =>
                                cell ? (
                                    <RenderMoneda
                                        className="px-0"
                                        monto={cell}
                                    />
                                ) : (
                                    <RenderMoneda className="px-0" monto={0} />
                                )
                            }
                        >
                            PRECIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cantidad"
                            dataAlign="center"
                            width="20%"
                        >
                            CANTIDAD
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="subtotal"
                            width="20%"
                            headerAlign="center"
                            dataAlign="right"
                            dataFormat={(cell) =>
                                cell ? (
                                    <RenderMoneda
                                        className="px-0"
                                        monto={cell}
                                    />
                                ) : (
                                    <RenderMoneda className="px-0" monto={0} />
                                )
                            }
                        >
                            SUBTOTAL
                        </TableHeaderColumn>
                    </Grid>
                )}

                <div
                    className="col-12 my-3 py-1"
                    style={{
                        backgroundColor: "#828FFB",
                        borderRadius: "5px"
                    }}
                >
                    <div className="row col-12 d-flex justify-content-end">
                        <div className="col-2 t-blanco"> TOTAL</div>
                        <div className="col-3">
                            {item && item.total ? (
                                <RenderMoneda
                                    className="t-blanco px-0"
                                    monto={item.total}
                                />
                            ) : (
                                <RenderMoneda
                                    className="t-blanco px-0"
                                    monto={0}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <br />
                <div className="row col-12">
                    <div className="col-12">
                        <br />
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <Link
                                to="/reporte_ventas"
                                className="btn btn-secondary"
                                type="button"
                            >
                                Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </CardSimple>
        );
    }
}
