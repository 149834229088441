import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import DetalleCaja from "../DetalleCaja";
import DetalleTienda from "../detalleTienda/MiTienda";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

export default class PuntoVenta extends Component {
    state = {
        open_modal: false,
    };
    handleSubmit = (values) => {
        let id = this.props.match.params.id;
        values.id = id;
        this.props.refusePM(values);
    };
    handleModal = (value) => {
        this.setState({ open_modal: value });
    };

    componentDidMount() {
        this.props.getCaja();
    }

    render() {
        return (
            <div className="col-12 row mt-3 mx-0 px-0 tabPuntoVenta">
                <div
                    style={{ position: "absolute", right: "30px", top: "15px" }}
                >
                    {this.props.caja && this.props.caja.sucursal ? (
                        <h5 className="t-texto semibold">
                            Caja:{"  "}
                            <span className="t-primary bold">
                                {this.props.caja.nombre}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sucursal:{"  "}
                            <span className="t-primary bold">
                                {this.props.caja.sucursal.nombre}
                            </span>
                        </h5>
                    ) : (
                        ""
                    )}
                </div>
                <Tabs
                    defaultActiveKey="MI_TIENDA"
                    tabBarPoition="top"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    {this.props.caja && this.props.caja.abierto && (
                        <TabPane tab="Mi tienda" key="MI_TIENDA">
                            <DetalleTienda {...this.props} />
                        </TabPane>
                    )}
                    <TabPane tab="Mi Caja" key="MI_CAJA">
                        <DetalleCaja {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
