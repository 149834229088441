import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _, { inRange, constant } from "lodash";
import moment from "moment";

let url = "reportes";
let form = "";
let dirGrid = "/";

export const constants = {
    LOADER: "LOADER_PRODUCTIVIDAD_HB",
    DATA: "DATA_PRODUCTIVIDAD_HB",
    ITEM: "ITEM_PRODUCTIVIDAD_HB",
    PAGE: `PAGE_PRODUCTIVIDAD_HB`,
    ORDERING: `ORDERING_PRODUCTIVIDAD_HB`,
    PRODUCTO: "PRODUCTO_PRODUCTIVIDAD_HB",
    EMPLEADO: "EMPLEADO_PRODUCTIVIDAD_HB",
    SET_FECHA_FINAL: "SET_FECHA_FINAL_PRODUCTIVIDAD_HB",
    SET_FECHA_INICIAL: "SET_FECHA_INICIAL_PRODUCTIVIDAD_HB",
    SEARCH: "SEARCH_PRODUCTIVIDAD_HB",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const getProductividad = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().reporteProductividad;
    const params = { page };
    if(store.fecha_inicial)
        params.fecha_inicial = moment(store.fecha_inicial).format("YYYY-MM-DD");
    if(store.fecha_final)
        params.fecha_final = moment(store.fecha_final).format("YYYY-MM-DD");
    if(store.producto)
        params.producto = store.producto.id
    if(store.empleado)
        params.empleado = store.empleado.id
    if (store.search)
        params.search = store.search;
    api.get(`${url}/reporteProductividadHB`, params)
        .then((response) => {
            dispatch(setPage(page));
            dispatch(setData(constants.DATA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { id };
    api.get(`${url}/reporteProductividadHB`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeEmpleado = (empleado) => (dispatch) => {
    dispatch(setData(constants.EMPLEADO,empleado));
    dispatch(getProductividad());
};

const changeProducto = (producto) => (dispatch) => {
    dispatch(setData(constants.PRODUCTO,producto));
    dispatch(getProductividad());
};
const changeFechaInicial = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_INICIAL,fecha));
    dispatch(getProductividad());
};
const changeFechaFinal = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_FINAL,fecha));
    dispatch(getProductividad());
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getProductividad());
};

export const actions = {
    getProductividad,
    changeEmpleado,
    changeProducto,
    changeFechaInicial,
    changeFechaFinal,
    searchChange,
    leer,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.EMPLEADO]: (state, { data }) => {
        return {
            ...state,
            empleado: data,
        };
    },
    [constants.PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data,
        };
    },
    [constants.SET_FECHA_INICIAL]: (state, { data }) => {
        return {
            ...state,
            fecha_inicial: data,
        };
    },
    [constants.SET_FECHA_FINAL]: (state, { data }) => {
        return {
            ...state,
            fecha_final: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    item: {},
    page: 1,
    search: "",
    ordering: "",
    empleado: null,
    producto: null,
    fecha_inicial: moment().subtract(7, "days"),
    fecha_final: moment(),
};

export default handleActions(reducers, initialState);
