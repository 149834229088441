import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../Utils/Grid/index';
import { standardActions } from "../../../Utils/Grid/StandardActions";

export default class NitsGrid extends Component{
    componentWillMount() {
        let id=this.props.CPid
        this.props.selectNits(id);
    }
    render(){
        const { nits, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={nits} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} >
                    <TableHeaderColumn
                        dataField="nombre"
                        isKey
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="Nit"
                        dataSort
                    >
                        NIT
                    </TableHeaderColumn>
                </Grid>

            </div>
        )
    }

}