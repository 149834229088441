import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Vehiculos/vehiculosMaquinaria';
import VehiculosCrear from './VehiculosCrear';

const mstp = state => {
    return {...state.vehiculosMaquinaria ,permisos: state.login.me.permisos, }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(VehiculosCrear)


