import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Transacciones/transacciones';
import TransaccionNueva from './transaccion';

const mstp = state => {
    return {...state.transacciones}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TransaccionNueva)
