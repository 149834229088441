import React, { Component, Fragment } from 'react';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { MESES } from '../../../../../utility/constants';
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import moment from 'moment';

export default class GridMes extends Component {

    componentDidMount() {
        const { porMeses, fecha_inicio, fecha_fin } = this.props;
        porMeses();
        this.props.cantidadMeses(fecha_inicio, fecha_fin);
    }

    getPorcentaje = (venta, costo) => {
        let porcentaje = 0;
        if (venta > 0) {
            porcentaje = ((venta - costo) / venta) * 100;
            porcentaje = porcentaje.toFixed(2);
        }
        return porcentaje;
    }


    render() {
        const { page2, data2, loader, porMeses } = this.props;

        return (
            <Fragment>
                <Grid
                    hover
                    striped
                    data={data2}
                    loading={loader}
                    page={page2}
                    className="reporte-table"
                    onPageChange={porMeses}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        width="0%"
                        dataFormat={(cell) => ""}
                    />
                    <TableHeaderColumn
                        dataField="nombre"
                        width="12%"
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    {
                        this.props.meses.map((label, index) => (
                            <TableHeaderColumn
                                key={index}
                                dataField="meses"
                                thStyle={{ textTransform: "uppercase" }}
                                headerAlign="center"
                                dataAlign="right"
                                dataFormat={(cell, row) => (
                                    <div>
                                        <div className="d-flex flex-1">
                                            <span className="font-weight-bold">Costo: </span>
                                            <span style={{ color: "black", marginLeft: "auto" }}>
                                                {
                                                    (cell[index]) && (cell[index].costo != null && cell[index].venta != null) ? (
                                                        <RenderMoneda
                                                            decimalScale={2}
                                                            monto={cell[index].costo}
                                                        />
                                                    ) : (
                                                            <div>-----</div>
                                                        )
                                                }
                                            </span>
                                            <br />
                                        </div>
                                        <div className="d-flex flex-1">
                                            <span className="font-weight-bold">Ventas: </span>
                                            <span style={{ color: "black", marginLeft: "auto" }}>
                                                {
                                                    ((cell[index]) && (cell[index].costo != null && cell[index].venta != null)) ? (
                                                        <RenderMoneda
                                                            decimalScale={2}
                                                            monto={cell[index].venta}
                                                        />
                                                    ) : (
                                                            <div>-----</div>
                                                        )
                                                }
                                            </span>
                                            <br />
                                        </div>
                                        <div className="d-flex flex-1">
                                            <span className="font-weight-bold">G. Neta: </span>
                                            <span style={{ color: "#00334E", marginLeft: "auto" }} >
                                                {
                                                    (cell[index]) && (cell[index].costo != null && cell[index].venta != null) ? (
                                                        <RenderMoneda
                                                            decimalScale={2}
                                                            monto={cell[index].venta - cell[index].costo}
                                                        />
                                                    ) : (
                                                            <div>-----</div>
                                                        )
                                                }
                                            </span>
                                            <br />
                                        </div>
                                        <div className="d-flex flex-1">
                                            <span className="font-weight-bold"></span>
                                            <span style={{ color: "#00334E", marginLeft: "auto" }} >
                                                {
                                                    (cell[index]) && (cell[index].costo != null && cell[index].venta != null) ? (
                                                        <div className="moneda-reporte">
                                                            {`(${this.getPorcentaje(cell[index].venta, cell[index].costo)}%)`}
                                                        </div>
                                                    ) : (
                                                            <div>-----</div>
                                                        )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                )}
                            >
                                {label}
                            </TableHeaderColumn>
                        ))
                    }
                </Grid>
            </Fragment>
        )
    }
}
