import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteProductividad from "../../../Utils/Toolbar/ToolbarReporteProductividad";

export default class ReporteProductividad extends Component {
    componentDidMount() {
        this.props.getProductividad();
    }

    render() {
        const { loader, data, page, getProductividad } = this.props;
        return (
            <CardSimple row={true} margen="mt-0" style={{ marginTop: "-50px" }}>
                <ToolbarReporteProductividad
                    changeEmpleado={this.props.changeEmpleado}
                    changeProducto={this.props.changeProducto}
                    productos={this.props.producto && this.props.producto}
                    empleados={this.props.empleado && this.props.empleado}
                    changeFechaInicial = {this.props.changeFechaInicial}
                    fecha_inicial = {this.props.fecha_inicial}
                    fecha_final = {this.props.fecha_final}
                    changeFechaFinal = {this.props.changeFechaFinal}
                />
                <br/>
                <label className="t-primary"> Listado </label>
                <hr
                    style={{
                        background: "#408EAA",
                        height: "2px",
                        width: "100%",
                        marginTop: '0px',
                    }}
                    />
                <div className="col-12 row m-0 p-0 d-flex">
                    <div className="col-md-12 mx-0 px-0">
                        {data && data.results && (
                            <Grid
                                hover
                                striped
                                page={page}
                                data={data}
                                loading={loader}
                                onPageChange={getProductividad}
                                pagination={true}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="10%"
                                    dataFormat={standardActions({
                                    })}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="creado"
                                    dataFormat={(cell) =>
                                        cell ? <RenderDateTime fecha ={cell} /> : "Sin registro"
                                    }
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="producto"
                                    dataFormat={(cell) =>
                                        cell ? cell.nombre : "Sin registro"
                                    }
                                >
                                    PRODUCTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad"
                                    dataAlign="center"
                                    dataFormat={(cell) =>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    CANTIDAD
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="producto"
                                    dataAlign="center"
                                    dataFormat={(cell)=>
                                        cell ? cell.unidad_medida : "Sin registro"
                                    }
                                >
                                    UNIDAD DE MEDIDA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre_empleado"
                                    dataAlign="center"
                                    dataFormat={(cell) =>
                                        cell ? cell : 0
                                    }
                                >
                                    EMPLEADO
                                </TableHeaderColumn>
                            </Grid>
                        )}
                    </div>
                </div>
            </CardSimple>
        );
    }
}
