import React from "react";
import HeaderSimple from "../Header/HeaderSimple";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import iconoDatosOc from "../../../../../assets/img/icons/datos oc.png";
import iconoPagoOc from "../../../../../assets/img/icons/Forma de pago oc.png";
import iconoEntregaOc from "../../../../../assets/img/icons/Datos de pago oc.png";
import iconoCheck from "../../../../../assets/img/icons/Finalizado oc.png";

function CardFormularioOrden(props) {
    return (
        <div className="Container">
            <div className="row">
                <div className="mb-4 col-12">
                    <div className="row justify-content-center mt-4">
                        <div className="col-sm-12">
                            <ProgressBar
                                percent={
                                    props.step === 1
                                        ? 20
                                        : props.step === 2
                                        ? 50
                                        : props.step === 3
                                        ? 80
                                        : 100
                                }
                                filledBackground="#ADD5E3"
                                stepPositions={[20, 50, 80]}
                            >
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                                color: "var(--primaryColor)",
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? props.step > index + 1
                                                            ? "step-caption-check"
                                                            : "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished &&
                                                props.step > index + 1 ? (
                                                    <img
                                                        width="15"
                                                        src={iconoCheck}
                                                    />
                                                ) : (
                                                    <img
                                                        width="15"
                                                        src={iconoDatosOc}
                                                    />
                                                )}
                                            </div>
                                            &nbsp;1. Datos
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                                color: "var(--primaryColor)",
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? props.step > index + 1
                                                            ? "step-caption-check"
                                                            : "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished &&
                                                    (props.step > index + 1 ? (
                                                        <img
                                                            width="15"
                                                            src={iconoCheck}
                                                        />
                                                    ) : (
                                                        <img
                                                            width="15"
                                                            src={iconoPagoOc}
                                                        />
                                                    ))}
                                            </div>
                                            &nbsp;2. Pago
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index }) => (
                                        <div
                                            style={{
                                                filter: `grayscale(${
                                                    accomplished ? 0 : 80
                                                }%)`,
                                                color: "var(--primaryColor)",
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <div
                                                className={`${
                                                    accomplished
                                                        ? "step-caption-selected"
                                                        : "step-caption"
                                                }`}
                                            >
                                                {accomplished && (
                                                    <img
                                                        width="15"
                                                        src={iconoEntregaOc}
                                                    />
                                                )}
                                            </div>
                                            &nbsp;3. Entrega
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>
                        </div>
                    </div>
                    <div
                        className="mb-4 card shadow card-small m-t"
                        style={{ borderRadius: "10px" }}
                    >
                        <div className="card-body">
                            {props.titulo && (
                                <HeaderSimple titulo={props.titulo} />
                            )}
                            <div className="mb-4 mt-5 col-12">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardFormularioOrden;
