import React, { Component, Fragment } from 'react'
import "react-step-progress-bar/styles.css";
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {ProgressBar, Step} from "react-step-progress-bar";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import PasoUnoForm from "./ClienteForm";
import PasoDosForm from "./facturacionForm";
import PasoTresForm from "./contactoForm";
import PasoCuatroForm from './sucursalesForm';
import PasoCincoForm from "./cuantaForm";
import PasoSeisForm from "./cuentaCPP";

import iconoBancosCP from '../../../../../../assets/img/icons/bancos_cp.png';
import iconoContactosCP from '../../../../../../assets/img/icons/agenda_cp.png';
import iconoCuentasCP from '../../../../../../assets/img/icons/cuentas_cp.png';
import iconoNitsCP from '../../../../../../assets/img/icons/nit_cp.png';
import iconoSucursalesCP from '../../../../../../assets/img/icons/sucursales_cp.png';
import iconoDatosCP from '../../../../../../assets/img/icons/datos oc.png';
import iconoCheck from '../../../../../../assets/img/icons/Finalizado oc.png';
import {BanderaEmpresa} from '../../../../../utility/constants';


export default class ClienteCrear extends Component{
    state ={
        editar:false,
        step:1,
        es_cliente: 2,
        titulo: "Datos generales",
        route: "/clientes",
        cpp: true,
        municipios: [],
        paso:0
    };
    componentDidMount(){
        this.props.limpiarItem()
        this.props.selectRegiones()
        this.props.selectEmpresas()
        if (this.props.location.state){
            this.setState({
                es_cliente:1,
                route:"/proveedores"
            })
            if (this.props.match.params.id){
                this.props.leer(this.props.match.params.id, "");
                this.setState({editar:true});
            }

        }else if (this.props.match.params.id){
            this.props.leer(this.props.match.params.id, true);
            this.setState({editar:true});
        }
    }
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    handleSubmit = (values) =>{
        values.tipo = this.state.es_cliente;
        const {crear, editar} =this.props
        let id = this.props.match.params.id;
        let Clipro= this.state.es_cliente;
        if(this.state.editar && this.props.item && !this.props.item.es_actualizado){
            values.cpp[0].empresa = `${BanderaEmpresa}`
        } else if(!this.state.editar){
            values.cpp[0].empresa = `${BanderaEmpresa}`
        }
        this.state.editar? editar(id,values,Clipro) : crear(values,Clipro);
    }
    render(){
        const {loader, municipios_bancos, AddMunicipio, ChangeMunicipio, controles, item,
            empresas, setEmpresasForm, setEmpresasFormProvedor
        } = this.props;
        const{step}=this.state;
        let permiso_cuenta = false
        if (!!item && !!item.es_actualizado){
            permiso_cuenta = false
        }
        else{permiso_cuenta=true;}
        return(
            <div className="Container">
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="row justify-content-center  mt-4">
                                    <div className="col-12">
                                        <ProgressBar
                                            percent={
                                                permiso_cuenta ? (
                                                    step === 1 ? 11: step === 2 ? 24 : step === 3 ? 41 :step === 4 ? 57 :step === 5 ? 71:step === 6 && 86 
                                                ):(
                                                    step === 1 ? 10: step === 2 ? 30 : step === 3 ? 50 :step === 4 ? 70 : step == 5 ? 90 : 90
                                                )
                                            }
                                            filledBackground= "#f3a631"
                                            stepPositions={ permiso_cuenta ? [11,24,41,57,71,86]:[10,30,50,70,88,88] }
                                        >
                                            <Step transition="scale">
                                                {({ accomplished, index }) => (
                                                    <div
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`}}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className={ `${accomplished ? this.state.step > index + 1 ? 'step-caption-check' : 'step-caption-selected':"step-caption"}`}>
                                                            {accomplished && (
                                                                this.state.step > index + 1
                                                                    ? <img width="15" src={iconoCheck}/>
                                                                    : <img width="15" src={iconoDatosCP}/>
                                                            )}
                                                        </div>
                                                        &nbsp;&nbsp;1. Datos
                                                    </div>
                                                )}
                                            </Step>
                                            <Step transition="scale" position="50%">
                                                {({ accomplished, index }) => (
                                                    <div
                                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`}}
                                                    className="d-flex align-items-center"
                                                    >
                                                        <div className={ `${accomplished ? this.state.step > index + 1 ? 'step-caption-check' : 'step-caption-selected':"step-caption"}`}>
                                                        {accomplished && (
                                                            this.state.step > index + 1
                                                            ? <img width="15" src={iconoCheck} />
                                                            : <img width="15" src={iconoNitsCP} />
                                                        )}
                                                        </div>
                                                        &nbsp;&nbsp;2. Nits
                                                    </div>
                                                )}
                                            </Step>
                                            <Step transition="scale" position="50%">
                                                {({ accomplished, index }) => (
                                                    <div
                                                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                    className="d-flex align-items-center"
                                                    >
                                                        <div className={ `${accomplished ? this.state.step > index + 1 ? 'step-caption-check' : 'step-caption-selected':"step-caption"}`}>
                                                            {accomplished && (
                                                                this.state.step > index + 1
                                                                    ? <img width="15" src={iconoCheck}/>
                                                                    :<img width="15" src={iconoContactosCP} /> 
                                                            )}
                                                        </div>
                                                        &nbsp;&nbsp;3. Contactos
                                                    </div>
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished, index }) => (
                                                    <div
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className={ `${accomplished ? this.state.step > index + 1 ? 'step-caption-check' : 'step-caption-selected':"step-caption"}`}>
                                                            {accomplished && (
                                                                this.state.step > index + 1
                                                                    ? <img width="15" src={iconoCheck}/>
                                                                    : <img width="15" src={iconoSucursalesCP} />
                                                            )}
                                                        </div>
                                                        &nbsp;&nbsp;4. Sucursales
                                                    </div>
                                                )}
                                            </Step>
                                            <Step transition="scale">
                                                {({ accomplished, index }) => (
                                                    <div
                                                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className={ `${accomplished ? this.state.step > index + 1 ? 'step-caption-check' : 'step-caption-selected':"step-caption"}`}>
                                                            {accomplished && (
                                                                this.state.step > index + 1
                                                                    ? <img width="15" src={iconoCheck}/>
                                                                    : <img width="15" src={iconoBancosCP} />
                                                            )}
                                                        </div>
                                                        &nbsp;&nbsp;5. Bancos
                                                    </div>
                                                )}
                                            </Step>
                                            {
                                                (<Step transition="scale">
                                                    {({ accomplished, index }) => (
                                                        <div
                                                            style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                            className="d-flex align-items-center"
                                                        >
                                                            <div className={ `${accomplished? 'step-caption-selected':"step-caption"}`}>
                                                                { permiso_cuenta
                                                                    ? accomplished && <img width="15" src={iconoCuentasCP} />
                                                                    : accomplished && <img width="15" src={iconoBancosCP} />
                                                                }
                                                            </div>
                                                            &nbsp;&nbsp;{ permiso_cuenta ? `6. Cuentas` : `5. Bancos`}
                                                        </div>
                                                    )}
                                                </Step>)
                                            }
                                        </ProgressBar>
                                    </div>
                                </div>
                        <div className="mb-4 card card-small m-t shadow" style={{ borderRadius:"10px"}}>
                            <div className="card-body">
                                <div className="p-0 px-3 pt-3">
                                    <LoadMask loading={loader} light>
                                        {
                                            this.state.editar ?
                                                (<Fragment>
                                                    {step === 1 && (
                                                        <PasoUnoForm
                                                            getMunicipios={this.props.setMunicipios}
                                                            municipios={this.props.municipios}
                                                            regiones={this.props.regiones}
                                                            onSubmit={this.nextStep}
                                                            route={this.state.route}
                                                            titulo={this.state.titulo}
                                                            es_cliente={this.state.es_cliente}
                                                            editar={this.state.editar}
                                                        /> 
                                                    )}
                                                    {step === 2 && (
                                                        <PasoDosForm
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                        />
                                                    )}
                                                    {step === 3 && (
                                                        <PasoTresForm
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                        />
                                                    )}
                                                    {step === 4 && (
                                                        <PasoCuatroForm
                                                            ChangeMunicipio={ChangeMunicipio}
                                                            AddMunicipio={AddMunicipio}
                                                            municipios_bancos={municipios_bancos}
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                            controles={controles}

                                                        />
                                                    )}
                                                    {step === 5 && (
                                                        <PasoCincoForm
                                                            onSubmit={permiso_cuenta ?  this.nextStep:this.handleSubmit}
                                                            previousStep={this.previousStep}
                                                            permiso_cuenta={permiso_cuenta}
                                                        />
                                                    )}
                                                    {permiso_cuenta && (step === 6 && (
                                                        <PasoSeisForm
                                                            onSubmit={this.handleSubmit}
                                                            previousStep={this.previousStep}
                                                            setEmpresasForm={setEmpresasForm}
                                                            setEmpresasFormProvedor={setEmpresasFormProvedor}
                                                            empresas={empresas}
                                                            es_cliente={ this.state.es_cliente}
                                                        />
                                                    ))}

                                                </Fragment>):
                                                (<Fragment>
                                                    {step === 1 && (
                                                        <PasoUnoForm
                                                            getMunicipios={this.props.setMunicipios}
                                                            municipios={this.props.municipios}
                                                            regiones={this.props.regiones}
                                                            onSubmit={this.nextStep}
                                                            route={this.state.route}
                                                            titulo={this.state.titulo}
                                                            es_cliente={this.state.es_cliente}
                                                            editar={this.state.editar}
                                                            permiso_cuenta={permiso_cuenta}
                                                            setEmpresasForm={setEmpresasForm}
                                                        />
                                                    )}
                                                    {step === 2 && (
                                                        <PasoDosForm
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                        />
                                                    )}
                                                    {step === 3 && (
                                                        <PasoTresForm
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                        />
                                                    )}
                                                    {step === 4 && (
                                                        <PasoCuatroForm
                                                            ChangeMunicipio={ChangeMunicipio}
                                                            AddMunicipio={AddMunicipio}
                                                            municipios_bancos={municipios_bancos}
                                                            onSubmit={this.nextStep}
                                                            previousStep={this.previousStep}
                                                            controles={controles}
                                                        />
                                                    )}
                                                    {step === 5 && (
                                                        <PasoCincoForm
                                                        onSubmit={permiso_cuenta ? this.nextStep : this.handleSubmit}
                                                        previousStep={this.previousStep}
                                                        permiso_cuenta={permiso_cuenta}

                                                        />
                                                    )}
                                                    {permiso_cuenta && (step === 6 && (
                                                        <PasoSeisForm
                                                            onSubmit={this.handleSubmit}
                                                            previousStep={this.previousStep}
                                                            setEmpresasForm={setEmpresasForm}
                                                            setEmpresasFormProvedor={setEmpresasFormProvedor}
                                                            empresas={empresas}
                                                            es_cliente={ this.state.es_cliente}
                                                        />
                                                    ))}
                                                </Fragment>
                                            )
                                        }
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

// const selector = formValueSelector('ClientesForm');
// Empresaform = connect(state =>{
//     const es_actualizado = selector(state, 'es_actualizado')
    
//     let permiso_cuenta = true

//     if (!!es_actualizado == true){
//         permiso_cuenta = false
//     }
//     else{permiso_cuenta=true}

//     return{
//         permiso_cuenta
//     }
// })(ClientesForm)
