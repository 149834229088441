import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
//import Modal from "react-responsive-modal";
import CardSimple from "../../../Utils/Cards/cardSimple";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

//import DetalleCajaGrid from "./detalleCajaGrid";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import ResumenMiCaja from "./resumenCaja";
import DetalleCaja from "./detalleCaja";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import DetalleVenta from "./DetalleVenta";

export default class MiCaja extends Component {
    state = {
        step: 1,
        cajaSeleccionada: undefined,
        cerrarCaja: false,
        collapse: 0,
    };

    pasoSiguiente = () => {
        this.setState({ step: this.state.step + 1 });
    };

    pasoAnterior = () => {
        this.setState({ step: this.state.step - 1 });
    };

    getCajasDisponibles = () => {
        this.props.cajasDisponibles();
        this.pasoSiguiente();
    };

    abrirCaja = (values) => {
        values.id_caja = this.state.cajaSeleccionada;
        this.props.abrirCaja(values);
    };
    funcionCambiarEstadoCerrarCaja = () => {
        this.setState({ cerrarCaja: false, step: 1 });
    };
    abrirOCerrarCaja = (values) => {
        if (this.state.cerrarCaja) {
            this.props.cerrarCaja(values, this.funcionCambiarEstadoCerrarCaja);
        } else {
            this.abrirCaja(values);
        }
        //this.setState({ cerrarCaja: false, step: 1 });
    };

    setCajaSeleccionada = (id) => {
        this.setState({ cajaSeleccionada: id });
        this.pasoSiguiente();
    };

    mensajeCajaAbierta = () => {
        this.props.mensajeCajaAbierta();
    };

    cerrarCaja = () => {
        this.setState({ cerrarCaja: true, step: 3 });
    };

    cancelarCerrarCaja = () => {
        this.setState({ cerrarCaja: false, step: 1 });
    };

    toggle = (e) => {
        let event = e.target.dataset.event;
        this.setState({
            collapse: this.state.collapse === Number(event) ? 0 : Number(event),
        });
    };

    render() {
        const {
            item,
            hijos,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            data,
            caja,
        } = this.props;
        const { collapse } = this.state;
        return (
            <CardSimple margen="mt-0 mb-4 py-3" row={true}>
                <LoadMask loading={loader} light className="col-12">
                    {this.props.match.params.id ? (
                        <DetalleVenta
                            id={this.props.match.params.id}
                            leerDetalle={this.props.getDetalleVenta}
                            detalleVenta={this.props.detalleVenta}
                            loader={this.props.loader}
                        />
                    ) : caja && caja.abierto && !this.state.cerrarCaja ? (
                        <DetalleCaja
                            data={caja}
                            cerrarCaja={this.cerrarCaja}
                            retiroDeCajaSucursal={
                                this.props.retiroDeCajaSucursal
                            }
                            agregarACajaSucursal={
                                this.props.agregarACajaSucursal
                            }
                            getHistorialVentas={this.props.getHistorialVentas}
                            ventas={this.props.ventas}
                            anularVenta={this.props.anularVenta}
                            pageVentas={this.props.pageVentas}
                            loaderVentas={this.props.loaderVentas}
                        />
                    ) : (
                        <div className="col-12 mx-0 row">
                            {this.state.step == 1 && (
                                <div className="col-12 row d-flex justify-content-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <img
                                            src={`${require("../../../../../../assets/img/Caja.png")}`}
                                            width="200"
                                        />
                                    </div>
                                    <div className="col-12 row d-flex justify-content-center">
                                        <div className="col-12 mb-3 d-flex justify-content-center">
                                            <small align="center">
                                                Para realizar ventas es
                                                necesario que habras una caja
                                                <br /> Por favor selecciona tu
                                                caja
                                            </small>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <button
                                                className="btn btn-azul-claro"
                                                onClick={
                                                    this.getCajasDisponibles
                                                }
                                            >
                                                Abrir caja
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.step == 2 && (
                                <div className="col-12 row d-flex justify-content-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h6> Selecciona tu caja para abrir </h6>
                                    </div>
                                    {data &&
                                    data.results && data.results[0] ? (
                                        <div className="col-12 row m-0 d-flex justify-content-center">
                                            {data.results.map((item, index) => {
                                                return (
                                                    <Card
                                                        style={{
                                                            marginBottom:
                                                                "1rem",
                                                            borderRadius:
                                                                "10px",
                                                            boxShadow: "None",
                                                        }}
                                                        className="col-12 p-0"
                                                        key={index}
                                                    >
                                                        <CardHeader
                                                            className="gradient-primary px-5 pt-3 pb-2 d-flex"
                                                            style={{
                                                                marginBottom:
                                                                    "0.5rem",
                                                                borderRadius:
                                                                    "10px",
                                                                justifyContent: "space-between",
                                                            }}
                                                            onClick={
                                                                this.toggle
                                                            }
                                                            data-event={index}
                                                        >
                                                            <div className="justify-content-start">
                                                                <label className="bold t-blanco">
                                                                    {
                                                                        item.nombre
                                                                    }
                                                                </label>
                                                            </div>
                                                            <div className="justify-content-end">
                                                                {collapse === index
                                                                    ? <span className="fa fa-chevron-circle-up fa-2x t-blanco"></span>
                                                                    : <span className="fa fa-chevron-circle-down fa-2x t-blanco"></span>
                                                                }
                                                            </div>
                                                        </CardHeader>
                                                        <Collapse
                                                            isOpen={
                                                                collapse ===
                                                                index
                                                            }
                                                        >
                                                            <CardBody
                                                                style={{
                                                                    marginTop:
                                                                        "1rem",
                                                                }}
                                                                className="row"
                                                            >
                                                                {item.mostradorsucursalhb_set && item.mostradorsucursalhb_set.map(
                                                                    (
                                                                        caja,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                className="col-6 col-md-6 row col-lg-3 mx-2 my-1 pt-4 pb-2 px-0"
                                                                                style={{
                                                                                    border:
                                                                                        "1px solid #D3D3D3",
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                    cursor:
                                                                                        "pointer",
                                                                                }}
                                                                                key={
                                                                                    caja.id
                                                                                }
                                                                                onClick={
                                                                                    caja.abierto
                                                                                        ? this
                                                                                              .mensajeCajaAbierta
                                                                                        : () => {
                                                                                              this.setCajaSeleccionada(
                                                                                                  caja.id
                                                                                              );
                                                                                          }
                                                                                }
                                                                            >
                                                                                <div className="col-12 px-0">
                                                                                    <div className="col-12 px-0 mb-3 d-flex justify-content-center">
                                                                                        <img
                                                                                            src={`${
                                                                                                caja.abierto
                                                                                                    ? require("../../../../../../assets/img/caja-inactivo.png")
                                                                                                    : require("../../../../../../assets/img/caja-activo.png")
                                                                                            }`}
                                                                                            style={{
                                                                                                maxWidth:
                                                                                                    "100%",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="col-12 d-flex justify-content-center">
                                                                                        <label
                                                                                            style={{
                                                                                                cursor:
                                                                                                    "pointer",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                caja.nombre
                                                                                            }
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-12 mb-3 d-flex justify-content-center">
                                                                                        <p>
                                                                                            {caja.abierto ? (
                                                                                                <div align="center">
                                                                                                    En
                                                                                                    uso
                                                                                                    por:
                                                                                                    <br />
                                                                                                    <label
                                                                                                        style={{
                                                                                                            cursor:
                                                                                                                "pointer",
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            caja
                                                                                                                .usuario
                                                                                                                .nombre
                                                                                                        }
                                                                                                    </label>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    Disponible
                                                                                                </span>
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className="col-12 row m-0 d-flex justify-content-center mt-3">
                                            <div className="col-12 d-flex justify-content-center">
                                                <br />
                                                <h3> NO HAY NINGUNA CAJA </h3>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center mt-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-azul-claro"
                                                    onClick={this.pasoAnterior}
                                                >
                                                    Regresar
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.step == 3 && (
                                <ResumenMiCaja
                                    onSubmit={this.abrirOCerrarCaja}
                                    data={caja}
                                    cerrarCaja={this.state.cerrarCaja}
                                    pasoAnterior={
                                        this.state.cerrarCaja
                                            ? this.cancelarCerrarCaja
                                            : this.pasoAnterior
                                    }
                                    initialValues={{
                                        cantidadBillete200: 0,
                                        cantidadBillete100: 0,
                                        cantidadBillete50: 0,
                                        cantidadBillete20: 0,
                                        cantidadBillete10: 0,
                                        cantidadBillete5: 0,
                                        cantidadBillete1: 0,
                                        cantidadMoneda1: 0,
                                        cantidadMoneda50: 0,
                                        cantidadMoneda25: 0,
                                        cantidadMoneda10: 0,
                                        cantidadMoneda5: 0,
                                    }}
                                />
                            )}
                        </div>
                    )}
                </LoadMask>
            </CardSimple>
        );
    }
}
