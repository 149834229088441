import React, { Fragment, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderNumber,
    renderCurrency,
    AsyncSelectField,
    SelectField,
} from "../../../Utils/renderField";

import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import { formatSelects } from "../../../Utils/renderField/renderReadField";

import { api } from "../../../../../utility/api";

const origen_pago = [
    { label: "Banco", value: 10 },
    { label: "Efectivo", value: 20 },
];

const getBonificaciones = (search) => {
    // let estate = 'A';
    return api
        .get("bonificacion", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const renderSueldoPuesto = ({
    fields,
    meta: { error, submitFailed },
    empresas,
    subtotales_sueldo,
    estado,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "20%" }}>SUELDO BASE</th>
                            <th style={{ width: "10%" }}>DESCUENTO</th>
                            <th style={{ width: "15%" }}>SUB. T.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((PET, index) => {
                            let transaccion = fields.get(index);
                            return (
                                <tr
                                    key={index}
                                    className="align-items-start"
                                    style={{ height: "68px" }}
                                >
                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "48px" }}
                                    >
                                        <img
                                            className="action-img iconoEliminarTabla iconoEliminar"
                                            style={{ padding: "10%" }}
                                            src={require("assets/img/icons/Cerrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                        />
                                    </td>
                                    {/*                                     <td
                                        className="sin-borde-top "
                                        style={{ width: "30%" }}
                                    >
                                        <Field
                                            name={`${PET}.empresa`}
                                            label="empresa"
                                            valueKey="value"
                                            labelKey="label"
                                            options={formatSelects(empresas)}
                                            component={SelectField}
                                        />
                                    </td> */}
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.sueldo`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.sueldo_descuento`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "20%" }}
                                        align="right"
                                    >
                                        <RenderMoneda
                                            monto={
                                                subtotales_sueldo
                                                    ? subtotales_sueldo[index]
                                                    : 0
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({ empresa: empresas[0].id,sueldo_descuento: 0 })}
                >
                    AGREGAR
                </button>
            </div>
        </div>

        {estado && (
            <div className="text-danger">Excedido el sueldo predeterminado</div>
        )}
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);

const renderBonficacionPuesto = ({
    fields,
    meta: { error, submitFailed },
    empresas,
    subtotales,
    estado,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "20%" }}>BONIFICACION</th>
                            <th style={{ width: "10%" }}>ORIGEN</th>
                            <th style={{ width: "15%" }}>MONTO</th>
                            <th style={{ width: "15%" }}>DESCUENTO</th>
                            <th style={{ width: "15%" }}>SUB. T.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((PET, index) => {
                            let transaccion = fields.get(index);
                            return (
                                <tr
                                    key={index}
                                    className="align-items-start"
                                    style={{ height: "68px" }}
                                >
                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "48px" }}
                                    >
                                        <img
                                            className="action-img iconoEliminarTabla iconoEliminar"
                                            style={{ padding: "15%" }}
                                            src={require("assets/img/icons/Cerrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.bono`}
                                            component={AsyncSelectField}
                                            loadOptions={getBonificaciones}
                                            type="text"
                                            filterOptions={false}
                                            valueKey="id"
                                            labelKey="nombre"
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    {/*                                     <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.empresa`}
                                            label="empresa"
                                            valueKey="value"
                                            labelKey="label"
                                            options={formatSelects(empresas)}
                                            component={SelectField}
                                        />
                                    </td> */}
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.origen_pago`}
                                            label="origen_pago"
                                            component={SelectField}
                                            options={origen_pago}
                                            type="text"
                                            className="form-control"
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.valor`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.valor_descuento`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "15%" }}
                                        align="right"
                                    >
                                        <RenderMoneda
                                            monto={
                                                subtotales
                                                    ? subtotales[index]
                                                    : 0
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({ empresa: empresas[0].id, valor_descuento: 0 })}
                >
                    AGREGAR
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);

const renderActividades = ({
    fields,
    meta: { error, submitFailed },
    empresas,
    subtotalesActividades,
    estado,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className=" table-header-wrapper">
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "20%" }}>HORAS TRABAJADAS</th>
                            <th style={{ width: "10%" }}>ORIGEN</th>
                            <th style={{ width: "15%" }}>MONTO</th>
                            <th style={{ width: "15%" }}>DESCUENTO</th>
                            <th style={{ width: "15%" }}>SUB. T.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((PET, index) => {
                            let transaccion = fields.get(index);
                            return (
                                <tr
                                    key={index}
                                    className="align-items-start"
                                    style={{ height: "68px" }}
                                >
                                    <td
                                        className="text-center sin-borde-top"
                                        style={{ width: "48px" }}
                                    >
                                        <img
                                            className="action-img"
                                            src={require("assets/img/icons/Cerrar.png")}
                                            alt="Borrar"
                                            title="Borrar"
                                            onClick={() => {
                                                fields.remove(index);
                                            }}
                                        />
                                    </td>
                                    {/*                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.empresa`}
                                            label="empresa"
                                            valueKey="value"
                                            labelKey="label"
                                            options={formatSelects(empresas)}
                                            component={SelectField}
                                        />
                                    </td> */}
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "20%" }}
                                    >
                                        <Field
                                            name={`${PET}.horas`}
                                            type="text"
                                            component={renderNumber}
                                            decimalScale={2}
                                            disabled={true}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.origen_pago`}
                                            label="origen_pago"
                                            component={SelectField}
                                            options={origen_pago}
                                            type="text"
                                            className="form-control"
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.valor`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top "
                                        style={{ width: "15%" }}
                                    >
                                        <Field
                                            name={`${PET}.valor_descuento`}
                                            label="monto"
                                            component={renderCurrency}
                                            parse={(cell) => parseFloat(cell)}
                                            className="form-control"
                                            top={{
                                                top: "67px",
                                                position: "inherit",
                                            }}
                                        />
                                    </td>
                                    <td
                                        className="sin-borde-top"
                                        style={{ width: "15%" }}
                                    >
                                        <RenderMoneda
                                            monto={
                                                subtotalesActividades
                                                    ? subtotalesActividades[
                                                          index
                                                      ]
                                                    : 0
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() =>
                        fields.push({
                            empresa: empresas[0].id,
                            origen_pago: 20,
                            valor: 0,
                            valor_descuento: 0,
                            horas: 0,
                        })
                    }
                >
                    AGREGAR
                </button>
            </div>
        </div>
        {submitFailed && error && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);

class EditBonosForm extends React.Component {
    render() {
        const {
            handleSubmit,
            total,
            total_banco,
            total_efectivo,
            empresas,
            subtotales,
            subtotales_sueldo,
            item,
            total_sueldo,
            total_bonos,
            sueldo_base,
            info_trabajador,
            subtotalesActividades,
            total_actividades,
        } = this.props;
        return (
            <form
                name="EditBonosForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row col-12 m-0">
                    <div className="form-group has-feedback row col-12 ">
                        <div className="form-group has-feedback  col-12 ">
                            <div
                                className="row"
                            >
                                <div className="col-12 row p-0">
                                    <div className="col-12 row">
                                        <div
                                            className={`row form-group has-feedback col-sm-6 col-12 d-flex align-items-center  justify-content-md-center justify-content-start m-0`}
                                        >
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total de Sueldos:
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <h5
                                                    className={
                                                        sueldo_base <
                                                        total_sueldo
                                                            ? "text-danger"
                                                            : `t-primary`
                                                    }
                                                >
                                                    <RenderMoneda
                                                        monto={total_sueldo}
                                                    />
                                                </h5>
                                            </div>
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total de Bonos:
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <h5 className="t-primary">
                                                    <RenderMoneda
                                                        monto={total_bonos}
                                                    />
                                                </h5>
                                            </div>
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total de Actividades:
                                                </label>
                                            </div>

                                            <div className="col-6 d-flex justify-content-end">
                                                <h5 className="t-primary">
                                                    <RenderMoneda
                                                        monto={
                                                            total_actividades
                                                        }
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                        <div
                                            className={`row form-group has-feedback col-sm-6 col-12 d-flex align-items-center  justify-content-md-center justify-content-start m-0`}
                                        >
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total Banco:
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <h5 className="t-primary">
                                                    <RenderMoneda
                                                        monto={total_banco}
                                                    />
                                                </h5>
                                            </div>
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total Efetivo:
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <h5 className="t-primary">
                                                    <RenderMoneda
                                                        monto={total_efectivo}
                                                    />
                                                </h5>
                                            </div>
                                            <div className="col-6">
                                                <label className="t-musgo text-uppercase">
                                                    Total:
                                                </label>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <h5 className="t-primary">
                                                    <RenderMoneda
                                                        monto={total}
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group has-feedback row col-12 ">
                        <label className="t-texto text-uppercase bold">
                            {info_trabajador.puesto.tipo == 5
                                ? "PRODUCCION"
                                : "SUELDO BASE"}
                        </label>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                        <FieldArray
                            name="sueldos"
                            label="sueldos"
                            component={renderSueldoPuesto}
                            className="form-control"
                            empresas={empresas}
                            simbolo={"Q."}
                            subtotales_sueldo={subtotales_sueldo}
                            estado={sueldo_base < total_sueldo ? true : false}
                        />
                    </div>
                    <div className="form-group has-feedback row col-12 ">
                        <label className="t-texto text-uppercase bold">
                            BONIFICACIONES
                        </label>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 0px 10px 0px",
                            }}
                        />
                        <br />
                        <FieldArray
                            name="bonificaciones"
                            label="bonificaciones"
                            component={renderBonficacionPuesto}
                            className="form-control"
                            empresas={empresas}
                            simbolo={"Q."}
                            subtotales={subtotales}
                        />
                    </div>
                </div>
                {info_trabajador.puesto.tipo == 5 && (
                    <div>
                        <div className="form-group has-feedback row col-12 ">
                            <h5 className="t-primary text-uppercase">
                                ACTIVIDADES
                            </h5>
                        </div>
                        <div className="form-group has-feedback row col-12 ">
                            <FieldArray
                                name="actividades"
                                label="actividades"
                                component={renderActividades}
                                className="form-control"
                                empresas={empresas}
                                simbolo={"Q."}
                                subtotalesActividades={subtotalesActividades}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <Link
                                className="btn btn-secondary m-1 align-self-center"
                                to={`/planilla/${item ? item.id : "nuevo"}`}
                            >
                                Cancelar
                            </Link>
                            {/* <button onClick={(e)=>{e.preventDefault(); this.props.setOpenModalEmpleado(false)}} className="btn btn-secondary  align-self-cente">CANCELAR</button> */}
                            <button
                                type="submit"
                                className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

EditBonosForm = reduxForm({
    form: "EditBonosForm", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        // bonificaciones ------------------------------------------------------------
        if (data.bonificaciones) {
            const detalleArray = [];
            data.bonificaciones.forEach(function (bono, index) {
                let detErrors = {};
                let repetidos = [];
                if (bono.bono) {
                    repetidos = _.filter(data.bonificaciones, (x) => {
                        if (x.bono)
                            return (
                                x.bono.id == bono.bono.id &&
                                x.empresa == bono.empresa
                            );
                        return false;
                    });
                }
                if (!bono.bono) {
                    detErrors.bono = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.bono = "Bono repetido";
                    }
                }
                if (!bono.valor) {
                    detErrors.valor = "Campo requerido";
                } else {
                    if (
                        parseFloat(bono.valor) <
                        parseFloat(bono.valor_descuento)
                    ) {
                        detErrors.valor = "Debe ser mayor al descuento";
                    }
                }

                if (parseFloat(bono.valor_descuento) < 0) {
                    detErrors.valor_descuento = "Debe ser mayor o igual a 0";
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.bonificaciones = detalleArray;
            }
        } else {
            errors.bonificaciones = {
                _error: "Debe de ingresar almenos una bonificacion",
            };
        }
        // sueldo ---------------------------------------------------------
        if (data.sueldos) {
            const detalleArray = [];
            data.sueldos.forEach(function (pago, index) {
                let detErrors = {};
                let repetidos = [];
                if (pago.empresa) {
                    repetidos = _.filter(data.sueldos, (x) => {
                        if (x.empresa) return x.empresa == pago.empresa;
                        return false;
                    });
                }
                if (!pago.empresa) {
                    detErrors.empresa = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.empresa = "Empresa repetida";
                    }
                }
                if (!pago.sueldo) {
                    detErrors.sueldo = "Campo requerido";
                } else {
                    if (
                        parseFloat(pago.sueldo) <
                        parseFloat(pago.sueldo_descuento)
                    ) {
                        detErrors.sueldo = "Debe ser mayor al descuento";
                    }
                }

                if (parseFloat(pago.sueldo_descuento) < 0) {
                    detErrors.sueldo_descuento = "Debe ser mayor o igual a 0";
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.sueldos = detalleArray;
            }
        } else {
            errors.sueldos = { _error: "Debe de ingresar almenos una sueldo" };
        }
        // actividades  ------------------------------------------------------------
        if (data.actividades) {
            const detalleArray = [];
            data.actividades.forEach(function (acto, index) {
                let detErrors = {};
                let repetidos = [];
                if (acto.empresa) {
                    repetidos = _.filter(data.actividades, (x) => {
                        if (x.empresa) return x.empresa == acto.empresa;
                        return false;
                    });
                }
                if (!acto.empresa) {
                    detErrors.empresa = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.empresa = "Empresa repetida";
                    }
                }
                if (!acto.valor) {
                    detErrors.valor = "Campo requerido";
                } else {
                    if (
                        parseFloat(acto.valor) <
                        parseFloat(acto.valor_descuento)
                    ) {
                        detErrors.valor = "Debe ser mayor al descuento";
                    }
                }

                if (parseFloat(acto.valor_descuento) < 0) {
                    detErrors.valor_descuento = "Debe ser mayor o igual a 0";
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.actividades = detalleArray;
            }
        }
        // else{
        //     errors.actividades = {_error: 'Debe de ingresar almenos una bonificacion'}
        // }
        //  ------------------------------------------------------------

        return errors;
    },
})(EditBonosForm);

const selector = formValueSelector("EditBonosForm");
EditBonosForm = connect((state) => {
    const bonifiaciones = selector(state, "bonificaciones");
    const sueldos = selector(state, "sueldos");
    const actividades = selector(state, "actividades");
    let bonos_trabajador = [];
    let sueldos_empresas = [];
    let actividades_empresas = [];
    if (bonifiaciones) {
        bonos_trabajador = bonifiaciones;
    }
    if (sueldos) {
        sueldos_empresas = sueldos;
    }
    if (actividades) {
        actividades_empresas = actividades;
    }

    let total = 0;
    let total_efectivo = 0;
    let total_banco = 0;
    let total_bonos = 0;
    let total_actividades = 0;
    let total_sueldo = 0;

    let subtotales = [];
    let subtotales_sueldo = [];
    let subtotalesActividades = [];

    let sumatoria = 0;

    bonos_trabajador.forEach((item, index) => {
        sumatoria = parseFloat(item.valor) - parseFloat(item.valor_descuento);
        item.valor ? subtotales.push(sumatoria) : subtotales.push(0);
        if (item.bono && sumatoria >= 0) {
            if (item.origen_pago == 20) {
                total_efectivo += item.valor ? sumatoria : 0;
            } else {
                total_banco += item.valor ? sumatoria : 0;
            }
            total += item.valor ? sumatoria : 0;
            total_bonos += sumatoria;
        }
    });

    sueldos_empresas.forEach((item, index) => {
        sumatoria = parseFloat(item.sueldo) - parseFloat(item.sueldo_descuento);
        item.sueldo
            ? subtotales_sueldo.push(sumatoria)
            : subtotales_sueldo.push(0);

        if (parseFloat(item.sueldo) > 0 && sumatoria >= 0) {
            total_banco += sumatoria;
            total += sumatoria;
            total_sueldo += sumatoria;
        }
    });

    actividades_empresas.forEach((item, index) => {
        sumatoria = parseFloat(item.valor) - parseFloat(item.valor_descuento);
        item.valor
            ? subtotalesActividades.push(sumatoria)
            : subtotalesActividades.push(0);

        if (sumatoria >= 0) {
            if (item.origen_pago == 20) {
                total_efectivo += item.valor ? sumatoria : 0;
            } else {
                total_banco += item.valor ? sumatoria : 0;
            }
            total += item.valor ? sumatoria : 0;
            total_actividades += sumatoria;
        }
    });

    return {
        total,
        total_banco,
        total_efectivo,
        subtotales,
        subtotales_sueldo,
        total_bonos,
        total_sueldo,
        subtotalesActividades,
        total_actividades,
    };
})(EditBonosForm);

export default EditBonosForm;
