import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/OrdenProduccion/ordenproduccion';
import DetalleOP from './detalleOrden';

const mstp = state => {
    return {...state.produccion, permisos: state.login.me.permisos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(DetalleOP)
