import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField, renderTextArea, renderCurrencyOC } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';


const AnulacionForm = (props) => {
    const { handleSubmit, tiene_monto_credito} = props;
    console.log(props.simbolo)
    return (
        <form name="anulacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center mt-4">
                <div className="form-group has-feedback col-md-10 col-12">
                    <Field
                        name="descripcionAnulacion"
                        label="descripcionAnulacion"
                        placeholder="Descripción de la anulación"
                        component={renderTextArea}
                        type="text"
                        top ={{top:"67px"}}
                        className="form-control" />
                </div>
                {
                    (tiene_monto_credito) && (
                        <div className="form-group has-feedback col-md-10 col-12">
                            <Field
                                name="credito"
                                label="credito"
                                component={renderCurrencyOC}
                                className="form-control"
                                placeholder="Crédito de Anulación"
                            />
                        </div>
                    )
                }

            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" onClick={() => {
                            props.cerrarModal(false);
                        }} className="btn btn-secondary justify-content-center align-self-center">Cancelar</button>
                        <button type="submit" className="btn btn-azul-claro m-1 ml-md-4 align-self-center">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'anulacionForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcionAnulacion: validators.exists()('Este campo es requerido'),
        });
    },
})(AnulacionForm);
