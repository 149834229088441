import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteMateriaPrima from "../../../Utils/Toolbar/ToolbarReporteMateriaPrima";

export default class ReporteMateriaPrima extends Component {
    componentDidMount() {
        //this.props.getReporteMateriaPrima();
        this.props.getReporteMateriaPrimaxMes();
    }

    render() {
        const { loader, loaderGrafica, data, dataGrafica, page, getReporteMateriaPrima } = this.props;
        return (
            <CardSimple row={true} margen="mt-0" style={{ marginTop: "-50px" }}>
                <ToolbarReporteMateriaPrima
                    changeProveedor={this.props.changeProveedor}
                    changeProducto={this.props.changeProducto}
                    producto={this.props.producto && this.props.producto}
                    proveedor={this.props.proveedor && this.props.proveedor}
                    changeFechaInicial = {this.props.changeFechaInicial}
                    fecha_inicial = {this.props.fecha_inicial && this.props.fecha_inicial}
                    fecha_final = {this.props.fecha_final && this.props.fecha_final}
                    changeFechaFinal = {this.props.changeFechaFinal}
                />
                <br/>
                <div className="col-12">
                    <hr
                        style={{
                            background: "#408EAA",
                            height: "2px",
                            width: "100%",
                            marginTop: '0px',
                        }}
                        />
                </div>
                <br/>
                <LoadMask loading={loaderGrafica} light type={"TailSpin"}>
                        <div className="col-12 row m-0 p-0 d-flex">
                            <div className="col-md-12 mx-0 px-0">
                                <div className="">
                                    {dataGrafica && dataGrafica.length && (

                                        <div style={{ width: "100%", flex: 1 }}>
                                        <Chart
                                            width={"100%"}
                                            height={250}
                                            chartType="ColumnChart"
                                            loader={<div>Cargando Gráfica</div>}
                                            data={dataGrafica}
                                            options={{
                                                chartArea: { width:"80%", right: "5%" },
                                                bar: { groupWidth: "40%" },
                                                colors: ["#128085", "#F8C9A8"],
                                                vAxis: {
                                                    format: "Q ###,###,###.00",
                                                    baselineColor: "#000",
                                                },
                                                tooltip: {
                                                    format: "Q ###,###,###.00",
                                                },
                                                animation: {
                                                    startup: true,
                                                    easing: "linear",
                                                    duration: 1000,
                                                },
                                                legend: {
                                                    position: "top",
                                                    alignment: "center",
                                                },
                                                fontName: "Cairo-Regular",
                                            }}
                                            formatters={[
                                                {
                                                    type: "NumberFormat",
                                                    column: 1,
                                                    options: {
                                                        prefix: "Q ",
                                                    },
                                                },
                                            ]}
                                            legendToggle
                                            />
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                <br/>
                <br/>
                <label className="t-primary"> Listado </label>
                <hr
                    style={{
                        background: "#408EAA",
                        height: "2px",
                        width: "100%",
                        marginTop: '0px',
                    }}
                    />
                <div className="col-12 row m-0 p-0 d-flex">
                    <div className="col-md-12 mx-0 px-0">
                        {data && data.results && (
                            <Grid
                                hover
                                striped
                                page={page}
                                data={data}
                                loading={loader}
                                onPageChange={getReporteMateriaPrima}
                                pagination={true}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="5%"
                                    dataFormat={standardActions({
                                    })}
                                >
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_orden"
                                    width="15%"
                                    dataFormat={(cell) =>
                                        cell ? <RenderDateTime fecha ={cell} /> : "Sin registro"
                                    }
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre_proveedor"
                                    dataAlign="center"
                                    dataFormat={(cell)=>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    PROVEEDOR
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="producto"
                                    headerAlign="center"
                                    dataAlign="right"
                                    dataFormat={(cell) =>
                                        cell ? <RenderMoneda monto={cell.costo} /> : <RenderMoneda monto={0}/>
                                    }
                                >
                                    COSTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="precio_unitario"
                                    headerAlign="center"
                                    dataAlign="right"
                                    dataFormat={(cell) =>
                                        cell ? <RenderMoneda monto={cell} /> : <RenderMoneda monto={0}/>
                                    }
                                >
                                    PRECIO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cantidad"
                                    dataAlign="center"
                                    dataFormat={(cell)=>
                                        cell ? cell : "Sin registro"
                                    }
                                >
                                    CANTIDAD
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="subtotal"
                                    headerAlign="center"
                                    dataAlign="right"
                                    dataFormat={(cell) =>
                                        cell ? <RenderMoneda monto={cell} /> : <RenderMoneda monto={0}/>
                                    }
                                >
                                    SUBTOTAL
                                </TableHeaderColumn>
                            </Grid>
                        )}
                    </div>
                </div>
            </CardSimple>
        );
    }
}
