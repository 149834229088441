import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
    RenderMoneda,
    getSimbolo,
} from "../../../Utils/renderField/renderTableField";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import Swal from "sweetalert2";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderFieldCheck } from "../../../Utils/renderField/renderField";
import {
    renderField,
    renderTextArea,
    renderSelectField,
    renderCurrency,
    AsyncSelectField,
    renderFieldRadio,
} from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import { Monedas } from "../../../../../utility/constants";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import Modal from "react-responsive-modal";
import { api } from "api";
import moment from "moment";
import EditBonosForm from "../editarbonos/editBonos";

class ItemArray extends Component {
    state = {
        trabajador: {},
    };
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        // this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getEmpleados(search),
        };
    }
    componentDidMount() {
        if (this.props.empleado_seleccionado) {
            this.setState({
                trabajador: this.props.empleado_seleccionado.trabajador,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.empleado_seleccionado !== prevProps.empleado_seleccionado
        ) {
            const { todos, index } = this.props;
            if (this.props.empleado_seleccionado) {
                this.setState({
                    trabajador: this.props.empleado_seleccionado.trabajador,
                });
            }
        }
    }
    render() {
        const {
            index,
            trabajador,
            eliminar,
            empleado_seleccionado,
            editar,
        } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td
                    className="text-center sin-borde-top"
                    style={{ width: "48px" }}
                >
                    <div className="d-flex justify-content-center">
                        <Link
                            to="/planilla/EdicionBonos"
                            className="px-2"
                            onClick={(e) => {
                                editar();
                            }}
                        >
                            <img
                                className="action-img iconoEditarTabla iconoEditar"
                                style={{ padding: "20%" }}
                                src={require("assets/img/icons/editar.png")}
                                alt="Editar"
                                title="Editar"
                            />
                        </Link>
                        <div className="px-2">
                            <img
                                className="action-img iconoEliminarTabla iconoEliminar"
                                style={{ padding: "20%" }}
                                src={require("assets/img/icons/Cerrar.png")}
                                alt="Borrar"
                                title="Borrar"
                                onClick={() => {
                                    eliminar();
                                }}
                            />
                        </div>
                    </div>
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${trabajador}`}
                        name={`${trabajador}.trabajador`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top={{ top: "67px", position: "inherit" }}
                        type="text"
                        valueKey="id"
                        labelKey="nombre_completo"
                        onChange={(e) => {
                            this.setState({ trabajador: e });
                        }}
                        className="form-control"
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    <span>
                        {this.state.trabajador && this.state.trabajador.puesto
                            ? this.state.trabajador.puesto.nombre
                            : "NA"}
                    </span>
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }} align="right">
                    <RenderMoneda
                        monto={
                            this.state.trabajador &&
                            this.state.trabajador.bonos_puesto &&
                            this.state.trabajador.actividades
                                ? this.state.trabajador.bonos_puesto.total +
                                  this.state.trabajador.puesto.total +
                                  this.state.trabajador.actividades.total
                                : 0
                        }
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }} align="right">
                    <RenderMoneda
                        monto={
                            this.state.trabajador &&
                            this.state.trabajador.bonos_puesto &&
                            this.state.trabajador.actividades
                                ? this.state.trabajador.bonos_puesto
                                      .total_banco +
                                  this.state.trabajador.puesto.total +
                                  this.state.trabajador.actividades.total_banco
                                : 0
                        }
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "10%" }} align="right">
                    <RenderMoneda
                        monto={
                            this.state.trabajador &&
                            this.state.trabajador.bonos_puesto &&
                            this.state.trabajador.actividades
                                ? this.state.trabajador.bonos_puesto
                                      .total_efectivo +
                                  this.state.trabajador.actividades
                                      .total_efectivo
                                : 0
                        }
                    />
                </td>
            </tr>
        );
    }
}

const renderPlanilla = ({
    fields,
    meta: { error, submitFailed },
    getEmpleados,
    todos_trabajadores,
    productos,
    proveedor,
    setOpenModalEmpleado,
}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: "30%" }}>NOMBRE</th>
                            <th style={{ width: "15%" }}>PUESTO</th>
                            <th style={{ width: "15%" }}>TOTAL</th>
                            <th style={{ width: "15%" }}>BANCO</th>
                            <th style={{ width: "15%" }}>EFECTIVO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((trabajador, index) => {
                            let todos = fields.getAll();
                            const empleado = todos_trabajadores[index];

                            return (
                                <ItemArray
                                    proveedor={proveedor}
                                    empleado_seleccionado={empleado}
                                    index={index}
                                    key={index}
                                    todos={todos}
                                    trabajador={trabajador}
                                    getEmpleados={getEmpleados}
                                    eliminar={() => {
                                        fields.remove(index);
                                    }}
                                    editar={() => {
                                        setOpenModalEmpleado(
                                            true,
                                            fields.get(index),
                                            index
                                        );
                                    }}
                                    field={fields ? fields.get(index) : null}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button
                    type="button"
                    className="btn btn-primary py-0"
                    onClick={() => fields.push({})}
                >
                    Agregar
                </button>
            </div>
        </div>
        {(submitFailed || error) && (
            <div className="invalid-feedback-array text-danger">{error}</div>
        )}
    </div>
);
//fin de render de productos
class TotalForm extends Component {
    state = {
        proveedor: null,
        moneda: null,
        ModalEmpleado: false,
        info_trabajador: {},
        index: null,
    };
    handleSubmit = (values) => {
        const { handleSubmit, es_borrador } = this.props;
        values.preventDefault();
        if (es_borrador == "1") handleSubmit(values);
        else {
            Swal.fire({
                title: "¿Desea aplicar el pago de planilla?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, aplicar pago!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    handleSubmit(values);
                }
            });
        }
    };
    getEmpleados = (search) => {
        let fecha1 = moment(this.props.fechaI).format("YYYY-MM-DD");
        let fecha2 = moment(this.props.fechaF).format("YYYY-MM-DD");
        let params = {
            fechaI: fecha1,
            fechaF: fecha2,
            search,
        };
        return api
            .get("empleados/getEmpleadosConBonos", params)
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    setOpenModalEmpleado = (val, info_trabajador = {}, index = null) => {
        const {
            editBonosEmpleados,
            setEmpleadoInfo,
            todos_trabajadores,
        } = this.props;
        if (val == true) {
            //     this.setState({ModalEmpleado: val, info_trabajador:info_trabajador.trabajador, index})
            //     // editBonosEmpleados(info_trabajador.trabajador.bonos_puesto.bonos)
            setEmpleadoInfo(
                info_trabajador.trabajador,
                index,
                todos_trabajadores,
                true
            );
        } else setEmpleadoInfo({}, null, [], false);
        // this.setState({ModalEmpleado: val, info_trabajador, index})
    };
    render() {
        const {
            handleSubmit,
            total,
            total_banco,
            total_efectivo,
            previousStep,
            total_empresas,
            texto_descripcion,
            loader,
            empresas,
            productos,
            todos_trabajadores,
        } = this.props;
        return (
            <div>
            <form
                name="planillaForm"
                className="form-validate mb-lg "
                onSubmit={this.handleSubmit}
            >
                <div className="row justify-content-center">
                    <div className="col-md-12 row m-0">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            <label
                                className="t-musgo"
                                style={{
                                    fontWeight: "bolder",
                                }}
                            >
                                TOTAL A PAGAR
                            </label>
                            <label
                                className="t-primary"
                                style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bolder",
                                }}
                            >
                                <RenderMoneda monto={total} />
                            </label>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center">
                            <label
                                className="t-musgo"
                                style={{
                                    fontWeight: "bolder",
                                }}
                            >
                                TOTAL EN BANCO
                            </label>
                            <label
                                className="t-primary"
                                style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bolder",
                                }}
                            >
                                <RenderMoneda monto={total_banco} />
                            </label>
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            <label
                                className="t-musgo"
                                style={{ fontWeight: "bolder" }}
                            >
                                TOTAL A EFECTIVO
                            </label>
                            <label
                                className="t-primary"
                                style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "bolder",
                                }}
                            >
                                <RenderMoneda monto={total_efectivo} />
                            </label>
                        </div>
                    </div>
                </div>
                <br />
                <div className="col-12">
                    <div className="row col-12 justify-content-center">
                        <h4 className="t-primary">{texto_descripcion}</h4>
                    </div>
                    <div className="row col-12 col-lg-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-lg-6 col-md-12 m-0 p-0 d-flex justify-content-end">
                            <div>
                                <label className="t-azul" htmlFor="nombre">
                                    Fecha Inicio:
                                </label>
                                <Field
                                    name="fechaI"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                    changePersonalizado={(e) => {
                                        this.props.selectEmpleados(
                                            e,
                                            this.props.fechaF
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 m-0 p-0 d-flex justify-content-start">
                            <div>
                                <label className="t-azul" htmlFor="nombre">
                                    Fecha Final:
                                </label>
                                <Field
                                    name="fechaF"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                    changePersonalizado={(e) => {
                                        this.props.selectEmpleados(
                                            this.props.fechaI,
                                            e
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className=" row col-12 col-lg-12 m-0 p-0 d-flex justify-content-start">
                        <div className="col-lg-6 col-md-12 m-0 p-0 d-flex justify-content-center">
                            <div
                                className={`${
                                    this.props.es_borrador == 1
                                        ? "colorOptionModal"
                                        : ""
                                } form-group has-feedback d-flex pr-4 justify-content-center`}
                            >
                                <Field
                                    name="es_borrador"
                                    label="Borrador"
                                    component={renderFieldRadio}
                                    type="radio"
                                    value={"1"}
                                    className="form-control"
                                />
                            </div>
                            <div
                                className={`${
                                    this.props.es_borrador == 0
                                        ? "colorOptionModal"
                                        : ""
                                } form-group has-feedback d-flex justify-content-center`}
                            >
                                <Field
                                    name="es_borrador"
                                    label="Pagar planilla"
                                    component={renderFieldRadio}
                                    type="radio"
                                    value={"0"}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 m-0 p-0 d-flex justify-content-center"></div>
                    </div>
                    <br />
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-12 m-0 p-0">
                            <FieldArray
                                name="detalleplanilla"
                                productos={productos}
                                reRender={this.reRender}
                                getEmpleados={this.getEmpleados}
                                component={renderPlanilla}
                                todos_trabajadores={todos_trabajadores}
                                setOpenModalEmpleado={this.setOpenModalEmpleado}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <Link
                                className="btn btn-secondary  align-self-center"
                                to="/planilla"
                            >
                                Cancelar
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            </div>
        );
    }
}

TotalForm = reduxForm({
    form: "planillaForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if (!data.detalleplanilla || !data.detalleplanilla.length) {
            errors.detalleplanilla = {
                _error: "No se puede generar una planilla vacia",
            };
        } else {
            const detalleArray = [];
            data.detalleplanilla.forEach(function (trabajador, index) {
                let detErrors = {};
                let repetidos = [];
                if (trabajador.trabajador) {
                    repetidos = _.filter(data.detalleplanilla, (x) => {
                        if (x.trabajador)
                            return x.trabajador.id == trabajador.trabajador.id;
                        return false;
                    });
                }
                if (!trabajador.trabajador) {
                    detErrors.trabajador = "Campo requerido";
                    detErrors.touched = true;
                } else {
                    if (1 < repetidos.length) {
                        detErrors.trabajador = "Este nombre esta repetido";
                        detErrors.touched = true;
                    }
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.detalleplanilla = detalleArray;
            }
        }

        return errors;
    },
})(TotalForm);

const selector = formValueSelector("planillaForm");
TotalForm = connect((state) => {
    const trabajadores = selector(state, "detalleplanilla");
    const es_borrador = selector(state, "es_borrador");
    let todos_trabajadores = [];
    if (trabajadores) {
        todos_trabajadores = trabajadores;
    }

    // TOTAL POR TIPO DE PAGO

    let total = 0;
    let total_efectivo = 0;
    let total_banco = 0;
    todos_trabajadores.forEach((item, index) => {
        if (item.trabajador) {
            total += item.trabajador.bonos_puesto.total;
            total_banco += item.trabajador.bonos_puesto.total_banco;
            total_efectivo += item.trabajador.bonos_puesto.total_efectivo;
        }
        if (item.trabajador){
            let sueldo = item.trabajador.puesto.sueldo
            sueldo.forEach((item, index)=>{
                if (item.sueldo){
                    total += item.sueldo - item.sueldo_descuento
                    total_banco += item.sueldo - item.sueldo_descuento
                }
            })
        }
        if (item.trabajador) {
            total += item.trabajador.actividades.total;
            total_banco += item.trabajador.actividades.total_banco;
            total_efectivo += item.trabajador.actividades.total_efectivo;
        }
        if (item.trabajador && item.trabajador.descuentos_puesto){
            total += item.trabajador.descuentos_puesto.total
            total_banco += item.trabajador.descuentos_puesto.total
        }
    });

    //  TOTAL POR EMPRESA
    let suma = 0;
    let empresas = new Map();
    todos_trabajadores.forEach((item, index) => {
        if(item.trabajador){
            item.trabajador.puesto.sueldo.forEach((item, index) => {
                if (empresas.has(item.empresa) == false) {
                    empresas.set(
                        item.empresa,
                        parseFloat(item.sueldo) - parseFloat(item.sueldo_descuento)
                    );
                } else {
                    empresas[item.empresa] +=
                        parseFloat(item.sueldo) - parseFloat(item.sueldo_descuento);
                }
            });
            item.trabajador.bonos_puesto.bonos.forEach((item, index) => {
                if (empresas.has(item.empresa) == false) {
                    empresas.set(
                        item.empresa,
                        parseFloat(item.valor) - parseFloat(item.valor_descuento)
                    );
                } else {
                    suma =
                        empresas.get(item.empresa) +
                        (parseFloat(item.valor) - parseFloat(item.valor_descuento));
                    empresas.set(item.empresa, suma);
                }
            });
            item.trabajador.actividades.sueldo.forEach((item, index) => {
                if (empresas.has(item.empresa) == false) {
                    empresas.set(
                        item.empresa,
                        parseFloat(item.valor) - parseFloat(item.valor_descuento)
                    );
                } else {
                    empresas[item.empresa] +=
                        parseFloat(item.valor) - parseFloat(item.valor_descuento);
                }
            });
        }
    });
    let total_empresas = [];
    empresas.forEach((item, key, map) => {
        total_empresas.push({ empresa: key, total: item });
    });

    return {
        total,
        total_banco,
        total_efectivo,
        total_empresas,
        es_borrador,
        total,
        total_banco,
        total_efectivo,
        todos_trabajadores,
    };
})(TotalForm);

export default TotalForm;
