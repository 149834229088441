import { connect } from "react-redux";
import { actions } from "../../../../../../redux/modules/gastos/gastos";
import EgresoCajaForm from "./egresoForm";

const mstp = (state) => {
    return { ...state.gastos };
};

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(EgresoCajaForm);
