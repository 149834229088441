import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ToolbarReporteCuentas from "../../../Utils/Toolbar/ToolbarReporteCuentas";

export default class OrdenesPorPoC extends Component {
    state = {
        es_cliente: this.props.es_cliente,
    };
    handleEstado = (value) => {
        let idclipro = this.props.match.params.idclipro;
        this.setState({ es_cliente: value });
        this.props.cuentasporCOP(
            {
                clienteproveedor: idclipro,
                vendedor: null,
                estado: 1,
                moneda: "GTQ",
                tipo_cambio_dolar: 0.13,
                tipo_cambio_euro: 0.12,
            },
            value,
            1
        );
    };
    handleDireccion = (direccion) => {
        if (direccion == "/ordenes_por_cobrar/:idclipro")
            this.handleEstado(true);
        else this.handleEstado(false);
    };
    componentDidMount() {
        this.handleDireccion(this.props.match.path);
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.path != prevProps.match.path) {
            this.handleDireccion(this.props.match.path);
        }
    }
    render() {
        const {
            deudasCoP,
            page,
            data,
            loader,
            open_modal,
            permisos,
            totalizado,
            variables_busqueda,
        } = this.props;
        if (permisos["cuentasp"] === false && permisos["cuentasc"] === false) {
            return <Redirect to={`/`} />;
        }
        let idclipro = this.props.match.params.idclipro;
        return (
            <CardSimple margen={"mt-0"}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center mt-3">
                        <div
                            className="row col-md-9 col-12 m-0  inline-headers text-lg-left"
                            style={{ padding: "15px" }}
                        >
                            <ToolbarReporteCuentas
                                es_cliente={this.state.es_cliente}
                                valorEsetado={{ value: 1, label: "Todos" }}
                                valorMoneda={{ value: "GTQ", label: "GTQ" }}
                                cuentasporCOP={this.props.cuentasporCOP}
                                isClearableCliPro={true}
                                vistaOrdenes={true}
                                defaultclipro={idclipro}
                                asyncValue={this.props.asyncValue}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 row m-0 p-0 d-flex justify-content-md-center justify-content-center mt-3">
                        <div className="col-md-5 col-12 row d-flex justify-content-center align-items-center">
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL PENDIENTE</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <h5 className="t-primary">
                                    <RenderMoneda
                                        monto={totalizado.total}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-5 col-12 row d-flex justify-content-center align-items-center">
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL VIGENTE</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <h5 className="t-verde">
                                    <RenderMoneda
                                        monto={totalizado.total_vigente}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL POR VENCER</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <h5 className="t-mostaza">
                                    <RenderMoneda
                                        monto={totalizado.total_porvencer}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <h6 className="t-musgo">TOTAL VENCIDOS</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <h5 className="t-corinto">
                                    <RenderMoneda
                                        monto={totalizado.total_vencido}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Grid
                            hover
                            striped
                            data={{
                                count: 1,
                                results: deudasCoP.results[0]
                                    ? deudasCoP.results[0].saldo_vigencia
                                          .ordenes
                                    : [],
                            }}
                            loading={loader}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                width="15%"
                                dataSort
                                isKey
                                dataFormat={standardActions({
                                    verpro: `${
                                        this.props.es_cliente
                                            ? "ordenesventa"
                                            : "ordencompra"
                                    }`,
                                })}
                            >
                                ACCIONES
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estado"
                                dataAlign="center"
                                width="15%"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <div className="d-flex justify-content-center">
                                        {cell == 2 && (
                                            <span className="t-verde bold">
                                                Vigente
                                            </span>
                                        )}
                                        {cell == 3 && (
                                            <span className="t-mostaza bold">
                                                Por vencer
                                            </span>
                                        )}
                                        {cell == 4 && (
                                            <span className="t-red bold">
                                                Vencido
                                            </span>
                                        )}
                                    </div>
                                )}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="no_orden"
                                dataSort
                                dataAlign="center"
                            >
                                {this.state.es_cliente ? "DESPACHO" : "ORDEN"}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                dataAlign="center"
                            >
                                FECHA DE VENCIMINETO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                dataAlign="right"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <RenderMoneda
                                        monto={Math.abs(cell)}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                )}
                            >
                                TOTAL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pendiente"
                                dataAlign="right"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <RenderMoneda
                                        monto={cell}
                                        simbolo={getsimbolo(
                                            variables_busqueda.moneda
                                        )}
                                    />
                                )}
                            >
                                PENDIENTE
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
