import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/clientes/clientes';
import ClientesGrid from './clientesGrid';

const mstp = state => {
    return {
        ...state.clientes,
        permisos: state.login.me.permisos,
    }
};

const mdtp = {
    ...actions,
};

export default connect(mstp,mdtp)(ClientesGrid);