import React, { Component } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../../Utils/Grid/StandardActions';
import ToolbarSimple from '../../../../Utils/Toolbar/ToolbarSimple';



//Extras
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
import { getsimbolo } from '../../../../../../utility/constants';
import Swal from 'sweetalert2';

import Tiempo from '../../../../../../../assets/img/icons/Tiempo.png';
import Check from '../../../../../../../assets/img/icons/Check.png';

import Modal from 'react-responsive-modal';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class RechazadosGrid extends Component {
    state = {

    }
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
        this.props.handleData(true)

    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, page } = this.props;
        return (
            <Grid hover
                page={page}
                striped data={data}
                loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    dataFormat={
                        standardActions({  eliminarModal: this.props.abrirModalEliminar,verpro:"ordencompra" })
                    }
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_orden"
                    dataSort
                    tdStyle={cellTachado}
                >
                    NO. ORDEN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="proveedor"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (cell ? cell.nombre : '--')
                    }
                >
                    PROVEEDOR
                </TableHeaderColumn>
{/*                 <TableHeaderColumn
                    dataField="nombre_empresa"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (cell ? cell : '--')
                    }
                >
                    Empresa
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="monto"
                    headerAlign="center"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderMoneda
                                monto={row.monto ? cell : 0}
                                simbolo={getsimbolo(row.moneda)}
                            />
                        )
                    }
                >
                    MONTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="pago_completo"
                    dataSort
                    tdStyle={cellTachado}
                    dataAlign="center"
                    dataFormat = {
                        (cell, row) => {
                            if(cell){
                                return (<img src={Check} width="20"/>)//<span className="t-verde font-weight-bold">Pagada</span>)
                            }else{
                                return(<img src={Tiempo} width="20"/>)//<span className="t-corinto font-weight-bold">Pendiente</span>)
                            }
                        }

                    }
                >
                    PAGADO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="entregado"
                    dataAlign="center"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            if(cell){
                                return (<img src={Check} width="20"/>)
                            }else{
                                return(<img src={Tiempo} width="20"/>)
                            }
                        }

                    }
                >
                    ENTREGADO
                </TableHeaderColumn>
        </Grid>
        );
    }
}
