import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _, { inRange, constant } from "lodash";
import moment from "moment";

let url = "reportes";
let form = "";
let dirGrid = "/";

export const constants = {
    LOADER: "LOADER_ROTACION_INVENTARIO_HB",
    LOADER_GRAFICA: "LOADER_GRAFICA_ROTACION_INVENTARIO_HB",
    DATA_GRAFICA: "DATA_GRAFICA_ROTACION_INVENTARIO_HB",
    DATA: "DATA_ROTACION_INVENTARIO_HB",
    ITEM: "ITEM_ROTACION_INVENTARIO_HB",
    PAGE: `PAGE_ROTACION_INVENTARIO_HB`,
    ORDERING: `ORDERING_ROTACION_INVENTARIO_HB`,
    PRODUCTO: "PRODUCTO_ROTACION_INVENTARIO_HB",
    BODEGA: "BODEGA_ROTACION_INVENTARIO_HB",
    SET_FECHA_FINAL: "SET_FECHA_FINAL_ROTACION_INVENTARIO_HB",
    SET_FECHA_INICIAL: "SET_FECHA_INICIAL_ROTACION_INVENTARIO_HB",
    SEARCH: "SEARCH_ROTACION_INVENTARIO_HB",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setLoaderGrafica = (loaderGrafica)=>({
    type: constants.LOADER_GRAFICA,
    loaderGrafica
})

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const getReporteRotacionxMes = () => (dispatch, getStore) => {
    dispatch(setLoaderGrafica(true));
    const store = getStore().reporteRotacionInvetario;
    const params = { };
    if(store.fecha_inicial)
        params.fecha_inicial = moment(store.fecha_inicial).format("YYYY-MM-DD");
    if(store.fecha_final)
        params.fecha_final = moment(store.fecha_final).format("YYYY-MM-DD");
    if(store.producto)
        params.producto = store.producto.id
    if(store.bodega)
        params.bodega = store.bodega.id
    if (store.search)
        params.search = store.search;
    api.get(`${url}/reporteRotacionInventarioxMesesHB`, params)
        .then((response) => {
            if(store.producto)
                response[0][1] = response[0][1] + 'en ' + store.producto.nombre
            dispatch(setData(constants.DATA_GRAFICA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderGrafica(false));
        });
};


const getReporteRoptacionInventario = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().reporteRotacionInvetario;
    const params = { page };
    if(store.fecha_inicial)
        params.fecha_inicial = moment(store.fecha_inicial).format("YYYY-MM-DD");
    if(store.fecha_final)
        params.fecha_final = moment(store.fecha_final).format("YYYY-MM-DD");
    if(store.producto)
        params.producto = store.producto.id
    if(store.bodega)
        params.bodega = store.bodega.id
    if (store.search)
        params.search = store.search;
    api.get(`${url}/reporteRotacionInventarioHB`, params)
        .then((response) => {
            dispatch(setPage(page));
            dispatch(setData(constants.DATA, response));
            // let dataGrafica = [["Año","Inventario"]]
            // if(response && response.results && response.results.length){
            //     response.results.forEach((item)=>{
            //         dataGrafica.push([String(moment(item.fecha).format("DD/MM/YY")),item.cantidad_nuevo])
            //     })
            // }else {
            //     dataGrafica.push([0,0])
            // }
            // dispatch(setData(constants.DATA_GRAFICA, dataGrafica));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeBodega= (bodega) => (dispatch, getStore) => {
    let store = getStore().reporteRotacionInvetario
    dispatch(setData(constants.BODEGA, bodega));
    if(store.producto){
        dispatch(getReporteRoptacionInventario());
        dispatch(getReporteRotacionxMes());
    }
};

const changeProducto = (producto) => (dispatch, getStore) => {
    let store = getStore().reporteRotacionInvetario
    dispatch(setData(constants.PRODUCTO,producto));
    if(store.bodega){
        dispatch(getReporteRoptacionInventario());
        dispatch(getReporteRotacionxMes());
    }
};
const changeFechaInicial = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_INICIAL,fecha));
    dispatch(getReporteRoptacionInventario());
};
const changeFechaFinal = (fecha) => (dispatch) => {
    dispatch(setData(constants.SET_FECHA_FINAL,fecha));
    dispatch(getReporteRoptacionInventario());
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getReporteRoptacionInventario());
};

export const actions = {
    getReporteRoptacionInventario,
    getReporteRotacionxMes,
    changeBodega,
    changeProducto,
    changeFechaInicial,
    changeFechaFinal,
    searchChange,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.LOADER_GRAFICA]: (state, { loaderGrafica }) => {
        return {
            ...state,
            loaderGrafica,
        };
    },
    [constants.DATA_GRAFICA]: (state, { data }) => {
        return {
            ...state,
            dataGrafica: data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.BODEGA]: (state, { data }) => {
        return {
            ...state,
            bodega: data,
        };
    },
    [constants.PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            producto: data,
        };
    },
    [constants.SET_FECHA_INICIAL]: (state, { data }) => {
        return {
            ...state,
            fecha_inicial: data,
        };
    },
    [constants.SET_FECHA_FINAL]: (state, { data }) => {
        return {
            ...state,
            fecha_final: data,
        };
    },
};

export const initialState = {
    loader: false,
    loaderGrafica: false,
    data: {},
    dataGrafica: {},
    item: {},
    page: 1,
    search: "",
    ordering: "",
    bodega: null,
    producto: null,
    fecha_inicial: moment().subtract(7, "days"),
    fecha_final: moment(),
};

export default handleActions(reducers, initialState);
