import React, { Fragment, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../../../Utils/renderField";
import Select, { Creatable, Async } from "react-select";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../../Utils/renderField/renderTableField";

const format = (item) => {
    let array = []
    item.producto.presentacion.forEach((item,index) => {
        array.push({
            id: item.id,
            value: item.id,
            label: item.unidad_medida
        })
    })
    return array
}

const Item = (props) => {
    return (
        <div
            className="col-12 py-1 pt-2 px-0 row mx-0"
            style={{
                borderBottom: "1px solid #d3d3d3",
            }}
        >
            <div className="col-12 row d-flex mx-0">
                <div className="col-10 justify-content-start px-0">
                    <label className="upercase">
                        {props.producto.producto.nombre}
                    </label>
                </div>
                <div className="col-2 d-flex justify-content-end">
                    <a onClick={props.eliminarDeCarrito}>
                        <i
                            className="fa fa-times action-img iconoRedondo iconoEliminarCarrito"
                            aria-hidden="true"
                        ></i>
                    </a>
                </div>
            </div>
            <div className="col-12 row mx-0">
                <div className="col-3 px-0">
                    <RenderMoneda
                        monto={props.producto.presentacion.precio_venta}
                        className="pl-0"
                    />
                    &nbsp;x
                </div>
                <div className="col-9 row mx-0 px-0">
                    <div className="col-7 row mx-0 px-0 d-flex justify-content-center">
                        <div className="col-4 mx-0 pr-1 pl-0" align="right">
                            <button
                                className="btn-subir p-0 m-0 col-12 col-lg-8"
                                onClick={(e) => {
                                    props.quitarDeCarrito({
                                        producto: props.producto.producto,
                                        cantidad: 1,
                                        presentacion: props.producto.presentacion,
                                    });
                                }}
                            >
                                <i
                                    className="fa fa-chevron-down"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                        <div className="col-4 mx-0 px-0 input-number-sin-arrows" aling="center">
                            <input
                                type="number"
                                className="col-12 p-0 m-0 cantidadACarrito"
                                value={props.producto.cantidad}
                                readOnly
                            />
                        </div>
                        <div className="col-4 mx-0 pr-0 pl-1" align="left">
                            <button
                                className="btn-subir p-0 m-0 col-12 col-lg-8"
                                onClick={() => {
                                    props.agregarACarrito({
                                        producto: props.producto.producto,
                                        cantidad: 1,
                                        presentacion: props.producto.presentacion,
                                    });
                                }}
                            >
                                <i
                                    className="fa fa-chevron-up"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-5 mx-0 px-0 row">
                        <div className="col-12 px-0">
                            {/* {`${props.producto.producto.label_unidad_inventario}`} */}
                            <Select
                                defaultValue={props.producto.presentacion}
                                value={props.producto.presentacion}
                                name="presentaciones"
                                options={
                                    props.producto ? format(props.producto) : []
                                }
                                className="basic-multi-select"
                                classNamePrefix="Presentacion"
                                onChange={(e) => {
                                    props.changePresentacion(
                                        {
                                            id_detalle: props.producto.id,
                                            presentacion: e.value
                                        }
                                    );
                                }}
                            />
                        </div>
                        <div className="col-12 px-0" align="right">
                            <RenderMoneda
                                monto={props.producto.subtotal}
                                className="pl-0 t-primary bold"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class Carrito extends Component {
    render() {
        const { handleSubmit, carrito } = this.props;
        return (
            <div className="mt-0 mx-4 mt-5 col-12 px-0 row d-flex justify-content-center">
                <div
                    className="col-12 px-0 row px-0"
                    style={{
                        minEight: "100%",
                        eight: "100%",
                        maxEight: "100%",
                    }}
                >
                    <div className="col-12 d-flex justify-content-end">
                        {carrito && (
                            <label>
                                {`${carrito.total_productos} ${carrito.total_productos == 1 ? 'Producto' : 'Productos'}`}
                            </label>
                        )}
                    </div>
                    <div
                        className="col-12 row px-0"
                        style={{
                            border: "1px solid #d3d3d3",
                            borderRadius: "10px",
                            //maxHeight: "500px",
                            //overflow: "auto",
                        }}
                    >
                        {carrito && carrito.detalle_carrito && (
                            <div>
                                {carrito.detalle_carrito.map((item) => {
                                    return (
                                        <Item
                                            key={item.id}
                                            producto={item}
                                            agregarACarrito={
                                                this.props.agregarACarrito
                                            }
                                            quitarDeCarrito={
                                                this.props.eliminarDeCarrito
                                            }
                                            changePresentacion={
                                                this.props.changePresentacion
                                            }
                                            eliminarDeCarrito={() => {
                                                this.props.eliminarDeCarrito({
                                                    producto: item.producto,
                                                    cantidad: item.cantidad,
                                                    presentacion: item.presentacion
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="col-12 row px-0">
                        <div className="col-12 px-0">
                            <div
                                className="col-12 py-3 mx-0 row "
                                style={{
                                    backgroundColor: "var(--primaryColor)",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="col-8 px-0 d-flex flex-wrap justify-content-start">
                                    <button
                                        type="button"
                                        onClick={this.props.cancelarVenta}
                                        className="btn btn-azul-claro mr-md-1 align-self-center"
                                        style={{
                                            border: "1px solid #fff",
                                            minWidth: "100px",
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.props.FinalizarCompra(true);
                                        }}
                                        className="btn btn-blanco t-primary m-1 align-self-center"
                                    >
                                        Pagar
                                    </button>
                                    <label className="t-blanco">
                                        {this.props.mensaje &&
                                            this.props.mensaje}
                                    </label>
                                </div>
                                <div className="col-4 px-0 d-flex justify-content-end">
                                    <label className="t-blanco" align="right">
                                        Total
                                        <br />
                                        {carrito && carrito.precio_total ? (
                                            <RenderMoneda
                                                monto={carrito.precio_total}
                                            />
                                        ) : (
                                            <RenderMoneda monto={0} />
                                        )}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Carrito;
