import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from "redux-form";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _ from "lodash";
import moment from "moment";

let url = "ordenventa";
let form = "PeticionVentaForm";
let dirGrid = "/peticionesventa";

export const constants = {
    LOADER: "PV_LOADER",
    DATA: "PV_DATA",
    DATAREFUSE: `PV_DATA_DATAREFUSE`,
    ITEM: `PV_ITEM`,
    PAGE: `PV_PAGE`,
    ORDERING: `PV_ORDERING`,
    SEARCH: `PV_SEARCH`,
    SET_EMPRESAS: "EMPRESAS_PV",
    SET_CHILDS: "SET_CHILDS",
    SET_PENDIENTES: "SET_PENDIENTES",
    CLIENTE_PV: "CLIENTE_PV",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const listar = (page = 1, estate) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.state = "1";
    params.es_rechazado = false;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            dispatch({ type: constants.DATA, data: response });
            dispatch(setPage(page));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const listarRefuse = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().peticionV;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.state = "1";
    params.es_rechazado = true;
    dispatch(setLoader(true));
    api.get(`${url}`, params)
        .then((response) => {
            console.log(response);
            dispatch({ type: constants.DATAREFUSE, data: response });
            dispatch(setPage(page));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(initializeForm([]));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.state = "1";
    api.get(`${url}/${id}`, params)
        .then((response) => {
            if (response.pago_inmediato === true) {
                response.pago_inmediato = "true";
            } else {
                response.pago_inmediato = "false";
            }
            response.direccion = {
                value: response.direccion,
                label: response.direccion,
            };
            _.forEach(response.Ordennits, (x) => {
                x.propietario.label = x.propietario.Nit;
                x.propietario.value = x.propietario.id;
            });
            dispatch({ type: constants.ITEM, item: response });
            if (!!form) dispatch(initializeForm(form, response));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/createovHB`, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            dispatch(reset(form));
            dispatch(listar(1))
            dispatch(push('/ordenesventa'));
        })
        .catch((error) => {
            let mensaje = "Error en la creación de la OV";
            if (error && error.detail) {
                NotificationManager.error(error.detail, "ERROR");
            } else {
                NotificationManager.error(mensaje, "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data, otro_grid) => (dispatch) => {
    dispatch(setLoader(true));
    data.fecha = moment(new Date(data.fecha)).format("YYYY-MM-D");
    data.fecha_prox_entrega = moment(new Date(data.fecha_prox_entrega)).format(
        "YYYY-MM-D"
    );
    api.put(`${url}/${id}/updatepv`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            if (!!dirGrid) {
                if (otro_grid) {
                    dispatch(push(otro_grid));
                } else {
                    dispatch(push(dirGrid));
                }
            }
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error en la edición","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`)
        .then(() => {
            dispatch(listar());
            dispatch(listPv(""));
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error al tratar de eliminar el registro","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const searchChange2 = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(1, 1));
};

const searchChangeRefuse = (search) => (dispatch) => {
    dispatch(setSearch(search));
    console.log("Search refuse");
    dispatch(listarRefuse(1, 1));
};

const onSortChangeRefuse = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarRefuse(sort.page, 1));
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().clientes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const selectEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("empresa/selectempresas")
        .then((res) => {
            dispatch(setData(constants.SET_EMPRESAS, res));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const refusePV = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/refusepv`, id)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(push(dirGrid));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const acceptPV = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/acceptpv`, id)
        .then((res) => {
            NotificationManager.success(res.detail, "Éxito", 3000);
            dispatch(push(dirGrid));
            // dispatch(push(`/ordencompra/${res.id}`));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listChilds = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}/listchildsOC`, id)
        .then((res) => {
            dispatch(setData(constants.SET_CHILDS, res));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listPv = (val) => (dispatch) => {
    dispatch(setLoader(true));
    let params = { val };
    api.get(`${url}/listpv`, params)
        .then((res) => {
            dispatch(setData(constants.SET_PENDIENTES, res));
        })
        .catch((e) => {
            if(e && e.detail){
                NotificationManager.error(`${e.detail}`,"ERROR")
            }else{
                NotificationManager.error("Hubo un error","ERROR")
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setProveedor = (proveedor) => (dispatch) => {
    dispatch({
        type: constants.CLIENTE_PV,
        proveedor,
    });
};

const clear = () => (dispatch) => {
    dispatch({ type: constants.ITEM, item: {} });
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    searchChange2,
    onSortChange,
    selectEmpresas,
    refusePV,
    acceptPV,
    listChilds,
    listPv,
    setProveedor,
    clear,
    listarRefuse,
    searchChangeRefuse,
    onSortChangeRefuse,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.SET_EMPRESAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data.empresa,
        };
    },
    [constants.SET_CHILDS]: (state, { data }) => {
        return {
            ...state,
            hijos: data,
        };
    },
    [constants.SET_PENDIENTES]: (state, { data }) => {
        return {
            ...state,
            pendientes: data,
        };
    },
    [constants.CLIENTE_PV]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [constants.DATAREFUSE]: (state, { data }) => {
        return {
            ...state,
            datarefuse: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: "",
    search: "",
    empresas: [],
    hijos: [],
    proveedor: null,
    pendientes: [],
    datarefuse: {
        results: [],
        count: 0,
    },
};

export default handleActions(reducers, initialState);
