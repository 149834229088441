import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';



export default class tabDetalle extends Component {

    render(){
        let { item, loader} = this.props
        return(
            <Grid hover striped data={{count: 10, results: item.detalle_orden}} loading={loader} pagination={false} >
                <TableHeaderColumn
                    isKey
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell)=>(cell.nombre? cell.nombre:"----")
                    }
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell)=>(cell.codigo? cell.codigo:"----")
                    }
                >
                    CODIGO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataSort
                >
                    CANT.SOLICITADA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_actual"
                    dataSort
                >
                    CANT.POR COMPRAR
                </TableHeaderColumn>
            </Grid>

        )
    }
}
