import React, { Fragment, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { renderField, renderCurrency } from "../../../../Utils/renderField";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../../Utils/renderField/renderTableField";

class DatosClienteForm extends Component {
    render() {
        const { handleSubmit, vuelto } = this.props;
        return (
            <div className="row mt-0 mx-4 row col-12 d-flex justify-content-center">
                <form name="datosClienteForm" onSubmit={handleSubmit}>
                    <div className="row mt-3 mx-4 d-flex justify-content-center">
                        <div className="col-6 px-1">
                            <div className="d-flex justify-content-end">
                                <span className="t-texto">
                                    MONTO TOTAL:&nbsp;
                                </span>
                            </div>
                        </div>
                        <div className="col-6 px-1">
                            <div className="d-flex justify-content-start">
                                <h6 className="t-primary">
                                    <RenderMoneda
                                        monto={this.props.carrito.precio_total}
                                        className="pl-0"
                                    />
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 row">
                        <div className="form-group has-feedback col-lg-6 mx-0 pr-1 pl-0">
                            <label className="t-azul" htmlFor="nombre">
                                Nombre
                            </label>
                            <Field
                                name="cliente"
                                label="cliente"
                                component={renderField}
                                type="text"
                                className="form-control"
                                readOnly={this.props.ticket ? true : false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 mx-0 pl-1 pr-0">
                            <label className="t-azul" htmlFor="nit">
                                Nit
                            </label>
                            <Field
                                name="nit"
                                label="nit"
                                type="text"
                                component={renderField}
                                className="form-control"
                                readOnly={this.props.ticket ? true : false}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 row">
                        <div className="react-bs-table react-bs-table-bordered">
                            <div className="react-bs-container-header table-header-wrapper">
                                <table className="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th className="py-2">
                                                DESCRIPCION
                                            </th>
                                            <th className="py-2">PAGO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="py-2">
                                                Monto recibido en efectivo
                                            </td>
                                            <td className="py-2">
                                                <Field
                                                    name="monto_efectivo"
                                                    label="tipo_cambio"
                                                    prefix={"Q "}
                                                    component={renderCurrency}
                                                    parse={(cell) =>
                                                        parseFloat(cell)
                                                    }
                                                    className="form-control"
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    readOnly={
                                                        this.props.ticket
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="py-2">
                                                Monto recibido en tarjeta
                                            </td>
                                            <td className="py-2">
                                                <Field
                                                    name="monto_tarjeta"
                                                    label="tipo_cambio"
                                                    prefix={"Q "}
                                                    component={renderCurrency}
                                                    parse={(cell) =>
                                                        parseFloat(cell)
                                                    }
                                                    className="form-control"
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    readOnly={
                                                        this.props.ticket
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 row mx-0 py-1 my-3">
                        <div
                            className="col-12 row"
                            style={{
                                backgroundColor: "#828FFB",
                                borderRadius: "5px 5px 0px 0px",
                            }}
                        >
                            <div className="col-12 row">
                                <div className="col-8 t-blanco">
                                    VUELTO EN EFECTIVO
                                </div>
                                <div className="col-4 t-blanco">
                                    <RenderMoneda
                                        monto={vuelto}
                                        className="pl-0 t-blanco"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center ">
                                <button
                                    type="button"
                                    onClick={() => {
                                        this.props.FinalizarCompra(false);
                                    }}
                                    className="btn btn-secondary align-self-center"
                                >
                                    Regresar
                                </button>
                                {!this.props.ticket && (
                                    <button
                                        type="submit"
                                        className="btn btn-azul-claro m-1 ml-md-4 align-self-center"
                                    >
                                        Finalizar venta
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

DatosClienteForm = reduxForm({
    form: "datosClienteForm", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.monto_efectivo && !data.monto_tarjeta) {
            errors.monto_efectivo = "Campo requerido";
        }
        return errors;
    },
})(DatosClienteForm);

const selector = formValueSelector("datosClienteForm");
DatosClienteForm = connect((state) => {
    let monto_tarjeta = selector(state, "monto_tarjeta");
    let monto_efectivo = selector(state, "monto_efectivo");
    const precio_total = state.puntoVenta.carrito.precio_total;

    if (!monto_tarjeta) monto_tarjeta = 0;
    if (!monto_efectivo) monto_efectivo = 0;

    let total = 0;
    let vuelto = 0;
    total = monto_tarjeta + monto_efectivo;
    if (total > precio_total) {
        vuelto = total - precio_total;
    }
    return {
        vuelto,
    };
})(DatosClienteForm);

export default DatosClienteForm;
