import React from "react";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField, renderCurrency } from "../../../Utils/renderField";
import { Link, Redirect } from "react-router-dom";
import { renderTextArea } from "../../../Utils/renderField/renderField";

const AgregarFForm = (props) => {
    const { handleSubmit, simbolo } = props;
    return (
        <form
            name="FacturaForm"
            className="form-validate mb-lg"
            style={{ marginTop: "-50px" }}
            onSubmit={handleSubmit}
        >
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="nombre">Nombre</label>
                    <Field
                        name="nombre"
                        label="Nombre"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="nit">Nit</label>
                    <Field
                        name="nit"
                        label="Nit"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="no_factura">No. de factura</label>
                    <Field
                        name="no_factura"
                        label="No. de factura"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="monto">Monto</label>
                    <Field
                        name="monto"
                        label="Monto"
                        component={renderCurrency}
                        prefix={simbolo}
                        type="text"
                        parse={(cell) => parseFloat(cell)}
                        className="form-control"
                    />
                </div>
            </div>
            <br />
            <div className="row  justify-content-sm-center justify-content-center">
                <button
                    onClick={() => {
                        props.handleModal(false);
                    }}
                    type="button"
                    className="btn btn-secondary m-1 align-self-center"
                >
                    Cancelar
                </button>
                <button
                    type="submit"
                    className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                >
                    Agregar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "FacturaForm", // a unique identifier for this form
    validate: (data) => {
        const errors={}
        if(!data.nombre){
            errors.nombre = "Este campo es requerido"
        }
        if(!data.nit){
            errors.nit = "Este campo es requerido"
        }
        if(!data.no_factura){
            errors.no_factura = "Este campo es requerido"
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido"
        }else if(data.monto <= 0){
            errors.monto = "El monto debe ser mayor a 0"
        }
        return errors;
    },
})(AgregarFForm);
