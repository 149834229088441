import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {RenderMoneda} from "Utils/renderField/renderTableField";
import { renderField, renderFieldRadio, renderSelectField, renderCurrency, renderTextArea, renderSelecttree} from '../../../Utils/renderField';
import { TipoTransaccion, getsimbolo } from '../../../../../utility/constants';
import  { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import { getSimbolo } from '../../../Utils/renderField/renderTableField';

// eslint-disable-next-line import/no-mutable-exports
let PagoCreditoForm = (props) => {
    const {
        handleSubmit,
        cuentas,
        texto_boton_movimiento,
        simbolo,
        tipo_documento,
        cerrarModal,
        es_gasto,
        tipo_cuenta,
        tipo_gastos,
        esCobro,
        esCheque,
        simbolo_orden,
        simbolo_de_cuenta,
        ver_tipo_cambio,
        monto_conversion,
        moneda_conversion
    } = props;

    return (
        <form name="PagoCreditoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                <div className="d-flex justify-content-start">
                    <h5 className="t-primary align-self-start">{props.titulo_modal? props.titulo_modal:""}</h5>
                </div>
                <div className="row col-md-12 m-0 p-0 d-flex justify-content-start">
                    <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-start`}>
                        <Field
                            name="tipo_cuenta"
                            label="Cuenta"
                            component={renderFieldRadio}
                            type="radio"
                            value={"true"}
                            className="form-control" />
                    </div>
                    <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-start`}>
                        <Field
                            value={"false"}
                            name="tipo_cuenta"
                            label="Mi Caja Chica"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div className={`form-group has-feedback  col-md-6  col-12 `}>

                </div>
            </div>
            <div className="row col-12 m-0 p-0">
                {
                    (tipo_cuenta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="numero_cuenta">No. de Cuenta</label>
                            <Field
                                name="numero_cuenta"
                                label="numero_cuenta"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                className="form-control"
                            />
                        </div>
                    )
                }
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="monto">Monto</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo_de_cuenta}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                    />
                </div>
            </div>
            {
                (ver_tipo_cambio) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback col-md-6 col-12">
                            <label className="t-azul"  htmlFor="monto">Tipo de cambio</label>
                            <Field
                                name="tipo_cambio"
                                label="tipo_cambio"
                                prefix={simbolo}
                                component={renderCurrency}
                                parse={cell => parseFloat(cell)}
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <span className="t-primary h5 mr-3"  htmlFor="descripcion">Total conversión </span>
                            <h2 className="t-mostaza" htmlFor="descripcion">
                                <RenderMoneda
                                    // eslint-disable-next-line camelcase
                                    monto={monto_conversion ? monto_conversion : 0}
                                    // eslint-disable-next-line camelcase
                                    simbolo={moneda_conversion}/>
                            </h2>
                        </div>
                    </div>
                )
            }


            {
                (tipo_cuenta) && (
                    <div className="row col-12 m-0 p-0">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                            <Field
                                name="tipo_documento"
                                label="tipo_documento"
                                labelKey="label" valueKey="value"
                                component={renderSelectField}
                                options={TipoTransaccion}
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="no_documento">{tipo_documento}</label>
                            <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }
            {
                (tipo_gastos) && (
                    <div className="row col-12 m-0 p-0">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                            <Field
                                name="tipo_gasto"
                                label="nombre"
                                component={renderSelecttree}
                                options={tipo_gastos}
                                type="text"
                                valueKey="value"
                                labelKey="label"
                                className="form-control" />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                        </div>
                    </div>
                )
            }
            {
                (esCheque) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Fecha de Cobro</label>
                            <Field
                                name="fecha_cobro"
                                selectedDate={new Date()}
                                maxDate="2030-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Banco de referencia</label>
                            <Field
                                name="banco_referencia"
                                className="form-control"
                                component={renderField}
                            />
                        </div>
                    </div>
                )
            }
            {
                (es_gasto) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                label="descripcion"
                                component={renderTextArea}
                                type="text"
                                className="form-control"
                                top={{top:"5px", position:"inherit"}}
                                />
                        </div>
                    </div>
                )
            }

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center " >
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary  align-self-center">Cancelar</button>
                        <button type="submit" className="btn btn-azul-claro ml-md-4 m-1 align-self-center">
                            {
                                (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("Agregar pago")
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};


PagoCreditoForm =  reduxForm({
    form: 'PagoCreditoForm', // a unique identifier for this form
    initialValues: {
        tipo_cuenta: 'true',
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data) => {
        const errors = {}
        if(!data.fecha){
            errors.fecha = "Este campo es requerido"
        }
        if(!data.numero_cuenta){
            errors.numero_cuenta = "Este campo es requerido"
        }
        if(!data.tipo_documento){
            errors.tipo_documento = "Este campo es requerido"
        }
        if(!data.no_documento){
            errors.no_documento = "Este campo es requerido"
        }
        if(!data.tipo_cambio){
            errors.tipo_cambio = 'Este campo es requerido'
        }
        if(!data.descripcion){
            errors.descripcion = 'Este campo es requerido'
        }
        if(!data.monto){
            errors.monto = "Este campo es requerido"
        }else if(data.monto <=0 ){
            errors.monto = "El monto debe ser mayor a 0"
        }
        return errors;
    },
})(PagoCreditoForm);

const selector = formValueSelector('PagoCreditoForm');
PagoCreditoForm = connect((state, props) => {
    const tipo_movimiento = selector(state, 'tipo_documento');
    const tipo_cuenta_form = selector(state, 'tipo_cuenta');
    const monto_form = selector(state, 'monto');
    const select_cuenta = selector(state, 'numero_cuenta');
    const tipo_cambio = selector(state, 'tipo_cambio');


    const simbolo_orden = props.simbolo_orden;

    let simbolo_de_cuenta = 'Q. ';
    let moneda_conversion = 'Q. ';
    let monto_conversion = 0;

    let ver_tipo_cambio = false;

    const arr_cuentas = props.cuentas;

    if(select_cuenta){
        let _seleccionado = arr_cuentas.find(x => x.id == select_cuenta);
        if(_seleccionado.moneda != 'GTQ') {
            simbolo_de_cuenta = getSimbolo(_seleccionado.moneda);
        }
    }

    if (simbolo_orden.trim() != 'Q.') {
        // Si la orden de compra es de una OC de diferente moneda de Q
        // debe de pedir el tipo de cambio.
        ver_tipo_cambio = true;
        if (simbolo_de_cuenta.trim() != 'Q.') {
            ver_tipo_cambio = true;
            moneda_conversion = 'Q.';
            monto_conversion = monto_form * tipo_cambio;
        }else {
            monto_conversion = monto_form / tipo_cambio;
            moneda_conversion = simbolo_orden;
        }

    } else {
        if(simbolo_de_cuenta.trim() != 'Q.') {
            ver_tipo_cambio = true;
            monto_conversion = monto_form * tipo_cambio;
        } else {
            monto_conversion = monto_form / tipo_cambio;
        }
    }


    let tipo_cuenta = true;
    let esCheque = false;
    if(tipo_cuenta_form == "false") {
        tipo_cuenta = false;
    }
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento) {
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
        if (seleccionado.value == 2){esCheque = true}
    }
    return{
        tipo_documento,
        tipo_cuenta,
        esCheque,
        simbolo_de_cuenta,
        ver_tipo_cambio,
        monto_conversion,
        moneda_conversion
    };


})(PagoCreditoForm);


export default PagoCreditoForm;
