import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import PropTypes from "prop-types";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link, Redirect } from "react-router-dom";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";

export default class RolesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            page,
            permisos,
            staff,
        } = this.props;
        if (staff === false) {
            if (permisos["roles"] === false) {
                if (!!permisos[`ver_roles`] === false) {
                    return <Redirect to={`/`} />;
                }
            }
        }
        return (
            <div
                className="Container shadow bg-white mt-4"
                style={{ borderRadius: "10px" }}
            >
                <br />
                <div className="row justify-content-end">
                    <div className="col-12">
                        <ToolbarSimple
                            textBoton="Agregar"
                            ruta="/role/nuevo"
                            buscar={this.props.searchChange}
                            buscador={this.props.search}
                            placeholder={"Buscar por: Nombre, Descripción"}
                            permisos={staff ? staff : permisos.roles}
                        />
                        {/* <Link className="btn btn-primary" to="/role/nuevo">AGREGAR</Link> */}
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-4 ">
                            <div className="p-0 px-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                >
                                    {(staff || permisos.roles) && (
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataSort
                                            width="20%"
                                            dataFormat={standardActions({
                                                editar: "role",
                                                eliminar,
                                            })}
                                        >
                                            ACCIONES
                                        </TableHeaderColumn>
                                    )}
                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                        width="35%"
                                    >
                                        ROL
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="descripcion"
                                        dataSort
                                    >
                                        DESCRIPCION
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
