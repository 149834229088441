import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import OrdenCompraForm from './VentaInternaForm';

class VentaInternaCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'NUEVA VENTA INTERNA',
    }
    componentDidMount(){
        this.props.getEmpresas();
        if (this.props.match.params.id){
            let id = this.props.match.params.id
            this.setState({editar: true, titulo:'EDITAR VENTA INTERNA'})
            this.props.leer(id);
        }

    }
    handleSubmit = (values) =>{
        //id de la cuenta a la que pertenece la nueva transaccion
        let id = this.props.match.params.id;
        values.tipo_orden = 4;
        const { crear, editar} = this.props
        let valores = _.cloneDeep(values);
        let productos = [];
        valores.detalle_orden.forEach(x => {
            productos.push(x.producto.id);
        });
        valores.producto_seleccionado = productos;
        this.state.editar? editar(id, valores): crear(valores);
    }

    render() {
        const {
            loader,
            empresas_origen,
            empresas_destino,
            setEmpresaOrigen,
            borrarOptionsSelect
        } = this.props;
        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light>
                    <OrdenCompraForm
                            onSubmit={this.handleSubmit}
                            initialValues={ {'fecha':new Date()}}
                            empresas={empresas_origen}
                            empresas_destino={empresas_destino}
                            onChangeOrigen={setEmpresaOrigen}
                            resetOpcionesSelect={borrarOptionsSelect}
                        />
                </LoadMask>
            </CardFormulario>

        );
    }
}

export default VentaInternaCrear;
