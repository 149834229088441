import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { NotificationManager, __esModule } from "react-notifications";
import _, { inRange, constant } from "lodash";
import moment from "moment";

let url = "reportes";
let form = "";
let dirGrid = "/";

export const constants = {
    LOADER: "LOADER_EXISTENCIAS_BODEGA_HB",
    DATA: "DATA_EXISTENCIAS_BODEGA_HB",
    ITEM: "ITEM_EXISTENCIAS_BODEGA_HB",
    PAGE: `PAGE_EXISTENCIAS_BODEGA_HB`,
    SET_ALERTADO: 'SET_ALERTADO_EXISTENCIAS_BODEGAS_HB',
    ORDERING: `ORDERING_EXISTENCIAS_BODEGA_HB`,
    BODEGAS_EXISTENCIA: "BODEGAS_EXISTENCIAS_BODEGA_HB",
    BODEGAS_SELECCIONADAS_EXISTENCIA: "BODEGAS_SELECCIONADAS_EXISTENCIA",
    PRODUCTOS_EXISTENCIA: "PRODUCTOS_EXISTENCIAS_BODEGA_HB",
    PRODUCTOS_SELECCIONADOS_EXISTENCIA: "PRODUCTOS_SELECCIONADOS_EXISTENCIA",
    SEARCH: "SEARCH_EXISTENCIAS_BODEGA_HB",
};

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});

const getExistenciasBodega = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore().reporteExistenciasBodega;
    const params = { page };
    if (store.bodegas_seleccionadas) {
        let lista_bodegas = [];
        for (let i = 0; i < store.bodegas_seleccionadas.length; i++) {
            lista_bodegas.push(store.bodegas_seleccionadas[i].value);
        }
        params.bodegas = lista_bodegas;
    }
    if (store.productos_seleccionados) {
        let lista_productos = [];
        for (let i = 0; i < store.productos_seleccionados.length; i++) {
            lista_productos.push(store.productos_seleccionados[i].value);
        }
        params.productos = lista_productos;
    }
    if (store.search) {
        params.search = store.search;
    }
    if (store.alertado && store.alertado.value == true){
        params.alerta = store.alertado.value
    }
    api.get(`${url}/reporteExistenciasBodegaHB`, params)
        .then((response) => {
            dispatch(setPage(page));
            dispatch(setData(constants.DATA, response));
        })
        .catch((e) => {
            console.log(e);
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los datos",
                    "ERROR"
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getProductos = (search = "") => (dispatch) => {
    let params = { search };
    api.get(`productos`, params)
        .then((response) => {
            dispatch(setData(constants.PRODUCTOS_EXISTENCIA, response));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los productos",
                    "ERROR"
                );
            }
        });
};

const getBodegas = (search = "") => (dispatch) => {
    api.get(`bodega`)
        .then((response) => {
            dispatch(setData(constants.BODEGAS_EXISTENCIA, response));
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error(
                    "Hubo un error al obtener los productos",
                    "ERROR"
                );
            }
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { id };
    api.get(`${url}/getDetalleVentaReporte`, params)
        .then((response) => {
            dispatch({ type: constants.ITEM, item: response });
        })
        .catch((e) => {
            if (e && e.detail) {
                NotificationManager.error(`${e.detail}`, "ERROR");
            } else {
                NotificationManager.error("Hubo un error", "ERROR");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeBodegas = (bodega) => (dispatch) => {
    dispatch({
        type: constants.BODEGAS_SELECCIONADAS_EXISTENCIA,
        bodega,
    });
    dispatch(getExistenciasBodega());
};

const changeProductos = (producto) => (dispatch) => {
    dispatch({
        type: constants.PRODUCTOS_SELECCIONADOS_EXISTENCIA,
        producto,
    });
    dispatch(getExistenciasBodega());
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(getExistenciasBodega());
};

const changeTipo = (value) => (dispatch) => {
    dispatch(setData(constants.SET_ALERTADO, value))
    dispatch(getExistenciasBodega())
}

export const actions = {
    getExistenciasBodega,
    changeBodegas,
    changeProductos,
    changeTipo,
    getProductos,
    searchChange,
    getBodegas,
    leer,
};

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SET_ALERTADO]: (state, { data }) => {
        return {
            ...state,
            alertado: data,
        }
    },
    [constants.BODEGAS_EXISTENCIA]: (state, { data }) => {
        return {
            ...state,
            bodegas: data,
        };
    },
    [constants.PRODUCTOS_EXISTENCIA]: (state, { data }) => {
        return {
            ...state,
            productos: data,
        };
    },
    [constants.BODEGAS_SELECCIONADAS_EXISTENCIA]: (state, { bodega }) => {
        return {
            ...state,
            bodegas_seleccionadas: bodega,
        };
    },
    [constants.PRODUCTOS_SELECCIONADOS_EXISTENCIA]: (state, { producto }) => {
        return {
            ...state,
            productos_seleccionados: producto,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    item: {},
    page: 1,
    search: "",
    ordering: "",
    bodegas: null,
    productos: null,
    alertado: false,
    bodegas_seleccionadas: undefined,
    productos_seleccionados: undefined,
};

export default handleActions(reducers, initialState);
