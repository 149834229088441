import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {
    Login,
    Profile,
    Registro,
    ResetPassword,
    VerifyPassword
} from './common/components/LoginRegister';
import Home from './common/components/app/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';



import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Listar as CuentasGrid,
    Nuevo as CuentasCrear,
} from '../js/common/components/app/Cuentas';
import {
    Listar as UsuarioGrid,
    Editar as UsuarioEditar
} from '../js/common/components/app/Users';

import {
    Listar as RolesGrid,
    Nuevo as RolesCrear
} from '../js/common/components/app/roles';

import {
    Listar as TransaccionesGrid,
    Nuevo as TransaccionCrear,
    AsignarFlotante
} from '../js/common/components/app/Transacciones';

import{
    ListarTipoProducto,
    TipoProductoCrear
} from './common/components/app/TipoProductos';

import {
    ProductoGrid,
    ProductoCrear
} from './common/components/app/Producto';

import {
    BodegaListar,
    BodegaCrear
} from './common/components/app/Bodega';

import {
    ListarClientes,
    DetalleCliente,
    CrearCliente,
    ListarProveedores,
} from './common/components/app/Clientes';

import {
    VendedoresGrid,
    VendedorCrear
} from './common/components/app/Vendedores';

import {
    OrdenCompraGrid,
    OrdenCompraCrear,
    DetalleOrdenCompra,
    IngresoMateria
} from './common/components/app/OrdenCompra';

import {
    ListartPeticiones,
    CrearPeticiones,
    DetallePetciones,
} from './common/components/app/PeticionMateriales';

import {
    BitacoraDetalle,
    BitacoraGrid
} from './common/components/app/Bitacora';

import {
    ListarPV,
    CrearPV,
    DetallePV,
} from './common/components/app/PeticionVenta';

import {
    ListarCategorias
} from './common/components/app/TipoCategoria'

import {
    GastoCrear,
    GastoGrid,
    GastoDetalle
} from './common/components/app/Gastos';

import {
    ListarCajaChica,
    DetalleCajaChica,
} from  './common/components/app/CajaChica';

import {
    ListarOrdenesVenta,
    DetalleOrdenVenta,
    DespachoOrdenVenta,
    FacturarOrndeVenta,
} from './common/components/app/OrdenVenta'

import {
    ListarPuestos,
    NuevoPuesto
} from './common/components/app/Puestos'

/* REPORTES */
import {
    DeudaReporte,
    CuentasporCOP,
    CuentasporC,
    DeudaDetallEmpresa,
    OrdenesporC,
    OrdenesporCOP,
    ReporteVentas,
    ReporteCostoVenta,
    DetalleVentasReporte,
    ReporteExistenciasBodega,
    DetalleExistenciasBodega,
    ReporteProductividad,
    ReporteMateriaPrima,
    ReporteCostoInventario,
    ReporteLoteInventario,
    ReporteHistoricoCajas,
    DetalleHistoricoCajas,
    ReporteRotacionInventario,
} from './common/components/app/Reportes';

import {
    VentaInternaGrid,
    VentaInternaCrear,
    VentaInternaDetalle,
    VentaInternaDespacho
} from './common/components/app/VentaInterna'

import {
    Listar as ListartBonificaciones,
    NuevaBonificacion
} from './common/components/app/Bonificacion';

import {
    ListarEmpleados,
    NuevoEmpleado,
    DetalleEmpleado
} from './common/components/app/Empleados';
import {
    EstadoBodega,
    DespacharBodega,
    IngresoBodega,
    DetalleDespachoEstado
} from './common/components/app/estado_bodega';

import {
    ListarPlanillas,
    CrearPagoPlanilla,
    EdicionBonosPlanilla,
    DetallePlanilla,
} from './common/components/app/Planilla';

import {
    ActividadCrear,
    ActividadGrid
} from './common/components/app/Actividades';

import {
    PuntoVentaDetalle,
    PuntoVentaEditar,
    PuntoVentaListar
} from './common/components/app/PuntoVenta';

import {
    Colaproduccion,
    listaOrdenesProduccion,
    NuevaProducion,
    ReportarProduccion,
    CambioReceta,
    DetalleOP
} from './common/components/app/Produccion';

import {
    AsignacionesGrid,
    AsignacionesCrear
} from './common/components/app/AsignacionActividades';

import {
    SucursalesListar,
    SucursalesEditar,
} from './common/components/app/Sucursales';

import {
    NotificacionesListar
} from './common/components/app/Notificaciones'

import {
    DevolucionCrear,
    DevolucionGrid,
    DevolucionDetalle
} from './common/components/app/Devolucion';

import { VehiculosGrid, VehiculosCrear } from './common/components/app/VehiculosMaquinaria';

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />


                {/* #recuperacion de password */}
                <Route exact path="/ResetPassword" component={ResetPassword} />
                <Route exact path="/verify/:token" component={VerifyPassword} />
                {/* home */}
                <ProtectedRoute exact path="/" component={Home} title="Inicio"/>

                {/* Usuarios */}
                <ProtectedRoute exact path="/usuarios" component={UsuarioGrid} title="Usuarios"/>
                <ProtectedRoute exact path="/usuario/nuevo" component={UsuarioEditar} title="Usuarios/Agregar usuario" />
                <ProtectedRoute exact path="/user-profile" component={Profile} title="Perfil" />
                <ProtectedRoute exact path="/usuario/:id" component={UsuarioEditar} title="Usuarios/Editar usuario"/>

                {/* Categoriga gastos */}
                <ProtectedRoute exact path="/admin_tipocategoria" component={ListarCategorias} title="Categoria de gastos"/>

                {/* gastos */}
                <ProtectedRoute exact path="/gastos" component={GastoGrid} title="Gastos"/>
                <ProtectedRoute exact path="/gasto/nuevo" component={GastoCrear} title="Gastos/Nuevo gasto"/>
                <ProtectedRoute exact path="/gasto/:id" component={GastoCrear} title="Gastos/Editar gasto"/>
                <ProtectedRoute exact path="/gasto/detalle/:id" component={GastoDetalle} title="Gastos/detalle"/>

                {/* roles */}
                <ProtectedRoute exact path="/roles" component={RolesGrid} title="Roles"/>
                <ProtectedRoute exact path="/role/nuevo" component={RolesCrear} title="Roles/Nuevo rol" />
                <ProtectedRoute exact path="/role/:id" component={RolesCrear} title="Roles/Editar rol" />

                {/* cuentas */}
                <ProtectedRoute exact path="/cuentas" component={CuentasGrid} title="Cuentas de banco" />
                <ProtectedRoute exact path="/cuenta/nueva" component={CuentasCrear} title="Cuentas/Nueva cuenta de banco."/>
                <ProtectedRoute exact path="/cuenta/:id" component={CuentasCrear} title="Cuentas/Editar cuenta de banco"/>
                <Route exact path="/transaccion/flotante" component={NotFound} title="Cuentas/Transaccion"/>
                <ProtectedRoute exact path="/transaccion/:id" component={TransaccionesGrid} title="Cuentas/Detalle"/>

                {/* caja chica */}
                <ProtectedRoute exact path="/cajas" component={ListarCajaChica} title="Cajas chicas"/>
                <ProtectedRoute exact path="/caja/:id" component={DetalleCajaChica} title="Mi caja"/>

                {/* notificaciones */}
                <ProtectedRoute exact path="/notificaciones" component={NotificacionesListar} title="Notificaciones"/>

                {/* Clientes y Proveedores */}
                <ProtectedRoute exact path="/clientes" component={ListarClientes} title="Clientes"/>
                <ProtectedRoute exact path="/clientes/nuevo" component={CrearCliente} title="Clientes/Nuevo cliente"/>
                <ProtectedRoute exact path="/clientes/:id" component={DetalleCliente} title="Clientes/Detalle cliente"/>
                <ProtectedRoute exact path="/clientes/:id/editar" component={CrearCliente} title="Clientes/Editar cliente"/>
                <ProtectedRoute exact path="/proveedores" component={ListarProveedores} title="Proveedores"/>
                <ProtectedRoute exact path="/proveedores/nuevo" component={CrearCliente} state={{ esCliente: false }} title="Proveedores/Nuevo proveedor"/>
                <ProtectedRoute exact path="/proveedores/:id" component={DetalleCliente} state={{ esCliente: false }} title="Proveedores/Detalle proveedor" />
                <ProtectedRoute exact path="/proveedores/:id/editar" component={CrearCliente} state={{ esCliente: false }} title="Proveedores/Editar proveedor" />


                {/* Sucursales */}
                <ProtectedRoute exact path="/sucursales" component={SucursalesListar} title="Sucursales" />
                <ProtectedRoute exact path="/sucursales/nuevo" component={SucursalesEditar} title="Sucursales/Crear" />
                <ProtectedRoute exact path="/sucursales/:id" component={SucursalesEditar} title="Sucursales/Editar" />

                {/* Tipo de producto */}
                <ProtectedRoute exact path="/tipoproductos" component={ListarTipoProducto} title="Tipo de productos" />
                <ProtectedRoute exact path="/tipoproducto/nuevo" component={TipoProductoCrear} title="Tipo de productos/Nuevo"/>
                <ProtectedRoute exact path="/tipoproducto/:id" component={TipoProductoCrear} title="Tipo de productos/Editar"/>

                {/* Producto */}
                <ProtectedRoute exact path="/productos" component={ProductoGrid} title="Productos"/>
                <ProtectedRoute exact path="/producto/nuevo" component={ProductoCrear} title="Productos/Nuevo producto"/>
                <ProtectedRoute exact path="/producto/:id" component={ProductoCrear} title="Productos/Editar producto" />

                {/* Bodega */}
                <ProtectedRoute exact path="/bodegas" component={BodegaListar} title="Bodega administracion" />
                <ProtectedRoute exact path="/bodega/nuevo" component={BodegaCrear} title="Bodega administracion/Nueva bodega"/>
                <ProtectedRoute exact path="/bodega/:id" component={BodegaCrear} title="Bodega administracion/Editar bodega" />

                {/* Estado de bodega */}
                <ProtectedRoute exact path="/estado_bodega/:id" component={EstadoBodega} title="Bodega administracion/Detalle" />
                <ProtectedRoute exact path="/estado_bodega/crear/:id" component={DespacharBodega} title="Bodega administracion/Despacho nuevo"/>
                <ProtectedRoute exact path="/estado_bodega/:bodega/despacho/:id" component={DetalleDespachoEstado} title="Bodega administracion/Despacho" />
                <ProtectedRoute exact path="/estado_bodega/:bodega/ingreso" component={IngresoBodega} title="Bodega administracion/Ingreso" />

                {/* Vendedores */}
                <ProtectedRoute exact path="/vendedores" component={VendedoresGrid} title="Vendedores"/>
                <ProtectedRoute exact path="/vendedor/nuevo" component={VendedorCrear} title="Vendedores/Nuevo vendedor" />
                <ProtectedRoute exact path="/vendedor/:id" component={VendedorCrear} title="Vendedores/Editar vendedor" />

                {/* ordenes de compra */}
                <ProtectedRoute exact path="/ordencompras" component={OrdenCompraGrid} title="Orden de compras" />
                <ProtectedRoute exact path="/ordencompra/nuevo" component={OrdenCompraCrear} title="Orden de compras/Nueva orden"/>
                <ProtectedRoute exact path="/ordencompra/:id" component={OrdenCompraCrear} title="Orden de compras/Editar orden" />
                <ProtectedRoute exact path="/ordencompra/:id/detalle" component={DetalleOrdenCompra} title="Orden de compras/Detalle de orden" />
                <ProtectedRoute exact path="/ingreso/ordencompra/:id" component={IngresoMateria} title="Ingreso Materia Prima"/>

                {/* peticiones de material */}
                <ProtectedRoute exact path="/peticiones" component={ListartPeticiones} title="Peticion de materiales" />
                <ProtectedRoute exact path="/peticiones/nuevo" component={CrearPeticiones} title="Peticion de materiales/Nuevo"/>
                <ProtectedRoute exact path="/peticiones/:id" component={CrearPeticiones} title="Peticion de materiales/Editar"/>
                <ProtectedRoute exact path="/peticiones/:id/detalle" component={DetallePetciones} title="Peticion de materiales/Detalle"/>
                {/* peticiones de venta */}
                <ProtectedRoute exact path="/peticionesventa" component={ListarPV} title="Peticion de Venta"/>
                <ProtectedRoute exact path="/peticionesventa/:id" component={CrearPV} title="Peticion de Venta/Editar"/>
                <ProtectedRoute exact path="/peticionesventa/:id/detalle" component={DetallePV} title="Peticion de Venta/Detalle"/>
                {/* ordenes de venta */}
                <ProtectedRoute exact path="/ordenesventa" component={ListarOrdenesVenta} title="Ordenes de venta"/>
                <ProtectedRoute exact path="/ordenesventa/nuevo" component={CrearPV} title="Ordenes de venta/Nuevo" />
                <ProtectedRoute exact path="/ordenesventa/:id" component={CrearPV} title="Ordenes de venta/Editar orden"/>
                <ProtectedRoute exact path="/ordenesventa/:id/detalle" component={DetalleOrdenVenta}title="Ordenes de venta/Detalle orden"/>
                <ProtectedRoute exact path="/despacho/ordenventa/:id" component={DespachoOrdenVenta}title="Ordenes de venta/Despacho de orden"/>
                <ProtectedRoute exact path="/facturar/ordenventa/:id" component={FacturarOrndeVenta}/>

                {/* Ventas internas */}
                <ProtectedRoute exact path="/ventainterna" component={VentaInternaGrid} title="Venta interna"/>
                <ProtectedRoute exact path="/ventainterna/nuevo" component={VentaInternaCrear} title="Venta interna/Nuevo"/>
                <ProtectedRoute exact path="/ventainterna/:id/detalle" component={VentaInternaDetalle} title="Venta interna/Detalle"/>
                <ProtectedRoute exact path="/despacho/ventainterna/:id" component={VentaInternaDespacho} title="Venta interna/Desapcho"/>

                {/* PLANILLA */}
                {/* Puestos de trabajo */}
                <ProtectedRoute exact path="/puestos" component={ListarPuestos} title="Puestos de trabajo"/>
                <ProtectedRoute exact path="/puesto/nuevo" component={NuevoPuesto} title="Puestos/Nuevo puesto"/>
                <ProtectedRoute exact path="/puesto/:id" component={NuevoPuesto} title="Puestos/Editar puesto"/>
                {/* bonificaciones */}
                <ProtectedRoute exact path="/bonificaciones" component={ListartBonificaciones}title="Bonificaciones"/>
                <ProtectedRoute exact path="/bonificacion_descuento/nuevo" component={NuevaBonificacion} title="Bonificaciones/Nueva bonificacion"/>
                <ProtectedRoute exact path="/bonificacion_descuento/:id" component={NuevaBonificacion}title="Bonificaciones/Editar bonificacion"/>
                {/* Empleados */}
                <ProtectedRoute exact path="/empleados" component={ListarEmpleados} title="Empleados"/>
                <ProtectedRoute exact path="/empleados/nuevo" component={NuevoEmpleado} title="Empleados/Nuevo empleado"/>
                <ProtectedRoute exact path="/empleados/:id" component={NuevoEmpleado}title="Empleados/Editar empleado"/>
                <ProtectedRoute exact path="/empleados/:id/detalle" component={DetalleEmpleado}title="Empleados/Detalle empleado"/>
                {/* pago de planilla */}
                <ProtectedRoute exact path="/planilla" component={ListarPlanillas} title="Pago de planilla"/>
                <ProtectedRoute exact path="/planilla/EdicionBonos" component={EdicionBonosPlanilla} title="Pago de planilla/Nueva planilla/Edicion de bonos"/>
                <ProtectedRoute exact path="/planilla/nuevo" component={CrearPagoPlanilla} title="Pago de planilla/Nueva planilla"/>
                <ProtectedRoute exact path="/planilla/:id" component={CrearPagoPlanilla} title="Pago de planilla/Editar planilla"/>
                <ProtectedRoute exact path="/planilla/:id/detalle" component={DetallePlanilla} title="Pago de planilla/Detalle"/>

                {/* actividades */}
                <ProtectedRoute exact path="/actividades" component={ActividadGrid} />
                <ProtectedRoute exact path="/actividad/nuevo" component={ActividadCrear} />
                <ProtectedRoute exact path="/actividad/:id" component={ActividadCrear} />

                {/* produccion */}
                <ProtectedRoute exact path="/produccion" component={Colaproduccion} title="Producción" />
                <ProtectedRoute exact path="/produccion/:id" component={Colaproduccion} title="Producción/Editar"/>
                <ProtectedRoute exact path="/produccion/orden/:idOP" component={DetalleOP} title="Producción/orden/detalle"/>
                <ProtectedRoute exact path="/produccion/ordenes/:id" component={listaOrdenesProduccion} title="Producción/ordenes de venta" />
                <ProtectedRoute exact path="/produccion/:empresa/orden" component={NuevaProducion} title="Producción/Nueva orden de produccion" />
                <ProtectedRoute exact path="/produccion/:empresa/orden/:orden" component={NuevaProducion} />
                <ProtectedRoute exact path="/reportar_produccion/edicionreceta" component={CambioReceta} title="Producción/Reportar producción/Editar receta"/>
                <ProtectedRoute exact path="/reportar_produccion/:empresa" component={ReportarProduccion} title="Producción/Reportar producción" />

                {/* Asignacion de actividades */}
                <ProtectedRoute exact path="/asignacion_actividades" component={AsignacionesGrid} />
                <ProtectedRoute exact path="/asignacion_actividades/nuevo" component={AsignacionesCrear} />

                {/* devoluciones */}
                <ProtectedRoute exact path="/devoluciones" component={DevolucionGrid} />
                <ProtectedRoute exact path="/devolucion/nuevo" component={DevolucionCrear} />
                <ProtectedRoute exact path="/devolucion/:id" component={DevolucionDetalle} />

                {/* Vehiculos y Maquinaria */}
                <ProtectedRoute exact path="/vehiculos_maquinaria" component={VehiculosGrid} />
                <ProtectedRoute exact path="/vehiculos_maquinaria/nuevo" component={VehiculosCrear} />
                <ProtectedRoute exact path="/vehiculos_maquinaria/:id" component={VehiculosCrear} />


                {/* Punto de Venta */}
                <ProtectedRoute exact path="/punto_venta" component={PuntoVentaDetalle} title="Punto de Venta"/>
                <ProtectedRoute exact path="/punto_venta/mi_tienda" component={PuntoVentaDetalle} title="Punto de Venta"/>
                <ProtectedRoute exact path="/punto_venta/:id" component={PuntoVentaDetalle} title="Punto de Venta/Detalle de venta"/>
                {/* Manejo de reportes */}
                {/* Manejo de bitácoras */}
                <ProtectedRoute exact path="/reporte_bitacora_detalle/:id/detalle" component={BitacoraDetalle} title="Reporte/Bitacora/Detalle"/>
                <ProtectedRoute exact path="/reporte_bitacoras" component={BitacoraGrid} title="Reporte/Bitacora"/>

                {/*  reporte de Ventas  */}
                <ProtectedRoute exact path="/reporte_ventas" component={ReporteVentas} title="Reportes/Ventas"/>
                <ProtectedRoute exact path="/reporte_ventas/:id" component={DetalleVentasReporte} title="Reporte/Ventas/Detalle"/>

                {/* reporte de existencias en bodega */}
                <ProtectedRoute exact path="/reporte_existencias" component={ReporteExistenciasBodega} title="Reportes/Existencias en bodegas"/>
                <ProtectedRoute exact path="/reporte_existencias/:id" component={DetalleExistenciasBodega} title="Reporte/Existencias en bodega/detalle" />

                {/* reporte de productividad */}
                <ProtectedRoute exact path="/reporte_productividad" component={ReporteProductividad} title="Reportes/Productividad" />

                {/* reporte de Materia Prima */}
                <ProtectedRoute exact path="/reporte_materia_prima" component={ReporteMateriaPrima} title="Reportes/Materia Prima" />

                {/* Reporte de costo de productos vs ventas */}
                <ProtectedRoute exact path="/reporte_costovsventas" component={ReporteCostoVenta} title="Reportes/Costos de productos vs ventas" />

                {/* Reporte de Costos de inventario */}
                <ProtectedRoute exact path="/costos_inventario" component={ReporteCostoInventario} title="Reportes/Costos de inventario" />
                <ProtectedRoute exact path="/costos_inventario/:id" component={ReporteLoteInventario} title="Reportes/Costos de inventario/detalle lote" />

                {/* Reporte de historico de cajas */}
                <ProtectedRoute exact path="/reporte_historico_cajas" component={ReporteHistoricoCajas} title="Reportes/Historico de cajas" />
                <ProtectedRoute exact path="/reporte_historico_cajas/:id" component={DetalleHistoricoCajas} title="Reportes/Historico de cajas/detalle" />

                {/* Reporte de Rotacion de Inventario */}
                <ProtectedRoute exact path="/reporte_rotacion_inventario" component={ReporteRotacionInventario} title="Reportes/Rotacion de Inventario" />

                {/* reportes de cuentas */}
                <ProtectedRoute exact path="/reporte_deuda" component={DeudaReporte} />
                <ProtectedRoute exact path="/reporte_deuda/:id" component={DeudaDetallEmpresa} />
                <ProtectedRoute exact path="/reporte_deuda_cliente_proveedor/:id" component={DeudaDetallEmpresa} title="Reporte"/>

                <ProtectedRoute exact path="/cuentas_por_cobrar" component={CuentasporC} title="Reportes/Cuentas por cobrar"/>
                <ProtectedRoute exact path="/cuentas_por_pagar" component={CuentasporCOP} title="Reportes/Cuentas por pagar"/>
                <ProtectedRoute exact path="/ordenes_por_cobrar/:idclipro" component={OrdenesporC} title="Reportes/Cuentas por cobrar" />
                <ProtectedRoute exact path="/ordenes_por_pagar/:idclipro" component={OrdenesporCOP} title="Reportes/Cuentas por pagar" />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
