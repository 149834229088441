import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    renderPercentage,
} from "../../../Utils/renderField";
// import { TipoTransaccion } from '../../../../../utility/constants';
import { getSimbolo } from "../../../Utils/renderField/renderTableField";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import { api } from "api";

class ConvertirChequeForm extends React.Component {
    state = {
        monto: 0,
    };

    render() {
        const {
            handleSubmit,
            cuentas,
            texto_boton_movimiento,
            simbolo,
            tipo_documento,
            cerrarModal,
            es_gasto,
            tipo_cuenta,
            tipo_gastos,
            esCobro,
            esCheque,
            es_tarjeta,
            cambioDescuento,
        } = this.props;
        return (
            <form
                name="ConvertirChequeForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="row col-12 m-0 p-0">
                    <div className="form-group has-feedback col-md-6 col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Fecha de cobro
                        </label>
                        <Field
                            name="fecha"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="nombre">
                            Banco donde se cobro
                        </label>
                        <Field
                            name="banco_referencia"
                            label="banco_referencia"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                            <button
                                type="button"
                                onClick={cerrarModal}
                                className="btn btn-secondary  align-self-center"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

ConvertirChequeForm = reduxForm({
    form: "ConvertirChequeForm", // a unique identifier for this form
    initialValues: {
        fecha: new Date(),
    },
    validate: (data) => {
        const errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.banco) {
            errors.banco = "Campo requerido";
        }
        return errors;
    },
})(ConvertirChequeForm);

const selector = formValueSelector("ConvertirChequeForm");
ConvertirChequeForm = connect((state) => {
    return {};
})(ConvertirChequeForm);

export default ConvertirChequeForm;
