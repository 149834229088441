import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/cuentaBancos/cuentas';
import Cuentas from './cuentas';

const mstp = state => {
    return {...state.cuentas}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Cuentas)
