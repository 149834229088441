import React, { Component, Fragment } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from '../../Crear/PagoCreditoForm';


import { TipoTransaccion } from '../../../../../../utility/constants';
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";

import Swal from 'sweetalert2';
import moment from 'moment';


import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';

import { getSimbolo } from '../../../../Utils/renderField/renderTableField';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}


export default class GastosGrid extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        anular_transaccion: true
    }

    componentWillMount(){
        const { moneda, item } = this.props;
        this.props.selectCuentas(null, moneda)

    }

    abrirModalEliminar = (id, row) => {
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModalGasto(true)
    }


    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, orden.orden.id);
            }
        });
    }


    botonRealizarMovimiento = (id, row) => {
        if(!row.estado){
            return(<div></div>)
        }
        if(!row.flotante){
            return(<div></div>)
        }
        return (<img className="action-img " title="adicional" src={require("../../../../../../../assets/img/icons/marcar_bono.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            anular_transaccion: false,
                            open_modal: true
                        })
                        this.props.setOpenModal(true)
                     }}
                      alt="Marcar pagado" />)
    }


    cerrarModal = () => {
        this.props.setOpenModal(false)
    }


    renderModalAnulacion = () => {
        const { open_modal_gasto } = this.props;
        return (
            <Modal open={open_modal_gasto} showCloseIcon={ false } onClose={(e) => {
                this.props.setOpenModalGasto(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">Justificación de anulación</h2>
                </div>
                    {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalGasto}
                            />
                        )
                    }

            </Modal>
        )
    }


    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values,this.props.id_cuenta);
            }
        });
    }


    renderModalFlotantePagado = () => {
        let transaccion = null
        if(this.state.transaccion_sel){
            transaccion = {
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Realizar pago </span>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModal}
                    />
            </Fragment>
        );
    }


    botonModal = (id, row) => {
        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row
                        })
                        this.props.setOpenModalGasto(true);
                     }}
                      alt="Ver eliminado" />)
    }


    renderModalAbonarPago = () => {
        const {  open_modal, cuentas, tipo_gastos,   simbolo, orden} = this.props;
        let simbolo_orden = getSimbolo(orden.orden.moneda);
        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Agregar gasto </span>
                </div>
                <PagoCreditoForm
                    cerrarModal={this.cerrarModal}
                    cuentas={cuentas}
                    simbolo={simbolo}
                    simbolo_orden={simbolo_orden}
                    tipo_gastos={tipo_gastos}
                    texto_boton_movimiento={"Agregar gasto"}
                    titulo_modal="Pagar gasto desde"
                    es_gasto={true}
                    onSubmit={(e) => {
                        this.props.abonarGastoOPago(orden.orden.id, false);
                        this.cerrarModal();
                    }} />
            </Fragment>
        )
    }


    render() {
        const {  open_modal, cuentas, cuenta,   simbolo, orden} = this.props;
        let data = {
            count: 1,
            results: cuenta.gastos
        }
        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <Modal open={open_modal} showCloseIcon={ false } onClose={(e) => {
                    this.props.setOpenModal(false)
                }} center>
                   {
                       (this.state.anular_transaccion) ? (
                            this.renderModalAbonarPago()
                       ):(
                            this.renderModalFlotantePagado()
                       )
                   }
                </Modal>
                <Grid hover striped data={data}  pagination={false} >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({eliminarModal: this.abrirModalEliminar, adicional: this.botonModal, adicional2: this.botonRealizarMovimiento})}
                    >
                    </TableHeaderColumn>
                        <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat = {
                            (cell,row) => {
                                return(
                                    <div>
                                        <span className="font-weight-bold">Fecha </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                        {
                                            (row.fecha_emision) && (
                                                <Fragment>
                                                    <span className="font-weight-bold">Fecha emisióń </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                                </Fragment>
                                            )
                                        }
                                        <span className="font-weight-bold">Tipo de pago</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                    </div>
                                )
                            }
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_cuenta"
                        dataSort
                        tdStyle={cellTachado}

                    >
                        ENTRO A
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero_cuenta"
                        dataSort
                        tdStyle={cellTachado}

                    >
                        CUENTA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="transaccion"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat = {
                            (cell, row) => {
                                let id = Number(cell.tipo_documento);
                                let cuenta = _.find(TipoTransaccion, x => x.value == id);
                                if(cuenta){
                                    return(cuenta.label);
                                }
                            }
                        }
                    >
                        TIPO DOCUMENTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="transaccion"
                        dataSort
                        tdStyle={cellTachado}
                        dataFormat = {
                            (cell, row) => {
                                return <span>{cell.no_documento}</span>
                            }
                        }
                    >
                        NO. TRANSACCION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="monto"
                        dataSort
                        headerAlign="center"
                        dataAlign="right"
                        tdStyle={cellTachado}
                        dataFormat ={
                            (cell, row)=>{
                                if(cell){
                                    return <RenderMoneda monto={cell} simbolo={getSimbolo(row.moneda)} />
                                }
                            }
                        }
                    >
                        MONTO
                    </TableHeaderColumn>
                </Grid>
            {
                (orden.estado) && (
                    <button type="button" onClick={(e) => {
                        e.preventDefault();
                        this.props.setOpenModal(true)
                        this.setState({open_modal: true, es_pago: true, es_gasto: false, anular_transaccion: true})
                    }} className="btn btn-mostaza m-1 align-self-center mt-2">Agregar gasto</button>
                )
            }



        </Fragment>
        )
    }
}
