import React, { Component, Fragment } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../Utils/Cards/cardSimple";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";

function cellTachado(cell, row) {
    if (!row.estado) {
        return {
            textDecoration: "line-through",
            whiteSpace: "normal",
            padding: 5,
        };
    }
    return { whiteSpace: "normal", padding: 5 };
}

const ListadoT = (props) => {
    const {
        onPageChange,
        data,
        loader,
        open_modal,
        permisos,
        page,
        nombre_empresa,
        moneda,
    } = props;
    return (
        <Fragment>
            <Grid
                hover
                striped
                data={data}
                loading={loader}
                page={page}
                onPageChange={props.onChangePage}
            >
                {/* <TableHeaderColumn
                                dataSort
                                // width='125'
                                dataField="tipo"
                                dataFormat={(cell)=><span>{props.getMovimiento(cell)}</span>}
                            >
                                MOVIMIENTO
                            </TableHeaderColumn> */}
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    tdStyle={{ whiteSpace: "normal" }}
                    dataFormat={standardActions({
                        eliminarModal: props.abrirModalEliminar,
                        adicional: props.botonModal,
                        // adicional2: props.botonChequeEfectivo,
                    })}
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    width="300"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell, row) =>
                        cell ? (
                            <div>
                                {row.tipo && (
                                    <div>
                                        <span>
                                            {() =>
                                                props.getMovimiento(row.tipo)
                                            }
                                        </span>
                                    </div>
                                )}
                                {row.cliente && (
                                    <Fragment>
                                        <span className="t-primary font-weight-bold">
                                            Cliente:{" "}
                                        </span>
                                        <span className="t-musgo">
                                            {row.cliente}
                                        </span>
                                        <br />
                                    </Fragment>
                                )}
                                <span className="t-primary font-weight-bold">
                                    Transaccion:{" "}
                                </span>
                                <span className="t-musgo ">
                                    {cell.label_tipo_transaccion}
                                </span>
                                <br />
                                <span className="t-primary font-weight-bold">
                                    No. documento:{" "}
                                </span>
                                <span className="t-musgo ">
                                    {cell.no_documento}
                                </span>
                                <br />
                                <span className="t-primary font-weight-bold">
                                    Descripcion:{" "}
                                </span>
                                <span className="t-musgo ">
                                    {cell.descripcion}
                                </span>
                                <br />
                                {cell.empresa_nombre && (
                                    <div>
                                        <span className="t-primary font-weight-bold">
                                            Empresa:{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            {cell.empresa_nombre}
                                        </span>
                                    </div>
                                )}
                                {cell.orden && (
                                    <div>
                                        <span className="t-primary font-weight-bold">
                                            orden:{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            {cell.orden}
                                        </span>
                                    </div>
                                )}
                                {cell.moneda != Monedas[1].value ? (
                                    <div>
                                        <span className="t-primary font-weight-bold">
                                            moneda:{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            {cell.moneda}
                                        </span>
                                        <span className="t-primary font-weight-bold">
                                            {" "}
                                            con tipo de cambio{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            <RenderMoneda
                                                monto={cell.tipo_cambio}
                                                simbolo={getsimbolo(
                                                    cell.moneda
                                                )}
                                                decimalScale={4}
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        <span className="t-primary font-weight-bold">
                                            moneda:{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            {cell.moneda}
                                        </span>
                                    </div>
                                )}
                                {row.transaccion.fecha_cobro && (
                                    <Fragment>
                                        <span className="t-primary font-weight-bold">
                                            Fecha a cobrar{" "}
                                        </span>
                                        <RenderDateTime
                                            fecha={row.transaccion.fecha_cobro}
                                        ></RenderDateTime>{" "}
                                        <br />
                                    </Fragment>
                                )}
                                {row.transaccion.banco_referencia && (
                                    <Fragment>
                                        <span className="t-primary font-weight-bold">
                                            Banco de referencia:{" "}
                                        </span>{" "}
                                        {row.transaccion.banco_referencia}{" "}
                                        <br />
                                    </Fragment>
                                )}
                                {row.flotante && (
                                    <div>
                                        <span className="t-primary font-weight-bold">
                                            Es flotante:{" "}
                                        </span>
                                        <span className="t-musgo ">
                                            Sí, pendiente validación.
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            " NO HAY INFORMACION "
                        )
                    }
                >
                    DETALLE TRANSACCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    // width='100'
                    tdStyle={cellTachado}
                    dataSort
                    dataFormat={(cell, row) => <RenderDateTime fecha={cell} />}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="debe"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell, row) => (
                        <RenderMoneda
                            monto={cell}
                            simbolo={getsimbolo(moneda)}
                        />
                    )}
                >
                    DEBE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="haber"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell, row) => (
                        <RenderMoneda
                            monto={cell}
                            simbolo={getsimbolo(moneda)}
                        />
                    )}
                >
                    HABER
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="saldo"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={(cell, row) => (
                        <RenderMoneda
                            monto={cell}
                            simbolo={getsimbolo(moneda)}
                        />
                    )}
                >
                    SALDO
                </TableHeaderColumn>
            </Grid>
        </Fragment>
    );
};

export default ListadoT;
