import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Empleados/empledos';
import EmpleadosGrid from './EmpleadosGrid';

const mstp = state => {
    return {
        ...state.empleados,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EmpleadosGrid)
