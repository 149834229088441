import React, { Component } from 'react'
import Select, { Creatable, Async } from 'react-select';
import classNames from 'classnames';


let entregado = [
    {id: true, value: "1", label:"Entregado"},
    {id: false, value: "0", label:"Pendiente"},
    {id: false, value: null, label:"Todos"},
]
let pago_completo = [
    {id: true, value: "1", label:"Pagados"},
    {id: false, value: "0", label:"Pendientes"},
    {id: false, value: null, label:"Todos"},
]


export default class ToolbarFiltrosOC extends Component {
    componentWillMount(){
    }
    render() {
        const {
            valorEntrega,
            valorPagado,
            changeEntrega,
            changePago,
        } = this.props;
        return (
            <div className=" row col-lg-12 col-12 row m-0 p-0 ">
                <div className="col-lg-6 col-12">
                     {/* <span className="t-musgo font-weight-bold">Año</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={pago_completo}
                    placeholder={"Pagados"}
                    value={valorPagado}
                    onChange={changePago}
                        />
                </div>
                <div className="col-lg-6 col-12">
                    {/* <span className="t-musgo font-weight-bold">Mes</span> */}
                    <Select
                    isClearable={false}
                    className={classNames('react-select-container')}
                    backspaceRemovesValue={false}
                    isSearchable={true}
                    options={entregado}
                    placeholder={"Despachos"}
                    value={valorEntrega}
                    onChange={changeEntrega}
                        />
                </div>
            </div>
        )
    }
}
