import React, { Component } from "react";
import Grid from "../../../Utils/Grid/index";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { RenderDateTimeAll } from "../../../Utils/renderField/renderReadField";

export default class PlanillaGrid extends Component {
    componentDidMount() {
        const { listar, page, permisos } = this.props;
        listar(page);
        this.props.handleData(this.props.value);
    }

    descargar = (id) => {
        const { descargarCSV } = this.props;
        console.log("Planilla ", id);
        descargarCSV(id, 1);
        setTimeout(() => {
            descargarCSV(id, 2);
        }, 2000);
        console.log("Planilla ", id);
    };

    renderStandarActions = () => {
        const { value, eliminar } = this.props;
        const { descargar } = this;
        if (value) {
            return standardActions({ verpro: "planilla", descargar });
        } else {
            return standardActions({ editar: "planilla", eliminar });
        }
    };

    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            permisos,
        } = this.props;

        return (
            <Grid
                page={this.props.page}
                hover
                striped
                data={data}
                loading={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    width="13%"
                    dataFormat={this.renderStandarActions()}
                >
                    ACCIONES
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_inicio"
                    dataSort
                    dataFormat={(cell, row) => {
                        return <div> <RenderDateTime fecha={row.fecha_inicio} className="t-negro" /> <RenderDateTime fecha={row.fecha_final} className="t-negro" /> </div>
                    }}
                >
                    FECHAS PAGADAS
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="modificado"
                    dataSort
                    width="15%"
                    dataFormat={(cell, row) => {
                        return <RenderDateTime fecha={cell} />;
                    }}
                >
                    FECHA DE PAGO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="no_pago" dataSort>
                    NO. PAGO DE PLANILLA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    headerAlign="center"
                    dataAlign="right"
                    dataSort
                    dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                >
                    TOTAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_banco"
                    headerAlign="center"
                    dataAlign="right"
                    dataSort
                    dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                >
                    TOTAL BANCO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_efectivo"
                    headerAlign="center"
                    dataAlign="right"
                    dataSort
                    dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                >
                    TOTAL EFECTIVO
                </TableHeaderColumn>
            </Grid>
        );
    }
}
