import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
} from "../../../../Utils/renderField";
import { TipoTransaccion } from "../../../../../../utility/constants";
import { getSimbolo } from "../../../../Utils/renderField/renderTableField";
import { renderDropdownDateField } from "../../../../Utils/renderField/otherrenders";
import { api } from "api";

const getPoss = (search) => {
    return api
        .get("poss", { search, tipo: 1 })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

let PagoCreditoForm = (props) => {
    const {
        handleSubmit,
        cuentas,
        texto_boton_movimiento,
        simbolo,
        tipo_documento,
        cerrarModal,
        es_gasto,
        tipo_cuenta,
        tipo_gastos,
        esCobro,
        esCheque,
        es_tarjeta,
    } = props;
    return (
        <form
            name="PagoCreditoForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div style={{ padding: "10px", marginTop: "-60px" }}>
                {/*                 <div className="d-flex justify-content-start">
                    <h5 className="t-primary align-self-center">
                        {props.titulo_modal ? props.titulo_modal : ""}
                    </h5>
                </div> */}
                <div className="row col-md-12 m-0 p-0 d-flex justify-content-start">
                    <div
                        className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-start`}
                    >
                        <Field
                            name="tipo_cuenta"
                            label="Cuenta"
                            component={renderFieldRadio}
                            type="radio"
                            value={"true"}
                            className="form-control"
                        />
                    </div>
                    <div
                        className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-start`}
                    >
                        <Field
                            value={"false"}
                            name="tipo_cuenta"
                            label="Mi Caja Chica"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">
                        Fecha
                    </label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                <div
                    className={`form-group has-feedback  col-md-6  col-12 `}
                ></div>
            </div>

            {tipo_cuenta && (
                <div className="row col-12 m-0 p-0">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="tipo_documento">
                            Tipo de Movimiento
                        </label>
                        <Field
                            name="tipo_documento"
                            label="tipo_documento"
                            labelKey="label"
                            valueKey="value"
                            component={renderSelectField}
                            options={TipoTransaccion}
                            className="form-control"
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="no_documento">
                            {tipo_documento}
                        </label>
                        <Field
                            name="no_documento"
                            label="no_documento"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            )}
            {tipo_gastos && (
                <div className="row col-12 m-0 p-0">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="nombre">
                            Categoría de Gasto
                        </label>
                        <Field
                            name="tipo_gasto"
                            label="nombre"
                            component={renderSelecttree}
                            options={tipo_gastos}
                            type="text"
                            valueKey="value"
                            labelKey="label"
                            className="form-control"
                        />
                    </div>
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    ></div>
                </div>
            )}

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="monto">
                        Monto
                    </label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={(cell) => parseFloat(cell)}
                        className="form-control"
                    />
                </div>
                {tipo_cuenta && !es_tarjeta && (
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="numero_cuenta">
                            No. de Cuenta
                        </label>
                        <Field
                            name="numero_cuenta"
                            label="numero_cuenta"
                            labelKey="label"
                            valueKey="id"
                            component={renderSelectField}
                            options={cuentas}
                            className="form-control"
                        />
                    </div>
                )}
                {es_tarjeta && tipo_cuenta && (
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="nombre">
                            POS
                        </label>
                        <Field
                            name="poss"
                            label="poss"
                            component={AsyncSelectField}
                            loadOptions={getPoss}
                            type="text"
                            valueKey="id"
                            labelKey="nombre"
                            onChange={(e) => {}}
                            isClearable={false}
                            className="form-control"
                        />
                    </div>
                )}
            </div>

            {esCobro && tipo_cuenta && esCheque && (
                <div className="row col-12 m-0 p-0">
                    <div className="form-group has-feedback  col-sm-6  col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Fecha de Cobro
                        </label>
                        <Field
                            name="fecha_cobro"
                            selectedDate={new Date()}
                            maxDate="2030-12-31"
                            minDate="2018-01-01"
                            className="form-control"
                            component={renderDropdownDateField}
                        />
                    </div>
                    <div className="form-group has-feedback  col-sm-6  col-12">
                        <label className="t-azul" htmlFor="nombre">
                            Banco de referencia
                        </label>
                        <Field
                            name="banco_referencia"
                            className="form-control"
                            component={renderField}
                        />
                    </div>
                </div>
            )}
            {es_gasto && (
                <div className="row col-12 m-0 p-0">
                    <div className="form-group has-feedback  col-sm-12  col-12">
                        <label className="t-azul" htmlFor="descripcion">
                            Descripción
                        </label>
                        <Field
                            name="descripcion"
                            label="descripcion"
                            component={renderTextArea}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-center ">
                        <button
                            type="button"
                            onClick={cerrarModal}
                            className="btn btn-secondary  align-self-center"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="btn btn-azul-claro ml-md-4 m-1 align-self-center"
                        >
                            {texto_boton_movimiento
                                ? `${texto_boton_movimiento}`
                                : "Agregar pago"}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

PagoCreditoForm = reduxForm({
    form: "PagoCreditoForm", // a unique identifier for this form
    initialValues: {
        tipo_cuenta: "true",
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data) => {
        return validate(data, {
            fecha: validators.exists()("Este campo es requerido"),
            numero_cuenta: validators.exists()("Este campo es requerido"),
            tipo_documento: validators.exists()("Este campo es requerido"),
            no_documento: validators.exists()("Este campo es requerido"),
            monto: validators.exists()("Este campo es requerido"),
        });
    },
})(PagoCreditoForm);

const selector = formValueSelector("PagoCreditoForm");
PagoCreditoForm = connect((state) => {
    const tipo_movimiento = selector(state, "tipo_documento");
    const tipo_cuenta_form = selector(state, "tipo_cuenta");

    let tipo_cuenta = true;
    let esCheque = false;
    let es_tarjeta = false;
    if (tipo_cuenta_form == "false") {
        tipo_cuenta = false;
    }
    let tipo_documento = "Documento de Registro";
    if (tipo_movimiento) {
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento;
        });
        tipo_documento = seleccionado.ayuda;
        if (seleccionado.value == 2) {
            esCheque = true;
        }
        if (seleccionado.value == 5) {
            es_tarjeta = true;
        }
    }
    return {
        tipo_documento,
        tipo_cuenta,
        esCheque,
        es_tarjeta,
    };
})(PagoCreditoForm);

export default PagoCreditoForm;
