import React, { Component } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../../Utils/Grid/StandardActions';
import ToolbarSimple from '../../../../Utils/Toolbar/ToolbarSimple';



//Extras
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";
import { getsimbolo } from '../../../../../../utility/constants';
import Swal from 'sweetalert2';


import Modal from 'react-responsive-modal';

function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}
export default class RechazadosGrid extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
        this.props.handleData(false)
    }
    render() {
        const { data, loader, listar: onPageChange, onSortChange, page } = this.props;
        return (
           <Grid hover
                page={page}
                striped data={data}
                loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    dataFormat={
                        standardActions({ editar:"ordencompra" })
                    }
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="no_orden"
                    dataSort
                    tdStyle={cellTachado}
                >
                    NO. ORDEN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="proveedor"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (cell ? cell.nombre : '--')
                    }
                >
                    PROVEEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    headerAlign="center"
                    dataAlign="right"
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => (
                            <RenderMoneda
                                monto={row.monto ? cell : 0}
                                simbolo={getsimbolo(row.moneda)}
                            />
                        )
                    }
                >
                    MONTO
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="pago_completo"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            if(cell){
                                return (<span className="t-verde font-weight-bold">Cancelada</span>)
                            }else{
                                return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                            }
                        }

                    }
                >
                    PAGADO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="entregado"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            if(cell){
                                return (<span className="t-verde font-weight-bold">Realizada</span>)
                            }else{
                                return(<span className="t-corinto font-weight-bold">Pendiente</span>)
                            }
                        }

                    }
                >
                    ENTREGADO
                </TableHeaderColumn> */}
            </Grid>
        );
    }
}
