import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/Reporte/historicoCajas";
import ReporteHistoricoCajas from "./reporteHistoricoCajas";

const mstp = (state) => {
    return {
        ...state.reporteHistoricoCajas,
        permisos: state.login.me.permisos,
    };
};

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(ReporteHistoricoCajas);
