import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import ContactosGrid from './contactosGrid';
import CuentasGrid from './cuentasGrid';
import NitsGrid from './nitsGrid';
import SucursalesGrid from './sucursalesGrid';

export default class RolesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        // debugger;
        let id = this.props.match.params.id
        if (this.props.location.state){
            this.props.leer(id,"");
        }else{
            this.props.leer(id,true);
        }
        // this.props.selectContactos(id);
    }

    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar } = this.props;
        let CPid = this.props.match.params.id
        return (
            <div className="Container bg-white shadow mt-4" style={{ borderRadius: "10px"}}>
                {/* <h3 className="t-primary m-t p-t">{item.nombre}</h3> */}
                <br/>
                {!!item && item != undefined && this.props.setTitulo(`${item.nombre}`)}
                <div className="row m-none justify-content-start">
                    <div className="col-md-5 col-sm-8 col-12">
                        <h6 className="m-none t-mostaza">Telefono</h6>
                        <h6 className="m-none t-musgo"> {item.telefono? '+502 '+item.telefono:'Sin telefono'}</h6>
                        <h6 className="m-none t-mostaza">Encargado</h6>
                        <h6 className="m-none t-musgo"> {item.encargado? item.encargado: "-------"}</h6>
                        <h6 className="m-none t-mostaza">Correo</h6>
                        <h6 className="m-none t-musgo"> {item.correo? item.correo:'Sin correo electronico'}</h6>
                    </div>
                    <div className="col-md-6 col-sm-8 col-12">
                        <h6 className="m-none t-mostaza">Comentario</h6>
                        <h6 className="m-none t-musgo"> {item.descripcion}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-12">
                        <div className="mb-1">
                            <div className="border-bottom card-header"><h6 className="m-0"></h6></div>
                            <div className="p-0 px-3 pt-3 p-b">
                            <Tabs
                                defaultActiveKey="AGENDA"
                                tabBarPoition = "top"
                                onchange={this.callback}
                                renderTabBar ={()=><ScrollableInkTabBar/>}
                                renderTabContent={()=><TabContent/>}
                            >
                                <TabPane tab="Nits" key="NITS">
                                    <NitsGrid
                                        CPid={CPid}
                                        loader={loader}
                                        data={data}
                                        eliminar={eliminar}
                                        selectNits={this.props.selectNits}
                                        nits={this.props.nits}
                                    />
                                </TabPane>
                                <TabPane tab="Contactos" key="CONTACTOS">
                                    <ContactosGrid
                                        CPid={CPid}
                                        loader={loader}
                                        data={data}
                                        eliminar={eliminar}
                                        selectContactos={this.props.selectContactos}
                                        contactos={this.props.contactos}
                                    />
                                </TabPane>
                                <TabPane tab="Sucursales" key="SUCURSALES">
                                    <SucursalesGrid
                                        CPid={CPid}
                                        loader={loader}
                                        data={data}
                                        eliminar={eliminar}
                                        selectSucursales={this.props.selectSucursales}
                                        sucursales={this.props.sucursales}
                                    />
                                </TabPane>
                                <TabPane tab="Cuentas" key="CUENTAS">
                                    <CuentasGrid
                                        CPid={CPid}
                                        loader={loader}
                                        data={data}
                                        eliminar={eliminar}
                                        cuenta={item.cuenta}
                                        selectCuentas={this.props.selectCuentas}
                                        cuentas={this.props.cuentas}
                                    />
                                </TabPane>
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

