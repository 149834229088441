import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';

import { renderField } from '../../../Utils/renderField';
import { TiposCuentas, Monedas } from '../../../../../utility/constants';
import {SelectField, renderSelectField} from '../../../Utils/renderField/renderField.js'

const CuentasForm = (props) => {
    const { handleSubmit, empresas, format } = props;
    return (
        <form name="CuentasForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="col-lg-8 col-12 offset-lg-2">
            <div className="row justify-content-center">
{/*                 <div className="form-group has-feedback col-lg-3 col-md-6  col-12">
                    <label className="t-azul" htmlFor="origen">Empresa</label>
                    <Field
                        name="origen"
                        label="origen"
                        options={format(empresas)}
                        component={renderSelectField}
                    />
                </div> */}
                <div className="form-group has-feedback col-md-6  col-12">
                    <label className="t-azul" htmlFor="propietario">Nombre de Cuenta <small> (Campo obligatorio) </small></label>
                    <Field name="propietario" label="propietario" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6  col-12">
                    <label className="t-azul"  htmlFor="numero_cuenta">Numero de Cuenta <small> (Campo obligatorio) </small></label>
                    <Field name="numero_cuenta" label="numero_cuenta" component={renderField} type="text" className="form-control" />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-md-6  col-12">
                    <label className="t-azul"  htmlFor="nombre_banco">Banco <small> (Campo obligatorio) </small></label>
                    <Field name="nombre_banco" label="nombre_banco" component={renderField} type="text" className="form-control" />
                </div>
                <div className="form-group has-feedback col-md-6  col-12">
                    <label className="t-azul"  htmlFor="tipo">Tipo de Cuenta <small> (Campo obligatorio) </small></label>
                    <Field 
                        name="tipo" 
                        label="tipo" 
                        component={renderSelectField} 
                        options={TiposCuentas}
                        className="form-control" 
                    />
                </div>
            </div>
            <div className="row justify-content-start">
                <div className="form-group has-feedback col-md-6  col-12">
                    <label className="t-azul"  htmlFor="moneda">Moneda <small> (Campo obligatorio) </small></label>
                    <Field 
                        name="moneda" 
                        label="moneda" 
                        component={renderSelectField} 
                        options={Monedas}
                        className="form-control" 
                    />
                </div>
            </div>
            <br/>
            <div className="row  justify-content-sm-center justify-content-center">
                <Link className="btn btn-secondary m-1 align-self-center" to="/cuentas">Cancelar</Link>
                <button type="submit" className="btn btn-azul-claro ml-md-4  m-1 align-self-center">Guardar</button>
            </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'CuentaForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            empresa_relacionada: validators.exists()('Este campo es requerido'),
            propietario: validators.exists()('Este campo es requerido'),
            numero_cuenta: validators.exists()('Este campo es requerido'),
            nombre_banco: validators.exists()('este campo es requerido'),
            tipo: validators.exists()('este campo es requerido'),
            moneda: validators.exists()('este campo es requerido'),
        });
    },
})(CuentasForm);
