import React, { Component } from "react";
import CardSimple from "../../../Utils/Cards/cardSimple";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import CardDS from "../../../Utils/Cards/casdsDS";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import LoadMask from "Utils/LoadMask/LoadMask";
import HistoricoGrid from "./HistoricoGrid";
import InventarioGrid from "./Inventario_grid";
import ToolbarProductos from './filtroProductos';
export default class EstadoBodegaGrid extends Component {
    componentWillMount() {
        const { leer, page, listDS } = this.props;
        leer(this.props.match.params.id);
        this.props.getDespachosPendientes(this.props.match.params.id);
        // lectura de los despachos
    }
    search = (search) => {
        this.props.searchChange(search, this.props.match.params.id);
    };
    render() {
        const {
            data,
            loader,
            listar: onPageChange,
            onSortChange,
            eliminar,
            item,
        } = this.props;
        return (
            <CardSimple margen="mt-0" row={true}>
                {!!item && this.props.setTitulo(`${item.nombre}`)}
                {this.props.despachos_pendientes &&
                    this.props.despachos_pendientes.length > 0 && (
                        <div>
                            <CardDS
                                data={this.props.despachos_pendientes}
                                loader={loader}
                                titulo="Movimientos de bodega pendientes"
                                id_bodega={this.props.match.params.id}
                                link={`/estado_bodega/despacho/${this.props.match.params.id}`}
                            />
                            <br />
                        </div>
                    )}
                <ToolbarSimple 
                    textBoton="Desapachar"
                    ruta={`/estado_bodega/crear/${this.props.match.params.id}`}
                    textBoton2="Ingreso"
                    ruta2={`/estado_bodega/${this.props.match.params.id}/ingreso`}
                    margen={"0"}
                    buscar={this.search}
                    buscador={this.props.search}
                    placeholder={"Buscar por: Nombre"}
                    permisos={true}
                    estatefilterBar = {true}
                    col='col-lg-3'
                    filterbar = {<ToolbarProductos
                        changeTipo = { this.props.changeTipo }
                        alertado = { this.props.alertado }
                        bodega = {this.props.match.params.id }
                    />}
                />
                <div className="row">
                    <div className="mb-4 col-12 m-0 p-0">
                        <div className="mb-4 ">
                            <div className="p-0 px-3 pt-3 p-b">
                                <Tabs
                                    defaultActiveKey="INVENTARIO"
                                    tabBarPosition="top"
                                    onchange={this.callback}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane tab="Inventario" key="INVENTARIO">
                                        <LoadMask
                                            loading={loader}
                                            light
                                            type={"TailSpin"}
                                        >
                                            <InventarioGrid
                                                {...this.props}
                                                id_bodega={
                                                    this.props.match.params.id
                                                }
                                            />
                                        </LoadMask>
                                    </TabPane>
                                    <TabPane tab="Historial " key="HISTORIAL">
                                        <LoadMask
                                            loading={loader}
                                            light
                                            type={"TailSpin"}
                                        >
                                            <HistoricoGrid
                                                id_bodega={
                                                    this.props.match.params.id
                                                }
                                                {...this.props}
                                            />
                                        </LoadMask>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </CardSimple>
        );
    }
}
