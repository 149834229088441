import React, { Component } from 'react';
import HeaderSimple from '../Header/HeaderSimple';

function CardSimple(props) {
    return (
            <div className="Container bg-white shadow-card mt-3" 
                style={{borderRadius:"10px"}}
            >
                {props.titulo_dashboard != undefined && (
                    <div className="col-12 px-4 pt-3 pb-1 mx-0 gradient-primary" style={{borderRadius: "10px"}}>
                        <h6 className="t-blanco">
                            {props.titulo_dashboard}
                        </h6>
                    </div>
                )}
                <HeaderSimple titulo={props.titulo}/>
                <br/>
                {(props.toolbar) && (
                    props.toolbar
                )}
                <div className={`row ${props.margen ? props.margen:'mt-5'} mx-4`}>
                    <div className={`mb-4 col-12 m-0 p-0 ${props.row ? '' : 'row'}`}>
                       {props.children}
                    </div>
                </div>
            </div>
    );
}

export default CardSimple;
