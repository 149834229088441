import React, { Component, Fragment } from 'react'
import Grid from '../../../../Utils/Grid/index';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {standardActions} from '../../../../Utils/Grid/StandardActions';
import Modal from 'react-responsive-modal';
import PagoCreditoForm from '../../Crear/PagoCreditoForm';


import { TipoTransaccion } from '../../../../../../utility/constants';
import {RenderMoneda, RenderDateTime} from "../../../../Utils/renderField/renderTableField";

import Swal from 'sweetalert2';
import moment from 'moment';


import AnulacionForm from '../../../Transacciones/Listar/anulacionForm';
import RealizarPagoForm from '../../../Transacciones/Listar/realizarPagoForm';
import { getSimbolo } from 'Utils/renderField/renderTableField';


function cellTachado(cell, row) {
    if(!row.estado){
        return {textDecoration: "line-through", whiteSpace: 'normal', padding: 5}
    }
    return {whiteSpace: 'normal', padding: 5}
}


export default class ReintegroDinero extends Component {
    state = {
        open_modal:false,
        es_pago:false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        mov_reintegro: true
    }
    componentWillMount(){
        const { moneda, orden } = this.props;
        this.props.selectCuentas(orden.orden.empresa, moneda)

    }
    abrirModalEliminar = (id, row) => {
        console.log('Eliminar reintegro ', id, row)
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setOpenModalGasto(true);
    }
    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, orden.orden.id);
            }
        });
    }
    marcarOCCompleta = () => {
        const { orden } = this.props;

        Swal.fire({
            title: '¿Desea marcar la orden de compra como pagada?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagada!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.marcarPagadaOC(orden.id);
            }
        });
    }
    cerrarModal = () => {
        this.props.cerrarModales();
    }
    renderModalAnulacion = () => {
        const { open_modal_gasto } = this.props;
        return (
            <Modal open={open_modal_gasto} showCloseIcon={ false } onClose={(e) => {
                this.props.cerrarModales()
            }} center>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Justificación de anulación </span>
                </div>

                    {
                        (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                            <div className="form-validate mb-lg text-center" >
                                        {console.log(this.state.transaccion_sel)}
                                        <span className="m-none t-mostaza semibold">Fecha anulación: </span><br/>
                                        <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br/>
                                        <span className="m-none t-mostaza semibold">Usuario anulación: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br/>
                                        <span className="m-none t-mostaza semibold">descripción: </span><br/>
                                        <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br/>
                                    </div>
                        ): (
                            <AnulacionForm
                            onSubmit={this.onSubmit}
                            cerrarModal={this.props.setOpenModalGasto}
                            />
                        )
                    }

            </Modal>
        )
    }
    botonModal = (id, row) => {

        if(row.estado){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional" src={require("../../../../../../../assets/img/icons/Ver.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row
                        })
                        this.props.setOpenModalReintegro(true);
                     }}
                      alt="Ver eliminado" />)
    }
    botonRealizarMovimiento = (id, row) => {
        if(!row.estado){
            return(<div></div>)
        }
        if(!row.flotante){
            return(<div></div>)
        }
        return (<img className="action-img" title="adicional"
            src={require("../../../../../../../assets/img/icons/marcar_bono.png")}
                     onClick={(e)=>{
                         e.preventDefault();
                         this.props.setOpenModalReintegro(true)
                         this.setState({
                            id_transaccion: id,
                            transaccion_sel: row,
                            mov_reintegro: false,
                            open_modal: true
                        })
                     }}
                      alt="Ver eliminado" />)
    }
    onSubmitCheques = (values) => {
        Swal.fire({
            title: '¿Desea marcar cheque como pagado?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, pagado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                values.fecha = moment(values.fecha).format('YYYY-MM-DD')
                this.props.flotanteChequePagado(values, this.props.id_cuenta);
            }
        });
    }
    renderModalFlotantePagado = () => {
        let transaccion = null
        if(this.state.transaccion_sel){
            transaccion = {
                reintegro: true,
                ...this.state.transaccion_sel.transaccion,
                fecha: new Date()
            }
        }
        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Realizar pago </span>
                </div>
                <RealizarPagoForm
                    transaccion={transaccion}
                    initialValues={transaccion}
                    onSubmit={this.onSubmitCheques}
                    cerrarModal={this.props.setOpenModalReintegro}
                    />
            </Fragment>
        )
    }
    renderMovimientoReintegro = () => {
        const {  pagos_credito, cuentas,  item, simbolo, orden} = this.props;
        let simbolo_orden = getSimbolo(orden.orden.moneda);

        return(
            <Fragment>
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title"> Reintegro </span>
                </div>
                <PagoCreditoForm
                    cerrarModal={this.cerrarModal}
                    cuentas={cuentas}
                    simbolo={simbolo}
                    simbolo_orden={simbolo_orden}
                    es_gasto={true}
                    texto_boton_movimiento={"Reintegro"}
                    titulo_modal="Ingreso reintegro a"
                    initialValues= {{
                        tipo_cuenta: 'true',
                        fecha: new Date(),
                        reintegro: true
                    }}
                    onSubmit={(e) => {
                        this.props.abonarGastoOPago(orden.orden.id, false);
                        this.cerrarModal();
                    }} />
            </Fragment>
        );
    }
    render() {
        const { item, simbolo, orden, open_modal_reintegro} = this.props;

        let data = {
            count: 1,
            results: item.reintegros
        }
        return (
            <Fragment>
                {this.renderModalAnulacion()}
                <Modal open={open_modal_reintegro} showCloseIcon={ false } onClose={(e) => {
                    //this.setState({open_modal: false})
                    this.props.setOpenModalReintegro(false)
                }} center>
                    {
                        (this.state.mov_reintegro) ? (
                            this.renderMovimientoReintegro()
                        ) : (
                            this.renderModalFlotantePagado()
                        )
                    }
                </Modal>
                <Grid hover striped data={data}  pagination={false} >
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({
                        eliminarModal: this.abrirModalEliminar,
                        adicional: this.botonModal,
                        adicional2: this.botonRealizarMovimiento
                    })}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell,row) => {
                            return(
                                <div>
                                    <span className="font-weight-bold">FECHA </span><RenderDateTime fecha={cell} ></RenderDateTime> <br/>
                                    {
                                        (row.fecha_emision) && (
                                            <Fragment>
                                                <span className="font-weight-bold">FECHA EMISIÓN </span><RenderDateTime fecha={row.fecha_emision} ></RenderDateTime> <br/>
                                            </Fragment>
                                        )
                                    }
                                    <span className="font-weight-bold">TIPO DE PAGO</span> <span>{row.flotante ? <span>Flotante</span> : <span>Inmediato</span>}</span>
                                </div>
                            )
                        }
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                        dataField="tipo_cuenta"
                        dataSort
                        tdStyle={cellTachado}

                    >
                        ENTRO A
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero_cuenta"
                        dataSort
                        tdStyle={cellTachado}

                    >
                        CUENTA
                    </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            let id = Number(cell.tipo_documento);
                            let cuenta = _.find(TipoTransaccion, x => x.value == id);
                            if(cuenta){
                                return(cuenta.label);
                            }
                        }
                    }
                >
                    TIPO DOCUMENTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat = {
                        (cell, row) => {
                            return <span>{cell.no_documento}</span>
                        }
                    }
                >
                    NO. TRANSACCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    dataSort
                    tdStyle={cellTachado}
                    headerAlign="center"
                    dataAlign="right"
                    dataFormat ={
                        (cell, row)=>{
                            if(cell){
                                return <RenderMoneda monto={cell.monto} simbolo={simbolo} />
                            }
                        }
                    }
                >
                    MONTO
                </TableHeaderColumn>
            </Grid>

            {
                (orden.estado) && (
                    <Fragment>
                        <button type="button" onClick={(e) => {
                            e.preventDefault();
                            this.props.setOpenModalReintegro(true)
                            this.setState({
                                open_modal: true,
                                es_pago: true,
                                mov_reintegro: true,
                                es_gasto: false})
                        }} className="btn btn-mostaza m-1 align-self-center mt-2">Agregar reintegro</button>
                    </Fragment>

                )
            }

        </Fragment>
        )
    }
}
