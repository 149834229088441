import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/Transacciones/transacciones';
import { setTransaccion } from '../../../../../redux/modules/Transacciones/flotante_inverso';

import TransaccionesGrid from './transaccionesGrid';

const mstp = state => {
    return {...state.transacciones}
};

const mdtp = {
    ...actions, setTransaccion
};

export default connect(mstp, mdtp)(TransaccionesGrid)
