import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import Grid from "../../../Utils/Grid/index";

const HistorialProduccion = (props) => {
    const { data, loader } = props;
    return (
        <div className="margin-tabgrid px-3" style={{ marginTop: "-40px" }}>
            <Grid
                hover
                striped
                data={{ count: 10, results: data }}
                loading={loader}
                pagination={false}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    isKey
                    dataFormat={(cell, row) => row.empleado}
                >
                    EMPLEADO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="producto" dataSort>
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    dataFormat={(cell) => (
                        <RenderNumber value={cell} decimalScale={4} />
                    )}
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn dataField="tipo_horario">
                    TIPO DE HORARIO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="horas_trabajadas"
                    dataAlign="right"
                >
                    HORAS TRABAJADAS
                </TableHeaderColumn>
            </Grid>
            <div className="col-12 mt-4 d-flex justify-content-center">
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Cerrar
                </button>
            </div>
        </div>
    );
};

export default HistorialProduccion;
