import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid/index";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { RenderNumber } from "../../../Utils/renderField/renderReadField";
import Modal from "react-responsive-modal";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";
import { TIPO_ENTREGA, Monedas } from "../../../../../utility/constants";
import LoadMask from "Utils/LoadMask/LoadMask";
import HistorialProduccion from "./HistorialProduccion";

export default class DetalleOP extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    state = {
        modal: false,
    };

    componentDidMount() {
        this.props.clear();
        let id = this.props.match.params.idOP;
        this.props.leer(id);
    }

    getsimbolo = (valor) => {
        let simbolo = "";
        Monedas.forEach((item, index) => {
            if (item.value === valor) {
                simbolo = item.simbolo;
            }
        });
        return simbolo + ". ";
    };

    openModal = () => {
        const id = this.props.match.params.idOP;
        this.props.getHistorial(id);
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    renderModal = () => {
        return (
            <Modal
                open={this.state.modal}
                showCloseIcon={false}
                onClose={(e) => {
                    this.setState({ modal: false });
                }}
                center
            >
                <div className="col-12 modal-card-header">
                    <span className="col-12 col-lg-10 modal-card-title">
                        Historial de producción
                    </span>
                </div>
                <HistorialProduccion
                    data={this.props.historial && this.props.historial}
                    closeModal={this.closeModal}
                    loader={this.props.loader}
                />
            </Modal>
        );
    };

    render() {
        const { item, data, loader } = this.props;
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                {this.renderModal()}
                <div
                    className="Container bg-white shadow"
                    style={{ borderRadius: "10px" }}
                >
                    <div className="m-t p-t fnt-size">
                        <div
                            className="row m-none"
                            style={{ paddingTop: "15px" }}
                        >
                            <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                <h3 className="t-titulo m-none">
                                    {item ? item.no_orden : ""}
                                </h3>
                            </div>
                        </div>
                        <br />
                        {/* DATOS GENERALES */}
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "10px 15px",
                            }}
                        />
                        <div className="row m-none">
                            <div className="col-lg-4 col-md-6 col-12">
                                <span className="m-none t-primary bold">
                                    EMITIDA POR:
                                </span>
                                <br />
                                <span className="m-none t-musgo regular">
                                    {`${
                                        item.emitido_por &&
                                        item.emitido_por.first_name
                                    } ${
                                        item.emitido_por &&
                                        item.emitido_por.last_name
                                    }`}
                                </span>
                            </div>
                        </div>
                        <br />
                        {/* DESCRIPCION */}
                        <div className="col-12">
                            <span className="m-none t-texto bold">
                                DESCRIPCIÓN
                            </span>
                        </div>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 15px 10px 15px",
                            }}
                        />
                        <div className="row m-none">
                            <div className="col-12">
                                <span className="m-none t-musgo semibold">{`${item.descripcion}`}</span>
                            </div>
                        </div>
                        <br /> {/*CONDICIONES DE ENTREGA */}
                        <div className="col-12">
                            <span className="m-none t-texto bold">FECHA</span>
                        </div>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 15px 10px 15px",
                            }}
                        />
                        <div className="row m-none">
                            <div className="col-md-6 col-12">
                                <span className="m-none t-primary bold">
                                    FECHA:{" "}
                                </span>
                                <br />
                                <span className="m-none t-musgo semibold">
                                    <RenderDateTime
                                        fecha={item.fecha}
                                    ></RenderDateTime>
                                </span>
                            </div>
                            <div className="col-md-6 col-12">
                                <span className="m-none t-primary bold">
                                    FECHA PROXIMA DE ENTREGA:{" "}
                                </span>
                                <br />
                                <span className="m-none t-musgo semibold">
                                    <RenderDateTime
                                        fecha={item.fecha_prox_entrega}
                                    ></RenderDateTime>
                                </span>
                            </div>
                        </div>
                        <br /> {/*Detalle */}
                        <div className="col-12">
                            <span className="m-none t-texto bold">DETALLE</span>
                        </div>
                        <hr
                            style={{
                                borderTop: "2px solid rgb(223, 224, 224)",
                                margin: "0px 15px 10px 15px",
                            }}
                        />
                        <div className="row">
                            <div className="mb-4 col-12">
                                <div className="mb-4 ">
                                    <div className="">
                                        <h6 className="m-0"></h6>
                                    </div>
                                    <div className="p-0 px-3 pt-3 p-b">
                                        <div className="margin-tabgrid px-3">
                                            <Grid
                                                hover
                                                striped
                                                data={{
                                                    count: 10,
                                                    results: item.detalle_orden,
                                                }}
                                                loading={loader}
                                                pagination={false}
                                            >
                                                <TableHeaderColumn
                                                    dataField="producto"
                                                    dataFormat={
                                                        (cell) => (cell.nombre)
                                                    }
                                                    isKey
                                                >
                                                    PRODUCTO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad"
                                                    dataAlign="right"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                                                >
                                                    CANTIDAD SOLICITADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad_actual"
                                                    dataAlign="right"
                                                    dataFormat={
                                                        (cell, row) => <RenderNumber value={row.cantidad - cell} decimalScale={4} />
                                                    }
                                                >
                                                    CANTIDAD REPORTADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="cantidad_actual"
                                                    dataAlign="right"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={4} />}
                                                >
                                                    CANTIDAD FALTANTE
                                                </TableHeaderColumn>
                                            </Grid>
                                            <div className="row justify-content-sm-start mt-2 justify-content-start ml-0">
                                                <button
                                                    onClick={this.openModal}
                                                    className="btn btn-azul-claro m-1 align-self-center"
                                                >
                                                    Ver historial
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        );
    }
}
