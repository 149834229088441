import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Chart from "react-google-charts";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../../Utils/renderField/renderTableField";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import CardSimple from "../../../../Utils/Cards/cardSimple";
import Grid from "../../../../Utils/Grid/index";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import ToolbarReporteVentas from '../../../../Utils/Toolbar/ToolbarReporteVentas';

export default class ReporteVentas extends Component {
    componentDidMount() {
        this.props.getVentasxMes();
        this.props.getVentasxFecha();
    }

    render() {
        const {
            loader,
            loaderDetalle,
            dataDetalle,
            page,
            getVentasxFecha,
        } = this.props;
        return (
            <div>
                <CardSimple
                    row={true}
                    margen="mt-0"
                    style={{ marginTop: "-50px" }}
                >
                    <LoadMask loading={loader} light type={"TailSpin"}>
                        <div className="col-12 row m-0 p-0 d-flex">
                            <div className="col-md-12 mx-0 px-0">
                                <div className="">
                                    {this.props.data && this.props.data.length && (

                                        <div style={{ width: "100%", flex: 1 }}>
                                        <Chart
                                            width={"100%"}
                                            height={250}
                                            chartType="ColumnChart"
                                            loader={<div>Cargando Gráfica</div>}
                                            data={this.props.data}
                                            options={{
                                                chartArea: { width:"80%", right: "5%" },
                                                bar: { groupWidth: "40%" },
                                                colors: ["#128085", "#F8C9A8"],
                                                vAxis: {
                                                    format: "Q ###,###,###.00",
                                                    baselineColor: "#000",
                                                },
                                                tooltip: {
                                                    format: "Q ###,###,###.00",
                                                },
                                                animation: {
                                                    startup: true,
                                                    easing: "linear",
                                                    duration: 1000,
                                                },
                                                legend: {
                                                    position: "top",
                                                    alignment: "center",
                                                },
                                                fontName: "Cairo-Regular",
                                            }}
                                            formatters={[
                                                {
                                                    type: "NumberFormat",
                                                    column: 1,
                                                    options: {
                                                        prefix: "Q ",
                                                    },
                                                },
                                                {
                                                    type: "NumberFormat",
                                                    column: 2,
                                                    options: {
                                                        prefix: "Q ",
                                                    },
                                                },
                                            ]}
                                            legendToggle
                                            />
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </CardSimple>
                <CardSimple
                    row={true}
                    margen="mt-0"
                    style={{ marginTop: "-50px" }}
                >
                    <ToolbarReporteVentas
                        fecha_inicio ={this.props.fecha_inicio}
                        fecha_fin={this.props.fecha_final}
                        changeFechaInicio={this.props.set_fecha_inicio}
                        changeFechaFin={this.props.set_fecha_final}
                        changeCliente = {this.props.changeCliente}
                        changeVendedor={this.props.changeVendedor}
                        vendedor = {this.props.vendedor}
                        cliente = {this.props.cliente}
                    />
                    <div className="col-12 row m-0 p-0 d-flex">
                        <div className="col-md-12 mx-0 px-0">
                            <div
                                className="col-12 my-3 py-1"
                                style={{
                                    backgroundColor: "#828FFB",
                                    borderRadius: "5px",
                                }}
                            >
                                <div className="row col-12 d-flex justify-content-end">
                                    <div className="col-2 t-blanco">TOTAL</div>
                                    <div className="col-3">
                                        {dataDetalle && dataDetalle.results && dataDetalle.results[0] ? (
                                            <RenderMoneda
                                                className="t-blanco px-0"
                                                monto={dataDetalle.results[0].total_general}
                                            />
                                        ) : (
                                            <RenderMoneda
                                                className="t-blanco px-0"
                                                monto={0}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {dataDetalle && dataDetalle.results && (
                                <Grid
                                    hover
                                    striped
                                    page={page}
                                    data={dataDetalle}
                                    loading={loaderDetalle}
                                    onPageChange={getVentasxFecha}
                                    pagination={true}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        isKey
                                        width="15%"
                                        dataFormat={standardActions({
                                            imprimirReporte: "punto_venta",
                                            ver: "/reporte_ventas",
                                        })}
                                    >
                                        ACCIONES
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fecha"
                                        dataSort
                                        dataFormat={(cell) =>
                                            cell ? (
                                                <RenderDateTime fecha={cell} />
                                            ) : (
                                                "Sin registro"
                                            )
                                        }
                                    >
                                        FECHA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="operador"
                                        dataAlign="center"
                                        dataFormat={(cell) =>
                                            cell ? cell.nombre : "Sin registro"
                                        }
                                    >
                                        OPERADOR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="no_venta"
                                        dataAlign="center"
                                    >
                                        CODIGO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="comprador"
                                        dataAlign="center"
                                        dataFormat={(cell) =>
                                            cell ? cell : "Sin registro"
                                        }
                                    >
                                        COMPRADOR
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                        headerAlign="center"
                                        dataAlign="right"
                                        dataFormat={(cell) =>
                                            cell ? (
                                                <RenderMoneda
                                                    className="px-0"
                                                    monto={cell}
                                                />
                                            ) : (
                                                <RenderMoneda
                                                    className="px-0"
                                                    monto={0}
                                                />
                                            )
                                        }
                                    >
                                        PAGO DE
                                    </TableHeaderColumn>
                                </Grid>
                            )}
                        </div>
                    </div>
                </CardSimple>
            </div>
        );
    }
}
